function filterSearchParamForSubTool (subtool, searchParam) {
	console.log("(_)I#()_#@()__()#()*#")
    if (subtool === 'market') {
      searchParam.states = ['ON_MARKET', 'OFF_MARKET']
    }  else if (subtool === 'pending') {
      searchParam.states = ['NEW', 'VERIFICATION_REQUESTED', 'UNVERIFIED', 'VERIFIED', 'REJECTED']
    } else if (subtool === 'past-sales') {
      searchParam.states = ['SOLD']
    }
    return searchParam
  }


  export {filterSearchParamForSubTool}