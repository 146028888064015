import {
  LOGIN,
  MARKET_LOGIN_PAGE_UNLOADED,
  SIGNUP_PAGE_UNLOADED,
  ASYNC_START,
  UPDATE_MARKET_LOGIN_FIELD_AUTH,
  MARKET_LOGIN,
  MARKET_RESEND_EMAIL_VERIFICATION_EMAIL
} from '../constants/actionTypes';

const uuidv4 = require('uuid/v4')

export default (state = {}, action) => {
  switch (action.type) {
    case MARKET_LOGIN:
    let errors = action.payload.errors ? action.payload.errors.slice() : null
 
    if (errors) {
      errors.map(error=>error.uuid = uuidv4())
    }
      return {
        ...state,
        inProgress: false,
        marketAuthErrors: action.error ? errors : null
      };
    case MARKET_LOGIN_PAGE_UNLOADED:
    case SIGNUP_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      if (action.subtype === LOGIN) {
        return { ...state, inProgress: true };
      }
      break;
    case MARKET_RESEND_EMAIL_VERIFICATION_EMAIL:
      let newErrors = state.marketAuthErrors.slice()
      newErrors.filter(error=>error.uuid === action.errUUID)[0].actionResp = action.payload.msg
      return {
        ...state,
        marketAuthErrors: newErrors
      }
    case UPDATE_MARKET_LOGIN_FIELD_AUTH:
      
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};