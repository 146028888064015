import React from "react";
import gql from "graphql-tag";
import GemRequestsManager from "../../../UtilityComponents/GemRequestsManager";

const SEARCH_GEM_REQUESTS_IN_STATE = gql`
  query SearchGemRequestsInState($state: GemRequestState!) {
    searchGemRequestsInState(state: $state) {
      edges {
        node {
          cutRange
          priceRange
          colorRange
          shapes
          clarityRange
          createdBy {
            person {
              familyName
              givenName
            }
            localAuth {
              email
            }
          }
        }
      }
      totalCount
    }
  }
`;

const GemRequestsManagement = props => {
  return (
    <GemRequestsManager
      searchQuery={SEARCH_GEM_REQUESTS_IN_STATE}
      match={props.match}
    />
  );
};

export default GemRequestsManagement;
