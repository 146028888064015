import { Icon } from "@chakra-ui/react";
import React from "react";


export const RoundShapeIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 504.304 504.78" height={'auto'} width={'100%'} fill={color}>
        <g
            id="Diamonds_Round_Outlined"
            data-name="Diamonds_Round Outlined"
            transform="translate(-40.518 -168.555)"
        >
            <g id="Diamonds">
                <g id="Grupo_48" data-name="Grupo 48">
                    <g id="XMLID_131_">
                        <path
                            id="XMLID_194_"
                            d="M292.492,671.335l0-.478h0Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_193_"
                            d="M292.492,214.491l.178.055v-.111Z"
                            fill="#000"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_192_"
                            d="M292.492,171.233l-.177,43.2.177.056.177-.056Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_191_"
                            d="M438.494,275.37l-.173-.1v.245Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_190_"
                            d="M524.373,326.3a250.4,250.4,0,0,0-51.537-79.113l-34.342,28.186Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_189_"
                            d="M438.321,275.512V360.2l60.233,60.233L525,327.833c-.206-.514-.419-1.024-.628-1.536L438.494,275.37Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_188_"
                            d="M498.831,420.708h43.991A249.453,249.453,0,0,0,525,327.833l-26.447,92.6Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_187_"
                            d="M292.314,214.546l.178-.055-.177-.056Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_186_"
                            d="M292.669,626.981v-.113l-.178.057Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_185_"
                            d="M438.321,275.056v.211l.173.1,34.342-28.186A250.46,250.46,0,0,0,383,187.375l54.969,87.681Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_184_"
                            d="M292.315,626.981l.177-.056-.178-.057Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_183_"
                            d="M146.646,566.14l.373.219v-.6Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_182_"
                            d="M147.049,275.006l-.03.05h.072Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_181_"
                            d="M292.494,670.857l.176,0A249.577,249.577,0,0,0,380.8,654.876l-88.127-27.9Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_180_"
                            d="M147.019,360.2V275.056L61.173,325.792c-.354.863-.713,1.723-1.058,2.59L86.51,420.707Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_179_"
                            d="M204.4,654.823a249.549,249.549,0,0,0,88.086,16.034l-.175-43.876Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_178_"
                            d="M147.019,565.76V481.217l-60.51-60.51L60.161,513.144c.378.951.77,1.894,1.16,2.84l85.326,50.155Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_177_"
                            d="M201.508,187.693a250.336,250.336,0,0,0-82.671,53.15l28.212,34.163Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_176_"
                            d="M147.049,275.006l-28.212-34.163a250.545,250.545,0,0,0-57.665,84.949l85.846-50.736Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_175_"
                            d="M146.646,566.14l-30.832,31.479a250.393,250.393,0,0,0,86.562,56.433l-55.357-87.693Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_174_"
                            d="M292.314,626.868l.178.057.178-.057-60.51-60.509H147.018l55.357,87.693c.674.261,1.351.516,2.028.771l87.91-27.842Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_173_"
                            d="M61.321,515.984a250.524,250.524,0,0,0,54.493,81.635l30.832-31.479Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_172_"
                            d="M42.518,420.708a249.463,249.463,0,0,0,17.643,92.437l26.348-92.437Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_171_"
                            d="M292.492,626.925l-.177.056.175,43.876h0l.176-43.876Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_170_"
                            d="M60.114,328.383a249.484,249.484,0,0,0-17.6,92.325H86.509Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_169_"
                            d="M498.831,420.708l-.277.277,26.378,92.766a249.443,249.443,0,0,0,17.89-93.043H498.831Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_168_"
                            d="M292.492,171.233l0-.675a249.545,249.545,0,0,0-88.4,16.154l88.226,27.723Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_167_"
                            d="M292.495,170.557h-.006l0,.675Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_166_"
                            d="M292.669,214.435l88.523-27.746a249.542,249.542,0,0,0-88.523-16.134l-.175,0,0,.676Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_165_"
                            d="M438.321,481.217V566.15l85.831-50.491c.261-.635.524-1.27.78-1.908l-26.378-92.766Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_164_"
                            d="M147.091,275.056H232.16l60.51-60.51-.177-.056-.179.056v-.112l-88.226-27.723c-.861.326-1.725.646-2.581.981l-54.459,87.313Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_162_"
                            d="M437.966,566.359h0l-54.743,87.595a250.4,250.4,0,0,0,86.032-56.07l-31.182-31.525h-.107Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_160_"
                            d="M437.966,566.359H353.18l-60.51,60.509v.113l88.127,27.9c.81-.3,1.621-.609,2.427-.922l54.742-87.595Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_158_"
                            d="M292.67,214.546l60.51,60.51h84.786L383,187.375c-.6-.232-1.2-.458-1.8-.686L292.67,214.435Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_155_"
                            d="M438.321,566.359h-.248l31.182,31.525a250.509,250.509,0,0,0,54.9-82.224l-85.831,50.491v.208Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_154_"
                            d="M498.475,420.708h.356l-.277-.277Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_153_"
                            d="M86.509,420.708h0l60.51,60.509V360.2Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_152_"
                            d="M292.67,214.546l-60.51,60.51H353.18Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_151_"
                            d="M498.475,420.708h0l.079-.277L438.321,360.2V481.217l60.233-60.232Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_150_"
                            d="M292.67,626.868l60.51-60.509H232.16Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_149_"
                            d="M498.475,420.708l.079.277.277-.277Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_148_"
                            d="M438.321,275.512h0v-.245l-.356-.211H353.18L438.321,360.2Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_143_"
                            d="M438.073,566.359h.248v-.209l-.287.169Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_142_"
                            d="M147.019,565.76h0v.6H232.16l-85.141-85.142Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_141_"
                            d="M437.966,566.359l.068-.04h0l.287-.169V481.217L353.18,566.359Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_140_"
                            d="M147.091,275.056h-.072V360.2l85.141-85.142Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_137_"
                            d="M438.321,275.267v-.211h-.356Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_134_"
                            d="M437.966,566.359h.107l-.039-.04Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_132_"
                            d="M353.18,275.056H232.16L147.019,360.2V481.217l85.141,85.142H353.18l85.141-85.142V360.2Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <circle
                        id="Elipse_268"
                        data-name="Elipse 268"
                        cx="250.152"
                        cy="250.152"
                        r="250.152"
                        transform="translate(42.518 170.895)"
                        strokeWidth="4"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        fill="none"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
