import * as React from "react";
import { AuthLogin } from "../../UtilityComponents/AuthLogin";
import { useTranslation } from "react-i18next";

const MarketSignUp = ({ signUpFunc }) => {
    let { t } = useTranslation();
    return (
        <AuthLogin
            mode="sign-up"
            h="calc(100vh - 6.94rem)"
            py="2rem"
            heading={t("CREATE_AN_ACCOUNT")}
            headingSubtext={t("marketing:SIGN_UP_TO_SHOP_MSG")}
            variant="two-panel-template"
        />
    );
};

export default MarketSignUp;
