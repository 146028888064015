import gql from "graphql-tag";

const GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS = gql`
query GetGlobalStatsFromSearchOfGemListings {
  getGlobalStatsFromSearchOfGemListings {
    counts {
      byState
      byCategory
    }
    totalPrice {
      byState
      byCategory
    }
  }
}
`;

export { GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS };
