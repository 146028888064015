import React from "react";
import { Box, VStack, Alert, AlertTitle, AlertIcon } from "../../Primatives";

const Errors = ({ errors }) => {
  return (
    <VStack align="left">
      <Box>
        {errors.map((error) => (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{error.message}</AlertTitle>
          </Alert>
        ))}
      </Box>
    </VStack>
  );
};

const MutationResult = ({ children, errors, result }) => {
  return (
    <Box>
      {errors && <Errors errors={errors} />}
      {result && result.message}
      {children}
    </Box>
  );
};
export default MutationResult;
