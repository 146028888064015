import {
  ADMIN_GEM_LISTING_DETAILS_LOADED,
  ADMIN_GEM_LISTING_DETAILS_UNLOADED,
  ADMIN_GEM_EVENT_HISTORY_LOADED,
  ADMIN_GEM_EVENT_HISTORY_UNLOADED,
  ADMIN_GEM_RELATIVE_PRICING_HISTORY_LOADED,
  ADMIN_GEM_RELATIVE_PRICING_HISTORY_UNLOADED
} from "../constants/actionTypes";

function initialState() {
  return {
    gemListing: null
  };
}

export default (state = initialState(), action) => {
  switch (action.type) {
    case ADMIN_GEM_LISTING_DETAILS_LOADED:
      return { ...state, gemListing: action.payload[0].gemListing };
    case ADMIN_GEM_EVENT_HISTORY_LOADED:
      return { ...state, gemListingEvents: action.payload[0].events };
    case ADMIN_GEM_EVENT_HISTORY_UNLOADED:
      return { ...state, gemListingEvents: null };
    case ADMIN_GEM_LISTING_DETAILS_UNLOADED:
      return initialState()
    case ADMIN_GEM_RELATIVE_PRICING_HISTORY_LOADED:
      return {
        ...state,
        gemListingRelativePricingHistory:
          action.payload[0].relativePricingHistory
      };
    case ADMIN_GEM_RELATIVE_PRICING_HISTORY_UNLOADED:
      return { ...state, gemListingRelativePricingHistory: null };
    default:
      return state;
  }
};
