import PubSub from 'pubsub-js';
import { useEffect, useState } from 'react';

// TODO - this is  a good one to make into type script
export function useCompanyEvents(type, topic) {


	const [eventToHandle, setEventToHandle] = useState();


	useEffect(() => {
		const token = PubSub.subscribe(`${type}:${topic}`, (topic, incomingEvent) => (setEventToHandle(incomingEvent)));
		return () => PubSub.unsubscribe(token);
	}, [eventToHandle])


	return { eventToHandle }
}