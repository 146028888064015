import { Mutation } from "@apollo/react-components";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import MutationResult from "../../../../stories/CloudManagementOS/Apollo/MutationResult";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import ErrorHandler from "../../../../stories/CloudManagementOS/ErrorHandler";
import { FieldGroup } from "../../../../stories/CloudManagementOS/FieldGroup";
import {
  Box,
  Button,
  Input,
  Stack,
  StackDivider,
  VStack,
} from "../../../../stories/Primatives";

const UPDATE_VENDOR_PASSWORD_GQL_MUTATION = gql`
  mutation UpdateVendorPassword(
    $originalPassword: String!
    $newPassword: String!
  ) {
    updateVendorPassword(
      originalPassword: $originalPassword
      newPassword: $newPassword
    ) {
      message
    }
  }
`;

const GQL_Q_CURRENT_VENDOR = gql`
  query CurrentVendor {
    currentVendor {
      vendorOrganization {
        legalName
      }
      vendor {
        localAuth {
          email
        }
        person {
          familyName
          givenName
        }
      }
    }
  }
`;

function checkNewPasswordAndNewPasswordConfirmation(
  newPassword,
  newPasswordConfirmation
) {
  return !(newPassword && newPassword === newPasswordConfirmation);
}

function CurrentVendor() {
  const { loading, error, data, refetch } = useQuery(GQL_Q_CURRENT_VENDOR);

  if (data) {
    return (
      <FieldGroup title="Personal Info">
        <VStack width="full" spacing="6">
          <Box>
            {data.currentVendor.vendor.person.givenName}
            {data.currentVendor.vendor.person.familyName}
          </Box>
        </VStack>
      </FieldGroup>
    );
  }
  return null;
}

function UpdatePassword() {
  const [originalPassword, setOriginalPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  return (
    <Mutation mutation={UPDATE_VENDOR_PASSWORD_GQL_MUTATION}>
      {(updateVendorPassword, { error, data, loading }) => {
        return (
          <div>
            <ErrorHandler graphError={error} />
            <MutationResult result={data && data.updateVendorPassword} />
            <form
              className="vendor-auth-sign-up-form"
              onSubmit={(e) => {
                e.preventDefault();
                updateVendorPassword({
                  variables: {
                    originalPassword,
                    newPassword,
                    newPasswordConfirmation,
                  },
                }).catch((e) => {
                  console.log(e);
                });
              }}
            >
              <FieldGroup title="Change password">
                <VStack width="full" spacing="6">
                  <Input
                    className="form-control form-control-lg"
                    type="password"
                    placeholder={getConstToLanguage("PASSWORD")}
                    value={originalPassword}
                    onChange={(e) => setOriginalPassword(e.target.value)}
                  />
                  <Input
                    className="form-control form-control-lg"
                    placeholder={getConstToLanguage("NEW_PASSWORD")}
                    value={newPassword}
                    type="password"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <Input
                    className="form-control form-control-lg"
                    placeholder={getConstToLanguage(
                      "NEW_PASSWORD_CONFIRMATION"
                    )}
                    value={newPasswordConfirmation}
                    type="password"
                    onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                  />
                </VStack>
              </FieldGroup>

              <fieldset className="form-group pull-xs-right">
                <Button
                  type="submit"
                  variant="cloud-management-os.primary"
                  disabled={
                    loading ||
                    checkNewPasswordAndNewPasswordConfirmation(
                      newPassword,
                      newPasswordConfirmation
                    )
                  }
                >
                  {getConstToLanguage("SUBMIT")}
                </Button>
              </fieldset>
            </form>
          </div>
        );
      }}
    </Mutation>
  );
}

function UserSettings() {
  return (
    <Stack spacing="4" divider={<StackDivider />}>
      <CurrentVendor />
      <UpdatePassword />
    </Stack>
  );
}

export default UserSettings;
