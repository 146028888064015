import { useMutation } from "@apollo/client";
import { LoginAsMarketUserGraphQLMutation } from "../../../../carbonext/graph/mutations/loginAsMarketUser";
import gql from "graphql-tag";

export function useLoginAsMarketUser(requestedAttrs) {
    let loginAsMarketUserGraphQLMutation = new LoginAsMarketUserGraphQLMutation();
    return useMutation(
        gql`
            ${loginAsMarketUserGraphQLMutation.build({ requestedAttrs })}
        `
    );
}
