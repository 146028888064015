import PropTypes from "prop-types";
import React from "react";
import {
    FixedText,
    Button,
    Divider,
    Container,
    Box,
    Center,
    Circle,
    Image,
    SimpleGrid,
    Text,
    Link,
    Flex,
    HStack,
    VStack,
    Grid,
    GridItem,
} from "../../stories/Primatives";

/**
 * Primary UI component for user interaction
 */
export const TextWithLink = ({
    title,
    url,
    urlText,
    firstLetter,
    ...props
}) => {
    let titleColor, titleSize, pSize, width, rowsHeight, firstLetterSize;
    titleColor = props.color ? props.color : "black";
    titleSize = 28;
    firstLetterSize = 125;
    pSize = 22;
    width = "100%";
    rowsHeight = [130, 120, 5];

    const FirstLetter = ({ letter }) => {
        return (
            <Text
                as={"h3"}
                fontSize={firstLetterSize}
                fontFamily={"Montserrat"}
                fontWeight={600}
                color={"brand.300"}
                lineHeight={"normal"}
            >
                {letter}
            </Text>
        );
    };

    return (
        <Container maxW="2xl">
            <Grid gap={4} templateColumns={"repeat(1, 1fr)"} height={"100%"}>
                <GridItem minH={rowsHeight[0]}>
                    <Divider
                        variant={"black"}
                        w={{ base: "100%", lg: "80%" }}
                        mb={4}
                    />
                    <HStack>
                        {firstLetter ? (
                            <FirstLetter letter={firstLetter} />
                        ) : null}
                        <Text
                            as={"h3"}
                            fontFamily={"QuicheText"}
                            fontSize={titleSize}
                            fontWeight={400}
                            lineHeight={1.36}
                            letterSpacing={-0.56}
                            color={titleColor}
                            textTransform={"uppercase"}
                        >
                            {title}
                        </Text>
                    </HStack>
                </GridItem>
                <GridItem>
                    <Text
                        fontFamily={"Montserrat"}
                        fontSize={pSize}
                        lineHeight={1.45}
                        color={"grey.600"}
                    >
                        {props.children}
                    </Text>
                </GridItem>

                <GridItem
                    pt={3}
                    alignSelf={"end"}
                    minH={rowsHeight[2]}
                    maxW="md"
                >
                    <Link
                        href={url}
                        fontFamily={"Quicksand"}
                        fontSize={22}
                        fontWeight={"normal"}
                        color={"brand.500"}
                    >
                        {urlText}
                    </Link>
                </GridItem>
            </Grid>
        </Container>
    );
};

TextWithLink.propTypes = {
    /** **/
    /**
     * Image src
     */
    src: PropTypes.string.isRequired,
    /**
     * Title
     */
    title: PropTypes.string.isRequired,
    /**
     * Link
     */
    url: PropTypes.string,

    /**
     * Link text
     */
    urlText: PropTypes.string,
};

TextWithLink.defaultProps = {
    title: "",
    url: undefined,
    urlText: "",
};
