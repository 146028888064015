import React, { useState, useCallback } from "react";
import {
    Box,
    IconButton,
    VStack,
    Grid,
    Input,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from "../../Primatives";
import { UnlockIcon, LockIcon } from "@chakra-ui/icons";
import { getConstToLanguage } from "../ConstToLanguageMapping";
import { EditableKeyValue } from "./EditableKeyValue";
import { useYupValidationResolver } from "../hooks/useYupValidationResolver";
import { useForm } from "react-hook-form";
import { isApolloError } from "@apollo/client";
import { ConfirmationModal } from "../ConfirmationModal";
const SmartForm = ({
    children,
    validationSchema,
    mutation,
    needsConfirmation,
    confirmationBody,
    cancelFunc,
}) => {
    const resolver = useYupValidationResolver(validationSchema);
    const [errorMessage, setErrorMessage] = useState();
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting, isValid },
    } = useForm({
        resolver,
        mode: needsConfirmation ? "onTouched" : "onSubmit",
    });

    const { isOpen, onOpen, onClose } = useDisclosure();

    function handleUserInputErrors(error, handlers) {
        error.graphQLErrors.forEach((graphQLError) => {
            const errorCodes = getErrorCodes(graphQLError);

            // errorCodes?.forEach((code) => {
            //   const handler = handlers[code];
            //   if (handler !== undefined) {
            //     handler();
            //   }
            // });
            // }
        });
    }

    function getErrorCodes(graphQLError) {
        const receivedErrorCodes = graphQLError?.extensions?.exception?.codes;
        // if (!isUserInputErrorCodeArray(receivedErrorCodes)) {
        //   return null;
        // }
        return receivedErrorCodes;
    }

    function handleErrors(error) {
        console.log("ERRORSS", error);
        // if (canHandleErrors(error, errorProperties)) {
        const handlers = {
            // [UserInputErrorCode.EmailExists]: handleEmailExistsError,
            // [UserInputErrorCode.InvalidPassword]: handleInvalidPasswordError,
        };
        // setMutationError(error);
        // handleUserInputErrors(error, handlers);
    }

    const onSubmit = async (variables) => {
        try {
            const result = await mutation({ variables });
            // reset();
            setErrorMessage(":SDKJLFHLSDJKHF");
        } catch (e) {
            let messageShown = false;

            console.log(e);

            if (isApolloError(e)) {
                e.graphQLErrors.forEach((gqlError) => {
                    // console.log(":SDJF:SLDKJF:LSDKJF:LKSJD:FLKSD");
                    setErrorMessage(gqlError.message);
                    // if (gqlError.extensions?.code === "BAD_USER_INPUT") {
                    // if (Array.isArray(gqlError.extensions?.errors)) {
                    //   console.log("SD:LKFJS:DLKFJ:LSDKJF:LKSDJ:SKDL");
                    //   setErrorMessage(gqlError.message);

                    //   // for (const fieldError of gqlError.extensions.errors) {
                    //   //   setError(fieldError.property, {
                    //   //     message: fieldError.message,
                    //   //   });
                    //   // }

                    messageShown = true;
                    // }
                    // }
                });
                console.log("asldjf;alksdjf;lkasjd;");
            }

            if (!messageShown) {
                setErrorMessage("An error occurred.");
            }
        }
    };

    let sumbitButton = needsConfirmation ? (
        <Button
            mt={4}
            colorScheme="teal"
            isLoading={isSubmitting}
            onClick={onOpen}
            disabled={!isValid}
            variant={"cloud-management-os.primary"}
        >
            {getConstToLanguage("SUBMIT")}
        </Button>
    ) : (
        <Button
            mt={4}
            colorScheme="teal"
            isLoading={isSubmitting}
            onClick={onOpen}
            variant={"cloud-management-os.primary"}
        >
            {getConstToLanguage("SUBMIT")}
        </Button>
    );

    return (
        <Box>
            {errorMessage && <p>{errorMessage}</p>}
            <form onSubmit={handleSubmit(onSubmit)}>
                {React.Children.map(children, (child) => {
                    return (
                        <FormControl isInvalid={errors[child.props.name]}>
                            {child.props.name
                                ? React.createElement(child.type, {
                                      ...{
                                          ...child.props,
                                          register,
                                          mode: "edit",
                                          key: child.props.name,
                                      },
                                  })
                                : child}
                            <FormErrorMessage>
                                {errors[child.props.name] &&
                                    errors[child.props.name].message}
                            </FormErrorMessage>
                        </FormControl>
                    );
                })}
                <Flex direction={"horizontal"} justify="flex-end">
                    {sumbitButton}
                    {cancelFunc && (
                        <Button
                            mt={4}
                            colorScheme="teal"
                            onClick={cancelFunc}
                            variant={"cloud-management-os.secondary"}
                        >
                            {getConstToLanguage("CANCEL")}
                        </Button>
                    )}
                </Flex>
                {needsConfirmation && (
                    <ConfirmationModal
                        onOpen={onOpen}
                        isOpen={isOpen}
                        confirmationBody={confirmationBody}
                        mutation={handleSubmit(onSubmit)}
                        onClose={onClose}
                        isSubmitting={isSubmitting}
                    />
                )}
            </form>
        </Box>
    );
};

export { SmartForm, EditableKeyValue };
