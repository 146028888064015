import React, { useState } from "react";
import {
  Button,
  Grid,
  Heading,
  FormControl,
  Input,
  Flex,
  FormLabel,
  FormErrorMessage,
} from "../../../Primatives";
import { Formik, Form, Field } from "formik";
import { getConstToLanguage } from "../../ConstToLanguageMapping";
import {
  NewPasswordInput,
  PasswordConfirmationInput,
  runAllPasswordRules,
} from "../../../../components/UtilityComponents/PasswordChecker";
import MutationResult from "../../Apollo/MutationResult";

import * as Yup from "yup";

const UpdatePasswordSchema = Yup.object().shape({
  originalPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .min(8, "Too Short!")
    .required("Required")
    .matches(/[0-9]/, "Must include a number")
    .matches(/[!+=@#$%^&*,.;]/, "Must include a special character")
    .matches(/[A-Z]/, "Must include a capital letter"),
  newPasswordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref("newPassword")],
      "Confirmation password must match new password"
    )
    .required("Required"),
});

const fullValidatorForSchema = (schema) => (values) =>
  schema
    .validate(values, {
      abortEarly: false,
      strict: false,
    })
    .then(() => ({}))
    .catch(({ inner }) =>
      inner.reduce(
        (memo, { path, message }) => ({
          ...memo,
          [path]: (memo[path] || []).concat(message),
        }),
        {}
      )
    );

export const UserPasswordUpdate = React.memo(
  ({ updatePasswordMutation, loading }) => {
    let [errors, setErrors] = useState(null);
    return (
      <MutationResult errors={errors}>
        <Heading as="h2" size="md" marginBottom={4}>
          {getConstToLanguage("UPDATE_PASSWORD")}
        </Heading>
        <Formik
          initialValues={{
            newPassword: "",
            originalPassword: "",
            newPasswordConfirmation: "",
          }}
          validationSchema={UpdatePasswordSchema}
          //   validate={fullValidatorForSchema(UpdatePasswordSchema)}
          onSubmit={async (values, actions) => {
            try {
              let result = await updatePasswordMutation({
                variables: values,
              });
            } catch (e) {
              setErrors(e.graphQLErrors);
            }
          }}
        >
          {(formik) => (
            <Form>
              <Field name="originalPassword">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.originalPassword &&
                      form.touched.originalPassword
                    }
                    marginBottom={6}
                  >
                    <Input
                      {...field}
                      placeholder={getConstToLanguage("ORIGINAL_PASSWORD")}
                      type="password"
                    />
                    <FormErrorMessage>
                      {form.errors.originalPassword}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="newPassword">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.newPassword && form.touched.newPassword
                    }
                    marginBottom={1}
                  >
                    <Input
                      type="password"
                      {...field}
                      placeholder={getConstToLanguage("NEW_PASSWORD")}
                    />
                    <FormErrorMessage>
                      {form.errors.newPassword}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="newPasswordConfirmation">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.newPasswordConfirmation &&
                      form.touched.newPasswordConfirmation
                    }
                    marginBottom={6}
                  >
                    <Input
                      {...field}
                      type="password"
                      placeholder={getConstToLanguage(
                        "NEW_PASSWORD_CONFIRMATION"
                      )}
                    />
                    <FormErrorMessage>
                      {form.errors.newPasswordConfirmation}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Flex justifyContent={"end"}>
                <Button
                  type="submit"
                  isLoading={loading}
                  disabled={!(formik.isValid && formik.dirty)}
                  variant={"cloud-management-os.primary"}
                >
                  {getConstToLanguage("UPDATE_PASSWORD")}
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </MutationResult>
    );
  }
);
