import React from "react";
import { Grid, GridItem, Flex } from "../../stories/Primatives"

//function mainTemplate1Header() { }

function mainTemplate1(sections, hasRunningMutations) {
  return (
    <Grid templateRows="4rem 2px 1fr" maxW="100%" h="100%">
      <Flex>
        {sections.header}
      </Flex>
      <GridItem>{hasRunningMutations ? (
        <div className="backend-async-mutation-running"></div>
      ) : null}</GridItem>
      <GridItem h="100vh">
        {sections.body}
      </GridItem>
    </Grid>
  );
}

const MainTemplate1BodyTemplate1Header = ({ left, middle, right, paddingLeft, templateColumns = "1fr 1fr 1fr" }) => {
  return (
    <Grid templateColumns={templateColumns} paddingLeft={paddingLeft}>
      <GridItem align="left">{left}</GridItem>
      <GridItem align="center">{middle}</GridItem>
      <GridItem align="right">{right}</GridItem>
    </Grid>

  );
}

function mainTemplate1BodyTemplate1Body(body) {
  return <div className="main__template_1__body_template_1__body">{body}</div>;
}

function mainTemplate1BodyTemplate1(sections) {
  return (
    <div className="main__template_1__body_template_1">
      {sections.header}
      {sections.body}
    </div>
  );
}

export {
  mainTemplate1,
  mainTemplate1BodyTemplate1,
  MainTemplate1BodyTemplate1Header,
  mainTemplate1BodyTemplate1Body
};
