import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../lib/GraphCallBuilder";

function CreateGemRequestGraphQLMutation() {
    return new GraphqlQueryBuilder({
        api: "createGemRequest",
        type: "mutation",
        args: [new GQLInputArg("input", "GemRequestInput", true)],
    });
}

export { CreateGemRequestGraphQLMutation };
