import React from "react";


const ErrorHandler = props => {
  return (
    <div className="resp-errors">
      <ul>
        {props.graphError &&
          props.graphError.graphQLErrors.map(({ message }, i) => (
            <li key={i}>{message}</li>
          ))}
      </ul>
    </div>
  );
};
export default ErrorHandler;
