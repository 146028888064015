import { useMutation } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";
import React from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Loader,
    LoadingButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useDisclosure,
    VStack,
    Input,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from "../../../../Primatives";
import { getConstToLanguage } from "../../../ConstToLanguageMapping";
import { useCurrentUser } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

const BankAccountInfoSchema = Yup.object().shape({
    routingNumber: Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(9, "Must be exactly 9 digits")
        .max(9, "Must be exactly 9 digits"),
    accountNumber: Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Must be only digits")
        .max(17, "Must be a max of 17 digits"),
});

const AddOrUpdatePayableBankAccountForm = ({}) => {
    const {
        handleSubmit,
        register,

        formState: { errors, isSubmitting },
    } = useForm({ resolver: yupResolver(BankAccountInfoSchema) });

    const onSubmit = (data) => console.log(data);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors && Object.keys(errors).length > 0}>
                <FormLabel htmlFor="routingNumber">
                    {getConstToLanguage("BANK_ROUTING_NUMBER")}
                </FormLabel>
                <Input
                    id="routingNumber"
                    placeholder="routingNumber"
                    {...register("routingNumber")}
                />
                <FormErrorMessage>
                    {errors.routingNumber && errors.routingNumber.message}
                </FormErrorMessage>
                <FormLabel htmlFor="accountNumber">
                    {getConstToLanguage("BANK_ACCOUNT_NUMBER")}
                </FormLabel>
                <Input
                    id="accountNumber"
                    placeholder="accountNumber"
                    {...register("accountNumber")}
                />
                <FormErrorMessage>
                    {errors.accountNumber && errors.accountNumber.message}
                </FormErrorMessage>
            </FormControl>
            {/* <input {...register("routingNumber")}></input>
      <input {...register("accountNumber")}></input> */}
            <Input type="submit" />
        </form>
    );
};

export const AddOrUpdatePayableBankAccount = ({}) => {
    return (
        <Box spacing={3}>
            <AddOrUpdatePayableBankAccountForm />
        </Box>
    );
};
