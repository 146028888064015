import React from 'react';
import RelativeGemListingSearchJobs from './RelativeGemListingSearchJobs'
import { connect } from 'react-redux';
import {
} from '../../../../constants/actionTypes';

const Promise = global.Promise;

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

class RelativeGemListingHistory extends React.Component {

  render() {

    return (<div><RelativeGemListingSearchJobs/></div>)

    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelativeGemListingHistory);