import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    Stack,
    Grid,
    VStack,
    PopoverOrModal,
    PopoverFooter,
    useBreakpointValue,
    Avatar,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from "../Primatives";

import PropTypes from "prop-types";
import React, { useContext, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useScrollYPosition } from "react-use-scroll-position";
import { CloseIcon, MenuIcon } from "../../components/IconsV2";
import { CurrentMarketUserContext } from "../../components/UtilityComponents/MarketOS/context";
import { Logo } from "../Branding/Icons/Logo";
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
import { getCurrentViewPortHeight } from "../CloudManagementOS/helpers/MediaQuery";
import { CurrentMarketUserNav } from "../Primatives/CurrentMarketUserNav";
import { LOGIN_AS_MARKET_USER } from "../../graph/mutations/user/marketUser";
import { Link as ReachLink } from "@reach/router";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import DesignSystem from "../../DesignSystem";

/**
 * Primary UI component for user interaction
 */

const MenuItem = ({
    children,
    isLast,
    to = "/",
    onClickMenuItem,
    color,
    ...rest
}) => {
    let currentScroll = useScrollYPosition();

    console.log("MDMMDNSSSUSUUS", onClickMenuItem);

    return (
        <Center color={color}>
            <Text
                padding={2}
                borderBottom="2px"
                borderColor="transparent"
                onClick={onClickMenuItem}
                _hover={{
                    borderBottom: "2px",
                    borderColor: currentScroll > 0 ? "brand.500" : "white",
                }}
                {...rest}
            >
                <Link to={to}>{children}</Link>
            </Text>
        </Center>
    );
};

const MyAccountSelection = ({
    fontWeight,
    children,
    linkTo,
    postClickFunc,
}) => {
    return (
        <Box
            w="100%"
            h="100%"
            fontWeight={fontWeight}
            padding={3}
            _hover={{
                bg: "dark-gold-opacity.200",
            }}
            onClick={() => postClickFunc()}
        >
            <Link to={linkTo}>
                <Box w="100%" h="100%">
                    {children}
                </Box>
            </Link>
        </Box>
    );
};

const PATHS_TO_AUTO_ROUTE_BACK_TO_HOME = {
    "/market/sign-up": 1,
    "/market/login": 1,
};

const MyAccount = ({ postClickFunc }) => {
    let currentScroll = useScrollYPosition();
    const variant = useBreakpointValue({ base: "modal", lg: "popover" });
    const { t } = useTranslation();
    const location = useLocation();

    let postClickFuncWrapped = useCallback(() => {
        console.log("SDL:KFJ:LSKDJF:LSDKJF:SDLKJF:SDKLJF:SD");
        // onClose();
        postClickFunc();
    }, []);

    let pathToRouteBack =
        location.pathname in PATHS_TO_AUTO_ROUTE_BACK_TO_HOME
            ? "/"
            : location.pathname;

    return (
        <PopoverOrModal
            variant={variant}
            trigger="click"
            placement="bottom-end"
            isLazy
            lazyBehavior={"keepMounted"}
            popoverWidth="xs"
            paddingBody={0}
            triggerBody={
                <Button
                    size="sm"
                    fontSize={"sm"}
                    variant={
                        currentScroll > 0
                            ? "market.primary"
                            : "market.primary.white"
                    }
                    h={10}
                    colorScheme="primary-button"
                    p="0 1.13rem"
                >
                    <HStack>
                        <Avatar size="2xs" bg="brand.500" />
                        <Box>{t("MY_ACCOUNT")}</Box>
                    </HStack>
                </Button>
            }
            body={
                <VStack color={"brand.500"}>
                    <MyAccountSelection
                        fontWeight={"bold"}
                        postClickFunc={postClickFuncWrapped}
                        linkTo={"/market/sign-up"}
                    >
                        {t("SIGN_UP")}
                    </MyAccountSelection>
                    <MyAccountSelection
                        linkTo={`/market/login?redirect=${pathToRouteBack}`}
                        postClickFunc={postClickFuncWrapped}
                    >
                        {t("LOGIN")}
                    </MyAccountSelection>

                    <Divider />
                    <MyAccountSelection
                        linkTo={"/vendor/login"}
                        postClickFunc={postClickFuncWrapped}
                    >
                        {t("SELL_ON_CARBONEXT")}
                    </MyAccountSelection>
                </VStack>
            }
        />
    );
};

const RenderCurrentMarketUser = ({
    iconography,
    colorMode,
    bg,
    color,
    menuVariant,
    postClickFunc,
    showCurrentShoppingBagAlways = true,
    ...props
}) => {
    const { currentMarketUser, logout } = useContext(CurrentMarketUserContext);

    if (colorMode) {
        if (colorMode === "dark") {
            bg = bg ? bg : "white";
            color = color ? color : "off-black";
        } else {
            bg = bg ? bg : "off-black";
            color = color ? color : "white";
        }
    }

    let showShowShoppingBag = false;
    if (showCurrentShoppingBagAlways) {
        showShowShoppingBag = true;
    }

    let currentSalesOrder = currentMarketUser?.currentSalesOrder;
    let items = currentSalesOrder && currentSalesOrder?.items;

    //  ------------------------ begin construction ------------------------------
    let constructedResult = [];

    if (currentMarketUser) {
        constructedResult.push(
            <CurrentMarketUserNav
                color={menuVariant === "full" ? color : "white"}
                shoppingCartColor={menuVariant === "full" ? color : "white"}
                icon={iconography.SHOPPING_CART}
                currentMarketUser={currentMarketUser}
                logout={logout}
                removeItemFunc={props.removeItemFunc}
            />
        );
    } else {
        constructedResult.push(<MyAccount postClickFunc={postClickFunc} />);
    }

    return constructedResult;
};

const FlatMenuOrDrawer = ({
    menuVariant,
    body,
    button,
    isOpen,
    onOpen,
    onClose,
}) => {
    const btnRef = React.useRef();
    if (menuVariant === "full") {
        return body;
    } else {
        return (
            <>
                <Box ref={btnRef} colorScheme="teal" onClick={onOpen}>
                    {button}
                </Box>

                <Drawer
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}
                    finalFocusRef={btnRef}
                    color="white"
                >
                    <DrawerOverlay />
                    <DrawerContent bg={"off-black"}>
                        <DrawerBody padding={6}>{body}</DrawerBody>
                    </DrawerContent>
                </Drawer>
            </>
        );
    }
};

const MenuItems = ({
    menuVariant,
    isOpen,
    onOpen,
    onClose,
    logout,
    getColorFromMenuVariant,
    currentMarketUser,
    color,
    colorMode,
    iconography,
    removeItemFunc,
}) => {
    let { t } = useTranslation();
    return (
        <Stack
            align={menuVariant === "slide-in" ? "center" : "none"}
            justify={menuVariant === "slide-in" ? "center" : "flex-end"}
            sp={menuVariant === "slide-in" ? "center" : "flex-end"}
            direction={menuVariant === "slide-in" ? "column" : "row"}
        >
            <MenuItem
                color={getColorFromMenuVariant()}
                to="/about-us"
                onClickMenuItem={() => onClose()}
            >
                About Us
            </MenuItem>
            <MenuItem
                color={getColorFromMenuVariant()}
                to="/market/diamonds"
                onClickMenuItem={() => onClose()}
            >
                Our Diamonds
            </MenuItem>
            <MenuItem
                color={getColorFromMenuVariant()}
                to="/vendor/sign-up"
                onClickMenuItem={() => onClose()}
            >
                Become a Vendor
            </MenuItem>
            <MenuItem
                color={getColorFromMenuVariant()}
                to="/contact-us"
                onClickMenuItem={() => onClose()}
            >
                {t("CONTACT_US")}
            </MenuItem>
            <Divider orientation="vertical" color="white" />
            <HStack spacing={4}>
                <RenderCurrentMarketUser
                    postClickFunc={() => onClose()}
                    currentMarketUser={currentMarketUser}
                    pos="relative"
                    logOut={logout}
                    colorMode={colorMode}
                    color={color}
                    menuVariant={menuVariant}
                    iconography={iconography}
                    removeItemFunc={removeItemFunc}
                />
            </HStack>
        </Stack>
    );
};

export const Nav = ({
    bg,
    slideInMenuStyling,
    colorMode,
    boxShadow,
    slideInMenuColor,
    logOut,
    color,
    iconography,
    menuVariant = "full",
    removeItemFunc,
    ...props
}) => {
    const { currentMarketUser, logout } = useContext(CurrentMarketUserContext);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toggleMenu = () => (isOpen ? onClose() : onOpen());

    const getColorFromMenuVariant = () => {
        return menuVariant === "slide-in" ? slideInMenuStyling.color : color;
    };

    if (colorMode) {
        if (colorMode === "dark") {
            bg = bg ? bg : "white";
            color = color ? color : "off-black";
        } else {
            bg = bg ? bg : "off-black";
            color = color ? color : "white";
        }
    }

    if (!bg) {
        bg = "off-black";
    }
    if (!color) {
        color = "white";
    }

    console.log("nav remove", props.removeItemFunc);

    let currentSalesOrder = currentMarketUser?.currentSalesOrder;
    let items = currentSalesOrder && currentSalesOrder?.items;

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            h="6.94rem"
            maxW={"100vw"}
            paddingLeft={menuVariant === "slide-in" ? 8 : 16}
            paddingRight={menuVariant === "slide-in" ? 8 : 16}
            bg={bg}
            color={getColorFromMenuVariant()}
            zIndex="docked"
            position="fixed"
            boxShadow={boxShadow ? boxShadow : "md"}
        >
            <Link to="">
                <Flex align="center" w={["14.25rem", "14.25rem", "18.25rem"]}>
                    <Logo logoColor1={color} />
                </Flex>
            </Link>
            <HStack>
                {menuVariant === "slide-in" ? (
                    <DesignSystem.Molecules.CurrentShoppingBagMenuMolecule
                        color={color}
                        items={items}
                        itemCountBgColor="gray.300"
                        itemCountTextColor="black"
                        linkTo={"/market/cart"}
                        icon={iconography.SHOPPING_CART}
                    />
                ) : null}

                <FlatMenuOrDrawer
                    drawerBackground={"black"}
                    menuVariant={menuVariant}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    button={
                        <HStack onClick={toggleMenu}>
                            {isOpen ? null : <MenuIcon color={color} />}
                        </HStack>
                    }
                    body={
                        <MenuItems
                            logout={logout}
                            iconography={iconography}
                            colorMode={colorMode}
                            getColorFromMenuVariant={getColorFromMenuVariant}
                            currentMarketUser={currentMarketUser}
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}
                            menuVariant={menuVariant}
                            removeItemFunc={removeItemFunc}
                        />
                    }
                />
            </HStack>
        </Flex>
    );
};

Nav.propTypes = {
    colorMode: PropTypes.oneOf(["dark", "light"]),
    bg: PropTypes.string,
};

Nav.defaultProps = {};
