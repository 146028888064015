import gql from "graphql-tag";

const GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_NOTIFICATIONS = gql`
query GetGlobalStatsFromSearchOfNotifications {
	getGlobalStatsFromSearchOfNotifications(searchParam: {filter: {unseenPlusXAmount: 5}}) {
    counts {
      total
    }
  }
}
`;

export { GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_NOTIFICATIONS };
