import gql from "graphql-tag";

const GQL_M_AUTHENTICATE_BILL_DOT_COM_STAGING_SESSION_ID = gql`
  mutation AuthenticateBillDotComStagingSessionId($mfaToken: String!) {
    authenticateBillDotComStagingSessionId(mfaToken: $mfaToken) {
      type
      message
      status
      title
      description
    }
  }
`;

export { GQL_M_AUTHENTICATE_BILL_DOT_COM_STAGING_SESSION_ID };
