import { useMutation } from "@apollo/react-hooks";
import { Flex, FormLabel } from "@chakra-ui/react";
import gql from "graphql-tag";
import React, { useState } from "react";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { Button, Checkbox } from "../../../../stories/Primatives";
import { SuccessResponse } from "../../../../stories/Primatives/SuccessResponse";
import { GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS } from "../../../Vendor/graph/queries/MutationAudit/listCurrentlyRunningVendorMutations";

const UPLOAD_GEM_LISTING_INVENTORY_CSVS = gql`
    mutation(
        $csvs: [Upload]!
        $options: UploadCertifiedGemListingInventoryOptionsInput
    ) {
        uploadGemListingInventoryCSVs(csvs: $csvs, options: $options) {
            id
            state
            mutation
        }
    }
`;

const UploadGemListingInventoryCSVs = (props) => {
    const [csvs, setCSVs] = useState(null);
    const [
        autoSetPriceAgainstRapnetPricing,
        setAutoSetPriceAgainstRapnetPricing,
    ] = useState(true);

    const [
        autoSubmitAllGemListingsForVerification,
        setAutoSubmitAllGemListingsForVerification,
    ] = useState(true);

    const updateCache = async (cache, { data }) => {
        // Fetch the todos from the cache

        const existingRunningMutations = cache.readQuery({
            query: GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS,
        });

        console.log("HEHHEHEHRHEHHEHHERHEHHRHEHRHEHRHEHRHEHRHEHR");
        console.log(existingRunningMutations);
        console.log(data);

        let newListOfRunningMutations = existingRunningMutations.listCurrentUsersRunningMutations.slice();

        newListOfRunningMutations.unshift(data.uploadGemListingInventoryCSVs);

        console.log(newListOfRunningMutations);
        console.log({
            ...existingRunningMutations,
            listCurrentUsersRunningMutations: [
                data.uploadGemListingInventoryCSVs,
                ...existingRunningMutations.listCurrentUsersRunningMutations,
            ],
        });

        // let newListOfGemListings = data.uploadGemListingInventoryCSVs.create;

        // console.log(existingGemListingsInState);
        await cache.writeQuery({
            query: GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS,
            data: {
                ...existingRunningMutations,
                listCurrentUsersRunningMutations: [
                    data.uploadGemListingInventoryCSVs,
                    ...existingRunningMutations.listCurrentUsersRunningMutations,
                ],
            },
        });
    };

    const [
        uploadGemListingInventoryCSVs,
        { data, loading, error },
    ] = useMutation(UPLOAD_GEM_LISTING_INVENTORY_CSVS, {
        context: {
            headers: {
                "apollo-require-preflight": true,
            },
        },
        update: updateCache,
    });

    return data ? (
        <SuccessResponse />
    ) : (
        <form
            method="post"
            encType="multipart/form-data"
            onSubmit={(e) => {
                e.preventDefault();

                uploadGemListingInventoryCSVs({
                    variables: {
                        csvs,
                        options: {
                            autoSubmitAllGemListingsForVerification,
                            autoSetPriceAgainstRapnetPricing,
                        },
                    },
                });
            }}
        >
            <div className="gem-listing-file-upload__choose-file">
                <FormLabel htmlFor="file">Choose file to upload</FormLabel>
                <input
                    type="file"
                    accept=".csv"
                    id="files"
                    onChange={({ target: { files } }) => setCSVs(files)}
                />
            </div>
            <div className="gem-listing-file-upload__options">
                <FormLabel>Options:</FormLabel>
                <Flex direction="column">
                    <Flex>
                        <Checkbox
                            size="md"
                            name="autoSubmitForVerfication"
                            colorScheme={"checkbox-gold"}
                            isChecked={autoSubmitAllGemListingsForVerification}
                            onChange={(e) =>
                                setAutoSubmitAllGemListingsForVerification(
                                    e.target.checked
                                )
                            }
                        >
                            Submit all for verification
                        </Checkbox>
                    </Flex>

                    <Flex>
                        <Checkbox
                            size="md"
                            colorScheme={"checkbox-gold"}
                            name="useRapnetPricing"
                            isChecked={autoSetPriceAgainstRapnetPricing}
                            onChange={(e) =>
                                setAutoSetPriceAgainstRapnetPricing(
                                    e.target.checked
                                )
                            }
                        >
                            Automatically set price based on rapnet
                        </Checkbox>
                    </Flex>
                </Flex>
            </div>
            <Button
                type="submit"
                variant={"cloud-management-os.primary"}
                isDisabled={loading || !csvs || csvs.length === 0}
                w="100%"
            >
                {getConstToLanguage("SUBMIT")}
            </Button>
        </form>
    );
};

export default UploadGemListingInventoryCSVs;
