import React, { useContext } from "react";
import {
    Box,
    GridShowcase,
    Input,
    VStack,
    Flex,
    Textarea,
    Center,
    Button,
    Heading,
    FormControl,
    FormErrorMessage,
    SpinnerButton,
} from "../../../stories/Primatives";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Link } from "react-router-dom";

import { PhoneIcon, EmailIcon, MapLocationIcon } from "../../IconsV2";
import TwoPanelShell from "../../../stories/CloudManagementOS/shells/TwoPanelShell";
import { MultiSectionFlexDirection } from "../../../stories/CloudManagementOS/shells/MultiSectionFlexDirection";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useSubmitMutation from "../../../stories/CloudManagementOS/hooks/useSubmitMutation";
import * as Yup from "yup";
import qs from "qs";
import { CreateContactUsGraphQLMutation } from "../../../api_gateway/carbonext/graph/mutations/createContactUs";
import "yup-phone";
import { CurrentMarketUserContext } from "../../UtilityComponents/MarketOS/context";
import { GQLFragment } from "../../../lib/GraphCallBuilder";
import DesignSystem from "../../../DesignSystem";

const ContactUsForPublicActorFormSchema = Yup.object().shape({
    fullName: Yup.string()
        .required("First name is a required field.")
        .min(2, "First Name must be at least 2 characters."),
    email: Yup.string()
        .required("Email is a required field.")
        .email()
        .min(2, "Email must be at least 2 characters."),
    phoneNumber: Yup.string()
        .default("+1")
        .required("Phone Number is a required field.")
        .phone(),
    salesOrderId: Yup.string(),
    message: Yup.string().required("Message is a required field."),
});

const ContactUsForMarketUserFormSchema = Yup.object().shape({
    salesOrderId: Yup.string(),
    message: Yup.string().required("Message is a required field."),
});

const InputWithBorder = ({ label, inputType, register, errors, formKey }) => {
    let input;
    let flexDirection;
    let labelContent;

    if (inputType == "textarea") {
        labelContent = (
            <Box fontWeight={"600"} textTransform="uppercase">
                {label}
            </Box>
        );

        input = (
            <Textarea
                w="100%"
                _focus={{
                    outline: "hidden",
                }}
                borderTopStyle={"hidden"}
                borderRightStyle="hidden"
                borderLeftStyle="hidden"
                borderBottomStyle="hidden"
                {...register(formKey)}
            />
        );

        flexDirection = "column";
    } else {
        flexDirection = "row";
        labelContent = (
            <Center fontWeight={"600"} textTransform="uppercase">
                {label}
            </Center>
        );
        input = (
            <Input
                w="100%"
                _focus={{
                    outline: "hidden",
                }}
                borderTopStyle={"hidden"}
                borderRightStyle="hidden"
                borderLeftStyle="hidden"
                borderBottomStyle="hidden"
                {...register(formKey)}
            ></Input>
        );
    }
    return (
        <Flex
            borderBottom={"1px solid black"}
            direction={flexDirection}
            w="100%"
        >
            {labelContent}
            <FormControl isInvalid={errors[formKey]}>
                {input}
                <FormErrorMessage>
                    {errors[formKey] && errors[formKey].message}
                </FormErrorMessage>
            </FormControl>
        </Flex>
    );
};

const ContactUsForm = ({}) => {
    const { t } = useTranslation();

    let createContactUsGraphQLMutation = new CreateContactUsGraphQLMutation();

    let [contactUsMutation] = useMutation(
        gql`
            ${createContactUsGraphQLMutation.build({
                requestedAttrs: [
                    new GQLFragment("result", ["message", "title"]),
                ],
            })}
        `
    );
    let queryParams = qs.parse(useLocation().search, {
        ignoreQueryPrefix: true,
    });

    let { currentMarketUser } = useContext(CurrentMarketUserContext);

    let {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: currentMarketUser
            ? ContactUsForMarketUserFormSchema.cast({
                  salesOrderId: queryParams.salesOrderId,
              })
            : ContactUsForPublicActorFormSchema.cast({
                  salesOrderId: queryParams.salesOrderId,
              }),
        resolver: yupResolver(
            currentMarketUser
                ? ContactUsForMarketUserFormSchema
                : ContactUsForPublicActorFormSchema
        ),
    });

    const contactUs = React.useCallback(
        async (variables) => {
            return await contactUsMutation({
                variables: {
                    ...variables,
                    source: "CONTACT_US",
                },
            });
        },
        [contactUsMutation]
    );
    let { onSubmit, loading, error, data } = useSubmitMutation(contactUs);

    return (
        <DesignSystem.Molecules.GraphQLResultResponseMolecule
            error={error}
            result={data?.createContactUs?.result}
            initialBody={
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack
                        align={"left"}
                        spacing={6}
                        padding={{ base: 6, lg: 20 }}
                    >
                        <Heading variant={"secondary"} size="lg">
                            {t("common:CONTACT_US")}
                        </Heading>
                        <Box fontSize={"sm"}>
                            {t("marketing:CONTACT_US_INSTRUCTIONS_MSG")}
                        </Box>
                        <VStack w="100%" spacing={4}>
                            {!currentMarketUser && (
                                <InputWithBorder
                                    label={t("common:NAME")}
                                    formKey={"fullName"}
                                    errors={errors}
                                    register={register}
                                    // {...register("fullName")}
                                />
                            )}
                            <InputWithBorder
                                label={t("common:SALES_ORDER_ID")}
                                formError={errors?.email}
                                formKey={"salesOrderId"}
                                errors={errors}
                                register={register}
                            />
                            {!currentMarketUser && (
                                <InputWithBorder
                                    label={t("common:EMAIL")}
                                    formError={errors?.email}
                                    formKey={"email"}
                                    errors={errors}
                                    register={register}
                                />
                            )}
                            {!currentMarketUser && (
                                <InputWithBorder
                                    label={t("common:PHONE")}
                                    formError={errors?.phoneNumber}
                                    formKey={"phoneNumber"}
                                    errors={errors}
                                    register={register}
                                />
                            )}

                            <InputWithBorder
                                formError={errors?.message}
                                label={t("common:MESSAGE")}
                                inputType="textarea"
                                formKey={"message"}
                                errors={errors}
                                register={register}
                            />
                            <Box align="right" w="100%" marginTop={6}>
                                <SpinnerButton
                                    type="submit"
                                    loading={loading}
                                    variant={"market.primary"}
                                >
                                    {t("common:SEND")}
                                </SpinnerButton>
                            </Box>
                        </VStack>
                    </VStack>
                </form>
            }
        />
    );
};

const ReadyToGetStarted = () => {
    const { t } = useTranslation();
    return (
        <Center
            minH={"440px"}
            backgroundSize="cover"
            bgImage="https://carbonext-assests.nyc3.digitaloceanspaces.com/ready_to_start.png"
        >
            <VStack w="100%" align="center" spacing={8}>
                <Heading color="white">
                    {t("marketing:READY_TO_GET_STARTED_MSG")}
                </Heading>
                <Link to="/market/sign-up">
                    <Button variant={"market.secondary"}>
                        {t("common:REGISTER_NOW")}
                    </Button>
                </Link>
            </VStack>
        </Center>
    );
};

const PhoneContact = () => {
    return (
        <Box>
            <PhoneIcon color="brand.500" />
            <VStack>
                <Box>+1 646.265.9224</Box>
                <Box> Mon-Fri 8am - 5pm EST</Box>
            </VStack>
        </Box>
    );
};

const EmailContact = () => {
    return (
        <Box>
            <EmailIcon color="brand.500" />
            <VStack>
                <Box>info@carbonext.io</Box>
            </VStack>
        </Box>
    );
};

const AddressContact = () => {
    return (
        <Box>
            <MapLocationIcon color="brand.500" />
            <VStack as="address" fontStyle={"normal"}>
                <Box>22 West 48th New York</Box>
                <Box>NY 10036</Box>
            </VStack>
        </Box>
    );
};

const ContactUsChannels = () => {
    return (
        <Center w="100%" align="center">
            <MultiSectionFlexDirection
                breakpoint="lg"
                padding={{ base: "24px", lg: "50px" }}
            >
                <PhoneContact />
                <EmailContact />
                <AddressContact />
            </MultiSectionFlexDirection>
        </Center>
    );
};

const ContactUs = ({}) => {
    return (
        <Box>
            <TwoPanelShell
                breakpointValue="md"
                panel1={
                    <Box width={{ base: "100%", sm: "100%" }}>
                        <GridShowcase
                            image1={
                                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/ContactUs_img_03@2x.jpg"
                            }
                            image2={
                                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/ContactUs_img_04@2x.jpg"
                            }
                            image3={
                                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/ContactUs_img_01@3x.jpg"
                            }
                            image4={
                                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/ContactUs_img_02@2x.jpg"
                            }
                        />
                    </Box>
                }
                panel2={<ContactUsForm />}
            />
            <ContactUsChannels />
            <ReadyToGetStarted />
        </Box>
    );
};

export { ContactUs };
