let currentLanguage = null;
let defaultLanguage = "en";
let constToLanguageMap = null;
var _ = require("lodash");

function setCurrentLanguage(language) {
  currentLanguage = language;
}

function setConstToLanguageMap(map) {
  constToLanguageMap = map;
}

const fillTemplate = function (templateString, templateVars) {
  console.log("TEMPLLATEEEEEEEETETE");
  console.log(templateString);
  console.log(templateVars);
  return new Function("return `" + templateString + "`;").call(templateVars);
};

function getConstToLanguage(constant, key, interpolation) {
  let languageToUse = currentLanguage ? currentLanguage : defaultLanguage;
  if (constant == null) {
    return "";
  }
  if (_.isString(constant)) {
    constant = constant.replace(/-/g, "_").toUpperCase();

    if (
      constToLanguageMap &&
      constant &&
      constant in constToLanguageMap &&
      languageToUse in constToLanguageMap[constant]
    ) {
      if (key && key in constToLanguageMap[constant][languageToUse]) {
        return interpolation
          ? fillTemplate(
            constToLanguageMap[constant][languageToUse][key],
            interpolation
          )
          : constToLanguageMap[constant][languageToUse][key];
      }
      return interpolation
        ? fillTemplate(
          constToLanguageMap[constant][languageToUse]["default"],
          interpolation
        )
        : constToLanguageMap[constant][languageToUse]["default"];
    } else {
      return constant;
    }
  } else {
    return constant.toString();
  }
}

module.exports.getConstToLanguage = getConstToLanguage;
module.exports.setConstToLanguageMap = setConstToLanguageMap;
module.exports.setCurrentLanguage = setCurrentLanguage;
