import { useQuery } from "@apollo/react-hooks";
import {
    Button,
    useDisclosure,
    Alert,
    AlertTitle,
    Box,
    Circle,
    Divider,
    Flex,
    Heading,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    VStack,
    HStack,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import PubSub from "pubsub-js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { NotificationIcon } from "../../components/IconsV2";
import { getConstToLanguage } from "./ConstToLanguageMapping";
import { NotificationsContext, SharedFunctionsContext } from "./Context";
import { useAuth } from "./hooks/useCurrentUser";
const SEARCH_NOTIFICATION_GQL_QUERY = gql`
    query SearchNotifications {
        searchNotifications(searchParam: { filter: { unseenPlusXAmount: 5 } }) {
            id
            createdAt
            channels {
                ... on WebNotification {
                    title
                    msg
                    errors {
                        msg
                    }
                }
            }
        }
    }
`;

const NotificationError = ({ error }) => {
    return (
        <Alert status="error">
            <AlertTitle>{error.msg}</AlertTitle>
        </Alert>
    );
};

const NotificationErrorList = ({ errorList }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <VStack align="left">
            <HStack>
                <Box onClick={isOpen ? onClose : onOpen} color={"red.400"}>
                    {errorList.length}{" "}
                    {errorList.length > 1
                        ? getConstToLanguage("ERRORS")
                        : getConstToLanguage("ERROR")}
                </Box>
            </HStack>
            {isOpen &&
                errorList.map((error) => <NotificationError error={error} />)}
        </VStack>
    );
};

const Notification = ({ notification, isUnread }) => {
    return (
        <Flex key={notification.id} bg={isUnread ? "pale-peach" : null}>
            <VStack p={3} align="left">
                <Heading as="h6" size="xs">
                    {notification.channels[0].title}
                </Heading>
                <Box size="xs">{notification.channels[0].msg}</Box>
                <Box size="xs">
                    {DateTime.fromISO(notification.createdAt).toFormat("fff")}
                </Box>
                {notification.channels[0].errors &&
                    notification.channels[0].errors.length > 0 && (
                        <NotificationErrorList
                            errorList={notification.channels[0].errors}
                        />
                    )}
            </VStack>
        </Flex>
    );
};

const NotificationDropdown = ({ setNotificationsToRead }) => {
    const sharedFunctions = React.useContext(SharedFunctionsContext);

    let currentUser = useAuth();
    const { loading, error, data } = useQuery(SEARCH_NOTIFICATION_GQL_QUERY, {
        fetchPolicy: "no-cache",
    });

    let indexOfLastSeenNotification =
        data?.searchNotifications &&
        data.searchNotifications.findIndex(
            (notification) =>
                notification.id ===
                currentUser?.currentUser?.lastSeenWebNotification
        );

    useEffect(
        () =>
            async function () {
                if (
                    currentUser &&
                    data?.searchNotifications.length > 0 &&
                    indexOfLastSeenNotification != 0
                ) {
                    await sharedFunctions.setLastSeenWebNotification(
                        data?.searchNotifications[0].id
                    );

                    await setNotificationsToRead();
                }
            },
        [data, currentUser]
    );

    return (
        <VStack>
            {data?.searchNotifications.map((notification, index) => (
                <Box key={notification.id}>
                    <Notification
                        notification={notification}
                        isUnread={index < indexOfLastSeenNotification}
                    />
                    {index < data?.searchNotifications.length && <Divider />}
                </Box>
            ))}
        </VStack>
    );
};

const NewNotificationCount = ({ notificationEvents, startingCount }) => {
    let totalCount =
        startingCount + (notificationEvents ? notificationEvents.length : 0);

    return totalCount ? (
        <Circle
            size={4}
            pos="absolute"
            bg="off-black"
            top={0}
            right={0}
            fontSize="xs"
            color="white"
        >
            {totalCount}
        </Circle>
    ) : null;
};

export const Notifications = () => {
    let [notificationEvents, setNotificationEvents] = useState([]);

    const setNotificationsToRead = useCallback(() => {
        setNotificationEvents([]);
    });

    const notifications = useContext(NotificationsContext);

    const appendNotificationEvents = (topic, notification) => {
        console.log(
            "NOTFOFIIFICIAAAETIOSDNPFKLDSNFDSF",
            notificationEvents,
            notification
        );
        console.log([...notificationEvents, notification]);

        setNotificationEvents([...notificationEvents, notification]);
    };

    // TODO - jeff - is it the correct way to have to unsubscribe and subscribe on notificationEvents changes?
    useEffect(() => {
        const token = PubSub.subscribe(
            "user:notifications",
            appendNotificationEvents
        );
        return () => PubSub.unsubscribe(token);
    }, [notificationEvents]);

    console.log(
        "Current Notifications Info: NotificationEvents - ",
        notificationEvents,
        "Notification Base Stats",
        notifications?.stats?.counts?.total
    );

    return (
        <Popover placement="bottom-end" isLazy={true} autoFocus={true}>
            {({ isOpen }) => (
                <>
                    <PopoverTrigger>
                        <Button
                            bg={{ sm: "off-black", md: "white" }}
                            width={8}
                            position="relative"
                            _hover={{ bg: "white" }}
                            _active={{ bg: "white" }}
                        >
                            <Circle bg="brand.300" color="white" size={6} p={1}>
                                <NotificationIcon />
                            </Circle>
                            <NewNotificationCount
                                notificationEvents={notificationEvents}
                                startingCount={
                                    notifications?.stats?.counts?.total || 0
                                }
                            />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent top={2} boxShadow="md" size="lg">
                        <PopoverArrow />
                        <PopoverHeader color="off-black" fontWeight="semibold">
                            {" "}
                            {getConstToLanguage("NOTIFICATIONS")}
                        </PopoverHeader>
                        <PopoverBody
                            color="off-black"
                            overflow="scroll"
                            h="30rem"
                        >
                            <NotificationDropdown
                                setNotificationsToRead={setNotificationsToRead}
                            />
                        </PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};

Notifications.propTypes = {
    unreadNotificationsCount: PropTypes.number,
};

Notifications.defaultProps = {
    unreadNotificationsCount: null,
};
