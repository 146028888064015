import gql from "graphql-tag";

const GQL_CREATE_OR_UPDATE_TO_CURRENT_SALES_ORDER = gql`
    mutation CreateOrUpdateSalesOrder(
        $impactedItemToAdd: ID
        $impactedItemToRemove: ID
        $thirdPartyItemToAdd: ID
        $thirdPartyItemToRemove: ID
    ) {
        createOrUpdateSalesOrder(
            impactedItemToAdd: $impactedItemToAdd
            impactedItemToRemove: $impactedItemToRemove
            thirdPartyItemToAdd: $thirdPartyItemToAdd
            thirdPartyItemToRemove: $thirdPartyItemToRemove
        ) {
            id
            createdAt
            items {
                id
                state
                createdAt
                price
                certifiedGem {
                    specs {
                        shape
                        carat
                        clarity
                        cut
                        color
                    }
                }
            }
        }
    }
`;

export { GQL_CREATE_OR_UPDATE_TO_CURRENT_SALES_ORDER };
