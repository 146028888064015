import React from "react";
import PropTypes from "prop-types";

import { Box, Flex, Image, Text } from "@chakra-ui/react";

/**
 * Primary UI component for user interaction
 */
export const GalleryItem = ({
    mode,
    src,
    subheading,
    title,
    stars,
    ...props
}) => {
    let titleColor,
        titleSize,
        subheadingColor,
        subheadingSize,
        width,
        height,
        rowsHeight,
        starSrc;
    switch (mode) {
        default:
            titleColor = "black";
            titleSize = 20;
            subheadingColor = "#b2ab99";
            subheadingSize = 17;
            width = 266;
            height = 367;
            rowsHeight = [125, 50, 100];
            starSrc =
                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Enmascarar-grupo-39.svg";
            break;
    }

    const getStars = () => {
        const starImages = [];
        for (let i = 1; i <= stars; i++) {
            starImages.push(<Image key={i} src={starSrc} />);
        }
        return starImages;
    };
    return (
        <Box
            textAlign={"center"}
            w={width}
            h={height}
            bgColor={"white"}
            {...props}
        >
            <Box minH={rowsHeight[0]} w={250} h={250} mx={"auto"}>
                <Image src={src} objectFit={"cover"} />
            </Box>
            <Box py={1}>
                <Text
                    as={"h4"}
                    fontFamily={"Quicksand"}
                    fontSize={subheadingSize}
                    lineHeight={1.41}
                    color={subheadingColor}
                >
                    {subheading}
                </Text>
                <Text
                    as={"h2"}
                    fontFamily={"QuicheText"}
                    fontSize={titleSize}
                    fontWeight={500}
                    lineHeight={1.3}
                    letterSpacing={-0.3}
                    color={titleColor}
                    py={1}
                >
                    {title}
                </Text>
                <Flex justify={"center"} py={1}>
                    {getStars()}
                </Flex>
            </Box>
        </Box>
    );
};

GalleryItem.propTypes = {
    /**
     * Image src
     */
    src: PropTypes.string.isRequired,
    /**
     * Subheading
     */
    subheading: PropTypes.string,
    /**
     * Title
     */
    title: PropTypes.string.isRequired,

    /**
     * stars
     */
    stars: PropTypes.number.isRequired,

    /**
     * Optional click handler
     */
    onClick: PropTypes.func,
};

GalleryItem.defaultProps = {
    mode: "default",
    src: "",
    subheading: "",
    title: "",
    stars: 1,
    onClick: undefined,
};
