import React, { useState } from "react";
import { Link, Link as ReachLink } from "react-router-dom";
import {
    AspectRatio,
    Box,
    Button,
    Center,
    ConditionalLink,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Grid,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    useDisclosure,
    VStack,
} from "../Primatives";
import { getConstToLanguage } from "./ConstToLanguageMapping";

export const Nav = ({
    variant,
    color,
    bg = "off-black",
    navType,
    navMapping,
    iconography,
    navVariant,
    currentUser,
    logoVariant,
    subItemMode = "dropdown",
    vendorTokensForOtherVendorOrganizations,
    ...props
}) => {
    const OtherAccounts = ({ vendorTokensForOtherVendorOrganizations }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        return (
            <>
                <li className="sidenav__list-item submenu" onClick={onOpen}>
                    switch accounts
                </li>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            {getConstToLanguage("SWITCH_ACCOUNTS")}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack>
                                {vendorTokensForOtherVendorOrganizations &&
                                    vendorTokensForOtherVendorOrganizations.map(
                                        (
                                            vendorTokensForOtherVendorOrganization
                                        ) => (
                                            <Button
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        "vendorToken",
                                                        vendorTokensForOtherVendorOrganization
                                                            .userAuth.token
                                                    );
                                                    window.location.reload(
                                                        false
                                                    );
                                                }}
                                            >
                                                {
                                                    vendorTokensForOtherVendorOrganization.vendorOrganizationLegalName
                                                }
                                            </Button>
                                        )
                                    )}
                            </VStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        );
    };

    const NavButton = ({ navItem, navItemConfig }) => {
        if (navVariant === "collapsed") {
            return (
                <Grid templateColumns="2.25rem" h="0 1.5rem" gap={2}>
                    <Popover
                        trigger="hover"
                        placement="right"
                        variant="responsive"
                    >
                        <PopoverTrigger>
                            <AspectRatio h="2rem" ratio={1}>
                                {navItemConfig.icon}
                            </AspectRatio>
                        </PopoverTrigger>
                        <PopoverContent bg="brand.300">
                            <PopoverArrow bg="brand.300"></PopoverArrow>
                            <PopoverHeader>
                                {getConstToLanguage(navItem)}
                            </PopoverHeader>
                        </PopoverContent>
                    </Popover>
                </Grid>
            );
        } else {
            return (
                <Grid templateColumns="2.25rem 1fr" h="0 1.5rem" gap={2}>
                    <AspectRatio h="2rem" ratio={1}>
                        {navItemConfig.icon}
                    </AspectRatio>
                    <Flex alignItems="center">
                        {getConstToLanguage(navItem)}
                        {navItemConfig &&
                            navItemConfig.subitems &&
                            Object.entries(navItemConfig.subitems).length > 0 &&
                            iconography &&
                            iconography.MENU_WITH_SUBMENU_ARROW_RIGHT_ICON &&
                            iconography.MENU_WITH_SUBMENU_ARROW_RIGHT_ICON({})}
                    </Flex>
                </Grid>
            );
        }
    };

    const NavItemSubItems = ({ navItemConfig }) => {
        return Object.entries(navItemConfig.subitems).map(
            ([navSubItem, subItemInfo]) => {
                return (
                    <ConditionalLink
                        key={navSubItem}
                        to={subItemInfo.link}
                        condition={subItemInfo.link}
                        color="white"
                        as={ReachLink}
                    >
                        <Box
                            paddingLeft={
                                subItemMode === "dropdown" ? "1.5rem" : "0"
                            }
                        >
                            {getConstToLanguage(navSubItem)}
                        </Box>
                    </ConditionalLink>
                );
            }
        );
    };

    const NavItem = ({ navItem, navItemConfig, openedItem, onClick }) => {
        if (subItemMode === "dropdown") {
            return (
                <Box onClick={onClick}>
                    <NavButton
                        navItem={navItem}
                        navItemConfig={navItemConfig}
                    />
                    {openedItem === navItem &&
                        navItemConfig &&
                        navItemConfig.subitems && (
                            <NavItemSubItems navItemConfig={navItemConfig} />
                        )}
                </Box>
            );
        } else if (subItemMode === "popover") {
            return (
                <Popover
                    placement="right-start"
                    trigger="hover"
                    variant="sidenav"
                >
                    <PopoverTrigger>
                        <HStack w="100%" color="white">
                            <NavButton
                                navItem={navItem}
                                navItemConfig={navItemConfig}
                            />
                        </HStack>
                    </PopoverTrigger>
                    {navItemConfig && navItemConfig.subitems && (
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                                <Flex direction="column">
                                    {navItemConfig &&
                                        navItemConfig.subitems && (
                                            <NavItemSubItems
                                                navItemConfig={navItemConfig}
                                            />
                                        )}
                                </Flex>
                            </PopoverBody>
                        </PopoverContent>
                    )}
                </Popover>
            );
        }
    };

    const NavList = () => {
        const [openedItem, setOpenedItem] = useState();
        if (navType === "sidenav") {
            return (
                <VStack align="left">
                    {Object.entries(navMapping.navItems).map(
                        ([navItem, navItemConfig]) => {
                            return (
                                <ConditionalLink
                                    to={navItemConfig.link}
                                    condition={navItemConfig.link}
                                    key={navItem}
                                    as={ReachLink}
                                >
                                    <NavItem
                                        onClick={() => setOpenedItem(navItem)}
                                        openedItem={openedItem}
                                        navItem={navItem}
                                        navItemConfig={navItemConfig}
                                    />
                                </ConditionalLink>
                            );
                        }
                    )}
                </VStack>
            );
        }
    };

    const CurrentUserInfo = () => {
        return (
            <Popover
                color="white"
                placement="right-start"
                trigger="hover"
                variant="sidenav"
            >
                <PopoverTrigger>
                    <Box>{currentUser && currentUser.getFullname()}</Box>
                </PopoverTrigger>
                <PopoverContent>
                    <VStack>
                        <Box
                            onClick={(e) =>
                                props.commonFunctions &&
                                props.commonFunctions.logout()
                            }
                        >
                            logout
                        </Box>
                        {vendorTokensForOtherVendorOrganizations && (
                            <OtherAccounts
                                vendorTokensForOtherVendorOrganizations={
                                    vendorTokensForOtherVendorOrganizations
                                }
                            />
                        )}
                    </VStack>
                </PopoverContent>
            </Popover>
        );
    };

    const InnerNav = () => {
        return (
            <Grid
                zIndex={1}
                templateRows="2.75rem 1fr 4rem"
                as="nav"
                h="100%"
                p="1rem"
                w="100%"
                bg={bg}
                color={color}
            >
                <InnerHeader />
                <InnerBody />
                <InnerFooter />
            </Grid>
        );
    };

    const InnerFooter = () => null;
    // <CurrentUserInfo />

    const InnerHeader = () => (
        <Box w="100%">
            {navVariant === "collapsed" ? (
                <Link to={navMapping.header.link}>
                    {<iconography.ICON_ONLY_LOGO.component colorMode="dark" />}
                </Link>
            ) : (
                <Link to={navMapping.header.link}>
                    {<iconography.FULL_LOGO.component colorMode="dark" />}
                </Link>
            )}
        </Box>
    );

    const InnerBody = () => <NavList />;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    if (variant === "full") {
        return <InnerNav />;
    }
    return (
        <>
            <Center ref={btnRef} onClick={onOpen} h="4rem" pos="fixed" w="4rem">
                <Box h="2.5rem" w="2.5rem">
                    {iconography?.MAIN_MENU_BUTTON ? (
                        <iconography.MAIN_MENU_BUTTON.component
                            variant={
                                logoVariant
                                    ? logoVariant
                                    : iconography.MAIN_MENU_BUTTON.variant
                            }
                        />
                    ) : (
                        "MENU"
                    )}
                </Box>
            </Center>
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay>
                    <DrawerContent bg={bg} color="white">
                        <DrawerCloseButton />
                        <DrawerHeader>
                            <InnerHeader />
                        </DrawerHeader>

                        <DrawerBody>
                            <InnerBody />
                        </DrawerBody>

                        <DrawerFooter>
                            <InnerFooter />
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
};
