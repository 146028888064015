import React from 'react';
import { Box, Avatar, Text, Center, VStack } from "@chakra-ui/react"


/**
 * Primary UI component for user interaction
 */
export const TeamMember = ({ personFullName, title, personImage, ...props }) => {

    return (

        <Box >
            <VStack>
                <Center>
                    <Avatar size="2xl" src={personImage} />
                </Center>
                <Center>
                    <Box fontSize={"3xl"}>
                        <Text as={"h2"}
                            fontSize={24}>{personFullName}</Text>
                    </Box>
                </Center>
                <Center>
                    <Box align="center" >
                        <Text as={"h4"}
                            color="greyish"
                            fontSize={"lg"}
                            fontWeight={'medium'}  >
                            {title}
                        </Text>
                    </Box>
                </Center>
                <Center>
                    <Box align="center"
                        fontSize={12}
                        lineHeight={8}
                        color={'brand.600'}>
                        {props.children}
                    </Box>
                </Center>
            </VStack>
        </Box>

    );
}

TeamMember.propTypes = {

};

TeamMember.defaultProps = {

};
