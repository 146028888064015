import React from "react";
import PropTypes from "prop-types";

import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

/**
 * Primary UI component for user interaction
 */
export const FixedText = ({ noOfLines, ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const LimitedText = (props) => {
        return (
            <Text as={"div"} noOfLines={noOfLines} {...props}>
                {props.children}
            </Text>
        );
    };

    return (
        <Box>
            <LimitedText {...props} />
            <Button
                onClick={onOpen}
                mt={8}
                variant={"link"}
                fontFamily={"Quicksand"}
                fontSize={22}
                fontWeight={"normal"}
                color={"brand.500"}
                _hover={{ bg: "initial", textDecor: "underline" }}
            >
                {/*
        adding _hover style because of button general style. TODO: review this when removing that style
*/}
                READ MORE
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader />
                    <ModalCloseButton />
                    <ModalBody textAlign={"center"}>{props.children}</ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

FixedText.propTypes = {
    /**
     * Optional click handler
     */
    noOfLines: PropTypes.number,
};

FixedText.defaultProps = {
    noOfLines: 3,
};
