import {
  GEM_LISTING_MANAGER__CLEAR_SEARCH_PARAM,
  VENDOR_HOME_PAGE_UNLOADED,
  TRADER_CREATE_GEM_LISTING,
  TRADER_GEM_PUT_ON_MARKET,
  TRADER_GEM_TAKE_OFF_MARKET,
  TRADER_GEM_REQUEST_VERIFICATION,
  TRADER_TOGGLE_GEM_PRICE_EDIT_MODE,
  TRADER_GEM_LISTING_PRICE_CHANGE,
  TRADER_GEM_MANAGEMENT_SUBTOOL_UNLOADED,
  VENDOR_TRADER_GEMS_NEXT_PAGE,
  VENDOR_TRADER_GEMS_PREVIOUS_PAGE,
  VENDOR_TRADER_PAGE_LOADED,
  TRADER_GEM_SEARCH_UPDATE_FIELD,
  TRADER_GEM_SEARCH_SUBMIT,
  GEM_LISTING_MANAGER_SEARCH_SUBMIT,
  GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_STATES,
  GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_SHAPES,
  GEM_LISTING_MANAGER_SEARCH_CHANGE_ATTR,
  GEM_LISTING_MANAGER_TOGGLE_STATE,
  GEM_LISTING_MANAGER_TOGGLE_SHAPE,
  TRADER_GEM_MANAGEMENT_PAGE_LOADED,
  TRADER_EDIT_GEM_LISTING_PRICE,
  GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR,
  TRADER_EDIT_GEM_LISTING_ACQ_PRICE,
  TRADER_TOGGLE_GEM_ACQ_PRICE_EDIT_MODE,
  TRADER_GEM_CHANGE_ACQ_PRICE,
  TRADER_ARCHIVE_GEM_LISTING,
  TRADER_CANCEL_ARCHIVE_GEM_LISTING,
  TRADER_CONFIRM_ARCHIVE_GEM_LISTING,
  ASYNC_END,
  GEM_LISTING_MANAGER__SEARCH_PARAM__FILTER__TOGGLE_SHAPE,
  ASYNC_START
} from "../../constants/actionTypes";

import {
  ROLE_TO_INITIAL_SUBTOOL_GLS_MAPPING,
  LIST_OF_SHAPES_IN_ORDER
} from "../../constants/businessConstants";

import { buildInitialGemListingRow } from "../initializers/gemListing";
//import { stat } from "fs";

let GLSHelper = require("../helpers/GLS");

export default (state = initialState(), action) => {
  switch (action.type) {
    case GEM_LISTING_MANAGER_SEARCH_SUBMIT:

      let buildInitialNextPagegemListingRows2 = action.payload.gemListings.map(
        gem => {
          return buildInitialGemListingRow(gem);
        }
      );

      return {
        ...state,
        gemListingRows: buildInitialNextPagegemListingRows2,
        gemListingsCount: action.payload.gemListingsCount,
        gemListingsSearchParam: action.searchParam
        
      };
    case GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_STATES:
      let newStateList2;
      if (
        state.gemListingsSearchParam.states.length ===
        initialState().gemListingsSearchParam.states.length
      ) {
        newStateList2 = [];
      } else {
        newStateList2 = initialState().gemListingsSearchParam.states;
      }
      return {
        ...state,
        gemListingsSearchParam: {
          ...state.gemListingsSearchParam,
          states: newStateList2
        }
      };
    case GEM_LISTING_MANAGER_SEARCH_CHANGE_ATTR:
      if (action.section) {
        if (action.section === "filter") {
          let newGemListingSearchParam = { ...state.newGemListingSearchParam };
          newGemListingSearchParam[action.section][action.attr] = action.value;
        }
      }
      break;
    case TRADER_GEM_SEARCH_UPDATE_FIELD:
      let newGemsSearchParam = { ...state.gemListingsSearchParam };
      newGemsSearchParam.gia = action.value;

      return { ...state, gemListingsSearchParam: newGemsSearchParam };

    case TRADER_GEM_MANAGEMENT_PAGE_LOADED:
      if (action.payload.errors) {
        break;
      } else {
        let buildInitialGemListingRows = action.payload[0].gemListings.map(
          gemListing => {
            return buildInitialGemListingRow(gemListing);
          }
        );

        return {
          ...state,
          gemListingRows: buildInitialGemListingRows,
          gemListingsCount: action.payload[0].gemListingsCount,
          globalGLSStats: action.payload[1],
          searchStats: action.payload[0].stats,
          currentlySelectedGLS: action.gls,
          gemListingsSearchParam: {
            ...action.gemListingsSearchParam,
            states: [ROLE_TO_INITIAL_SUBTOOL_GLS_MAPPING.vendor[action.subtool]]
          },
          reservedGemListings:
            action.payload.length === 3
              ? action.payload[2].reservedGemListings
              : null
        };
      }
    case TRADER_GEM_SEARCH_SUBMIT:
    case VENDOR_TRADER_GEMS_NEXT_PAGE:
    case VENDOR_TRADER_GEMS_PREVIOUS_PAGE:
      let buildInitialNextPagegemListingRows = action.payload.gemListings.map(
        gem => {
          return buildInitialGemListingRow(gem);
        }
      );

      return {
        ...state,
        gemListingRows: buildInitialNextPagegemListingRows,
        gemListingsCount: action.payload.gemListingsCount,
        gemListingsSearchParam: action.gemListingsSearchParam,
        searchStats: action.payload.stats
      };
    case GEM_LISTING_MANAGER_TOGGLE_SHAPE:
      let newShapeList = state.gemListingsSearchParam.shapes.slice();

      if (newShapeList.includes(action.shape)) {
        newShapeList = newShapeList.filter(shape => shape !== action.shape);
      } else {
        newShapeList.push(action.shape);
      }

      return {
        ...state,
        gemListingsSearchParam: {
          ...state.gemListingsSearchParam,
          shapes: newShapeList
        }
      };
    case TRADER_GEM_REQUEST_VERIFICATION:
    case TRADER_GEM_PUT_ON_MARKET:
    case TRADER_GEM_TAKE_OFF_MARKET:
    case TRADER_CONFIRM_ARCHIVE_GEM_LISTING:
      let newState = GLSHelper.updateGLS(state, action);
      return newState;

    case TRADER_CREATE_GEM_LISTING:
      if (!action.payload.errors) {
        let newGemRow = buildInitialGemListingRow(
          action.payload.gemListing,
          true
        );
        return {
          ...state,
          gemListingRows: [newGemRow].concat(state.gemListingRows)
        };
      }
      break;
    case TRADER_GEM_LISTING_PRICE_CHANGE:
      if (action.payload.gemListing) {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (
              gemListingRow.gemListing._id === action.payload.gemListing._id
            ) {
              return {
                ...gemListingRow,
                gemListing: action.payload.gemListing,
                priceEditMode: !gemListingRow.priceEditMode,
                newPrice: action.payload.gemListing.price
              };
            }
            return gemListingRow;
          })
        };
      }
      break;

    case TRADER_GEM_CHANGE_ACQ_PRICE:
      if (action.payload.gemListing) {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (
              gemListingRow.gemListing._id === action.payload.gemListing._id
            ) {
              return {
                ...gemListingRow,
                gemListing: action.payload.gemListing,
                acqPriceEditMode: !gemListingRow.acqPriceEditMode,
                newAcqPrice: action.payload.gemListing.acqPrice
              };
            }
            return gemListingRow;
          })
        };
      }
      break;

    case TRADER_TOGGLE_GEM_PRICE_EDIT_MODE:
      if (action.gemListing) {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (gemListingRow.gemListing._id === action.gemListing._id) {
              return {
                ...gemListingRow,
                priceEditMode: !gemListingRow.priceEditMode
              };
            }
            return gemListingRow;
          })
        };
      }
      break;

    case TRADER_TOGGLE_GEM_ACQ_PRICE_EDIT_MODE:
      if (action.gemListing) {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (gemListingRow.gemListing._id === action.gemListing._id) {
              return {
                ...gemListingRow,
                acqPriceEditMode: !gemListingRow.acqPriceEditMode
              };
            }
            return gemListingRow;
          })
        };
      }
      break;

    // case SET_PAGE:
    //   console.log("asdfasfasd")
    //   console.log(action.payload)
    //   return {
    //     ...state,
    //     gems: action.payload.gemListings,
    //     gemListingsCount: action.payload.gemListingsCount,
    //     currentPage: action.page
    //   };

    case GEM_LISTING_MANAGER__SEARCH_PARAM__FILTER__TOGGLE_SHAPE:
      let newNewSearchParam = {...state.newGemListingSearchParam}

      if (newNewSearchParam.filter.shapes.includes(action.shape)) {
        newNewSearchParam.filter.shapes = newNewSearchParam.filter.shapes.filter(shape => shape !== action.shape);
      } else {
        newNewSearchParam.filter.shapes.push(action.shape);
      }

      return {
        ...state,
        newGemListingSearchParam: newNewSearchParam
      };

    case GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_SHAPES:
      let newShapeList2;
      if (
        state.gemListingsSearchParam.shapes.length ===
        initialState().gemListingsSearchParam.shapes.length
      ) {
        newShapeList2 = [];
      } else {
        newShapeList2 = initialState().gemListingsSearchParam.shapes;
      }
      return {
        ...state,
        gemListingsSearchParam: {
          ...state.gemListingsSearchParam,
          shapes: newShapeList2
        }
      };

    case GEM_LISTING_MANAGER_TOGGLE_STATE:
      let newStateList = state.gemListingsSearchParam.states.slice();

      if (newStateList.includes(action.state)) {
        newStateList = newStateList.filter(state => state !== action.state);
      } else {
        newStateList.push(action.state);
      }

      return {
        ...state,
        gemListingsSearchParam: {
          ...state.gemListingsSearchParam,
          states: newStateList
        }
      };

    case VENDOR_TRADER_PAGE_LOADED: {
      let newState = initialState();

      if (action.payload[0].gemListings) {
        let buildInitialGemListingRows = action.payload[0].gemListings.map(
          gem => {
            return buildInitialGemListingRow(gem);
          }
        );

        return {
          ...newState,
          pager: action.pager,
          gemListingRows: buildInitialGemListingRows,
          gemListingsCount: action.payload[0].gemListingsCount, // TODO - check backend gives this
          currentPage: 0
        };
      }
    }
      break;

    case GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR:
      let buildInitialGemListingRows2 = action.payload.gemListings.map(
        gemListing => {
          return {
            gemListing: gemListing,
            priceEditMode: false,
            showEventHistory: false
          };
        }
      );

      return {
        ...state,
        gemListingRows: buildInitialGemListingRows2,
        gemListingsCount: action.payload.gemListingsCount,
        gemListingsSearchParam: action.newGLSearchParam,
        searchStats: action.payload.stats,
        currentlySelectedGLS: action.gls
      };
    case TRADER_ARCHIVE_GEM_LISTING:
      if (action.gemListing) {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (gemListingRow.gemListing._id === action.gemListing._id) {
              return {
                ...gemListingRow,
                requestToArchive: true
              };
            }
            return gemListingRow;
          })
        };
      }
      return state;
    case TRADER_CANCEL_ARCHIVE_GEM_LISTING:
      if (action.gemListing) {
        return {
          ...state,
          gemListingRows: state.gemListingRows(gemListingRow => {
            if (gemListingRow.gemListing._id === action.gemListing._id) {
              return {
                ...gemListingRow,
                requestToArchive: false
              };
            }
            return gemListingRow;
          })
        };
      }
      return state;
    case TRADER_GEM_MANAGEMENT_SUBTOOL_UNLOADED:
    case VENDOR_HOME_PAGE_UNLOADED:
      return initialState();

    case TRADER_EDIT_GEM_LISTING_PRICE:
      if (action.gemListing) {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (gemListingRow.gemListing._id === action.gemListing._id) {
              console.log("CHANGINE PRICE");
              return {
                ...gemListingRow,
                newPrice: action.price
              };
            }
            return gemListingRow;
          })
        };
      }
      break;

    case TRADER_EDIT_GEM_LISTING_ACQ_PRICE:
      if (action.gemListing) {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (gemListingRow.gemListing._id === action.gemListing._id) {
              console.log("CHANGINE PRICE");
              return {
                ...gemListingRow,
                newAcqPrice: action.acqPrice
              };
            }
            return gemListingRow;
          })
        };
      }
      break;

    case GEM_LISTING_MANAGER__CLEAR_SEARCH_PARAM:
      return { ...state, newGemListingSearchParam: initialNewSearchParam() };
    case ASYNC_START:
      if (
        action.subtype === TRADER_GEM_MANAGEMENT_PAGE_LOADED ||
        action.subtype === TRADER_GEM_SEARCH_SUBMIT ||
        action.subtype === VENDOR_TRADER_GEMS_NEXT_PAGE ||
        action.subtype === VENDOR_TRADER_GEMS_PREVIOUS_PAGE ||
        action.subtype === GEM_LISTING_MANAGER_SEARCH_SUBMIT ||
        action.subtype === GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR
      ) {
        return { ...state, isDataLoading: true };
      } else if (action.subtype === TRADER_GEM_LISTING_PRICE_CHANGE) {
        return {
          ...state,
          priceChangeInProgress: {
            ...state.priceChangeInProgress,
            [action.identifier]: true
          }
        };
      } else if (action.subtype === TRADER_GEM_CHANGE_ACQ_PRICE) {
        return {
          ...state,
          acqPriceChangeInProgress: {
            ...state.acqPriceChangeInProgress,
            [action.identifier]: true
          }
        };
      } else if (
        action.subtype === TRADER_GEM_REQUEST_VERIFICATION ||
        action.subtype === TRADER_GEM_PUT_ON_MARKET ||
        action.subtype === TRADER_GEM_TAKE_OFF_MARKET
      ) {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (gemListingRow.gemListing._id === action.identifier) {
              let updatedGemRow = { ...gemListingRow, glsChange: true };
              return updatedGemRow;
            }
            return gemListingRow;
          })
        };
      }
      return state;
    case ASYNC_END:
      if (
        action.subtype === TRADER_GEM_MANAGEMENT_PAGE_LOADED ||
        action.subtype === TRADER_GEM_SEARCH_SUBMIT ||
        action.subtype === VENDOR_TRADER_GEMS_NEXT_PAGE ||
        action.subtype === VENDOR_TRADER_GEMS_PREVIOUS_PAGE ||
        action.subtype === GEM_LISTING_MANAGER_SEARCH_SUBMIT ||
        action.subtype === GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR
      ) {
        return { ...state, isDataLoading: false };
      } else if (action.subtype === TRADER_GEM_LISTING_PRICE_CHANGE) {
        return {
          ...state,
          priceChangeInProgress: {
            ...state.priceChangeInProgress,
            [action.identifier]: false
          }
        };
      } else if (action.subtype === TRADER_GEM_CHANGE_ACQ_PRICE) {
        return {
          ...state,
          acqPriceChangeInProgress: {
            ...state.priceChangacqPriceChangeInProgresseInProgress,
            [action.identifier]: false
          }
        };
      }
      return state;
    default:
      return state;
  }
};

function initialNewSearchParam() {
  return {
    filter: {
      caratRange: [0, 50],
      priceRange: [0, 100000],
      certificationId: "",
      shapes: LIST_OF_SHAPES_IN_ORDER
    },
    options: {
      pagination: {
        offset: 0,
        limit:20
      }
    }
  };
}

function initialState() {
  return {
    vendorEvents: [],
    requestErrors: [],
    newGemListingSearchParam: initialNewSearchParam(),
    gemListingsSearchParam: {
      filter: {
        certificationId: null,
        shapes: LIST_OF_SHAPES_IN_ORDER
      },
      options: {
        orderBy: null,
        pagination: {
          offset: 0,
          limit: 20
        }
      }
    },
    isDataLoading: false,
    priceChangeInProgress: {},
    acqPriceChangeInProgress: {}
  };
}
