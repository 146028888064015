import React from "react";

import { Image } from "@chakra-ui/react";

/**
 * Primary UI component for user interaction
 */
export const DividerImage = ({ ...props }) => {
    return (
        <Image
            src="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/divider.svg"
            mx={"auto"}
            {...props}
            alt="Divider"
        />
    );
};
