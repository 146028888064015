 import {
  MARKET_SHOW_SPECIFIC_SHAPE_DESCRIPTION
} from '../constants/actionTypes';

export default (state = {showThisShapeDescription: null}, action) => {
  switch (action.type) {

    case MARKET_SHOW_SPECIFIC_SHAPE_DESCRIPTION:
      return {
        showThisShapeDescription: action.shape
      }
    default:
      return state;
  }
};