import {numberToCurrency} from "./floatToCurrency";

const getSum = (items, key) => {
  let sum = items.reduce(function (total, currentValue) {
    return total + currentValue[key];
  }, 0);

  return numberToCurrency(sum);
};

export {getSum};
