import {
    MARKET_GEM_LISTING_DETAILS
  } from '../constants/actionTypes';
  
  export default (state = {gemListing: null}, action) => {
    switch (action.type) {
      case MARKET_GEM_LISTING_DETAILS:
        
        return {...state, gemListing: action.payload[0].gemListing}
    
      default:
        return state;
    }
  };
  