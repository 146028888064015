import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../../lib/GraphCallBuilder";

function GetVendorOrganizationGraphQLQuery() {
    return new GraphqlQueryBuilder({
        api: "getVendorOrganization",
        type: "query",
        args: [new GQLInputArg("vendorOrganizationId", "ID", true)],
    });
}

export { GetVendorOrganizationGraphQLQuery };
