import { useApolloClient, useQuery } from "@apollo/react-hooks";
import * as Ably from "ably";
import PubSub from "pubsub-js";
import { useEffect, useMemo } from "react";
import { useCompanyEvents } from "../../../stories/CloudManagementOS/hooks";

// TODO - this is  a good one to make into type script
export function useCurrentlyReservedGemListings(
    graphQLQueryForCurrentlyReservedGemListings
) {
    const cache = useApolloClient();
    let { eventToHandle } = useCompanyEvents(
        "public",
        "gemlisting:reservation:events"
    );

    const { data: currentlyReservedGemListings } = useQuery(
        graphQLQueryForCurrentlyReservedGemListings,
        {}
    );

    const ablyClient = useMemo(() => {
        return new Ably.Realtime({
            key: process.env.REACT_APP__ABLY_PUBLIC_KEY,
        });
    }, []);

    let subscribedGemListingReservationEvents = useMemo(() => {
        return ablyClient.channels.get(`public:gemlisting:reservation:events`);
    }, []);

    useEffect(() => {
        console.log("Subscribing to Carbonext Events Channel");

        subscribedGemListingReservationEvents.subscribe((data) => {
            let incomingEvent = incomingDataToIncomingEvent(data.data);

            console.log("Incoming Carbonext Public Event: ", incomingEvent);

            PubSub.publish(
                `public:gemlisting:reservation:events`,
                incomingEvent
            );
        });

        return () => subscribedGemListingReservationEvents.unsubscribe();
    }, []);

    const incomingDataToIncomingEvent = (data) => {
        var incomingEvent = JSON.parse(JSON.stringify(data));

        if (
            incomingEvent.data &&
            incomingEvent.datacontenttype === "application/json"
        ) {
            let messageData = JSON.parse(incomingEvent.data);
            incomingEvent.data = messageData;
        }

        return incomingEvent;
    };

    useEffect(() => {
        if (eventToHandle) {
            const data = cache.readQuery({
                query: graphQLQueryForCurrentlyReservedGemListings,
            });
            let newListOfReservedGemListings = [
                ...data.getReservedGemListingIDs,
            ];

            switch (eventToHandle.type) {
                case "gemlisting.reserved":
                    newListOfReservedGemListings.push(eventToHandle.data.id);
                    break;
                case "gemlisting.unreserved":
                    newListOfReservedGemListings = newListOfReservedGemListings.filter(
                        (reservedGemListingId) =>
                            eventToHandle.data.id !== reservedGemListingId
                    );
                    break;
                default:
            }
            cache.writeQuery({
                query: graphQLQueryForCurrentlyReservedGemListings,
                data: {
                    getReservedGemListingIDs: newListOfReservedGemListings,
                },
            });
        }
    }, [eventToHandle, cache]);

    return {
        currentlyReservedGemListings:
            currentlyReservedGemListings?.getReservedGemListingIDs,
    };
}
