import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../lib/GraphCallBuilder";

function AddReferralCodeToCurrentSalesOrderGraphQLMutation() {
    return new GraphqlQueryBuilder({
        api: "addReferralCodeToCurrentSalesOrder",
        type: "mutation",
        args: [new GQLInputArg("referralCode", "String", true)],
    });
}

export { AddReferralCodeToCurrentSalesOrderGraphQLMutation };
