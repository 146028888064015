import { Icon } from "@chakra-ui/react";
import React from "react";


export const PrincessShapeIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 487.048 487.048" fill={color} height={'auto'} width={'100%'}>
        <g
            id="Diamonds_Princess_Outlined"
            data-name="Diamonds_Princess Outlined"
            transform="translate(-52.833 -162.291)"
        >
            <g id="Diamonds">
                <g id="Grupo_46" data-name="Grupo 46">
                    <g id="Grupo_45" data-name="Grupo 45">
                        <g id="XMLID_264_">
                            <path
                                id="XMLID_298_"
                                d="M479.226,207.609v.453l.677-2.015-.2.146Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_297_"
                                d="M479.9,206.047l-.169.049-.033.1Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_296_"
                                d="M479.7,206.193l.033-.1-.508.148v.294Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_295_"
                                d="M113.937,205.213l-.226-.068.226.164Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_294_"
                                d="M113.711,606.483l.226-.176v-.495Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_293_"
                                d="M479.55,606.333l.5.15-.827-2.463v1.349Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_292_"
                                d="M479.7,206.193l-.475.345v1.071Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_291_"
                                d="M479.226,606.236l.324.1-.324-.964Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_290_"
                                d="M113.711,205.145l.226.672v-.508Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_289_"
                                d="M479.226,208.062V604.02l.827,2.463-.5-.15.353,1.052,56.775,39.953h1.2V164.291h-.893L479.9,206.047Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_288_"
                                d="M113.937,205.213v-.068H479.226v1.1l.508-.148.319-.951-.319.951.169-.049,57.084-41.756H57.859l55.852,40.854Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_287_"
                                d="M479.226,606.236v.247h-.472l1.149.9-.353-1.052Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_286_"
                                d="M479.9,607.385l-1.149-.9H113.937v-.176l-.226.176L55.654,647.338H536.678Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_285_"
                                d="M113.937,605.812v-.252l-.075.022.075-.022V205.817l-.226-.672L57.859,164.291H54.833V647.338h.821l58.057-40.855Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_284_"
                                d="M297.408,551.81l180.543,54.045-84.29-66.11Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_283_"
                                d="M412.628,406.761,393.661,539.745h0l84.29,66.11,1.275.381v-.867Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_281_"
                                d="M412.838,406.041l-.076-.227-.112.79Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_280_"
                                d="M479.226,207.609v-1.071l-90.608,65.9,24.144,133.378Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_279_"
                                d="M181,405.814,114.03,605.533l1.426-.418L201.413,537.7Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_278_"
                                d="M201.413,537.7l-85.957,67.417,181.952-53.3Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_277_"
                                d="M206.139,272.366l90.217-12.548-182.419-54.6v.1Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_275_"
                                d="M114.03,605.533l-.093.279v.495l1.519-1.192Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_273_"
                                d="M113.937,605.56v.252l.093-.279Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_272_"
                                d="M205.635,272.436l.5-.07-92.2-67.057v.508l67.065,200Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_269_"
                                d="M479.226,606.236l-1.275-.381.8.628h.472Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_266_"
                                d="M412.628,406.761l.022-.157-.038.112Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                            <path
                                id="XMLID_265_"
                                d="M393.661,539.745h0l18.967-132.984-.016-.045.038-.112.112-.79L388.618,272.436h0l-92.262-12.618-90.217,12.548h0l-.5.07L181,405.814,201.413,537.7h0l95.995,14.112Z"
                                fill="none"
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="4"
                            />
                        </g>
                        <rect
                            id="Rectángulo_820"
                            data-name="Rectángulo 820"
                            width="483.048"
                            height="483.048"
                            transform="translate(54.833 164.291)"
                            strokeWidth="4"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            fill="none"
                        />
                    </g>
                    <line
                        id="Línea_3"
                        data-name="Línea 3"
                        x1="183.345"
                        y2="53.625"
                        transform="translate(296.356 206.193)"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
