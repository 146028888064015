import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { default as React } from "react";
import { useHistory } from "react-router-dom";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { AuthLogin } from "../../UtilityComponents/AuthLogin";

const RESET_VENDOR_PASSWORD_GQL_MUTATION = gql`
    mutation ResetVendorPassword($email: Email!) {
        resetVendorPassword(email: $email) {
            message
        }
    }
`;

function VendorResetPassword() {
    const history = useHistory();

    const [resetPasswordFuncMutation, { data, loading, error }] = useMutation(
        RESET_VENDOR_PASSWORD_GQL_MUTATION,
        {}
    );

    const resetPasswordFunc = async (variables) =>
        await resetPasswordFuncMutation({ variables });

    return (
        <AuthLogin
            mode="reset-password"
            title="Vendor Reset Password"
            heading={getConstToLanguage("VENDOR_LOGIN")}
            resetPasswordFunc={resetPasswordFunc}
        />
    );
}

export default VendorResetPassword;
