import gql from "graphql-tag";

export const GQL_Q_SEARCH_ADMINS = gql`
  query SearchAdmins {
    searchAdmins {
      totalCount
      edges {
        node {
          id
          localAuth {
            email
          }
          person {
            givenName
            additionalName
            familyName
          }
        }
      }
    }
  }
`;
