function buildInitialGemListingRow(gemListing, newlyCreated=false) {
  return {
    gemListing: gemListing,
    priceEditMode: false,
    newPrice: gemListing.price,
    newAcqPrice: gemListing.acqPrice,
    newlyCreated: newlyCreated
  };
}

export { buildInitialGemListingRow };
