import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../../lib/GraphCallBuilder";

function GetOnMarketGemListingGraphQLQuery() {
    return new GraphqlQueryBuilder({
        api: "getOnMarketGemListing",
        type: "query",
        args: [new GQLInputArg("id", "ID", true)],
    });
}

export { GetOnMarketGemListingGraphQLQuery };
