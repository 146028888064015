import React from "react";
import { Box, Grid, useBreakpointValue } from "../../Primatives";

const TwoPanelShell = ({
    panel1,
    panel2,
    h,
    breakpointValue = "sm",
    variant = "right-main",
    subViewWidth = "35%",
    spacing,
    subViewProps,
    mainViewProps,
    bg,
    subPanelHiddenOnSmallScreens = false,
}) => {
    const direction = useBreakpointValue({
        base: "column",
        [breakpointValue]: "row",
    });

    let leftWidth,
        rightWidth,
        templateColumns,
        templateRows,
        templateColumnsValue,
        templateRowsValue,
        leftPanelOn,
        rightPanelOn,
        rightPanelProps,
        leftPanelProps,
        leftPanelOnValue = true,
        rightPanelOnValue = true;

    if (variant === "right-main") {
        rightWidth = "stretch";
        leftWidth = { base: "100%", md: "20%", lg: subViewWidth };
        leftPanelProps = subViewProps;
        rightPanelOn = mainViewProps;
        templateColumnsValue = {
            base: "100%",
            [breakpointValue]: spacing
                ? `${subViewWidth} ${spacing} 1fr`
                : `${subViewWidth} 1fr`,
        };
        templateRowsValue = {
            base: spacing
                ? ` max-content ${spacing} max-content`
                : `max-content max-content`,
            [breakpointValue]: `100%`,
        };

        if (subPanelHiddenOnSmallScreens) {
            leftPanelOnValue = {
                base: false,
                [breakpointValue]: true,
            };
            rightPanelOnValue = {
                base: true,
            };
        } else {
            leftPanelOnValue = {
                base: true,
            };
            rightPanelOnValue = {
                base: true,
            };
        }
    } else if (variant === "left-main") {
        rightWidth = { base: "100%", md: "20%", lg: subViewWidth };
        leftWidth = "stretch";
        rightPanelProps = subViewProps;
        leftPanelProps = mainViewProps;

        templateColumnsValue = {
            base: "100%",
            [breakpointValue]: spacing
                ? `1fr ${spacing} ${subViewWidth}`
                : `1fr ${subViewWidth}`,
        };
        templateRowsValue = {
            base: spacing
                ? `max-content ${spacing} max-content`
                : "max-content max-content",
            [breakpointValue]: `100%`,
        };

        if (subPanelHiddenOnSmallScreens) {
            rightPanelOnValue = {
                base: false,
                [breakpointValue]: true,
            };
            leftPanelOnValue = {
                base: true,
            };
        } else {
            leftPanelOnValue = {
                base: true,
            };
            rightPanelOnValue = {
                base: true,
            };
        }
    }

    templateColumns = useBreakpointValue(templateColumnsValue);
    templateRows = useBreakpointValue(templateRowsValue);
    templateRows = useBreakpointValue(templateRowsValue);
    leftPanelOn = useBreakpointValue(leftPanelOnValue);
    rightPanelOn = useBreakpointValue(rightPanelOnValue);

    console.log("RIGHTTSSSSS", rightPanelOn);

    return (
        <Grid
            templateColumns={templateColumns}
            templateRows={templateRows}
            h={h}
            w="100%"
        >
            {leftPanelOn ? (
                <Box {...leftPanelProps}>{panel1}</Box>
            ) : (
                <Box></Box>
            )}
            {spacing ? <Box bg={bg}></Box> : null}
            {rightPanelOn ? (
                <Box {...rightPanelProps}>{panel2}</Box>
            ) : (
                <Box></Box>
            )}
        </Grid>
    );
};

export default TwoPanelShell;
