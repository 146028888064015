import React, { useState } from "react";
import { SearchResultListItem } from "../../../../stories/CloudManagementOS"
import { useMutation } from "@apollo/react-hooks";

import { GQL_M_COMPLETE_CURRENT_SALES_ORDER } from "../../graph/mutations/completeCurrentSalesOrder";

function GemRequestListItem(props) {
  //let [state, setState] = useState({ showActions: false });
  const [completeCurrentSalesOrder, { data, loading, error }] = useMutation(
    GQL_M_COMPLETE_CURRENT_SALES_ORDER
  );

  // constructor() {
  //   super();

  //   state = { showActions: false };

  //   renderActions = renderActions.bind(this);
  //   renderModelType = renderModelType.bind(this);
  //   renderIdentifier = renderIdentifier.bind(this);
  //   renderMeta = renderMeta.bind(this);
  //   renderOwner = renderOwner.bind(this);
  // }

  function renderActions() {
    return [
      {
        name: "COMPLETE",
        onClick: e =>
          completeCurrentSalesOrder({
            variables: { id: props.item.data.id }
          })
      }
    ];
  }

  function renderModelType() {
    return <div>CURRENT_SALES_ORDER</div>;
    // return (
    //   // <div className="gem-request-item-model-item-style-1-model-type">
    //   //   <div className="gem-request-item-model-item-style-1-model-type__icon">
    //   //     {props.itemIdentifierIcon({ color: "#000000" })}
    //   //   </div>
    //   // </div>
    // );
  }

  function renderIdentifier() {
    return (
      <div>
        <div>
          {props.item.data.marketUser.person.givenName}{" "}
          {props.item.data.marketUser.person.familyName}
          {props.item.data.marketUser.localAuth.email}{" "}
        </div>
      </div>
    );
  }

  function renderMeta() {
    // return (
    //   // <div>
    //   //   {" "}
    //   //   {props.item &&
    //   //     props.item.data &&
    //   //     props.item.data.node.createdAt}
    //   // </div>
    // );
  }

  //function renderPrimaryInfo() { }

  function renderItems() {
    return (
      <div>
        {props.item.data.items.map(item => (
          <div>
            {item.certifiedGem.certification.certificationId}{" "}
            {item.certifiedGem.specs.carat} {item.certifiedGem.specs.shape}{" "}
            {item.certifiedGem.specs.clarity} {item.certifiedGem.specs.color}{" "}
            {item.certifiedGem.specs.cut}{" "}
          </div>
        ))}
      </div>
    );
  }
  function renderOwner() {
    // return (
    //   // <div>
    //   //   {getConstToLanguage("REQUESTED_BY")}{" "}
    //   //   {props.item &&
    //   //     props.item.data &&
    //   //     props.item.data.node.createdBy &&
    //   //     props.item.data.node.createdBy.person.givenName}{" "}
    //   //   {props.item &&
    //   //     props.item.data &&
    //   //     props.item.data.node.createdBy &&
    //   //     props.item.data.node.createdBy.person.familyName}
    //   // </div>
    // );
  }

  function renderSecondaryInfo() {
    const reducer = (accumulator, currentValue) =>
      accumulator + currentValue.price;
    return <div>TOTAL PRICE: {props.item.data.items.reduce(reducer, 0)}</div>;
  }

  return (
    <SearchResultListItem
      iconography={props.iconography}
      actions={renderActions()}
      modelType={renderModelType()}
      menu={props.menu}
      indentifier={renderIdentifier()}
      meta={renderMeta()}
      owner={renderOwner()}
      primaryInfo={renderItems()}
      secondaryInfo={renderSecondaryInfo()}
      createdAt={props.item.data && props.item.data.createdAt}
    />
  );
}

export default GemRequestListItem;
