import React from "react";
import SubTool from "./SubTool";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  ...state.gemListingsManager
});

const mapDispatchToProps = dispatch => ({});

class GemListingsManagement extends React.Component {

  displayTool(subtool) {
    return (
      <SubTool
        runningMutations={this.props.runningMutations}
        submitSearchFunc={this.submitSearchFunc}
        searchValueUpdateFunc={this.searchValueUpdateFunc}
        nextPageFunc={this.nextPageFunc}
        previousPageFunc={this.previousPageFunc}
        gemListingsCount={this.props.gemListingsCount}
        gemListingRows={this.props.gemListingRows}
        searchFlags={this.props.gemListingsSearchFlags}
        subtool={subtool}
        param1={this.props.param1}
        match={this.props.match}
      />
    );
  }

  render() {
    return this.displayTool(this.props.subtool);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GemListingsManagement);
//
