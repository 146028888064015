import React from "react";
import { connect } from "react-redux";
import agent from "../../../../../agent";
import TablePagination from "../../../../TablePagination";
import {
  ADMIN_RELATIVE_GEM_LISTING_SEARCH_JOBS_PAGE_LOADED,
  ADMIN_SEARCH_RELATIVE_GEM_LISTING_SEARCH_JOBS
} from "../../../../../constants/actionTypes";

const Promise = global.Promise;

const mapStateToProps = state => ({
  ...state.adminRelativeGemListingSearchJobs
});

const mapDispatchToProps = dispatch => ({
  onLoad: (pager, payload) => {
    dispatch({
      type: ADMIN_RELATIVE_GEM_LISTING_SEARCH_JOBS_PAGE_LOADED,
      pager,
      payload
    });
  },
  searchRelativeGemListingSearchJobs: searchParam => {
    dispatch({
      type: ADMIN_SEARCH_RELATIVE_GEM_LISTING_SEARCH_JOBS,
      searchParam
    });
  }
});

class RelativeGemListingSearchJobs extends React.Component {
  constructor(props) {
    super(props);

    this.rows = this.rows.bind(this);

    this.nextPageFunc = () => ev => {
      ev.preventDefault();
      let newSearchParam = {
        ...this.props.searchParam
      };
      newSearchParam.searchOptions.offset += newSearchParam.searchOptions.limit;
      this.props.searchRelativeGemListingSearchJobs(newSearchParam);
    };

    this.previousPageFunc = () => ev => {
      ev.preventDefault();
      let newSearchParam = {
        ...this.props.searchParam
      };
      newSearchParam.offset -= newSearchParam.limit;
      if (newSearchParam.offset < 0) {
        newSearchParam.offset = 0;
      }
      this.props.searchRelativeGemListingSearchJobs(newSearchParam);
    };
  }

  componentWillMount() {
    const jobsPromise = agent.RelativePricing.getRelativeGemListingSearchJobs;

    this.props.onLoad(jobsPromise, Promise.all([jobsPromise({})]));
  }

  headers() {
    return (
      <tr>
        <th>UUID</th>
        <th>SHAPE</th>
        <th>SOURCE</th>
        <th>GEM TYPE</th>
        <th>EXPECTED COUNT</th>
        <th>RETRIEVED COUNT</th>
        <th>INSERTED COUNT</th>
        <th>NEW GEM LISTING COUNT</th>
        <th>START TIME</th>

        <th>END TIME</th>
        <th>DURATION</th>
      </tr>
    );
  }

  rows() {
    if (this.props.relativeGemListingSearchJobsResp) {
      return this.props.relativeGemListingSearchJobsResp.relativeGemListingSearchJobs.map(
        relativeGemListingSearchJob => {

          let resultClass = ''
          if (relativeGemListingSearchJob.timeEnd && relativeGemListingSearchJob.hadException === false) {
            resultClass = 'success-job'
          } else if (relativeGemListingSearchJob.hadException === true) {
            resultClass = 'failed-job'
          }

          return (
            <tbody>
              <tr className={resultClass}>
                <td>{relativeGemListingSearchJob.uuid}</td>
                <td>{relativeGemListingSearchJob.shape}</td>
                <td>{relativeGemListingSearchJob.source}</td>
                <td>{relativeGemListingSearchJob.gemType}</td>
                <td>{relativeGemListingSearchJob.expectedCount}</td>
                <td>{relativeGemListingSearchJob.retrievedCount}</td>
                <td>{relativeGemListingSearchJob.insertedCount}</td>
                <td>{relativeGemListingSearchJob.newListingsCount}</td>
                <td>{relativeGemListingSearchJob.timeStart}</td>
                <td>{relativeGemListingSearchJob.timeEnd}</td>
                <td>{relativeGemListingSearchJob.duration}</td>
              </tr>
            </tbody>
          );
        }
      );
    }
  }

  render() {
    return (
      <div>
        <TablePagination
          title="RELATIVE GEM LISTING JOBS"
          headerRow={this.headers()}
          rows={this.rows()}
          totalCount={
            this.props.relativeGemListingSearchJobsResp &&
            this.props.relativeGemListingSearchJobsResp.stats.totalCount
          }
          offset={
            this.props.relativeGemListingSearchJobsResp &&
            this.props.relativeGemListingSearchJobsResp.searchOptions.offset
          }
          limit={
            this.props.relativeGemListingSearchJobsResp &&
            this.props.relativeGemListingSearchJobsResp.searchOptions.limit
          }
          nextPageFunc={this.nextPageFunc}
          previousPageFunc={this.previousPageFunc}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelativeGemListingSearchJobs);
