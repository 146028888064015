import AuthListErrors from "../../AuthListErrors";
import React, { useState } from "react";

import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";

import { LOGO_WITH_WORDS } from "../../Icons";
import { Box, Heading, VStack } from "../../../stories/Primatives";
import TwoPanelShell from "../../../stories/CloudManagementOS/shells/TwoPanelShell";
import { UserPasswordUpdate } from "../../../stories/CloudManagementOS/Settings/UserSettings";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { GQL_Q_CURRENT_VENDOR } from "../graph/queries/currentVendor";

const UPDATE_VENDOR_PASSWORD_GQL_MUTATION = gql`
    mutation UpdateVendorPassword(
        $originalPassword: String!
        $newPassword: String!
    ) {
        updateVendorPassword(
            originalPassword: $originalPassword
            newPassword: $newPassword
        ) {
            message
        }
    }
`;

const FirstTimeLoginDescriptionPanel = ({ currentVendor }) => {
    return (
        <Box
            backgroundImage={
                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/hero-image2x.jpg"
            }
            h="100%"
            backgroundSize={"cover"}
            align={"center"}
        >
            <VStack w="75%" position={"relative"} top={"40%"}>
                <Box w="75%">
                    {LOGO_WITH_WORDS({
                        mainColor: "white",
                        accentColor: "#9a7b1d",
                    })}
                </Box>

                <Heading as="h3" color={"white"}>
                    {getConstToLanguage("WELCOME")}{" "}
                    {currentVendor?.vendor?.person?.givenName}!
                </Heading>
                <Box color={"white"}>
                    {getConstToLanguage("FIRST_TIME_LOGIN")}
                </Box>
            </VStack>
        </Box>
    );
};

const UserPasswordUpdatePanel = ({}) => {
    const updateCache = async (cache, { data }) => {
        const currentVendor = await cache.readQuery({
            query: GQL_Q_CURRENT_VENDOR,
        });

        let updatedCurrentVendor = { ...currentVendor };
        console.log(
            "SDLJF:SDLKJF:SDLKJF:SDLKJF:SDLKJF:SDLKFJS:DLKFJ",
            updatedCurrentVendor
        );
        updatedCurrentVendor.currentVendor.vendor.hasResetFirstTimePassword = true;

        // TODO - is there better way to do this??
        window.location.reload();

        // await cache.writeQuery({
        //   query: GQL_Q_CURRENT_VENDOR,
        //   data: updatedCurrentVendor,
        // });
    };

    const [
        updateVendorPassword,
        { data, loading, error },
    ] = useMutation(UPDATE_VENDOR_PASSWORD_GQL_MUTATION, {
        update: updateCache,
    });

    return (
        <Box w="100%" h={"100%"} padding={40}>
            <UserPasswordUpdate
                updatePasswordMutation={updateVendorPassword}
                loading={loading}
            />
        </Box>
    );
};

const VendorFirstLoginResetPassword = (props) => {
    console.log("VENDOR", props.currentVendor);
    return (
        <TwoPanelShell
            h="100vh"
            panel1={
                <FirstTimeLoginDescriptionPanel
                    currentVendor={props.currentVendor}
                />
            }
            panel2={
                <UserPasswordUpdatePanel currentVendor={props.currentVendor} />
            }
        />
    );
};

export default VendorFirstLoginResetPassword;
