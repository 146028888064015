import React from "react";
import Manager from "./Manager";

class GemListingsManagement extends React.Component {
  constructor(props) {
    super(props);

    this.displayTool = this.displayTool.bind(this);
  }

  displayTool() {
    if (this.props.subtool) {
      return (
        <Manager
          param1={this.props.param1}
          param2={this.props.param2}
          subtool={this.props.subtool}
          match={this.props.match}
          submitSearchFunc={this.submitSearchFunc}
          searchValueUpdateFunc={this.searchValueUpdateFunc}
          nextPageFunc={this.nextPageFunc}
          previousPageFunc={this.previousPageFunc}
          gemListingsCount={this.props.gemListingsCount}
          gemListingRows={this.props.gemListingRows}
          searchParam={this.props.gemListingsSearchParam}
          searchFlags={this.props.gemListingsSearchFlags}
          searchStats={this.props.searchStats}
        />
      );
    }
  }

  render() {
    return this.displayTool();
  }
}



export default GemListingsManagement