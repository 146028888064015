import {
  MARKET_GEM_REQUEST__CREATE_GEM_REQUEST_FROM_RELATIVE_GEM_LISTING_URL,
  MARKET_GEM_REQUEST__EDIT_RELATIVE_GEM_LISTING_INFO,
  MARKET_GEM_REQUEST__CHOOSE_GEM_LISTING_INPUT_TYPE,
  MARKET_GEM_REQUEST__UPDATE_RELATIVE_GEM_LISTING_URL,
  MARKET_GEM_REQUEST__ON_LOAD,
  MARKET_GEM_REQUEST__ARCHIVE_GEM_REQUEST
} from "../constants/actionTypes";

export default (
  state = {
    showThisShapeDescription: null,
    relativeGemListingUrl: "",
    gemListingInputSelector: null,
    myGemRequests: null
  },
  action
) => {
  let newListOfMyGemRequests = { ...state.myGemRequests };
  switch (action.type) {
    case MARKET_GEM_REQUEST__ON_LOAD:
      return { ...state, myGemRequests: action.payload[0] };
    case MARKET_GEM_REQUEST__UPDATE_RELATIVE_GEM_LISTING_URL:
      return { ...state, relativeGemListingUrl: action.relativeGemListingUrl };
    case MARKET_GEM_REQUEST__CREATE_GEM_REQUEST_FROM_RELATIVE_GEM_LISTING_URL:
      newListOfMyGemRequests.gemRequests.push(action.payload.gemRequest);
      return { ...state, myGemRequests: newListOfMyGemRequests };
    case MARKET_GEM_REQUEST__EDIT_RELATIVE_GEM_LISTING_INFO:
      return { ...state, [action.key]: action.value };
    case MARKET_GEM_REQUEST__CHOOSE_GEM_LISTING_INPUT_TYPE:
      return { ...state, gemListingInputSelector: action.inputType };
    case MARKET_GEM_REQUEST__ARCHIVE_GEM_REQUEST:
      newListOfMyGemRequests.gemRequests = newListOfMyGemRequests.gemRequests.filter(
        gemRequest => gemRequest._id !== action.gemRequest._id
      );
      return { ...state, myGemRequests: newListOfMyGemRequests };
    default:
      return state;
  }
};
