import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { urlConstToConst } from "../../../../stories/CloudManagementOS/ConstToUrl";
import { Header } from "../../../../stories/CloudManagementOS/Header";
import MainActionMenu from "../../../../stories/CloudManagementOS/MainActionMenu";
import { ModelManager } from "../../../../stories/CloudManagementOS/ModelManager";
import TreeHeader from "../../../../stories/CloudManagementOS/TreeHeader";
import { ARROW_DOWN_ICON } from "../../../Icons";
import { iconography } from "../../../IconsV2";
import SalesOrderListItem from "./SalesOrderListItem";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";

function header(props) {
    let modelConst = "CURRENT_SALES_ORDERS";

    let modelSelectorConfig = {
        tree: {
            CURRENT_SALES_ORDERS: {},
        },
        currentSelection: [
            modelConst,
            urlConstToConst(props.match.params.param1),
        ],
    };

    let actionMenuButtons = {};

    return (
        <Header
            main={
                <TreeHeader
                    // urlPrefix={
                    //   props.userType === "vendor"
                    //     ? "/vendor/gem-requests-manager"
                    //     : "/admin/gem-requests-manager"
                    // }
                    dropDownIcon={ARROW_DOWN_ICON}
                    tree={modelSelectorConfig.tree}
                    currentSelection={modelSelectorConfig.currentSelection}
                />
            }
            actionMenu={<MainActionMenu buttons={actionMenuButtons} />}
        />
    );
}

function listItemFunc(salesOrderItem) {
    return (
        <SalesOrderListItem
            item={salesOrderItem}
            key={salesOrderItem.data.id}
            // itemIdentifierIcon={GEM_REQUEST}
            iconography={iconography}
        />
    );
}

const SalesOrderManager = (props) => {
    let searchQuery = props.searchQuery;

    let state = urlConstToConst(props.match.params.param1);

    const { loading, error, data } = useQuery(searchQuery, {
        variables: { state },
    });

    let items;
    if (data) {
        // TODO -make into nodes
        items = data.searchCurrentSalesOrders.map((datum) => ({
            data: datum,
        }));
    }

    return (
        <ModelManager
            modelName={getConstToLanguage("SALES_ORDER")}
            loading={loading}
            items={items}
            header={header(props)}
            listItemFunc={listItemFunc}
            isDataLoading={loading}
            loadMoreIcon={ARROW_DOWN_ICON}
            dropdownIcon={ARROW_DOWN_ICON}
            template="template1"
            // param1={this.match.params.param1}
            totalCount={
                data &&
                data.searchsalesOrdersInState &&
                data.searchsalesOrdersInState.totalCount
            }
            pageInfo={
                data &&
                data.searchsalesOrdersInState &&
                data.searchsalesOrdersInState.pageInfo
            }
        />
    );
};

export default SalesOrderManager;
