import {
  MARKET_HOME_PAGE_LOADED,
  MARKET_HOME_PAGE_UNLOADED,
  MARKET_UPDATE_PRICE_RANGE_IN_SEARCH_PARAM,
  MARKET_UPDATE_CUT_RANGE_IN_SEARCH_PARAM,
  MARKET_UPDATE_CLARITY_RANGE_IN_SEARCH_PARAM,
  MARKET_UPDATE_COLOR_RANGE_IN_SEARCH_PARAM,
  MARKET_GEM_SEARCH,
  UPDATE_SEARCH_SORT_BY_ATTR,
  MARKET_UPDATE_CARAT_RANGE_IN_SEARCH_PARAM,
  GET_DIAMOND_RELATIVE_PRICE_DATA,
  MARKET_GEM_RESERVATION_START_PAGE_LOADED,
  MARKET_USER_START_RESERVE_PROCESS,
  MARKET_USER_CLOSE_RESERVE_GEM_VIEW,
  MARKET_GEM_LISTINGS_PAGE_LOADED,
  MARKET_TOGGLE_GEM_SHAPE,
  MARKET_USER_CANCEL_GEM_RESERVATION,
  MARKET_USER_RESERVE_GEM,
  MARKET_LOGOUT,
  MARKET_GEM_LISTINGS_PREVIOUS_PAGE,
  MARKET_GEM_LISTINGS_NEXT_PAGE,
  ASYNC_END,
  ASYNC_START
} from "../constants/actionTypes";

export default (state = initialState(), action) => {
  let buildSearchInitialGemRows;
  switch (action.type) {
    case MARKET_UPDATE_CUT_RANGE_IN_SEARCH_PARAM:
      return {
        ...state,
        searchParam: { ...state.searchParam, cutRange: action.cutRange }
      };
    case MARKET_UPDATE_CLARITY_RANGE_IN_SEARCH_PARAM:
      return {
        ...state,
        searchParam: { ...state.searchParam, clarityRange: action.clarityRange }
      };
    case MARKET_UPDATE_COLOR_RANGE_IN_SEARCH_PARAM:
      return {
        ...state,
        searchParam: { ...state.searchParam, colorRange: action.colorRange }
      };

    case UPDATE_SEARCH_SORT_BY_ATTR:
      return {
        ...state,
        searchParam: { ...state.searchParam, sortBy: action.attribute }
      };

    case MARKET_USER_START_RESERVE_PROCESS:
      return { ...state, reserveGemViewGem: action.gemListing };

    case MARKET_USER_CLOSE_RESERVE_GEM_VIEW:
      return { ...state, reserveGemViewGem: null };

    case MARKET_UPDATE_PRICE_RANGE_IN_SEARCH_PARAM:
      console.log(action.priceRange);
      return {
        ...state,
        searchParam: {
          ...state.searchParam,
          priceRange: [action.priceRange[0], action.priceRange[1]]
        }
      };
    case MARKET_GEM_RESERVATION_START_PAGE_LOADED:
      return {
        ...state,
        userGemListingReservations: action.payload[0].gemListingReservations
      };
    case MARKET_UPDATE_CARAT_RANGE_IN_SEARCH_PARAM:
      return {
        ...state,
        searchParam: {
          ...state.searchParam,
          caratRange: [action.caratRange[0], action.caratRange[1]]
        }
      };
    case MARKET_TOGGLE_GEM_SHAPE:
      let newShapesList = state.searchParam.shapes.slice();

      if (newShapesList.includes(action.shape)) {
        newShapesList = newShapesList.filter(shape => shape !== action.shape);
      } else {
        newShapesList.push(action.shape);
      }

      return {
        ...state,
        searchParam: { ...state.searchParam, shapes: newShapesList }
      };
    
    case MARKET_GEM_LISTINGS_PREVIOUS_PAGE:
    case MARKET_GEM_LISTINGS_NEXT_PAGE:
    let nextOrPreviousSearchInitialGemRows;
      if (action.payload.gemListings) {
        nextOrPreviousSearchInitialGemRows = action.payload.gemListings.map(gemListing => {
          return { gemListing: gemListing };
        });
      } else {
        buildSearchInitialGemRows = [];
      }


      return {
        ...state,
        gemListingRows: nextOrPreviousSearchInitialGemRows,
        gemListingsCount: action.payload.gemListingsCount,
        gemListingsSearchParam: action.searchParam
      };

    case MARKET_GEM_SEARCH:
      if (action.payload.gemListings) {
        buildSearchInitialGemRows = action.payload.gemListings.map(gemListing => {
          return { gemListing: gemListing };
        });
      } else {
        buildSearchInitialGemRows = [];
      }

      return {
        ...state,
        gemListingRows: buildSearchInitialGemRows,
        gemListingsCount: action.payload.gemListingsCount,
        gemListingsSearchParam: action.searchParam
      };

    case GET_DIAMOND_RELATIVE_PRICE_DATA:
      return {
        ...state,
        gemRelativeData: action.payload
      };
    case MARKET_USER_RESERVE_GEM:
      let newListOfGemListingReservations = state.userGemListingReservations.slice();

      newListOfGemListingReservations.push(action.payload.gemListingReservation);

      return {
        ...state,
        userGemListingReservations: newListOfGemListingReservations
      };

    case MARKET_USER_CANCEL_GEM_RESERVATION:
      return {
        ...state,
        userGemListingReservations: state.userGemListingReservations.filter(
          gemListingReservation =>
            gemListingReservation._id !== action.payload.gemListingReservation._id
        )
      };

    case MARKET_GEM_LISTINGS_PAGE_LOADED:
      let buildInitialGemRows = action.payload[0].gemListings.map(gemListing => {
        return { gemListing: gemListing };
      });
      return {
        ...state,
        gemListingRows: buildInitialGemRows,
        gemListingsCount: action.payload[0].gemListingsCount,
        searchStats: action.payload[0].stats
      };

    case MARKET_HOME_PAGE_LOADED:
      return {
        ...state,
        userGemListingReservations: action.payload[0].gemListingReservations
      };
    case MARKET_LOGOUT:
      return {
        ...state,
        userGemListingReservations: null
      };
    case MARKET_HOME_PAGE_UNLOADED:
      return initialState();
    case ASYNC_START:
      if (
        action.subtype === MARKET_GEM_SEARCH ||
        action.subtype === MARKET_HOME_PAGE_LOADED
      ) {
        return { ...state, gemListingSearchInProgress: true };
      }
      return state;
    case ASYNC_END:
      if (
        action.subtype === MARKET_GEM_SEARCH ||
        action.subtype === MARKET_HOME_PAGE_LOADED
      ) {
        return { ...state, gemListingSearchInProgress: false };
      }
      return state;
    default:
      return state;
  }
};

function initialState() {
  return {
    searchParam: {
      shapes: [
        "MARQUISE",
        "ROUND",
        "PRINCESS",
        "CUSHION",
        "EMERALD",
        "OVAL",
        "RADIANT",
        "ASSCHER",
        "HEART",
        "PEAR",
        "EUROPEAN_CUT",
        "OLD_MINER",
        "TRILLIANT"
      ],
      priceRange: [0, 100000],
      caratRange: [0, 5],
      cutRange: ["Fair", "Excellent"],
      colorRange: ["K", "D"],
      clarityRange: ["I2", "FL"],
      offset: 0,
      limit: 20,
      sortBy: ""
    },
    gemListingSearchInProgress: false
  };
}
