import { Icon } from "@chakra-ui/react";
import React from "react";

export const Video360Icon = ({ color = "#00000" }) => (
    <Icon
        viewBox="0 0 312.02 159.64"
        height={"auto"}
        width={"100%"}
        fill={color}
    >
        <path
            class="cls-1"
            d="M72.77,34c.4-15.96,13.58-21.31,23.09-21.31,12.88,0,21.51,8.72,21.51,19.33,0,9.02-6.05,13.28-9.81,15.36,13.38,4.56,14.07,15.16,14.07,18.43,0,13.08-11.6,22.99-25.97,22.99-8.03,0-16.95-3.17-21.51-9.81-3.27-4.46-4.06-9.91-4.26-13.28h14.87c.4,4.66,3.96,10.01,11.2,10.11,7.14,0,10.9-4.96,10.9-11.1,0-7.24-6.84-10.51-12.59-10.51h-5.95v-11h3.27c5.05,0,11-2.87,11-9.12,0-4.46-2.58-8.42-7.33-8.42-6.24,0-7.73,5.05-7.93,8.33h-14.57Z"
        />
        <path
            class="cls-1"
            d="M169.67,14.08l-13.78,19.33c-.99,1.49-2.08,2.87-3.17,4.46,.79-.4,3.57-1.09,5.85-1.09,11.2,0,23.29,8.62,23.29,24.78s-12.29,27.26-27.35,27.26c-9.02,0-16.95-3.47-21.61-9.51-4.46-5.05-6.14-12.19-6.14-17.74,0-7.73,3.17-13.97,6.64-19.33l19.23-28.15h17.05Zm-28.35,48.76c0,7.83,5.95,12.98,12.69,12.98,7.33,0,13.08-5.65,13.08-12.98s-5.55-13.08-12.98-13.08c-6.54,0-12.79,4.96-12.79,12.98v.1Z"
        />
        <path
            class="cls-1"
            d="M187.51,39.65c0-21.21,17.34-26.96,26.46-26.96,10.31,0,26.26,6.94,26.26,26.76v22.6c0,20.02-15.86,26.76-26.07,26.76s-26.66-6.74-26.66-26.76v-22.4Zm14.77,22.8c0,6.64,3.57,13.38,11.6,13.38s11.6-7.73,11.6-13.38v-23.39c0-4.16-1.59-13.38-11.69-13.38-8.23,0-11.5,7.04-11.5,13.48v23.29Z"
        />
        <g>
            <path
                class="cls-1"
                d="M254.17,50.73v5.41c25.69,7.14,41.61,17.05,41.61,28.01,0,16.97-38.17,31.44-91.66,36.96v16.07c62.62-6.5,107.9-25.35,107.9-47.58,0-15.69-22.56-29.7-57.85-38.87Z"
            />
            <path
                class="cls-1"
                d="M162.88,123.45c-2.28,.03-4.56,.05-6.87,.05-77.19,0-139.77-17.62-139.77-39.35,0-10.96,15.92-20.88,41.61-28.01v-5.41C22.56,59.9,0,73.91,0,89.6c0,27.61,69.85,50,156.01,50,2.3,0,4.59-.02,6.87-.05v20.09l29.76-30.3-29.76-25.87v19.99Z"
            />
        </g>
        <path
            class="cls-1"
            d="M258.03,0c-7.71,0-14.52,5.73-14.52,14.59v.04c0,7.41,5.99,14.11,14.48,14.11s14.59-6.51,14.59-14.37-6.17-14.37-14.56-14.37Zm-8.13,14.33c0-4.42,3.13-8.57,8.16-8.57s8.13,4.42,8.13,8.64-2.99,8.57-8.09,8.57-8.2-4.01-8.2-8.6v-.03Z"
        />
    </Icon>
);
