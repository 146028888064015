import { useMutation } from "@apollo/client";
import {
    Avatar,
    AvatarGroup,
    Box,
    Center,
    Container,
    DarkMode,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { LogoWithWordsIcon } from "../../IconsV2";
import { SignUpForm } from "./VendorOrganizationInquiryForm";
import { CREATE_VENDOR_ORGANIZATION_INQUIRY } from "../../../graph/mutations/VendorOrganizationInquiry";

export const VendorOrganizationInquiryBody = () => {
    const [signUpMutation] = useMutation(CREATE_VENDOR_ORGANIZATION_INQUIRY);

    const signUp = React.useCallback(
        async (flattenedVariables) => {
            let constructedVariables = {
                email: flattenedVariables.email,
                phoneNumber: flattenedVariables.phoneNumber,
                organization: {
                    legalName: flattenedVariables.organizationName,
                    organizationType: "LLC",
                },
                person: {
                    givenName: flattenedVariables.givenName,
                    familyName: flattenedVariables.familyName,
                },
                vendorTypes: [],
            };

            if (flattenedVariables?.trader) {
                constructedVariables.vendorTypes.push({
                    vendorRole: "GEM_TRADER",
                    category: "CERTIFIED_COLORLESS_DIAMONDS",
                });
            }

            if (flattenedVariables?.jewelryMaker) {
                constructedVariables.vendorTypes.push({
                    vendorRole: "JEWELRY_MAKER",
                    category: "ENGAGEMENT_RINGS",
                });
            }

            return await signUpMutation({ variables: constructedVariables });
        },
        [signUpMutation]
    );

    return (
        <Flex
            paddingTop={"6.94rem"}
            minH={{ base: "auto", md: "100vh" }}
            bgGradient={useBreakpointValue({
                md: mode(
                    "linear(to-r, blue.600 50%, white 50%)",
                    "linear(to-r, blue.600 50%, gray.900 50%)"
                ),
            })}
        >
            <Flex
                // maxW="8xl"
                // mx="auto"

                width="full"
                direction={{ base: "column", md: "row" }}
            >
                <Box
                    flex="1"
                    h="full"
                    minH={"lg"}
                    bgSize="cover"
                    backgroundPosition={"center"}
                    bgImage="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/pexels-the-glorious-studio-9332185.jpg"
                >
                    <Center h="full">
                        <DarkMode>
                            <Flex
                                direction="column"
                                // px={{ base: "4", md: "8" }}
                                height="full"
                                color="on-accent"
                            >
                                {/* <Flex align="center" h="24">
                                    <LogoWithWordsIcon />
                                </Flex> */}
                                {/* <Flex flex="1" align="center"> */}
                                <Stack spacing="8">
                                    <Stack
                                        spacing="6"
                                        textAlign={"center"}
                                        paddingTop={{ base: "15%", md: "21%" }}
                                    >
                                        <Heading
                                            size={useBreakpointValue({
                                                md: "lg",
                                                xl: "2xl",
                                            })}
                                        >
                                            BECOME A VENDOR TODAY
                                        </Heading>
                                        <Text fontSize="lg" fontWeight="medium">
                                            Sign up to become one of our trusted
                                            vendors.
                                        </Text>
                                    </Stack>
                                </Stack>
                                {/* </Flex> */}
                                <Flex align="center" h="24"></Flex>
                            </Flex>
                        </DarkMode>
                    </Center>
                </Box>

                <Center flex="1">
                    <Container maxW="md">
                        <SignUpForm signUpFunc={signUp} />
                    </Container>
                </Center>
            </Flex>
        </Flex>
    );
};
