import { Icon } from "@chakra-ui/react";
import React from "react";

export const RadiantShapeIcon = ({ color = "#000000", height = 'auto' }) => (
    <Icon viewBox="0 0 481.642 581.625" fill={color} height={height} width={'100%'} >
        <g
            id="Diamonds_Radiant_Outlined"
            data-name="Diamonds_Radiant Outlined"
            transform="translate(-53.806 -115.805)"
        >
            <g id="Diamonds">
                <g id="Grupo_47" data-name="Grupo 47">
                    <g id="XMLID_428_">
                        <path
                            id="XMLID_453_"
                            d="M194.753,166.985H400.085l.714.6,2.011-.6,38.822-48.735-.612-.445H148.475l41.035,49.18Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_452_"
                            d="M120.616,220.3l2.151,1.228,65.347-54.546h1.4l-41.035-49.18h-1.3L56.464,183.649Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_451_"
                            d="M400.8,167.582l64.975,54.235h0l.226.188v.161l1.4-.161,65-36.143v-1.735L441.632,118.25,402.81,166.985Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_450_"
                            d="M122.2,590.523V230.037l-1.583-9.734L56.464,183.649l-.658.478V628.2l65.817-37.605Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_449_"
                            d="M122.2,222.005l.568-.474-2.151-1.228,1.583,9.734v-8.032Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_448_"
                            d="M532.393,192.933v-7.071l-65,36.143-1.4.161v368.6l-.106.088,66.5,36.978v-434.9Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_447_"
                            d="M465.817,590.921h0l-65.382,54.574.4.12,39.315,49.354h.867l91.373-66.323v-.811l-66.5-36.978Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_446_"
                            d="M187.808,645.533,122.2,590.769v-.18h0v-.066l-.576.072L55.806,628.2v.446L146.668,694.6l40.87-48.983Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_445_"
                            d="M400.435,645.495l-.35.293H188.114l-.306-.255-.27.082L146.668,694.6l.511.371H440.153l-39.315-49.354Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_444_"
                            d="M400.8,167.582l-.714-.6H189.51L294.674,199.05Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_443_"
                            d="M181.443,229.289l1.014-.271h0L294.674,199.05,189.51,166.985h-1.4l-65.347,54.546.828.474Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_442_"
                            d="M404,229.254l29.722,173.891L465.707,222.2l-61.582,7.09Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_441_"
                            d="M404,229.254h0l.126.035,61.582-7.09.067-.382L400.8,167.582,294.674,199.05Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_440_"
                            d="M402.153,583.312l63.271,7.283.372.208L433.721,403.145,401.859,583.393Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_439_"
                            d="M294.674,199.05,182.457,229.018,151.875,412.526l27.78,170.835L292.7,613.55l109.157-30.157,31.862-180.248L404,229.254Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_438_"
                            d="M122.21,590.522l57.26-7.21.185.049-27.78-170.835Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_437_"
                            d="M433.721,403.145,465.8,590.8l.1.054.106-.088v-368.6l-.293.033Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_436_"
                            d="M465.817,590.921l.077-.064-.1-.054Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_435_"
                            d="M465.424,590.595l-63.271-7.283-.294.081h0L292.7,613.55l107.733,31.945,65.382-54.574-.021-.118Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_434_"
                            d="M465.774,221.817l-.067.382.293-.033v-.161Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_433_"
                            d="M187.808,645.533l.306.255H400.085l.35-.293L292.7,613.55Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_432_"
                            d="M179.655,583.361h0l-.185-.049-57.26,7.21-.011.067v.18l65.609,54.764L292.7,613.55Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_431_"
                            d="M151.875,412.526,122.2,230.037V590.523h.011Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_430_"
                            d="M182.457,229.018l-1.014.271L123.6,222.005l-.828-.474-.568.474v8.032l29.676,182.489Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_429_"
                            d="M122.2,590.523v.066l.011-.067Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <path
                        id="Trazado_1666"
                        data-name="Trazado 1666"
                        d="M442.688,118.712l-.613-.445H148.234L57.519,184.111l-.658.478V629.108l90.862,65.952.511.37H442.075l91.373-66.322V184.589Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
