import React from "react";
import "./css/market-os.css";
import { Switch } from "react-router-dom";
import { Nav } from "../../../stories/Market/Nav";
import { Box } from "@chakra-ui/react";

const MarketOS = ({
    siteMap,
    slideInMenuStyling,
    menuVariant,
    navigationMap,
    iconography,
    removeItemFunc,
    match,
    h = "inherid",
}) => {
    return (
        <Box>
            <Nav
                iconography={iconography}
                removeItemFunc={removeItemFunc}
                colorMode="dark"
                boxShadow="base"
                slideInMenuStyling={slideInMenuStyling}
                menuVariant={menuVariant}
            />
            {/* <Navigation
          navigationMap={navigationMap}
          iconography={props.iconography}
          currentMarketUser={currentMarketUser}
          logOut={props.logOut}
          removeItemFunc={props.removeItemFunc}
        /> */}
            <Box paddingTop={"6.94rem"} pos="absolute" w="100%" h={h}>
                <Switch>{siteMap}</Switch>
            </Box>
        </Box>
    );
};

export default MarketOS;
