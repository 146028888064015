import React from "react";
import { ModelManager } from "../../../../../stories/CloudManagementOS/ModelManager";
import VendorOrganizationListItem from "./VendorOrganizationListItem";
import { SEARCH_ICON, PREVIOUS_ICON, NEXT_ICON } from "../../../../Icons";
import { getConstToLanguage } from "../../../../../stories/CloudManagementOS/ConstToLanguageMapping";

import { GQL_Q_SEARCH_VENDOR_ORGANIZATIONS } from "../../../../../graph/queries/VendorOrganization/searchVendorOrganizations";
import { Query } from "@apollo/react-components";

var _ = require("lodash");

class VendorOrganizationSearchAndList extends React.Component {
    displayColumnsBasedOnSubTool(subtool) {
        switch (subtool) {
        }
    }

    rows() {
        if (this.props.gemListingRows) {
            return this.props.gemListingRows.map((gemListingRow) => {
                return (
                    <VendorOrganizationListItem
                        key={gemListingRow.gemListing._id}
                        gemListingRow={gemListingRow}
                        singleGemListingToolbar={
                            this.props.singleGemListingToolbar
                        }
                        subtool={this.props.subtool}
                        priceChangeFunc={this.props.priceChangeFunc}
                        acqPriceChangeFunc={this.props.acqPriceChangeFunc}
                        togglePriceEditModeFunc={
                            this.props.togglePriceEditModeFunc
                        }
                        editPriceFunc={this.props.editPriceFunc}
                        editAcqPriceFunc={this.props.editAcqPriceFunc}
                        toggleAcqPriceEditModeFunc={
                            this.props.toggleAcqPriceEditModeFunc
                        }
                        options={this.props.options}
                        reservedGemListings={this.props.reservedGemListings}
                        priceChangeInProgress={this.props.priceChangeInProgress}
                        acqPriceChangeInProgress={
                            this.props.acqPriceChangeInProgress
                        }
                        cancelArchiveFunc={this.props.cancelArchiveFunc}
                        confirmArchiveFunc={this.props.confirmArchiveFunc}
                    />
                );
            });
        }
    }

    listItemFunc(vendorOrganizationItem) {
        return <VendorOrganizationListItem item={vendorOrganizationItem} />;
    }

    renderHeader() {
        return (
            <div className="model-manager-header">
                {getConstToLanguage("VENDOR_ORGANIZATION")} :
            </div>
        );
    }

    searchSection() {
        return <div className="search-inner"></div>;
    }

    renderSearchParamFilter(attr, value) {}

    render() {
        // const history = useHistory();
        let header = this.renderHeader();
        let modelSelectorConfig = {
            model: {
                pluralConst: "VENDOR_ORGANIZATIONS",
                categories: this.props.subtoolSelectorMenu,
                states: [""],
            },
            currentSelection: {
                category: this.props.subtool,
                state: this.props.param1,
                otherPossibleStates: this.props.stateSelectorMenu,
            },
        };

        let actionMenuButtons = [];

        let toolbar = {
            actions: {
                paginationTools: {
                    nextPage: {
                        icon: NEXT_ICON,
                        func: this.props.nextPageFunc,
                    },
                    previousPage: {
                        icon: PREVIOUS_ICON,
                    },
                },
            },
        };

        return (
            <Query query={GQL_Q_SEARCH_VENDOR_ORGANIZATIONS}>
                {({ loading, error, data }) => {
                    let items;
                    if (data) {
                        items = data.searchVendorOrganizations.map((datum) => ({
                            data: datum,
                        }));
                    }

                    // if (
                    //   error &&
                    //   error.graphQLErrors[0].extensions.code ===
                    //     "AUTH__INVALID_ADMIN_TOKEN"
                    // ) {
                    //   history.push("/admin/login");
                    // }

                    return (
                        <ModelManager
                            isDataLoading={loading}
                            items={items}
                            listItemFunc={this.listItemFunc}
                            searchParamFilterRenderFunc={
                                this.renderSearchParamFilter
                            }
                            totalCount={this.props.gemListingsCount}
                            newSearchParam={this.props.newSearchParam}
                            header={header}
                            modelSelectorConfig={modelSelectorConfig}
                            actionMenuButtons={actionMenuButtons}
                            param1={this.props.param1}
                            searchSection={this.searchSection}
                            template="template1"
                            nextIcon={NEXT_ICON}
                            searchIcon={SEARCH_ICON}
                            previousIcon={PREVIOUS_ICON}
                            submitSearchFunc={this.props.submitSearchFunc}
                            searchValueUpdateFunc={
                                this.props.searchValueUpdateFunc
                            }
                            // nextPageFunc={}
                            previousPageFunc={this.props.previousPageFunc}
                            gemListingsCount={this.props.gemListingsCount}
                            gemListingRows={this.props.gemListingRows}
                            searchParam={this.props.searchParam}
                            searchFlags={this.props.searchFlags}
                            subtool={this.props.subtool}
                            singleGemListingToolbar={
                                this.props.singleGemListingToolbar
                            }
                            priceChangeFunc={this.props.priceChangeFunc}
                            acqPriceChangeFunc={this.props.acqPriceChangeFunc}
                            togglePriceEditModeFunc={
                                this.props.togglePriceEditModeFunc
                            }
                            toggleAcqPriceEditModeFunc={
                                this.props.toggleAcqPriceEditModeFunc
                            }
                            editPriceFunc={this.props.editPriceFunc}
                            editAcqPriceFunc={this.props.editAcqPriceFunc}
                            stateSelectorMenu={this.props.stateSelectorMenu}
                            currentSelectedGLS={this.props.currentSelectedGLS}
                            options={this.props.options}
                            changeGLSSelector={this.props.changeGLSSelector}
                            globalGLSStats={this.props.globalGLSStats}
                            searchStats={this.props.searchStats}
                            reservedGemListings={this.props.reservedGemListings}
                            priceChangeInProgress={
                                this.props.priceChangeInProgress
                            }
                            acqPriceChangeInProgress={
                                this.props.acqPriceChangeInProgress
                            }
                            cancelArchiveFunc={this.props.cancelArchiveFunc}
                            confirmArchiveFunc={this.props.confirmArchiveFunc}
                            // headerRow={this.tableHeaderRow(this.props.subtool, this.props.options)}
                            rows={this.rows(this.props)}
                        />
                    );
                }}
            </Query>
        );
    }
}

export default VendorOrganizationSearchAndList;
