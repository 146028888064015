import React from "react";

import {
  SubNavAndBodyWithModes,
  SubNavAndBodyWithModesBody,
  SubMenuSideNav,
} from "../shells/SubNavAndBodyWithModes";

import { useBreakpointValue } from "../../Primatives";
import { getConstToLanguage } from "../ConstToLanguageMapping";

export const Help = ({ routePrefix, navConfig }) => {
  const btnRef = React.useRef();
  const subNavAndBodyWithMode = useBreakpointValue({
    base: "drawer",
    lg: "full",
  });

  return (
    <SubNavAndBodyWithModes
      mode={subNavAndBodyWithMode}
      nav={
        <SubMenuSideNav
          title={getConstToLanguage("HELP")}
          navConfig={navConfig}
          routePrefix={routePrefix}
        />
      }
      body={
        <SubNavAndBodyWithModesBody
          navConfig={navConfig}
          routePrefix={routePrefix}
        />
      }
    ></SubNavAndBodyWithModes>
  );
};
