import { useMutation } from "@apollo/react-hooks";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { AuthLogin } from "../../UtilityComponents/AuthLogin";
import { CurrentMarketUserContext } from "../../UtilityComponents/MarketOS/context";
import { useLoginAsMarketUser } from "../../../api_gateway/hooks/carbonext/graph/mutations/loginAsMarketUser";
import { useTranslation } from "react-i18next";
import qs from "qs";

function MarketLogin(props) {
    const history = useHistory();
    const { login } = useContext(CurrentMarketUserContext);
    let { t } = useTranslation();

    let queryParams = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
    });

    let postAuthenticatedCallBack = (token) => {
        if (token) {
            login(token);
            let redirectTo = queryParams.redirect || "/market/diamonds";
            history.push(redirectTo);
            history.go();
        }
    };

    const [loginAsMarketUserMutation] = useLoginAsMarketUser([
        "token",
        "userType",
        "type",
    ]);

    const callLoginAsMarketUserMutation = (variables) =>
        loginAsMarketUserMutation({ variables });

    return (
        <AuthLogin
            h={"calc(100vh - 6.94rem)"}
            variant="two-panel-template"
            logo={null}
            createAccountLink={"/market/sign-up"}
            forgotPasswordLink="/market-user/reset-password"
            headingSubtext={t("marketing:LOGIN_TO_ACCOUNT_MSG")}
            heading={t("WELCOME_BACK")}
            postAuthenticatedCallBack={postAuthenticatedCallBack}
            loginFunc={callLoginAsMarketUserMutation}
            initialResult={queryParams?.initialResult}
        />
    );
}

export default MarketLogin;
