import { Icon } from "@chakra-ui/react";
import React from "react";

export const PhoneIcon = ({
    color = "white",
    w = 98,
    h = "auto",
    fill = "#9A7B2D",
}) => (
    <Icon w={w} h={h} viewBox="0 0 98 98" color={color}>
        <path
            d="M58.3236 73.9866C53.4951 73.9866 46.4717 71.4685 37.0358 62.1211C25.1703 50.3645 24.204 42.3816 25.4834 37.7572C25.8407 36.5118 26.654 35.4705 27.7735 34.8172L31.326 32.7041C32.4523 32.0337 33.9053 32.3604 34.6335 33.4425L40.3059 41.8746C41.0171 42.9362 40.7823 44.379 39.7683 45.1617L36.5833 47.6185C36.1579 47.9417 36.0388 48.51 36.2974 48.9728C36.3825 49.1225 36.4642 49.2722 36.5492 49.4287C37.3999 50.977 38.4616 52.9064 42.3884 56.8026C46.305 60.6851 48.2514 61.7366 49.8167 62.5805C49.98 62.669 50.1365 62.754 50.2931 62.8391C50.7558 63.0943 51.3513 62.9718 51.6712 62.5567L54.1518 59.3989C54.9378 58.3985 56.3874 58.1637 57.4525 58.8646L65.9628 64.486C67.0585 65.2108 67.3886 66.657 66.708 67.7799L64.5745 71.3052C63.9178 72.4077 62.8663 73.2142 61.6209 73.568C60.6647 73.8267 59.569 73.9934 58.327 73.9934L58.3236 73.9866ZM32.5884 33.7283C32.3978 33.7283 32.2039 33.7794 32.0269 33.8814L28.471 35.9946C27.6544 36.471 27.0623 37.2264 26.8071 38.1247C25.6263 42.3918 26.6097 49.8541 38.0022 61.1411C49.3981 72.4315 56.9387 73.4047 61.2534 72.2342C62.1551 71.979 62.9174 71.3937 63.3971 70.5906L65.5341 67.0619C65.8301 66.5719 65.6838 65.9424 65.204 65.6226L56.6971 60.0012C56.2241 59.6881 55.581 59.7936 55.2339 60.236L52.7567 63.3903C52.0149 64.3465 50.7014 64.6153 49.6329 64.0301C49.4798 63.945 49.3267 63.8633 49.1667 63.7783C47.5232 62.8935 45.4781 61.791 41.422 57.7655C37.3557 53.7298 36.243 51.7052 35.3481 50.0787C35.263 49.9255 35.1813 49.7792 35.0997 49.6329C34.5144 48.5849 34.7934 47.2476 35.7496 46.5194L38.9312 44.066C39.3735 43.7257 39.479 43.0962 39.166 42.6334L33.4935 34.2047C33.286 33.8951 32.9389 33.7249 32.5884 33.7249V33.7283Z"
            fill={fill}
        />
        <path
            d="M66.1262 50.5109H64.7548C64.7548 46.3016 63.1011 42.3442 60.0964 39.3668C57.0918 36.3893 53.0935 34.7492 48.8401 34.7492V33.3779C53.4576 33.3779 57.7962 35.1609 61.0628 38.3936C64.3295 41.6296 66.1296 45.9341 66.1296 50.5109H66.1262Z"
            fill={fill}
        />
        <path
            d="M72.6527 50.5109H71.2814C71.2814 44.573 68.9471 38.9925 64.7106 34.7968C60.4742 30.5978 54.8358 28.2839 48.8401 28.2839V26.9126C55.1998 26.9126 61.1785 29.366 65.677 33.8236C70.1755 38.2813 72.6527 44.2089 72.6527 50.5143V50.5109Z"
            fill={fill}
        />
        <path
            d="M59.5997 50.5108H58.2283C58.2283 48.0302 57.2517 45.6959 55.4823 43.9401C53.7095 42.1843 51.3513 41.2145 48.8435 41.2145V39.8431C51.7154 39.8431 54.4172 40.9524 56.4487 42.9669C58.4835 44.9813 59.6031 47.6627 59.6031 50.5108H59.5997Z"
            fill={fill}
        />
    </Icon>
);
