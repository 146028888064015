import React from "react";
import { ModelManager } from "../../../stories/CloudManagementOS/ModelManager";
import { useQuery } from "@apollo/react-hooks";
import { urlConstToConst } from "../../../stories/CloudManagementOS/ConstToUrl";
import { Header } from "../../../stories/CloudManagementOS/Header";
import TreeHeader from "../../../stories/CloudManagementOS/TreeHeader";
import MainActionMenu from "../../../stories/CloudManagementOS/MainActionMenu";
import GemRequestListItem from "./GemRequestListItem";
import {
  ARROW_DOWN_ICON,
  ARROW_RIGHT_ICON,
  LINK_ICON,
  ACTION_ICON,
  GEM_REQUEST
} from "../../Icons";

function header(props) {
  let modelConst = "GEM_REQUESTS";

  let modelSelectorConfig = {
    tree: {
      GEM_REQUESTS: {
        children: {
          UNFULFILLED: {},
          FULFILLED: {},
          ARCHIVED: {}
        }
      }
    },
    currentSelection: [modelConst, urlConstToConst(props.match.params.param1)]
  };


  let actionMenuButtons = {};

  return (
    <Header
      main={
        <TreeHeader
          urlPrefix={
            props.userType === "vendor"
              ? "/vendor/gem-requests-manager"
              : "/admin/gem-requests-manager"
          }
          dropDownIcon={ARROW_DOWN_ICON}
          tree={modelSelectorConfig.tree}
          currentSelection={modelSelectorConfig.currentSelection}
        />
      }
      actionMenu={<MainActionMenu buttons={actionMenuButtons} />}
    />
  );

  // return (
  //   <Query query={GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS}>
  //     {({ loading, error, data, fetchMore }) => {
  //       if (data) {
  //         Object.keys(modelSelectorConfig.tree[modelConst].children).forEach(
  //           category => {
  //             if (
  //               category in
  //               data.getGlobalStatsFromSearchOfGemListings.counts.byCategory
  //             ) {

  //               modelSelectorConfig.tree[modelConst].children[
  //                 category
  //               ].count =
  //                 data.getGlobalStatsFromSearchOfGemListings.counts.byCategory[
  //                   category
  //                 ];
  //             }
  //             Object.keys(
  //               modelSelectorConfig.tree[modelConst].children[category]
  //                 .children
  //             ).forEach(state => {
  //               if (
  //                 state in
  //                 data.getGlobalStatsFromSearchOfGemListings.counts.byState
  //               ) {
  //                 modelSelectorConfig.tree[modelConst].children[
  //                   category
  //                 ].children[state].count =
  //                   data.getGlobalStatsFromSearchOfGemListings.counts.byState[
  //                     state
  //                   ];
  //               }
  //             });
  //           }
  //         );
  //       }

  //       return (
  //         <Header
  //           main={
  //             <TreeHeader
  //               urlPrefix={
  //                 this.props.userType === "vendor"
  //                   ? "/vendor/gem-listings-manager"
  //                   : "/admin/gem-listings-manager"
  //               }
  //               dropDownIcon={ARROW_DOWN_ICON}
  //               tree={modelSelectorConfig.tree}
  //               currentSelection={modelSelectorConfig.currentSelection}
  //             />
  //           }
  //           actionMenu={<MainActionMenu buttons={actionMenuButtons} />}
  //         />
  //       );
  //     }}
  //   </Query>
  // );
}

function listItemFunc(gemRequestItem) {
  return (
    <GemRequestListItem
      item={gemRequestItem}
      key={gemRequestItem.data.id}
      itemIdentifierIcon={GEM_REQUEST}
      iconography={{
        LINK_ICON: LINK_ICON,
        ARROW_RIGHT_ICON: ARROW_RIGHT_ICON,
        ACTION_ICON: ACTION_ICON
      }}
    />
  );
}

const GemRequestsManager = props => {
  let searchQuery = props.searchQuery;

  let state = urlConstToConst(props.match.params.param1);

  const { loading, error, data } = useQuery(searchQuery, {
    variables: { state }
  });

  let items;
  if (data) {
    items = data.searchGemRequestsInState.edges.map(datum => ({
      data: datum
    }));
  }

  return (
    <ModelManager
      loading={loading}
      items={items}
      header={header(props)}
      listItemFunc={listItemFunc}
      isDataLoading={loading}
      loadMoreIcon={ARROW_DOWN_ICON}
      dropdownIcon={ARROW_DOWN_ICON}
      template="template1"
      // param1={this.match.params.param1}
      totalCount={data && data.searchGemRequestsInState.totalCount}
      pageInfo={data && data.searchGemRequestsInState.pageInfo}
    />
  );
};

export default GemRequestsManager;

// import { Link } from "react-router-dom";
// import React from "react";
// import GemRequestsList from "./GemRequestsList";
// import TablePagination from "../../TablePagination";
// import { CANCEL_FILTER_ICON, SORTDIRECTION_ICON, SEARCH_ICON, PREVIOUS_ICON, NEXT_ICON } from '../../Icons'
// import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
// import agent from "../../../agent";
// import { connect } from "react-redux";
// import {} from "../../../constants/actionTypes";

// import {} from "../../../constants/businessConstantsToLanguageMapping";

// const Promise = global.Promise;

// const mapStateToProps = state => ({});

// const mapDispatchToProps = dispatch => ({});

// class GemRequestsManager extends React.Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {

//     return (

//       "asdfasdfaqwerqwerqwerqwes"
//       // <GemRequestsList
//       //   gemRequestItems={this.props.gemRequestItems}
//       //   userType={this.props.userType}
//       // />
//     );
//   }
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(GemRequestsManager);
