import { CURRENT_MARKET_USER_GRAPH_QL_QUERY } from "../graph/queries/currentMarketUser";

const updateSalesOrder = async (cache, { data }) => {
    const originalCurrentMarketUser = cache.readQuery({
        query: CURRENT_MARKET_USER_GRAPH_QL_QUERY,
    });

    cache.writeQuery({
        query: CURRENT_MARKET_USER_GRAPH_QL_QUERY,
        data: {
            ...originalCurrentMarketUser,
            currentMarketUser: {
                ...originalCurrentMarketUser.currentMarketUser,
                currentSalesOrder: { ...data.createOrUpdateSalesOrder },
            },
        },
    });
};

export { updateSalesOrder };
