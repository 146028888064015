import React from "react";
import { Grid, GridItem, useStyleConfig } from "../../Primatives";

export const InnerHeaderBody = ({ header, body }) => {
    const styles = useStyleConfig("InnerHeaderBody", {});

    let gridLayout = {};
    gridLayout.templateRows = "3rem 1px 1fr";
    gridLayout.templateColumns = "1fr";

    return (
        <Grid {...gridLayout} w="100%" h="100%" maxH="100%" sx={styles}>
            <GridItem w="100%" h="100%">
                {header}
            </GridItem>
            <GridItem bg="gray.300"></GridItem>
            <GridItem h="100%" overflow="hidden">
                {body}
            </GridItem>
        </Grid>
    );
};
