import { Icon } from "@chakra-ui/react";
import React from "react";

export const EmailIcon = ({ w = 98, h = "auto", fill = "#9A7B2D" }) => (
    <Icon w={w} h={h} viewBox="0 0 98 98">
        <path
            d="M70.6145 75.4226H27.3856C25.5107 75.4226 23.9862 73.9083 23.9862 72.047V47.2714C23.9862 46.4003 24.3231 45.5734 24.9322 44.9405C25.0241 44.8452 25.1227 44.7567 25.1874 44.7023L29.907 40.0269L29.9683 32.7245H37.2741L45.6687 24.4047C47.5062 22.5842 50.4972 22.5842 52.3382 24.4047L60.726 32.7245H68.0352V33.4085L68.0658 40.0099L72.8433 44.7431C73.0373 44.9133 73.187 45.0664 73.2993 45.2093C73.762 45.798 74.0172 46.533 74.0172 47.2782V72.0538C74.0172 73.9151 72.4928 75.4294 70.6179 75.4294L70.6145 75.4226ZM26.6132 73.8981C26.8513 73.9968 27.1134 74.0479 27.3856 74.0479H70.6111C70.8901 74.0479 71.1521 73.9934 71.3937 73.8913L50.9294 53.5223C49.9222 52.5219 48.2752 52.5219 47.2578 53.5223L26.6097 73.8913L26.6132 73.8981ZM25.3609 48.9183V72.047C25.3609 72.3805 25.446 72.697 25.5923 72.976L37.645 61.0867L25.3609 48.9183ZM60.4163 61.0288L72.4111 72.9692C72.5575 72.6935 72.6391 72.3805 72.6391 72.047V48.9183L60.4163 61.0288ZM31.3396 52.9132L38.6182 60.1237L46.2948 52.5525C47.8465 51.0213 50.3611 51.0213 51.8992 52.5525L59.4397 60.059L66.6536 52.9098V34.0924H31.3396V52.9098V52.9132ZM25.3779 47.006L29.9683 51.5521V41.8984L26.0721 45.7538C26.0415 45.7776 25.9802 45.8354 25.9224 45.8967C25.6229 46.2063 25.4358 46.5942 25.3779 47.006ZM68.0318 41.9086V51.5521L72.6221 47.006C72.5745 46.6589 72.435 46.3288 72.2138 46.0532C72.1525 45.9749 72.0538 45.8797 71.9211 45.7606L71.8871 45.7299L68.0318 41.9086V41.9086ZM39.2238 32.7211H58.7728L51.3684 25.3779C50.0651 24.0849 47.9384 24.0849 46.6317 25.3779L39.2238 32.7211V32.7211Z"
            fill={fill}
        />
        <path
            d="M59.4704 45.6823H38.5331V47.0536H59.4704V45.6823Z"
            fill={fill}
        />
        <path
            d="M59.4704 42.2319H38.5331V43.6032H59.4704V42.2319Z"
            fill={fill}
        />
        <path
            d="M59.4704 38.6011H38.5331V39.9725H59.4704V38.6011Z"
            fill={fill}
        />
    </Icon>
);
