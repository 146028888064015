import {
  TRADER_SUBMIT_GEM_SPECS_FOR_RELATIVE_PRICE_SEARCH,
  TRADER_UPDATE_SPEC_FOR_RELATIVE_PRICE_SEARCH,
  ASYNC_END,
  ASYNC_START
} from "../constants/actionTypes";

function initialState() {
  return {
    carat: 0,
    shape: "ROUND",
    cut: "Good",
    clarity: "I1",
    color: "I",
    relativePricingSearchResponse: null
  };
}

export default (state = { gemSpecs: initialState() }, action) => {
  switch (action.type) {
    case TRADER_SUBMIT_GEM_SPECS_FOR_RELATIVE_PRICE_SEARCH:
      return {...state, relativePricingSearchResponse: action.payload};
    case TRADER_UPDATE_SPEC_FOR_RELATIVE_PRICE_SEARCH:
      return { ...state, gemSpecs: {...state.gemSpecs, [action.gemListingSpec]: action.value }};
    case ASYNC_START:
      return {...state, inProgress: true, relativePricingSearchResponse: null};
    case ASYNC_END:
      return {...state, inProgress: false};
    default:
      return state;
  }
};
