import { DateTime } from "luxon";
import React, { useContext, useState } from "react";
import {
    Badge,
    Box,
    Flex,
    Grid,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useDisclosure,
} from "../Primatives";
import { getConstToLanguage } from "./ConstToLanguageMapping";
import { CloudManagementOSVariant } from "./Context";
import { ActionsThatNeedConfirmation } from "../CloudManagementOS/ActionsThatNeedConfirmation";

/**
 * Primary UI component for user interaction
 */

const Actions = ({ iconography, actions, id }) => {
    return (
        <Menu placement="left-start">
            <MenuButton as={Box}>
                <iconography.ACTION_ICON.component />
            </MenuButton>
            <MenuList>
                {actions?.map((action, idx) => (
                    <MenuItem as={Box} key={idx}>
                        {action?.needsConfirmation ? (
                            <ActionsThatNeedConfirmation
                                body={
                                    <Box id={id} w="100%" h="100%" key={id}>
                                        {action.name}
                                    </Box>
                                }
                                confirmationBody={action?.needsConfirmationBody}
                                funcToRun={action.onClick}
                            />
                        ) : (
                            <Box
                                onClick={action.onClick}
                                id={id}
                                w="100%"
                                h="100%"
                                key={id}
                            >
                                {action.name}
                            </Box>
                        )}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export const SearchResultListItem = ({
    id,
    h = "10rem",
    badges,
    bg,
    badgeColorMapping,
    iconography,
    submenus,
    actions,
    key,
    modelType,
    indentifier,
    owner,
    primaryInfo,
    secondaryInfo,
    createdAt,
    ...props
}) => {
    let cloudManagementOSVariant = useContext(CloudManagementOSVariant);

    let gridLayout = {};
    if (cloudManagementOSVariant === "collapse") {
        gridLayout.templateRows = "1fr 1fr";
    } else {
        gridLayout.templateColumns = "1fr 1fr";
    }

    return (
        <Box w="100%" position="relative" className="grow" boxShadow="sm">
            <Grid {...gridLayout} bg={bg} w="100%">
                <Grid
                    paddingRight="1rem"
                    h={h}
                    templateColumns={`${h} auto`}
                    {...props}
                >
                    <Box maxH={h} h={h}>
                        {modelType}
                    </Box>
                    <Grid
                        templateRows="1re, fit-content 1fr"
                        p="0.75rem"
                        h="100%"
                    >
                        <Box>
                            {badges &&
                                badges.map((badge, idx) => (
                                    <Badge
                                        key={idx}
                                        fontSize="0.7em"
                                        colorScheme={
                                            badge in badgeColorMapping
                                                ? badgeColorMapping[badge]
                                                : null
                                        }
                                    >
                                        {getConstToLanguage(badge)}
                                    </Badge>
                                ))}
                        </Box>
                        <Flex direction="column">
                            <Box>{indentifier}</Box>
                            <Box>{owner}</Box>
                        </Flex>
                        <Flex alignItems="center">{primaryInfo}</Flex>
                    </Grid>
                </Grid>
                <Grid templateColumns={`50% 50%`} w="100%" h={h}>
                    <Flex p="0.75rem">{secondaryInfo}</Flex>
                    <Flex p="0.75rem" paddingRight="1.75rem">
                        <Text fontSize="sm">
                            {getConstToLanguage("CREATED_AT")}
                            <br />
                            {DateTime.fromISO(createdAt).toFormat("fff")}
                        </Text>
                    </Flex>

                    {/* <div className={actionMenuClasses}>
          <div className="dropdown-menu__title">
            {getConstToLanguage("ACTIONS")}
          </div>
          {renderActionItems(actions)}
        </div> */}
                </Grid>
            </Grid>
            <Box position="absolute" top="0.75rem" right="1rem">
                <Actions iconography={iconography} actions={actions} id={id} />
            </Box>
        </Box>
    );
};

SearchResultListItem.propTypes = {};

SearchResultListItem.defaultProps = {};
