import React from "react";
import { SHAPE_TO_ICON_MAPPING, CLOSE_ICON } from "../../components/Icons";
import { numberToCurrency } from "../../components/UtilityComponents/etc/floatToCurrency";
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
import { Box, HStack, Center, Button } from "@chakra-ui/react";

const getFormattedSpecs = (specs) => {
    const carat = specs.carat
        ? specs.carat + " " + getConstToLanguage("CARAT") + " "
        : "";
    const color = specs.color ? specs.color + " " : "";
    const clarity = specs.clarity ? specs.clarity + " " : "";
    const cut = specs.cut
        ? specs.cut + " " + getConstToLanguage("CUT") + " "
        : "";
    const shape = specs.shape
        ? specs.shape + " " + getConstToLanguage("SHAPE") + " "
        : "";
    return (
        carat + color + clarity + cut + shape + getConstToLanguage("Diamond")
    );
};

const SalesOrderItem = (props) => {
    return (
        <Box>
            <HStack>
                <Center
                    h={props.h}
                    w={props.h}
                    border="1px"
                    borderColor="off-black"
                    padding={4}
                >
                    <Center h="inherit" w="inherit">
                        {SHAPE_TO_ICON_MAPPING[
                            props.item.certifiedGem.specs.shape
                        ]({})}
                    </Center>
                </Center>
                <Box>
                    <Box className="specs" fontSize={"xs"}>
                        {getFormattedSpecs(props.item.certifiedGem.specs)}
                    </Box>
                    <Box className="price" fontSize={"md"} fontWeight={"bold"}>
                        {numberToCurrency(props.item.price)}
                    </Box>
                </Box>
                <Box alignSelf={"normal"}>
                    {props.onRemove && (
                        <Button
                            value={props.item.id}
                            onClick={props.onRemove}
                            w={"0.69rem"}
                            h={"0.69rem"}
                            size={""}
                            bgColor={"secondary-button.50"}
                        >
                            <Center
                                w={"0.31rem"}
                                h={"0.31rem"}
                                color={"#707070"}
                            >
                                <CLOSE_ICON />
                            </Center>
                        </Button>
                    )}
                </Box>
            </HStack>
        </Box>
    );
};

export { SalesOrderItem };
