import React from "react";
import { Link as ReactLink } from "react-router-dom";
import ReactPlayer from "react-player";
import {
    Box,
    ConditionalLink,
    Grid,
    Heading,
    useBreakpointValue,
    Icon,
    Link,
    VStack,
} from "../../../../stories/Primatives";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";

import StepInstructions from "../../../../stories/CloudManagementOS/Help/StepInstructions";
import { Help } from "../../../../stories/CloudManagementOS/Help";

const SFTPHelp = () => {
    let steps = [
        {
            title: "Get a gem listing csv ready.",
            subTitle: (
                <Box>
                    here is an{" "}
                    <Link
                        color={"brand.500"}
                        href="https://carbonext-assests.nyc3.digitaloceanspaces.com/example_gem_listing_inventory.csv"
                    >
                        example_gem_listing_inventory.csv
                    </Link>
                </Box>
            ),
        },
        {
            title: "Generate a SFTP private key",
            subTitle: (
                <Box>
                    Navigate to settings section, and then{" "}
                    <Link
                        to="/vendor/settings/api-settings"
                        as={ReactLink}
                        color={"brand.500"}
                    >
                        API settings
                    </Link>{" "}
                    to see a section called{" "}
                    {getConstToLanguage("SFTP_CREDENTIALS")}
                </Box>
            ),
            body: (
                <ReactPlayer
                    url="https://carbonext-assests.nyc3.digitaloceanspaces.com/generate_sftp_private_key.webm"
                    controls={true}
                />
            ),
        },
        {
            title: "Test out your credentials",
            subTitle: (
                <Box>
                    Free SFTP Tools:
                    <Box>
                        <Link
                            href="https://filezilla-project.org"
                            target="_blank"
                            color={"brand.500"}
                            isExternal={true}
                        >
                            Filezilla
                        </Link>
                    </Box>
                </Box>
            ),
            body: (
                <ReactPlayer
                    url="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/try_out_generated_sftp_private_key.webm"
                    controls={true}
                />
            ),
        },
    ];
    return (
        <Box p={12}>
            <Heading as="h4" size="md">
                <StepInstructions
                    steps={steps}
                    title={
                        "Setting up SFTP gem listing inventory csv automated uploads"
                    }
                />
            </Heading>
        </Box>
    );
};

const VendorHelp = ({}) => {
    let navConfig = [
        {
            navItemLabel: getConstToLanguage("API"),
            link: "/api",
            subItems: [
                {
                    link: "/sftp-setup-credentials",
                    label: getConstToLanguage("SFTP_SETUP"),
                    view: <SFTPHelp />,
                },
            ],
        },
    ];

    return <Help routePrefix={"/vendor/help"} navConfig={navConfig} />;
};

export default VendorHelp;
