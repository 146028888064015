import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import gql from "graphql-tag";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { getConstToLanguage } from "../../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import {
    Box,
    Button,
    Container,
    Flex,
    Spinner,
    VStack,
    Image,
    Grid,
    Link,
    SimpleGrid,
    WrapItem,
    Wrap,
    Center,
    HStack,
} from "../../../../../stories/Primatives";
import {
    CaratInputFormControl,
    ClaritySelectorDropDownFormControl,
    ColorSelectorDropDownFormControl,
    CutSelectorDropDownFormControl,
    ShapeSelectorDropDownFormControl,
} from "../../../../UtilityComponents/etc/gemStats";

import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import useSubmitMutation from "../../../../../stories/CloudManagementOS/hooks/useSubmitMutation";

const ComparableGemListingSearchSchema = Yup.object().shape({
    // shape: Yup.string().required(),
    carat: Yup.string().required(),
});

const client = new ApolloClient({
    uri: "https://api.carbonext.io/graphql",
    cache: new InMemoryCache(),
    link: createHttpLink({
        uri: "https://api.carbonext.io/graphql",
    }),
});

const SEARCH_COMPARABLE_GEM_LISTINGS_GQL_M = gql`
    mutation SearchComparableGemListings(
        $searchParam: SearchComparableGemListingSearchParam!
    ) {
        searchComparableGemListings(searchParam: $searchParam) {
            foundComparableGemListings {
                shape
                source
                cut
                clarity
                color
                price
                carat
                link
            }
        }
    }
`;

const ComparableLogo = ({ source, link }) => {
    return (
        <Image src="https://css.brilliantearth.com/static/img/logo-new.svg"></Image>
    );
};

const SearchResultsStats = ({ stats }) => {
    return (
        <Box padding="4">
            <Wrap>
                <WrapItem>min: {stats.min}</WrapItem>
                <WrapItem>max: {stats.max}</WrapItem>
                <WrapItem>average: {stats.average}</WrapItem>
                <WrapItem> count: {stats.count}</WrapItem>
            </Wrap>
        </Box>
    );
};

const MatchGemListingSpecs = ({ login }) => {
    let {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: yupResolver(ComparableGemListingSearchSchema) });

    const [
        searchComparableGemListings,
    ] = useMutation(SEARCH_COMPARABLE_GEM_LISTINGS_GQL_M, { client });

    const searchComparables = async (formVariables) => {
        let variables = {
            searchParam: {
                carat: parseFloat(formVariables.carat),
                clarities: [formVariables.clarity],
                cuts: [formVariables.cut],
                colors: [formVariables.color],
                shapes: [formVariables.shape],
            },
        };

        return await searchComparableGemListings({ variables });
    };
    let { onSubmit, loading, error, data } = useSubmitMutation(
        searchComparables
    );

    let stats;

    if (data?.searchComparableGemListings?.foundComparableGemListings.length) {
        console.log(";:LSDKJF:LKSDJF:LKSDJ:FLKJSD:LKF");
        let prices = data?.searchComparableGemListings.foundComparableGemListings.map(
            (searchComparableGemListing) => searchComparableGemListing.price
        );
        stats = {
            count:
                data?.searchComparableGemListings.foundComparableGemListings
                    .length,
            min: Math.min(...prices),
            max: Math.max(...prices),
            average: (
                prices.reduce((p, c) => p + c, 0) /
                data?.searchComparableGemListings.foundComparableGemListings
                    .length
            ).toFixed(2),
        };
    }

    console.log("STTASSTSSS", stats);
    return (
        <VStack>
            <Container
                maxW="container.lg"
                padding={6}
                border="grey.50"
                marginTop={6}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Wrap spacing="30px" justify="center">
                        <WrapItem w="240px">
                            <ShapeSelectorDropDownFormControl
                                error={errors?.shape}
                                register={register}
                                name="shape"
                            />
                        </WrapItem>
                        <WrapItem w="240px">
                            <CaratInputFormControl
                                error={errors?.carat}
                                register={register}
                                name="carat"
                            />
                        </WrapItem>
                        <WrapItem w="240px">
                            <ClaritySelectorDropDownFormControl
                                error={errors?.clarity}
                                register={register}
                                name="clarity"
                            />
                        </WrapItem>
                        <WrapItem w="240px">
                            <ColorSelectorDropDownFormControl
                                error={errors?.color}
                                register={register}
                                name="color"
                            />
                        </WrapItem>
                        <WrapItem w="240px">
                            <CutSelectorDropDownFormControl
                                error={errors?.cut}
                                register={register}
                                name="cut"
                            />
                        </WrapItem>
                    </Wrap>
                    <HStack
                        justifyContent={"flex-end"}
                        marginTop="8"
                        spacing="24px"
                    >
                        <Button
                            type="submit"
                            variant="cloud-management-os.primary"
                            w="270px"
                        >
                            {getConstToLanguage("SEARCH")}
                        </Button>
                    </HStack>
                </form>
            </Container>

            <Container maxW="container.lg">
                {loading && <Spinner />}
                <VStack spacing={3}>
                    {stats && <SearchResultsStats stats={stats} />}

                    {(error ||
                        data?.searchComparableGemListings
                            ?.foundComparableGemListings.length == 0) && (
                        <Box>No results found</Box>
                    )}
                    {data?.searchComparableGemListings?.foundComparableGemListings.map(
                        (foundComparableGemListing) => (
                            <Box w="100%" border={"1px solid black"}>
                                <Link
                                    href={foundComparableGemListing.link}
                                    isExternal={true}
                                >
                                    <Grid
                                        templateColumns="10rem 1fr"
                                        gap={4}
                                        h="10rem"
                                    >
                                        <Center padding={"4"}>
                                            <ComparableLogo />
                                        </Center>

                                        <Wrap
                                            padding={"4"}
                                            display="flex"
                                            alignItems={"center"}
                                        >
                                            <WrapItem w="240px">
                                                <Box fontWeight={"bold"}>
                                                    Price: $
                                                    {
                                                        foundComparableGemListing.price
                                                    }
                                                </Box>
                                            </WrapItem>
                                            <WrapItem w="240px">
                                                <Box>
                                                    Shape:{" "}
                                                    {
                                                        foundComparableGemListing.shape
                                                    }
                                                </Box>
                                            </WrapItem>
                                            <WrapItem w="240px">
                                                <Box>
                                                    Carat:{" "}
                                                    {
                                                        foundComparableGemListing.carat
                                                    }
                                                </Box>
                                            </WrapItem>
                                            <Box></Box>
                                            <WrapItem w="240px">
                                                <Box>
                                                    Clarity:{" "}
                                                    {
                                                        foundComparableGemListing.clarity
                                                    }
                                                </Box>
                                            </WrapItem>
                                            <WrapItem w="240px">
                                                <Box>
                                                    Color:{" "}
                                                    {
                                                        foundComparableGemListing.color
                                                    }
                                                </Box>
                                            </WrapItem>
                                            <WrapItem w="240px">
                                                <Box>
                                                    Cut:{" "}
                                                    {
                                                        foundComparableGemListing.cut
                                                    }
                                                </Box>
                                            </WrapItem>
                                        </Wrap>
                                    </Grid>
                                </Link>
                            </Box>
                        )
                    )}
                </VStack>
            </Container>
        </VStack>
    );
};

const RelativePriceSearchTool = () => {
    return (
        <Box>
            <MatchGemListingSpecs />
        </Box>
    );
};

export default RelativePriceSearchTool;
