import * as React from "react";
import Atoms from "../Atoms";
import { Box } from "../Atoms";

export const GraphQLResultResponseMolecule = ({
    error,
    result,
    initialBody,
    resultVariant = "inline",
    errorPosition = "above",
}) => {
    let constructedBody = [];

    if (error) {
        constructedBody.push(<Atoms.BadResponseAtom error={error} />);
    } else if (result) {
        return (
            <Atoms.GoodResponseAtom
                resultVariant={resultVariant}
                title={result?.title}
                description={result?.message}
            />
        );
    }
    constructedBody.push(initialBody);
    return <Box>{constructedBody}</Box>;
};
