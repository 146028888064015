import { Query } from "@apollo/react-components";
import React from "react";
import { Redirect } from "react-router";
import { GQL_Q_SEARCH_VENDOR_ORGANIZATION_INQUIRIES_BY_STATE } from "../../../../graph/queries/VendorOrganizationInquiry/searchVendorOrganizationInquiryInState";
import { urlConstToConst } from "../../../../stories/CloudManagementOS/ConstToUrl";
import { Header } from "../../../../stories/CloudManagementOS/Header";
import { ModelManager } from "../../../../stories/CloudManagementOS/ModelManager";
import TreeHeader from "../../../../stories/CloudManagementOS/TreeHeader";
import { ARROW_DOWN_ICON, CSV_ICON, SEARCH_ICON } from "../../../Icons";
import { GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_VENDOR_ORGANIZATION } from "../../graph/queries/getGlobalStatsFromSearchOfVendorOrganizations";
import VendorOrganizationInquiryListItem from "./VendorOrganizationInquiryListItem";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";
var _ = require("lodash");

class VendorOrganizationInquiryManager extends React.Component {
    constructor(props) {
        super(props);
        this.header = this.header.bind(this);
    }

    displayColumnsBasedOnSubTool(subtool) {
        switch (subtool) {
        }
    }

    rows() {
        if (this.props.gemListingRows) {
            return this.props.gemListingRows.map((gemListingRow) => {
                return (
                    <VendorOrganizationInquiryListItem
                        key={gemListingRow.gemListing._id}
                        gemListingRow={gemListingRow}
                        singleGemListingToolbar={
                            this.props.singleGemListingToolbar
                        }
                        subtool={this.props.subtool}
                        priceChangeFunc={this.props.priceChangeFunc}
                        acqPriceChangeFunc={this.props.acqPriceChangeFunc}
                        togglePriceEditModeFunc={
                            this.props.togglePriceEditModeFunc
                        }
                        editPriceFunc={this.props.editPriceFunc}
                        editAcqPriceFunc={this.props.editAcqPriceFunc}
                        toggleAcqPriceEditModeFunc={
                            this.props.toggleAcqPriceEditModeFunc
                        }
                        options={this.props.options}
                        reservedGemListings={this.props.reservedGemListings}
                        priceChangeInProgress={this.props.priceChangeInProgress}
                        acqPriceChangeInProgress={
                            this.props.acqPriceChangeInProgress
                        }
                        cancelArchiveFunc={this.props.cancelArchiveFunc}
                        confirmArchiveFunc={this.props.confirmArchiveFunc}
                    />
                );
            });
        }
    }

    listItemFunc(vendorOrganizationInquiryItem) {
        return (
            <VendorOrganizationInquiryListItem
                item={vendorOrganizationInquiryItem}
                key={vendorOrganizationInquiryItem.data.id}
            />
        );
    }

    header() {
        let modelConst = "VENDOR_ORGANIZATION_INQUIRIES";
        let states = {
            NEW: {},
            ACCEPTED: {},
            DEFERRED: {},
            REJECTED: {},
        };

        let modelSelectorConfig = {
            tree: {
                VENDOR_ORGANIZATION_INQUIRIES: {
                    children: states,
                },
            },
            currentSelection: [
                modelConst,
                urlConstToConst(this.props.match.params.subtool),
            ],
        };
        return (
            <Query
                query={
                    GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_VENDOR_ORGANIZATION
                }
            >
                {({ loading, error, data, fetchMore }) => {
                    if (data) {
                        Object.keys(states).forEach((state) => {
                            if (
                                state in
                                data
                                    .getGlobalStatsFromSearchOfVendorOrganizations
                                    .counts.byState
                            ) {
                                states[state].count =
                                    data.getGlobalStatsFromSearchOfVendorOrganizations.counts.byState[
                                        state
                                    ];
                            }
                        });
                    }

                    return (
                        <Header
                            main={
                                <TreeHeader
                                    urlPrefix="/admin"
                                    dropDownIcon={ARROW_DOWN_ICON}
                                    tree={modelSelectorConfig.tree}
                                    currentSelection={
                                        modelSelectorConfig.currentSelection
                                    }
                                />
                            }
                        />
                    );
                }}
            </Query>
        );
    }

    searchSection() {
        return <div className="search-inner"></div>;
    }

    render() {
        let state =
            this.props.match.params.subtool &&
            this.props.match.params.subtool.toUpperCase();

        if (this.props.stats) {
        }

        let actionMenuButtons = [
            {
                icon: <CSV_ICON />,
                description: "Upload a CSV",
            },
            {
                icon: "asdf",
                description: "Do something else",
            },
        ];

        return (
            <Query
                query={GQL_Q_SEARCH_VENDOR_ORGANIZATION_INQUIRIES_BY_STATE}
                variables={{
                    state: state,
                }}
            >
                {({ loading, error, data, fetchMore }) => {
                    let items;
                    if (data) {
                        items = data.searchVendorOrganizationInquiriesInState.edges.map(
                            (datum) => ({
                                data: datum,
                            })
                        );
                    }

                    return (
                        <ModelManager
                            modelName={getConstToLanguage(
                                "VENDOR_ORGANIZATION_INQUIRIES"
                            )}
                            isDataLoading={loading}
                            items={items}
                            totalCount={
                                data &&
                                data.searchVendorOrganizationInquiriesInState
                                    .totalCount
                            }
                            pageInfo={
                                data &&
                                data.searchVendorOrganizationInquiriesInState
                                    .pageInfo
                            }
                            listItemFunc={this.listItemFunc}
                            loadMoreIcon={ARROW_DOWN_ICON}
                            dropdownIcon={ARROW_DOWN_ICON}
                            header={this.header()}
                            onLoadMore={() =>
                                fetchMore({
                                    variables: {
                                        pagination: {
                                            first: 5,
                                            after:
                                                data &&
                                                data
                                                    .searchVendorOrganizationInquiriesInState
                                                    .pageInfo.endCursor,
                                        },
                                    },
                                    updateQuery: (
                                        previousResult,
                                        { fetchMoreResult }
                                    ) => {
                                        const newEdges =
                                            fetchMoreResult
                                                .searchVendorOrganizationInquiriesInState
                                                .edges;
                                        const pageInfo =
                                            fetchMoreResult
                                                .searchVendorOrganizationInquiriesInState
                                                .pageInfo;

                                        return newEdges.length
                                            ? {
                                                  // Put the new comments at the end of the list and update `pageInfo`
                                                  // so we have the new `endCursor` and `hasNextPage` values
                                                  searchVendorOrganizationInquiriesInState: {
                                                      __typename:
                                                          previousResult
                                                              .searchVendorOrganizationInquiriesInState
                                                              .__typename,
                                                      edges: [
                                                          ...previousResult
                                                              .searchVendorOrganizationInquiriesInState
                                                              .edges,
                                                          ...newEdges,
                                                      ],
                                                      pageInfo,
                                                  },
                                              }
                                            : previousResult;
                                    },
                                })
                            }
                            searchParamFilterRenderFunc={
                                this.renderSearchParamFilter
                            }
                            newSearchParam={this.props.newSearchParam}
                            actionMenuButtons={actionMenuButtons}
                            param1={this.props.param1}
                            searchSection={this.searchSection}
                            template="template1"
                            searchIcon={SEARCH_ICON}
                            submitSearchFunc={this.props.submitSearchFunc}
                            searchValueUpdateFunc={
                                this.props.searchValueUpdateFunc
                            }
                        />
                    );
                }}
            </Query>
        );
    }
}

export default VendorOrganizationInquiryManager;
