import { ApolloProvider } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useScrollYPosition } from "react-use-scroll-position";
import { theme } from "../../chakra";
import { marketUserGraphClient } from "../../graph";
import { getCurrentViewPortHeight } from "../../stories/CloudManagementOS/helpers/MediaQuery";
import { convertRemToPixels } from "../../stories/CloudManagementOS/helpers/UnitTranslators";
import { GalleryItem } from "../../stories/Market/GalleryItem";
import { GetStarted } from "../../stories/Market/GetStarted";
import { Nav } from "../../stories/Market/Nav";
import { Footer } from "../../stories/Marketing/Footer";
import { HowItWorks } from "../../stories/Marketing/HowItWorks";
import { NewWayToShop } from "../../stories/Marketing/NewWayToShop";
import {
    Box,
    Button,
    ChakraProvider,
    Flex,
    Divider,
    Heading,
    Image,
    Stack,
    Img,
    Text,
    useMediaQuery,
    Container,
    useBreakpointValue,
    VStack,
} from "../../stories/Primatives";
import { iconography } from "../IconsV2";
import { CurrentMarketUserContext } from "../UtilityComponents/MarketOS/context";

function Hero({ title, subtitle, image, ctaLink, ctaText, ...rest }) {
    return (
        <Flex
            align="center"
            justify={{
                base: "center",
                md: "space-around",
                xl: "space-between",
            }}
            direction={{ base: "column-reverse", md: "row" }}
            wrap="no-wrap"
            minH="100vh"
            px={8}
            // mb={16}
            {...rest}
        >
            <Stack
                spacing={4}
                w={{ base: "80%", md: "40%" }}
                align={["center", "center", "flex-start", "flex-start"]}
            >
                <Heading
                    as="h1"
                    size="xl"
                    fontWeight="bold"
                    color="primary.800"
                    textAlign={["center", "center", "left", "left"]}
                >
                    {title}
                </Heading>
                <Heading
                    as="h2"
                    size="md"
                    color="primary.800"
                    opacity="0.8"
                    fontWeight="normal"
                    lineHeight={1.5}
                    textAlign={["center", "center", "left", "left"]}
                >
                    {subtitle}
                </Heading>
                <Link to={ctaLink}>
                    <Button
                        colorScheme="primary"
                        borderRadius="8px"
                        py="4"
                        px="4"
                        lineHeight="1"
                        size="md"
                    >
                        {ctaText}
                    </Button>
                </Link>
                <Text
                    fontSize="xs"
                    mt={2}
                    textAlign="center"
                    color="primary.800"
                    opacity="0.6"
                >
                    No credit card required.
                </Text>
            </Stack>
            <Box
                w={{ base: "80%", sm: "60%", md: "50%" }}
                mb={{ base: 12, md: 0 }}
            >
                {/* TODO: Make this change every X secs */}
                <Image src={image} size="100%" rounded="1rem" shadow="2xl" />
            </Box>
        </Flex>
    );
}

// Hero.propTypes = {
//     title: PropTypes.string,
//     subtitle: PropTypes.string,
//     image: PropTypes.string,
//     ctaText: PropTypes.string,
//     ctaLink: PropTypes.string
// };

Hero.defaultProps = {
    title: "React landing page with Chakra UI",
    subtitle:
        "This is the subheader section where you describe the basic benefits of your product",
    image: "https://source.unsplash.com/collection/404339/800x600",
    ctaText: "Create your account now",
    ctaLink: "/signup",
};

const CloseIcon = () => (
    <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>Close</title>
        <path
            fill="white"
            d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
        />
    </svg>
);

const MenuIcon = () => (
    <svg
        width="24px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        fill="white"
    >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
);

// Do this at the root of your application

const MenuItems = (props) => {
    const { children, isLast, to = "/", ...rest } = props;
    return (
        <Text
            mb={{ base: isLast ? 0 : 8, sm: 0 }}
            mr={{ base: 0, sm: isLast ? 0 : 8 }}
            display="block"
            {...rest}
        >
            <Link to={to}>{children}</Link>
        </Text>
    );
};

function LandingPage(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const menuVariant = useBreakpointValue({ base: "slide-in", lg: "full" });
    let currentScroll = useScrollYPosition();
    let bg = currentScroll ? null : "transparent";
    let colorMode = currentScroll ? "dark" : null;
    let slideInMenuColor = "off-black";
    let color = currentScroll ? null : "white";
    let navHeight = 6.94;

    let slideInMenuStyling = {
        color: "white",
        bg: "off-black",
    };

    return (
        <Flex direction="column" bg="brand.main" m="0 auto">
            <Nav
                menuVariant={menuVariant}
                bg={bg}
                slideInMenuStyling={slideInMenuStyling}
                slideInMenuColor={slideInMenuColor}
                boxShadow={
                    currentScroll >
                    getCurrentViewPortHeight() - convertRemToPixels(navHeight)
                        ? "md"
                        : "none"
                }
                color={color}
                colorMode={colorMode}
                logOut={props.logout}
                iconography={iconography}
            />
            <Box>{props.children}</Box>

            {/* <Footer /> */}
        </Flex>
    );
}

const FirstSection = () => {
    const history = useHistory();
    const [isIphone] = useMediaQuery("(max-width: @iphone-screen)");
    let { t } = useTranslation();

    return (
        <Box
            height="100vh"
            width="100vw"
            position="relative"
            // bg="url('https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/gift-habeshaw-sQcvXmxgPfw-unsplash.png') no-repeat center center fixed; "/
            // bgSize="cover"
            // bgAttachment={isIphone ? "scroll" : { base: "none", md: "fixed" }}
            // bgRepeat="no-repeat"
            // bgPosition="center"
            paddingTop={{ base: 32, lg: 0 }}
            css={{
                "-webkit-background-size": "100%",
                "-moz-background-size:": "100%",
                "-o-background-size": "100%",
                "background-size": "100%",
                "-moz-background-size": "cover",
                "-webkit-background-size": "cover",
                "-o-background-size": "cover",
                "background-size": "cover",
            }}
        >
            {" "}
            <Img
                position={"absolute"}
                top="0px"
                boxSize="full"
                objectFit="cover"
                src="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/gift-habeshaw-sQcvXmxgPfw-unsplash.png"
                alt="Lady at work"
            />
            <VStack h="100%" paddingTop={"10%"} textAlign={"center"}>
                <Text
                    color="white"
                    // my={15}
                    zIndex="2"
                    textTransform={"uppercase"}
                    fontSize={{ base: "3xl", lg: "7xl" }}
                    fontFamily="QuicheText, sans-serif"
                    pb={3}
                >
                    Commitment made easy
                </Text>
                <Text
                    color="white"
                    pb={10}
                    zIndex="2"
                    fontSize={{ base: "xl", lg: "3xl" }}
                    fontFamily="Montserrat"
                    lineHeight={"33px"}
                >
                    Shop Our Diamonds with Clarity
                </Text>
                <Button
                    size="lg"
                    w={240}
                    variant="market.outlined"
                    onClick={() => history.push("/market/diamonds")}
                >
                    {t("SHOP_NOW")}
                </Button>
            </VStack>
        </Box>
    );
};

const SecondSection = () => {
    return (
        <Flex
            align="center"
            justify={{
                base: "center",
                md: "space-around",
                xl: "space-between",
            }}
            direction={{ base: "column", md: "column", xl: "row" }}
            wrap="no-wrap"
            w="100%"
            bg="white"
            px={{ base: 8, md: 20, xl: 0 }}
            maxH={{ xl: "70vh" }}
        >
            <Box w={{ base: "100%", md: "100%", xl: "50%" }} align="center">
                <Image
                    src="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Diamonds.H03.2k2x.png"
                    srcSet="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Diamonds.H03.2k2x-p-500.png 500w, https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Diamonds.H03.2k2x-p-800.png 800w, https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Diamonds.H03.2k2x-p-1080.png 1080w, https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Diamonds.H03.2k2x-p-1600.png 1600w, https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Diamonds.H03.2k2x.png 1708w"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 800px) 93vw, 47vw"
                    alt="Diamonds"
                />
            </Box>
            <Container maxW={{ base: "2xl", lg: null }}>
                <VStack
                    spacing={10}
                    w={"100%"}
                    align="left"
                    my={"2.75rem"}
                    pr={{ base: 0, xl: "10%" }}
                >
                    <Divider variant={"gold"} w={44} />

                    <Text
                        as={"h2"}
                        fontSize={{ base: "3xl", md: "4xl", xl: "4xl" }}
                        lineHeight={"shorter"}
                        fontFamily="QuicheText, sans-serif"
                        textTransform={"uppercase"}
                    >
                        Luxury without compromising value
                    </Text>

                    <Text
                        fontSize={"1.375rem"}
                        lineHeight={8}
                        fontFamily="Montserrat"
                        color={"grey.600"}
                    >
                        Search our inventory of diamonds sourced by US based
                        trusted traders and retailers.
                    </Text>

                    <Link to="why-choose-us">
                        <Button size="md" variant="market.outlined-black">
                            {" "}
                            Why Choose Us?{" "}
                        </Button>
                    </Link>
                </VStack>
            </Container>
        </Flex>
    );
};

const FifthSection = () => {
    return (
        <Box
            textAlign={"center"}
            py={16}
            px={10}
            bgColor={"#eeeeee"}
            maxWidth="100vw"
        >
            <Text
                as={"h1"}
                fontFamily={"QuicheText, sans-serif"}
                fontSize={50}
                fontWeight={500}
                mb={7}
            >
                Our latest creations
            </Text>
            <Text mb={9} fontFamily={"Quicksand, sans-serif"} fontSize={20}>
                Explore our client’s designs to find inspiration for your own
                unique ring.
            </Text>
            <Box overflow="auto">
                <Flex
                    align="center"
                    // justify={{ base: "center", md: "center", xl: "center" }}
                    direction={{ base: "column", md: "row", xl: "row" }}
                    wrap="no-wrap"
                >
                    {getGallery()}
                </Flex>
            </Box>
            <Flex
                align="center"
                justify={{ base: "center", md: "center", xl: "center" }}
                direction={{ base: "column", md: "row", xl: "row" }}
                mt={9}
            >
                <Button size="md" variant="market.primary" mx={2}>
                    {" "}
                    Browse our gallery{" "}
                </Button>
                <Button size="md" variant="market.secondary" mx={2}>
                    {" "}
                    Build your own ring!{" "}
                </Button>
            </Flex>
        </Box>
    );
};

const getGallery = () => {
    const galleryItems = [];
    //harcoded data
    for (let i = 0; i < 5; i++) {
        galleryItems.push(
            <GalleryItem
                key={i}
                title={"Pacheco's Jewelry"}
                subheading={"Gold Diamond Ring"}
                stars={5}
                src={
                    "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Gold-Ring-With-Small-Diamonds.I09.2k2x.png"
                }
                m={1}
            />
        );
    }
    return galleryItems;
};

const LandingBody = () => {
    return (
        <Box w="100%">
            <FirstSection />
            <SecondSection />
            <NewWayToShop />
            <HowItWorks />
            {/* <FourthSection />
      <FifthSection />*/}
            <GetStarted bgColor={"grey.500"} />
        </Box>
    );
};

function App(props) {
    return (
        <ApolloProvider client={marketUserGraphClient}>
            <ChakraProvider theme={theme}>
                <LandingPage {...props}>
                    {/*
                    <LandingBody {...props} />
*/}
                    <LandingBody />
                </LandingPage>
                <Footer />
            </ChakraProvider>
        </ApolloProvider>
    );
}

export default App;
