import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { ModelManager } from "../../../../stories/CloudManagementOS/ModelManager";
import { GQL_Q_SEARCH_ADMINS } from "../../graph/queries";
import { SmartTable } from "../../../../stories/CloudManagementOS/SmartTable";
import {
  Tr,
  Td,
  HStack,
  Checkbox,
  Avatar,
  Badge,
  Text,
  Box,
  IconButton,
} from "../../../../stories/Primatives";
import { FiTrash2, FiEdit2 } from "react-icons/fi";

function fullName(item) {
  return item?.node?.person?.givenName + " " + item?.node?.person?.familyName;
}

function listItemFunc(item) {
  console.log(item);

  return (
    <Tr key={item.node.id}>
      <Td>
        <HStack spacing="3">
          <Checkbox />
          <Avatar name={fullName(item)} boxSize="10" />
          <Box>
            <Text fontWeight="medium">{fullName(item)}</Text>
          </Box>
        </HStack>
      </Td>
      <Td>
        <Badge
          size="sm"
          colorScheme={item?.node?.status === "active" ? "green" : "red"}
        >
          {item?.node?.status}
        </Badge>
      </Td>
      <Td>
        <Text color="muted">{item?.node?.localAuth?.email}</Text>
      </Td>
      <Td>
        <Text color="muted">Admin</Text>
      </Td>
      <Td>
        <Text color="muted"></Text>
      </Td>
      <Td>
        <HStack spacing="1">
          <IconButton
            icon={<FiTrash2 fontSize="1.25rem" />}
            variant="ghost"
            aria-label="Delete member"
          />
          <IconButton
            icon={<FiEdit2 fontSize="1.25rem" />}
            variant="ghost"
            aria-label="Edit member"
          />
        </HStack>
      </Td>
    </Tr>
  );
}

const AdminManagement = (props) => {
  const { loading, error, data } = useQuery(GQL_Q_SEARCH_ADMINS, {});
  return (
    <SmartTable
      w="100%"
      listItemFunc={listItemFunc}
      items={data && data.searchAdmins.edges}
    />
  );
  return (
    <ModelManager
      isDataLoading={loading}
      header={getConstToLanguage("ADMINISTRATORS")}
      items={data && data.searchAdmins.edges}
      listItemFunc={listItemFunc}
      searchSection={null}
      totalCount={data && data.searchAdmins.totalCount}
    />
  );
};

export default AdminManagement;
