import React from "react";
import { SHAPE_TO_ICON_MAPPING, CLOSE_ICON } from "../../components/Icons";
import { numberToCurrency } from "../../components/UtilityComponents/etc/floatToCurrency";
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
import { Box, HStack, Center, Button, VStack, Heading } from "@chakra-ui/react";

export const Address = ({ name, address }) => {
    return address ? (
        <Box as={"address"}>
            <VStack align={"left"}>
                {name && (
                    <Heading
                        as="h5"
                        size="sm"
                        fontFamily="Montserrat"
                        fontStyle={"normal"}
                    >
                        {name}{" "}
                    </Heading>
                )}
                <Box>{address.address1}</Box>
                {address.address2 && <Box>{address.address2}</Box>}
                <Box>
                    {address.city}, {address.region} {address.postalCode}
                </Box>
            </VStack>
        </Box>
    ) : (
        ""
    );
};
