import gql from "graphql-tag";

const GQL_Q_GET_CURRENT_PAYABLE_BANK_ACCOUNT = gql`
  query GetCurrentPayableBankAccount {
    getCurrentPayableBankAccount {
      accountNumber
      routingNumber
    }
  }
`;

export { GQL_Q_GET_CURRENT_PAYABLE_BANK_ACCOUNT };
