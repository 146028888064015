import { useMutation } from "@apollo/client";
import { AddReferralCodeToCurrentSalesOrderGraphQLMutation } from "../../../../carbonext/graph/mutations/addReferralCodeToCurrentSalesOrder";
import gql from "graphql-tag";

export function useAddReferralCodeToCurrentSalesOrder(requestedAttrs) {
    let addReferralCodeToCurrentSalesOrderGraphQLMutation = new AddReferralCodeToCurrentSalesOrderGraphQLMutation();
    return useMutation(
        gql`
            ${addReferralCodeToCurrentSalesOrderGraphQLMutation.build({
                requestedAttrs,
            })}
        `
    );
}
