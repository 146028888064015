import React, { useState, useCallback } from "react";
import {
    Box,
    IconButton,
    VStack,
    Grid,
    Input,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from "../../Primatives";
import { getConstToLanguage } from "../ConstToLanguageMapping";

const ConfirmationModal = ({
    isOpen,
    onClose,
    mutation,
    confirmationBody,
    variables,
    isSubmitting,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {getConstToLanguage("CONFIRM_CHANGES")}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {confirmationBody || "Needs Confirmation"}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        {getConstToLanguage("CANCEL")}
                    </Button>
                    <Button
                        onClick={() => mutation({ variables })}
                        isLoading={isSubmitting}
                    >
                        {getConstToLanguage("CONFIRM")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export { ConfirmationModal };
