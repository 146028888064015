import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({

});

class TablePagination extends React.Component {

  constructor(props) {
    super(props)

    this.genClass = this.genClass.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
  }


  showNextButton() {

    if (this.props.nextPageFunc) {
      return (
        <button disabled={this.props.nextPageFunc && (this.props.offset + this.props.limit) >= this.props.totalCount} onClick={this.props.nextPageFunc()}>next</button>
      )
    }
    
  }
  showPreviousButton() {

    if (this.props.previousPageFunc) {
      return (
        <button disabled={this.props.previousPageFunc && this.props.offset === 0} onClick={this.props.previousPageFunc()}>previous</button>
      )
    }
    
  }

  showSearchField(searchValue) {

    if (this.props.searchValueUpdateFunc) {
      return (
        <div className="search">
          <input

                        type="text"
                        placeholder="search"
                        value={this.searchValue}
                        onChange={this.props.searchValueUpdateFunc} />
          <button onClick={this.props.submitSearchFunc(this.props.searchValue)}>search</button>
          <div> &nbsp; </div>
        </div>

      )
    } else {
      return (
        <div className="search">
          <div> &nbsp; </div>
        </div>

      )
    }
    
  }

  genClass() {
    if (this.props.class) {
      return "paginated-table " + this.props.class
    } else {
      return "paginated-table"
    }
  }

  renderTitle() {
    if (this.props.title) {
      return (
      <div className="pagination-table-title">
          { this.props.title}
      </div>
      )}
    
  }

  isDataLoading() {
    return (
      <tr >
        <td className="table-pagination-in-progress-td" colSpan="100%">
        <div className="table-pagination-in-progress">
        <div className="loader">

        </div>
        </div>
        </td>
      </tr>
      
    )
  }

  render() {

    return (
      <div className="pagination-table"> 
        
        { this.renderTitle()}
        
        <div className="pagination-table-tools">
          <div className="pagination-table-tools-left">
            { this.showSearchField(this.props.searchValue)}
          </div>
          <div className="pagination-table-tools-right">
            { this.showPreviousButton()}
            { this.showNextButton()}
            {/* TODO - make this better! */}
            { this.props.totalCount > 0 ? this.props.offset + 1 : 0} to { this.props.totalCount < (this.props.offset + this.props.limit) ? this.props.totalCount : (this.props.offset + this.props.limit)} of {this.props.totalCount}
          </div>
        </div>
        <div className="pagination-table-content">
          <table className={this.genClass()}>
              {this.props.headerRow}
              {this.props.isDataLoading ? this.isDataLoading() : this.props.rows}
          </table>
        </div>
      </div>);
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);