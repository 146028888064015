import React from "react";
import { EmailIcon } from "../../components/IconsV2";
import { SHAPE_TO_ICON_MAPPING, CLOSE_ICON } from "../../components/Icons";
import { numberToCurrency } from "../../components/UtilityComponents/etc/floatToCurrency";
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
import {
    Box,
    HStack,
    Center,
    Button,
    VStack,
    Heading,
    Text,
} from "@chakra-ui/react";

export const Email = ({ email, showIcon, iconColor = "black" }) => {
    return (
        <a href={`mailto:${email}`}>
            <HStack>
                <EmailIcon color={iconColor} w={12} />
                <Text fontSize="sm">{email}</Text>
            </HStack>
        </a>
    );
};
