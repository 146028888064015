import { ApolloProvider, useMutation, useQuery } from "@apollo/react-hooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { CONST_TO_LANG_MAP } from "../../../constants/businessConstantsToLanguageMapping";
import { vendorGraphClient } from "../../../graph";
import CloudManagmentOS from "../../../stories/CloudManagementOS";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
// import Settings from "./Settings";
import Settings from "../../../stories/CloudManagementOS/Settings";
import { ManageSFTPCredentials } from "../../../stories/CloudManagementOS/Settings/APISettings";
import VendorOrganizationSettings from "../../../stories/CloudManagementOS/Settings/VendorOrganizationSettings";
import {
    AccordianOrButtonsWithModal,
    useBreakpointValue,
} from "../../../stories/Primatives";
import { isVendorLoggedIn } from "../../helpers/currentUser";
import { GEM_LISTINGS, SETTINGS, TOOLS } from "../../Icons";
import { HomeIcon, iconography } from "../../IconsV2";
import {
    GQL_M_GENERATE_NEW_VENDOR_ORGANIZATION_APIS_SFTP_CREDENTIALS,
    GQL_M_SET_LAST_SEEN_WEB_NOTIFICATION,
    GQL_M_TEST_SSH_CREDENTIALS,
} from "../graph/mutations";
import { GQL_Q_SEARCH_VENDORS } from "../graph/queries";
import { GQL_Q_CURRENT_VENDOR } from "../graph/queries/currentVendor";
import { GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_NOTIFICATIONS } from "../graph/queries/getGlobalStatsFromSearchofNotification";
import { GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS } from "../graph/queries/MutationAudit/listCurrentlyRunningVendorMutations";
import VendorFirstLoginResetPassword from "../VendorInfoAcquistion/VendorFirstLoginResetPassword";
import InitialAddBankAccountInfoForVendor from "../VendorInfoAcquistion/InitialAddBankAccountInfoForVendor";
import { VendorHome } from "./Home";
import GeneralSettings from "./Settings";
import UserManagement from "../../../stories/CloudManagementOS/AccountManagment/UserManagement";
import Trader from "./Trader";
import { TraderTools } from "./Trader/Tools";
import Help from "./Help";
import { UserDataAcquisitionFlow } from "../../../stories/CloudManagementOS/UserDataAcquisitionFlow";
import {
    GQL_M_CREATE_BANK_ACCOUNT_FOR_VENDOR,
    GQL_M_DOWNLOAD_PRIVATE_KEY_AS,
} from "../graph/mutations";
import { GQL_Q_GET_CURRENT_PAYABLE_BANK_ACCOUNT } from "../graph/queries";
import {
    Box,
    Button,
    Container,
    Stack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
class CurrentVendor {
    constructor(otherVendorOrganizationsAuth, options = {}) {
        Object.assign(this, options);
        this.otherVendorOrganizationsAuth = otherVendorOrganizationsAuth;
    }

    getFullname() {
        return (
            this.vendor.person.givenName + " " + this.vendor.person.familyName
        );
    }
}

const GenSettings = (props) => {
    const settingsMode = useBreakpointValue({
        base: "buttons-with-modal",
        lg: "accordian",
    });

    let [createNewBankAccountForVendorMutation] = useMutation(
        GQL_M_CREATE_BANK_ACCOUNT_FOR_VENDOR
    );

    let { refetch: getCurrentPayableBankAccount } = useQuery(
        GQL_Q_GET_CURRENT_PAYABLE_BANK_ACCOUNT,
        {
            refetchOnWindowFocus: false,
            enabled: false,
        }
    );

    return (
        <Settings
            routePrefix={"/vendor/settings"}
            navConfig={[
                {
                    navItemLabel: getConstToLanguage("GENERAL"),
                    link: "/general",
                    view: <GeneralSettings />,
                },
                {
                    navItemLabel: getConstToLanguage("ORGANIZATION_SETTINGS"),
                    link: "/organization",
                    view: (
                        <VendorOrganizationSettings
                            createNewBankAccountForVendorMutation={
                                createNewBankAccountForVendorMutation
                            }
                            getCurrentPayableBankAccount={
                                getCurrentPayableBankAccount
                            }
                        />
                    ),
                },
                {
                    navItemLabel: getConstToLanguage("API_SETTINGS"),
                    link: "/api",
                    // view: <Card p />,
                    view: (
                        <Box paddingTop="4rem">
                            <AccordianOrButtonsWithModal
                                mode={settingsMode}
                                items={[
                                    {
                                        button: getConstToLanguage(
                                            "SFTP_CREDENTIALS"
                                        ),
                                        panel: (
                                            <ManageSFTPCredentials
                                                generateSFTPCredentialsMutation={
                                                    GQL_M_GENERATE_NEW_VENDOR_ORGANIZATION_APIS_SFTP_CREDENTIALS
                                                }
                                                testConnectionWithSFTPServerMutation={
                                                    GQL_M_TEST_SSH_CREDENTIALS
                                                }
                                                downloadPrivateKeyAsMutation={
                                                    GQL_M_DOWNLOAD_PRIVATE_KEY_AS
                                                }
                                            />
                                        ),
                                    },
                                ]}
                            />{" "}
                        </Box>
                    ),
                },
                {
                    navItemLabel: getConstToLanguage("USER_MANAGEMENT"),
                    link: "/users",
                    view: <UserManagement gqlQuery={GQL_Q_SEARCH_VENDORS} />,
                },
            ]}
        />
    );
};

const InnerHome = (props) => {
    function renderCurrentVendor(vendor, vendorOrganization) {
        return (
            <div>
                <div>{`${vendor?.person?.givenName} ${vendor?.person.familyName} `}</div>
                <div>{vendorOrganization?.legalName}</div>
            </div>
        );
    }

    function currentView(runningMutations) {
        if (props.match.path === "/vendor/home") {
            return (
                <VendorHome
                    runningMutations={
                        runningMutations &&
                        runningMutations.listCurrentUsersRunningMutations
                    }
                />
            );
        }

        if (props.match.params.tool === "gem-listings-manager") {
            return (
                <Trader
                    subtool={props.match.params.subtool}
                    param1={props.match.params.param1}
                    match={props.match}
                    runningMutations={
                        runningMutations &&
                        runningMutations.listCurrentUsersRunningMutations
                    }
                />
            );
        } else if (props.match.params.tool === "tools") {
            return (
                <TraderTools
                    subtool={props.match.params.subtool}
                    runningMutations={
                        runningMutations &&
                        runningMutations.listCurrentUsersRunningMutations
                    }
                />
            );
        } else if (props.match.params.tool === "help") {
            return (
                <Help
                    subtool={props.match.params.subtool}
                    runningMutations={
                        runningMutations &&
                        runningMutations.listCurrentUsersRunningMutations
                    }
                />
            );
        } else if (props.match.params.tool === "settings") {
            return <GenSettings {...props} />;
        }
    }

    let currentLanguage = "en";
    let navMapping = {
        header: {
            link: "/vendor/home",
        },
        navItems: {
            HOME: {
                icon: <HomeIcon />,
                link: "/vendor/home",
            },
            GEM_LISTINGS: {
                icon: GEM_LISTINGS(),
                link: "/vendor/gem-listings-manager/home",
            },
            // GEM_REQUESTS: {
            //   subitems: {
            //     UNFULFILLED: {
            //       link: "/vendor/trader/gem-requests"
            //     }
            //   }
            // },
            TOOLS: {
                icon: TOOLS(),
                link: "/vendor/tools/relative-price-search",
                // subitems: {
                //     RELATIVE_GEM_LISTING_SEARCH_TOOL: {},
                // },
            },
        },
    };

    // TODO NOT sure why need to pass the client even inside vendor client proviers
    const [setLastSeenWebNotificationCall] = useMutation(
        GQL_M_SET_LAST_SEEN_WEB_NOTIFICATION,
        {
            client: vendorGraphClient,
        }
    );

    const { loading, error, data, refetch } = useQuery(GQL_Q_CURRENT_VENDOR, {
        client: vendorGraphClient,
    });

    const {
        data: globalStatsFromSearchOfNotifications,
        refetch: refetchStatsFromSearchOfNotifications,
    } = useQuery(GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_NOTIFICATIONS, {
        client: vendorGraphClient,
    });

    const setLastSeenWebNotification = async (notificationId) => {
        const updateCacheForLastSeenWebNotification = async (
            cache,
            { data }
        ) => {
            const currentVendor = cache.readQuery({
                query: GQL_Q_CURRENT_VENDOR,
            });

            let updatedCurrentVendor = { ...currentVendor };
            updatedCurrentVendor.currentVendor.lastSeenWebNotification = notificationId;

            await cache.writeQuery({
                query: GQL_Q_CURRENT_VENDOR,
                data: updatedCurrentVendor,
            });

            await refetchStatsFromSearchOfNotifications();
        };

        await setLastSeenWebNotificationCall({
            variables: {
                notificationId,
            },
            update: updateCacheForLastSeenWebNotification,
        });
    };

    let currentVendor = data?.currentVendor;

    if (currentVendor && !currentVendor.vendor.hasResetFirstTimePassword) {
        return <VendorFirstLoginResetPassword currentVendor={currentVendor} />;
    }

    // return (
    //   <UserDataAcquisitionFlow
    //     subFlows={[<InitialAddBankAccountInfoForVendor />]}
    //   />
    // );

    // return;

    let vendorTokensForOtherVendorOrganizations = localStorage.getItem(
        "vendorTokensForOtherVendorOrganizations"
    );

    if (vendorTokensForOtherVendorOrganizations) {
        vendorTokensForOtherVendorOrganizations = JSON.parse(
            vendorTokensForOtherVendorOrganizations
        );
    }

    return (
        <CloudManagmentOS
            notifications={{
                stats:
                    globalStatsFromSearchOfNotifications?.getGlobalStatsFromSearchOfNotifications,
            }}
            iconography={iconography}
            loading={loading}
            setLastSeenWebNotification={setLastSeenWebNotification}
            currentUserObj={
                currentVendor &&
                new CurrentVendor(vendorTokensForOtherVendorOrganizations, {
                    ...currentVendor,
                })
            }
            logoVariant={
                props.match.params.tool === "settings" ? "light" : null
            }
            constToLanguageMap={CONST_TO_LANG_MAP}
            navMapping={navMapping}
            navVariant={props.navVariant}
            currentLanguage={currentLanguage}
            main={currentView()}
            userType={"vendor"}
            companyGlobalPublicEventsChannel={"public:events"}
            companyIdentifier={"carbonext"}
            currentUser={
                currentVendor &&
                renderCurrentVendor(
                    data?.currentVendor?.vendor,
                    data?.currentVendor?.vendorOrganization
                )
            }
            graphAPI={{
                listCurrentUsersRunningMutations: GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS,
            }}
        />
    );
};

const Home = (props) => {
    const history = useHistory();
    let navVariant = useBreakpointValue({ base: "collapsed", xl: "full" });

    if (isVendorLoggedIn()) {
        return (
            <ApolloProvider client={vendorGraphClient}>
                <InnerHome navVariant={navVariant} match={props.match} />
            </ApolloProvider>
        );
    } else {
        history.push("/vendor/login");
        return <div></div>;
    }
};

export default Home;
