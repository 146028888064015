import React, { useContext } from "react";
import { Box, IconButton, Text, useDisclosure, VStack } from "../../Primatives";
import { getConstToLanguage } from "../ConstToLanguageMapping";
import { CloudManagementOSVariant } from "../Context";
import { Header } from "../Header";
import { HeaderBodyShell } from "../shells";
import { HeaderBodyWithDrawers } from "../shells/HeaderBodyWithDrawers";
import { SearchResults } from "./SearchResults";

const Body = React.memo(({ actionMenuButtons, ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    function genFullUrlPrefix() {
        return `/${props.urlPrefix}/${props.toolUrlRoute}`;
    }

    let cloudManagementOSVariant = useContext(CloudManagementOSVariant);

    function renderCurrentSearchParam() {
        if (props.searchParam && props.searchParam.filter) {
            return (
                <div className="model_manager__toolbar__current_search_param">
                    {Object.entries(props.searchParam.filter).map(
                        ([attr, filter]) => {
                            if (filter) {
                                if (props.searchParamFilterRenderFunc) {
                                    return props.searchParamFilterRenderFunc(
                                        attr,
                                        filter
                                    );
                                }
                                return (
                                    <div className="filtered-attr">
                                        {attr}: {filter.toString()}
                                    </div>
                                );
                            }
                        }
                    )}
                </div>
            );
        }
    }

    function renderCount() {
        return (
            <Text
                key={"totalCount"}
                fontSize="xs"
            >{`Count: ${props.totalCount}`}</Text>
        );
    }

    return (
        <HeaderBodyWithDrawers
            isDrawerOpen={isOpen}
            actionMenuButtons={actionMenuButtons}
            onDrawerClose={onClose}
            btnRef={btnRef}
            tools={
                cloudManagementOSVariant === "collapse" ? (
                    <IconButton
                        padding={2}
                        ref={btnRef}
                        onClick={onOpen}
                        variant="toolbar"
                        icon={"SEARCH"}
                    ></IconButton>
                ) : null
            }
            meta={renderCount()}
            breadcrumbs={[
                {
                    breadcrumb: props.category,
                    link: `${genFullUrlPrefix()}/${props.modelUrlName}/${
                        props.category
                    }`,
                },
                { breadcrumb: props.state },
            ]}
            drawer={
                props.searchFilterLocation === "search-filter"
                    ? {
                          ...props.searchSection,
                          body: (
                              <VStack>
                                  <Box>{props.searchSection.body}</Box>
                              </VStack>
                          ),
                      }
                    : props.searchSection
            }
            body={
                <SearchResults
                    items={props.items}
                    listItemFunc={props.listItemFunc}
                    onLoadMore={props.onLoadMore}
                    pageInfo={props.pageInfo}
                    isDataLoading={props.isDataLoading}
                    loadMoreIcon={props.loadMoreIcon}
                />
            }
        />
    );
});

export const ModelManager = ({ modelName, ...props }) => {
    function genFullUrlPrefix() {
        return `/${props.urlPrefix}/${props.toolUrlRoute}`;
    }

    return (
        <HeaderBodyShell
            header={
                <Header
                    linkTo={genFullUrlPrefix() + "/home"}
                    title={modelName}
                />
            }
            body={<Body {...props} />}
        />
    );
};
