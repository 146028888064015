import React from "react";
import {
    Heading,
    Center,
    HStack,
    Container,
    chakra,
    Box,
    shouldForwardProp,
} from ".";
import { motion, isValidMotionProp } from "framer-motion";

const ChakraBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: (prop) =>
        isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const LoadingScreen = ({
    logo,
    title,
    marginTop,
    h = "100vh",
    w = "100vw",
}) => {
    return (
        <Container
            h={h}
            w={w}
            marginTop={marginTop}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <ChakraBox
                animate={{
                    scale: [1, 1.25, 1.25, 1, 1],
                    // rotate: [0, 0, 270, 270, 0],
                    borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                }}
                // @ts-ignore no problem in operation, although type error appears.
                transition={{
                    duration: 2,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "loop",
                }}
                padding="2"
                // bgGradient="linear(to-l, #7928CA, #FF0080)"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <HStack spacing={6}>
                    <Box width="80px" height="80px">
                        {logo}
                    </Box>
                    <Heading fontFamily="Montserrat">{title}</Heading>
                </HStack>
            </ChakraBox>
        </Container>
    );
};
