import {
  TRADER_CREATE_GEM_LISTING,
  TRADER_UPDATE_SPEC_FOR_NEW_GEM_LISTING,
  TRADER_CREATE_GEM_LISTING_UNLOADED
} from "../constants/actionTypes";

function initialState() {
  return {
    carat: 0,
    shape: "ROUND",
    cut: "Good",
    clarity: "I1",
    color: "J",
    certificationId: "",
    polish: "Good",
    symmetry: "Good",
    fluorescenceIntensity: "None",
    fluorescenceColor: "Blue",
    depthPercentage: 0,
    tablePercentage: 0,
    dimensions: ""
  };
}

export default (state = initialState(), action) => {
  switch (action.type) {
    case TRADER_CREATE_GEM_LISTING:
      return initialState();
    case TRADER_CREATE_GEM_LISTING_UNLOADED:
      return initialState();
    case TRADER_UPDATE_SPEC_FOR_NEW_GEM_LISTING:
      return { ...state, [action.gemListingSpec]: action.value };
    default:
      return state;
  }
};
