let currency = "USD";

const floatToCurrency = (amount) => {
    if (currency === "USD") {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    }
};

const numberToCurrency = (number) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);
};

module.exports.floatToCurrency = floatToCurrency;
module.exports.numberToCurrency = numberToCurrency;
