import gql from "graphql-tag";

export const GQL_Q_SEARCH_VENDORS = gql`
  query SearchVendors {
    searchAssociatedVendors {
      totalCount
      edges {
        node {
          roles {
            type
          }
          state
          vendor {
            id
            localAuth {
              email
            }
            person {
              givenName
              additionalName
              familyName
            }
          }
        }
      }
    }
  }
`;
