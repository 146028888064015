import {
  ADMIN_HOME_PAGE_LOADED,
  ADMIN_HOME_PAGE_UNLOADED,
  GEM_VERIFIED,
  GEM_UNVERIFIED,
  ADMIN_USER_MANAGEMENT_PAGE_LOADED,
  ADMIN_CHANGE_TOOL,
  ADMIN_ADMIN_MANAGEMENT_PAGE_LOADED,
  UPDATE_NEW_ADMIN_FIELD,
  ADMIN_CREATED,
  TOGGLE_ADMIN_ACTIVE_ATTR,
  CREATE_ADMIN,
  ADMIN_GEM_DELETED,
  ADMIN_USER_DELETED,
  ADMIN_GEM_MANAGEMENT_PAGE_LOADED,
  ADMIN_TOGGLE_SHOW_JEWELRY_MAKER_INFO,
  ADMIN_TOGGLE_SHOW_TRADER_INFO,
  ADMIN_TOGGLE_SHOW_TRADER_GEMS,
  ADMIN_GEMS_NEXT_PAGE,
  ADMIN_GEMS_PREVIOUS_PAGE,
  ADMIN_TRADER_GEMS_NEXT_PAGE,
  ADMIN_TRADER_GEMS_PREVIOUS_PAGE,
  ADMIN_GEM_SEARCH_UPDATE_FIELD,
  ADMIN_MARK_GEM_AS_SOLD,
  ADMIN_TOGGLE_GEM_HISTORY_VIEW,
  CLOSE_APP_EVENT,
  GEM_LISTING_MANAGER_TOGGLE_STATE,
  GEM_LISTING_MANAGER_TOGGLE_SHAPE,
  GET_DIAMOND_RELATIVE_PRICE_DATA,
  GEM_LISTING_MANAGER_SEARCH_SUBMIT,
  GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_STATES,
  GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_SHAPES,
  GEM_LISTING_MANAGER_SEARCH_CHANGE_ATTR,
  ADMIN_RESET_ATTR_INITIAL_SEARCH_STATE,
  ADMIN_GEM_MANAGEMENT_SUBTOOL_UNLOADED,
  ASYNC_START,
  ASYNC_END,
  GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR
} from "../constants/actionTypes";

import {
  ROLE_TO_INITIAL_SUBTOOL_GLS_MAPPING
} from "../constants/businessConstants";

import { filterSearchParamForSubTool } from "../helpers/filters.js";

const uuidv4 = require("uuid/v4");

let GLSHelper = require("./helpers/GLS");

export default (state = initialState(), action) => {
  let buildInitialNextPageGemRows;

  switch (action.type) {
    case UPDATE_NEW_ADMIN_FIELD:
      return { ...state, [action.key]: action.value };

    case ADMIN_GEM_SEARCH_UPDATE_FIELD:
      let newGemsSearchParam = { ...state.gemListingsSearchParam };
      newGemsSearchParam.gia = action.value;

      return { ...state, gemListingsSearchParam: newGemsSearchParam };

    case ADMIN_RESET_ATTR_INITIAL_SEARCH_STATE:
      return {
        ...state,
        gemListingRows: buildInitialNextPageGemRows,
        gemListingsCount: action.payload.gemListingsCount,
        searchStats: action.payload[0].stats,
        gemListingsSearchFlags: {
          ...state.gemListingsSearchFlags,
          statesFiltered: !(
            state.gemListingsSearchParam.states.length ===
            initialState().gemListingsSearchParam.states.length
          ),
          shapesFiltered: !(
            state.gemListingsSearchParam.shapes.length ===
            initialState().gemListingsSearchParam.shapes.length
          )
        }
      };

    case GEM_LISTING_MANAGER_SEARCH_SUBMIT:
    case ADMIN_GEMS_PREVIOUS_PAGE:
    case ADMIN_GEMS_NEXT_PAGE:
      console.log("WEOIUPEOIUEWP");
      console.log(
        state.gemListingsSearchParam.states.length ===
          initialState().gemListingsSearchParam.states.length
      );

      buildInitialNextPageGemRows = action.payload.gemListings.map(gemListing => {
        return { gemListing: gemListing, priceEditMode: false, showEventHistory: false };
      });

      return {
        ...state,
        gemListingRows: buildInitialNextPageGemRows,
        gemListingsCount: action.payload.gemListingsCount,
        searchStats: action.payload.stats,
        gemListingsSearchFlags: {
          ...state.gemListingsSearchFlags,
          statesFiltered: !(
            state.gemListingsSearchParam.states.length ===
            initialState().gemListingsSearchParam.states.length
          ),
          shapesFiltered: !(
            state.gemListingsSearchParam.shapes.length ===
            initialState().gemListingsSearchParam.shapes.length
          )
        }
      };

    case ADMIN_TRADER_GEMS_NEXT_PAGE:
    case ADMIN_TRADER_GEMS_PREVIOUS_PAGE:
    case ADMIN_TOGGLE_SHOW_TRADER_GEMS:
      console.log("NEW USER");
      console.log(action);

      let newUserTraderRow = state.userRows
        .filter(userRow => userRow.user._id === action.user._id)
        .slice()[0];

      let buildInitialGemRowsForTrader = action.payload.gemListings.map(gemListing => {
        return { gemListing: gemListing, priceEditMode: false, showEventHistory: false };
      });
      newUserTraderRow.user.vendor.trader.gemListingRows = buildInitialGemRowsForTrader;
      newUserTraderRow.user.vendor.trader.showGems = true;
      newUserTraderRow.traderGemSearchParam = action.searchParam;

      return {
        ...state,
        userRows: state.userRows.map(userRow => {
          if (userRow.user._id === newUserTraderRow.user._id) {
            return newUserTraderRow;
          }
          return userRow;
        })
      };

    case CREATE_ADMIN:
      return state;

    case ADMIN_CHANGE_TOOL:
      return {
        ...state,
        api: action.api
      };

    case ADMIN_TOGGLE_GEM_HISTORY_VIEW:
      return {
        ...state,
        gemListingRows: state.gemListingRows.map(gemListingRow => {
          if (gemListingRow.gemListing._id === action.gemListing._id) {
            return {
              ...gemListingRow,
              showEventHistory: !gemListingRow.showEventHistory
            };
          }
          return gemListingRow;
        })
      };

    case GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_STATES:
      let newStateList2;
      if (
        state.gemListingsSearchParam.states.length ===
        initialState().gemListingsSearchParam.states.length
      ) {
        newStateList2 = [];
      } else {
        newStateList2 = initialState().gemListingsSearchParam.states;
      }
      return {
        ...state,
        gemListingsSearchParam: { ...state.gemListingsSearchParam, states: newStateList2 }
      };
    case GEM_LISTING_MANAGER_SEARCH_CHANGE_ATTR:
      let newGemsSearch = { ...state.gemListingsSearchParam };
      newGemsSearch[action.attr] = action.value;

      return {
        ...state,
        gemListingsSearchParam: newGemsSearch
      };

    case GEM_LISTING_MANAGER_TOGGLE_STATE:
      let newStateList = state.gemListingsSearchParam.states.slice();

      if (newStateList.includes(action.state)) {
        console.log("EIORWEOIPRUPEWEW");
        newStateList = newStateList.filter(state => state !== action.state);
      } else {
        newStateList.push(action.state);
      }

      return {
        ...state,
        gemListingsSearchParam: { ...state.gemListingsSearchParam, states: newStateList }
      };

    case GEM_LISTING_MANAGER_TOGGLE_SHAPE:
      let newShapeList = state.gemListingsSearchParam.shapes.slice();

      if (newShapeList.includes(action.shape)) {
        newShapeList = newShapeList.filter(shape => shape !== action.shape);
      } else {
        newShapeList.push(action.shape);
      }

      return {
        ...state,
        gemListingsSearchParam: { ...state.gemListingsSearchParam, shapes: newShapeList }
      };

    case GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_SHAPES:
      let newShapeList2;
      if (
        state.gemListingsSearchParam.shapes.length ===
        initialState().gemListingsSearchParam.shapes.length
      ) {
        newShapeList2 = [];
      } else {
        newShapeList2 = initialState().gemListingsSearchParam.shapes;
      }
      return {
        ...state,
        gemListingsSearchParam: { ...state.gemListingsSearchParam, shapes: newShapeList2 }
      };

    case ADMIN_GEM_DELETED:
      console.log();

      let newGemRows = state.gemListingRows.filter(
        gemListingRow => gemListingRow.gemListing._id !== action.payload.gemListing._id
      );

      return {
        ...state,
        gemListingRows: newGemRows,
        gemListingsCount: state.gemListingsCount - 1
      };

    case ADMIN_USER_DELETED:
      let newUserRows = state.userRows.filter(
        userRow => userRow.user._id !== action.payload._id
      );

      return {
        ...state,
        userRows: newUserRows,
        usersCount: state.usersCount - 1
      };
    case ADMIN_GEM_MANAGEMENT_SUBTOOL_UNLOADED:
      return initialState();
    case TOGGLE_ADMIN_ACTIVE_ATTR:
      return {
        ...state,
        adminRows: state.adminRows.map(adminRow => {
          if (adminRow.admin._id === action.payload.admin._id) {
            return {
              ...adminRow,
              admin: action.payload.admin
            };
          }
          return adminRow;
        })
      };

    case ADMIN_CREATED:
      if (!action.payload.errors) {
        let newAdminRow = { admin: action.payload.admin };
        return {
          ...state,
          newAdminEmail: "",
          adminRows: [newAdminRow].concat(state.adminRows)
        };
      } else {
        return state;
      }

    case ADMIN_ADMIN_MANAGEMENT_PAGE_LOADED:
      let buildInitialAdminRows = action.payload[0].admins.map(admin => {
        return { admin: admin };
      });

      return {
        ...state,
        adminRows: buildInitialAdminRows,
        adminsCount: action.payload[0].adminsCount
      };

    case ADMIN_GEM_MANAGEMENT_PAGE_LOADED:
      if (action.payload.errors) {
        break;
      } else {
        let buildInitialGemRows = action.payload[0].gemListings.map(gemListing => {
          return { gemListing: gemListing, priceEditMode: false, showEventHistory: false };
        });

        return {
          ...state,
          gemListingRows: buildInitialGemRows,
          gemListingsCount: action.payload[0].gemListingsCount,
          currentlySelectedGLS:
              ROLE_TO_INITIAL_SUBTOOL_GLS_MAPPING.admin[action.subtool],
          globalGLSStats: action.payload[1],
          searchStats: action.payload[0].stats,
          gemListingsSearchParam: filterSearchParamForSubTool(
              action.subtool,
              initialState(action.subtool).gemListingsSearchParam
          ),
          gemListingSearchResultsByCategory: action.subtool === 'past-sales' ? action.payload[2] : null,
          reservedGemListings:
              action.payload.length === 3
                  ? action.payload[2].reservedGemListings
                  : null

        };
      }

    case ADMIN_USER_MANAGEMENT_PAGE_LOADED:
      if (action.payload.errors) {
        break;
      } else {
        let buildInitialUserManagement = action.payload[0].users.map(user => {
          return {
            user: user,
            showTraderInfo: false,
            showJewelryMakerInfo: false
          };
        });

        return {
          ...state,
          userRows: buildInitialUserManagement,
          usersCount: action.payload[0].usersCount
        };
      }

    case ADMIN_TOGGLE_SHOW_TRADER_INFO:
      if (action.payload && action.payload.errors) {
        break;
      } else {
        return {
          ...state,
          userRows: state.userRows.map(userRow => {
            if (userRow.user._id === action.user._id) {
              return {
                ...userRow,
                showTraderInfo: !userRow.showTraderInfo
              };
            }
            return userRow;
          })
        };
      }
    case ADMIN_TOGGLE_SHOW_JEWELRY_MAKER_INFO:
      if (action.payload.errors) {
        break;
      } else {
        return {
          ...state,
          userRows: state.userRows.map(userRow => {
            if (userRow.user._id === action.user._id) {
              return {
                ...userRow,
                showJewelryMakerInfo: !userRow.showJewelryMakerInfo
              };
            }
            return userRow;
          })
        };
      }
    // case ADMIN_GEM_LISTING_GO_TO_SUBTOOL:
    //   let filteredSearchBySubTool = searchParam
    //   if (action.subtool === 'market') {
    //     filteredSearchBySubTool.states['ON_MARKET', 'OFF_MARKET']
    //   }
    //   return return {
    //       ...state,
    //   }

    case ADMIN_HOME_PAGE_UNLOADED:
      return {};
    case ADMIN_HOME_PAGE_LOADED:
      return { ...state };
    case ADMIN_MARK_GEM_AS_SOLD:
      if (action.payload.result.type !== "error") {
        return {
          ...state,
          gemListingRows: state.gemListingRows.map(gemListingRow => {
            if (gemListingRow.gemListing._id === action.payload.gemListing._id) {
              let newGem = {
                ...gemListingRow.gemListing,
                state: action.payload.gemListing.state
              };
              return {
                ...gemListingRow,
                gemListing: newGem
              };
            }
            return gemListingRow;
          })
        };
      }
      return state;
    case CLOSE_APP_EVENT:
      let newEvents2 = state.adminEvents.filter(function(item) {
        return item.eventId !== action.event.eventId;
      });

      return {
        ...state,
        adminEvents: newEvents2
      };
    case GET_DIAMOND_RELATIVE_PRICE_DATA:
      return {
        ...state,
        gemRelativeData: { ...state.gemRelativeData, ...action.payload }
      };

    case GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR:
      let buildInitialGemRows2 = action.payload.gemListings.map(gemListing => {
        return { gemListing: gemListing, priceEditMode: false, showEventHistory: false };
      });

      return {
        ...state,
        gemListingRows: buildInitialGemRows2,
        gemListingsCount: action.payload.gemListingsCount,
        searchStats: action.payload.stats,
        gemListingsSearchParam: action.newGLSearchParam,
        currentlySelectedGLS: action.gls
      };

    case GEM_VERIFIED:
    case GEM_UNVERIFIED:
      let newState = GLSHelper.updateGLS(state, action);
      return newState;
    case ASYNC_START:
      if (
        action.subtype === ADMIN_GEM_MANAGEMENT_PAGE_LOADED ||
        action.subtype === GEM_LISTING_MANAGER_SEARCH_SUBMIT ||
        action.subtype === ADMIN_GEMS_NEXT_PAGE ||
        action.subtype === ADMIN_GEMS_PREVIOUS_PAGE ||
        action.subtype === GEM_LISTING_MANAGER_SEARCH_SUBMIT ||
        action.subtype === GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR
      ) {
        return { ...state, isDataLoading: true };
      }
      return state;
    case ASYNC_END:
      // TODO - split admin and gemlisting into 2 reducers
      let apiSplit = action.path.split("/");
      let tool = apiSplit[1];
      let view = apiSplit[2];
      let returnState = { ...state };

      if (tool === "admin" && view !== "login") {
        if (action.promise && action.promise.result !== undefined) {
          let newEvents = state.adminEvents.slice();
          action.promise.eventId = uuidv4();
          newEvents.push(action.promise);

          returnState = {
            ...returnState,
            adminEvents: newEvents
          };
        }
      }

      if (
        action.subtype === ADMIN_GEM_MANAGEMENT_PAGE_LOADED ||
        action.subtype === GEM_LISTING_MANAGER_SEARCH_SUBMIT ||
        action.subtype === ADMIN_GEMS_NEXT_PAGE ||
        action.subtype === ADMIN_GEMS_PREVIOUS_PAGE ||
        action.subtype === GEM_LISTING_MANAGER_SEARCH_SUBMIT ||
        action.subtype === GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR
      ) {
        return { ...returnState, isDataLoading: false };
      }
      return returnState;
    default:
      return state;
  }
};

function initialState(subtool) {
  return {
    adminEvents: [],
    newAdminEmail: "",
    gemListingsSearchParam: filterSearchParamForSubTool(subtool, {
      gia: "",
      limit: 20,
      offset: 0,
      email: "",
      certificationId: null,
      shapes: [
        "ROUND",
        "PRINCESS",
        "PEAR",
        "OVAL",
        "HEART",
        "MARQUISE",
        "ASSCHER",
        "CUSHION",
        "RADIANT",
        "OLD_MINER",
        "EUROPEAN_CUT",
        "EMERALD",
        "TRILLIANT"
      ],
      states: [
        "UNVERIFIED",
        "VERIFIED",
        "ON_MARKET",
        "OFF_MARKET",
        "SOLD",
        "REJECTED",
        "NEW",
        "VERIFICATION_REQUESTED"
      ]
    }),
    usersSearchParam: {
      offset: 0,
      limit: 20
    },
    gemListingsSearchFlags: {
      statesFiltered: false,
      shapesFiltered: false
    },
    isDataLoading: false
  };
}
