import PubSub from "pubsub-js";
import { useEffect, useState } from "react";

export default function useToggle(defaultValue) {
  let [isToggled, setIsToggled] = useState(defaultValue);

  const toggle = () => {
    setIsToggled(!isToggled);
  };
  return { isToggled, toggle };
}
