import React, { useState, useCallback } from "react";
import { Box, useDisclosure } from "../../Primatives";
import { ConfirmationModal } from "../ConfirmationModal";

const ActionsThatNeedConfirmation = ({ body, funcToRun, confirmationBody }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const _wrappedFunc = async (e) => {
        await funcToRun(e);
        onClose();
    };
    return (
        <Box w="100%">
            <Box onClick={onOpen}>{body}</Box>
            <ConfirmationModal
                onOpen={onOpen}
                isOpen={isOpen}
                confirmationBody={confirmationBody}
                mutation={_wrappedFunc}
                onClose={onClose}
            />
        </Box>
    );
};

export { ActionsThatNeedConfirmation };
