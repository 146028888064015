import { createIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { AsscherShapeIcon } from "./AsscherShapeIcon";
import { CushionShapeIcon } from "./CushionShapeIcon";
import { EmeraldShapeIcon } from "./EmeraldShapeIcon";
import { EuropeanCutShapeIcon } from "./EuropeanCutShapeIcon";
import { HeartShapeIcon } from "./HeartShapeIcon";
import { MarquiseShapeIcon } from "./MarquiseShapeIcon";
import { OldMinerShapeIcon } from "./OldMinerShapeIcon";
import { OvalShapeIcon } from "./OvalShapeIcon";
import { PearShapeIcon } from "./PearShapeIcon";
import { PrincessShapeIcon } from "./PrincessShapeIcon";
import { RadiantShapeIcon } from "./RadiantShapeIcon";
import { RoseShapeIcon } from "./RoseShapeIcon";
import { RoundShapeIcon } from "./RoundShapeIcon";
import { SearchIcon } from "./SearchIcon";
import { SquareEmeraldShapeIcon } from "./SquareEmeraldShapeIcon";
import { TrilliantShapeIcon } from "./TrilliantShapeIcon";
import { PhoneIcon } from "./PhoneIcon";
import { EmailIcon } from "./EmailIcon";
import { MapLocationIcon } from "./MapLocationIcon";
import { ShippingTruck } from "./ShippingTruck";
import { Video360Icon } from "./Video360Icon";

const SHAPE_TO_ICON_MAPPING = {
    ROUND: RoundShapeIcon,
    PRINCESS: PrincessShapeIcon,
    RADIANT: RadiantShapeIcon,
    PEAR: PearShapeIcon,
    ROSE: RoseShapeIcon,
    OVAL: OvalShapeIcon,
    OLD_MINER: OldMinerShapeIcon,
    MARQUISE: MarquiseShapeIcon,
    EMERALD: EmeraldShapeIcon,
    CUSHION: CushionShapeIcon,
    ASSCHER: AsscherShapeIcon,
    HEART: HeartShapeIcon,
    TRILLIANT: TrilliantShapeIcon,
    EUROPEAN_CUT: EuropeanCutShapeIcon,
    SQUARE_RADIANT: RadiantShapeIcon,
    SQUARE_EMERALD: SquareEmeraldShapeIcon,
};

const SHAPE_CATEGORY_TO_ICON_MAPPING = {
    ROUND: RoundShapeIcon,
    PRINCESS: PrincessShapeIcon,
    RADIANT: RadiantShapeIcon,
    PEAR: PearShapeIcon,
    OVAL: OvalShapeIcon,
    OLD_MINER: OldMinerShapeIcon,
    MARQUISE: MarquiseShapeIcon,
    EMERALD: EmeraldShapeIcon,
    CUSHION: CushionShapeIcon,
    ASSCHER: AsscherShapeIcon,
    HEART: HeartShapeIcon,
    TRILLIANT: TrilliantShapeIcon,
    EUROPEAN_CUT: EuropeanCutShapeIcon,
};

const getShapeIcon = (shape) => {
    if (shape in SHAPE_TO_ICON_MAPPING && SHAPE_TO_ICON_MAPPING[shape]) {
        return SHAPE_TO_ICON_MAPPING[shape];
    }
    return shape;
};

const getShapeCategoryIcon = (shapeCategory) => {
    if (
        shapeCategory in SHAPE_TO_ICON_MAPPING &&
        SHAPE_TO_ICON_MAPPING[shapeCategory]
    ) {
        return SHAPE_TO_ICON_MAPPING[shapeCategory];
    }
    return shapeCategory;
};

const SettingsIcon = (props) => {
    return (
        <Icon viewBox="0 0 42 42" {...props}>
            <g id="ico-settings" transform="translate(-112 -39)">
                <rect
                    id="Rectángulo_831"
                    data-name="Rectángulo 831"
                    width="42"
                    height="42"
                    transform="translate(112 39)"
                    fill="none"
                />
                <path
                    id="gear"
                    color={props.color}
                    d="M29.375,14.443,27.2,12.74a7.924,7.924,0,0,0,0-1.789l2.18-1.7a1.068,1.068,0,0,0,.258-1.361L27.369,3.97a1.071,1.071,0,0,0-1.31-.472L23.493,4.531a8.793,8.793,0,0,0-1.542-.9L21.557.909A1.049,1.049,0,0,0,20.508,0H15.967a1.046,1.046,0,0,0-1.042.9L14.53,3.635a9.118,9.118,0,0,0-1.54.9L10.416,3.5a1.049,1.049,0,0,0-1.3.463L6.848,7.883A1.06,1.06,0,0,0,7.106,9.25l2.178,1.7a7.85,7.85,0,0,0,0,1.789l-2.18,1.7a1.067,1.067,0,0,0-.259,1.361L9.11,19.724a1.07,1.07,0,0,0,1.31.472l2.567-1.033a8.833,8.833,0,0,0,1.543.9l.395,2.725a1.047,1.047,0,0,0,1.042.909h4.541a1.047,1.047,0,0,0,1.045-.9l.395-2.735a9.136,9.136,0,0,0,1.54-.9L26.065,20.2a1.05,1.05,0,0,0,1.3-.463L29.64,15.8A1.065,1.065,0,0,0,29.375,14.443Zm-11.138,2.34a4.936,4.936,0,1,1,4.936-4.936A4.936,4.936,0,0,1,18.238,16.783Z"
                    transform="translate(115.058 48.154)"
                />
            </g>
        </Icon>
    );
};

// createIcon({
//   displayName: "SettingsIcon",
//   viewBox: "0 0 42 42",
//   width: "100%",
//   path: <Fragment></Fragment>,
// });

const FilledStar = createIcon({
    displayName: "FilledStar",
    viewBox: "0 0 17 17",
    path: (
        <Fragment>
            <defs>
                <clipPath id="5dfevb9lha">
                    <path
                        fill="#efbe2c"
                        stroke="#707070"
                        d="M0 0H17V17H0z"
                        transform="translate(1500.853 2177.853)"
                    />
                </clipPath>
            </defs>
            <g
                clipPath="url(#5dfevb9lha)"
                transform="translate(-1500.853 -2177.853)"
            >
                <path
                    fill="#efbe2c"
                    d="M16.663 6.078a.886.886 0 0 0-.764-.611l-4.822-.437L9.17.567a.888.888 0 0 0-1.633 0L5.63 5.03l-4.823.437A.889.889 0 0 0 .3 7.021l3.645 3.2-1.072 4.731a.887.887 0 0 0 1.321.959l4.159-2.486 4.158 2.486a.888.888 0 0 0 1.321-.959l-1.075-4.734 3.645-3.2a.888.888 0 0 0 .26-.944zm0 0"
                    transform="translate(1501 2178.32)"
                />
            </g>
        </Fragment>
    ),
});

const HalfFilledStar = createIcon({
    displayName: "FilledStar",
    viewBox: "0 0 17 17",
    path: (
        <Fragment>
            <defs>
                <clipPath id="5dfevb9lha">
                    <path
                        fill="#efbe2c"
                        stroke="#707070"
                        d="M0 0H17V17H0z"
                        transform="translate(1500.853 2177.853)"
                    />
                </clipPath>
            </defs>
            <g
                clipPath="url(#5dfevb9lha)"
                transform="translate(-1500.853 -2177.853)"
            >
                <path
                    fill="#efbe2c"
                    d="M16.663 6.078a.886.886 0 0 0-.764-.611l-4.822-.437L9.17.567a.888.888 0 0 0-1.633 0L5.63 5.03l-4.823.437A.889.889 0 0 0 .3 7.021l3.645 3.2-1.072 4.731a.887.887 0 0 0 1.321.959l4.159-2.486 4.158 2.486a.888.888 0 0 0 1.321-.959l-1.075-4.734 3.645-3.2a.888.888 0 0 0 .26-.944zm0 0"
                    transform="translate(1501 2178.32)"
                />
            </g>
        </Fragment>
    ),
});

// export const LogoWithWordsIcon = createIcon({
//     displayName: "LogoWithWordsIcon",
//     viewBox: "0 0 778 135",
//     defaultProps: { height: 'auto', width: 'auto' },
//     // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
//     path: (
//         <g transform="translate(-46 -479)">
//             <rect transform="translate(46 479)" width="257" height="68" fill="none" data-name="Rectángulo 830" />
//             <g transform="translate(-143.71 392.77)" data-name="Grupo 70">
//                 <g transform="translate(254.83 105.1)" data-name="Grupo 4">
//                     <g fill="#222" data-name="Grupo 3">
//                         <path transform="translate(-299.82 -118.22)" d="m304.72 128.77c0 5.3 2.96 8.469 7.649 8.469a6.354 6.354 0 0 0 5.773-3.077l0.938 1.114c-1.26 2.316-4.308 4.045-8.177 4.045-6.125 0-11.078-4.455-11.078-10.521" data-name="Trazado 13" />
//                         <path transform="translate(-299.82 -109.5)" d="m299.82 120.12c0-6.066 4.953-10.521 11.078-10.521 3.869 0 6.916 1.729 8.177 4.045l-0.938 1.114a6.353 6.353 0 0 0-5.773-3.077c-4.689 0-7.649 3.165-7.649 8.469" data-name="Trazado 14" />
//                         <path transform="translate(-317.18 -109.66)" d="m350.38 125.52h-8.206l-2.139 4.953h-2.052l9-20.514h1.612l8.968 20.514h-5.041zm-0.7-1.582-3.429-7.913-3.4 7.913z" data-name="Trazado 15" />
//                         <path transform="translate(-335.93 -109.66)" d="m397.32 130.47h-5.158a4.447 4.447 0 0 1-1.289-1.7l-2.433-5.305a3.061 3.061 0 0 0-1.084-1.289h-3.37v8.294h-4.777v-20.514h8.851c4.4 0 7.766 2.52 7.766 6.1 0 2.813-2.022 4.953-5.011 5.773a4.47 4.47 0 0 1 3.048 2.578l1.817 3.839a5.6 5.6 0 0 0 1.64 2.224zm-13.334-9.876h2.96c2.4 0 3.986-1.789 3.986-4.514 0-2.754-1.583-4.542-3.986-4.542h-2.96z" data-name="Trazado 16" />
//                         <path transform="translate(-353.21 -109.66)" d="m434.57 125.1c0 3.135-2.931 5.363-6.565 5.363h-10.814v-20.514h9.935c3.781 0 6.565 2.2 6.565 5.07 0 2.169-1.349 3.81-3.605 4.747 2.726 0.909 4.484 2.697 4.484 5.334zm-12.6-13.569v7.59h2.727c2.462 0 4.1-1.231 4.1-3.927a3.4 3.4 0 0 0-3.663-3.663zm7.707 13.51c0-3.81-2.784-4.337-4.1-4.337h-3.6v8.177h4.044a3.482 3.482 0 0 0 3.655-3.839z" data-name="Trazado 17" />
//                         <path transform="translate(-368.95 -109.42)" d="m451.79 119.97c0-6.008 4.719-10.55 10.991-10.55s11.019 4.542 11.019 10.55-4.748 10.551-11.019 10.551-10.991-4.543-10.991-10.551zm17.115 0c0-5.392-2.432-8.968-6.124-8.968-3.664 0-6.1 3.575-6.1 8.968s2.432 8.968 6.1 8.968c3.692 0 6.12-3.576 6.12-8.968z" data-name="Trazado 18" />
//                         <path transform="translate(-389.63 -109.66)" d="m514.61 109.95v20.514h-1l-14.624-14.39v14.39h-1.729v-20.514h2.2l13.452 13.305v-13.305z" data-name="Trazado 19" />
//                         <path transform="translate(-407.28 -109.66)" d="M551.29,128.886v1.582H536.051V109.954H551v1.582H540.828v7.737h7.825v1.582h-7.825v8.03Z" data-name="Trazado 20" />
//                         <path transform="translate(-420.36 -109.66)" d="m577.59 130.47-4.747-7.649-5.275 7.649h-2.257l6.506-9.261-7-11.253h5.1l4.66 7.5 5.217-7.5h2.256l-6.449 9.146 7.063 11.371z" data-name="Trazado 21" />
//                         <path transform="translate(-435.69 -109.66)" d="m615.57 111.54h-6.125v18.932h-4.777v-18.932h-6.154v-1.582h17.056z" data-name="Trazado 22" />
//                     </g>
//                 </g>
//                 <g transform="translate(200.92 99.917)" data-name="Grupo 5">
//                     <path transform="translate(-216.37 -99.917)" d="m246.75 111.93-8.026 11.551h10.73l8.874-12.4-11.03-11.163h-12.418z" fill="#b49a3c" data-name="Trazado 23" />
//                     <path transform="translate(-200.92 -99.917)" d="m225.34 108.75-8.73-8.834h-4.752l-10.943 11.077 20.874 29.552 10.734-15h-10.687l-9.376-13.671 3.089-3.127z" fill="#222" data-name="Trazado 24" />
//                 </g>
//                 <g transform="translate(298.24 132.11)" data-name="Grupo 15">
//                     <g fill="#b49a3c" data-name="Grupo 14">
//                         <path transform="translate(-366.23 -162.95)" d="M369.483,165.432a2.441,2.441,0,0,1-.188.932,2.677,2.677,0,0,1-.5.8,2.375,2.375,0,0,1-.752.546,2.134,2.134,0,0,1-.914.206H366.3a.063.063,0,0,1-.071-.071v-4.821a.063.063,0,0,1,.071-.071h.829a2.146,2.146,0,0,1,1.18.347,2.52,2.52,0,0,1,.858.918A2.48,2.48,0,0,1,369.483,165.432Zm-2.893-2.12v4.24h.539a1.794,1.794,0,0,0,1-.3,2.164,2.164,0,0,0,.727-.791,2.121,2.121,0,0,0,.266-1.028,2.07,2.07,0,0,0-.16-.794,2.269,2.269,0,0,0-.425-.681,2.061,2.061,0,0,0-.635-.468,1.769,1.769,0,0,0-.772-.177Z" data-name="Trazado 37" />
//                         <path transform="translate(-381 -162.95)" d="M394.61,163.043v4.778a.09.09,0,0,1-.014.05.107.107,0,0,1-.032.032.092.092,0,0,1-.046.011h-.177a.1.1,0,0,1-.092-.093v-4.778a.089.089,0,0,1,.028-.064.09.09,0,0,1,.064-.028h.177a.1.1,0,0,1,.092.092Z" data-name="Trazado 38" />
//                         <path transform="translate(-392.52 -163.04)" d="M416.911,166.88l-.453,1.057a.089.089,0,0,1-.093.064h-.22a.067.067,0,0,1-.057-.032.068.068,0,0,1-.007-.067l1.928-4.729c.014-.038.032-.057.053-.057s.039.019.053.057L420,167.9a.069.069,0,0,1-.008.067.064.064,0,0,1-.057.032h-.22a.094.094,0,0,1-.092-.064l-.44-1.05a.2.2,0,0,0-.081-.1.216.216,0,0,0-.117-.039h-1.864a.209.209,0,0,0-.121.039A.289.289,0,0,0,416.911,166.88Zm1.1-2.779-.83,2.155a.111.111,0,0,0-.008.068.079.079,0,0,0,.033.05.108.108,0,0,0,.067.017h1.56a.1.1,0,0,0,.064-.021.091.091,0,0,0,.035-.049.1.1,0,0,0-.008-.064l-.808-2.155c-.014-.038-.032-.057-.053-.057S418.025,164.064,418.011,164.1Z" data-name="Trazado 39" />
//                         <path transform="translate(-407.54 -163)" d="M444.92,164.026v3.849a.1.1,0,0,1-.092.093h-.177a.1.1,0,0,1-.093-.093V163.1c0-.024.006-.038.018-.043s.027,0,.046.021l1.745,1.893c.038.047.078.047.12,0l1.745-1.893c.018-.019.034-.026.046-.021s.018.019.018.043v4.778a.1.1,0,0,1-.093.093h-.177a.085.085,0,0,1-.067-.028.1.1,0,0,1-.025-.064v-3.849c0-.029-.006-.045-.018-.05s-.027,0-.046.021l-1.383,1.5c-.042.047-.082.047-.12,0l-1.383-1.5c-.019-.018-.035-.026-.046-.021S444.92,164,444.92,164.026Z" data-name="Trazado 40" />
//                         <path transform="translate(-422.37 -162.95)" d="M476.211,167.581a2.245,2.245,0,0,1-2.354,0,2.408,2.408,0,0,1-.858-.9,2.61,2.61,0,0,1,0-2.489,2.4,2.4,0,0,1,.858-.9,2.245,2.245,0,0,1,2.354,0,2.407,2.407,0,0,1,.858.9,2.61,2.61,0,0,1,0,2.489A2.412,2.412,0,0,1,476.211,167.581Zm-1.181-4.247a1.871,1.871,0,0,0-1,.28,2.014,2.014,0,0,0-.724.766,2.159,2.159,0,0,0-.266,1.053,2.084,2.084,0,0,0,.581,1.481,1.909,1.909,0,0,0,2.811,0,2.169,2.169,0,0,0,0-2.963A1.872,1.872,0,0,0,475.03,163.333Z" data-name="Trazado 41" />
//                         <path transform="translate(-438.27 -162.95)" d="M503.21,164.185v3.637a.1.1,0,0,1-.092.093h-.177a.1.1,0,0,1-.093-.093v-4.778c0-.024.006-.038.018-.043s.025,0,.039.028l2.779,3.75c.015.019.027.026.039.021s.017-.018.017-.043v-3.715a.093.093,0,0,1,.011-.043.088.088,0,0,1,.032-.035.086.086,0,0,1,.05-.014h.177a.09.09,0,0,1,.064.028.089.089,0,0,1,.028.064V167.9c0,.029-.006.044-.017.046s-.025-.006-.039-.025l-2.779-3.75c-.014-.024-.027-.034-.039-.029S503.21,164.161,503.21,164.185Z" data-name="Trazado 42" />
//                         <path transform="translate(-452.57 -162.95)" d="M533.207,165.432a2.432,2.432,0,0,1-.188.932,2.665,2.665,0,0,1-.5.8,2.375,2.375,0,0,1-.751.546,2.135,2.135,0,0,1-.915.206h-.829a.063.063,0,0,1-.071-.071v-4.821a.062.062,0,0,1,.071-.071h.829a2.144,2.144,0,0,1,1.18.347,2.52,2.52,0,0,1,.858.918A2.478,2.478,0,0,1,533.207,165.432Zm-2.893-2.12v4.24h.539a1.792,1.792,0,0,0,1-.3,2.16,2.16,0,0,0,.726-.791,2.121,2.121,0,0,0,.266-1.028,2.07,2.07,0,0,0-.16-.794,2.279,2.279,0,0,0-.425-.681,2.063,2.063,0,0,0-.635-.468,1.771,1.771,0,0,0-.773-.177Z" data-name="Trazado 43" />
//                         <path transform="translate(-466.92 -162.95)" d="M557.694,167.579a1.437,1.437,0,0,1-.532-.915.071.071,0,0,1,.017-.064.072.072,0,0,1,.053-.028l.185-.021a.076.076,0,0,1,.092.07,1.14,1.14,0,0,0,1.241.936,1.129,1.129,0,0,0,.872-.56,1.09,1.09,0,0,0,.142-.773,1.15,1.15,0,0,0-.121-.344.925.925,0,0,0-.436-.425,2.739,2.739,0,0,0-.266-.128c-.066-.026-.163-.06-.291-.1a3.373,3.373,0,0,1-.382-.156,1.314,1.314,0,0,1-.646-.6,1.025,1.025,0,0,1-.028-.787,1.037,1.037,0,0,1,.538-.61,1.223,1.223,0,0,1,.851-.092,1.235,1.235,0,0,1,.213.092,1.65,1.65,0,0,1,.259.177,1,1,0,0,1,.241.305,1.1,1.1,0,0,1,.124.425.06.06,0,0,1-.018.056.077.077,0,0,1-.06.029h-.185a.074.074,0,0,1-.085-.071.711.711,0,0,0-.23-.464.816.816,0,0,0-.947-.159.685.685,0,0,0-.369.418q-.206.61.5.971a2.984,2.984,0,0,0,.34.128c.085.028.152.051.2.07l.206.086a1.59,1.59,0,0,1,.22.11c.056.035.123.079.2.131a.8.8,0,0,1,.191.173c.05.064.1.135.152.213a1.03,1.03,0,0,1,.124.269,1.594,1.594,0,0,1,.06.322,1.427,1.427,0,0,1-.327,1.092,1.34,1.34,0,0,1-.39.333,1.474,1.474,0,0,1-.61.213c-.057,0-.108.007-.156.007a1.467,1.467,0,0,1-.773-.22A1.8,1.8,0,0,1,557.694,167.579Z" data-name="Trazado 44" />
//                     </g>
//                 </g>
//             </g>
//         </g>

//     ),
// })

const LogoWithWordsIcon = (props) => {
    let logoColor1 = props.logoColor1 ? props.logoColor1 : "black";

    if (props.colorMode === "dark") {
        logoColor1 = "white";
    }

    return (
        <Icon
            viewBox="0 0 264 69"
            height={"auto"}
            width={props.w ? props.w : "100%"}
        >
            <g transform="translate(-46 -479)">
                <rect
                    transform="translate(46 479)"
                    width="257"
                    height="68"
                    fill="none"
                    data-name="Rectángulo 830"
                />
                <g transform="translate(-143.71 392.77)" data-name="Grupo 70">
                    <g transform="translate(254.83 105.1)" data-name="Grupo 4">
                        <g fill={logoColor1} data-name="Grupo 3">
                            <path
                                transform="translate(-299.82 -118.22)"
                                d="m304.72 128.77c0 5.3 2.96 8.469 7.649 8.469a6.354 6.354 0 0 0 5.773-3.077l0.938 1.114c-1.26 2.316-4.308 4.045-8.177 4.045-6.125 0-11.078-4.455-11.078-10.521"
                                data-name="Trazado 13"
                            />
                            <path
                                transform="translate(-299.82 -109.5)"
                                d="m299.82 120.12c0-6.066 4.953-10.521 11.078-10.521 3.869 0 6.916 1.729 8.177 4.045l-0.938 1.114a6.353 6.353 0 0 0-5.773-3.077c-4.689 0-7.649 3.165-7.649 8.469"
                                data-name="Trazado 14"
                            />
                            <path
                                transform="translate(-317.18 -109.66)"
                                d="m350.38 125.52h-8.206l-2.139 4.953h-2.052l9-20.514h1.612l8.968 20.514h-5.041zm-0.7-1.582-3.429-7.913-3.4 7.913z"
                                data-name="Trazado 15"
                            />
                            <path
                                transform="translate(-335.93 -109.66)"
                                d="m397.32 130.47h-5.158a4.447 4.447 0 0 1-1.289-1.7l-2.433-5.305a3.061 3.061 0 0 0-1.084-1.289h-3.37v8.294h-4.777v-20.514h8.851c4.4 0 7.766 2.52 7.766 6.1 0 2.813-2.022 4.953-5.011 5.773a4.47 4.47 0 0 1 3.048 2.578l1.817 3.839a5.6 5.6 0 0 0 1.64 2.224zm-13.334-9.876h2.96c2.4 0 3.986-1.789 3.986-4.514 0-2.754-1.583-4.542-3.986-4.542h-2.96z"
                                data-name="Trazado 16"
                            />
                            <path
                                transform="translate(-353.21 -109.66)"
                                d="m434.57 125.1c0 3.135-2.931 5.363-6.565 5.363h-10.814v-20.514h9.935c3.781 0 6.565 2.2 6.565 5.07 0 2.169-1.349 3.81-3.605 4.747 2.726 0.909 4.484 2.697 4.484 5.334zm-12.6-13.569v7.59h2.727c2.462 0 4.1-1.231 4.1-3.927a3.4 3.4 0 0 0-3.663-3.663zm7.707 13.51c0-3.81-2.784-4.337-4.1-4.337h-3.6v8.177h4.044a3.482 3.482 0 0 0 3.655-3.839z"
                                data-name="Trazado 17"
                            />
                            <path
                                transform="translate(-368.95 -109.42)"
                                d="m451.79 119.97c0-6.008 4.719-10.55 10.991-10.55s11.019 4.542 11.019 10.55-4.748 10.551-11.019 10.551-10.991-4.543-10.991-10.551zm17.115 0c0-5.392-2.432-8.968-6.124-8.968-3.664 0-6.1 3.575-6.1 8.968s2.432 8.968 6.1 8.968c3.692 0 6.12-3.576 6.12-8.968z"
                                data-name="Trazado 18"
                            />
                            <path
                                transform="translate(-389.63 -109.66)"
                                d="m514.61 109.95v20.514h-1l-14.624-14.39v14.39h-1.729v-20.514h2.2l13.452 13.305v-13.305z"
                                data-name="Trazado 19"
                            />
                            <path
                                transform="translate(-407.28 -109.66)"
                                d="M551.29,128.886v1.582H536.051V109.954H551v1.582H540.828v7.737h7.825v1.582h-7.825v8.03Z"
                                data-name="Trazado 20"
                            />
                            <path
                                transform="translate(-420.36 -109.66)"
                                d="m577.59 130.47-4.747-7.649-5.275 7.649h-2.257l6.506-9.261-7-11.253h5.1l4.66 7.5 5.217-7.5h2.256l-6.449 9.146 7.063 11.371z"
                                data-name="Trazado 21"
                            />
                            <path
                                transform="translate(-435.69 -109.66)"
                                d="m615.57 111.54h-6.125v18.932h-4.777v-18.932h-6.154v-1.582h17.056z"
                                data-name="Trazado 22"
                            />
                        </g>
                    </g>
                    <g
                        transform="translate(200.92 99.917)"
                        fill={logoColor1}
                        data-name="Grupo 5"
                    >
                        <path
                            transform="translate(-216.37 -99.917)"
                            d="m246.75 111.93-8.026 11.551h10.73l8.874-12.4-11.03-11.163h-12.418z"
                            fill="#b49a3c"
                            data-name="Trazado 23"
                        />
                        <path
                            transform="translate(-200.92 -99.917)"
                            d="m225.34 108.75-8.73-8.834h-4.752l-10.943 11.077 20.874 29.552 10.734-15h-10.687l-9.376-13.671 3.089-3.127z"
                            fill={logoColor1}
                            data-name="Trazado 24"
                        />
                    </g>
                    <g
                        transform="translate(298.24 132.11)"
                        data-name="Grupo 15"
                    >
                        <g fill="#b49a3c" data-name="Grupo 14">
                            <path
                                transform="translate(-366.23 -162.95)"
                                d="M369.483,165.432a2.441,2.441,0,0,1-.188.932,2.677,2.677,0,0,1-.5.8,2.375,2.375,0,0,1-.752.546,2.134,2.134,0,0,1-.914.206H366.3a.063.063,0,0,1-.071-.071v-4.821a.063.063,0,0,1,.071-.071h.829a2.146,2.146,0,0,1,1.18.347,2.52,2.52,0,0,1,.858.918A2.48,2.48,0,0,1,369.483,165.432Zm-2.893-2.12v4.24h.539a1.794,1.794,0,0,0,1-.3,2.164,2.164,0,0,0,.727-.791,2.121,2.121,0,0,0,.266-1.028,2.07,2.07,0,0,0-.16-.794,2.269,2.269,0,0,0-.425-.681,2.061,2.061,0,0,0-.635-.468,1.769,1.769,0,0,0-.772-.177Z"
                                data-name="Trazado 37"
                            />
                            <path
                                transform="translate(-381 -162.95)"
                                d="M394.61,163.043v4.778a.09.09,0,0,1-.014.05.107.107,0,0,1-.032.032.092.092,0,0,1-.046.011h-.177a.1.1,0,0,1-.092-.093v-4.778a.089.089,0,0,1,.028-.064.09.09,0,0,1,.064-.028h.177a.1.1,0,0,1,.092.092Z"
                                data-name="Trazado 38"
                            />
                            <path
                                transform="translate(-392.52 -163.04)"
                                d="M416.911,166.88l-.453,1.057a.089.089,0,0,1-.093.064h-.22a.067.067,0,0,1-.057-.032.068.068,0,0,1-.007-.067l1.928-4.729c.014-.038.032-.057.053-.057s.039.019.053.057L420,167.9a.069.069,0,0,1-.008.067.064.064,0,0,1-.057.032h-.22a.094.094,0,0,1-.092-.064l-.44-1.05a.2.2,0,0,0-.081-.1.216.216,0,0,0-.117-.039h-1.864a.209.209,0,0,0-.121.039A.289.289,0,0,0,416.911,166.88Zm1.1-2.779-.83,2.155a.111.111,0,0,0-.008.068.079.079,0,0,0,.033.05.108.108,0,0,0,.067.017h1.56a.1.1,0,0,0,.064-.021.091.091,0,0,0,.035-.049.1.1,0,0,0-.008-.064l-.808-2.155c-.014-.038-.032-.057-.053-.057S418.025,164.064,418.011,164.1Z"
                                data-name="Trazado 39"
                            />
                            <path
                                transform="translate(-407.54 -163)"
                                d="M444.92,164.026v3.849a.1.1,0,0,1-.092.093h-.177a.1.1,0,0,1-.093-.093V163.1c0-.024.006-.038.018-.043s.027,0,.046.021l1.745,1.893c.038.047.078.047.12,0l1.745-1.893c.018-.019.034-.026.046-.021s.018.019.018.043v4.778a.1.1,0,0,1-.093.093h-.177a.085.085,0,0,1-.067-.028.1.1,0,0,1-.025-.064v-3.849c0-.029-.006-.045-.018-.05s-.027,0-.046.021l-1.383,1.5c-.042.047-.082.047-.12,0l-1.383-1.5c-.019-.018-.035-.026-.046-.021S444.92,164,444.92,164.026Z"
                                data-name="Trazado 40"
                            />
                            <path
                                transform="translate(-422.37 -162.95)"
                                d="M476.211,167.581a2.245,2.245,0,0,1-2.354,0,2.408,2.408,0,0,1-.858-.9,2.61,2.61,0,0,1,0-2.489,2.4,2.4,0,0,1,.858-.9,2.245,2.245,0,0,1,2.354,0,2.407,2.407,0,0,1,.858.9,2.61,2.61,0,0,1,0,2.489A2.412,2.412,0,0,1,476.211,167.581Zm-1.181-4.247a1.871,1.871,0,0,0-1,.28,2.014,2.014,0,0,0-.724.766,2.159,2.159,0,0,0-.266,1.053,2.084,2.084,0,0,0,.581,1.481,1.909,1.909,0,0,0,2.811,0,2.169,2.169,0,0,0,0-2.963A1.872,1.872,0,0,0,475.03,163.333Z"
                                data-name="Trazado 41"
                            />
                            <path
                                transform="translate(-438.27 -162.95)"
                                d="M503.21,164.185v3.637a.1.1,0,0,1-.092.093h-.177a.1.1,0,0,1-.093-.093v-4.778c0-.024.006-.038.018-.043s.025,0,.039.028l2.779,3.75c.015.019.027.026.039.021s.017-.018.017-.043v-3.715a.093.093,0,0,1,.011-.043.088.088,0,0,1,.032-.035.086.086,0,0,1,.05-.014h.177a.09.09,0,0,1,.064.028.089.089,0,0,1,.028.064V167.9c0,.029-.006.044-.017.046s-.025-.006-.039-.025l-2.779-3.75c-.014-.024-.027-.034-.039-.029S503.21,164.161,503.21,164.185Z"
                                data-name="Trazado 42"
                            />
                            <path
                                transform="translate(-452.57 -162.95)"
                                d="M533.207,165.432a2.432,2.432,0,0,1-.188.932,2.665,2.665,0,0,1-.5.8,2.375,2.375,0,0,1-.751.546,2.135,2.135,0,0,1-.915.206h-.829a.063.063,0,0,1-.071-.071v-4.821a.062.062,0,0,1,.071-.071h.829a2.144,2.144,0,0,1,1.18.347,2.52,2.52,0,0,1,.858.918A2.478,2.478,0,0,1,533.207,165.432Zm-2.893-2.12v4.24h.539a1.792,1.792,0,0,0,1-.3,2.16,2.16,0,0,0,.726-.791,2.121,2.121,0,0,0,.266-1.028,2.07,2.07,0,0,0-.16-.794,2.279,2.279,0,0,0-.425-.681,2.063,2.063,0,0,0-.635-.468,1.771,1.771,0,0,0-.773-.177Z"
                                data-name="Trazado 43"
                            />
                            <path
                                transform="translate(-466.92 -162.95)"
                                d="M557.694,167.579a1.437,1.437,0,0,1-.532-.915.071.071,0,0,1,.017-.064.072.072,0,0,1,.053-.028l.185-.021a.076.076,0,0,1,.092.07,1.14,1.14,0,0,0,1.241.936,1.129,1.129,0,0,0,.872-.56,1.09,1.09,0,0,0,.142-.773,1.15,1.15,0,0,0-.121-.344.925.925,0,0,0-.436-.425,2.739,2.739,0,0,0-.266-.128c-.066-.026-.163-.06-.291-.1a3.373,3.373,0,0,1-.382-.156,1.314,1.314,0,0,1-.646-.6,1.025,1.025,0,0,1-.028-.787,1.037,1.037,0,0,1,.538-.61,1.223,1.223,0,0,1,.851-.092,1.235,1.235,0,0,1,.213.092,1.65,1.65,0,0,1,.259.177,1,1,0,0,1,.241.305,1.1,1.1,0,0,1,.124.425.06.06,0,0,1-.018.056.077.077,0,0,1-.06.029h-.185a.074.074,0,0,1-.085-.071.711.711,0,0,0-.23-.464.816.816,0,0,0-.947-.159.685.685,0,0,0-.369.418q-.206.61.5.971a2.984,2.984,0,0,0,.34.128c.085.028.152.051.2.07l.206.086a1.59,1.59,0,0,1,.22.11c.056.035.123.079.2.131a.8.8,0,0,1,.191.173c.05.064.1.135.152.213a1.03,1.03,0,0,1,.124.269,1.594,1.594,0,0,1,.06.322,1.427,1.427,0,0,1-.327,1.092,1.34,1.34,0,0,1-.39.333,1.474,1.474,0,0,1-.61.213c-.057,0-.108.007-.156.007a1.467,1.467,0,0,1-.773-.22A1.8,1.8,0,0,1,557.694,167.579Z"
                                data-name="Trazado 44"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export const LogoIconOnly = (props) => {
    let logoColor1 = props.logoColor1;

    if (props.colorMode === "dark" || props.variant == "dark") {
        logoColor1 = "white";
    }

    if (props.variant == "light") {
        logoColor1 = "black";
    }

    if (!logoColor1) {
        logoColor1 = "dark";
    }

    return (
        <Icon viewBox="0 0 495 480" height={"auto"} width={props.w || "100%"}>
            <g>
                <title>Layer 1</title>
                <g id="Grupo_5" stroke="null">
                    <path
                        id="svg_2"
                        stroke="null"
                        fill={"#9a7b1d"}
                        d="m358.64462189160156,141.79178983789063 l-94.73845,136.35068 l126.65394,0 l104.75463,-146.37396 l-130.16776,-131.76851 l-146.61886,0 l140.1165,141.79179 z"
                    />
                    <path
                        id="svg_3"
                        fill={logoColor1}
                        d="m288.30062189160157,104.28966983789063 l-103.05096,-104.28967 l-56.07926,0 l-129.1704,130.75695 l246.40089,348.84492 l126.71073,-177.04008 l-126.16413,0 l-110.67845,-161.38403 l36.45862,-36.91293 l115.57296,0.02484 z"
                    />
                </g>
            </g>
        </Icon>
    );
};

const ToolsIcon = (props) => (
    <Icon
        viewBox="0 0 42 42"
        fill={props.color ? props.color : "#FFFFFF"}
        height={"auto"}
        width={"100%"}
    >
        <g id="ico-tools" transform="translate(-112 -299)">
            <rect
                id="Rectángulo_836"
                data-name="Rectángulo 836"
                width="42"
                height="42"
                transform="translate(112 299)"
                fill="none"
            />
            <g id="repair" transform="translate(118.814 307.685)">
                <g
                    id="Grupo_91"
                    data-name="Grupo 91"
                    transform="translate(11.469 0)"
                >
                    <path
                        id="Trazado_1748"
                        data-name="Trazado 1748"
                        d="M210.352,162.334l-.008.008.019,0Zm-3.21-3.194.008-.008-.011-.011Z"
                        transform="translate(-206.424 -151.112)"
                    />
                    <path
                        id="Trazado_1749"
                        data-name="Trazado 1749"
                        d="M201.446,11.946l-4.555-.713-3.224-3.224-.714-4.554L200.171,0l1.51,1.51-4.16,4.16,1.708,1.708,4.16-4.16,1.48,1.48Z"
                        transform="translate(-192.953 0)"
                    />
                </g>
                <g
                    id="Grupo_92"
                    data-name="Grupo 92"
                    transform="translate(1.851 9.087)"
                >
                    <path
                        id="Trazado_1750"
                        data-name="Trazado 1750"
                        d="M0,0H13.116V4.529H0Z"
                        transform="translate(0 9.273) rotate(-45)"
                    />
                </g>
                <g
                    id="Grupo_93"
                    data-name="Grupo 93"
                    transform="translate(16.507 15.087)"
                >
                    <path
                        id="Trazado_23"
                        data-name="Trazado 23"
                        d="M237.693,102.761l-1.9,2.735h2.541l2.1-2.936-2.611-2.643h-2.941Z"
                        transform="translate(-230.498 -99.917)"
                    />
                    <path
                        id="Trazado_24"
                        data-name="Trazado 24"
                        d="M206.7,102.009l-2.067-2.092H203.51l-2.591,2.623,4.943,7,2.542-3.552h-2.531l-2.22-3.237.731-.74Z"
                        transform="translate(-200.919 -99.917)"
                    />
                </g>
            </g>
        </g>
    </Icon>
);

const NotificationIcon = createIcon({
    displayName: "NotficationIcon",
    viewBox: "0 0 21 22",
    defaultProps: { height: "auto", width: "100%" },
    path: (
        <g
            fill="#fff"
            id="Enmascarar_grupo_49"
            clipPath="url(#clipPath)"
            transform="translate(-.364 .386)"
        >
            <path
                id="notification"
                d="M17.074 14.533a2.837 2.837 0 0 1-.862-1.174 13.585 13.585 0 0 1-.9-5.368V7.97a5.181 5.181 0 0 0-3.05-4.7V2.034A2.035 2.035 0 0 0 10.231 0h-.168a2.035 2.035 0 0 0-2.031 2.034V3.27a5.181 5.181 0 0 0-3.05 4.72 13.586 13.586 0 0 1-.9 5.368 2.837 2.837 0 0 1-.862 1.174.52.52 0 0 0-.286.592.544.544 0 0 0 .537.418h3.922a2.755 2.755 0 0 0 5.509 0h3.921a.544.544 0 0 0 .537-.418.52.52 0 0 0-.286-.591zM9.1 2.034a.963.963 0 0 1 .961-.963h.168a.963.963 0 0 1 .961.963v.889a5.182 5.182 0 0 0-2.09 0v-.889zm1.047 15.156a1.688 1.688 0 0 1-1.684-1.647h3.369a1.688 1.688 0 0 1-1.685 1.647zm2.153-2.719H4.691a5.608 5.608 0 0 0 .28-.492A13.545 13.545 0 0 0 6.052 7.99a4.1 4.1 0 1 1 8.191 0v.02a13.518 13.518 0 0 0 1.081 5.967 5.612 5.612 0 0 0 .28.492z"
                className="cls-1"
                transform="translate(.671 1.687)"
            />
        </g>
    ),
});

const HomeIcon = (props) => (
    <Icon
        viewBox="0 0 42 42"
        fill={props.color ? props.color : "#FFFFFF"}
        height={"auto"}
        width={"100%"}
    >
        <g id="ico-home" transform="translate(-112 -195)">
            <rect
                id="Rectángulo_832"
                data-name="Rectángulo 832"
                width="42"
                height="42"
                transform="translate(112 195)"
                fill="none"
            />
            <g id="sydney-opera-house" transform="translate(121 192.243)">
                <g
                    id="Grupo_83"
                    data-name="Grupo 83"
                    transform="translate(0 12.676)"
                >
                    <path
                        id="Trazado_1740"
                        data-name="Trazado 1740"
                        d="M23.6,22.811,21.57,20.963V14.837a.706.706,0,0,0-.706-.706H15.984a.706.706,0,0,0-.706.706v.393L12.83,13a1.225,1.225,0,0,0-1.658,0L.4,22.811a1.231,1.231,0,0,0,.829,2.14h1.72v9.831a.706.706,0,0,0,.706.706h5.9a.706.706,0,0,0,.706-.706V28.813h3.469v5.969a.706.706,0,0,0,.706.706h5.9a.706.706,0,0,0,.706-.706V24.951h1.72a1.231,1.231,0,0,0,.829-2.141Zm-3.255.729a.706.706,0,0,0-.706.706v9.831h-4.49V28.107a.706.706,0,0,0-.706-.706H9.56a.706.706,0,0,0-.706.706v5.969H4.364V24.245a.706.706,0,0,0-.706-.706H1.7L12,14.153l3.508,3.2a.706.706,0,0,0,1.181-.522V15.543h3.469v5.732a.706.706,0,0,0,.23.522L22.3,23.54H20.343Z"
                        transform="translate(0 -12.676)"
                    />
                </g>
            </g>
        </g>
    </Icon>
);

const GemListingIcon = (props) => (
    <Icon
        viewBox="0 0 42 42"
        fill={props.color ? props.color : "#FFFFFF"}
        height={"auto"}
        width={"100%"}
    >
        <g id="ico-listing" transform="translate(-112 -247)">
            <rect
                id="Rectángulo_834"
                data-name="Rectángulo 834"
                width="42"
                height="42"
                transform="translate(112 247)"
                fill="none"
            />
            <g id="open-menu" transform="translate(124.24 196.905)">
                <path
                    id="Trazado_1741"
                    data-name="Trazado 1741"
                    d="M15.908,64.267H1.612a1.613,1.613,0,0,0,0,3.226h14.3a1.613,1.613,0,0,0,0-3.226Z"
                />
                <path
                    id="Trazado_1742"
                    data-name="Trazado 1742"
                    d="M15.908,208.867H1.612a1.613,1.613,0,0,0,0,3.226h14.3a1.613,1.613,0,0,0,0-3.226Z"
                    transform="translate(0 -139.652)"
                />
                <path
                    id="Trazado_1743"
                    data-name="Trazado 1743"
                    d="M15.908,353.467H1.612a1.613,1.613,0,0,0,0,3.226h14.3a1.613,1.613,0,0,0,0-3.226Z"
                    transform="translate(0 -279.304)"
                />
            </g>
        </g>
    </Icon>
);

const ArrowIcon = ({ direction, ...props }) => {
    let transform = "";
    if (direction === "down") {
        transform = "rotate(90)";
    }
    transform = "rotate(180)";

    return (
        <Icon
            viewBox="0 0 25.456 27.999"
            transform={transform}
            height={"auto"}
            width={"100%"}
            fill={props.color ? props.color : "#00000"}
        >
            <path
                d="M248.633,113.882,236.88,127.916h12.676l12.78-14.034-12.78-13.965H236.88Z"
                transform="translate(-236.88 -99.917)"
            />
        </Icon>
    );
};

const CloseIcon = ({ color = "#FFFFFF" }) => (
    <Icon width="24" viewBox="0 0 18 18" fill={color}>
        <title>Close</title>
        <path
            fill="white"
            d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
        />
    </Icon>
);

const MenuIcon = ({ color = "#FFFFFF" }) => (
    <Icon width="24px" viewBox="0 0 20 20" fill={color}>
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </Icon>
);

const ShoppingCart = ({ color = "#000000" }) => (
    <Icon
        fill={color}
        viewBox="0 0 17.656 21.333"
        height={"auto"}
        width={"100%"}
    >
        <path d="M17.653 20.632L16.1 5.192a.618.618 0 0 0-.6-.569h-2.916v-.695A3.849 3.849 0 0 0 8.828 0a3.849 3.849 0 0 0-3.757 3.928v.695H2.157a.618.618 0 0 0-.6.569L0 20.632a.655.655 0 0 0 .153.491.6.6 0 0 0 .451.21h16.445a.6.6 0 0 0 .451-.21.654.654 0 0 0 .153-.491zM6.286 3.928A2.605 2.605 0 0 1 8.828 1.27a2.605 2.605 0 0 1 2.542 2.658v.695H6.286zm-5 16.135L2.7 5.893h2.371v1.4a.608.608 0 1 0 1.214 0v-1.4h5.085v1.4a.608.608 0 1 0 1.214 0v-1.4h2.367l1.423 14.17zm0 0" />
    </Icon>
);

const LinkIcon = ({ color = "#b2ab99" }) => (
    <Icon viewBox="0 0 486.465 486.465" fill={color}>
        <g>
            <g>
                <path
                    d="M453.323,39.655l-16.564-14.656C418.729,9.021,395.521,0.22,371.405,0.22c-28.223,0-55.118,12.079-73.791,33.143
                  L250.207,86.86c-6.105,6.876-9.164,15.722-8.608,24.901c0.557,9.166,4.642,17.576,11.518,23.673l4.438,3.94
                  c6.299,5.594,14.416,8.673,22.842,8.673l2.054-0.059c9.166-0.551,17.582-4.637,23.699-11.523l47.418-53.503
                  c8.342-9.416,24.169-10.362,33.601-2.026l16.558,14.688c4.748,4.203,7.57,10.021,7.955,16.384
                  c0.386,6.358-1.722,12.465-5.937,17.208L302.042,246.198c-6.982,7.887-19.377,10.164-28.734,5.342
                  c-14.577-7.519-33.58-3.93-44.392,8.256l-0.813,0.926c-7.573,8.518-10.727,19.838-8.674,31.104
                  c2.074,11.198,9.047,20.801,19.153,26.09c13.986,7.311,29.763,11.33,45.621,11.33h0.012c28.21,0,55.117-12.238,73.8-33.308
                  l103.691-117.046C497.746,138.226,494.004,75.731,453.323,39.655z"
                />
                <path
                    d="M228.873,347.458c-13.669-12.103-36.426-10.743-48.574,2.938l-47.396,53.487c-8.342,9.412-24.159,10.387-33.58,2.043
                  l-16.576-14.705c-4.747-4.207-7.57-10.025-7.955-16.383c-0.387-6.348,1.722-12.453,5.935-17.196l103.692-116.974
                  c6.876-7.765,19.047-10.111,28.297-5.566c15.121,7.448,34.359,3.818,46.05-9.416c7.433-8.374,10.555-19.496,8.586-30.463
                  c-1.956-11.031-8.747-20.389-18.618-25.666c-14.201-7.604-30.274-11.624-46.466-11.624c-28.223,0-55.118,12.084-73.791,33.151
                  L24.772,308.038c-36.062,40.666-32.308,103.082,8.361,139.143l16.564,14.482c18.021,15.979,41.229,24.582,65.345,24.582
                  c0.011,0,0,0,0.011,0c28.223,0,55.129-11.889,73.812-32.957l47.388-53.379c6.116-6.887,9.176-15.691,8.618-24.819
                  c-0.533-9.068-4.736-17.694-11.538-23.706L228.873,347.458z"
                />
            </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </Icon>
);

const ActionIcon = ({ color }) => (
    <div className="action-icon">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
    </div>
);

const EditIcon = () => (
    <Icon viewBox="0 0 486.8 486.8">
        <g>
            <g>
                <path
                    d="M486.8,83.813c0-21.6-8.4-41.9-23.7-57.2l-2.4-2.4c-31.5-31.5-82.8-31.5-114.4,0l-228.8,229c-2.9,2.9-4.7,6.7-5.2,10.8
              l-11.2,102.4c-0.6,5.4,1.3,10.8,5.2,14.7c3.9,3.9,9.3,5.8,14.7,5.2l102.4-11.2c4.1-0.4,7.9-2.3,10.8-5.2l88.2-88.2
              c7-7,7-18.4,0-25.5c-7-7.1-18.4-7-25.5,0l-83.7,83.7l-73.9,8l8-73.9l224.5-224.3c17.5-17.5,46-17.5,63.5,0l2.4,2.4
              c17.5,17.5,17.5,46,0,63.5l-55.7,55.7c-3.4,3.4-5.3,8-5.3,12.7l0.1,257.9c0,4.6-3.8,8.4-8.4,8.4h-324c-4.6,0-8.4-3.8-8.4-8.4v-324
              c0-4.6,3.8-8.4,8.4-8.4h172.9c9.9,0,18-8.1,18-18c0-9.9-8.1-18-18-18H44.4c-24.5-0.1-44.4,19.9-44.4,44.3v324
              c0,24.5,19.9,44.4,44.4,44.4h324c24.5,0,44.4-19.9,44.4-44.4l-0.1-250.4l50.4-50.4C478.4,125.713,486.8,105.413,486.8,83.813z"
                />
            </g>
        </g>
    </Icon>
);

const CancelIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 18.019 18.019" fill={color}>
        <g data-name="close (1)">
            <path
                d="M18.019 2.986L15.032 0 9.009 6.023 2.986 0 0 2.986l6.023 6.023L0 15.032l2.986 2.986L9.009 12l6.023 6.023 2.986-2.986L12 9.009z"
                data-name="Trazado 1726"
            />
        </g>
    </Icon>
);

const SubmitIcon = () => (
    <Icon viewBox="0 0 24 24">
        <path
            clipRule="evenodd"
            d="M21.652,3.211c-0.293-0.295-0.77-0.295-1.061,0L9.41,14.34  c-0.293,0.297-0.771,0.297-1.062,0L3.449,9.351C3.304,9.203,3.114,9.13,2.923,9.129C2.73,9.128,2.534,9.201,2.387,9.351  l-2.165,1.946C0.078,11.445,0,11.63,0,11.823c0,0.194,0.078,0.397,0.223,0.544l4.94,5.184c0.292,0.296,0.771,0.776,1.062,1.07  l2.124,2.141c0.292,0.293,0.769,0.293,1.062,0l14.366-14.34c0.293-0.294,0.293-0.777,0-1.071L21.652,3.211z"
            fillRule="evenodd"
        />
    </Icon>
);

const ArrowRightIcon = ({
    width = "10px",
    // transform = "rotate(90)",
    color = "#9a7b1d",
}) => (
    <Icon
        viewBox="0 0 25.456 27.999"
        // transform={transform}
        width={width}
        fill={color}
    >
        <path
            d="M248.633,113.882,236.88,127.916h12.676l12.78-14.034-12.78-13.965H236.88Z"
            transform="translate(-236.88 -99.917)"
        />
    </Icon>
);

const iconography = {
    SHOPPING_CART: {
        component: ShoppingCart,
        variant: "variant",
    },
    FULL_LOGO: {
        component: LogoWithWordsIcon,
    },
    ICON_ONLY_LOGO: {
        component: LogoIconOnly,
    },
    MAIN_MENU_BUTTON: {
        component: LogoIconOnly,
        variant: "dark",
    },
    SEARCH_ICON: {
        component: SearchIcon,
    },
    LINK_ICON: {
        component: LinkIcon,
    },
    ARROW_RIGHT_ICON: { component: ArrowRightIcon },
    ACTION_ICON: { component: ActionIcon },
    EDIT_ICON: { component: EditIcon },
    SUBMIT_ICON: { component: SubmitIcon },
    CANCEL_ICON: { component: CancelIcon },
};

export {
    RoundShapeIcon,
    RadiantShapeIcon,
    iconography,
    GemListingIcon,
    ArrowIcon,
    NotificationIcon,
    HomeIcon,
    LogoWithWordsIcon,
    FilledStar,
    HalfFilledStar,
    PrincessShapeIcon,
    CushionShapeIcon,
    EuropeanCutShapeIcon,
    SearchIcon,
    MarquiseShapeIcon,
    HeartShapeIcon,
    SquareEmeraldShapeIcon,
    CloseIcon,
    getShapeIcon,
    getShapeCategoryIcon,
    MenuIcon,
    LinkIcon,
    SettingsIcon,
    PhoneIcon,
    EmailIcon,
    MapLocationIcon,
    ShippingTruck,
    Video360Icon,
};
