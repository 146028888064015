import React from "react";
import { Button, Spinner } from "@chakra-ui/react";

/**
 * Primary UI component for user interaction
 */
export const SpinnerButton = ({ children, loading, ...props }) => {
    return (
        <Button disabled={loading} {...props}>
            {loading ? <Spinner /> : children}
        </Button>
    );
};
