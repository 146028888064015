import gql from "graphql-tag";

const GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS = gql`
  query ListCurrentUsersRunningMutations {
    listCurrentUsersRunningMutations {
      id
      mutation
      state
    }
  }
`;

export { GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS };
