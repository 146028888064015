export const CONST_TO_LANG_MAP = {
    GEM_LISTINGS: {
        en: {
            default: "Gem Listings",
        },
    },
    PAST_SALES: {
        en: {
            default: "Past Sales",
        },
    },
    MARKET: {
        en: {
            default: "Market",
        },
    },
    SEARCH: {
        en: {
            default: "Search",
        },
    },
    EXCELLENT: {
        en: {
            default: "Excellent",
        },
    },
    RESET: {
        en: {
            default: "Reset",
        },
    },
    SHAPE: {
        en: {
            default: "Shape",
        },
    },
    SHAPES: {
        en: {
            default: "Shapes",
        },
    },
    STATES: {
        en: {
            default: "States",
        },
    },
    STATE: {
        en: {
            default: "State",
        },
    },
    ALL: {
        en: {
            default: "All",
        },
    },
    PRICE: {
        en: {
            default: "Price",
        },
    },
    CARAT: {
        en: {
            default: "Carat",
        },
    },
    ARCHIVES: {
        en: { default: "Archives" },
    },
    GEM_REQUESTS: {
        en: { default: "Gem Requests" },
    },
    VERIFICATION_REQUESTED: {
        en: {
            default: "Verification Requested",
        },
    },
    NEW: {
        en: { default: "New" },
    },
    REQUESTED_BY: {
        en: { default: "Requested by" },
    },
    REQUESTED_NEEDED_BY: {
        en: { default: "Request needed by" },
    },
    UNFULFILLED: {
        en: { default: "Unfufilled" },
    },
    FULFILLED: {
        en: { default: "Fufilled" },
    },
    TOOLS: {
        en: { default: "Tools" },
    },
    PENDING: {
        en: { default: "Pending" },
    },
    ON_MARKET: {
        en: { default: "On Market" },
    },
    OFF_MARKET: {
        en: { default: "Off Market" },
    },
    SOLD: {
        en: { default: "Sold" },
    },
    ARCHIVED: {
        en: { default: "Archived" },
    },
    RELATIVE_GEM_LISTING_SEARCH_TOOL: {
        en: { default: "Relative Gem Listing Search Tool" },
    },
    UNVERIFIED: {
        en: { default: "Unverified" },
    },
    REJECTED: {
        en: { default: "Rejected" },
    },
    VERIFIED: {
        en: { default: "Verified" },
    },
    GEM_LISTINGS_MANAGER: {
        en: {
            default: "Gem Listings Manager",
            header: "Gem Listings",
        },
    },
    EMERALD: {
        en: {
            default: "Emerald",
        },
    },
    TRILLIANT: {
        en: {
            default: "Trilliant",
        },
    },
    ROUND: {
        en: {
            default: "Round",
        },
    },
    EUROPEAN_CUT: {
        en: {
            default: "Old Euro",
        },
    },
    PRINCESS: {
        en: {
            default: "Princess",
        },
    },
    PEAR: {
        en: {
            default: "Pear",
        },
    },
    OVAL: {
        en: {
            default: "Oval",
        },
    },
    HEART: {
        en: {
            default: "Heart",
        },
    },
    MARQUISE: {
        en: {
            default: "Marquise",
        },
    },
    ASSCHER: {
        en: {
            default: "Asshcher",
        },
    },
    CUSHION: {
        en: {
            default: "Cushion",
        },
    },
    RADIANT: {
        en: {
            default: "Radiant",
        },
    },
    OLD_MINER: {
        en: {
            default: "Old Miner",
        },
    },
    GIVEN_NAME: {
        en: {
            default: "First Name",
        },
    },
    FAMILY_NAME: {
        en: {
            default: "Last Name",
        },
    },
    ADDITIONAL_NAME: {
        en: {
            default: "Middle Name",
        },
    },
    EMAIL: {
        en: {
            default: "Email",
        },
    },
    PASSWORD: {
        en: {
            default: "Password",
        },
    },
    PASSWORD_CONFIRMATION: {
        en: {
            default: "Confirm Password",
        },
    },
    SIGN_UP: {
        en: {
            default: "Sign Up",
        },
    },
    CREATE_AN_ACCOUNT: {
        en: {
            default: "Create an Account",
        },
    },
    HAVE_AN_ACCOUNT_Q: {
        en: {
            default: "Have an Account?",
        },
    },
    NEED_AN_ACCOUNT_Q: {
        en: {
            default: "Need an Account?",
        },
    },
    SIGNUP_AS_VENDOR: {
        en: {
            default: "Vendor Signup",
        },
    },
    ORGANIZATION_LEGAL_NAME: {
        en: {
            default: "Company Name",
        },
    },
    GEM_TRADER: {
        en: {
            default: "Trader",
        },
    },
    TRADER: {
        en: {
            default: "Trader",
        },
    },

    JEWELRY_MAKER: {
        en: {
            default: "Jewelry Maker",
        },
    },
    VENDOR_LOGIN: {
        en: {
            default: "Vendor Login",
        },
    },
    RESET_PASSWORD: {
        en: {
            default: "Reset Password",
        },
    },
    ADMIN_LOGIN: {
        en: {
            default: "Admin Login",
        },
    },
    PRIVATE_CLIENT: {
        en: {
            default: "Private Client",
        },
    },
    VENDOR: {
        en: {
            default: "Vendor",
        },
    },
    HOME: {
        en: {
            default: "Home",
        },
    },
    VENDOR_ORGANIZATIONS: {
        en: {
            default: "Vendor Orgs",
        },
    },
    VENDOR_ORGANIZATION_INQUIRIES: {
        en: {
            default: "Vendor Inquiries",
        },
    },
    ACCEPTED: {
        en: {
            default: "Accepted",
        },
    },
    PRICE_RANGE: {
        en: {
            default: "Price Range",
        },
    },
    CARAT_RANGE: {
        en: {
            default: "Carat Range",
        },
    },
    COLOR_RANGE: {
        en: {
            default: "Color Range",
        },
    },
    CLARITY_RANGE: {
        en: {
            default: "Clarity Range",
        },
    },
    MARKET_SIGN_UP: {
        en: {
            default: "Market Sign Up",
        },
    },
    MARKET_LOGIN: {
        en: {
            default: "Market Login",
        },
    },
    CLARITY: {
        en: {
            default: "Clarity",
        },
    },
    COLOR: {
        en: {
            default: "Color",
        },
    },
    CUT: {
        en: {
            default: "Cut",
        },
    },
    CUT_RANGE: {
        en: {
            default: "Cut Range",
        },
    },
    DEFERRED: {
        en: {
            default: "Deferred",
        },
    },
    PHONE_NUMBER: {
        en: {
            default: "Phone Number",
        },
    },
    CERTIFIED_DIAMOND_GEM_TRADER: {
        en: {
            default: "Diamond Trader",
        },
    },
    VENDOR_INQUIRY: {
        en: {
            default: "Vendor Inquiry",
        },
    },
    SETTINGS: {
        en: {
            default: "Settings",
        },
    },
    NEW_PASSWORD: {
        en: {
            default: "New Password",
        },
    },
    NEW_PASSWORD_CONFIRMATION: {
        en: {
            default: "New Password Confirmation",
        },
    },
    UPDATE_PASSWORD: {
        en: {
            default: "Update Password",
        },
    },
    VERIFYING_EMAIL: {
        en: {
            default: "Verifying Email",
        },
    },
    VERY_GOOD: {
        en: {
            default: "Very Good",
        },
    },
    FAIR: {
        en: {
            default: "Fair",
        },
    },
    GOOD: {
        en: {
            default: "Good",
        },
    },
    CREATED_BY: {
        en: {
            default: "Created by",
        },
    },
    OPEN_CERTIFICATE: {
        en: {
            default: "Open certificate",
        },
    },
    CREATED_AT: {
        en: {
            default: "Created at",
        },
    },
    ACTIONS: {
        en: {
            default: "Actions",
        },
    },
    GOOD_DEAL: {
        en: {
            default: "Good Deal",
        },
    },
    ADMINISTRATORS: {
        en: {
            default: "Administrators",
        },
    },
    BAD_DEAL: {
        en: {
            default: "Bad Deal",
        },
    },
    MID_DEAL: {
        en: {
            default: "Mid Deal",
        },
    },
    FIND_DIAMONDS: {
        en: {
            default: "Find Diamonds",
        },
    },
    BECOME_A_VENDOR: {
        en: {
            default: "Become a Vendor",
        },
    },
    SIGN_IN: {
        en: {
            default: "Sign In",
        },
    },
    DIAMOND: {
        en: {
            default: "Diamond",
        },
    },
    SPECIAL_OFFER: {
        en: {
            default: "Special Offer",
        },
    },
    DIAMOND_SEARCH_Q: {
        en: {
            default: "What type of diamond are you looking for?",
        },
    },
    FAINT_YELLOW: {
        en: {
            default: "Faint Yellow",
        },
    },
    NEAR_COLORLESS: {
        en: {
            default: "Near Colorless",
        },
    },
    COLORLESS: {
        en: {
            default: "Colorless",
        },
    },
    INCLUSIONS: {
        en: {
            default: "Inclusions",
        },
    },
    SLIGHT_INCLUSIONS: {
        en: {
            default: "Slight Inclusions",
        },
    },
    FLAWLESS: {
        en: {
            default: "Flawless",
        },
    },
    CERTIFICATIONID: {
        en: {
            default: "Certificate ID",
        },
    },
    CARATRANGE: {
        en: {
            default: "Carat Range",
        },
    },
    COLORRANGE: {
        en: {
            default: "Color Range",
        },
    },
    PRICERANGE: {
        en: {
            default: "Price Range",
        },
    },
    WELCOME_TO_CARBONEXT: {
        en: {
            default: "Welcome ${this.authUser.person.givenName} to Carbonext",
        },
    },
    NEED_PASSWORD_RESET: {
        en: {
            default:
                "Because you are logging in for the first time, please reset your password",
        },
    },
    PRODUCT_DETAILS: {
        en: {
            default: "Product Details",
        },
    },
    RESERVE_DIAMOND: {
        en: {
            default: "Reserve Diamond",
        },
    },
    FLUORESCENCE_INTENSITY: {
        en: {
            default: "Fluorescence Intensity",
        },
    },
    FLUORESCENCE_COLOR: {
        en: {
            default: "Fluorescence Color",
        },
    },
    MEDIUM: {
        en: {
            default: "Medium",
        },
    },
    BLUE: {
        en: {
            default: "Blue",
        },
    },
    SYMMETRY: {
        en: {
            default: "Symmetry",
        },
    },
    LAB: {
        en: {
            default: "Lab",
        },
    },
    BY: {
        en: {
            default: "by",
        },
    },
    CREATED_FOR: {
        en: {
            default: "Created for",
        },
    },
    SIGN_UP_TO_RESERVE: {
        en: {
            default: "Sign up to reserve",
        },
    },
    NONE: {
        en: {
            default: "None",
        },
    },
    RESERVED_BY_YOU: {
        en: {
            default: "Reserved by you",
        },
    },
    LOGIN_AS_VENDOR: {
        en: {
            default: "Login as Vendor",
        },
    },
    LOGIN_AS_MARKET_USER: {
        en: {
            default: "Login",
        },
    },
    CURRENTLY_RESERVED: {
        en: {
            default: "Currently Reserved",
        },
    },
    MY_CART: {
        en: {
            default: "My Cart",
        },
    },
    CHECKOUT: {
        en: {
            default: "Checkout",
        },
    },
    PROCEED_CHECKOUT: {
        en: {
            default: "Proceed to Checkout",
        },
    },
    SUBTOTAL: {
        en: {
            default: "Subtotal",
        },
    },
    TOTAL: {
        en: {
            default: "Total",
        },
    },
    TIME_SOLD: {
        en: {
            default: "Time Sold",
        },
    },
    ORDER_DETAILS: {
        en: {
            default: "Order details",
        },
    },
    NEED_HELP: {
        en: {
            default: "Need help?",
        },
    },

    LOGIN: {
        en: {
            default: "Log In",
        },
    },
    OLD_PASSWORD: {
        en: {
            default: "Old Password",
        },
    },
    WELCOME: {
        en: {
            default: "Welcome",
        },
    },
    CHANGE_PASSWORD: {
        en: {
            default: "Change Password",
        },
    },
    REPEAT_NEW_PASSWORD: {
        en: {
            default: "Repeat New Password",
        },
    },
    ORIGINAL_PASSWORD: {
        en: {
            default: "Original Password",
        },
    },
    FIRST_TIME_LOGIN: {
        en: {
            default:
                "We require you to reset your password upon your first login. Please reset now.",
        },
    },
    PASSWORD_REQUIREMENTS: {
        en: {
            default:
                "Your password must be at least 6 characters long and must contain letters, numbers and special characters. Cannot contain whitespace.",
        },
    },
    SET_NEW_PASSWORD: {
        en: {
            default: "Set New Password",
        },
    },
    RESET_FILTERS: {
        en: {
            default: "Reset Filters",
        },
    },
    OPEN_FILTERS: {
        en: {
            default: "Open Filters",
        },
    },
    REMOVE_FROM_CART: {
        en: {
            default: "Remove From Cart",
        },
    },
    CLOSE_FILTERS: {
        en: {
            default: "Close Filters",
        },
    },
    MY_ACCOUNT: {
        en: {
            default: "My Account",
        },
    },
    SIGN_OUT: {
        en: {
            default: "Sign out",
        },
    },
    ROSE: {
        en: {
            default: "Rose",
        },
    },
    SHOPPING_BAG: {
        en: {
            default: "Shopping Bag",
        },
    },
    MANAGE_SFTP_CREDENTIALS_DESCRIPTION: {
        en: {
            default:
                "Manage your SFTP credentials here.  Only 1 key/pair will active.  Regenerating a new key pair, will invalidate the old.  We do not save private keys so if lost, you will need to regnerate a key pair.",
        },
    },
    TEST_CONNECTION: {
        en: {
            default: "Test Connection",
        },
    },
    GENERATE_KEY_PAIR: {
        en: {
            default: "Generate Key Pair",
        },
    },
    USER_NAME: {
        en: {
            default: "Username",
        },
    },
    USER_SETTINGS: {
        en: {
            default: "User",
        },
    },
    API_SETTINGS: {
        en: {
            default: "API",
        },
    },
    SFTP_CREDENTIALS: {
        en: {
            default: "SFTP Credentials Management",
        },
    },
    INITIAL_SETUP_OF_PAYABLE_BANK_ACCOUNT: {
        en: {
            default:
                "Please enter a bank account for carbonext to send payments to.",
        },
    },
    ORGANIZATION_SETTINGS: {
        en: {
            default: "Organization",
        },
    },
    CONFIRM_PAYABLE_ACCOUNT_CHANGE: {
        en: {
            default:
                "Please confirm editing accounts payable change.  Note that this will need to restart the process bank account verfication.",
        },
    },
    CONFIRM_CHANGES: {
        en: {
            default: "Confirm Changes",
        },
    },
    ROUTING_NUMBER: {
        en: {
            default: "Routing number",
        },
    },
    ACCOUNT_NUMBER: {
        en: {
            default: "Account number",
        },
    },
    PAYABLE_BANK_ACCOUNT_MANAGEMENT: {
        en: {
            default: "Accounts Payable",
        },
    },
    DOWNLOAD_AS_CSV: {
        en: {
            default: "Download CSV",
        },
    },
    UPLOAD_A_CSV: {
        en: {
            default: "Upload a CSV",
        },
    },
    GEM_LISTING_INVENTORY_CSV_SFTP_HOST: {
        en: {
            default: "SFTP Host",
        },
    },
    SFTP_SETUP: {
        en: {
            default: "SFTP Setup",
        },
    },
    HELP: {
        en: {
            default: "Help",
        },
    },
    LOGOUT: {
        en: {
            default: "Log Out",
        },
    },
    USER_MANAGEMENT: {
        en: {
            default: "Users",
        },
    },
    GENERAL: {
        en: {
            default: "General",
        },
    },
    VENDOR_ORGANIZATION: {
        en: {
            default: "Vendor Organization",
        },
    },
    _GENERATE_KEY_PAIR_CONFIRMATION: {
        en: {
            default:
                "Please confirm you want to generate a new key/pair.  This will invalidate the previous key.  Ignore this message if this is your first time generating.",
        },
    },
    _ARCHIVE_CONFIRMATION: {
        en: {
            default:
                "Are you sure you want to archive this gem listing.  As of now, we do not have a process to unarchive a gem listing.  This maybe a future functionality for us to build.",
        },
    },
    _TAKE_OFF_MARKET_CONFIRMATION: {
        en: {
            default:
                "Are you sure you want to take this gem listing off the market?",
        },
    },
    _PUT_ON_MARKET_CONFIRMATION: {
        en: {
            default:
                "Are you sure you want to put this gem listing onto the market?  Once done, our private clients will be able to reserve this listing.",
        },
    },
};
