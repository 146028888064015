

export const getCurrentViewPortHeight = () => {
    return window.innerHeight;
}

export const getCurrentViewPortWidth = () => {
    return window.innerWidth;
}


export const getCurrentViewPortAspectRatio = () => {
    return getCurrentViewPortWidth() / getCurrentViewPortHeight()
}