import AuthListErrors from "../../AuthListErrors";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";

import { LOGO_GRAPHIC_ONLY } from "../../Icons";
import { useMutation } from "@apollo/react-hooks";
import qs from "qs";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";

const VERIFY_MARKET_USER_EMAIL_GQL_MUTATION = gql`
    mutation VerifyMarkertUserEmail($token: String!) {
        verifyMarketUserEmail(token: $token) {
            message
        }
    }
`;

const VerifyMarketUserEmail = (props) => {
    let history = useHistory();
    let queryParams = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
    });
    let token = queryParams.token;

    const [updateMarketUserPassword, { data, loading, error }] = useMutation(
        VERIFY_MARKET_USER_EMAIL_GQL_MUTATION
    );

    useEffect(() => {
        updateMarketUserPassword({ variables: { token: token } });
    }, [updateMarketUserPassword]);

    if (data) {
        let initialResult = {
            initialResult: {
                title: "User email verified.  Please login.",
            },
        };

        const queryString = qs.stringify(initialResult);
        history.push(`/market/login?${queryString}`);
    }

    return (
        <div className="auth-page">
            <div className="auth-container">
                <div className="vendor-sign-up">
                    <div className="auth-card">
                        <div className="auth-title">
                            <div className="auth-logo">
                                <LOGO_GRAPHIC_ONLY />
                            </div>{" "}
                            <h1>{getConstToLanguage("VERIFYING_EMAIL")}</h1>
                        </div>

                        <AuthListErrors graphError={error} />

                        {data && (
                            <div>
                                {data.verifyMarketUserEmail.message}
                                go to <Link to="/market/login">login</Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyMarketUserEmail;
