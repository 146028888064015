import React from "react";
import { Icon } from "@chakra-ui/react"


export const SearchIcon = ({ color = "#000000", height = 'auto', width = 'auto' }) => (
    <Icon viewBox="0 0 1000 1000" height={height} width={width} color={color}>
        <g>
            <path d="M963.1,833.6L704.8,575.3c0,0,0,0-6.1,0c30.7-61.5,49.2-123,49.2-190.6c0-202.9-166-368.9-368.9-368.9S10,181.8,10,384.7c0,202.9,166,368.9,368.9,368.9c67.6,0,135.3-18.4,190.6-55.3l0,0l258.2,258.2c36.9,36.9,92.2,36.9,129.1,0C999.9,925.8,999.9,870.5,963.1,833.6z M133,384.7c0-135.3,110.7-245.9,245.9-245.9c135.3,0,245.9,110.7,245.9,245.9c0,135.3-110.7,246-245.9,246C243.7,630.7,133,520,133,384.7z" />
        </g>
    </Icon>
);
