import React from "react";
import { Box, Grid } from "../../Primatives";

export const SubNavAndBody = ({ nav, body }) => {
    return (
        <Grid templateColumns="12rem 1fr" h="100%" w="100%">
            <Box boxShadow="0px 0px 5px var(--chakra-colors-gray-300)">
                {nav}
            </Box>
            <Box overflow={"scroll"}>{body}</Box>
        </Grid>
    );
};
