import React, { Children, useState } from "react";
import {
    Box,
    IconButton,
    VStack,
    Heading,
    Flex,
    HStack,
    Button,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    useDisclosure,
} from "../../Primatives";
import { EditIcon } from "@chakra-ui/icons";
import useToggle from "../hooks/useToggle";
import useYupValidationResolver from "../../CloudManagementOS/hooks/useYupValidationResolver";
import { getConstToLanguage } from "../ConstToLanguageMapping";
import { SmartForm } from "../SmartForm";

const EditModeForm = ({
    validationSchema,
    mutation,
    needsConfirmation,
    confirmationBody,
    cancelFunc,
    children,
}) => {
    return (
        <SmartForm
            validationSchema={validationSchema}
            mutation={mutation}
            needsConfirmation={needsConfirmation}
            confirmationBody={confirmationBody}
            cancelFunc={cancelFunc}
        >
            {children}
        </SmartForm>
    );
};

const ViewMode = ({ children }) => {
    return <Box>{children}</Box>;
};

const ViewOrEditForm = ({
    children,
    validationSchema,
    mutation,
    needsConfirmation = false,
    confirmationBody = null,
    formTitle,
    bg,
    border,
    borderColor,
    boxShadow,
}) => {
    let { isToggled: isEditModeToggled, toggle: toggleEditMode } = useToggle();

    return (
        <VStack
            w="100%"
            p={8}
            align="left"
            bg={bg}
            border={border}
            borderColor={borderColor}
            boxShadow={boxShadow}
        >
            <Flex justifyContent="space-between" w="100%">
                <Heading as="h3" size="md">
                    {formTitle}
                </Heading>
                <IconButton
                    onClick={toggleEditMode}
                    size="xs"
                    icon={<EditIcon />}
                />
            </Flex>
            <Box>
                {isEditModeToggled ? (
                    <EditModeForm
                        validationSchema={validationSchema}
                        mutation={mutation}
                        needsConfirmation={needsConfirmation}
                        confirmationBody={confirmationBody}
                        children={children}
                        cancelFunc={toggleEditMode}
                    />
                ) : (
                    <ViewMode children={children} />
                )}
            </Box>
        </VStack>
    );
};

export default ViewOrEditForm;
