import gql from "graphql-tag";

const GQL_Q_SEARCH_GEM_LISTINGS_IN_STATE = gql`
    query SearchGemListingsInState(
        $state: GemListingState!
        $searchParam: GemListingSearchParam
        $pagination: PaginationInput
    ) {
        searchGemListingsInState(
            state: $state
            searchParam: $searchParam
            pagination: $pagination
        ) {
            pageInfo {
                hasNextPage
                endCursor
                page
            }
            totalCount
            edges {
                node {
                    id
                    createdAt
                    state
                    price
                    askingPrice
                    timeSold
                    createdBy {
                        ... on Vendor {
                            localAuth {
                                email
                            }
                            person {
                                givenName
                                familyName
                            }
                        }
                        ... on SystemProcess {
                            serviceName
                        }
                    }
                    currentRelativeDiscountPercentage
                    vendorOrganization {
                        legalName
                    }
                    latestRelativePrice {
                        relativePrice
                    }
                    certifiedGem {
                        certification {
                            certificationId
                            certificationType
                        }

                        specs {
                            carat
                            color
                            cut
                            shape
                            clarity
                        }
                    }
                }
            }
        }
    }
`;

export { GQL_Q_SEARCH_GEM_LISTINGS_IN_STATE };
