import React from "react";
import { Box, VStack } from "../../Primatives";
import { Loader } from "../../Primatives/Loader";

const SearchItems = React.memo(
    ({ items, listItemFunc, onLoadMore, pageInfo, loadMoreIcon }) => {
        return (
            <VStack w="100%" p="1rem">
                {items && items.map(listItemFunc)}
                <LoadMore
                    onLoadMore={onLoadMore}
                    pageInfo={pageInfo}
                    loadMoreIcon={loadMoreIcon}
                />
            </VStack>
        );
    }
);

const LoadMore = React.memo(({ onLoadMore, pageInfo, loadMoreIcon }) => {
    console.log("SLKDJF:LKSDJ:FLKJDS:LKFJDKL:Fd", loadMoreIcon);

    if (onLoadMore && pageInfo?.hasNextPage) {
        return (
            <div className="load-more grow" onClick={onLoadMore}>
                {loadMoreIcon && loadMoreIcon({})}
            </div>
        );
    }
    return null;
});

export const SearchResults = React.memo(
    ({
        items,
        listItemFunc,
        onLoadMore,
        pageInfo,
        loadMoreIcon,
        isDataLoading,
    }) => {
        return (
            <Box overflowX={"hidden"} h="100%" w="100%">
                {isDataLoading ? (
                    <Loader />
                ) : (
                    <SearchItems
                        items={items}
                        listItemFunc={listItemFunc}
                        onLoadMore={onLoadMore}
                        pageInfo={pageInfo}
                        loadMoreIcon={loadMoreIcon}
                    />
                )}
            </Box>
        );
    }
);
