import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ConditionalLink } from "../../Primatives";
import { Link as ReactLink } from "react-router-dom";
import { getConstToLanguage } from "../../CloudManagementOS/ConstToLanguageMapping";
import { useTranslation } from "react-i18next";

const Tag = ({ tag }) => {
    let { t } = useTranslation();
    return (
        <Box
            pos={"absolute"}
            textTransform={"uppercase"}
            fontSize={10}
            p={"3px"}
            lineHeight={"0.8em"}
            className={tag.level}
        >
            {t(tag.name)}
        </Box>
    );
};

export const ListingItem = ({ children, emphasis, ...props }) => {
    const defaultColor = "black";
    const specialColor = "#9a7b1d";
    const background = "#fefefe";

    const getSpecialOfferFormat = () => {
        if (props.discount >= 30) {
            return { color: specialColor };
        }
        return { color: defaultColor };
    };

    return (
        <ConditionalLink
            as={ReactLink}
            to={props.linkPrefix ? `${props.linkPrefix}/${props.id}` : null}
            condition={props.linkPrefix}
            linkType={"LinkBox"}
        >
            <Box h={"100%"}>
                <Box
                    position={"absolute"}
                    width={"50px"}
                    paddingLeft={4}
                    paddingTop={2}
                >
                    {emphasis}
                </Box>
                <Box p={4} h={"100%"} bg={background}>
                    {props.tag && <Tag tag={props.tag} />}
                    <Box position={"relative"} w={"100%"}>
                        <Flex my={6} h={"100px"} justify={"center"}>
                            {props.itemShowCase}
                        </Flex>
                    </Box>
                    <Box fontSize={"0.8em"}>{props.title}</Box>
                    {props.discount && props.discount > 0 ? (
                        <Box textDecor={"line-through"} fontSize={"0.75em"}>
                            ${props.relativePrice}
                        </Box>
                    ) : (
                        <Box h={"1em"}> </Box>
                    )}
                    <Flex alignItems={"center"} {...getSpecialOfferFormat()}>
                        <Box mr={3} fontWeight={"bold"} fontSize={21}>
                            ${props.price}
                        </Box>
                        {props.discount && props.discount > 0 && (
                            <Box fontSize={14}>{props.discount}% OFF</Box>
                        )}
                    </Flex>
                </Box>
            </Box>
        </ConditionalLink>
    );
};

ListingItem.propTypes = {};

ListingItem.defaultProps = {};
