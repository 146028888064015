import {
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    StackProps,
    Text,
    FormErrorMessage,
    useBreakpointValue,
    Divider,
    SpinnerButton,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
} from "../../../stories/Primatives";
import * as React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useSubmitMutation from "../../../stories/CloudManagementOS/hooks/useSubmitMutation";
import * as Yup from "yup";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { GraphQLRespErrorHandler } from "../../../stories/CloudManagementOS/Apollo/GraphQLRespErrorHandler";
import "yup-phone";

const SignUpFormSchema = Yup.object().shape({
    givenName: Yup.string()
        .required("First name is a required field.")
        .min(2, "First Name must be at least 2 characters."),
    familyName: Yup.string()
        .required("Last name is a required field.")
        .min(2, "Last Name must be at least 2 characters."),
    email: Yup.string()
        .required("Email is a required field.")
        .email()
        .min(2, "Email must be at least 2 characters."),
    organizationName: Yup.string()
        .required("Company Name is a required field.")
        .min(2, "Company Name must be at least 2 characters."),
    phoneNumber: Yup.string()
        .default("+1")
        .required("Phone Number is a required field.")
        .phone(),
});

export const SignUpForm = ({ signUpFunc, ...props }) => {
    let {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: SignUpFormSchema.cast(),
        resolver: yupResolver(SignUpFormSchema),
    });

    let { onSubmit, loading, error, data } = useSubmitMutation(signUpFunc);

    if (data) {
        return (
            <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
            >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                    Application submitted!
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                    Thanks for submitting your application. Our team will get
                    back to you soon.
                </AlertDescription>
            </Alert>
        );
    }
    return (
        <Stack spacing="8" {...props}>
            <Stack spacing="6">
                <Stack spacing="6">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        id="vendor-organization-inquiry-form"
                    >
                        <GraphQLRespErrorHandler error={error} />
                        <Stack spacing={"8"}>
                            <Stack spacing="6">
                                <FormControl isInvalid={errors?.givenName}>
                                    <FormLabel>
                                        {getConstToLanguage("GIVEN_NAME")}
                                    </FormLabel>
                                    <Input
                                        id="given-name"
                                        {...register("givenName")}
                                    />
                                    <FormErrorMessage>
                                        {errors.givenName &&
                                            errors.givenName.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors?.familyName}>
                                    <FormLabel>
                                        {getConstToLanguage("FAMILY_NAME")}
                                    </FormLabel>
                                    <Input
                                        id="family-name"
                                        {...register("familyName")}
                                    />
                                    <FormErrorMessage>
                                        {errors.familyName &&
                                            errors.familyName.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isInvalid={errors?.organizationName}
                                >
                                    <FormLabel>
                                        {getConstToLanguage(
                                            "ORGANIZATION_LEGAL_NAME"
                                        )}
                                    </FormLabel>
                                    <Input
                                        id="organization-name"
                                        {...register("organizationName")}
                                    />
                                    <FormErrorMessage>
                                        {errors.organizationName &&
                                            errors.organizationName.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors?.email}>
                                    <FormLabel htmlFor="email">
                                        {getConstToLanguage("EMAIL")}
                                    </FormLabel>
                                    <Input
                                        id="email"
                                        type="email"
                                        {...register("email")}
                                    />
                                    <FormErrorMessage>
                                        {errors.email && errors.email.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors?.phoneNumber}>
                                    <FormLabel>
                                        {getConstToLanguage("PHONE_NUMBER")}
                                    </FormLabel>
                                    <Input {...register("phoneNumber")} />
                                    <FormErrorMessage>
                                        {errors.phoneNumber &&
                                            errors.phoneNumber.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="email">
                                        Inquiring to become a (select that all
                                        apply):
                                    </FormLabel>
                                    <Stack spacing={5} direction="row">
                                        <Checkbox {...register("trader")}>
                                            {getConstToLanguage("TRADER")}
                                        </Checkbox>
                                        <Checkbox {...register("jewelryMaker")}>
                                            {getConstToLanguage(
                                                "JEWELRY_MAKER"
                                            )}
                                        </Checkbox>
                                    </Stack>
                                </FormControl>
                            </Stack>
                            <Divider />

                            <SpinnerButton
                                type="submit"
                                w="100%"
                                loading={loading}
                                variant={"cloud-management-os.primary"}
                            >
                                {getConstToLanguage("SIGN_UP")}
                            </SpinnerButton>
                        </Stack>
                    </form>
                </Stack>

                <Stack spacing="4"></Stack>
            </Stack>
        </Stack>
    );
};
