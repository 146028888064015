import { useMutation } from "@apollo/react-hooks";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { LOGIN_AS_ADMIN } from "../../../graph/mutations/user/admin";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { AuthLogin } from "../../UtilityComponents/AuthLogin";

function AdminLogin() {
    const history = useHistory();

    let postAuthenticatedCallBack = (data) => {
        if (data) {
            localStorage.setItem("adminToken", data);
            history.push("/admin/home");
        }
    };

    const [loginAsAdminMutation] = useMutation(LOGIN_AS_ADMIN);

    const callLoginAsAdminMutation = (variables) =>
        loginAsAdminMutation({ variables });

    return (
        <AuthLogin
            heading={getConstToLanguage("ADMIN_LOGIN")}
            postAuthenticatedCallBack={postAuthenticatedCallBack}
            loginFunc={callLoginAsAdminMutation}
        />
    );
}

export default AdminLogin;
