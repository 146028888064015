import React from "react";
import { getConstToLanguage } from "./ConstToLanguageMapping";
import { convertConstToUrlConst, urlConstToConst } from "./ConstToUrl";
import { Link } from "react-router-dom";
import { ConditionalLink } from "../Primatives";

import { Flex, Popover, PopoverBody, Box, PopoverContent, PopoverTrigger, PopoverArrow } from "../Primatives"

var _ = require("lodash");

class TreeHeader extends React.Component {

  renderPrefix(scope) {
    return (
      <div className="model-manager-header-selector__section">{scope}</div>
    );
  }

  renderTree(tree, currentlySelected, urlPrefix) {
    if (tree) {
      let keys = Object.keys(tree);
      let result;
      if (
        keys.includes(urlConstToConst(currentlySelected[0])) ||
        !currentlySelected[0]
      ) {
        // let everythingBesidesCurrentlySelected = tree;
        let isState = "children" in tree[currentlySelected[0]] ? false : true;
        result = [
          this.renderDropdown(currentlySelected[0], tree, urlPrefix, isState)
          // [currentlySelected[0]].concat(everythingBesidesCurrentlySelected)
        ];
        if (currentlySelected[0] && "children" in tree[currentlySelected[0]]) {
          console.log(currentlySelected[0]);
          result = result.concat(
            this.renderTree(
              tree[currentlySelected[0]].children,
              currentlySelected.slice(1),
              urlPrefix + `/${convertConstToUrlConst(currentlySelected[0])}`
            )
          );
        }
      }
      return result;
    }
  }

  renderDropdown(selectedValue, tree, urlPrefix, isState) {
    let restOfValues = _.omit(tree, [selectedValue]);

    return (
      <Popover trigger="hover" placement="bottom-start">
        <ConditionalLink
          to={`${urlPrefix}/${convertConstToUrlConst(selectedValue)}`}
          condition={!!selectedValue}
        >
          <PopoverTrigger >
            <Flex h="100%">
              <Box >
                {selectedValue
                  ? getConstToLanguage(selectedValue)
                  : getConstToLanguage("HOME")}
              </Box>
              {Object.keys(tree).length > 1 && (
                <Box>
                  {tree[selectedValue].count ? tree[selectedValue].count : 0}
                </Box>
              )}
              {restOfValues && Object.keys(restOfValues).length > 0 && (
                <Box><this.props.dropDownIcon /></Box>
              )}
            </Flex>
          </PopoverTrigger>
        </ConditionalLink>
        {
          restOfValues && Object.keys(restOfValues).length > 0 && (
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                {Object.keys(restOfValues).map(relationship => (
                  <Link to={`${urlPrefix}/${convertConstToUrlConst(relationship)}`}>
                    <Box>
                      {getConstToLanguage(relationship)}{" "}
                      <div className="model-header-dropdown-count">
                        {restOfValues[relationship].count
                          ? restOfValues[relationship].count
                          : 0}
                      </div>
                    </Box>
                  </Link>
                ))}
              </PopoverBody>
            </PopoverContent>
          )
        }
      </Popover >
    );
  }

  render() {
    let prefix = this.props.prefix;
    let urlPrefix = this.props.urlPrefix;
    let tree = this.props.tree;
    let currentSelection = this.props.currentSelection;

    let generatedRender = [];

    if (prefix) {
      generatedRender.push(this.renderPrefix(prefix));
    }

    generatedRender = generatedRender.concat(
      this.renderTree(tree, currentSelection, urlPrefix)
    );

    return (
      <div className="state-model-header">
        {generatedRender.reduce(
          (acc, x) => (acc === null ? [x] : [acc, " : ", x]),
          null
        )}
      </div>
    );
  }
}
export default TreeHeader;
