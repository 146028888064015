import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as React from "react";
import { IoArrowDown } from "react-icons/io5";

export const InnerTable = ({ listItemFunc, items, ...props }) => (
  <Table {...props}>
    <Thead>
      <Tr>
        <Th>
          <HStack spacing="3">
            {/* <Checkbox /> */}
            <HStack spacing="1">
              <Text>Name</Text>
              {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
            </HStack>
          </HStack>
        </Th>
        <Th>Status</Th>
        <Th>Email</Th>
        <Th>Role</Th>
        {/* <Th>Rating</Th> */}
        <Th></Th>
      </Tr>
    </Thead>
    <Tbody>{items && items.map((item) => listItemFunc(item))}</Tbody>
  </Table>
);
