import { ApolloProvider } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { ChakraProvider } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";
// import Header from './Header';
import React, { useContext, useState, useMemo, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { theme } from "../chakra";
import AdminLogin from "../components/Admin/AdminLogin";
import Market from "../components/Market";
import VendorHome from "../components/Vendor/VendorHome";
import VendorOrganizationInquiry from "../components/Vendor/VendorOrganizationInquiry";
import VendorResetPassword from "../components/Vendor/VendorResetPassword";
import VendorUpdatePassword from "../components/Vendor/VendorUpdatePassword";
import { CONST_TO_LANG_MAP } from "../constants/businessConstantsToLanguageMapping";
import "../css/Animations.css";
import "../css/App.css";
import "../css/CarbonextApp.css";
import "../css/rc-slider.css";
// TODO - Jeff shouldn't live here
import { marketUserGraphClient } from "../graph";
import {
    setConstToLanguageMap,
    setCurrentLanguage,
} from "../stories/CloudManagementOS/ConstToLanguageMapping";
import { useLocalStorage } from "../stories/hooks";
import { Loader } from "../stories/Primatives/Loader";
import "../styles";
import Admin from "./Admin/AdminHome";
import AdminUpdatePassword from "./Admin/AdminUpdatePassword";
import { isMarketUserLoggedIn } from "./helpers/currentUser";
import { AboutUs } from "./Market/AboutUs";
import { CURRENT_MARKET_USER_GRAPH_QL_QUERY } from "./Market/graph/queries/currentMarketUser";
import LandingPage from "./Market/LandingPage";
// import socketIOClient from 'socket.io-client'
import MarketUserResetPassword from "./Market/MarketUserResetPassword";
import MarketUserUpdatePassword from "./Market/MarketUserUpdatePassword";
import MarketUserVerifyEmail from "./Market/MarketUserVerifyEmail";
import { WhyChooseUs } from "./Market/WhyChooseUs";
import { CurrentMarketUserContext } from "./UtilityComponents/MarketOS/context";

import { ContactUsWrapper } from "../components/Market/ContactUsWraper";
import VendorLogin from "./Vendor/VendorLogin";
import PubSub from "pubsub-js";

import { useUserEvents } from "../stories/CloudManagementOS/hooks";

import * as Ably from "ably";

import { useCurrentUser } from "../stories/CloudManagementOS/hooks";

const MarketUserProvider = ({ children }) => {
    // User is the name of the "data" that gets stored in context
    const [currentMarketUser, setCurrentMarketUser] = useState();
    const [
        marketUserToken,
        setMarketUserToken,
        removeMarketUserToken,
    ] = useLocalStorage("marketUserToken");

    // Login updates the user data with a name parameter
    const login = (marketUserToken) => {
        setMarketUserToken(marketUserToken);
    };

    // Logout updates the user data to default
    const logout = () => {
        setCurrentMarketUser(null);
        removeMarketUserToken();
    };

    return (
        <CurrentMarketUserContext.Provider
            value={{ currentMarketUser, login, logout, setCurrentMarketUser }}
        >
            {children}
        </CurrentMarketUserContext.Provider>
    );
};

const InnerApp = (props) => {
    let currentMarketUser = null;
    const { setCurrentMarketUser } = useContext(CurrentMarketUserContext);

    const { loading, error, data, refetch } = useQuery(
        CURRENT_MARKET_USER_GRAPH_QL_QUERY,
        {
            skip: !isMarketUserLoggedIn(),
        }
    );

    if (data) {
        currentMarketUser = data.currentMarketUser;
        setCurrentMarketUser(currentMarketUser);
    }

    setConstToLanguageMap(CONST_TO_LANG_MAP);
    setCurrentLanguage("en");

    const auth = useCurrentUser("market-user", currentMarketUser);

    const ablyClient = useMemo(() => {
        return (
            currentMarketUser &&
            new Ably.Realtime({
                token: auth.jwtToken,
                authCallback: async (tokenParams, callback) => {
                    try {
                        const tokenRequest = await auth.refreshToken();
                        callback(null, tokenRequest);
                    } catch (error) {
                        callback(error, null);
                    }
                },
            })
        );
    }, [currentMarketUser]);

    let currentMarketUserEventsChannel = useMemo(() => {
        if (currentMarketUser) {
            return ablyClient?.channels.get(
                `market-user:${currentMarketUser.marketUser.id}:events`
            );
        }
    }, [currentMarketUser]);

    useEffect(() => {
        if (currentMarketUserEventsChannel) {
            console.log("Subscribing to Carbonext Events Market User Channel");
            currentMarketUserEventsChannel.subscribe((data) => {
                console.log("Incoming User Event");
                let incomingEvent = incomingDataToIncomingEvent(data.data);

                PubSub.publish("user:events", incomingEvent);
            });

            return () => {
                currentMarketUserEventsChannel.unsubscribe();
            };
        }
    }, [auth.jwtToken, currentMarketUserEventsChannel]);

    let { eventToHandle } = useUserEvents();

    useEffect(() => {
        if (eventToHandle && eventToHandle.type === "sales-order.completed") {
            refetch();
        }
    }, [eventToHandle]);

    const incomingDataToIncomingEvent = (data) => {
        let incomingEvent = JSON.parse(JSON.stringify(data));

        if (
            incomingEvent.data &&
            incomingEvent.datacontenttype == "application/json"
        ) {
            let messageData = JSON.parse(incomingEvent.data);
            incomingEvent.data = messageData;
        }

        return incomingEvent;
    };

    return (
        <ChakraProvider theme={theme}>
            {loading ? (
                <Loader />
            ) : (
                <Router>
                    <Switch>
                        <Route
                            path="/market"
                            render={(props) => <Market {...props} />}
                        />
                        <Route
                            exact
                            path="/"
                            render={(props) => <LandingPage {...props} />}
                        />
                        <Route
                            exact
                            path="/about-us"
                            render={(props) => <AboutUs {...props} />}
                        />
                        <Route
                            exact
                            path="/why-choose-us"
                            render={(props) => <WhyChooseUs {...props} />}
                        />
                        <Route
                            exact
                            path="/contact-us"
                            render={(props) => <ContactUsWrapper {...props} />}
                        />
                        <Route
                            exact
                            path="/vendor/login"
                            component={VendorLogin}
                        />
                        <Route
                            exact
                            path="/vendor/sign-up"
                            component={VendorOrganizationInquiry}
                        />
                        <Route
                            exact
                            path="/vendor/reset-password"
                            component={VendorResetPassword}
                        />
                        <Route
                            exact
                            path="/market-user/reset-password"
                            component={MarketUserResetPassword}
                        />
                        <Route
                            exact
                            path="/market-user/verify-email"
                            component={MarketUserVerifyEmail}
                        />
                        <Route
                            exact
                            path="/admin/update-password"
                            component={AdminUpdatePassword}
                        />
                        <Route
                            exact
                            path="/market-user/update-password"
                            component={MarketUserUpdatePassword}
                        />
                        <Route
                            exact
                            path="/vendor/update-password"
                            component={VendorUpdatePassword}
                        />
                        <Route
                            exact
                            path="/vendor/home"
                            component={VendorHome}
                        />
                        <Route
                            exact
                            path="/vendor/:tool"
                            component={VendorHome}
                        />
                        <Route
                            exact
                            path="/vendor/:tool/:subtool"
                            component={VendorHome}
                        />
                        <Route
                            exact
                            path="/vendor/:tool/:subtool/:param1"
                            component={VendorHome}
                        />
                        ,
                        <Route
                            path="/vendor/:tool/:subtool/:param1/:param2"
                            component={VendorHome}
                        />
                        {/* ADMIN ROUTES*/}
                        <Route
                            exact
                            path="/admin/login"
                            component={AdminLogin}
                        />
                        <Route exact path="/admin/:tool" component={Admin} />
                        <Route
                            exact
                            path="/admin/:tool/:subtool"
                            component={Admin}
                        />
                        <Route
                            exact
                            path="/admin/:tool/:subtool/:param1"
                            component={Admin}
                        />
                        <Route
                            exact
                            path="/admin/:tool/:subtool/:param1/:param2"
                            component={Admin}
                        />
                    </Switch>
                </Router>
            )}
        </ChakraProvider>
    );
};

const App = (props) => {
    return (
        <ApolloProvider client={marketUserGraphClient}>
            <MarketUserProvider>
                <InnerApp {...props} />
            </MarketUserProvider>
        </ApolloProvider>
    );
};

export default App;
