import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, AlertIcon, AlertDescription } from "@chakra-ui/react"


/**
 * Primary UI component for user interaction
 */
export const SuccessResponse = ({ error, title }) => {

    return (

        <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
        >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
                Gem Inventory CSV submitted!
        </AlertTitle>
            <AlertDescription maxWidth="sm">
                Thanks for submitting your csv. You will receive a notification once the csv has been processed
        </AlertDescription>
        </Alert>

    );
};

SuccessResponse.propTypes = {
    message: PropTypes.string,
    title: PropTypes.string
};

SuccessResponse.defaultProps = {
    title: "this is an error",
    details: "this is details about the error"
};
