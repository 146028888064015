import React from "react";
import {
    Grid,
    GridItem,
    Flex,
    useBreakpointValue,
    Stack,
    useBreakpoint,
} from "../../Primatives";

export const MultiSectionFlexDirection = ({
    children,
    breakpoint,
    padding,
}) => {
    const direction = useBreakpointValue({
        base: "column",
        [breakpoint]: "row",
    });

    const spacing = useBreakpointValue({
        base: "50px",
        lg: "150px",
        xl: "220px",
    });
    const breakpointValue = useBreakpoint();
    console.log(spacing, breakpointValue);

    return (
        <Stack
            padding={padding}
            direction={direction}
            spacing={spacing}
            paddingTop={{
                base: 12,
                [breakpoint]: 20,
            }}
            paddingBottom={{
                base: 12,
                [breakpoint]: 20,
            }}
        >
            {children}
        </Stack>
    );
};
