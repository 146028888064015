import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "../../../stories/hooks";
import { CurrentUserContext } from "../Context";

export const useAuth = () => {
    return useContext(CurrentUserContext);
};

const CREATE_GEM_REQUEST_GQL_MUTATION = gql`
    mutation RenewRealTimeToken {
        renewRealTimeToken
    }
`;

export function useCurrentUser(userType, initialUser) {
    const [currentUser, setCurrentUser] = useState();
    const history = useHistory();
    const [refreshTokenMutation] = useMutation(CREATE_GEM_REQUEST_GQL_MUTATION);
    const [vendorToken, setVendorToken, removeVendorToken] = useLocalStorage(
        "vendorToken"
    );
    let tokenToUse;

    switch (userType) {
        case "vendor":
            tokenToUse = "vendorToken";
            break;
        case "admin":
            tokenToUse = "adminToken";
            break;
        case "market-user":
            tokenToUse = "marketUserToken";
            break;
    }

    let jwtToken = localStorage.getItem("tokenToUse");

    useEffect(() => {
        setCurrentUser(initialUser);
    });

    const refreshToken = async () => {
        const { data } = await refreshTokenMutation();
        localStorage.setItem(tokenToUse, data.renewRealTimeToken);
        return data.renewRealTimeToken;
    };

    const logout = () => {
        localStorage.removeItem(tokenToUse);

        if (userType === "vendor") {
            localStorage.removeItem("vendorTokensForOtherVendorOrganizations");
        }
        history.push(`/${userType}/login`);
    };

    const switchAccount = async (accountIndex) => {
        if (currentUser?.otherVendorOrganizationsAuth) {
            await setVendorToken(
                currentUser.otherVendorOrganizationsAuth[accountIndex].userAuth
                    .token
            );
            history.push(`/${userType}/home`);
            window.location.reload();
        }
    };

    const parseToken = (userType) => {
        switch (userType) {
            case "vendor":
                return jwt_decode(localStorage.getItem("vendorToken"));
            case "admin":
                return jwt_decode(localStorage.getItem("adminToken"));
            case "market-user":
                return jwt_decode(localStorage.getItem("marketUserToken"));
            default:
        }
    };

    // TODO - should have fucntion to get the whole org but lazy now.
    const getCurrentVendorOrganizationId = () => {
        if (userType === "vendor") {
            let decodedToken = parseToken("vendor");
            return decodedToken.vendorOrganization;
        }
    };

    return {
        currentUser,
        logout,
        setCurrentUser,
        switchAccount,
        parseToken,
        jwtToken,
        refreshToken,
        getCurrentVendorOrganizationId,
    };
}
