import React from "react";
import { Link } from "react-router-dom";
import { Box, HStack } from "../../../../../stories/Primatives";

const VendorOrganizationListItem = ({ item }) => {
    return (
        <Link to={`/admin/vendor-organization/${item.data.id}`}>
            <HStack>
                <Box>{item.data?.legalName}</Box>
                <Box>
                    {item.data?.vendorTypes
                        .map((vendorType) => vendorType.vendorRole)
                        .toString()}
                </Box>
            </HStack>
        </Link>
    );
};

export default VendorOrganizationListItem;
