import PubSub from 'pubsub-js';
import { useEffect, useState } from 'react';

export function useVOrgEvents() {


	const [eventToHandle, setEventToHandle] = useState();

	const asdf = (topic, incomingEvent) => {
		console.log("HHS:LDKJF:LSDKJF:LKSDJF:LKSDJF:LKSDJF:KSD")
		setEventToHandle(incomingEvent)
	}


	useEffect(() => {
		const token = PubSub.subscribe("vorg:events", asdf);
		return () => PubSub.unsubscribe(token);
	}, [eventToHandle])


	return { eventToHandle }
}