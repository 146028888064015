import gql from "graphql-tag";

const GQL_Q_GET_BILL_DOT_COM_VENDOR_BANK_ACCOUNT = gql`
    query getBillDotComVendorBankAccount($vendorBankAccountId: ID!) {
        getBillDotComVendorBankAccount(
            vendorBankAccountId: $vendorBankAccountId
        )
    }
`;

export { GQL_Q_GET_BILL_DOT_COM_VENDOR_BANK_ACCOUNT };
