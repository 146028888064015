import {
    Box,
    Center,
    Heading,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { GetStarted } from "../Market/GetStarted";
import { FixedText } from "../Primatives";
import { DividerImage } from "../Primatives/DividerImage";
import { Footer } from "./Footer";
import { TeamMember } from "./TeamMember";

/**
 * Primary UI component for user interaction
 */
export const AboutUs = ({ currentMarketUser, m, ...props }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box w="100%" m={m}>
            <Box
                backgroundSize="cover"
                backgroundImage="linear-gradient(180deg, rgba(0,0,0,.6), rgba(0,0,0,.6)), url('https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/woman-placing-diamonds.png')"
            >
                {/* <Box w="100%" h="100%" bg="black" pos="absolute" opacity="60%" zIndex="-1">

</Box> */}
                <Box pos="relative" color="white" p={[8, 16, 32, 32]}>
                    <VStack spacing={8}>
                        <Heading
                            fontSize={{ base: "2rem", md: "3rem", lg: "4rem" }}
                            color="white"
                        >
                            About Us
                        </Heading>
                        <Text align="center" color="white">
                            We revolutionized the online jewelry industry with a
                            disruptive hybrid model, providing the customer with
                            online discounted pricing on the diamond while being
                            able to provide the personalized and intimate
                            experience of going into a jewelry store to design
                            your ring.
                        </Text>
                    </VStack>
                </Box>
            </Box>

            <Box p={[8, 16, 32, 32]}>
                <Center>
                    <VStack
                        spacing={[6, 12]}
                        mx={{ base: "0", md: "10%", lg: "" }}
                    >
                        <Heading
                            fontSize={{
                                base: "1.5rem",
                                md: "3rem",
                                lg: "3rem",
                            }}
                            color="brand.300"
                            align="center"
                        >
                            "Carbonext was founded with the idea that the
                            explosive online diamond industry was ready for a
                            big change. A back to basics if you will."
                        </Heading>

                        <Box
                            sx={{
                                columnCount: [1, 2],
                                columnGap: ["0", "3rem"],
                            }}
                        >
                            <Text as={"p"}>
                                {" "}
                                Sure, all these online retailers were innovative
                                in the way you purchase a diamond, but we wanted
                                to bring that personalized and luxurious
                                experience back to the customer.
                            </Text>
                            <br />
                            <Text as={"p"}>
                                {" "}
                                We revolutionized the online jewelry industry
                                with a disruptive hybrid model, providing the
                                customer with online discounted pricing on their
                                diamond while being able to provide you with the
                                personalized and intimate experience of going
                                into a jewelry store to design their ring.
                            </Text>
                            <br />
                            <Text as={"p"}>
                                Our objective is to maximize the client’s
                                savings and be completely transparent about our
                                diamond inventory, which you will see in each
                                listing description.
                            </Text>
                            <br />
                            <Text as={"p"}>
                                {" "}
                                What makes Carbonext NOT the next online diamond
                                platform, is that we can guarantee that our
                                pricing on any given diamond found on our
                                platform, will be at least 10% less than any
                                other well known online diamond retailers on the
                                market.{" "}
                            </Text>
                            <br />
                            <Text as={"p"}>
                                We understand that today, more than ever, the
                                price of a diamond is what drives this industry
                                and Carbonext guarantees you will get the best
                                price for the diamond you select.
                            </Text>
                        </Box>
                    </VStack>
                </Center>
            </Box>
            <Box bg="brand.600" p={[8, 16, 16, 32]}>
                <VStack spacing="1rem">
                    <Center>
                        <Heading>Meet the team!</Heading>
                    </Center>

                    <Center>
                        <DividerImage />
                    </Center>
                    <Box mb={2} mt={2}>
                        <Center>
                            <Text fontSize={16} textAlign={"center"}>
                                The Carbonext team is made up of a savvy, smart
                                and diversified group from all walks of life.
                                They have come together, each bringing something
                                special and unique to the company creating the
                                future of diamond buying.
                            </Text>
                        </Center>
                    </Box>

                    <Center>
                        <Wrap justify="space-around">
                            <WrapItem w={["90%", "40%", "35%", "30%"]} py={10}>
                                <TeamMember
                                    personFullName="Jeff Su"
                                    title="CEO/CTO/Founder"
                                    personImage="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/jeff.jpg"
                                >
                                    <FixedText noOfLines={5} color="black">
                                        <p>
                                            Jeff graduated from the University
                                            of California, with a B.S. degree in
                                            Electrical Engineering. Jeff, then
                                            went on to work in the integrated
                                            circuit technology space for 10
                                            years before making the move into
                                            cloud computing.
                                        </p>
                                        <br />
                                        <p>
                                            Jeff developed cloud products in
                                            IOT, advertising and banking
                                            companies for Ayla Networks, Ampush
                                            and Smartbiz. Jeff’s most recent
                                            experience in the cloud space is
                                            building the infrastructure to
                                            support the massive influx of loans
                                            during the COVID-19 pandemic.
                                        </p>
                                        <br />
                                        <p>
                                            Jeff was excited to join Carbonext
                                            because he wanted to make the world
                                            more beautiful and it gave him the
                                            opportunity to use his engineering
                                            background in a very unique way.
                                            What made Jeff intrigued in the
                                            company was that most technology
                                            that is created ends up taking jobs
                                            away from people, creating a larger
                                            gap in the unemployment rate, but
                                            the concept of Carbonext will be
                                            giving jobs back and putting
                                            customers back into the mom and pop
                                            jewelry stores.
                                        </p>
                                    </FixedText>
                                </TeamMember>
                            </WrapItem>
                            <WrapItem w={["90%", "40%", "40%", "30%"]} py={10}>
                                <TeamMember
                                    personFullName="Jaime Feld"
                                    title="Chief Operations Officer"
                                    personImage="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/jaime.jpg"
                                    w="200px"
                                >
                                    <FixedText noOfLines={5} color="black">
                                        <p>
                                            After spending 10 years in corporate
                                            America, mitigating financial crime
                                            risk for a major global bank, Jaime
                                            decided to change careers and enter
                                            into the world of diamonds. Jaime
                                            undertook an apprenticeship in
                                            diamond cutting and in just a few
                                            years has become a premiere diamond
                                            cutter. One of only a handful of
                                            female diamond cutters in the
                                            country. After being trained and
                                            studying under significant leaders
                                            in the industry, Jaime brings her
                                            proficient diamond knowledge to
                                            Carbonext. Using her diamond cutting
                                            expertise, she has developed a keen
                                            eye in diamond selection and is able
                                            to see value where others in the
                                            industry do not.
                                        </p>
                                        <br />
                                        <p>
                                            Jaime is the face behind all the
                                            content and operations of Carbonext.
                                            Using her financial crime risk
                                            experience, Jaime created the
                                            process in which our vendors are
                                            vetted and authenticated. Jaime
                                            spearheaded the anti-money
                                            laundering program at Carbonext that
                                            verifies the safety of our vendors
                                            for our customers. Bringing her
                                            expertise in both financial crime
                                            risk and AML policies along with her
                                            knowledge of diamonds, Jaime brings
                                            an extremely unique set of skills to
                                            Carbonext.
                                        </p>
                                    </FixedText>
                                </TeamMember>
                            </WrapItem>
                            <WrapItem w={["90%", "40%", "35%", "30%"]} py={10}>
                                <TeamMember
                                    personFullName="Daniel Han"
                                    title="Chief Strategy Officer/Founder"
                                    personImage="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/dan.jpg"
                                >
                                    <FixedText noOfLines={5} color="black">
                                        <p>
                                            Looking for an engagement ring for
                                            his wife as a grad student on a
                                            budget, Daniel still wanted to get
                                            her a diamond that was still high
                                            quality. Learning how working with a
                                            diamond trader was able to provide
                                            great value by bypassing the retail
                                            channels, Daniel got interested in
                                            getting more customers access.
                                        </p>
                                        <br />
                                        <p>
                                            Prior to Carbonext, he worked in
                                            product management and logistics at
                                            Amazon. He holds a Michigan MBA,
                                            UCSD BS.
                                        </p>
                                    </FixedText>
                                </TeamMember>
                            </WrapItem>

                            <WrapItem w={["90%", "40%", "40%", "30%"]} py={10}>
                                <TeamMember
                                    personFullName="Gloria Jeong"
                                    title="Head of Design"
                                    personImage="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/gloria.jpg"
                                >
                                    <FixedText noOfLines={5} color="black">
                                        <p>
                                            With years of experience in the
                                            cosmetics industry, Gloria was
                                            excited to enter a new world of
                                            beauty—diamonds. Intrigued by the
                                            transparency and the customization
                                            concept of Carbonext, Gloria was
                                            ready to bring her design expertise
                                            and help make Carbonext really
                                            shine.
                                        </p>
                                        <br />
                                        <p>
                                            In addition to making Carbonext look
                                            radiant, Gloria’s goal is to make
                                            the online customer experience easy
                                            and seamless to navigate, shop, and
                                            design a ring that’s one of a kind.
                                        </p>
                                    </FixedText>
                                </TeamMember>
                            </WrapItem>
                        </Wrap>
                    </Center>
                </VStack>
            </Box>
            <GetStarted currentMarketUser={currentMarketUser} />
            <Footer />
        </Box>
    );
};

AboutUs.propTypes = {};

AboutUs.defaultProps = {};
