import React, { useState } from "react";
import gql from "graphql-tag";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { mainTemplate1 } from "../../../Templates";
import { Mutation } from "@apollo/react-components";
import ErrorHandler from "../../../../stories/CloudManagementOS/ErrorHandler";
import MutationResult from "../../../../stories/CloudManagementOS/Apollo/MutationResult";

const UPDATE_ADMIN_PASSWORD_GQL_MUTATION = gql`
  mutation UpdateAdminPassword(
    $originalPassword: String!
    $newPassword: String!
  ) {
    updateAdminPassword(
      originalPassword: $originalPassword
      newPassword: $newPassword
    ) {
      message
    }
  }
`;

function checkNewPasswordAndNewPasswordConfirmation(
  newPassword,
  newPasswordConfirmation
) {
  return !(newPassword && newPassword === newPasswordConfirmation);
}

function UpdatePassword() {
  const [originalPassword, setOriginalPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  return (
    <Mutation mutation={UPDATE_ADMIN_PASSWORD_GQL_MUTATION}>
      {(updateAdminPassword, { error, data, loading }) => {
        return (
          <div>
            <ErrorHandler graphError={error} />
            <MutationResult result={data && data.updateAdminPassword} />
            <form
              className="vendor-auth-sign-up-form"
              onSubmit={e => {
                e.preventDefault();
                updateAdminPassword({
                  variables: {
                    originalPassword,
                    newPassword,
                    newPasswordConfirmation
                  }
                }).catch(e => {
                  console.log(e);
                });
              }}
            >
              <fieldset className="form-group">
                <input
                  className="form-control form-control-lg"
                  type="password"
                  placeholder={getConstToLanguage("PASSWORD")}
                  value={originalPassword}
                  onChange={e => setOriginalPassword(e.target.value)}
                />
              </fieldset>

              <fieldset className="form-group">
                <input
                  className="form-control form-control-lg"
                  placeholder={getConstToLanguage("NEW_PASSWORD")}
                  value={newPassword}
                  type="password"
                  onChange={e => setNewPassword(e.target.value)}
                />
              </fieldset>

              <fieldset className="form-group">
                <input
                  className="form-control form-control-lg"
                  placeholder={getConstToLanguage("NEW_PASSWORD_CONFIRMATION")}
                  value={newPasswordConfirmation}
                  type="password"
                  onChange={e => setNewPasswordConfirmation(e.target.value)}
                />
              </fieldset>

              <fieldset className="form-group pull-xs-right">
                <button
                  className="auth-submit-btn"
                  type="submit"
                  disabled={
                    loading ||
                    checkNewPasswordAndNewPasswordConfirmation(
                      newPassword,
                      newPasswordConfirmation
                    )
                  }
                >
                  {getConstToLanguage("SUBMIT")}
                </button>
              </fieldset>
            </form>
          </div>
        );
      }}
    </Mutation>
  );
}

function Settings() {
  let sections = {
    header: getConstToLanguage("SETTINGS"),
    body: UpdatePassword()
  };

  return mainTemplate1(sections);
}

export default Settings;
