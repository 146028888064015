import { ApolloProvider } from "@apollo/react-hooks";
import React from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import { CONST_TO_LANG_MAP } from "../../../constants/businessConstantsToLanguageMapping";
import { adminGraphClient } from "../../../graph";
import CloudManagmentOS from "../../../stories/CloudManagementOS";
import { useBreakpointValue } from "../../../stories/Primatives";
import { isAdminLoggedIn } from "../../helpers/currentUser";
import {
    GEM_LISTINGS,
    GEM_REQUEST,
    HOME,
    SETTINGS,
    VENDOR_ORGANIZATION_ICON,
} from "../../Icons";
import { iconography } from "../../IconsV2";
import { GQL_Q_CURRENT_ADMIN } from "../graph/queries";
import { GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS } from "../graph/queries/listCurrentUsersRunningMutations";
import AdminManagement from "./AdminManagement";
import VendorManagement from "./VendorManagement";
import MarketUserManagement from "./MarketUserManagement";
import GemListingManagement from "./GemListingManagement";
import GemRequestManagement from "./GemRequestManagement";
import AdminHome from "./Home";
import RelativeGemListingHistory from "./RelativeGemListingHistory";
import SalesOrderManagement from "./SalesOrderManagement";
import Settings from "./Settings";
import System from "./System";
import VendorOrganizationInquiryManagement from "./VendorOrganizationInquiryManagement";
import VendorOrganizationManagement from "./VendorOrganizationManagement/SearchAndList";
import SingleVendorOrganizationManagment from "./VendorOrganizationManagement/Single";

class CurrentAdmin {
    constructor(options = {}) {
        Object.assign(this, options);
    }

    getFullname() {
        return this?.person?.givenName + " " + this?.person?.familyName;
    }
}

const InnerHome = ({ ...props }) => {
    const { loading, error, data, refetch } = useQuery(GQL_Q_CURRENT_ADMIN, {
        client: adminGraphClient,
    });

    let currentAdmin = data?.currentAdmin;

    function currentView() {
        if (props.match.params.tool === "gem-listings-manager") {
            return (
                <GemListingManagement
                    match={props.match}
                    param1={props.match.params.param1}
                    param2={props.match.params.param2}
                    subtool={props.match.params.subtool}
                />
            );
        } else if (props.match.params.tool === "settings") {
            return <Settings />;
        } else if (props.match.params.tool === "home") {
            return <AdminHome match={props.match} />;
        } else if (props.match.params.tool === "vendor-org-manager") {
            return <VendorOrganizationManagement match={props.match} />;
        } else if (props.match.params.tool === "vendor-organization") {
            return <SingleVendorOrganizationManagment match={props.match} />;
        } else if (
            props.match.params.tool === "user-management" &&
            props.match.params.subtool === "administrators"
        ) {
            return <AdminManagement match={props.match} />;
        } else if (
            props.match.params.tool === "user-management" &&
            props.match.params.subtool === "vendors"
        ) {
            return <VendorManagement match={props.match} />;
        } else if (
            props.match.params.tool === "user-management" &&
            props.match.params.subtool === "market-users"
        ) {
            return <MarketUserManagement match={props.match} />;
        } else if (
            props.match.params.tool === "vendor-organization-inquiries"
        ) {
            return <VendorOrganizationInquiryManagement match={props.match} />;
            // } else if (props.match.params.tool === "gem-listings") {
            //   return <GemListingDetails match={props.match} />;
        } else if (props.match.params.tool === "gem-requests-manager") {
            return <GemRequestManagement match={props.match} />;
        } else if (props.match.params.tool === "sales-orders") {
            return <SalesOrderManagement match={props.match} />;
        } else if (props.match.params.tool === "relative-gem-listing-history") {
            return <RelativeGemListingHistory match={props.match} />;
        } else if (props.match.params.tool === "system") {
            return <System match={props.match} />;
        } else {
            return <div> unknown </div>;
        }
    }

    let currentLanguage = "en";
    let navMapping = {
        header: {
            link: "/admin/home",
        },
        navItems: {
            HOME: {
                link: "/admin/home",
                icon: HOME(),
            },
            GEM_LISTINGS: {
                link: "/admin/gem-listings-manager/home",
                icon: GEM_LISTINGS(),
            },
            USERS: {
                icon: GEM_LISTINGS(),
                subitems: {
                    ADMINISTRATORS: {
                        link: "/admin/user-management/administrators",
                    },
                    VENDORS: {
                        link: "/admin/user-management/vendors",
                    },
                    MARKET_USERS: {
                        link: "/admin/user-management/market-users",
                    },
                },
            },
            VENDOR_ORGANIZATIONS: {
                link: "/admin/vendor-org-manager/home",
                icon: VENDOR_ORGANIZATION_ICON({}),
            },
            VENDOR_ORGANIZATION_INQUIRIES: {
                link: "/admin/vendor-organization-inquiries/new",
                icon: GEM_LISTINGS(),
            },
            GEM_REQUESTS: {
                icon: GEM_REQUEST({ color: "#FFFFFF" }),
                subitems: {
                    UNFULFILLED: {
                        link:
                            "/admin/gem-requests-manager/gem-requests/unfulfilled",
                    },
                },
            },
            SALES_ORDERS: {
                icon: GEM_LISTINGS(),
                subitems: {
                    CURRENT: {
                        link: "/admin/sales-orders/current",
                    },
                },
            },
            SETTINGS: {
                icon: SETTINGS(),
                link: "/admin/settings",
            },
            SYSTEM: {
                icon: SETTINGS(),
                link: "/admin/system",
            },
            TOOLS: {
                icon: GEM_LISTINGS(),
                subitems: {
                    RELATIVE_GEM_LISTING_SEARCH_TOOL: {
                        link: "/admin/tools/relative-price-search",
                    },
                },
            },
        },
    };

    return (
        <CloudManagmentOS
            constToLanguageMap={CONST_TO_LANG_MAP}
            navMapping={navMapping}
            currentUserObj={
                currentAdmin && new CurrentAdmin({ ...currentAdmin })
            }
            currentLanguage={currentLanguage}
            navVariant={props.navVariant}
            main={currentView()}
            userType={"admin"}
            iconography={iconography}
            graphAPI={{
                listCurrentUsersRunningMutations: GQL_Q_LIST_CURRENT_USERS_RUNNING_MUTATIONS,
            }}
        />
    );
};

const Admin = (props) => {
    const history = useHistory();
    let navVariant = useBreakpointValue({ base: "collapsed", xl: "full" });

    if (isAdminLoggedIn()) {
        return (
            <ApolloProvider client={adminGraphClient}>
                <InnerHome navVariant={navVariant} {...props} />
            </ApolloProvider>
        );
    } else {
        history.push("/admin/login");
        return <div></div>;
    }
};

export default Admin;
