import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import SalesOrder from "../../UtilityComponents/MarketOS/SalesOrder";
import { Container, Center } from "../../../stories/Primatives";

const stripePromise = loadStripe(
    process.env.REACT_APP__THIRD_PARTY__STRIPE__PUBLISHABLE_KEY
);

const ComponentFunction = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <SalesOrder />
        </Elements>
    );
};

export default ComponentFunction;
