import { extendTheme } from "@chakra-ui/react";
import styles from "./styles";
const config = {
    useSystemColorMode: false,
    initialColorMode: "light",
};

const activeLabelStyles = {
    transform: "scale(0.85) translateY(-24px)",
};
export const theme = extendTheme({
    ...styles,
    config,
    fonts: {
        body: "Montserrat, sans-serif",
        heading: "QuicheText, sans-serif",
        mono: "Menlo, monospace",
    },
    styles: {
        global: {},
    },

    colors: {
        "primary-button": {
            500: "#f2ce4e", // primary button
            600: "brand.200", // secondary button yellow
        },
        "secondary-button": {
            50: "#fdf9f0", // primary button
            600: "#f2ce4e", // secondary button yellow
        },
        "gold-button": {
            500: "#b49a3c",
            600: "#9a7b1d",
        },
        "off-black": "#222222",
        yellow: {
            500: "#EACB49",
        },
        "checkbox-gold": {
            500: "#EACB49",
        },
        white: "#fff",
        "pale-peach": "#f0deb4",
        "off-grey": "#eeeeee",
        greyish: "#b2ab99",
        grey: {
            50: "#eeeeee",
            100: "#dddddd",
            500: "#EEEEEE",
            600: "#504F4F",
            700: "#7E7E7E",
        },
        // brand: {
        //     50: "#f5db79",
        //     100: "red",
        //     200: "green",
        //     300: "#9a7b1d",
        //     400: "#f7fafc",
        //     500: "#efbe2c", // primary button
        //     600: "#222222",// secondary button yellow
        //     700: "#eeeeee", // you need this
        //     800: "green", // you need this
        // },
        "gold-color": {
            300: "#EACB49",
            400: "#B38A39",
            500: "#D8B428", // primary button
            600: "brand.500", // secondary button yellow
            700: "#AB8F35",
        },
        "dark-gold": {
            50: "#000000",
            100: "#FFFFFF",
            200: "#EACB49",
            300: "#B39A39",
            400: "#D8B428",
            500: "#9A7B2D",
            600: "#DCDAD9",
            700: "#615F5A",
            800: "#242424",
        },
        "dark-gold-opacity": {
            200: "#E6DECB",
        },
        brand: {
            50: "#000000",
            100: "#FFFFFF",
            200: "#EACB49",
            300: "#B39A39",
            400: "#D8B428",
            500: "#9A7B2D",
            600: "#DCDAD9",
            700: "#615F5A",
            800: "#242424",
        },
    },
    components: {
        IconButton: {
            variants: {
                backgroundColor: "red",
            },
        },
        Button: {
            defaultProps: {
                size: "md",
            },
            sizes: {
                md: {
                    fontSize: "xl",
                    lineHeight: "8",
                    h: "14",
                },
                lg: {
                    fontSize: "2xl",
                    lineHeight: "10",
                    h: "4.438rem",
                },
            },
            variants: {
                "market.primary": {
                    w: "fit-content",
                    h: "2.75rem",
                    minW: "150px",
                    padding: "0px",
                    fontFamily: "Montserrat, sans-serif",
                    // paddingTop: "0.5rem",
                    // paddingBottom: "0.5rem",
                    borderRadius: 0,
                    color: "white",
                    bg: "dark-gold.500",
                    textTransform: "uppercase",
                    borderColor: "dark-gold.500",
                    _hover: {
                        bg: "gold-color.700",
                        // color: "dark-gold.500",
                    },
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                },
                /*        "market.secondary": {
          w: "fit-content",
          borderRadius: 0,
          color: "white",
          bg: "dark-gold.500",
          border: "2px",
          borderColor: "dark-gold.500",
          _hover: {
            bg: "white",
            color: "dark-gold.500",
          },
        },*/
                "market.primary.white": {
                    w: "fit-content",
                    borderRadius: 0,
                    h: "2.75rem",
                    color: "brand.500",
                    textTransform: "uppercase",
                    bg: "brand.100",
                    border: "2px",
                    borderColor: "dark-gold.500",
                    _hover: {
                        bg: "dark-gold-opacity.200",
                    },
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                },
                "market.secondary": {
                    w: "fit-content",
                    h: "2.75rem",
                    minW: "150px",
                    borderRadius: 0,
                    color: "black",
                    bg: "#FFFFFF",
                    textTransform: "uppercase",
                    borderColor: "dark-gold.500",
                    _hover: {
                        bg: "white",
                        color: "dark-gold.500",
                    },
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                },
                "market.outlined": {
                    w: "fit-content",
                    borderRadius: 0,
                    color: "white",
                    bg: "transparent",
                    border: "1px",
                    borderColor: "white",
                    _hover: {
                        bg: "white",
                        color: "dark-gold.500",
                    },
                    fontFamily: "Montserrat",
                    textTransform: "uppercase",
                    px: "12",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                },
                "market.outlined-black": {
                    w: "fit-content",
                    borderRadius: 0,
                    color: "black",
                    bg: "transparent",
                    border: "1px",
                    borderColor: "black",
                    _hover: {
                        bg: "white",
                        color: "dark-gold.500",
                    },
                    fontFamily: "Montserrat",
                    textTransform: "uppercase",
                    px: "12",
                },
                "cloud-management-os.primary": {
                    w: "fit-content",
                    borderRadius: 0,
                    color: "white",
                    bg: "dark-gold.500",
                    border: "2px",
                    borderColor: "dark-gold.500",
                    _hover: {
                        bg: "white",
                        color: "dark-gold.500",
                    },
                },
                "cloud-management-os.secondary": {
                    w: "fit-content",
                    borderRadius: 0,
                    bf: "white",
                    color: "dark-gold.500",
                    border: "2px",
                    borderColor: "dark-gold.500",
                    _hover: {
                        bg: "dark-gold.500",
                        color: "white",
                    },
                },
                "cloud-management-os.transparent-icon": {
                    backgroundColor: "Transparent",
                    _hover: {
                        backgroundColor: "Transparent",
                    },
                },
                sizes: {
                    sm: {
                        padding: "0.75rem 1.5rem",
                        fontSize: "xs",
                    },
                    xl: {
                        padding: "1.25rem 2.5rem",
                        fontSize: "lg",
                    },
                },
            },
        },
        Header: {
            variants: {
                full: {
                    bg: "white",
                    paddingLeft: "1.5rem",
                },
                collapse: {
                    bg: "off-black",
                    color: "white",
                    paddingLeft: "4rem",
                    heading: {
                        color: "white",
                    },
                },
            },
        },
        HeaderBodyWithDrawers: {
            baseStyle: {
                bg: "brand.600", // Normally, it is "semibold"
            },
            variants: {},
        },
        Popover: {
            variants: {
                responsive: {
                    popper: {
                        maxWidth: "unset",
                        width: "unset",
                    },
                    content: {
                        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.29)",
                    },
                },
                sidenav: {
                    content: {
                        color: "brand.300",
                    },
                },
            },
        },
        Form: {
            variants: {
                floating: {
                    container: {
                        _focusWithin: {
                            label: {
                                ...activeLabelStyles,
                            },
                        },
                        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
                            ...activeLabelStyles,
                        },
                        input: {
                            borderRadius: 0,
                            border: "1px black solid",
                        },
                        label: {
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            position: "absolute",
                            backgroundColor: "white",
                            pointerEvents: "none",
                            mx: 3,
                            px: 1,
                            my: 2,
                            transformOrigin: "left top",
                        },
                    },
                },
            },
        },
        Heading: {
            variants: {
                secondary: { fontFamily: "Montserrat, sans-serif" },
            },
        },
        Divider: {
            variants: {
                gold: {
                    opacity: "1",
                    border: "0",
                    borderColor: "#AB8F35",
                    borderStyle: "solid",
                    borderBottomWidth: "2px",
                },
                black: {
                    opacity: "1",
                    border: "0",
                    borderColor: "#000000",
                    borderStyle: "solid",
                    borderBottomWidth: "0.84px",
                },
            },
        },
    },
});
