import {
  VENDOR_JEWELRY_MAKER_ABOUT_TOGGLE_EDIT_MODE,
  VENDOR_JEWELRY_MAKER_ABOUT_UPDATE_FIELD,
  VENDOR_JEWELRY_MAKER_ABOUT_SAVE,
  VENDOR_JEWELRY_MAKER_ABOUT_PAGE_LOADED
} from '../constants/actionTypes';

export default (state = {aboutEditMode: false}, action) => {
  switch (action.type) {
    case VENDOR_JEWELRY_MAKER_ABOUT_PAGE_LOADED:

      return {
        ...state,
        about: action.payload[0].about
      };
    case VENDOR_JEWELRY_MAKER_ABOUT_TOGGLE_EDIT_MODE:
      return {
        ...state,
        aboutEditMode: !state.aboutEditMode
      };

    case VENDOR_JEWELRY_MAKER_ABOUT_SAVE:
      return {
        ...state,
        about: action.payload.about,
        aboutEditMode: false
      }

    case VENDOR_JEWELRY_MAKER_ABOUT_UPDATE_FIELD:
      let newAbout = {...state.about}
      newAbout[action.key] = action.value
      return { ...state, about: newAbout };
    default:
      return state;
  }
};
