import { QuestionIcon } from "@chakra-ui/icons";
import { Range } from "rc-slider";
import React, { useMemo } from "react";
import {
    LIST_OF_CLARITIES_IN_ORDER,
    LIST_OF_COLORS_IN_ORDER,
    LIST_OF_CUTS_IN_ORDER,
    LIST_OF_SHAPES_IN_ORDER,
} from "../../../constants/businessConstants";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import {
    AspectRatio,
    Box,
    Checkbox,
    Heading,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    SimpleGrid,
    VStack,
    Select,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "../../../stories/Primatives";
import SliderWithManualInputs from "../../../stories/Primatives/SliderWithManualInputs";
import { getShapeIcon } from "../../IconsV2";

/// TODO ------- THESE ALL NEED TO BE DEPRECATED

class ClarityRange extends React.Component {
    render() {
        return (
            <div className="">
                <Heading>{getConstToLanguage("CLARITY")}</Heading>
                <Range
                    min={0}
                    max={9}
                    defaultValue={[0, 9]}
                    allowCross={false}
                    // value={[
                    //   LIST_OF_CLARITIES_IN_ORDER.findIndex(
                    //     clarity => clarity === this.props.searchParam.clarityRange[0]
                    //   ),
                    //   LIST_OF_CLARITIES_IN_ORDER.findIndex(
                    //     clarity => clarity === this.props.searchParam.clarityRange[1]
                    //   )
                    // ]}
                    marks={LIST_OF_CLARITIES_IN_ORDER}
                    onChange={this.props.onChange}
                    tipFormatter={(value) => LIST_OF_CLARITIES_IN_ORDER[value]}
                />
                ;
            </div>
        );
    }
}

const CutRange = ({ onChange }) => {
    return (
        <div className="">
            <div className="title">{getConstToLanguage("CUT")}</div>
            <Range
                min={0}
                max={LIST_OF_CUTS_IN_ORDER.length - 1}
                defaultValue={[0, LIST_OF_CUTS_IN_ORDER.length - 1]}
                allowCross={false}
                marks={LIST_OF_CUTS_IN_ORDER.map(getConstToLanguage)}
                onChange={onChange}
                tipFormatter={(value) =>
                    getConstToLanguage(LIST_OF_CUTS_IN_ORDER[value])
                }
            />
        </div>
    );
};

class ColorRange extends React.Component {
    render() {
        return (
            <div className="">
                <div className="title">{getConstToLanguage("COLOR")}</div>
                <Range
                    min={0}
                    max={LIST_OF_COLORS_IN_ORDER.length - 1}
                    allowCross={false}
                    // value={[
                    //   LIST_OF_COLORS_IN_ORDER.findIndex(
                    //     color => color === this.props.searchParam.colorRange[0]
                    //   ),
                    //   LIST_OF_COLORS_IN_ORDER.findIndex(
                    //     color => color === this.props.searchParam.colorRange[1]
                    //   )
                    // ]}
                    marks={LIST_OF_COLORS_IN_ORDER}
                    onChange={this.props.onChange}
                    tipFormatter={(value) => LIST_OF_COLORS_IN_ORDER[value]}
                />
            </div>
        );
    }
}

const ShapeHelpPopover = React.memo(() => {
    return (
        <SimpleGrid columns={2} spacing={4} color="white">
            {LIST_OF_SHAPES_IN_ORDER.map((shape, idx) => {
                return (
                    <VStack key={idx}>
                        <AspectRatio w="1.25rem" ratio={1}>
                            {getShapeIcon(shape)({
                                color: "white",
                                height: "1.25rem",
                            })}
                        </AspectRatio>
                        <Box>{getConstToLanguage(shape)}</Box>
                    </VStack>
                );
            })}
        </SimpleGrid>
    );
});

const ShapeSelector = React.memo((props) => {
    return (
        <VStack align="left">
            <HStack>
                <Heading size="xs">{getConstToLanguage("SHAPE")}</Heading>
                <Popover
                    trigger="hover"
                    placement="right-start"
                    variant="responsive"
                >
                    <PopoverTrigger>
                        <QuestionIcon />
                    </PopoverTrigger>
                    <PopoverContent bg="greyish" p="1rem">
                        <PopoverArrow bg="greyish" />
                        <PopoverBody>
                            <ShapeHelpPopover />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </HStack>
            <Box>
                {LIST_OF_SHAPES_IN_ORDER.map((shape) => {
                    return (
                        <HStack spacing={2} key={shape}>
                            <Checkbox
                                value={shape}
                                colorScheme="checkbox-gold"
                                isChecked={
                                    props.selectedShapes &&
                                    props.selectedShapes.includes(shape)
                                }
                                onChange={() =>
                                    props.onClick({ target: { value: shape } })
                                }
                            />
                            <Box
                                onClick={() =>
                                    props.onClickSelectOnly({
                                        target: { value: shape },
                                    })
                                }
                            >
                                {getConstToLanguage(shape)}
                            </Box>
                        </HStack>
                    );
                })}
            </Box>
        </VStack>
    );
});

const ShapeSelectorDropDown = ({ register, name }) => {
    return (
        <Select {...register(name)}>
            {LIST_OF_SHAPES_IN_ORDER.map((shape) => (
                <option value={shape}>{getConstToLanguage(shape)}</option>
            ))}
        </Select>
    );
};

const ShapeSelectorDropDownFormControl = ({ error, register, name }) => {
    return (
        <FormControl isInvalid={error}>
            <FormLabel>{getConstToLanguage("SHAPE")}</FormLabel>
            <ShapeSelectorDropDown register={register} name={name} />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

const ClaritySelectorDropDown = ({ register, name }) => {
    return (
        <Select {...register(name)}>
            {LIST_OF_CLARITIES_IN_ORDER.map((clarity) => (
                <option value={clarity}>{getConstToLanguage(clarity)}</option>
            ))}
        </Select>
    );
};

const ClaritySelectorDropDownFormControl = ({ error, register, name }) => {
    return (
        <FormControl>
            <FormLabel>{getConstToLanguage("CLARITY")}</FormLabel>
            <ClaritySelectorDropDown register={register} name={name} />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

const ColorSelectorDropDown = ({ register, name }) => {
    return (
        <Select {...register(name)}>
            {LIST_OF_COLORS_IN_ORDER.map((color) => (
                <option value={color}>{getConstToLanguage(color)}</option>
            ))}
        </Select>
    );
};

const ColorSelectorDropDownFormControl = ({ error, register, name }) => {
    return (
        <FormControl>
            <FormLabel>{getConstToLanguage("COLOR")}</FormLabel>
            <ColorSelectorDropDown register={register} name={name} />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

const CaratInput = ({ register, name }) => {
    return <Input {...register(name)} />;
};

const CaratInputFormControl = ({ error, register, name }) => {
    return (
        <FormControl isInvalid={error}>
            <FormLabel>{getConstToLanguage("CARAT")}</FormLabel>
            <CaratInput register={register} name={name} />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

const CutSelectorDropDown = ({ register, name }) => {
    return (
        <Select {...register(name)}>
            {LIST_OF_CUTS_IN_ORDER.map((color) => (
                <option value={color}>{getConstToLanguage(color)}</option>
            ))}
        </Select>
    );
};

const CutSelectorDropDownFormControl = ({ error, register, name }) => {
    return (
        <FormControl>
            <FormLabel>{getConstToLanguage("CUT")}</FormLabel>
            <CutSelectorDropDown register={register} name={name} />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

export {
    ShapeSelector,
    ColorRange,
    CutRange,
    ClarityRange,
    ShapeSelectorDropDown,
    ClaritySelectorDropDown,
    ColorSelectorDropDown,
    CaratInput,
    CutSelectorDropDown,
    ShapeSelectorDropDownFormControl,
    ClaritySelectorDropDownFormControl,
    CaratInputFormControl,
    ColorSelectorDropDownFormControl,
    CutSelectorDropDownFormControl,
};
