import gql from "graphql-tag";

const GQL_Q_CURRENT_VENDOR = gql`
  query CurrentVendor {
    currentVendor {
      lastSeenWebNotification
      vendorOrganization {
        id
        legalName
      }
      vendor {
        localAuth {
          email
        }
        hasResetFirstTimePassword
        lastLogin {
          IP
          at
        }
        person {
          familyName
          givenName
        }
      }
    }
  }
`;
export { GQL_Q_CURRENT_VENDOR };
