import React from "react";
import { MainTemplate1BodyTemplate1Header } from "../../components/Templates";
import MainActionMenu from "../../stories/CloudManagementOS/MainActionMenu";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    HStack,
} from "../Primatives";
import { InnerHeaderBody } from "./shells/InnerHeaderBody";

// <
//         templateColumns="max-content 1fr max-content"
//         left={<Left />}
//         middle={this.props.searchFilterLocation === 'toolbar' && this.renderCurrentSearchParam()}
//         right={<Right />}
//       />

export const BreadCrumbToolbarInnerBody = React.memo(
    ({ body, breadcrumbs, tools, meta, actionMenuButtons }) => {
        const Right = () => {
            let elements = [];
            meta && elements.push(meta);
            tools && elements.push(tools);
            elements.push(
                <MainActionMenu
                    key={"rightActionMenu"}
                    buttons={actionMenuButtons}
                />
            );

            return (
                <HStack
                    padding={2}
                    spacing={2}
                    justifyContent="flex-end"
                    paddingRight="1.5rem"
                    alignItems="center"
                    h="100%"
                >
                    {elements}
                </HStack>
            );
        };

        return (
            <InnerHeaderBody
                header={
                    <MainTemplate1BodyTemplate1Header
                        paddingLeft="1.5rem"
                        left={
                            <Flex h="100%" alignItems="center">
                                <Breadcrumb>
                                    {breadcrumbs.map((breadcrumb, idx) => (
                                        <BreadcrumbItem
                                            key={idx}
                                            _last={{ color: "brand.300" }}
                                        >
                                            <BreadcrumbLink
                                                href={breadcrumb.link}
                                            >
                                                {breadcrumb.breadcrumb}
                                            </BreadcrumbLink>
                                        </BreadcrumbItem>
                                    ))}
                                </Breadcrumb>
                            </Flex>
                        }
                        right={<Right />}
                    />
                }
                body={body}
            />
        );
    }
);
