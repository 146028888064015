import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../../lib/GraphCallBuilder";

function GetSalesOrderGraphQLQuery() {
    return new GraphqlQueryBuilder({
        api: "getSalesOrder",
        type: "query",
        args: [new GQLInputArg("salesOrderId", "ID", true)],
    });
}

export { GetSalesOrderGraphQLQuery };
