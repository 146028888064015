import React from "react";
import { connect } from "react-redux";
import agent from "../../../../agent";
import { GEM_LISTING_MANAGER_SEARCH_SUBMIT } from "../../../../constants/actionTypes";
import GemListingManagement from "./GemListingManagement";

const mapStateToProps = (state) => ({
  ...state.gemListingsManager,
});

const mapDispatchToProps = (dispatch) => ({
  onSearchSubmit: (searchParam) =>
    dispatch({
      type: GEM_LISTING_MANAGER_SEARCH_SUBMIT,
      payload: agent.Vendor.searchGems(searchParam),
    }),
});

class TraderMainView extends React.Component {
  constructor(props) {
    super(props);

    this.nextPageFunc = () => (ev) => {
      ev.preventDefault();
      let newSearchParam = this.props.searchParam;
      newSearchParam.offset += newSearchParam.limit;
      this.props.nextPage(newSearchParam);
    };
    this.previousPageFunc = () => (ev) => {
      ev.preventDefault();
      let newSearchParam = this.props.searchParam;
      newSearchParam.offset -= newSearchParam.limit;
      if (newSearchParam.offset < 0) {
        newSearchParam.offset = 0;
      }
      this.props.previousPage(newSearchParam);
    };
    this.searchValueUpdateFunc = (ev) =>
      this.props.onChangeSearchValue(ev.target.value);

    this.submitSearchFunc = (searchParam) => (ev) => {
      ev.preventDefault();
      this.props.onSearchSubmit(searchParam);
    };
  }

  render() {

    return (
      <GemListingManagement
        runningMutations={this.props.runningMutations}
        subtool={this.props.subtool}
        param1={this.props.param1}
        gemListingItems={this.props.gemListingRows}
        match={this.props.match}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TraderMainView);
