import React from "react";
import PropTypes from "prop-types";
import { Center, Image, LinkBox, LinkOverlay } from "@chakra-ui/react";

/**
 * Primary UI component for user interaction
 */

export const ContactIcon = ({ media, url, ...props }) => {
    let imgSrc;
    switch (media) {
        case "email":
            imgSrc =
                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/interface-1.svg";
            break;
        case "facebook":
            imgSrc =
                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/ico-facebook%202.svg";
            break;
        case "instagram":
            imgSrc =
                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/ico-ig-1%202.svg";
            break;
        default:
            imgSrc = "";
    }
    return (
        <LinkBox h={12} w={12}>
            <Center h={12} w={12}>
                <LinkOverlay href={url} isExternal={"true"}>
                    <Image {...props} src={imgSrc}></Image>
                </LinkOverlay>
            </Center>
        </LinkBox>
    );
};

ContactIcon.propTypes = {
    /**
     * Link
     */
    media: PropTypes.oneOf(["email", "facebook", "instagram"]),
    /**
     * Link
     */
    url: PropTypes.string.isRequired,
};

ContactIcon.defaultProps = {
    media: "email",
    url: "",
};
