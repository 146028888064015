import { createContext } from "react";

export const NotificationsContext = createContext({
  notifications: {
    notifications: null,
    stats: null,
  },
});

export const CompanyPublicEventsContext = createContext({});

export const BaseRoutePrefix = createContext({});

export const CurrentUserContext = createContext({
  currentUser: null,
});

export const RealTimeSubscription = createContext({});

export const SharedFunctionsContext = createContext();
export const RunningMutationsContext = createContext();

export const CloudManagementOSVariant = createContext();
