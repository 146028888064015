import React from "react";
import { getShapeCategoryIcon, CloseIcon } from "../../../../IconsV2";
import { numberToCurrency } from "../../../etc/floatToCurrency";
import { getConstToLanguage } from "../../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import {
    Box,
    Flex,
    HStack,
    SpinnerButton,
    VStack,
} from "../../../../../stories/Primatives";
import { useTranslation } from "react-i18next";
import SalesOrderItem from "../SalesOrderItem";

const SalesOrderDetails = ({ salesOrder }) => {
    return (
        <VStack>
            {salesOrder?.items.map((item) => (
                <SalesOrderItem item={item} key={item.id} />
            ))}
        </VStack>
    );
};

export default SalesOrderDetails;
