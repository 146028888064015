import gql from "graphql-tag";

const CURRENT_MARKET_USER_GRAPH_QL_QUERY = gql`
    query CurrentMarketUser {
        currentMarketUser {
            currentSalesOrder {
                createdAt
                items {
                    id
                    state
                    createdAt
                    price
                    certifiedGem {
                        certification {
                            certificationId
                        }
                        specs {
                            shape
                            carat
                            clarity
                            cut
                            color
                        }
                    }
                }
            }
            marketUser {
                id
                person {
                    id
                    givenName
                }
            }
        }
    }
`;

export { CURRENT_MARKET_USER_GRAPH_QL_QUERY };
