import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

/**
 * Primary UI component for user interaction
 */
export const MutationResult = ({ mutationResult }) => {
  if (mutationResult) {
    return (
      <Alert status={mutationResult.status.toLowerCase()}>
        <AlertIcon />
        <AlertTitle mr={2}>{mutationResult.title}</AlertTitle>
        <AlertDescription>{mutationResult.description}</AlertDescription>
      </Alert>
    );
  }
  return <></>;
};

MutationResult.propTypes = {};

MutationResult.defaultProps = {};
