import { Icon } from "@chakra-ui/react";
import React from "react";


export const HeartShapeIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 525.545 516.515" fill={color} height={'auto'} width={'100%'}>
        <g
            id="Diamonds_Heart_Outlined"
            data-name="Diamonds_Heart Outlined"
            transform="translate(-36.047 -158.96)"
        >
            <g id="Diamonds">
                <g id="Grupo_41" data-name="Grupo 41">
                    <g id="XMLID_1728_">
                        <path
                            id="XMLID_1768_"
                            d="M75.985,458.2l.006,0,0-.009Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1767_"
                            d="M521.654,458.2l-.057-.389c-.1.192-.2.387-.294.579Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1766_"
                            d="M426.681,316.851l78.179,27.363,27.379-122.267-.17-.237L454.6,231.97Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1765_"
                            d="M346.269,262.126h0l80.413,54.725,27.922-84.88Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1764_"
                            d="M251.371,262.126Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1763_"
                            d="M346.268,262.126Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1762_"
                            d="M346.268,262.126l-47.324-52.044v-.2c-.083.067-.166.129-.249.2L251.37,262.126Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1761_"
                            d="M485.891,180.321a133.533,133.533,0,0,0-60.157-16.363c-1.824-.126-3.663-.205-5.511-.26L454.6,231.972Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1760_"
                            d="M346.268,262.126h0L454.6,231.971,420.223,163.7c-36.994-1.109-78.6,12.064-121.28,46.188v.2Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1759_"
                            d="M241.18,514.812l-1.536-2.513L168.166,409.55l.558,98.84Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1758_"
                            d="M143.037,231.971l27.921,84.88,80.413-54.725h0Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1757_"
                            d="M429.473,409.55l75.387-65.336-78.179-27.363Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1756_"
                            d="M356.459,514.812l72.456-6.422.558-98.84L357.995,512.3Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1755_"
                            d="M558.856,294.332a146.971,146.971,0,0,0-26.617-72.385L504.86,344.214Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1754_"
                            d="M298.694,543.571v65.335l57.765-94.094h0Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1753_"
                            d="M298.694,543.571,241.18,514.812h0l57.514,94.094Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1752_"
                            d="M168.166,409.55l2.792-92.7L92.779,344.214Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1751_"
                            d="M251.371,262.126,298.7,210.082c-42.667-34.194-84.273-47.435-121.275-46.392l-34.383,68.281Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1750_"
                            d="M532.07,221.711a139.24,139.24,0,0,0-46.179-41.39L454.6,231.971Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1749_"
                            d="M373.588,625.583a608.815,608.815,0,0,0,55.327-46.7v-70.5Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1748_"
                            d="M356.459,514.812l-57.765,94.094,67.939,21.813q3.428-2.493,6.955-5.136L428.915,508.39Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1747_"
                            d="M230.832,630.775c13.384,9.735,25.621,17.844,35.9,24.281q1.044.654,2.061,1.285,2.033,1.262,3.955,2.431,2.88,1.755,5.492,3.3,1.738,1.032,3.351,1.97,3.222,1.878,5.9,3.384,1.336.753,2.528,1.413c5.553,3.079,8.672,4.633,8.672,4.633V608.906Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1746_"
                            d="M428.915,578.888a510.767,510.767,0,0,0,53.7-59.664l-53.7-10.834Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1745_"
                            d="M298.694,673.475c.806-.408,3.984-2.034,8.672-4.633q1.189-.66,2.527-1.413c1.782-1,3.755-2.133,5.9-3.384q1.611-.939,3.351-1.97,2.609-1.548,5.492-3.3,1.92-1.17,3.955-2.431,1.017-.63,2.061-1.285c10.3-6.449,22.565-14.577,35.979-24.337l-67.939-21.813v64.568Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1744_"
                            d="M521.6,457.816a343.638,343.638,0,0,0,29.753-80.961l-46.489-32.64Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1743_"
                            d="M504.86,344.214l46.489,32.64a319.83,319.83,0,0,0,7.245-49.155q.739-10.832.748-21.982c0-3.8-.169-7.6-.485-11.386Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1742_"
                            d="M429.473,409.55l-.558,98.841L521.3,458.4c.1-.192.2-.387.294-.579l-16.737-113.6Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1741_"
                            d="M482.612,519.224A413.483,413.483,0,0,0,521.3,458.395L428.915,508.39Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1740_"
                            d="M114.809,519.268a511.047,511.047,0,0,0,53.915,59.857V508.39Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1739_"
                            d="M46.075,377.006A343.794,343.794,0,0,0,75.986,458.2L92.778,344.213Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1738_"
                            d="M38.549,294.117c-.327,3.861-.5,7.731-.5,11.6q0,11.15.748,21.982a319.716,319.716,0,0,0,7.28,49.306l46.7-32.792Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1737_"
                            d="M65.34,221.681a147,147,0,0,0-26.79,72.436l54.229,50.1Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1736_"
                            d="M170.958,316.851l-27.921-84.88-77.7-10.291h0L92.779,344.214Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1735_"
                            d="M111.687,180.218a139.2,139.2,0,0,0-46.346,41.461l77.7,10.291Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1734_"
                            d="M168.724,579.125a609.4,609.4,0,0,0,55.464,46.748L168.724,508.39Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1733_"
                            d="M241.18,514.812l-72.456-6.422,55.464,117.483q3.363,2.512,6.644,4.9l67.862-21.869Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1732_"
                            d="M177.419,163.69c-1.933.054-3.857.137-5.765.268a133.529,133.529,0,0,0-59.968,16.261l31.35,51.752Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1731_"
                            d="M168.166,409.55,92.779,344.214,75.986,458.2l0,.009,92.733,50.182Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1730_"
                            d="M75.991,458.208a413.388,413.388,0,0,0,38.819,61.06l53.915-10.878Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1729_"
                            d="M298.945,543.571l57.514-28.759h0l1.536-2.512,71.478-102.75-2.792-92.7-80.413-54.725h-94.9l-80.413,54.725-2.792,92.7L239.644,512.3l1.536,2.513h0l57.514,28.759Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <path
                        id="Trazado_1662"
                        data-name="Trazado 1662"
                        d="M559.106,291.663a146.971,146.971,0,0,0-26.617-72.385l-.17-.237a139.24,139.24,0,0,0-46.179-41.39,133.533,133.533,0,0,0-60.157-16.363c-1.824-.126-3.663-.205-5.511-.26-36.994-1.109-78.6,12.064-121.28,46.188-.083.067-.166.129-.249.2-42.667-34.194-84.273-47.435-121.275-46.392-1.933.054-3.857.137-5.765.268a133.529,133.529,0,0,0-59.968,16.261A139.2,139.2,0,0,0,65.589,219.01h0A147,147,0,0,0,38.8,291.447c-.327,3.861-.5,7.731-.5,11.6q0,11.148.748,21.981a319.716,319.716,0,0,0,7.28,49.306,343.794,343.794,0,0,0,29.911,81.193v.006l0,0a413.465,413.465,0,0,0,38.818,61.06,511.045,511.045,0,0,0,53.915,59.857A609.245,609.245,0,0,0,224.436,623.2q3.363,2.512,6.644,4.9c13.384,9.735,25.621,17.844,35.9,24.281q1.044.654,2.061,1.285,2.033,1.262,3.955,2.431,2.88,1.755,5.492,3.3,1.738,1.032,3.351,1.97,3.222,1.878,5.9,3.384,1.336.753,2.528,1.413c5.553,3.079,8.672,4.633,8.672,4.633h0c.806-.408,3.984-2.034,8.672-4.633q1.189-.66,2.527-1.413c1.782-1,3.755-2.133,5.9-3.384q1.611-.939,3.351-1.97,2.609-1.547,5.492-3.3,1.92-1.17,3.955-2.431,1.017-.63,2.061-1.285c10.3-6.449,22.565-14.577,35.979-24.337q3.428-2.495,6.955-5.136a608.815,608.815,0,0,0,55.327-46.7,510.767,510.767,0,0,0,53.7-59.664,413.4,413.4,0,0,0,38.691-60.829l.351-.19-.057-.389A343.584,343.584,0,0,0,551.6,374.184a319.812,319.812,0,0,0,7.245-49.155q.739-10.832.748-21.981C559.59,299.25,559.421,295.452,559.106,291.663Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
