import React, { useState } from "react";
import {
  Box,
  IconButton,
  VStack,
  Container,
  useColorModeValue,
} from "../../../../Primatives";
import { EditIcon } from "@chakra-ui/icons";
import CurrentBankAccount from "./CurrentBankAccount";
import { AddOrUpdatePayableBankAccount } from "./AddOrUpdatePayableBankAccount";
import { EditableKeyValue } from "../../../../CloudManagementOS/SmartForm";
import ViewOrEditForm from "../../../shells/ViewOrEditForm";
import { getConstToLanguage } from "../../../ConstToLanguageMapping";
import { SmartForm } from "../../../SmartForm";
import * as yup from "yup";

const validationSchema = yup.object({
  routingNumber: yup
    .string()
    .matches(
      /^([0-9]{9})$/,
      getConstToLanguage("ROUTING_NUMBER_MUST_BE_9_DIGITS")
    )
    .required("Required"),
  accountNumber: yup
    .string()
    .matches(
      /^([0-9]{6,17})$/,
      getConstToLanguage("ACCOUNT_NUMBER_MUST_BE_6_TO_17_DIGITS")
    )
    .required("Required"),
});

const PayableBankAccountManagement = ({
  createNewBankAccountForVendorMutation,
  getCurrentPayableBankAccount,
}) => {
  let [
    currentPayableBankAccountInfo,
    setCurrentPayableBankAccountInfo,
  ] = useState();

  const onUnhide = async () => {
    if (!currentPayableBankAccountInfo) {
      let { data } = await getCurrentPayableBankAccount();
      setCurrentPayableBankAccountInfo(data?.getCurrentPayableBankAccount);
    }
  };

  return (
    <ViewOrEditForm
      validationSchema={validationSchema}
      mutation={createNewBankAccountForVendorMutation}
      boxShadow={useColorModeValue("sm", "sm-dark")}
      needsConfirmation={true}
      formTitle={getConstToLanguage("PAYABLE_BANK_ACCOUNT_MANAGEMENT")}
      confirmationBody={getConstToLanguage("CONFIRM_PAYABLE_ACCOUNT_CHANGE")}
    >
      <EditableKeyValue
        name={"routingNumber"}
        keyName={getConstToLanguage("ROUTING_NUMBER")}
        hidden={true}
        onUnhide={onUnhide}
        unHiddenValue={currentPayableBankAccountInfo?.routingNumber}
      />
      <EditableKeyValue
        name={"accountNumber"}
        keyName={getConstToLanguage("ACCOUNT_NUMBER")}
        hidden={true}
        onUnhide={onUnhide}
        unHiddenValue={currentPayableBankAccountInfo?.accountNumber}
      />
    </ViewOrEditForm>
  );
};

export default PayableBankAccountManagement;
