import { useQuery } from "@apollo/react-hooks";
import {
  Box,
  SimpleGrid,
  useColorModeValue as mode
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { GLS_CATEGORY_TO_GLS_ARRAY_MAPPING } from "../../../constants/businessConstants";
import { Header } from "../../../stories/CloudManagementOS";
import { BreadCrumbToolbarInnerBody } from "../../../stories/CloudManagementOS/BreadCrumbToolbarInnerBody";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { convertConstToUrlConst } from "../../../stories/CloudManagementOS/ConstToUrl";
import { CloudManagementOSVariant } from "../../../stories/CloudManagementOS/Context";
import { useUserEvents } from "../../../stories/CloudManagementOS/hooks/useUserEvents";
import { HeaderBodyShell } from "../../../stories/CloudManagementOS/shells";
import { StatCard } from "../../../stories/CloudManagementOS/StatCard";


export const GemListingStateCategoryDashboard = ({
  urlPrefix,
  toolUrlRoute,
  gqlQueries,
  category,
  actionMenuButtons,
}) => {
  const { loading, error, data, refetch } = useQuery(
    gqlQueries.globalStatForSearchQuery,
    {}
  );

  let variant = useContext(CloudManagementOSVariant);
  let { eventToHandle } = useUserEvents()

  let transformedDataFromGQLQuery = data
    ? GLS_CATEGORY_TO_GLS_ARRAY_MAPPING[category.toLowerCase()].map(
      (state) => ({
        label: state,
        value:
          data.getGlobalStatsFromSearchOfGemListings.totalPrice.byState[
          state
          ],
        count:
          data.getGlobalStatsFromSearchOfGemListings.counts.byState[state],
        currency: "$",
        link: `/${urlPrefix}/${toolUrlRoute}/gem-listings/${convertConstToUrlConst(
          category
        )}/${convertConstToUrlConst(state)}`,
      })
    )
    : null;



  useEffect(() => {

    if (eventToHandle && eventToHandle.type === "mutation.state_change") {
      if (eventToHandle.data.state === 'COMPLETED') {
        refetch()

      }
    }

  }, [eventToHandle]);



  return (
    <HeaderBodyShell
      headerHeight="4rem"
      header={
        <Header
          variant={variant}
          title={getConstToLanguage("GEM_LISTINGS")}
          linkTo={`/${urlPrefix}/${toolUrlRoute}`}
          bg={variant === "full" ? "white" : "off-black"}
          color={variant === "full" ? "off-black" : "white"}
        />
      }
      body={
        <BreadCrumbToolbarInnerBody
          tools={null}
          meta={null}
          actionMenuButtons={actionMenuButtons}
          breadcrumbs={[{ breadcrumb: getConstToLanguage(category) }]}
          body={
            <Box as="section" bg={mode("gray.50", "gray.800")} p="10">
              <Box maxW="7xl" mx="auto" px={{ base: "6", md: "8" }}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="6">
                  {transformedDataFromGQLQuery?.map(
                    (categoryStat, idx) => (
                      <StatCard key={idx} data={categoryStat} />
                    )
                  )}
                </SimpleGrid>
              </Box>
            </Box>
          }
        />
      }
    />
  );
};

export default GemListingStateCategoryDashboard;
