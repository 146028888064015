import { useApolloClient, useQuery } from "@apollo/react-hooks";
import * as Ably from "ably";
import PubSub from "pubsub-js";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Center, Grid, Spinner, useBreakpointValue } from "../Primatives";
import { setConstToLanguageMap } from "./ConstToLanguageMapping";
import {
    CloudManagementOSVariant,
    CompanyPublicEventsContext,
    CurrentUserContext,
    NotificationsContext,
    RunningMutationsContext,
    SharedFunctionsContext,
    BaseRoutePrefix,
} from "./Context";
import "./css/cloudManagementOS.css";
import { useCurrentUser, useUserEvents } from "./hooks";
import { Nav } from "./Nav";

const CloudManagementOS = ({
    notifications,
    currentUserObj,
    navMapping,
    setLastSeenWebNotification,
    constToLanguageMap,
    iconography,
    loading,
    main,
    navVariant,
    graphAPI,
    companyIdentifier,
    companyGlobalPublicEventsChannel,
    userType,
    logoVariant,
    currentLanguage,
}) => {
    setConstToLanguageMap(constToLanguageMap);

    const [companyPublicEvents, setCompanyPublicEvents] = useState({
        newEvents: [],
    });
    const variant = useBreakpointValue({ base: "collapse", md: "full" });
    const { data: runningMutationsResp } = useQuery(
        graphAPI.listCurrentUsersRunningMutations
    );
    let runningMutations =
        runningMutationsResp?.listCurrentUsersRunningMutations;
    let { eventToHandle } = useUserEvents();
    const cache = useApolloClient();

    useEffect(() => {
        if (
            eventToHandle &&
            eventToHandle.type === "mutation.state_change" &&
            eventToHandle.data &&
            eventToHandle.data.state === "COMPLETED"
        ) {
            const data = cache.readQuery({
                query: graphAPI.listCurrentUsersRunningMutations,
            });

            cache.writeQuery({
                query: graphAPI.listCurrentUsersRunningMutations,
                data: {
                    listCurrentUsersRunningMutations: data?.listCurrentUsersRunningMutations.filter(
                        (runningMutation) =>
                            runningMutation.id !== eventToHandle.data.id
                    ),
                },
            });
        }
    }, [eventToHandle, runningMutations, cache]);

    console.log("Current Mutations: ", runningMutations);

    let templateColumns;

    if (variant === "full") {
        if (navVariant === "collapsed") {
            templateColumns = "4rem 1fr";
        } else {
            templateColumns = "12rem 1fr";
        }
    } else {
        templateColumns = "1fr";
    }

    const auth = useCurrentUser(userType, currentUserObj);
    let parsedToken = auth.parseToken(userType);

    const ablyClient = useMemo(() => {
        return new Ably.Realtime({
            token: auth.jwtToken,
            authCallback: async (tokenParams, callback) => {
                try {
                    const tokenRequest = await auth.refreshToken();
                    callback(null, tokenRequest);
                } catch (error) {
                    callback(error, null);
                }
            },
        });
    }, [currentUserObj]);

    let subscribedNotificationChannels = useMemo(() => {
        if (parsedToken?.vendorOrganization) {
            return [
                ablyClient.channels.get(
                    `vorg:${parsedToken.vendorOrganization}:vendor:${parsedToken.userId}:notifications`
                ),
                ablyClient.channels.get(
                    `vorg:${parsedToken.vendorOrganization}:notifications`
                ),
            ];
        } else {
            console.log(
                "HEREEE I AMMMMMM",
                `${parsedToken.userType}:${parsedToken.userId}:notifications`
            );
            return [
                ablyClient.channels.get(
                    `${parsedToken.userType}:${parsedToken.userId}:notifications`
                ),
            ];
        }
    }, [ablyClient]);

    let subscribedCompanyPublicEventsChannel = useMemo(() => {
        return ablyClient.channels.get(companyGlobalPublicEventsChannel);
    }, [ablyClient]);

    let subscribedUserEventChannel = useMemo(() => {
        if (parsedToken.vendorOrganization) {
            return ablyClient.channels.get(
                `vorg:${parsedToken.vendorOrganization}:vendor:${parsedToken.userId}:events`
            );
        } else {
            return ablyClient.channels.get(
                `${parsedToken.userType}:${parsedToken.userId}:events`
            );
        }
    }, [ablyClient]);

    let subscribedVOrgEventChannel = useMemo(() => {
        if (parsedToken.vendorOrganization) {
            return ablyClient.channels.get(
                `vorg:${parsedToken.vendorOrganization}:events`
            );
        }
    }, [ablyClient]);

    console.log("Company Public Events: ", companyPublicEvents);

    useEffect(() => {
        console.log("Subscribing to Carbonext Events Channel");
        console.log(subscribedCompanyPublicEventsChannel);

        subscribedCompanyPublicEventsChannel.subscribe((data) => {
            let incomingEvent = incomingDataToIncomingEvent(data.data);

            console.log(`Incoming ${companyIdentifier} Event`, incomingEvent);
            PubSub.publish(`${companyIdentifier}:public:events`, incomingEvent);
        });

        subscribedNotificationChannels.map((subscribedChannel) =>
            subscribedChannel.subscribe(function (data) {
                let dataObj = JSON.parse(JSON.stringify(data));
                let notification = dataObj.data;

                console.log("Incoming User Notification", notification);
                PubSub.publish("user:notifications", notification);
            })
        );

        return () => {
            subscribedCompanyPublicEventsChannel.unsubscribe();
            subscribedNotificationChannels.map((subscribedChannel) =>
                subscribedChannel.unsubscribe()
            );
        };
    }, [auth.jwtToken]);

    const incomingDataToIncomingEvent = (data) => {
        let incomingEvent = JSON.parse(JSON.stringify(data));

        if (
            incomingEvent.data &&
            incomingEvent.datacontenttype == "application/json"
        ) {
            let messageData = JSON.parse(incomingEvent.data);
            incomingEvent.data = messageData;
        }

        return incomingEvent;
    };

    useEffect(() => {
        console.log("Subscribing to Events Channel");
        subscribedUserEventChannel.subscribe(function (data) {
            let incomingEvent = incomingDataToIncomingEvent(data.data);

            console.log(`Incoming User Event`, incomingEvent);

            PubSub.publish("user:events", incomingEvent);
        });

        if (parsedToken.vendorOrganization) {
            subscribedVOrgEventChannel.subscribe(function (data) {
                console.log(`Incoming Vendor Organization Event`, data.data);
                let incomingEvent = incomingDataToIncomingEvent(data.data);

                console.log(
                    `Incoming Vendor Organization Event`,
                    incomingEvent
                );

                PubSub.publish("vorg:events", incomingEvent);
            });
        }

        return () => {
            subscribedUserEventChannel.unsubscribe();
            if (subscribedVOrgEventChannel) {
                subscribedVOrgEventChannel.unsubscribe();
            }
        };
    }, [auth.jwtToken]);

    return (
        <CompanyPublicEventsContext.Provider
            value={[companyPublicEvents, setCompanyPublicEvents]}
        >
            <BaseRoutePrefix.Provider value={"/" + userType}>
                <NotificationsContext.Provider value={notifications}>
                    <CurrentUserContext.Provider value={auth}>
                        <RunningMutationsContext.Provider
                            value={runningMutations}
                        >
                            <SharedFunctionsContext.Provider
                                value={{
                                    setLastSeenWebNotification: setLastSeenWebNotification,
                                }}
                            >
                                <CloudManagementOSVariant.Provider
                                    value={variant}
                                >
                                    {loading ? (
                                        <Center w="100vw" h="100vh">
                                            <Spinner color="brand.300" />
                                        </Center>
                                    ) : (
                                        <>
                                            {variant === "collapse" && (
                                                <Nav
                                                    logoVariant={logoVariant}
                                                    variant={variant}
                                                    bg="off-black"
                                                    color="white"
                                                    navType={"sidenav"}
                                                    navMapping={navMapping}
                                                    iconography={iconography}
                                                    currentUser={currentUserObj}
                                                />
                                            )}
                                            <Grid
                                                templateColumns={
                                                    templateColumns
                                                }
                                                w="100vw"
                                                h="100vh"
                                                maxH="100vh"
                                                overflow="hidden"
                                            >
                                                {variant === "full" && (
                                                    <Nav
                                                        variant={variant}
                                                        navVariant={navVariant}
                                                        bg="off-black"
                                                        color="white"
                                                        navType={"sidenav"}
                                                        navMapping={navMapping}
                                                        iconography={
                                                            iconography
                                                        }
                                                        currentUser={
                                                            currentUserObj
                                                        }
                                                    />
                                                )}
                                                <Box maxH="100vh"> {main}</Box>
                                            </Grid>
                                        </>
                                    )}
                                </CloudManagementOSVariant.Provider>
                            </SharedFunctionsContext.Provider>
                        </RunningMutationsContext.Provider>
                    </CurrentUserContext.Provider>
                </NotificationsContext.Provider>
            </BaseRoutePrefix.Provider>
        </CompanyPublicEventsContext.Provider>
    );
};

export default CloudManagementOS;
export { Header } from "./Header";
export { SearchResultListItem } from "./SearchResultListItem";
