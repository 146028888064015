import React, { useContext, useState, setContext } from "react";
import { useHistory } from "react-router-dom";
import { onError } from "@apollo/client/link/error";
import { Box, createStandaloneToast } from "@chakra-ui/react";
import { history } from "../store";
import { theme } from "../chakra";
import { MARKET_USER_START_RESERVE_PROCESS } from "../constants/actionTypes";

const toast = createStandaloneToast({ theme: theme });

const ApolloClientErrorHandler = (redirectPath) => {
    return ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            for (let error of graphQLErrors) {
                console.log("EXCCPTTTIONSSSSSS22222", error);
                console.error(
                    `[GraphQL error]: ${error.extensions.code} Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
                );

                switch (error.extensions.code) {
                    case "INVALID_AUTH_TOKEN":
                        localStorage.clear();

                        // if (redirectPath) {
                        //     history.push(redirectPath);
                        //     window.location.reload();
                        // } else {
                        //     toast({
                        //         title: "Login to continue.",
                        //         description: "Login to continue.",
                        //         status: "warning",
                        //         duration: 9000,
                        //         isClosable: true,
                        //         position: "top",
                        //     });
                        // }
                        break;
                    case "LOGIN_FAILED":
                        break;
                    case "INVALID_ORGINAL_PASSWORD":
                        break;
                    case "MU_EMAIL_EXISTS":
                        break;

                    /*       //need to define getNewToken
               case 'UNAUTHENTICATED':
                 return fromPromise(
                   getNewToken().catch(error => {
                     // redirect to login
                     return;
                   })
                 ).filter(value => Boolean(value))
                   .flatMap(accessToken => {
                     const oldHeaders = operation.getContext().headers;
                     // modify the operation context with a new token
                     operation.setContext({
                       headers: {
                         ...oldHeaders,
                         authorization: `Bearer ${accessToken}`,
                       },
                     });
     
                     // retry the request, returning the new observable
                     return forward(operation);
                   });*/

                    default:
                    // toast({
                    //     title: "An error occurred.",
                    //     description:
                    //         error.message ||
                    //         "There is an error handling your request." +
                    //             error.toString(),
                    //     status: "warning",
                    //     duration: 9000,
                    //     isClosable: true,
                    //     position: "top",
                    // });
                    /*toast({
            title: "An error occurred.",
            description: "Your session has expired.",
            status: "warning",
            duration: 9000,
            isClosable: true,
            position: 'top',
          })*/
                }
            }
        }
        if (networkError) {
            // errors.add(networkError);

            console.error(`[Network error]: ${networkError}`);

            // let createdToast = toast({
            //     title: "An error occurred.",
            //     description: `Network error.${networkErrorCount}`,
            //     status: "error",
            //     duration: 9000,
            //     isClosable: true,
            //     position: "top",
            // });

            // count += 1;
        }
    };
};
export default ApolloClientErrorHandler;
