import React from "react";
import { Mutation } from "@apollo/react-components";
import { VERIFY_AND_CREATE_ORGANIZATION_FROM_VENDOR_ORGANIZATION_INQUIRY } from "../../../graph/mutations/VendorOrganization/verifyAndCreateVendorOrganizationFromVendorInquiry";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { SearchResultListItem } from "../../../stories/CloudManagementOS"
import { SHAPE_TO_ICON_MAPPING } from "../../Icons";

class GemRequestListItem extends React.Component {
  constructor() {
    super();

    this.state = { showActions: false };

    this.renderActions = this.renderActions.bind(this);
    this.renderModelType = this.renderModelType.bind(this);
    this.renderIdentifier = this.renderIdentifier.bind(this);
    this.renderMeta = this.renderMeta.bind(this);
    this.renderOwner = this.renderOwner.bind(this);
  }

  renderActions() {
    return [
      <Mutation
        mutation={
          VERIFY_AND_CREATE_ORGANIZATION_FROM_VENDOR_ORGANIZATION_INQUIRY
        }
      >
        {(
          verifyAndCreateVendorOrganizationFromVendorInquiry,
          { error, data, loading }
        ) => {
          return (
            <button
              onClick={e => {
                e.preventDefault();
                verifyAndCreateVendorOrganizationFromVendorInquiry({
                  variables: {
                    vendorOrganizationInquiryId: this.props.item.data.id
                  }
                });
              }}
            >
              Verify Inquiry
            </button>
          );
        }}
      </Mutation>
    ];
  }

  renderModelType() {
    return (
      <div className="gem-request-item-model-item-style-1-model-type">
        <div className="gem-request-item-model-item-style-1-model-type__icon">
          {this.props.itemIdentifierIcon({ color: "#000000" })}
        </div>
      </div>
    );
  }

  renderIdentifier() {
    return <div className="">{getConstToLanguage("REQUESTED_NEEDED_BY")} </div>;
  }

/*  renderOwner() {
    return (
      <div>
        {getConstToLanguage("CREATED_BY")}{" "}
        {this.props.item &&
          this.props.item.data &&
          this.props.item.data.node.vendorOrganization &&
          this.props.item.data.node.vendorOrganization.legalName}
      </div>
    );
  }*/

  renderMeta() {
    return (
      <div>
        {" "}
        {this.props.item &&
          this.props.item.data &&
          this.props.item.data.node.createdAt}
      </div>
    );
  }

  renderPrimaryInfo() {
    return (
      <div className="gem-listing-pricing-info">
        {/* <div className="gem-listing-pricing-info__relative-price">
          {"$1,700"}
        </div>
        <div className="gem-listing-pricing-info__pricing">
          <div className="gem-listing-pricing-info__pricing__price">
            {"$1,300"}
          </div>
          <div className="gem-listing-pricing-info__pricing__discount">
            {"30%"}
          </div>
        </div> */}
      </div>
    );
  }

  renderOwner() {
    return (
      <div>
        {getConstToLanguage("REQUESTED_BY")}{" "}
        {this.props.item &&
          this.props.item.data &&
          this.props.item.data.node.createdBy &&
          this.props.item.data.node.createdBy.person.givenName}{" "}
        {this.props.item &&
          this.props.item.data &&
          this.props.item.data.node.createdBy &&
          this.props.item.data.node.createdBy.person.familyName}
      </div>
    );
  }

  renderSecondaryInfo() {
    let priceRange = [100, 2000];
    let shapes = ["ROUND", "HEART", "TRILLIANT"];
    let caratRange = [1.0, 2.0];
    let clarityRange = ["VS1", "VVS2"];
    let colorRange = ["D", "F"];
    let cutRange = ["D", "F"];
    return (
      <div className="gem-request-list-item-secondary-info">
        <div>
          {getConstToLanguage("PRICE")} : {priceRange[0]} - {priceRange[1]}
          {/* <Range value={priceRange} min={priceRange[0]} max={priceRange[1]} /> */}
        </div>
        <div>
          {getConstToLanguage("SHAPES")} :{" "}
          <div className="gem-request-list-item-secondary-info__shape_list">
            {shapes.map(shape =>
              SHAPE_TO_ICON_MAPPING[shape]({ color: "#000000" })
            )}
          </div>
        </div>
        <div>
          {getConstToLanguage("CARAT")} : {caratRange[0]} - {caratRange[1]}{" "}
        </div>
        <div>
          {getConstToLanguage("CLARITY")} : {clarityRange[0]} -{" "}
          {clarityRange[1]}{" "}
        </div>
        <div>
          {getConstToLanguage("COLOR")} : {colorRange[0]} - {colorRange[1]}{" "}
        </div>
        <div>
          {getConstToLanguage("CUT")} : {cutRange[0]} - {cutRange[1]}{" "}
        </div>
      </div>
    );
  }

  render() {
    console.log("ISDFPOISDUFPOIDSUPOIDUOPD");
    return (
      <SearchResultListItem
        iconography={this.props.iconography}
        actions={this.renderActions()}
        modelType={this.renderModelType()}
        menu={this.props.menu}
        indentifier={this.renderIdentifier()}
        meta={this.renderMeta()}
        owner={this.renderOwner()}
        primaryInfo={this.renderPrimaryInfo()}
        secondaryInfo={this.renderSecondaryInfo()}
        createdAt={this.props.item.data && this.props.item.data.node.createdAt}
      />
    );
  }
}

export default GemRequestListItem;
