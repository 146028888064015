import gql from "graphql-tag";
import GQL_M_CREATE_BANK_ACCOUNT_FOR_VENDOR from "./createBankAccountForVendorOrganization";

const GQL_M_SET_LAST_SEEN_WEB_NOTIFICATION = gql`
    mutation SetLastSeenWebNotification($notificationId: ID!) {
        setLastSeenWebNotification(notificationId: $notificationId)
    }
`;

const GQL_M_GENERATE_NEW_VENDOR_ORGANIZATION_APIS_SFTP_CREDENTIALS = gql`
    mutation GenerateNewVendorOrganizationAPISFTPCredentials {
        generateNewVendorOrganizationAPISFTPCredentials {
            privateKey
        }
    }
`;

const GQL_M_TEST_SSH_CREDENTIALS = gql`
    mutation TestSSHCredentials($sshPrivateKey: String!) {
        testSSHCredentials(sshPrivateKey: $sshPrivateKey) {
            type
        }
    }
`;

const GQL_M_DOWNLOAD_PRIVATE_KEY_AS = gql`
    mutation ConvertPrivateKeyToPPKFormat(
        $sshPrivateKey: String!
        $type: PrivateKeyFileType!
    ) {
        convertPrivateKeyToPPKFormat(privateKey: $sshPrivateKey, type: $type)
    }
`;

export {
    GQL_M_SET_LAST_SEEN_WEB_NOTIFICATION,
    GQL_M_GENERATE_NEW_VENDOR_ORGANIZATION_APIS_SFTP_CREDENTIALS,
    GQL_M_TEST_SSH_CREDENTIALS,
    GQL_M_CREATE_BANK_ACCOUNT_FOR_VENDOR,
    GQL_M_DOWNLOAD_PRIVATE_KEY_AS,
};
