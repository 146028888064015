import { Icon } from "@chakra-ui/react";
import React from "react";


export const CushionShapeIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 517.865 508.169" fill={color} height={'auto'} width={'100%'}>
        <g
            id="Diamonds_Cushion_Outlined"
            data-name="Diamonds_Cushion Outlined"
            transform="translate(-35.028 -155.216)"
        >
            <g id="Diamonds">
                <g id="Grupo_39" data-name="Grupo 39">
                    <g id="XMLID_1862_">
                        <path
                            id="XMLID_1908_"
                            d="M394.47,169.625,295.027,157.85l81.145,55.316Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1907_"
                            d="M376.172,213.166,295.027,157.85l-1.145-.135-3.438.407L209.7,213.167H376.172Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1906_"
                            d="M191.61,170.124,98.122,212.847l-.7.319H209.7Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1905_"
                            d="M290.444,158.121l-97.952,11.6-.882.4L209.7,213.166Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1904_"
                            d="M489.871,312.274l.071.006,42.43-20.506-41.624-78.422-.407-.186h-.47Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1903_"
                            d="M489.871,213.166v-.215l-94.6-43.23-.8-.1-18.3,43.541h113.7Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1902_"
                            d="M489.871,472.652l60.893-62.346-.026-.757-.077-.5-60.79-96.735Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1901_"
                            d="M490.4,312.315l-.454-.035-.071.035,60.79,96.735L532.713,292.417l-.341-.643-42.43,20.506Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1900_"
                            d="M489.942,312.28l-.071-.006v.041Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1899_"
                            d="M490.341,213.166l-.47-.215v.215Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1898_"
                            d="M190.981,648.623l1.655.756,101.39,12.006-100.39-55.809Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1897_"
                            d="M489.871,606.214l1.021-.467.09-.171h-1.111Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1896_"
                            d="M392.234,605.576l2.7,43.859.477-.057,94.454-43.165v-.637Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1895_"
                            d="M294.026,661.385l100.913-11.95-2.7-43.859Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1894_"
                            d="M98.122,213.166h-.7l-.407.186L55.068,292.387l43.054,19.928Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1893_"
                            d="M193.636,605.576l100.39,55.809,98.208-55.809Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1892_"
                            d="M489.871,472.652l43.442,51.062,17.451-113.408Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1891_"
                            d="M489.871,605.576h1.111l41.874-78.894.457-2.968-43.442-51.062Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1890_"
                            d="M37.344,407.491l-.316,2.058L54.921,524.908l43.2-51.683Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1889_"
                            d="M98.122,605.576v.611l92.859,42.436,2.655-43.047Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1888_"
                            d="M55.068,292.387l-.015.03-17.4,113.066,60.468-93.168Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1887_"
                            d="M97.07,605.576l.09.171.962.44v-.611Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1886_"
                            d="M98.122,473.225l-43.2,51.683.275,1.774L97.07,605.576h1.052Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1885_"
                            d="M489.871,312.315v-.041l-.051,0Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1884_"
                            d="M489.871,312.274V213.166h-113.7l113.648,99.1Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1883_"
                            d="M489.82,312.27l-113.648-99.1,20.652,91.978Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1882_"
                            d="M292.935,267.949l103.889,37.2-20.652-91.978L294,267.57Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1881_"
                            d="M396.824,305.144l45.892,99.243,47.155-92.072-.051-.045Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1880_"
                            d="M98.122,213.166v99.149L209.7,213.166Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1879_"
                            d="M98.122,605.576h95.514L98.122,473.225Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1878_"
                            d="M292.935,267.949,294,267.57l82.176-54.4H209.7l82.175,54.4Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1877_"
                            d="M292.935,267.949l-103.888,37.2-45.893,99.243,36.714,103.258,113.584,41.4L406,507.645l36.714-103.258-45.892-99.243Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1876_"
                            d="M489.871,605.576V472.652L392.234,605.576Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1875_"
                            d="M293.452,549.046l98.782,56.53L406,507.645Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1874_"
                            d="M179.868,507.645l13.768,97.931,99.816-56.53Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1873_"
                            d="M292.935,267.949l-1.061-.379-82.175-54.4-20.652,91.978Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1872_"
                            d="M392.234,605.576l97.637-132.924L406,507.645Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1871_"
                            d="M406,507.645l83.869-34.993-47.155-68.265Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1870_"
                            d="M442.716,404.387l47.155,68.265V312.315Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1869_"
                            d="M193.636,605.576h198.6l-98.782-56.53Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1868_"
                            d="M209.7,213.166,98.122,312.315l90.925-7.171Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1867_"
                            d="M189.047,305.144l-90.925,7.171,45.032,92.072Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1866_"
                            d="M143.154,404.387,98.122,473.225l81.746,34.42Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1865_"
                            d="M98.122,473.225l95.514,132.351-13.768-97.931Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1864_"
                            d="M98.122,312.315v160.91l45.032-68.838Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1863_"
                            d="M98.122,312.315,37.654,405.483l-.626,4.066,61.094,63.676Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <path
                        id="Trazado_1660"
                        data-name="Trazado 1660"
                        d="M550.868,409.05l-.077-.5L532.843,291.917l-.341-.643-41.623-78.422-.407-.186-.47-.215-94.6-43.23-.8-.1-99.443-11.775-1.145-.135-3.438.407-97.952,11.6-.882.4h0L98.254,212.348l-.7.319h0l-.407.186L55.2,291.888l-.016.03-17.4,113.066-.626,4.066L55.05,524.408l.275,1.774L97.2,605.076l.09.17.962.44,92.859,42.436,1.655.756,101.39,12.006,100.913-11.95.477-.057L490,605.712l1.021-.467.09-.17,41.874-78.894.457-2.968L550.893,409.8Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
