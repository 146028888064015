import { Icon } from "@chakra-ui/react";
import React from "react";

export const AsscherShapeIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 502.099 483.695" fill={color} height={'auto'} width={'100%'}>
        <g
            id="Diamonds_Asscher_Outlined"
            data-name="Diamonds_Asscher Outlined"
            transform="translate(-46.698 -183.992)"
        >
            <g id="Diamonds" transform="translate(48.698 185.992)">
                <g id="Grupo_38" data-name="Grupo 38">
                    <g id="XMLID_1960_">
                        <path
                            id="XMLID_1985_"
                            d="M210.668,223.58,193.4,187.243,48.7,288.777v.018l41.024,20.748Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1984_"
                            d="M89.341,310.2v-.381l.381-.271L48.7,288.8V562.851l40.643-21.177Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1983_"
                            d="M505.093,309.216l40.969-20.8L400.752,186.455h-.16l-16.508,36.753Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1982_"
                            d="M211.358,223.09H383.917l.167.118,16.508-36.753H194.524l-1.124.788,17.268,36.337Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1981_"
                            d="M546.577,289.227v-.45l-.515-.361-40.969,20.8.842.6V542.253l40.642,21.259V289.227Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1980_"
                            d="M89.341,542.935v-1.261L48.7,562.851v.977l145.6,102.161,17.02-36.362Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1979_"
                            d="M505.935,542.554v.381L383.917,629.659h-.037l16.46,36.491h.412L546.577,563.828v-.316l-40.642-21.259Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1978_"
                            d="M211.358,629.659l-.044-.032-17.02,36.362.23.161H400.34l-16.46-36.491Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1977_"
                            d="M124.567,327.166,89.722,309.543l-.381.271v231.86l35.226-18.356Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1976_"
                            d="M124.567,327.078v-.323l100.537-72.8L210.668,223.58,89.722,309.543l34.845,17.623Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1975_"
                            d="M225.95,253.346H369.326l.922.668,13.836-30.806-.167-.118H211.358l-.69.49L225.1,253.959Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1974_"
                            d="M225.95,597.495,124.567,524.086v-.768L89.341,541.674v1.261l121.973,86.692,15.041-32.132Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1973_"
                            d="M470.708,326.755V523.826l35.227,18.427V309.814l-.842-.6-34.452,17.491Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1972_"
                            d="M370.248,254.014l100.393,72.693,34.452-17.491L384.084,223.208Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1971_"
                            d="M470.708,524.086,369.361,597.47l14.519,32.189h.037l122.018-86.724v-.682l-35.227-18.427Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1970_"
                            d="M369.326,597.495H226.355l-15.041,32.132.044.032H383.88L369.361,597.47Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1969_"
                            d="M238.457,570.736l-83.7-61.617v-1.534l-30.195,15.733v.768L225.95,597.495h.4l12.526-26.759Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1968_"
                            d="M356.819,570.736H238.881l-12.526,26.759H369.326l.035-.025-12.18-27Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1967_"
                            d="M238.39,281.918,225.1,253.959l-100.537,72.8v.411l31.04,15.7Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1966_"
                            d="M154.762,343.757v-.271l.845-.622-31.04-15.7V523.318l30.195-15.733Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1965_"
                            d="M439.32,342.608l31.321-15.9L370.248,254.014l-12.739,28.363Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1964_"
                            d="M225.95,253.346l-.846.613,13.286,27.959.067-.049H356.819l.69.508,12.739-28.363-.922-.668Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1963_"
                            d="M440.514,508.848v.271l-83.333,61.35,12.18,27,101.347-73.384v-.26l-30.194-15.794Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1962_"
                            d="M440.514,343.486V508.032l30.194,15.794V326.755l-.067-.048-31.321,15.9Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1961_"
                            d="M155.607,342.864l-.845.622v164.1h0v1.534l83.7,61.617H356.819l.362-.267h0l83.333-61.35v-1.087h0V343.486l-1.194-.878h0l-81.811-60.231h0l-.69-.508H238.457l-.067.049h0Z"
                            transform="translate(-48.698 -186.455)"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <path
                        id="Trazado_1659"
                        data-name="Trazado 1659"
                        d="M546.282,288.416,400.972,186.455H194.744l-1.124.788L48.919,288.777V563.828l145.6,102.161.23.161H400.972L546.8,563.828V288.777Z"
                        transform="translate(-48.698 -186.455)"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
