import {
  LOGIN,
  SIGNUP,
  LOGIN_PAGE_UNLOADED,
  SIGNUP_PAGE_UNLOADED,
  ASYNC_START,
  MARKET_USER_SIGN_UP_PAGE__UPDATE_FIELD,
  MARKET_SIGNUP,
  UPDATE_FIELD_SIGN_UP_TYPES
} from "../constants/actionTypes";

var _ = require("lodash");

export default (state = { marketUser: {} }, action) => {
  switch (action.type) {
    case LOGIN:
    case SIGNUP:
      if (!action.error) {
        return {
          ...state,
          inProgress: false,
          traderState: true
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null
      };
    case MARKET_SIGNUP:
      return {
        ...state,
        inProgress: false,
        marketAuthErrors: action.error ? action.payload.errors : null
      };
    case UPDATE_FIELD_SIGN_UP_TYPES:
      return { ...state, [action.key]: action.value };
    case LOGIN_PAGE_UNLOADED:
    case SIGNUP_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === SIGNUP) {
        return { ...state, inProgress: true };
      }
      break;
    case MARKET_USER_SIGN_UP_PAGE__UPDATE_FIELD:
      let newState = { ...state };
      return _.set(newState, action.key, action.value);
    default:
      return state;
  }
  return state;
};
