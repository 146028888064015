import gql from "graphql-tag";

const CREATE_VENDOR_ORGANIZATION_INQUIRY = gql`
  mutation CreateVendorOrganizationInquiry(
    $organization: VendorOrganizationInput!
    $email: Email!
    $person: PersonInput!
    $phoneNumber: PhoneNumber!
    $vendorTypes: [VendorOrganizationTypeInput]!
  ) {
    createVendorOrganizationInquiry(
      email: $email
      person: $person
      organization: $organization
      vendorTypes: $vendorTypes
      phoneNumber: $phoneNumber
    ) {
      id
      email
      organization {
        legalName
      }
    }
  }
`;

export { CREATE_VENDOR_ORGANIZATION_INQUIRY };
