import React from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
    Container,
    Stack,
    Text,
    useColorModeValue,
} from "../Primatives";

const RenderItem = ({ mode, item }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    if (mode === "accordian") {
        return (
            <AccordionItem>
                <AccordionButton
                    bg={"gray.300"}
                    _hover={{
                        bg: "gray.200",
                    }}
                    _expanded={{ bg: "gold-color.400", color: "white" }}
                >
                    <Heading as="h6" flex="1" textAlign="left" size="sm">
                        {item.button}
                    </Heading>
                    <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={4}>{item.panel}</AccordionPanel>
            </AccordionItem>
        );
    } else if (mode === "buttons-with-modal") {
        return (
            <Box>
                <Button w="100%" onClick={onOpen}>
                    {item.button}
                </Button>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{item.button}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>{item.panel}</ModalBody>
                    </ModalContent>
                </Modal>
            </Box>
        );
    }
};

export const AccordianOrButtonsWithModal = ({
    mode,
    items,
    maxW = "4xl",
    allowToggle = true,
}) => {
    if (mode === "accordian") {
        return (
            <Container maxW={maxW}>
                <Accordion allowToggle={allowToggle}>
                    {items.map((item) => (
                        <RenderItem item={item} mode={mode} />
                    ))}
                </Accordion>
            </Container>
        );
    } else if (mode === "buttons-with-modal") {
        return (
            <VStack align="left">
                {items.map((item) => (
                    <RenderItem item={item} mode={mode} />
                ))}
            </VStack>
        );
    }
};

AccordianOrButtonsWithModal.propTypes = {};

AccordianOrButtonsWithModal.defaultProps = {};
