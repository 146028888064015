import React, { useContext } from "react";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Grid,
    GridItem,
} from "../../Primatives";
import { BreadCrumbToolbarInnerBody } from "../BreadCrumbToolbarInnerBody";
import { CloudManagementOSVariant } from "../Context";

export const HeaderBodyWithDrawers = ({
    actionMenuButtons,
    breadcrumbs,
    tools,
    meta,
    drawer,
    body,
    isDrawerOpen,
    onDrawerClose,
    btnRef,
}) => {
    let cloudManagementOSVariant = useContext(CloudManagementOSVariant);
    let gridLayout = {};
    if (cloudManagementOSVariant === "collapse") {
        gridLayout.templateRows = "2.5rem 1fr";
        gridLayout.templateColumns = "1fr";
    } else {
        gridLayout.templateRows = "2.5rem 1fr";
        gridLayout.templateColumns = "13rem 1px 1fr";
    }

    return (
        <BreadCrumbToolbarInnerBody
            actionMenuButtons={actionMenuButtons}
            tools={tools}
            meta={meta}
            breadcrumbs={breadcrumbs}
            body={
                <Grid
                    templateColumns={gridLayout.templateColumns}
                    h="100%"
                    maxH="100%"
                >
                    {cloudManagementOSVariant !== "collapse" && (
                        <>
                            <GridItem h="100%" maxH="100%" overflow="hidden">
                                <Grid
                                    templateRows="auto-fit max-content"
                                    h="100%"
                                    maxH="100%"
                                >
                                    <GridItem overflow="scroll">
                                        {drawer?.body}
                                    </GridItem>
                                    <GridItem>{drawer?.footer}</GridItem>
                                </Grid>
                            </GridItem>
                            <GridItem bg="gray.400"></GridItem>
                        </>
                    )}
                    <GridItem
                        w="100%"
                        h="100%"
                        overflowY="scroll"
                        overflowX={"hidden"}
                    >
                        <Box w="100%" h="100%">
                            {body}
                        </Box>
                    </GridItem>
                    <Drawer
                        isOpen={isDrawerOpen}
                        onClose={onDrawerClose}
                        finalFocusRef={btnRef}
                        placement="left"
                    >
                        <DrawerOverlay>
                            <DrawerContent>
                                <DrawerCloseButton />
                                <DrawerHeader>
                                    {getConstToLanguage("SEARCH_FILTER")}
                                </DrawerHeader>
                                <DrawerBody>{drawer?.body}</DrawerBody>
                                <DrawerFooter>
                                    <Box w="100%" h="100%">
                                        {drawer?.footer}
                                    </Box>
                                </DrawerFooter>
                            </DrawerContent>
                        </DrawerOverlay>
                    </Drawer>
                </Grid>
            }
        />
    );
};
