import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { useForm } from "react-hook-form";
import {
    Box,
    Heading,
    Button,
    VStack,
    MutationResult,
    Input,
} from "../../../../stories/Primatives";

import {
    GQL_M_CHECK_STATUS_OF_CURRENT_SESSION_FOR_BILL_DOT_COM,
    GQL_M_STAGE_NEW_SESSION_FOR_BILL_DOT_COM,
    GQL_M_AUTHENTICATE_BILL_DOT_COM_STAGING_SESSION_ID,
    GQL_M_FORCE_UPDATE_OF_ALL_GEM_LISTING_RELATIVE_PRICE_IN_SALES_PIPELINE,
    GQL_M_FORCE_UPDATE_OF_ALL_RELATIVE_PRICING_FOR_GEM_SPECS,
} from "../../graph/mutations";

const AuthenicateSession = () => {
    const { register, handleSubmit } = useForm({
        shouldUseNativeValidation: true,
    });

    const [
        authenticateBillDotComStagingSessionId,
        { data, loading, error },
    ] = useMutation(GQL_M_AUTHENTICATE_BILL_DOT_COM_STAGING_SESSION_ID);

    const onSubmit = async (data) => {
        console.log("MFFAAAAAAA", data);
        authenticateBillDotComStagingSessionId({
            variables: { mfaToken: data.mfaToken },
        });
    };

    let [] = useState("");
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Heading>{getConstToLanguage("AUTHENICATE_MFA")}</Heading>
            <MutationResult
                mutationResult={data?.authenticateBillDotComStagingSessionId}
            />
            <Input {...register("mfaToken")} />
            <Button type="submit">{getConstToLanguage("SUBMIT")}</Button>
        </form>
    );
};

const CheckCurrentlyLiveSession = () => {
    const [
        checkStatusOfCurrentSessionForBillDotCom,
        { data, loading, error },
    ] = useMutation(GQL_M_CHECK_STATUS_OF_CURRENT_SESSION_FOR_BILL_DOT_COM, {});

    return (
        <Box>
            <MutationResult
                mutationResult={data?.checkStatusOfCurrentSessionForBillDotCom}
            />

            <Button
                variant="cloud-management-os.primary"
                onClick={(e) => {
                    e.preventDefault();

                    checkStatusOfCurrentSessionForBillDotCom();
                }}
            >
                {getConstToLanguage("CHECK_CURRENTLY_LIVE_SESSION")}
            </Button>
        </Box>
    );
};

const StageANewAuthenticatedSession = () => {
    const [
        stageNewSessionForBillDotCom,
        { data, loading, error },
    ] = useMutation(GQL_M_STAGE_NEW_SESSION_FOR_BILL_DOT_COM, {});

    if (data?.stageNewSessionForBillDotCom.status === "SUCCESS") {
        return <AuthenicateSession />;
    }

    return (
        <Box>
            <MutationResult
                mutationResult={data?.stageNewSessionForBillDotCom}
            />

            <Button
                variant="cloud-management-os.primary"
                onClick={(e) => {
                    e.preventDefault();

                    stageNewSessionForBillDotCom();
                }}
            >
                {getConstToLanguage("STAGE_A_NEW_SESSION")}
            </Button>
        </Box>
    );
};

const BillDotComMaintanence = () => {
    return (
        <Box>
            <Heading>{getConstToLanguage("BILL_COM_MAINTENANCE")}</Heading>
            <VStack align="right">
                <StageANewAuthenticatedSession />
                <CheckCurrentlyLiveSession />
            </VStack>
        </Box>
    );
};

const ForceUpdateOfAllGemListingRelativePriceInSalesPipeline = () => {
    const [
        forceUpdateOfAllGemListingRelativePriceInSalesPipeline,
        { data, loading, error },
    ] = useMutation(
        GQL_M_FORCE_UPDATE_OF_ALL_GEM_LISTING_RELATIVE_PRICE_IN_SALES_PIPELINE,
        {}
    );
    return (
        <Box>
            <Box>
                <Button
                    variant="cloud-management-os.primary"
                    onClick={(e) => {
                        e.preventDefault();

                        forceUpdateOfAllGemListingRelativePriceInSalesPipeline();
                    }}
                >
                    {getConstToLanguage(
                        "FORCE_UPDATE_OF_ALL_GEM_LISTING_RELATIVE_PRICE_IN_SALES_PIPELINE"
                    )}
                </Button>
            </Box>
        </Box>
    );
};

const ForceUpdateOfAllRelativePricingForGemSpecs = () => {
    const [
        forceUpdateOfAllRelativePricingForGemSpecs,
        { data, loading, error },
    ] = useMutation(
        GQL_M_FORCE_UPDATE_OF_ALL_RELATIVE_PRICING_FOR_GEM_SPECS,
        {}
    );
    return (
        <Box>
            <Box>
                <Button
                    variant="cloud-management-os.primary"
                    onClick={(e) => {
                        e.preventDefault();

                        forceUpdateOfAllRelativePricingForGemSpecs();
                    }}
                >
                    {getConstToLanguage(
                        "FORCE_UPDATE_OF_ALL_RELATIVE_PRICING_FOR_GEM_SPECS"
                    )}
                </Button>
            </Box>
        </Box>
    );
};

const SystemMaintanence = () => {
    return (
        <Box>
            <Heading>{getConstToLanguage("SYSTEM")}</Heading>
            <ForceUpdateOfAllGemListingRelativePriceInSalesPipeline />
            <ForceUpdateOfAllRelativePricingForGemSpecs />
            <BillDotComMaintanence />
        </Box>
    );
};

export default SystemMaintanence;
