import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AspectRatio,
    Box,
    Center,
    Checkbox,
    Flex,
    Grid,
    GridItem,
    Heading,
    HStack,
    Text,
    useBreakpointValue,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import {
    getShapeCategoryIcon,
    Video360Icon,
} from "../../../components/IconsV2";
import { LIST_OF_SHAPES_IN_ORDER } from "../../../constants/businessConstants";
import { getConstToLanguage } from "../../CloudManagementOS/ConstToLanguageMapping";
import { Button } from "../../Primatives";
import {
    CaratSlider,
    ClarityRange,
    ColorRange,
    CutRange,
    PriceSlider,
} from "../../Primatives/GemSearchFilterComponents";
/**
 * Primary UI component for user interaction
 */

const SearchFilterShape = ({ toggleShapeSelection, newSearchParam }) => {
    return (
        <Grid templateColumns="repeat(3, minmax(0, 1fr))">
            {LIST_OF_SHAPES_IN_ORDER.map((shape) => {
                return (
                    <Box>
                        <ShapeFilterButton
                            shape={shape}
                            toggleShapeSelection={toggleShapeSelection}
                            selectedShapes={newSearchParam?.shapes}
                        />
                        <Center>{getConstToLanguage(shape)}</Center>
                    </Box>
                );
            })}
        </Grid>
    );
};

const SearchFilterAccordianItem = ({ sectionIdentifier, sectionContent }) => {
    return (
        <AccordionItem>
            <AccordionButton>
                <Box>{sectionIdentifier}</Box>
            </AccordionButton>
            <AccordionPanel bg="bg.200" paddingTop={4}>
                {sectionContent}
            </AccordionPanel>
        </AccordionItem>
    );
};

const SearchFilterForSmallScreenSize = ({
    newSearchParam,
    toggleShapeSelection,
    setClarityRange,
    setColorRange,
    setPriceRange,
    setCaratRange,
    variant,
    setCutRange,
}) => {
    return (
        <Box bg="brand.500" p={2} w="100%">
            <Accordion w="100%" bg="pale-peach" paddingBottom={2}>
                <SearchFilterAccordianItem
                    sectionIdentifier="SHAPE"
                    shapes={newSearchParam?.shapes}
                    variant={variant}
                    sectionContent={
                        <SearchFilterShape
                            newSearchParam={newSearchParam}
                            toggleShapeSelection={toggleShapeSelection}
                        />
                    }
                />
                <SearchFilterAccordianItem
                    sectionIdentifier="PRICE"
                    variant={variant}
                    sectionContent={
                        <PriceSlider
                            onChange={setPriceRange}
                            value={newSearchParam?.priceRange}
                        />
                    }
                />
                <SearchFilterAccordianItem
                    sectionIdentifier="CARAT"
                    variant={variant}
                    sectionContent={
                        <CaratSlider
                            value={newSearchParam?.caratRange}
                            onChange={setCaratRange}
                        />
                    }
                />
                <SearchFilterAccordianItem
                    sectionIdentifier="CUT"
                    variant={variant}
                    sectionContent={
                        <CutRange
                            cutRange={newSearchParam?.cutRange}
                            onChange={setCutRange}
                        />
                    }
                />
                <SearchFilterAccordianItem
                    sectionIdentifier="CLARITY"
                    variant={variant}
                    sectionContent={
                        <ClarityRange
                            clarityRange={newSearchParam?.clarityRange}
                            onChange={setClarityRange}
                        />
                    }
                />
                <SearchFilterAccordianItem
                    sectionIdentifier="COLOR"
                    variant={variant}
                    sectionContent={
                        <ColorRange
                            colorRange={newSearchParam?.colorRange}
                            onChange={setColorRange}
                        />
                    }
                />
            </Accordion>
        </Box>
    );
};

const SearchFilterItem = ({ heading, body }) => (
    <Grid>
        <GridItem>
            {" "}
            <Heading size="md" color="off-black" marginBottom={4}>
                {" "}
                {heading}
            </Heading>{" "}
        </GridItem>
        <GridItem alignSelf={"center"}>
            {" "}
            <Box> {body} </Box>{" "}
        </GridItem>
    </Grid>
);

const ShapeFilterButton = ({
    shape,
    selectedShapes,
    toggleShapeSelection,
    variant,
}) => {
    return (
        <Box
            onClick={(e) => toggleShapeSelection(shape)}
            border="1px"
            borderRadius={4}
            borderColor="off-black"
            padding={variant === "mobile" ? 4 : 2}
            bg={
                selectedShapes && selectedShapes.includes(shape)
                    ? "brand.200"
                    : "white"
            }
            _hover={{ bg: "pale-peach" }}
        >
            <Center>
                <AspectRatio ratio={1} w="100%">
                    <Center w="100%">
                        {getShapeCategoryIcon(shape)({ height: "100%" })}
                    </Center>
                </AspectRatio>
            </Center>
        </Box>
    );
};

const SearchFilterForLargeScreenSize = ({
    newSearchParam,
    toggleShapeSelection,
    setClarityRange,
    setColorRange,
    setPriceRange,
    setCaratRange,
    variant,
    setCutRange,
    setFilterGemListingsWith360VideoOnly,
}) => {
    const shapesNumber = LIST_OF_SHAPES_IN_ORDER.length;
    const columns =
        variant === "tablet" ? Math.round(shapesNumber / 2) : shapesNumber;

    return (
        <Grid w="100%" gap={8}>
            <Grid
                templateColumns={`repeat(${columns}, minmax(0, 1fr))`}
                gap={2}
            >
                {LIST_OF_SHAPES_IN_ORDER.map((shape) => {
                    return (
                        <Flex key={shape} direction="column">
                            <Box paddingBottom={3}>
                                <ShapeFilterButton
                                    shape={shape}
                                    toggleShapeSelection={toggleShapeSelection}
                                    selectedShapes={newSearchParam?.shapes}
                                />
                            </Box>
                            <Center>
                                <Text fontSize={{ base: "0.5rem", lg: "xs" }}>
                                    {getConstToLanguage(shape)}
                                </Text>
                            </Center>
                        </Flex>
                    );
                })}
            </Grid>
            <Grid templateColumns="repeat(3, minmax(0, 1fr))" gap={12}>
                <SearchFilterItem
                    heading={getConstToLanguage("PRICE")}
                    body={
                        <PriceSlider
                            value={newSearchParam?.priceRange}
                            onChange={setPriceRange}
                        />
                    }
                />
                <SearchFilterItem
                    heading={getConstToLanguage("CARAT")}
                    body={
                        <CaratSlider
                            value={newSearchParam?.caratRange}
                            onChange={setCaratRange}
                        />
                    }
                />
                <SearchFilterItem
                    heading={getConstToLanguage("CUT")}
                    body={
                        <CutRange
                            cutRange={newSearchParam?.cutRange}
                            onChange={setCutRange}
                        />
                    }
                />
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={12}>
                <SearchFilterItem
                    heading={getConstToLanguage("COLOR")}
                    body={
                        <ColorRange
                            colorRange={newSearchParam?.colorRange}
                            onChange={setColorRange}
                        />
                    }
                />
                <SearchFilterItem
                    heading={getConstToLanguage("CLARITY")}
                    body={
                        <ClarityRange
                            clarityRange={newSearchParam?.clarityRange}
                            onChange={setClarityRange}
                        />
                    }
                />
            </Grid>
            {/* 
            Put back later when we have 360 video
            <Center paddingTop={8}>
                <FilterWith360VideoCheckbox
                    setFilterGemListingsWith360VideoOnly={
                        setFilterGemListingsWith360VideoOnly
                    }
                />
            </Center> */}
        </Grid>
    );
};

const SearchFilterClarity = (props) => {
    return <ClarityRange />;
};

const SearchFilter = ({
    newSearchParam,
    toggleShapeSelection,
    setClarityRange,
    setColorRange,
    setPriceRange,
    setCaratRange,
    resetSearchParam,
    searchWithNewSearchParam,
    variant,
    setCutRange,
    setFilterGemListingsWith360VideoOnly,
}) => {
    return (
        <Fragment>
            {variant === "mobile" ? (
                <SearchFilterForSmallScreenSize
                    variant={variant}
                    newSearchParam={newSearchParam}
                    resetSearchParam={resetSearchParam}
                    searchWithNewSearchParam={searchWithNewSearchParam}
                    toggleShapeSelection={toggleShapeSelection}
                    setClarityRange={setClarityRange}
                    setColorRange={setColorRange}
                    setPriceRange={setPriceRange}
                    setCaratRange={setCaratRange}
                    setCutRange={setCutRange}
                    setFilterGemListingsWith360VideoOnly={
                        setFilterGemListingsWith360VideoOnly
                    }
                />
            ) : (
                <SearchFilterForLargeScreenSize
                    variant={variant}
                    newSearchParam={newSearchParam}
                    resetSearchParam={resetSearchParam}
                    searchWithNewSearchParam={searchWithNewSearchParam}
                    toggleShapeSelection={toggleShapeSelection}
                    setClarityRange={setClarityRange}
                    setColorRange={setColorRange}
                    setPriceRange={setPriceRange}
                    setCaratRange={setCaratRange}
                    setCutRange={setCutRange}
                    setFilterGemListingsWith360VideoOnly={
                        setFilterGemListingsWith360VideoOnly
                    }
                />
            )}
        </Fragment>
    );
};

const FilterWith360VideoCheckbox = ({
    newSearchParam,
    setFilterGemListingsWith360VideoOnly,
}) => {
    return (
        <HStack spacing={4}>
            <Checkbox
                size="lg"
                colorScheme="yellow"
                isChecked={newSearchParam?.filterGemListingsWith360VideoOnly}
                onChange={(e) =>
                    setFilterGemListingsWith360VideoOnly(
                        !newSearchParam?.filterGemListingsWith360VideoOnly
                    )
                }
            ></Checkbox>
            <Box w="80px">
                <Video360Icon color="brand.500" />
            </Box>
        </HStack>
    );
};

export const SearchParam = ({
    newSearchParam,
    resetSearchParam,
    searchWithNewSearchParam,
    toggleShapeSelection,
    setClarityRange,
    setColorRange,
    setPriceRange,
    setCaratRange,
    setCutRange,
    setFilterGemListingsWith360VideoOnly,
}) => {
    const variant = useBreakpointValue({
        base: "mobile",
        sm: "tablet",
        lg: "full",
    });
    const {
        isOpen: isOpenSearchFilter,
        onOpen: onOpenSearchFilter,
        onClose: onCloseSearchFilter,
        onToggle: onToggleSearchFilter,
    } = useDisclosure();

    console.log("NEW SEARCHHHHHH", toggleShapeSelection);

    return (
        <Box>
            <VStack spacing={variant === "mobile" ? 4 : 10}>
                <VStack
                    spacing={4}
                    padding={variant === "mobile" ? "1rem 1.5rem" : "none"}
                >
                    <Center align="center">
                        <Heading size={variant === "mobile" ? "md" : "lg"}>
                            What type of diamond are you looking for?
                        </Heading>
                    </Center>
                    {variant === "mobile" ? (
                        <Button onClick={onToggleSearchFilter}>
                            {isOpenSearchFilter
                                ? getConstToLanguage("CLOSE_FILTERS")
                                : getConstToLanguage("OPEN_FILTERS")}
                        </Button>
                    ) : null}
                </VStack>

                <Box
                    w="100%"
                    hidden={variant === "mobile" ? !isOpenSearchFilter : false}
                >
                    <VStack spacing={variant === "mobile" ? 6 : 12}>
                        <SearchFilter
                            w="100%"
                            variant={variant}
                            newSearchParam={newSearchParam}
                            resetSearchParam={resetSearchParam}
                            searchWithNewSearchParam={searchWithNewSearchParam}
                            toggleShapeSelection={toggleShapeSelection}
                            setClarityRange={setClarityRange}
                            setColorRange={setColorRange}
                            setPriceRange={setPriceRange}
                            setCaratRange={setCaratRange}
                            setCutRange={setCutRange}
                            setFilterGemListingsWith360VideoOnly={
                                setFilterGemListingsWith360VideoOnly
                            }
                        />
                        <VStack spacing={variant === "mobile" ? 2 : 4}>
                            <Center>
                                <HStack spacing={2}>
                                    <Button
                                        variant="market.primary"
                                        size={{ base: "md", lg: "lg" }}
                                        p={{
                                            base: "0.1rem 1.5rem",
                                            lg: "0.94rem 4.56rem",
                                        }}
                                        onClick={(e) =>
                                            searchWithNewSearchParam(
                                                newSearchParam
                                            )
                                        }
                                    >
                                        {getConstToLanguage("SEARCH")}
                                    </Button>
                                    {variant === "mobile" && (
                                        <Button
                                            size={{ base: "md", lg: "lg" }}
                                            variant="market.secondary"
                                            p="0.1rem 1.5rem"
                                            onClick={onToggleSearchFilter}
                                        >
                                            {getConstToLanguage("CANCEL")}
                                        </Button>
                                    )}
                                </HStack>
                            </Center>
                            <Center>
                                <Box
                                    onClick={(e) => resetSearchParam()}
                                    textDecoration="underline"
                                    cursor="pointer"
                                >
                                    {getConstToLanguage("RESET_FILTERS")}
                                </Box>
                            </Center>
                        </VStack>
                    </VStack>
                </Box>
            </VStack>
        </Box>
    );
};

SearchParam.propTypes = {};

SearchParam.defaultProps = {};
