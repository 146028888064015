import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as React from "react";
import { IoArrowDown } from "react-icons/io5";

import { useQuery } from "@apollo/react-hooks";

import Query from "../../Apollo/Query";
import { SmartTable } from "../../SmartTable";
import { getConstToLanguage } from "../../ConstToLanguageMapping";

const UserRow = ({ node }) => {
  let fullName =
    node.vendor.person.givenName + " " + node.vendor.person.familyName;
  return (
    <Tr key={node.id}>
      <Td>
        <HStack spacing="3">
          {/* <Checkbox /> */}
          <Avatar name={fullName} boxSize="10" />
          <Box>
            <Text fontWeight="medium">{fullName}</Text>
          </Box>
        </HStack>
      </Td>
      <Td>
        <Badge
          size="sm"
          colorScheme={node?.state === "ACTIVE" ? "green" : "red"}
        >
          {node?.state}
        </Badge>
      </Td>
      <Td>
        <Text color="muted">{node?.vendor?.localAuth?.email}</Text>
      </Td>
      <Td>
        <Text color="muted">
          {node?.roles.map((role) => (
            <Badge>{role?.type}</Badge>
          ))}
        </Text>
      </Td>
      {/* <Td>
        <Text color="muted"></Text>
      </Td> */}
      <Td>
        <HStack spacing="1">
          {/* <IconButton
            icon={<FiTrash2 fontSize="1.25rem" />}
            variant="ghost"
            aria-label="Delete member"
          />
          <IconButton
            icon={<FiEdit2 fontSize="1.25rem" />}
            variant="ghost"
            aria-label="Edit member"
          /> */}
        </HStack>
      </Td>
    </Tr>
  );
};

const UserManagement = ({ gqlQuery }) => {
  const { loading, error, data } = useQuery(gqlQuery);

  return (
    <Query loading={loading} error={error}>
      <SmartTable
        title={getConstToLanguage("USER_MANAGEMENT")}
        w="100%"
        listItemFunc={(props) => <UserRow {...props} />}
        items={data && data.searchAssociatedVendors.edges}
      />
    </Query>
  );
};

export default UserManagement;
