import { useApolloClient, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, {
    useCallback,
    useContext,
    useMemo,
    useState,
    useEffect,
} from "react";

import qs from "qs";

import { useLocation, useHistory } from "react-router-dom";
import { GemListingItem } from "../../../stories/Market/GemListingItem/GemListingItem";
import { SearchParam } from "../../../stories/Market/GemListingSearch/SearchParam";
import { useCurrentlyReservedGemListings } from "../../UtilityComponents/hooks";
import { CurrentMarketUserContext } from "../../UtilityComponents/MarketOS/context";
import ProductSearch from "../../UtilityComponents/MarketOS/ProductSearch";
import { GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY } from "../graph/queries";
import { SearchGemListingsOnMarketGraphQLQuery } from "../../../api_gateway/carbonext/graph/queries/searchGemListingsOnMarket";
import { GQLFragment } from "../../../lib/GraphCallBuilder/index.js";
import { useTranslation } from "react-i18next";
import {
    LIST_OF_CLARITIES_IN_ORDER,
    LIST_OF_COLORS_IN_ORDER,
    LIST_OF_CUTS_IN_ORDER,
} from "../../../constants/businessConstants";
import rison from "rison";
import {
    Box,
    Button,
    VStack,
    Text,
    Container,
    SpinnerButton,
} from "../../../stories/Primatives";
import { CreateGemRequestGraphQLMutation } from "../../../api_gateway/carbonext/graph/mutations/createGemRequest";
import { useMutation } from "@apollo/client";
import DesignSystem from "../../../DesignSystem";

import { Footer } from "../../../stories/Marketing/Footer";
import { Video360Icon } from "../../IconsV2";

const GQL_Q_SEARCH_GEM_LISTINGS_ON_MARKET = gql`
    query SearchGemListingsOnMarket($searchParam: GemListingSearchParam) {
        searchGemListingsOnMarket(searchParam: $searchParam) {
            pageInfo {
                hasNextPage
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    createdAt
                    state
                    price
                    currentRelativeDiscountPercentage
                    vendorOrganization {
                        legalName
                    }
                    latestRelativePrice {
                        relativePrice
                    }
                    certifiedGem {
                        certification {
                            certificationId
                        }

                        specs {
                            carat
                            color
                            cut
                            shape
                            clarity
                        }
                    }
                }
            }
        }
    }
`;

const GenGemListingItem = (props) => {
    let currentMarketUser = useContext(CurrentMarketUserContext);
    return (
        <GemListingItem
            key={props.item.node.id}
            currentMarketUser={currentMarketUser}
            node={props.item.node}
            currentReservedData={props.currentlyReservedGemListings}
        />
    );
};

const ConvertSearchParamIntoGemRequest = ({ searchParam }) => {
    const { t } = useTranslation();
    const [createGemRequest, { data, loading, error }] = useMutation(
        gql`
            ${CreateGemRequestGraphQLMutation().build({
                requestedAttrs: [
                    new GQLFragment("result", ["message", "title"]),
                ],
            })}
        `
    );
    return (
        <Box bg={"gray.200"}>
            <Container maxW="4xl">
                <VStack p={8} w="100%" spacing={6}>
                    <Text>
                        {t(
                            "marketing:CREATE_GEM_REQUEST_FROM_SEARCH_RESULT_MSG"
                        )}
                    </Text>
                    <DesignSystem.Molecules.GraphQLResultResponseMolecule
                        result={data?.createGemRequest?.result}
                        error={error}
                    />
                    <SpinnerButton
                        loading={loading}
                        variant={"market.primary"}
                        onClick={() =>
                            createGemRequest({
                                variables: {
                                    input: {
                                        caratRange: searchParam.caratRange,
                                        priceRange: searchParam.priceRange,
                                        cutRange: searchParam.cutRange,
                                        clarityRange: searchParam.clarityRange,
                                        colorRange: searchParam.colorRange,
                                        shapes: searchParam.shapes,
                                    },
                                },
                            })
                        }
                    >
                        {t("START_CONCIERGE")}
                    </SpinnerButton>
                </VStack>
            </Container>
        </Box>
    );
};

const GEMLISTING_LIMIT = 100;

const GemListings = (props) => {
    let history = useHistory();
    const location = useLocation();

    let defaultSearchParam = useMemo(
        () =>
            location.search ? rison.decode(location.search.substring(1)) : {},
        [location.search]
    );

    console.log("DEFAULT SEARCH PARAM", defaultSearchParam);

    let [newSearchParam, setNewSearchParam] = useState(defaultSearchParam);

    const [searchParam, setSearchParam] = useState(defaultSearchParam);
    const [searchOptions, setSearchOptions] = useState({
        pagination: {
            page: 1,
            limit: GEMLISTING_LIMIT,
        },
    });

    useEffect(() => {
        setSearchParam(defaultSearchParam);
    }, [defaultSearchParam]);

    const { t } = useTranslation();

    const { loading, error, data, fetchMore } = useQuery(
        gql`
            ${new SearchGemListingsOnMarketGraphQLQuery().build({
                requestedAttrs: [
                    new GQLFragment("edges", [
                        new GQLFragment("node", [
                            "id",
                            "createdAt",
                            "state",
                            "mainImage",
                            "videoLink",
                            "price",
                            "currentRelativeDiscountPercentage",
                            new GQLFragment("vendorOrganization", [
                                "legalName",
                            ]),
                            new GQLFragment("latestRelativePrice", [
                                "relativePrice",
                            ]),
                            new GQLFragment("certifiedGem", [
                                new GQLFragment("certification", [
                                    "certificationId",
                                ]),
                                new GQLFragment("specs", [
                                    "carat",
                                    "color",
                                    "cut",
                                    "shape",
                                    "clarity",
                                ]),
                            ]),
                        ]),
                    ]),
                    "totalCount",

                    new GQLFragment("pageInfo", ["page"]),
                ],
            })}
        `,
        {
            variables: {
                searchParam: { filter: searchParam, options: searchOptions },
            },
        }
    );

    let { currentlyReservedGemListings } = useCurrentlyReservedGemListings(
        GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY
    );

    console.log("CURRENTTTLYYYS RESERVEDDDD", currentlyReservedGemListings);

    let searchResultItems = [];
    let totalCount = null;
    if (data) {
        searchResultItems = data.searchGemListingsOnMarket.edges;
        totalCount = data.searchGemListingsOnMarket.totalCount;
    }

    const setPriceRange = useCallback(
        (priceRange) => {
            setNewSearchParam({
                ...newSearchParam,
                priceRange,
            });
        },
        [newSearchParam]
    );

    const setCaratRange = useCallback(
        (caratRange) => {
            setNewSearchParam({
                ...newSearchParam,
                caratRange,
            });
        },
        [newSearchParam]
    );

    const setCutRange = useCallback(
        (cutRange) => {
            setNewSearchParam({
                ...newSearchParam,
                cutRange,
            });
        },
        [newSearchParam]
    );
    const setColorRange = useCallback(
        (colorRange) => {
            console.log(colorRange);
            setNewSearchParam({
                ...newSearchParam,
                colorRange,
            });
        },
        [newSearchParam]
    );

    const setClarityRange = useCallback(
        (clarityRange) => {
            console.log(clarityRange);
            setNewSearchParam({
                ...newSearchParam,
                clarityRange,
            });
        },
        [newSearchParam]
    );

    const setFilterGemListingsWith360VideoOnly = useCallback(
        (with360VideoOnly) => {
            setNewSearchParam({
                ...newSearchParam,
                with360VideoOnly,
            });
        },
        [newSearchParam]
    );

    const setOrderBy = useCallback(
        (fieldName, direction) => {
            setSearchOptions({
                ...searchOptions,
                orderBy: [
                    {
                        fieldName,
                        direction,
                    },
                ],
            });
        },
        [newSearchParam]
    );

    const toggleShapeSelection = useCallback(
        (shape) => {
            let updatedNewSearchParam = { ...newSearchParam };
            if (!updatedNewSearchParam.shapes) {
                updatedNewSearchParam.shapes = [];
            }

            updatedNewSearchParam.shapes = updatedNewSearchParam.shapes?.includes(
                shape
            )
                ? updatedNewSearchParam.shapes?.length === 1
                    ? null
                    : updatedNewSearchParam?.shapes?.filter(
                          (shapeInFilter) => shapeInFilter !== shape
                      )
                : [...updatedNewSearchParam.shapes, shape];

            setNewSearchParam(updatedNewSearchParam);
        },
        [newSearchParam, defaultSearchParam]
    );

    const searchWithNewSearchParam = useCallback(() => {
        let convertedNewSearchParam = {
            ...newSearchParam,
        };

        if (newSearchParam?.caratRange) {
            convertedNewSearchParam.caratRange = newSearchParam?.caratRange?.map(
                (carat) => parseFloat(carat)
            );
        }

        const urlSearchParams = rison.encode(convertedNewSearchParam);
        history.push(location.pathname + "?" + urlSearchParams);
    }, [newSearchParam]);

    const resetSearchParam = useCallback(() => {
        history.push(location.pathname);
        setNewSearchParam(defaultSearchParam);
    }, []);

    useEffect(() => {
        function handleScroll() {
            const {
                scrollTop,
                scrollHeight,
                clientHeight,
            } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight) {
                // User has scrolled to the bottom of the page
                // Call your function here
                fetchMoreOfSearch();
            }
        }
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const fetchMoreOfSearch = (e) => {
        fetchMore({
            variables: {
                searchParam: {
                    filter: searchParam,
                    options: {
                        pagination: {
                            page:
                                data?.searchGemListingsOnMarket?.pageInfo
                                    ?.page + 1,
                            limit: 100,
                        },
                    },
                },
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges =
                    fetchMoreResult.searchGemListingsOnMarket.edges;
                const pageInfo =
                    fetchMoreResult.searchGemListingsOnMarket.pageInfo;
                return newEdges.length
                    ? {
                          // Put the new comments at the end of the list and update `pageInfo`
                          // so we have the new `endCursor` and `hasNextPage` values
                          searchGemListingsOnMarket: {
                              __typename:
                                  previousResult.searchGemListingsOnMarket
                                      .__typename,
                              edges: [
                                  ...previousResult.searchGemListingsOnMarket
                                      .edges,
                                  ...newEdges,
                              ],
                              pageInfo,
                          },
                      }
                    : previousResult;
            },
        });
    };

    return (
        <>
            <ProductSearch
                searchParam={
                    <SearchParam
                        searchParam={searchParam}
                        newSearchParam={newSearchParam}
                        resetSearchParam={resetSearchParam}
                        searchWithNewSearchParam={searchWithNewSearchParam}
                        toggleShapeSelection={toggleShapeSelection}
                        setPriceRange={setPriceRange}
                        setCaratRange={setCaratRange}
                        setColorRange={setColorRange}
                        setClarityRange={setClarityRange}
                        setCutRange={setCutRange}
                        setFilterGemListingsWith360VideoOnly={
                            setFilterGemListingsWith360VideoOnly
                        }
                    />
                }
                setOrderBy={setOrderBy}
                searchResultItems={searchResultItems}
                loading={loading}
                itemFunc={GenGemListingItem}
                itemProps={useMemo(() => ({ currentlyReservedGemListings }), [
                    currentlyReservedGemListings,
                ])}
                totalCount={totalCount}
                searchSubtext={
                    "Choose a loose diamond or build your dream diamond ring. Filter by cut, color, clarity, carat, and shape to narrow down your search, or use our advanced filters."
                }
                bodyIfItemsFoundListIsEmpty={
                    <ConvertSearchParamIntoGemRequest
                        searchParam={searchParam}
                    />
                }
            />
            <Footer />
        </>
    );
};

export default GemListings;
