import { useMutation } from "@apollo/react-hooks";
import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { LOGIN_AS_VENDOR } from "../../../graph/mutations/user/vendor";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { AuthLogin } from "../../UtilityComponents/AuthLogin";

function VendorLogin() {
    const history = useHistory();

    let postAuthenticatedCallBack = (data) => {
        if (data) {
            localStorage.setItem("vendorToken", data.userAuth.token);
            history.push("/vendor/home");
        }
    };

    const [loginAsVendorMutation, { data, loading, error }] = useMutation(
        LOGIN_AS_VENDOR,
        {}
    );

    const callLoginAsVendorMutation = (variables) =>
        loginAsVendorMutation({ variables });

    return (
        <AuthLogin
            forgotPasswordLink="/vendor/reset-password"
            createAccountLink="/vendor/sign-up"
            heading={getConstToLanguage("VENDOR_LOGIN")}
            postAuthenticatedCallBack={postAuthenticatedCallBack}
            loginFunc={callLoginAsVendorMutation}
        />
    );
}

export default VendorLogin;
