import React from "react";
import { DividerImage } from "../Primatives/DividerImage";
import { ImageWithText } from "./ImageWithText";
import {
    Divider,
    Image,
    Text,
    Box,
    Heading,
    Center,
    VStack,
    SimpleGrid,
    Img,
    Grid,
    GridItem,
    Container,
} from "../Primatives";
import { TextWithReadMore } from "../../components/Market/TextWithReadMore";

/**
 * Primary UI component for user interaction
 */
export const NewWayToShop = ({ ...props }) => {
    return (
        <Box
            bgColor="grey.500"
            py={{ base: 10, lg: 20 }}
            px={{ base: 10, lg: 20 }}
        >
            <Center>
                <Divider variant={"gold"} w={44} />
            </Center>
            <Center py={10}>
                <Text
                    as={"h2"}
                    fontSize={{ base: "3xl", md: "4xl", xl: "4xl" }}
                    lineHeight={"shorter"}
                    fontFamily="QuicheText, sans-serif"
                    textTransform={"uppercase"}
                    textAlign={"center"}
                >
                    The new way to shop for your engagement ring
                </Text>
            </Center>
            <Box>
                <Grid
                    templateRows={{
                        base: "repeat(1, 1fr)",
                        lg: "repeat(2, 1fr)",
                    }}
                    templateColumns={{
                        base: "repeat(1, 1fr)",
                        lg: "repeat(3, 1fr)",
                    }}
                    gap={10}
                >
                    <GridItem
                        rowSpan={2}
                        bgSize="cover"
                        bgImage="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/alekon-pictures-yj4kwA4h_Ms.png"
                        bgPos={{
                            sm: "50% -239px",
                            md: "50% -350px",
                            lg: "50% 50%",
                        }}
                    >
                        <Container maxW="2xl">
                            <Box
                                objectPosition={{
                                    sm: "50% -239px",
                                    md: "50% -350px",
                                    lg: "50% 50%",
                                }}
                                h={{ sm: "48", lg: "100%" }}
                                // w={"100%"}

                                alt="Ring"
                            />
                        </Container>
                    </GridItem>
                    <GridItem pt={8}>
                        <TextWithReadMore title="We’re deliberately different">
                            Carbonext is able to offer the most competitively
                            priced diamonds in the market and across all the
                            other diamond platforms. All of the diamonds found
                            in our marketplace have gone through a verification
                            process to ensure they look as good in person as
                            they do on paper. Our number one goal is to be
                            completely transparent.
                        </TextWithReadMore>
                    </GridItem>
                    <GridItem pt={8}>
                        <TextWithReadMore title="Diamonds sourced by our trusted US traders">
                            All of our available diamonds are located within the
                            US and readily available to ship. There is no lag
                            time for diamonds to have to clear customs or any
                            possible international freight delays.
                        </TextWithReadMore>
                    </GridItem>
                    <GridItem>
                        <TextWithReadMore title="GIA certified">
                            Every single one of the diamonds listed on our
                            platform have gone through the Gemological Institute
                            of America diamond grading process. The GIA
                            certification is a globally recognized
                            certification. Each and every diamond will be
                            delivered with their respective GIA report.{" "}
                        </TextWithReadMore>
                    </GridItem>
                    <GridItem>
                        <TextWithReadMore title="Our Mission">
                            Carbonext is able to provide you with the
                            personalized and luxury experience of building your
                            engagement ring. Our transparent process allows the
                            customer to feel at ease choosing their own diamond
                            and their own jeweler based on their needs, wants
                            and budget. Since we do not own any of the inventory
                            we do not need to push any one diamond or any one
                            jeweler. After finding many inefficiencies across
                            all the online diamond platforms, this platform was
                            created for the purpose to revolutionize the online
                            diamond buying process.{" "}
                        </TextWithReadMore>
                    </GridItem>
                </Grid>
            </Box>
        </Box>
    );
};

NewWayToShop.propTypes = {};

NewWayToShop.defaultProps = {};
