import React, { useState } from "react";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import ReactPlayer from "react-player";
import { Header } from "../../../../stories/CloudManagementOS";
import { HeaderBodyShell } from "../../../../stories/CloudManagementOS/shells"

export const VendorHome = (props) => {
  let [showControls, setShowControls] = useState(false);

  return <HeaderBodyShell
    header={<Header title={getConstToLanguage("HOME")} linkTo={'#'}
    />}
    body={<div className="vendor-home">
      <div className="vendor-home__annoucements">
        <h2> Welcome to Beta Version of the carbonext platform</h2>
        We are still in the works in building out this platform. If you have
        any questions, or need any help, please contact{" "}
        <a href="mailto: help@carbonext.io">help@carbonext.io</a>.
      </div>

      <div>
        <h2>Tutorials</h2>
      </div>
      <div
        className="vendor-home__tutorials"
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        <ReactPlayer
          url="https://carbonext-assests.nyc3.digitaloceanspaces.com/upload-gem-inventory-csv.mp4"
          controls={showControls}
        />
      </div>
    </div>}
  />
}

