import { useQuery } from "@apollo/client";
import { GetSalesOrderGraphQLQuery } from "../../../../carbonext/graph/queries/getSalesOrder";
import gql from "graphql-tag";

export function useGetSalesOrder(requestedAttrs, options) {
    let getSalesOrderQueryBuilder = new GetSalesOrderGraphQLQuery();
    return useQuery(
        gql`
            ${getSalesOrderQueryBuilder.build({ requestedAttrs })}
        `,
        options
    );
}
