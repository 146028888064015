import {
  MARKET_USER_RESERVE_GEM,
  MARKET_USER_CANCEL_GEM_RESERVATION,
  MARKET_USER_CLOSE_RESERVE_GEM_VIEW,
  MARKET_GEM_RESERVATION_START_PAGE_LOADED
} from "../constants/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case MARKET_GEM_RESERVATION_START_PAGE_LOADED:
      return { ...state, reserveGemViewGem: action.payload[1].gemListing };
    case MARKET_USER_RESERVE_GEM:
      console.log(action.payload);

      if (action.payload.errors) {
        return {
          ...state,
          newReservationRespErrors: action.payload.errors
        };
      }
      return {
        ...state,
        newgemListingReservationResponse: action.payload
      };
    case MARKET_USER_CANCEL_GEM_RESERVATION:
      if (action.payload.errors) {
        return {
          ...state,
          gemListingReservationActivityRespErrors: action.payload.errors
        };
      }
      return {
        ...state,
        newgemListingReservationResponse: null,
        gemListingReservationActivityResp: action.payload
      };
    case MARKET_USER_CLOSE_RESERVE_GEM_VIEW:
      return {};

    default:
      return state;
  }
};
