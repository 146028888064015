import React, { useEffect } from "react";

import {
    Box,
    VStack,
    Heading,
    Center,
    Address,
    PhoneNumber,
    HStack,
    Email,
    Container,
    Text,
    Divider,
    Link,
    LoadingScreen,
} from "../../../../../stories/Primatives";
import { useTranslation } from "react-i18next";
import { useAddReferralCodeToCurrentSalesOrder } from "../../../../../api_gateway/hooks/carbonext/graph/mutations/addReferralCodeToCurrentSalesOrder";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useSubmitMutation from "../../../../../stories/CloudManagementOS/hooks/useSubmitMutation";
import { GQLFragment } from "../../../../../lib/GraphCallBuilder";
import { useGetSalesOrder } from "../../../../../api_gateway/hooks/carbonext/graph/queries/getSalesOrder";
import TwoPanelShell from "../../../../../stories/CloudManagementOS/shells/TwoPanelShell";
import Query from "../../../../../stories/CloudManagementOS/Apollo/Query";

import { Link as ReactLink } from "react-router-dom";
import { Logo } from "../../../../../stories/Branding/Icons/Logo";

import SalesOrderDetails from "../SalesOrderDetails";
import { useUserEvents } from "../../../../../stories/CloudManagementOS/hooks";

const SalesOrderCompleted = ({}) => {
    let { t } = useTranslation();
    const query = new URLSearchParams(window.location.search);

    let salesOrderId = query.get("salesOrderId");

    let { eventToHandle } = useUserEvents();

    const { loading, error, data, refetch } = useGetSalesOrder(
        [
            "state",
            new GQLFragment("items", [
                "id",
                "state",
                "price",
                new GQLFragment("certifiedGem", [
                    new GQLFragment("certification", ["certificationId"]),
                    new GQLFragment("specs", [
                        "shape",
                        "carat",
                        "clarity",
                        "cut",
                        "color",
                    ]),
                ]),
            ]),
            new GQLFragment("succeededSalesTransaction", [
                "state",
                "amountPaid",
            ]),
            new GQLFragment("pickUpLocation", [
                "name",
                new GQLFragment("address", [
                    "address1",
                    "address2",
                    "city",
                    "region",
                    "country",
                    "postalCode",
                ]),

                new GQLFragment("contact", ["email", "phoneNumber"]),
            ]),
        ],
        {
            variables: { salesOrderId },
        }
    );

    useEffect(() => {
        if (eventToHandle && eventToHandle.type === "sales-order.completed") {
            if (eventToHandle.data.id === salesOrderId) {
                refetch();
            }
        }
    }, [eventToHandle]);

    let salesOrder = data?.getSalesOrder;

    if (salesOrder?.state === "INCOMPLETE") {
        return (
            <LoadingScreen
                // marginTop="6.94rem"
                h="calc(100vh - 6.94rem)"
                logo={<Logo logoType="logoOnly" />}
                title={t("FINALIZING_SALES_ORDER")}
            />
        );
    }

    return (
        <Query loading={loading} error={error}>
            <TwoPanelShell
                h={"100vh"}
                breakpointValue="lg"
                variant="left-main"
                subViewProps={{ bg: "dark-gold.300" }}
                // subViewWidth={{ base: "35%", xl: "45%" }}
                panel1={
                    <Container maxW="4xl">
                        <VStack spacing={12} align="left" padding={20}>
                            <VStack spacing={4} align="left">
                                <Heading fontFamily="Montserrat">
                                    {t("SALES_ORDER_COMPLETED")}
                                </Heading>
                                <Heading
                                    fontFamily="Montserrat"
                                    size="md"
                                    as="h3"
                                >
                                    {t("ORDER")} #{salesOrderId}
                                </Heading>
                                <Text paddingLeft={4}>
                                    {t(
                                        "marketing:THANK_YOU_FOR_YOUR_PURCHASE_MSG"
                                    )}
                                </Text>
                                <Link
                                    as={ReactLink}
                                    to={`/contact-us?salesOrderId=${salesOrderId}`}
                                    color={"brand.500"}
                                >
                                    {t("CONTACT_US")}
                                </Link>
                            </VStack>

                            <Box>
                                <SalesOrderDetails
                                    salesOrder={data?.getSalesOrder}
                                />
                            </Box>
                        </VStack>
                    </Container>
                }
                panel2={
                    <Container maxW="4xl">
                        <VStack spacing={6} padding={20}>
                            <Heading fontFamily="Montserrat" color="white">
                                {t("PICKUP_LOCATION")}
                            </Heading>
                            <Box
                                px={{ base: "4", md: "6" }}
                                py={{ base: "5", md: "6" }}
                                bg="white"
                                borderRadius="lg"
                                boxShadow={"sm"}
                            >
                                <VStack padding={4} align={"left"} spacing="4">
                                    <Heading
                                        fontFamily="Montserrat"
                                        size="md"
                                        as="h4"
                                    >
                                        {t("ADDRESS")}
                                    </Heading>
                                    <Box paddingLeft={4}>
                                        <Address
                                            name={
                                                data?.getSalesOrder
                                                    ?.pickUpLocation?.name
                                            }
                                            address={
                                                data?.getSalesOrder
                                                    .pickUpLocation.address
                                            }
                                        />
                                    </Box>
                                </VStack>

                                <Divider />

                                <VStack align={"left"} padding={4} spacing="2">
                                    <Heading
                                        fontFamily="Montserrat"
                                        size="md"
                                        as="h4"
                                    >
                                        {t("CONTACT_INFO")}
                                    </Heading>
                                    <VStack align="left" paddingLeft={4}>
                                        <PhoneNumber
                                            fullNumber={
                                                data?.getSalesOrder
                                                    ?.pickUpLocation?.contact
                                                    ?.phoneNumber
                                            }
                                        />

                                        <Email
                                            email={
                                                data?.getSalesOrder
                                                    ?.pickUpLocation?.contact
                                                    ?.email
                                            }
                                        />
                                    </VStack>
                                </VStack>
                            </Box>

                            <Heading fontFamily="Montserrat" color="white">
                                {t("PICKUP_TIMES")}
                            </Heading>
                            <Box as={"address"}>
                                Monday through Friday 12-pm - 24pm
                            </Box>
                        </VStack>
                    </Container>
                }
            />
        </Query>
    );
};

export default SalesOrderCompleted;
