import {
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    StackProps,
    Text,
    FormErrorMessage,
    useBreakpointValue,
    Divider,
    SpinnerButton,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
} from "../../../stories/Primatives";
import * as React from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useSubmitMutation from "../../../stories/CloudManagementOS/hooks/useSubmitMutation";
import * as Yup from "yup";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { GraphQLRespErrorHandler } from "../../../stories/CloudManagementOS/Apollo/GraphQLRespErrorHandler";
import { CREATE_MARKET_USER } from "../../../graph/mutations/user/marketUser";
import "yup-phone";

const MarketUserSignUpFormSchema = Yup.object().shape({
    givenName: Yup.string()
        .required("First name is a required field.")
        .min(2, "First Name must be at least 2 characters."),
    familyName: Yup.string()
        .required("Last name is a required field.")
        .min(2, "Last Name must be at least 2 characters."),
    email: Yup.string()
        .required("Email is a required field.")
        .email()
        .min(2, "Email must be at least 2 characters."),
    password: Yup.string()
        .required("Password is a required field.")
        .min(8, "Password must be at least 8 characters.")
        .matches(/[A-Z]/, "Must have a capital letter.")
        .matches(
            /[!+=@#$%^&*,.;]/,
            "Must have a special charater [!+=@#$%^&*,.;]"
        )
        .matches(/[0-9]/, "Must have a number"),
    passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
});

const SignUpForm = ({ signUpFunc, ...props }) => {
    let {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: yupResolver(MarketUserSignUpFormSchema) });

    const [marketSignUpMutation] = useMutation(CREATE_MARKET_USER);

    const marketSignUp = React.useCallback(
        async (flattenedVariables) => {
            console.log(flattenedVariables);
            let constructedVariables = {
                email: flattenedVariables.email,
                phoneNumber: flattenedVariables.phoneNumber,
                organization: {
                    legalName: flattenedVariables.organizationName,
                    organizationType: "LLC",
                },
                marketUser: {
                    person: {
                        givenName: flattenedVariables.givenName,
                        familyName: flattenedVariables.familyName,
                    },
                },
                password: flattenedVariables.password,
                passwordConfirmation: flattenedVariables.passwordConfirmation,
            };

            return await marketSignUpMutation({
                variables: constructedVariables,
            });
        },
        [marketSignUpMutation]
    );

    let { onSubmit, loading, error, data } = useSubmitMutation(marketSignUp);

    if (data) {
        return (
            <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
            >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                    Account Created!
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                    Please check your email to verify your email.
                </AlertDescription>
            </Alert>
        );
    }
    return (
        <Stack spacing="8" {...props}>
            <Stack spacing="6">
                <Stack spacing="6">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <GraphQLRespErrorHandler error={error} />
                        <Stack spacing={"8"}>
                            <Stack spacing="6">
                                <HStack>
                                    <FormControl isInvalid={errors?.givenName}>
                                        <FormLabel>
                                            {getConstToLanguage("GIVEN_NAME")}
                                        </FormLabel>
                                        <Input
                                            id="given-name"
                                            {...register("givenName")}
                                        />
                                        <FormErrorMessage>
                                            {errors.givenName &&
                                                errors.givenName.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors?.familyName}>
                                        <FormLabel>
                                            {getConstToLanguage("FAMILY_NAME")}
                                        </FormLabel>
                                        <Input
                                            id="family-name"
                                            {...register("familyName")}
                                        />
                                        <FormErrorMessage>
                                            {errors.familyName &&
                                                errors.familyName.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <FormControl isInvalid={errors?.email}>
                                    <FormLabel htmlFor="email">
                                        {getConstToLanguage("EMAIL")}
                                    </FormLabel>
                                    <Input
                                        id="email"
                                        type="email"
                                        {...register("email")}
                                    />
                                    <FormErrorMessage>
                                        {errors.email && errors.email.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors?.password}>
                                    <FormLabel htmlFor="email">
                                        {getConstToLanguage("PASSWORD")}
                                    </FormLabel>
                                    <Input
                                        type="password"
                                        {...register("password")}
                                    />
                                    <FormErrorMessage>
                                        {errors.password &&
                                            errors.password.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isInvalid={errors?.passwordConfirmation}
                                >
                                    <FormLabel htmlFor="email">
                                        {getConstToLanguage(
                                            "PASSWORD_CONFIRMATION"
                                        )}
                                    </FormLabel>
                                    <Input
                                        type="password"
                                        {...register("passwordConfirmation")}
                                    />
                                    <FormErrorMessage>
                                        {errors.passwordConfirmation &&
                                            errors.passwordConfirmation.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Stack>
                            <Divider />

                            <SpinnerButton
                                type="submit"
                                w="100%"
                                loading={loading}
                                variant={"cloud-management-os.primary"}
                            >
                                {getConstToLanguage("SIGN_UP")}
                            </SpinnerButton>
                        </Stack>
                    </form>
                </Stack>

                <Stack spacing="4"></Stack>
            </Stack>
        </Stack>
    );
};

export default SignUpForm;
