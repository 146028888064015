import AuthListErrors from "../../AuthListErrors";
import React, { useState } from "react";

import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";

import { LOGIN_AS_VENDOR } from "../../../graph/mutations/user/vendor";
import { LOGO_GRAPHIC_ONLY } from "../../Icons";

import { Mutation } from "@apollo/react-components";
import { Redirect } from "react-router";

function AdminUpdatePassword() {
  const [password, setPassword] = useState("");

  return (
    <div className="auth-page">
      <div className="auth-container">
        <div className="vendor-sign-up">
          <div className="auth-card">
            <div className="auth-title">
              <div className="auth-logo">
                <LOGO_GRAPHIC_ONLY />
              </div>{" "}
              <h1>{getConstToLanguage("UPDATE_PASSWORD")}</h1>
            </div>

            <Mutation mutation={LOGIN_AS_VENDOR}>
              {(updateVendorPassword, { error, data, loading }) => {
                if (data) {
                  return <Redirect to={"/vendor/login"} />;
                }

                return (
                  <div>
                    <AuthListErrors graphError={error} />
                    <form
                      className="vendor-auth-sign-up-form"
                      onSubmit={e => {
                        e.preventDefault();
                        updateVendorPassword({
                          variables: {
                            password
                          }
                        });
                      }}
                    >
                      <fieldset className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          placeholder={getConstToLanguage("NEW_PASSWORD")}
                          value={password}
                          onChange={setPassword}
                        />
                      </fieldset>

                      <fieldset className="form-group pull-xs-right">
                        <button
                          className="auth-submit-btn"
                          type="submit"
                          disabled={loading}
                        >
                          {getConstToLanguage("UPDATE_PASSWORD")}
                        </button>
                      </fieldset>
                    </form>
                  </div>
                );
              }}
            </Mutation>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUpdatePassword;
