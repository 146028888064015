import gql from "graphql-tag";

const GQL_M_CHECK_STATUS_OF_CURRENT_SESSION_FOR_BILL_DOT_COM = gql`
  mutation CheckStatusOfCurrentSessionForBillDotCom {
    checkStatusOfCurrentSessionForBillDotCom {
      type
      message
      status
      title
      description
    }
  }
`;

export { GQL_M_CHECK_STATUS_OF_CURRENT_SESSION_FOR_BILL_DOT_COM };
