import { ADMIN__GEM_REQUESTS_MANAGER__ON_LOAD } from "../../constants/actionTypes";
import { buildInitialGemRequestRow } from "../initializers/gemRequest";

export default (
  state = {
    gemRequestItems: []
  },
  action
) => {
  let newListOfGemRequestItems = { ...state.gemRequestItems };
  switch (action.type) {
    case ADMIN__GEM_REQUESTS_MANAGER__ON_LOAD:
      return {
        ...state,
        gemRequestItems: action.payload[0].gemRequests.map(gemRequest =>
          buildInitialGemRequestRow(gemRequest)
        )
      };
    default:
      return state;
  }
};
