import {
    Box,
    Center,
    Heading,
    Spinner,
    VStack,
    Stack,
    Select,
    Flex,
    HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ListContainer from "./ListContainer";

const ProductSearchResultDescription = React.memo((props) => {
    return (
        <VStack
            paddingTop={{ base: 6, lg: 12 }}
            paddingLeft={{ base: 6, lg: 12 }}
            paddingRight={{ base: 6, lg: 12 }}
        >
            <Heading>
                <Center>{props.totalCount} Search results</Center>
            </Heading>
            {props.searchSubtext && (
                <Box align="center">{props.searchSubtext}</Box>
            )}
        </VStack>
    );
});

const SearchOptions = ({ setOrderBy }) => {
    const { t } = useTranslation();

    const onChange = async (ev) => {
        ev.persist();

        // Rapnet - "Price","Shape","Size","Color", "Clarity", "Cut","Lab"

        switch (ev.target.value) {
            case "SORT_PRICE_ASC":
                setOrderBy("price", "ASC");
                break;
            case "SORT_PRICE_DSC":
                setOrderBy("price", "DSC");
                break;

            // case "SORT_DISCOUNT_ASC":
            //     setOrderBy("currentRelativeDiscountPercentage", "ASC");
            //     break;
            // case "SORT_DISCOUNT_DSC":
            //     setOrderBy("currentRelativeDiscountPercentage", "DSC");
            //     break;
        }
    };

    return (
        <HStack spacing={3}>
            <Select
                placeholder={t("SORT_BY")}
                size="md"
                onChange={onChange}
                w={200}
                outline="brand.500"
                bg="white"
            >
                <option value="SORT_PRICE_ASC">{t("SORT_PRICE_ASC")}</option>
                <option value="SORT_PRICE_DSC">{t("SORT_PRICE_DSC")}</option>
                {/* <option value="SORT_DISCOUNT_ASC">
                    {t("SORT_DISCOUNT_ASC")}
                </option>
                <option value="SORT_DISCOUNT_DSC">
                    {t("SORT_DISCOUNT_DSC")}
                </option> */}
            </Select>
        </HStack>
    );
};

const ProductSearch = ({
    loading,
    searchParam,
    setOrderBy,
    searchSubtext,
    totalCount,
    searchResultItems,
    itemFunc,
    itemProps,
    bodyIfItemsFoundListIsEmpty,
}) => {
    return (
        <Box w="100vw">
            <Box padding={[0, 8, 14, 32]} w="100vw">
                {searchParam}
            </Box>
            {loading ? (
                <Center minH={"20rem"}>
                    <Spinner color="brand.500" />
                </Center>
            ) : (
                <Box>
                    {searchResultItems && searchResultItems.length > 0 ? (
                        <VStack bg="off-grey" spacing={4}>
                            <ProductSearchResultDescription
                                searchSubtext={searchSubtext}
                                totalCount={totalCount}
                            />

                            <Box>
                                <Flex
                                    paddingLeft={{ base: 8, lg: 24 }}
                                    paddingRight={{ base: 8, lg: 24 }}
                                    w="100%"
                                    justifyContent={"right"}
                                >
                                    <Box>
                                        <SearchOptions
                                            setOrderBy={setOrderBy}
                                        />
                                    </Box>
                                </Flex>
                                <ListContainer
                                    listItems={searchResultItems}
                                    itemFunc={itemFunc}
                                    itemProps={itemProps}
                                />
                                )
                            </Box>
                        </VStack>
                    ) : (
                        bodyIfItemsFoundListIsEmpty
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ProductSearch;
