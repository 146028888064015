import { GoodResponseAtom } from "./GoodResponseAtom";
import { BadResponseAtom } from "./BadResponseAtom";
export * from "@chakra-ui/react";

export { GoodResponseAtom, BadResponseAtom };

export default {
    GoodResponseAtom,
    BadResponseAtom,
};
