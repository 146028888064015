import MarketMain from "./Main";
import React from "react";

import {
  setConstToLanguageMap,
  setCurrentLanguage
} from "../../stories/CloudManagementOS/ConstToLanguageMapping";
import { CONST_TO_LANG_MAP } from "../../constants/businessConstantsToLanguageMapping";

import { ApolloProvider } from "@apollo/client";
import { marketUserGraphClient } from "../../graph";

const MarketHome = ({ ...props }) => {
  setConstToLanguageMap(CONST_TO_LANG_MAP);
  setCurrentLanguage("en");

  return (
    <ApolloProvider client={marketUserGraphClient} >
      <MarketMain />
    </ApolloProvider>
  );
};

export default MarketHome;
