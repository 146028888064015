import React from "react";
import PayableBankAccountManagement from "./PayableBankAccountManagement";
import { Box } from "../../../Primatives";
import { useMutation } from "@apollo/client";

const VendorOrganizationSettings = ({
  createNewBankAccountForVendorMutation,
  getCurrentPayableBankAccount,
}) => {
  return (
    <Box p={6}>
      <PayableBankAccountManagement
        createNewBankAccountForVendorMutation={
          createNewBankAccountForVendorMutation
        }
        getCurrentPayableBankAccount={getCurrentPayableBankAccount}
      />
    </Box>
  );
};

export default VendorOrganizationSettings;
