import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import { graphGraphClient } from "../../../../graph";
import { useTranslation } from "react-i18next";
import { CreateGemRequestGraphQLMutation } from "../../../../api_gateway/carbonext/graph/mutations/createGemRequest";
import { MatchComparableGemListingsFromSpecsGraphQLQuery } from "../../../../api_gateway/carbonext/graph/queries/matchComparableGemListingsFromSpecs";
import {
    Badge,
    Button,
    Box,
    Heading,
    HStack,
    Link,
    Text,
    Center,
    Popover,
    Image,
    Square,
    ListItem,
    UnorderedList,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Grid,
    VStack,
    List,
    Flex,
    SpinnerButton,
    Container,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    useBreakpointValue,
    Tooltip,
    AspectRatio,
    Tag,
    background,
} from "../../../../stories/Primatives";
import ReactPlayer from "react-player";
import { Video360Icon } from "../../../IconsV2";

import { Link as ReactLink, Route } from "react-router-dom";

import TwoPanelShell from "../../../../stories/CloudManagementOS/shells/TwoPanelShell";
import { getShapeIcon, LinkIcon, ShippingTruck } from "../../../IconsV2";
import { floatToCurrency } from "../../../UtilityComponents/etc/floatToCurrency";
import { useCurrentlyReservedGemListings } from "../../../UtilityComponents/hooks";
import { CurrentMarketUserContext } from "../../../UtilityComponents/MarketOS/context";
import { updateSalesOrder } from "../../cache";
import { GQL_CREATE_OR_UPDATE_TO_CURRENT_SALES_ORDER } from "../../graph/mutations/createOrUpdateCurrentSalesOrder";
import { GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY } from "../../graph/queries";
import { RequestCertifiedGemListingCertificaticationGraphQLMutation } from "../../../../api_gateway/carbonext/graph/mutations/requestCertifiedGemListingCertificatication";
import { GQLFragment } from "../../../../lib/GraphCallBuilder";
import { Item } from "../../../../stories/Market/GalleryItem.stories";
import DesignSystem from "../../../../DesignSystem";

import { Footer } from "../../../../stories/Marketing/Footer";
import { CreateContactUsGraphQLMutation } from "../../../../api_gateway/carbonext/graph/mutations/createContactUs";

import { GetOnMarketGemListingGraphQLQuery } from "../../../../api_gateway/carbonext/graph/queries/getOnMarketGemListing";

const Badges = ({ gemListing, currentlyReservedGemListings }) => {
    let { t } = useTranslation();
    const { currentMarketUser } = useContext(CurrentMarketUserContext);
    let badges = [];
    console.log("CCSDFSDFDSFSDUF", currentMarketUser);
    if (
        currentMarketUser?.currentSalesOrder?.items.some(
            (reservedItem) => reservedItem.id === gemListing.id
        )
    ) {
        badges.push(<Badge>{t("RESERVED_BY_YOU")}</Badge>);
    } else if (currentlyReservedGemListings?.includes(gemListing.id)) {
        badges.push(<Badge>{t("CURRENTLY_RESERVED")}</Badge>);
    }

    return badges;
};

const Title = ({ gemListing }) => {
    let { t } = useTranslation();
    return (
        <Heading as="h1" w="100%">
            <Text fontSize="2xl" fontFamily={"Montserrat"} fontWeight={"600"}>
                {gemListing.certifiedGem.specs.carat} - {t("gems:CARAT")}{" "}
                {t(`gems:${gemListing.certifiedGem.specs.shape}`)}{" "}
                {t("gems:CUT")} {t("gems:DIAMOND")}
            </Text>
        </Heading>
    );
};

const CertificateInformation = ({ gemListing }) => {
    let { t } = useTranslation();
    let placement = useBreakpointValue(
        {
            base: "bottom",
            md: "right",
        },
        {
            // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
            // (Defaults to 'base')
            fallback: "bottom",
        }
    );
    return (
        <HStack fontSize={"sm"}>
            <HStack>
                <Box color={"brand.500"}>
                    {gemListing?.certifiedGem?.certification?.certificationType}{" "}
                    :{" "}
                </Box>
                <Box>
                    {gemListing.certifiedGem?.certification?.certificationId}
                </Box>
            </HStack>
            <Tooltip label={t("OPEN_CERTIFICATE")} aria-label="A tooltip">
                <Link
                    href={`https://www.gia.edu/report-check?reportno=${gemListing?.certifiedGem?.certification?.certificationId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Box w="10px">{<LinkIcon />}</Box>
                </Link>
            </Tooltip>
        </HStack>
    );
};

const Spec = ({ spec, specValue }) => {
    return (
        <HStack>
            <Box>{spec} : </Box>
            <Box>{specValue}</Box>
        </HStack>
    );
};

const PriceInformation = ({ gemListing }) => {
    const { loading, error, data, refetch } = useQuery(
        gql`
            ${new MatchComparableGemListingsFromSpecsGraphQLQuery().build({
            requestedAttrs: [
                new GQLFragment("matchedSpecs", [
                    "calculatedRelativePrice",
                ]),
            ],
        })}
        `,
        {
            client: graphGraphClient,
            variables: {
                specs: [
                    {
                        carat: gemListing.certifiedGem.specs.carat,
                        clarity: gemListing.certifiedGem.specs.clarity,
                        shape: gemListing.certifiedGem.specs.shape,
                        color: gemListing.certifiedGem.specs.color,
                        cut: gemListing.certifiedGem.specs.cut,
                    },
                ],
            },
        }
    );

    if (
        data?.matchComparableGemListingsFromSpecs?.matchedSpecs[0]
            .calculatedRelativePrice
    ) {
        gemListing.latestRelativePrice = {
            relativePrice:
                data?.matchComparableGemListingsFromSpecs?.matchedSpecs[0]
                    .calculatedRelativePrice,
        };

        gemListing.currentRelativeDiscountPercentage =
            (1 -
                gemListing.price /
                data?.matchComparableGemListingsFromSpecs?.matchedSpecs[0]
                    .calculatedRelativePrice) *
            100;
    }
    return (
        <HStack bg={"white"} p="8">
            <Text
                color="brand.500"
                fontSize="4xl"
                fontWeight={"600"}
                fontFamily={"Montserrat"}
            >
                {floatToCurrency(gemListing.price)}
            </Text>

            {gemListing?.currentRelativeDiscountPercentage &&
                gemListing?.currentRelativeDiscountPercentage > 0 && (
                    <>
                        <Text
                            color="black"
                            textDecoration={"line-through"}
                            fontSize="2xl"
                        >
                            {gemListing?.latestRelativePrice?.relativePrice
                                ? floatToCurrency(
                                    gemListing?.latestRelativePrice
                                        ?.relativePrice
                                )
                                : ""}
                        </Text>
                        <Text fontSize="2xl">
                            {gemListing?.currentRelativeDiscountPercentage
                                ? `${Math.round(
                                    gemListing?.currentRelativeDiscountPercentage
                                )}% OFF`
                                : ""}
                        </Text>
                    </>
                )}
            <Box></Box>
        </HStack>
    );
};

const Action = ({ gemListing }) => {
    const { currentMarketUser } = useContext(CurrentMarketUserContext);
    const [
        createOrUpdateCurrentSalesOrder,
        { data, loading, error },
    ] = useMutation(GQL_CREATE_OR_UPDATE_TO_CURRENT_SALES_ORDER, {
        update: updateSalesOrder,
    });
    let { currentlyReservedGemListings } = useCurrentlyReservedGemListings(
        GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY
    );
    let { t } = useTranslation();

    if (currentMarketUser) {
        console.log(
            "CURRRNNTNTTTTTTTMO>>>>",
            currentMarketUser.currentSalesOrder
        );
        if (currentMarketUser.currentSalesOrder) {
            if (
                currentMarketUser.currentSalesOrder.items.find(
                    (item) => item.id === gemListing.id
                )
            ) {
                return (
                    <VStack gap={2}>
                        <Button
                            w={"100%"}
                            variant={"market.primary"}
                            isLoading={loading}
                            onClick={(e) => {
                                e.preventDefault();

                                createOrUpdateCurrentSalesOrder({
                                    variables: {
                                        thirdPartyItemToRemove: gemListing.id,
                                    },
                                });
                            }}
                        >
                            {t("REMOVE_FROM_CART")}
                        </Button>
                        <Link
                            as={ReactLink}
                            to="/market/cart"
                            w={"100%"}
                            textDecoration={"none"}
                            _hover={{
                                textDecoration: "none",
                            }}
                        >
                            <Button w={"100%"} variant={"market.primary.white"}>
                                {t("CHECKOUT")}
                            </Button>
                        </Link>
                    </VStack>
                );
            }
        }
        return (
            <Button
                variant={"market.primary"}
                isLoading={loading}
                w={"100%"}
                disabled={currentlyReservedGemListings?.includes(gemListing.id)}
                onClick={(e) => {
                    e.preventDefault();

                    createOrUpdateCurrentSalesOrder({
                        variables: {
                            thirdPartyItemToAdd: gemListing.id,
                        },
                    });
                }}
            >
                {currentlyReservedGemListings?.includes(gemListing.id)
                    ? t("CURRENTLY_RESERVED")
                    : t("RESERVE_DIAMOND")}
            </Button>
        );
    } else {
        return (
            <Link
                as={ReactLink}
                to="/market/sign-up"
                _hover={{ textDecoration: "none" }}
            >
                <Button variant={"market.primary"} w="100%">
                    {t("SIGN_UP_TO_RESERVE")}
                </Button>
            </Link>
        );
    }
};

const VIDEO_360 = "360_VIDEO";

const ItemShowCase = ({ gemListing }) => {
    let [selected, setSelected] = useState(0);
    let shapeIcon = getShapeIcon(gemListing.certifiedGem.specs.shape);
    let { t } = useTranslation();
    let shapeIconMedia = {
        preview: (
            <Box bgColor={"white"} padding={"10%"}>
                {shapeIcon({ color: "black", height: "100%" })}
            </Box>
        ),
        content: (
            <Box bgColor={"white"} padding={"10%"}>
                {shapeIcon({ color: "black", height: "100%" })}
            </Box>
        ),
    };
    let media = [];

    if (gemListing?.publicMediaLinks) {
        gemListing.publicMediaLinks.forEach((link) => {
            if (link?.tags.includes(VIDEO_360)) {
                media.push({
                    preview: (
                        <Box textAlign={"center"} padding={"10%"}>
                            <Video360Icon color="brand.500" />
                        </Box>
                    ),
                    content: <iframe width="100%" src={link.url} />,
                });
            } else {
                media.push({
                    preview: <Image src={link.url} />,
                    content: <Image src={link.url} />,
                });
            }
        });
    }
    media.push(shapeIconMedia);

    console.log("MEIDSUSSSS", media);

    return (
        <Grid
            templateColumns={{
                base: "45px 1fr",
                sm: "72px 1fr",
                md: "61px 1fr",
            }}
            w="100%"
            gap={2}
        >
            <Box>
                <VStack>
                    {media.map((medium, idx) => {
                        return (
                            <AspectRatio
                                w="100%"
                                bg="white"
                                ratio={1}
                                onClick={() => setSelected(idx)}
                            >
                                {medium.preview}
                            </AspectRatio>
                        );
                    })}
                </VStack>
            </Box>
            <Box>
                <Center w="100%">
                    <AspectRatio ratio={1} w="100%">
                        {media[selected].content}
                    </AspectRatio>
                </Center>
            </Box>
        </Grid>
    );
};

const BasicSpecs = ({ gemListing }) => {
    let { t } = useTranslation();
    return (
        <Text color="grey.700" fontSize={"md"} fontFamily={"Montserrat"}>
            <HStack>
                <Box>
                    {t(`gems:${gemListing?.certifiedGem?.specs?.color}`)}{" "}
                    {t("gems:COLOR")}
                </Box>
                <span>&#183;</span>
                <Box>
                    {t(`gems:${gemListing?.certifiedGem?.specs?.clarity}`)}{" "}
                    {t("gems:CLARITY")}
                </Box>
                {gemListing?.certifiedGem?.specs?.cut && (
                    <>
                        <span>&#183;</span>
                        <Box>
                            {t(`gems:${gemListing.certifiedGem.specs.cut}`)}{" "}
                            {t("gems:CUT")}
                        </Box>
                    </>
                )}
            </HStack>
        </Text>
    );
};

const PrimaryInfo = ({ gemListing }) => {
    let { currentlyReservedGemListings } = useCurrentlyReservedGemListings(
        GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY
    );

    const { t } = useTranslation();

    return (
        <TwoPanelShell
            subViewWidth="1fr"
            breakpointValue={"md"}
            spacing="2rem"
            bg={"grey.500"}
            panel1={
                <Box h="100%" bg={"grey.500"}>
                    <ItemShowCase gemListing={gemListing} />
                </Box>
            }
            panel2={
                <Container maxW="3xl" bg={"grey.500"}>
                    <VStack w="100%" align={"left"} h="100%" spacing={3}>
                        <Badges
                            currentlyReservedGemListings={
                                currentlyReservedGemListings
                            }
                            gemListing={gemListing}
                        />
                        <VStack spacing={6} align="left">
                            <VStack spacing={4} align="left">
                                <Title gemListing={gemListing} />
                                <BasicSpecs gemListing={gemListing} />
                                <CertificateInformation
                                    gemListing={gemListing}
                                />
                                <PriceInformation gemListing={gemListing} />
                                <HStack>
                                    <ShippingTruck w="35px" />
                                    <Text>
                                        {t(
                                            "marketing:SHIPS_FROM_UNITED_STATES"
                                        )}
                                    </Text>
                                </HStack>
                            </VStack>
                            <Action gemListing={gemListing} />
                        </VStack>
                    </VStack>
                </Container>
            }
        />
    );
};

const ProductDetailsItem = ({ spec, specValue }) => {
    return (
        <ListItem>
            <span>{spec}</span>
            <span>{specValue}</span>
        </ListItem>
    );
};

const CertificateDetails = ({ gemListing }) => {
    let { t } = useTranslation();

    const { currentMarketUser } = useContext(CurrentMarketUserContext);

    let [
        requestCertifiedGemListingCertificatication,
        { data, loading, error },
    ] = useMutation(
        gql`
            ${new RequestCertifiedGemListingCertificaticationGraphQLMutation().build(
            {
                requestedAttrs: ["title", "message"],
            }
        )}
        `,
        {
            variables: {
                gemListingId: gemListing.id,
            },
        }
    );
    return (
        <Box>
            <ProductDetailsItem
                spec={t("gems:LAB")}
                specValue={t(
                    `gems:${gemListing.certifiedGem.certification.certificationType}`
                )}
            />
            {currentMarketUser ? (
                <>
                    <DesignSystem.Molecules.GraphQLResultResponseMolecule
                        error={error}
                        result={
                            data?.requestCertifiedGemListingCertificatication
                        }
                    />
                    <SpinnerButton
                        variant={"market.primary"}
                        loading={loading}
                        onClick={() =>
                            requestCertifiedGemListingCertificatication()
                        }
                    >
                        {t("REQUEST_PDF")}
                    </SpinnerButton>
                </>
            ) : (
                <Link
                    as={ReactLink}
                    to="/market/sign-up"
                    _hover={{ textDecoration: "none" }}
                >
                    <Button variant={"market.primary"} w="100%">
                        {t("CREATE_ACCOUNT_TO_REQUEST_PDF")}
                    </Button>
                </Link>
            )}
        </Box>
    );
};

const GradingResultDetails = ({ gemListing }) => {
    const { t } = useTranslation();
    return (
        <DetailSection
            sectionTitle={t("gems:FLUORESCENCE")}
            sectionDetails={
                <>
                    <ProductDetailsItem
                        spec={`${t("gems:DIAMOND")} ${t("gems:SHAPE")}`}
                        specValue={t(
                            `gems:${gemListing.certifiedGem.specs.shape}`
                        )}
                    />
                    <ProductDetailsItem
                        spec={t("gems:MEASUREMENTS")}
                        specValue={<Measurements gemListing={gemListing} />}
                    />
                    <ProductDetailsItem
                        spec={t("gems:COLOR")}
                        specValue={t(
                            `gems:${gemListing.certifiedGem.specs.color}`
                        )}
                    />
                    <ProductDetailsItem
                        spec={t("gems:WEIGHT")}
                        specValue={t(
                            `${gemListing.certifiedGem.specs.carat} ${t(
                                "gems:CARAT"
                            )}`
                        )}
                    />
                    <ProductDetailsItem
                        spec={t("gems:CLARITY")}
                        specValue={t(
                            `gems:${gemListing.certifiedGem.specs?.clarity}`
                        )}
                    />
                    <ProductDetailsItem
                        spec={t("gems:CUT")}
                        specValue={
                            gemListing.certifiedGem.specs.cut
                                ? t(
                                    `gems:${gemListing.certifiedGem.specs?.cut}`
                                )
                                : "N/A"
                        }
                    />
                </>
            }
        />
    );
};

const ProportionsDetails = ({ gemListing }) => {
    const { t } = useTranslation();
    return (
        <DetailSection
            sectionTitle={t("gems:PROPORTIONS")}
            sectionDetails={
                <>
                    <ProductDetailsItem
                        spec={t("gems:DEPTH_PERCENTAGE")}
                        specValue={`${gemListing.certifiedGem?.specs?.depthPercentage}%`}
                    />
                    <ProductDetailsItem
                        spec={t("gems:TABLE_PERCENTAGE")}
                        specValue={`${gemListing.certifiedGem?.specs?.tablePercentage}%`}
                    />
                </>
            }
        />
    );
};

const FinishDetails = ({ gemListing }) => {
    const { t } = useTranslation();
    return (
        <DetailSection
            sectionTitle={t("gems:FINISH")}
            sectionDetails={
                <>
                    <ProductDetailsItem
                        spec={t("gems:POLISH")}
                        specValue={
                            gemListing.certifiedGem?.specs?.polish
                                ? t(
                                    `gems:${gemListing.certifiedGem?.specs.polish}`
                                )
                                : ""
                        }
                    />
                    <ProductDetailsItem
                        spec={t("gems:SYMMETRY")}
                        specValue={t(
                            `gems:${gemListing.certifiedGem.specs?.symmetry}`
                        )}
                    />
                </>
            }
        />
    );
};

const FluorescenceDetails = ({ gemListing }) => {
    const { t } = useTranslation();
    return (
        <DetailSection
            sectionTitle={t("gems:FLUORESCENCE")}
            sectionDetails={
                <>
                    <ProductDetailsItem
                        spec={t("gems:FLUORESCENCE_INTENSITY")}
                        specValue={t(
                            `gems:${gemListing.certifiedGem?.specs?.fluorescence?.intensity}`
                        )}
                    />
                    <ProductDetailsItem
                        spec={t("gems:FLUORESCENCE_COLOR")}
                        specValue={
                            gemListing.certifiedGem?.specs?.fluorescence?.color
                                ? t(
                                    `gems:${gemListing.certifiedGem?.specs?.fluorescence?.color}`
                                )
                                : ""
                        }
                    />
                </>
            }
        />
    );
};

const Measurements = ({ gemListing }) => {
    let gemListingStr = "";
    if (gemListing.certifiedGem.specs.shape == "ROUND") {
        gemListingStr = `${gemListing.certifiedGem.specs.dimensions.minDiameter} - ${gemListing.certifiedGem.specs.dimensions.maxDiameter} x ${gemListing.certifiedGem.specs.dimensions.depth} mm`;
    } else {
        gemListingStr = `${gemListing.certifiedGem.specs.dimensions.length} x ${gemListing.certifiedGem.specs.dimensions.width} x ${gemListing.certifiedGem.specs.dimensions.depth} mm`;
    }

    return <Box>{gemListingStr}</Box>;
};

const DetailSection = ({ sectionTitle, sectionDetails }) => {
    return (
        <VStack align={"left"}>
            <Text fontWeight="600" size="sm" fontFamily={"Montserrat"}>
                {sectionTitle}
            </Text>
            <Box>
                <UnorderedList
                    p={0}
                    spacing={1}
                    marginLeft={0}
                    overflowX={"hidden"}
                    listStyle="none"
                    width={"100%"}
                    sx={{
                        "li::before": {
                            float: "left",
                            width: 0,
                            "white-space": "nowrap",
                            content:
                                '". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . "',
                        },

                        "span:first-child": {
                            "padding-right": "0.33em",
                            background: "white",
                            color: "grey.700",
                        },

                        "span + span": {
                            float: "right",
                            "padding-left": "0.33em",
                            background: "white",
                            color: "brand.500",
                        },
                    }}
                >
                    {sectionDetails}
                </UnorderedList>
            </Box>
        </VStack>
    );
};

const ProductDetails = ({ gemListing }) => {
    let { t } = useTranslation();
    return (
        <VStack align={"left"} spacing={6}>
            <GradingResultDetails gemListing={gemListing} />
            <FinishDetails gemListing={gemListing} />
            <ProportionsDetails gemListing={gemListing} />
            <FluorescenceDetails gemListing={gemListing} />
        </VStack>
    );
};

const DetailProductInfo = ({ gemListing }) => {
    let { t } = useTranslation();
    return (
        <Container maxW={"3xl"}>
            <ProductDetails gemListing={gemListing} />
        </Container>
    );
};

const GemRequest = ({ gemListing }) => {
    let { t } = useTranslation();
    let { currentMarketUser } = useContext(CurrentMarketUserContext);
    const [createGemRequest, { data, loading, error }] = useMutation(
        gql`
            ${CreateGemRequestGraphQLMutation().build({
            requestedAttrs: [
                new GQLFragment("result", ["message", "title"]),
            ],
        })}
        `
    );

    return (
        <Center
            w="100%"
            h="268px"
            bgImage={
                "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/black-white-expecting-diamond.png"
            }
        >
            <DesignSystem.Molecules.GraphQLResultResponseMolecule
                result={data?.createGemRequest?.result}
                error={error}
                resultVariant={"box"}
                initialBody={
                    <VStack padding="8">
                        <Heading as="h2" color="black" size="lg" align="center">
                            {t(
                                "marketing:LOOKING_FOR_SOMETHING_SIMILAR_QUESTION"
                            )}
                        </Heading>
                        <VStack>
                            <Text color="black">
                                {t("marketing:ASK_FOR_CONCIERGE_HELP_MSG")}
                            </Text>
                            {currentMarketUser ? (
                                <Button
                                    fontFamily={"Montserrat"}
                                    _hover={{ background: "none" }}
                                    background={"none"}
                                    color="brand.500"
                                    border={"none"}
                                    fontSize={"sm"}
                                    onClick={() =>
                                        createGemRequest({
                                            variables: {
                                                input: {
                                                    respectiveThirdPartyGemListingId:
                                                        gemListing.id,
                                                },
                                            },
                                        })
                                    }
                                >
                                    {t("DIAMOND_CONCIERGE_TEAM")}
                                </Button>
                            ) : (
                                <Link
                                    as={ReactLink}
                                    to="/market/sign-up"
                                    _hover={{ textDecoration: "none" }}
                                >
                                    <Button variant={"market.primary"} w="100%">
                                        {t("CREATE_ACCOUNT")}
                                    </Button>
                                </Link>
                            )}
                        </VStack>
                    </VStack>
                }
            />
        </Center>
    );
};

const QuestionsAboutThisGem = ({ gemListing }) => {
    let { t } = useTranslation();

    let createContactUsGraphQLMutation = new CreateContactUsGraphQLMutation();

    let [contactUsMutation, { data, error }] = useMutation(
        gql`
            ${createContactUsGraphQLMutation.build({
            requestedAttrs: [
                new GQLFragment("result", ["message", "title"]),
            ],
        })}
        `
    );
    return (
        <DesignSystem.Molecules.GraphQLResultResponseMolecule
            result={data?.createContactUs?.result}
            error={error}
            initialBody={
                <Center
                    w="100%"
                    bgImage={
                        "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/gold-marble-background-with-jewelry.png"
                    }
                    h="268px"
                >
                    <VStack spacing={3} padding={8}>
                        <Heading as="h1" color="white" size="xl">
                            {t("marketing:QUESTIONS_QUESTION")}
                        </Heading>
                        <Text color="white">
                            {t("marketing:QUESTIONS_ABOUT_THIS_DIAMOND")}
                        </Text>
                        <Button
                            variant="market.secondary"
                            onClick={() =>
                                contactUsMutation({
                                    variables: {
                                        source: "QUESTION_ABOUT_A_PRODUCT",
                                        productId: gemListing.id,
                                        productType: "GemListing",
                                    },
                                })
                            }
                        >
                            {t("ASK_NOW")}
                        </Button>
                    </VStack>
                </Center>
            }
        />
    );
};

const CurrentMarketUserActions = ({ gemListing }) => {
    return (
        <VStack align={"left"} spacing="8">
            <QuestionsAboutThisGem gemListing={gemListing} />
            <GemRequest gemListing={gemListing} />
        </VStack>
    );
};

const ReadyToShop = ({ gemListing }) => {
    let { t } = useTranslation();
    return (
        <VStack align={"left"} spacing="8">
            <Center
                w="100%"
                bgImage={
                    "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/gold-marble-background-with-jewelry.png"
                }
                h="268px"
            >
                <VStack spacing={3}>
                    <Heading as="h1" color="white" size="xl">
                        {t("marketing:READY_TO_SHOP_QUESTION")}
                    </Heading>
                    <Link
                        as={ReactLink}
                        to="/market/sign-up"
                        _hover={{ textDecoration: "none" }}
                    >
                        <Button variant="market.secondary">
                            {t("REGISTER_NOW")}
                        </Button>
                    </Link>
                </VStack>
            </Center>
            <GemRequest gemListing={gemListing} />
        </VStack>
    );
};

const Actions = ({ gemListing }) => {
    const { currentMarketUser } = useContext(CurrentMarketUserContext);

    console.log("CURREENNNTTTTTTTT", currentMarketUser);

    if (currentMarketUser) {
        return <CurrentMarketUserActions gemListing={gemListing} />;
    } else {
        return <ReadyToShop gemListing={gemListing} />;
    }
};

const SecondaryDetails = ({ gemListing }) => {
    return (
        <TwoPanelShell
            subViewWidth="50%"
            spacing={"32px"}
            breakpointValue={"md"}
            panel1={<DetailProductInfo gemListing={gemListing} />}
            panel2={<Actions gemListing={gemListing} />}
        />
    );
};

const SingleItem = (props) => {
    let { t } = useTranslation();
    window.scrollTo(0, 0);

    return (
        <>
            <Box maxW="100vw">
                <VStack>
                    <Box w="100%" bg={"grey.500"}>
                        <Container
                            maxW="6xl"
                            paddingTop={24}
                            paddingBottom={24}
                        >
                            <PrimaryInfo
                                gemListing={props.data.getOnMarketGemListing}
                            />
                        </Container>
                    </Box>
                    <Container maxW="6xl" paddingTop={24} paddingBottom={24}>
                        <SecondaryDetails
                            gemListing={props.data.getOnMarketGemListing}
                        />
                    </Container>
                </VStack>
            </Box>
            <Footer />
        </>
    );
};

const QueryWrapper = (props) => {
    if (props.loading) {
        return <Box className="loader"></Box>;
    }
    if (props.data?.getOnMarketGemListing) {
        return <SingleItem data={props.data} />;
    } else if (props.data) {
        return <Box>NOT FOUND!</Box>;
    } else {
        return <Box></Box>;
    }
};

const SingleItemComponent = (props) => {
    const { loading, error, data, refetch } = useQuery(
        gql`
            ${new GetOnMarketGemListingGraphQLQuery().build({
            requestedAttrs: [
                "id",
                "state",
                "price",
                "mainImage",
                new GQLFragment("publicMediaLinks", ["url", "tags"]),
                "currentRelativeDiscountPercentage",
                new GQLFragment("latestRelativePrice", ["relativePrice"]),
                new GQLFragment("certifiedGem", [
                    new GQLFragment("specs", [
                        "shape",
                        "carat",
                        "cut",
                        "color",
                        "clarity",
                        "polish",
                        "symmetry",

                        new GQLFragment("fluorescence", [
                            "intensity",
                            "color",
                        ]),
                        "dimensions",
                        "depthPercentage",
                        "tablePercentage",
                    ]),
                    new GQLFragment("certification", [
                        "certificationId",
                        "certificationType",
                    ]),
                ]),
            ],
        })}
        `,
        {
            variables: { id: props.match.params.id },
        }
    );

    return <QueryWrapper loading={loading} error={error} data={data} />;
};

export default SingleItemComponent;
