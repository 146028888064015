import gql from "graphql-tag";

const GQL_M_CREATE_BANK_ACCOUNT_FOR_VENDOR = gql`
  mutation CreateBankAccountForVendorOrganization(
    $accountNumber: String!
    $routingNumber: String!
  ) {
    createBankAccountForVendorOrganization(
      accountNumber: $accountNumber
      routingNumber: $routingNumber
      tags: ["LINKED_PAYABLE"]
    ) {
      accountNumber
      routingNumber
      tags
      state
    }
  }
`;

export default GQL_M_CREATE_BANK_ACCOUNT_FOR_VENDOR;
