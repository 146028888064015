import { Range } from "rc-slider";
import React from "react";
import {
    LIST_OF_CLARITIES_IN_ORDER,
    LIST_OF_COLORS_IN_ORDER,
    LIST_OF_CUTS_IN_ORDER,
    LIST_OF_SHAPES_IN_ORDER,
    DEFAULT_PRICE_RANGE,
    DEFAULT_CARAT_RANGE,
} from "../../constants/businessConstants";
import SliderWithManualInputs from "./SliderWithManualInputs";
import { QuestionIcon } from "@chakra-ui/icons";
import {
    AspectRatio,
    Box,
    Checkbox,
    Heading,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    SimpleGrid,
    VStack,
} from "../Primatives";

import { getShapeIcon } from "../../components/IconsV2";

import * as yup from "yup";
import { useTranslation } from "react-i18next";

const CaratSlider = ({ title, value, onChange }) => {
    const { t } = useTranslation();
    let valueToDisplay = value || DEFAULT_CARAT_RANGE;
    return (
        <div className="">
            {title && <div className="title">{t("CARAT")}</div>}
            <div>
                <SliderWithManualInputs
                    min={0}
                    max={10}
                    value={valueToDisplay}
                    allowCross={false}
                    marks={{ 0: "0.0", 10: "10.0" }}
                    step={0.01}
                    onChange={onChange}
                    matchRegex={/^\d{1,}(\.[0-9]{0,2})?$/}
                    parseAs={parseFloat}
                />
            </div>
        </div>
    );
};

const ShapeHelpPopover = React.memo(() => {
    const { t } = useTranslation();
    return (
        <SimpleGrid columns={2} spacing={4} color="white">
            {LIST_OF_SHAPES_IN_ORDER.map((shape, idx) => {
                return (
                    <VStack key={idx}>
                        <AspectRatio w="1.25rem" ratio={1}>
                            {getShapeIcon(shape)({
                                color: "white",
                                height: "1.25rem",
                            })}
                        </AspectRatio>
                        <Box>{t(`gems:${shape}`)}</Box>
                    </VStack>
                );
            })}
        </SimpleGrid>
    );
});

const ShapeSelector = React.memo(
    ({ selectedShapes, onClickSelectOnly, onClick }) => {
        const { t } = useTranslation();
        return (
            <VStack align="left">
                <HStack>
                    <Heading size="xs">{t("SHAPE")}</Heading>
                    <Popover
                        trigger="hover"
                        placement="right-start"
                        variant="responsive"
                    >
                        <PopoverTrigger>
                            <QuestionIcon />
                        </PopoverTrigger>
                        <PopoverContent bg="greyish" p="1rem">
                            <PopoverArrow bg="greyish" />
                            <PopoverBody>
                                <ShapeHelpPopover />
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </HStack>
                <Box>
                    {LIST_OF_SHAPES_IN_ORDER.map((shape) => {
                        return (
                            <HStack spacing={2} key={shape}>
                                <Checkbox
                                    value={shape}
                                    colorScheme="checkbox-gold"
                                    isChecked={
                                        selectedShapes &&
                                        selectedShapes.includes(shape)
                                    }
                                    onChange={() =>
                                        onClick({ target: { value: shape } })
                                    }
                                />
                                <Box
                                    onClick={() =>
                                        onClickSelectOnly &&
                                        onClickSelectOnly({
                                            target: { value: shape },
                                        })
                                    }
                                >
                                    {t(`gems:${shape}`)}
                                </Box>
                            </HStack>
                        );
                    })}
                </Box>
            </VStack>
        );
    }
);

const PriceSlider = ({ title, value, onChange }) => {
    const { t } = useTranslation();
    let valueToDisplay = value || DEFAULT_PRICE_RANGE;
    return (
        <div className="">
            {title && <div className="title">{t("PRICE")}</div>}
            <div>
                <SliderWithManualInputs
                    min={0}
                    max={100000}
                    value={valueToDisplay}
                    allowCross={false}
                    marks={{ 0: "$0", 100000: "$100000" }}
                    step={1}
                    onChange={onChange}
                    matchRegex={/^\d+$/}
                    parseAs={parseInt}
                />
            </div>
        </div>
    );
};

const ClarityRange = ({ clarityRange, onChange }) => {
    const setClarityRange = (range) => {
        let rangeToCutRange = [
            LIST_OF_CLARITIES_IN_ORDER[range[0]],
            LIST_OF_CLARITIES_IN_ORDER[range[1]],
        ];

        onChange && onChange(rangeToCutRange);
    };

    return (
        <Range
            min={0}
            max={9}
            defaultValue={[0, LIST_OF_CLARITIES_IN_ORDER.length - 1]}
            allowCross={false}
            value={
                clarityRange && [
                    LIST_OF_CLARITIES_IN_ORDER.findIndex(
                        (clarity) => clarity === clarityRange[0]
                    ),
                    LIST_OF_CLARITIES_IN_ORDER.findIndex(
                        (clarity) => clarity === clarityRange[1]
                    ),
                ]
            }
            marks={LIST_OF_CLARITIES_IN_ORDER}
            onChange={setClarityRange}
        />
    );
};

const CutRange = ({ cutRange, onChange }) => {
    let { t } = useTranslation();
    const setCutRange = (range) => {
        let rangeToCutRange = [
            LIST_OF_CUTS_IN_ORDER[range[0]],
            LIST_OF_CUTS_IN_ORDER[range[1]],
        ];

        onChange && onChange(rangeToCutRange);
    };

    return (
        <Range
            min={0}
            max={LIST_OF_CUTS_IN_ORDER.length - 1}
            defaultValue={[0, LIST_OF_CUTS_IN_ORDER.length - 1]}
            allowCross={false}
            marks={LIST_OF_CUTS_IN_ORDER.map((cut) => t(`gems:${cut}`))}
            value={
                cutRange && [
                    LIST_OF_CUTS_IN_ORDER.findIndex(
                        (cut) => cut === cutRange[0]
                    ),
                    LIST_OF_CUTS_IN_ORDER.findIndex(
                        (cut) => cut === cutRange[1]
                    ),
                ]
            }
            onChange={setCutRange}
            tipFormatter={(value) => LIST_OF_CUTS_IN_ORDER[value]}
        />
    );
};

export const ColorRange = ({ colorRange, onChange }) => {
    const setColorRange = (range) => {
        let rangeToColorRange = [
            LIST_OF_COLORS_IN_ORDER[range[0]],
            LIST_OF_COLORS_IN_ORDER[range[1]],
        ];

        onChange && onChange(rangeToColorRange);
    };

    return (
        <Range
            min={0}
            max={LIST_OF_COLORS_IN_ORDER.length - 1}
            allowCross={false}
            defaultValue={[0, LIST_OF_COLORS_IN_ORDER.length - 1]}
            value={
                colorRange && [
                    LIST_OF_COLORS_IN_ORDER.findIndex(
                        (color) => color === colorRange[0]
                    ),
                    LIST_OF_COLORS_IN_ORDER.findIndex(
                        (color) => color === colorRange[1]
                    ),
                ]
            }
            marks={LIST_OF_COLORS_IN_ORDER}
            onChange={setColorRange}
        />
    );
};

export { PriceSlider, CaratSlider, CutRange, ClarityRange, ShapeSelector };
