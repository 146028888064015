import { Icon } from "@chakra-ui/react";
import React from "react";

export const OvalShapeIcon = ({ color = "#000000", height = 'auto' }) => (
    <Icon viewBox="0 0 481.153 625.575" height={height} width={'100%'} fill={color}>
        <g
            id="Diamonds_Oval_Outlined"
            data-name="Diamonds_Oval Outlined"
            transform="translate(-52.983 -106.105)"
        >
            <g id="Diamonds">
                <g id="Grupo_43" data-name="Grupo 43">
                    <g id="XMLID_1071_">
                        <path
                            id="XMLID_1312_"
                            d="M434.059,413.844l-38.85,118.937,67.88-36.634-.068-1.115Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1311_"
                            d="M295.157,586.777l69.739,38.8,30.313-92.8Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1310_"
                            d="M364.9,625.582l106.1-.8-7.9-128.637-67.88,36.634Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1309_"
                            d="M223.253,625.582,294.074,727.2,364.9,625.582l-69.739-38.8Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1308_"
                            d="M463.089,496.147l.959-.518-1.027-.6Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1307_"
                            d="M532.071,412.411a390,390,0,0,0-19.559-116.84l-50.344,32.22Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1306_"
                            d="M434.059,413.844l28.962,81.188,1.027.6,1.106-.6,66.969-74.654c0-.43.013-.857.013-1.287,0-2.233-.029-4.458-.065-6.68l-69.9-84.619Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1305_"
                            d="M125.981,492.64l-.853,2.392-7.974,129.752,106.1.8-31.464-96.323Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1304_"
                            d="M462.168,327.792,389.5,288.929l44.56,124.915Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1303_"
                            d="M389.5,288.929l-30.577-85.714L294.074,237.9Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1302_"
                            d="M358.922,203.215l-64.848-93.839-64.848,93.839L294.074,237.9Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1301_"
                            d="M125.981,327.792l28.108,86.052L198.65,288.929Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1300_"
                            d="M295.157,586.777l100.052-54,38.85-118.937L389.5,288.929,294.074,237.9,198.65,288.929,154.089,413.844l37.7,115.415Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1299_"
                            d="M154.089,413.844l-28.108,78.8,65.808,36.619Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1298_"
                            d="M512.512,295.571c-11.061-33.194-26.438-63.378-45.218-89.351l-5.126,121.572Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1297_"
                            d="M191.789,529.259l31.464,96.323,71.9-38.8Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1296_"
                            d="M229.226,203.215,198.65,288.929,294.074,237.9Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1295_"
                            d="M294.074,109.376l-.6-.87c-34.793.032-67.842,9.77-97.639,27.244l33.392,67.466Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1294_"
                            d="M389.5,288.929l72.669,38.863,5.126-121.572q-1.645-2.277-3.328-4.506l-105.043,1.5Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1293_"
                            d="M223.253,625.582,191.926,700.07c30.49,18.754,64.519,29.341,100.43,29.589l1.718-2.464Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1292_"
                            d="M292.356,729.659c.431,0,.86.021,1.291.021.714,0,1.423-.027,2.135-.035l-1.708-2.45Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1291_"
                            d="M114.963,624.768c.669.985,1.332,1.977,2.01,2.95l.18-2.933Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1290_"
                            d="M117.154,624.784l-.18,2.933c20.966,30.1,46.41,54.8,74.952,72.353l31.327-74.488Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1289_"
                            d="M463.089,496.147l7.9,128.638,1.332-.01c19.375-28.525,34.791-61.713,45.112-98.121l-53.391-31.025Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1238_"
                            d="M465.154,495.032l-1.106.6,53.391,31.025a394.551,394.551,0,0,0,14.684-106.275Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1237_"
                            d="M125.128,495.032,69.98,527.077c10.319,36.239,25.687,69.279,44.984,97.69l2.191.016Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1236_"
                            d="M364.9,625.582l31.153,74.075c28.627-17.752,54.105-42.716,75.056-73.088l-.11-1.785Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1235_"
                            d="M471.1,626.569c.411-.595.816-1.2,1.223-1.794l-1.332.01Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1234_"
                            d="M294.074,727.2l1.708,2.45c35.867-.411,69.842-11.122,100.266-29.988L364.9,625.582Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1094_"
                            d="M195.833,135.749c-27.281,16-51.825,38.492-72.5,65.953l105.887,1.513Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1093_"
                            d="M358.922,203.215l33.2-67.077c-29.728-17.575-62.716-27.429-97.456-27.619l-.592.857Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1086_"
                            d="M294.666,108.519c-.34,0-.678-.017-1.018-.017-.059,0-.116,0-.175,0l.6.87Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1077_"
                            d="M463.966,201.714c-20.505-27.245-44.823-49.6-71.844-65.576l-33.2,67.077Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1076_"
                            d="M120.807,205.093c-19.064,26.106-34.663,56.535-45.873,90.029l51.047,32.67Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1075_"
                            d="M125.981,492.64l28.109-78.8-28.109-86.052L55.214,413.456c-.024,1.743-.049,3.487-.051,5.238l70.818,73.946Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1074_"
                            d="M74.934,295.122a390.114,390.114,0,0,0-19.72,118.335l70.767-85.665Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1073_"
                            d="M198.65,288.929l30.576-85.714L123.339,201.7c-.847,1.126-1.7,2.249-2.532,3.391l5.174,122.7Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1072_"
                            d="M125.981,492.64h0L55.164,418.694c0,.133,0,.264,0,.4A394.635,394.635,0,0,0,69.98,527.077l55.148-32.045Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <path
                        id="Trazado_1664"
                        data-name="Trazado 1664"
                        d="M531.9,412.014a390,390,0,0,0-19.559-116.84C501.276,261.98,485.9,231.8,467.119,205.823q-1.645-2.277-3.328-4.506c-20.505-27.245-44.823-49.6-71.844-65.576-29.729-17.575-62.716-27.429-97.456-27.619-.34,0-.678-.017-1.019-.017-.059,0-.116,0-.175,0-34.793.032-67.842,9.771-97.639,27.244-27.281,16-51.825,38.492-72.5,65.953-.847,1.126-1.7,2.248-2.532,3.391-19.064,26.106-34.663,56.535-45.873,90.029A390.114,390.114,0,0,0,55.038,413.06c-.024,1.743-.049,3.487-.051,5.238,0,.133,0,.264,0,.4A394.635,394.635,0,0,0,69.8,526.681c10.319,36.239,25.687,69.279,44.984,97.69.669.985,1.332,1.977,2.01,2.95,20.966,30.1,46.41,54.8,74.952,72.353,30.49,18.754,64.519,29.341,100.43,29.589.431,0,.86.021,1.291.021.714,0,1.423-.027,2.135-.035,35.867-.411,69.842-11.122,100.266-29.988,28.627-17.752,54.105-42.716,75.056-73.088.411-.595.816-1.2,1.223-1.794,19.375-28.525,34.791-61.713,45.112-98.121a394.551,394.551,0,0,0,14.684-106.275c0-.43.013-.857.013-1.287C531.961,416.461,531.931,414.237,531.9,412.014Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
