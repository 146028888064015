import {
  UPDATE_MARKET_RESET_PASSWORD_FIELD_AUTH,
  MARKET_RESET_PASSWORD,
  MARKET_RESET_PASSWORD_UNLOADED
} from "../constants/actionTypes";

function initialState() {
  return { email: null };
}

export default (state = { email: null }, action) => {
  switch (action.type) {
    case UPDATE_MARKET_RESET_PASSWORD_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    case MARKET_RESET_PASSWORD:
      return { ...state, respMsg: action.payload.msg };
    case MARKET_RESET_PASSWORD_UNLOADED:
      return initialState();
    default:
      return state;
  }
};
