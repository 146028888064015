// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
import { useMutation } from "@apollo/react-hooks";
import { loadStripe } from "@stripe/stripe-js";
import gql from "graphql-tag";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getConstToLanguage } from "../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import {
    SpinnerButton,
    VStack,
    Heading,
    Box,
    Grid,
    Flex,
    Container,
    FormControl,
    Input,
    FormLabel,
    InputWithFloatingLabel,
    HStack,
    Center,
    Select,
    FormErrorMessage,
} from "../../../../stories/Primatives";
import { getSum } from "../../etc/calculations";
import SalesOrderDetails from "./SalesOrderDetails";
import { CurrentMarketUserContext } from "../context";
import CheckoutReferral from "./CheckoutReferral";
import TwoPanelShell from "../../../../stories/CloudManagementOS/shells/TwoPanelShell";
import { useYupValidationResolver } from "../../../../stories/CloudManagementOS/hooks/useYupValidationResolver";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

const GQL_M_CREATE_PAYMENT_SESSION = gql`
    mutation CreatePaymentSession($pickUpLocation: PickUpLocationInput) {
        createPaymentSession(pickUpLocation: $pickUpLocation)
    }
`;
const stripePromise = loadStripe(
    process.env.REACT_APP__THIRD_PARTY__STRIPE__PUBLISHABLE_KEY
);

const CurrentSalesOrder = ({ subtotal, currentSalesOrder }) => {
    return (
        <VStack spacing={8} padding={{ base: 8, md: 20 }}>
            <SalesOrderDetails salesOrder={currentSalesOrder} />
            <Flex
                justifyContent={"space-between"}
                color="brand.500"
                fontSize="xl"
                w="100%"
            >
                <Box>{getConstToLanguage("SUBTOTAL")}</Box>
                <Box>{subtotal}</Box>
            </Flex>
        </VStack>
    );
};

const addressToOneLinerString = (address) => {
    let oneLiner = "";
    if (address.country === "US") {
        oneLiner += address.address1;
        oneLiner = address.address2
            ? (oneLiner += ` ${address.address2}`)
            : oneLiner;
        oneLiner += ` ${address.city}, ${address.region} ${address.postalCode}`;
    }
    return oneLiner;
};

const ProductDisplay = ({ handleClick, currentSalesOrder }) => {
    let { t } = useTranslation();
    const subtotal = currentSalesOrder
        ? getSum(currentSalesOrder.items, "price")
        : 0;
    const total = subtotal; //+ shipping, taxes

    let CanCheckoutSchema = Yup.object().shape({
        pickUpLocationAddress: Yup.string().required(
            t("errors:PICK_UP_LOCATION_IS_REQUIRED")
        ),
    });

    let locations = [
        {
            name: "David Feld Diamonds",
            address: {
                address1: "22 W48th Street",
                address2: "Suite 1004",
                city: "New York",
                region: "NY",
                postalCode: "10036",
                country: "US",
            },
            contact: {
                phoneNumber: "+16462659224",
                email: "david@kingdiamondsonline.com",
            },
        },
        {
            name: "Jamie Feld Jewelers",
            address: {
                address1: "7100 W Camino Real",
                address1: "Suite 303",
                city: "Boca Raton",
                region: "FL",
                postalCode: "33433",
                country: "US",
            },
            contact: {
                phoneNumber: "+15165091397",
                email: "jaimefeldjewelers@gmail.com",
            },
        },
    ];

    const resolver = useYupValidationResolver(CanCheckoutSchema);

    const handleClickWrapper = (formVariables) => {
        handleClick({
            pickUpLocation:
                locations[parseInt(formVariables.pickUpLocationAddress)],
        });
    };

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isSubmitting, isValid },
    } = useForm({
        resolver,
        mode: "onSubmit",
    });

    return (
        <TwoPanelShell
            subViewWidth="45%"
            breakpointValue="md"
            variant="left-main"
            panel1={
                <CurrentSalesOrder
                    currentSalesOrder={currentSalesOrder}
                    subtotal={subtotal}
                />
            }
            panel2={
                <VStack spacing={8} padding={8} w="100%">
                    <VStack spacing={6} w="100%">
                        <Heading className="checkout-title">
                            {t("ORDER_DETAILS")}
                        </Heading>
                        <hr className="divider-line" />
                        <Flex w="100%" justifyContent={"space-between"}>
                            <Box>{t("SUBTOTAL")}</Box>
                            <Box>{subtotal}</Box>
                        </Flex>
                        <Flex w="100%" justifyContent={"space-between"}>
                            <Box>{t("TOTAL")}</Box>
                            <Box>{total}</Box>
                        </Flex>
                    </VStack>
                    <Box w="100%">
                        <CheckoutReferral />
                    </Box>
                    <Box w="100%">
                        <form
                            onSubmit={handleSubmit(handleClickWrapper)}
                            width="100%"
                        >
                            <VStack w="100%" spacing={4}>
                                <Box w="100%">
                                    <FormControl
                                        isInvalid={errors.pickUpLocationAddress}
                                    >
                                        <Select
                                            placeholder={t("PICKUP_LOCATION")}
                                            id="pick-up-location"
                                            {...register(
                                                "pickUpLocationAddress"
                                            )}
                                        >
                                            {locations.map((location, idx) => (
                                                <option value={idx}>
                                                    {addressToOneLinerString(
                                                        location.address
                                                    )}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>
                                            {errors.pickUpLocationAddress &&
                                                errors.pickUpLocationAddress
                                                    .message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>
                                <SpinnerButton
                                    variant="market.primary"
                                    id="checkout-button"
                                    role="link"
                                    w="100%"
                                    type={"SUBMIT"}
                                >
                                    {t("PAY_WITH_CREDIT_CARD")}
                                </SpinnerButton>
                            </VStack>
                        </form>
                    </Box>
                </VStack>
            }
        ></TwoPanelShell>
    );
};

const CanceledOrder = () => {
    return <Box>Canceled Order</Box>;
};

// const CheckoutReferral = () => {
//     return (

// };

export default function App() {
    const [
        createPaymentSession,
        { data, loading, error: createNewPaymentSessionError },
    ] = useMutation(GQL_M_CREATE_PAYMENT_SESSION);

    const { currentMarketUser } = useContext(CurrentMarketUserContext);

    const query = new URLSearchParams(window.location.search);
    if (query.get("canceled")) {
        return <CanceledOrder />;
    }

    const handleClick = async (variables) => {
        const stripe = await stripePromise;
        const { data } = await createPaymentSession({ variables });

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: data.createPaymentSession,
        });
        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };
    return (
        <Container maxW="7xl" centerContent>
            <ProductDisplay
                handleClick={handleClick}
                currentSalesOrder={
                    currentMarketUser && currentMarketUser.currentSalesOrder
                }
            />
        </Container>
    );
}
