import gql from "graphql-tag";
import { GQL_Q_SEARCH_VENDORS } from "./searchVendors";
import { GQL_Q_GET_CURRENT_PAYABLE_BANK_ACCOUNT } from "./getCurrentPayableBankAccount";

const GQL_Q_SEARCH_GEM_LISTINGS_IN_STATE = gql`
  query SearchGemListingsInState {
    searchGemListingsInState {
      id
    }
  }
`;

export {
  GQL_Q_SEARCH_GEM_LISTINGS_IN_STATE,
  GQL_Q_SEARCH_VENDORS,
  GQL_Q_GET_CURRENT_PAYABLE_BANK_ACCOUNT,
};
