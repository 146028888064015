import { Icon } from "@chakra-ui/react";
import React from "react";


export const OldMinerShapeIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 549.87 542.052" height={'auto'} width={'100%'} fill={color}>
        <g id="Diamonds_OldMiner2" transform="translate(-13067.994 509.938)">
            <path id="Trazado_1702" data-name="Trazado 1702" d="M853.161,707.511,789.7,729.968,626.644,753.4,471.4,731.92l-71.275-24.409-20.5-67.369L356.191,484.9,379.623,327.7l20.5-65.417L471.4,239.832,631.2,215.712l152.643,22.167,69.322,24.409,24.409,73.228L901,484.9,877.57,640.142Z" transform="translate(12714.834 -723.122)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" />
            <path id="Trazado_1713" data-name="Trazado 1713" d="M549.641,296.83l93.837,21.126,21.937,96.537-26.923,85.328-88.851,20.369L462,499.821,439.064,408.9l18.946-90.949Z" transform="translate(12793.79 -645.839)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" />
            <path id="Trazado_1714" data-name="Trazado 1714" d="M558.564,216.382,432.426,303.675l123.54,69.8,130.731-68.8Z" transform="translate(12787.466 -722.484)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" />
            <path id="Trazado_1715" data-name="Trazado 1715" d="M379.623,273.064l66.27,27.8-89.7,129.4,89.7,132.516L518.02,431.547,445.893,300.859" transform="translate(12714.834 -668.482)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" />
            <path id="Trazado_1716" data-name="Trazado 1716" d="M483.621,292.546l31.908,90.926-91.072-39.739L378.69,250.52l104.93,42.025-33.655-64.482" transform="translate(12736.271 -711.354)" fill="none" stroke="#000" strokeLinejoin="bevel" strokeWidth="5" />
            <path id="Trazado_1717" data-name="Trazado 1717" d="M580.639,294.5l-36.894,89.929,91.072-39.074,39.655-93.877L580.639,294.5l24.511-67.432" transform="translate(12893.523 -712.307)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" />
            <path id="Trazado_1718" data-name="Trazado 1718" d="M688.178,277.064l-64.064,20.649,87.5,128.735-87.5,132.516-69.135-125.64,69.135-135.611" transform="translate(12904.226 -664.671)" fill="none" stroke="#000" strokeLinejoin="bevel" strokeWidth="5" />
            <path id="Trazado_1719" data-name="Trazado 1719" d="M424.457,441.093l95.061-40.311-40.384,91.166L378.69,531.189Z" transform="translate(12736.271 -546.8)" fill="none" stroke="#000" strokeLinejoin="bevel" strokeWidth="5" />
            <line id="Línea_15" data-name="Línea 15" y1="22.726" x2="66.27" transform="translate(13094.457 -105.707)" fill="none" stroke="#000" strokeLinejoin="bevel" strokeWidth="5" />
            <path id="Trazado_1720" data-name="Trazado 1720" d="M701.314,463.82l-64.064-22.727-96.058-40.311,43.874,91.166,28.375,61.7" transform="translate(12891.091 -546.8)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" />
            <path id="Trazado_1721" data-name="Trazado 1721" d="M430.128,472.28,556.2,557.411,690.88,472.28l91.839,39.242-39.655-90.1" transform="translate(12785.276 -527.132)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5" />
            <path id="Trazado_1722" data-name="Trazado 1722" d="M705.111,482.01l-132.725-70.8-128.027,70.8L415.19,545.661" transform="translate(12771.045 -536.863)" fill="none" stroke="#000" strokeLinejoin="bevel" strokeWidth="5" />
        </g>
    </Icon>
);
