import React from "react";

import {
    Box,
    Input,
    SpinnerButton,
    Button,
    InputWithFloatingLabel,
    Flex,
    HStack,
} from "../../../../../stories/Primatives";
import { useTranslation } from "react-i18next";
import { useAddReferralCodeToCurrentSalesOrder } from "../../../../../api_gateway/hooks/carbonext/graph/mutations/addReferralCodeToCurrentSalesOrder";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useSubmitMutation from "../../../../../stories/CloudManagementOS/hooks/useSubmitMutation";
import { GQLFragment } from "../../../../../lib/GraphCallBuilder";
import { Molecules } from "../../../../../DesignSystem";

const CheckoutReferralFormSchema = Yup.object().shape({
    referralCode: Yup.string().required("referralCode is a required field."),
});

const CheckoutReferral = (props) => {
    let [
        addReferralCodeToCurrentSalesOrderGQLMutation,
    ] = useAddReferralCodeToCurrentSalesOrder([
        new GQLFragment("result", ["title"]),
        new GQLFragment("salesOrder", ["createdAt"]),
    ]);
    const { t } = useTranslation();

    let {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: CheckoutReferralFormSchema.cast(),
        resolver: yupResolver(CheckoutReferralFormSchema),
    });

    const addReferralCodeToCurrentSalesOrder = React.useCallback(
        async (variables) => {
            return await addReferralCodeToCurrentSalesOrderGQLMutation({
                variables,
            });
        },
        [addReferralCodeToCurrentSalesOrderGQLMutation]
    );

    let { onSubmit, loading, error, data } = useSubmitMutation(
        addReferralCodeToCurrentSalesOrder
    );

    return (
        <Molecules.GraphQLResultResponseMolecule
            error={error}
            result={data?.addReferralCodeToCurrentSalesOrder?.result}
            initialBody={
                <form onSubmit={handleSubmit(onSubmit)}>
                    <HStack spacing={3}>
                        <InputWithFloatingLabel
                            {...register("referralCode")}
                            label={t("REFERRAL_CODE")}
                            error={errors?.referralCode}
                        ></InputWithFloatingLabel>

                        <SpinnerButton
                            type="submit"
                            loading={loading}
                            variant={"market.primary"}
                        >
                            {t("SUBMIT")}
                        </SpinnerButton>
                    </HStack>
                </form>
            }
        />
    );
};

export default CheckoutReferral;
