import gql from "graphql-tag";

const GQL_Q_SEARCH_VENDOR_ORGANIZATION_INQUIRIES_BY_STATE = gql`
    query SearchVendorOrganizationInquiriesInState(
        $state: VendorOrganizationInquiryState!
        $pagination: PaginationInput
    ) {
        searchVendorOrganizationInquiriesInState(
            state: $state
            pagination: $pagination
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    email
                    phoneNumber
                    state
                    createdAt
                    updatedAt
                    createdVendorOrganization
                    organization {
                        legalName
                    }
                    vendorTypes {
                        __typename
                        ... on GemTradingOrganizationVendorType {
                            gemTraderCategory: category
                            gemTraderVendorRole: vendorRole
                        }
                        ... on JewelryMakerOrganizationVendorType {
                            jewelryMakerCategory: category
                            jewelryMakerVendorRole: vendorRole
                        }
                    }
                    person {
                        givenName
                        familyName
                        additionalName
                    }
                }
            }
        }
    }
`;

export { GQL_Q_SEARCH_VENDOR_ORGANIZATION_INQUIRIES_BY_STATE };
