import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../lib/GraphCallBuilder";

function CreateContactUsGraphQLMutation() {
    return new GraphqlQueryBuilder({
        api: "createContactUs",
        type: "mutation",
        args: [
            new GQLInputArg("fullName", "String", false),
            new GQLInputArg("email", "String", false),
            new GQLInputArg("phoneNumber", "String", false),
            new GQLInputArg("message", "String", false),
            new GQLInputArg("salesOrderId", "String", false),
            new GQLInputArg("productType", "ProductType", false),
            new GQLInputArg("productId", "String", false),
            new GQLInputArg("source", "ContactUsSource", true),
        ],
    });
}

export { CreateContactUsGraphQLMutation };
