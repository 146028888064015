import React, { Children, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { HeaderBodyShell } from "../../../../../stories/CloudManagementOS/shells";
import gql from "graphql-tag";
import {
    Box,
    Container,
    Text,
    Divider,
    Stack,
    Button,
    useDisclosure,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalFooter,
    ModalCloseButton,
    ModalHeader,
    Tabs,
    TabList,
    TabPanel,
    Tab,
    TabPanels,
} from "../../../../../stories/Primatives";
import { Header } from "../../../../../stories/CloudManagementOS/Header";
import Query from "../../../../../stories/CloudManagementOS/Apollo/Query";
import GemListingSubTool from "../../GemListingManagement/Manager";
import { GQL_Q_GET_VENDOR_ORGANIZATION } from "../../../../../graph/queries/VendorOrganization/getVendorOrganization";
import {
    GQL_Q_SEARCH_BANK_ACCOUNTS,
    GQL_Q_GET_BILL_DOT_COM_VENDOR_BANK_ACCOUNT,
    GQL_Q_GET_BILL_DOT_COM_VENDOR,
} from "../../../graph/queries";
import ReactJson from "react-json-view";
import { HiddenAttribute } from "../../../../../stories/CloudManagementOS/SmartForm/EditableKeyValue";
import { GQLFragment } from "../../../../../lib/GraphCallBuilder";
import { GetVendorOrganizationGraphQLQuery } from "../../../../../api_gateway/carbonext/graph/queries/getVendorOrganization";

import _ from "lodash";
import { useTranslation } from "react-i18next";

const JSONModal = ({
    jsonBody,
    isOpen,
    onClose,
    title,
    size = "xl",
    error,
    loading,
}) => {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={size}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Query error={error} loading={loading}>
                            <ReactJson src={jsonBody} />
                        </Query>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

const BillDotComVendorRawData = ({ vendorId, isOpen, onClose }) => {
    const { t } = useTranslation();
    let { data, error, loading } = useQuery(GQL_Q_GET_BILL_DOT_COM_VENDOR, {
        variables: {
            vendorId,
        },
    });

    return (
        <JSONModal
            title={t("BILL_DOT_COM_VENDOR_BANK_ACCOUNT")}
            jsonBody={data?.getBillDotComVendor}
            isOpen={isOpen}
            error={error}
            loading={loading}
            onClose={onClose}
        />
    );
};

const BillDotComVendorBankAccountRawData = ({
    vendorBankAccountId,
    isOpen,
    onClose,
}) => {
    const { t } = useTranslation();
    let { data, error, loading } = useQuery(
        GQL_Q_GET_BILL_DOT_COM_VENDOR_BANK_ACCOUNT,
        {
            variables: {
                vendorBankAccountId: vendorBankAccountId,
            },
        }
    );

    return (
        <JSONModal
            title={t("BILL_DOT_COM_VENDOR_BANK_ACCOUNT")}
            jsonBody={data?.getBillDotComVendorBankAccount}
            isOpen={isOpen}
            error={error}
            loading={loading}
            onClose={onClose}
        />
    );
};

const BankAccount = ({ bankAccount }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box>
            <HiddenAttribute unHiddenValue={bankAccount?.routingNumber} />
            <HiddenAttribute unHiddenValue={bankAccount?.accountNumber} />
            {bankAccount?.thirdPartyReferenceEntities.billDotCom
                .vendorBankAccountId && (
                <>
                    <Button onClick={onOpen}>BILL_DOT_COM</Button>
                    {isOpen && (
                        <BillDotComVendorBankAccountRawData
                            vendorBankAccountId={
                                bankAccount?.thirdPartyReferenceEntities
                                    .billDotCom.vendorBankAccountId
                            }
                            isOpen={isOpen}
                            onClose={onClose}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export const Section = ({ header, children, subtitle }) => (
    <Box
        as="section"
        bg="bg-surface"
        pt={{ base: "4", md: "8" }}
        pb={{ base: "12", md: "24" }}
    >
        <Container maxW={"5xl"}>
            <Stack spacing="5">
                <Box>
                    <Text fontSize="lg" fontWeight="medium">
                        {header}
                    </Text>
                    {subtitle && (
                        <Text color="muted" fontSize="sm">
                            {subtitle}
                        </Text>
                    )}
                </Box>
                <Divider />
                {children}
            </Stack>
        </Container>
    </Box>
);

const PayableBankAccount = ({ bankAccount }) => {
    const { t } = useTranslation();
    return (
        <Section header={t("ONLINE_TRANSFER")}>
            <BankAccount key={bankAccount?.id} bankAccount={bankAccount} />
        </Section>
    );
};

const OtherBankAccounts = ({ bankAccounts }) => {
    const { t } = useTranslation();
    return (
        <Section header={t("OTHER_ACCOUNTS")}>
            {bankAccounts.map((bankAccount) => (
                <BankAccount key={bankAccount?.id} bankAccount={bankAccount} />
            ))}
        </Section>
    );
};

const BankAccountManagement = ({ vendorOrganization }) => {
    let { data, error, loading } = useQuery(GQL_Q_SEARCH_BANK_ACCOUNTS, {
        variables: {
            searchParam: {
                filter: {
                    vendorOrganizationId: vendorOrganization?.id,
                },
            },
        },
    });

    let [payableBankAccounts, restOfBankAccounts] = useMemo(() => {
        return _.partition(
            data?.searchBankAccounts,
            (bankAccount) => bankAccount.uses.onlineTransfer === true
        );
    }, [data]);

    console.log("DSL:KFJSD:LKFJSDLK:FS", payableBankAccounts);

    let payableBankAccount = useMemo(() => {
        return payableBankAccounts && payableBankAccounts[0];
    }, [payableBankAccounts]);

    return (
        <Query error={error} loading={loading}>
            <PayableBankAccount bankAccount={payableBankAccount} />
            <OtherBankAccounts bankAccounts={restOfBankAccounts} />
        </Query>
    );
};

const ThirdPartyReferenceEntities = ({ vendorOrganization }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box>
            <a
                href={`${process.env.REACT_APP__BILLDOTCOM_HOST}/neo/frame/enhanced-vendors/${vendorOrganization?.thirdPartyReferenceEntities?.billDotCom?.vendorId}/overview`}
                target="_blank"
            >
                BILL_DOT_COM_LINK
            </a>
            {vendorOrganization?.thirdPartyReferenceEntities.billDotCom
                .vendorId && (
                <>
                    <Button onClick={onOpen}>BILL_DOT_COM</Button>
                    {isOpen && (
                        <BillDotComVendorRawData
                            vendorId={
                                vendorOrganization?.thirdPartyReferenceEntities
                                    ?.billDotCom?.vendorId
                            }
                            isOpen={isOpen}
                            onClose={onClose}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

const Body = ({ vendorOrganization, ...props }) => {
    const { t } = useTranslation();
    return (
        <Tabs>
            <TabList>
                <Tab>{t("GEM_LISTINGS")}</Tab>
                <Tab>{t("BANK_ACCOUNTS")}</Tab>
                <Tab>{t("THIRD_PARTY_REFERENCES")}</Tab>
                {/* <Tab>Three</Tab> */}
            </TabList>

            <TabPanels>
                <TabPanel>
                    <GemListingSubTool
                        bodyOnly={true}
                        scope={{ vendorOrganizationId: vendorOrganization?.id }}
                    />
                </TabPanel>
                <TabPanel>
                    <BankAccountManagement
                        vendorOrganization={vendorOrganization}
                    />
                </TabPanel>
                <TabPanel>
                    <ThirdPartyReferenceEntities
                        vendorOrganization={vendorOrganization}
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

const SingleVendorOrganizationManagment = (props) => {
    let vendorOrganizationId = props.match.params.subtool;

    let getSalesOrderQueryBuilder = new GetVendorOrganizationGraphQLQuery();

    let { data, error, loading } = useQuery(
        gql`
            ${getSalesOrderQueryBuilder.build({
                requestedAttrs: [
                    "legalName",
                    "id",
                    new GQLFragment("thirdPartyReferenceEntities", [
                        new GQLFragment("billDotCom", ["vendorId"]),
                    ]),
                ],
            })}
        `,
        {
            variables: {
                vendorOrganizationId,
            },
        }
    );

    return (
        <HeaderBodyShell
            header={
                <Header
                    linkTo={`/admin/vendor-organization/${vendorOrganizationId}`}
                    title={data?.getVendorOrganization?.legalName}
                />
            }
            body={
                <Query error={error} loading={loading}>
                    <Body
                        vendorOrganization={data?.getVendorOrganization}
                        {...props}
                    />{" "}
                </Query>
            }
        />
    );
};

export default SingleVendorOrganizationManagment;
