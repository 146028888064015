import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import React, { memo, useState } from "react";
import { SearchResultListItem } from "../../../stories/CloudManagementOS";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import {
    Box,
    Center,
    Flex,
    Grid,
    HStack,
    Text,
    Input,
    InputGroup,
    Link,
    InputLeftElement,
    PopoverOrModal,
} from "../../../stories/Primatives";

import Query from "../../../stories/CloudManagementOS/Apollo/Query";
import { renderAsPercentage } from "../../../utils/percentage";
import CertificateInfo from "../etc/CertificateInfo";
import { ResponsiveLine } from "@nivo/line";

import { ActionsThatNeedConfirmation } from "../../../stories/CloudManagementOS/ActionsThatNeedConfirmation";

const CHANGE_GLS_GQL_MUTATION = gql`
    mutation ChangeGemListingState($state: GemListingState!, $id: ID!) {
        changeGemListingState(state: $state, id: $id) {
            result {
                message
                type
            }
            gemListing {
                id
                state
            }
        }
    }
`;

const EDIT_PRICE_GLS_GQL_MUTATION = gql`
    mutation SetGemListingAskingPrice($gemListingId: ID!, $askingPrice: Int!) {
        setGemListingAskingPrice(
            gemListingId: $gemListingId
            askingPrice: $askingPrice
        ) {
            result {
                type
            }
            gemListing {
                id
                price
                askingPrice
                currentRelativeDiscountPercentage
            }
        }
    }
`;

const SEARCH_RELATIVE_PRICING_HISTORY_FOR_GEM_SPEC = gql`
    query SearchRelativePricingHistoryForGemSpec($gemSpecs: GemSpecs!) {
        searchRelativePricingHistoryForGemSpec(gemSpecs: $gemSpecs) {
            relativePrice
            createdAt
            algo
        }
    }
`;

const FORCE_GEM_LISTING_TO_UPDATE_RELATIVE_PRICE = gql`
    mutation ForceGemListingToUpdateRelativePrice($gemListingId: ID!) {
        forceGemListingToUpdateRelativePrice(gemListingId: $gemListingId)
    }
`;

function renderModelType(props) {
    console.log("IDFDNDFSFDS", props.itemIdentifierIcon);
    return (
        <Grid h="100%" templateRows="2.5fr 1fr">
            <Center w="100%">
                <Box w="4rem" h="4rem">
                    {props?.itemIdentifierIcon({
                        color: "#000000",
                        height: "4rem",
                    })}
                </Box>
            </Center>

            <Center>
                <HStack>
                    <div>
                        {props.item &&
                            props.item.data &&
                            props.item.data.node.certifiedGem &&
                            props.item.data.node.certifiedGem.specs.carat}
                    </div>
                    <div>
                        {props.item &&
                            props.item.data &&
                            props.item.data.node.certifiedGem &&
                            props.item.data.node.certifiedGem.specs.clarity}
                    </div>
                    <div>
                        {props.item &&
                            props.item.data &&
                            props.item.data.node.certifiedGem &&
                            props.item.data.node.certifiedGem.specs.color}
                    </div>
                    <div>
                        {props.item &&
                            props.item.data &&
                            props.item.data.node.certifiedGem &&
                            getConstToLanguage(
                                props.item.data.node.certifiedGem.specs.cut
                            )}
                    </div>
                </HStack>
            </Center>
        </Grid>
    );
}

function renderIdentifier(props) {
    return <CertificateInfo gemListing={props.item.data.node} />;
}

function renderOwner(props) {
    if (props.userType === "admin") {
        return (
            <Flex direction="column">
                <Text fontSize="xs" m="0" lineHeight="1rem">
                    {getConstToLanguage("CREATED_FOR")}
                    {props.item &&
                        props.item.data &&
                        props.item.data.node.vendorOrganization &&
                        props.item.data.node.vendorOrganization.legalName}
                </Text>
                <Text fontSize="xs" lineHeight="1rem">
                    {getConstToLanguage("BY")}{" "}
                    {props?.item?.data?.node?.createdBy?.person?.givenName}{" "}
                    {props?.item?.data?.node?.createdBy?.person?.familyName}
                </Text>
            </Flex>
        );
    } else if (props.userType === "vendor") {
        console.log(props.item.data.node.createdBy);

        if (props.item.data.node.createdBy.__typename === "SystemProcess") {
            return (
                <div className="gem-listing-owner-as-vendor">
                    {getConstToLanguage("CREATED_BY")}
                    {" auto upload"}
                </div>
            );
        } else if (props.item.data.node.createdBy.__typename === "Vendor") {
            return (
                <div className="gem-listing-owner-as-vendor">
                    {getConstToLanguage("CREATED_BY")}{" "}
                    {props.item &&
                        props.item.data &&
                        props.item.data.node.createdBy &&
                        props.item.data.node.createdBy.person.givenName}{" "}
                    {props.item &&
                        props.item.data &&
                        props.item.data.node.createdBy &&
                        props.item.data.node.createdBy.person.familyName}
                </div>
            );
        }
    }
}

function renderMeta(props) {
    return (
        <div>
            {props.item && props.item.data && props.item.data.node.createdAt}
        </div>
    );
}

const getDealBanner = (currentDiscount) => {
    if (currentDiscount !== null) {
        let tagName;
        if (currentDiscount < 10) {
            tagName = "BAD_DEAL";
        } else if (currentDiscount >= 10 && currentDiscount < 20) {
            tagName = "MID_DEAL";
        } else if (currentDiscount >= 20) {
            tagName = "GOOD_DEAL";
        }
        return tagName;
    }
};

function PriceEdit(props) {
    const [priceEditMode, setPriceEditMode] = useState(false);
    const [newPrice, setNewPrice] = useState(props.gemListing.askingPrice);

    const updateCache = (cache, { data }) => {
        setPriceEditMode(false);
    };

    const [
        setGemListingAskingPrice,
        { data, loading, error },
    ] = useMutation(EDIT_PRICE_GLS_GQL_MUTATION, { update: updateCache });

    if (priceEditMode) {
        return (
            <div className="price-and-toolbar">
                <input
                    onChange={(e) => setNewPrice(e.target.value)}
                    value={newPrice}
                ></input>
                <div className="submit-or-cancel">
                    <div
                        onClick={(e) =>
                            !loading &&
                            setGemListingAskingPrice({
                                variables: {
                                    askingPrice: parseInt(newPrice),
                                    gemListingId: props.gemListing.id,
                                },
                            })
                        }
                    >
                        <props.iconography.SUBMIT_ICON.component
                            onClick={(e) =>
                                !loading &&
                                setGemListingAskingPrice({
                                    variables: {
                                        askingPrice: parseInt(newPrice),
                                        gemListingId: props.gemListing.id,
                                    },
                                })
                            }
                        />
                    </div>
                    <div onClick={(e) => setPriceEditMode(false)}>
                        <props.iconography.CANCEL_ICON.component />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="price-and-toolbar">
                <div>
                    {props.gemListing.price
                        ? `$${props.gemListing.price}`
                        : "UNSET"}
                </div>
                <div>
                    {props.gemListing.askingPrice
                        ? `$${props.gemListing.askingPrice}`
                        : "UNSET"}
                </div>
                <div onClick={(e) => setPriceEditMode(true)}>
                    {<props.iconography.EDIT_ICON.component />}
                </div>
            </div>
        );
    }
}

function renderSecondaryInfo(props) {
    return (
        <div>
            {props?.item?.data?.node?.videoLink && (
                <Link href={props?.item?.data?.node?.videoLink} target="_blank">
                    {getConstToLanguage("source video link")}
                </Link>
            )}
            {props.item.data.node &&
                props.item.data.node.timeSold &&
                getConstToLanguage("TIME_SOLD") +
                    " : " +
                    DateTime.fromISO(props.item.data.node.timeSold).toFormat(
                        "fff"
                    )}
        </div>
    );
}

const RelativePriceBody = ({ gemListing }) => {
    const { loading, error, data } = useQuery(
        SEARCH_RELATIVE_PRICING_HISTORY_FOR_GEM_SPEC,
        {
            variables: {
                gemSpecs: {
                    carat: gemListing.certifiedGem.specs.carat,
                    clarity: gemListing.certifiedGem.specs.clarity,
                    shape: gemListing.certifiedGem.specs.shape,
                    cut: gemListing.certifiedGem.specs.cut,
                    color: gemListing.certifiedGem.specs.color,
                },
            },
        }
    );

    let dataForGraph = [
        {
            id: "relative_price",
            data: data?.searchRelativePricingHistoryForGemSpec.map(
                (relativePriceDatum) => ({
                    y: relativePriceDatum.relativePrice,
                    x: new Date(relativePriceDatum.createdAt)
                        .toISOString()
                        .substring(0, 10),
                })
            ),
        },
    ];

    const commonProperties = {
        animate: true,
        enableSlices: "x",
    };

    return (
        <Query loading={loading} error={error}>
            <Box w="100%" h="20">
                {data && (
                    <ResponsiveLine
                        {...commonProperties}
                        data={dataForGraph}
                        xScale={{
                            type: "time",
                            format: "%Y-%m-%d",
                            useUTC: false,
                            precision: "day",
                        }}
                        xFormat="time:%Y-%m-%d"
                        yScale={{
                            type: "linear",
                            // stacked: boolean("stacked", false),
                        }}
                        axisLeft={
                            {
                                // legend: "linear scale",
                                // legendOffset: 12,
                            }
                        }
                        axisBottom={{
                            format: () => "",
                            // tickValues: "every 2 days",
                            // legend: "time scale",
                            // legendOffset: -12,
                        }}
                        // somehow need
                        colors={["#9A7B2D"]}
                        curve={"linear"}
                        // pointSymbol={CustomSymbol}
                        pointSize={3}
                        pointBorderWidth={0}
                        pointBorderColor={{
                            from: "color",
                            modifiers: [["darker", 0.3]],
                        }}
                        useMesh={true}
                        enableSlices={false}
                    />
                )}
            </Box>
        </Query>
    );
};

const RelativePrice = ({ gemListing }) => {
    return (
        <PopoverOrModal
            header={getConstToLanguage("RELATIVE_PRICING_HISTORY")}
            variant="popover"
            trigger="click"
            isLazy
            lazyBehavior={"keepMounted"}
            triggerBody={
                <Box>
                    <Text as="s" fontSize="sm">
                        {gemListing.latestRelativePrice
                            ? `$${gemListing.latestRelativePrice.relativePrice}`
                            : "??"}
                    </Text>
                </Box>
            }
            body={<RelativePriceBody gemListing={gemListing} />}
        />
    );
};

function renderPrimaryInfo(props) {
    let percentageClasses = "gem-listing-pricing-info__pricing__discount";

    if (
        props.item.data.node &&
        props.item.data.node.currentRelativeDiscountPercentage
    ) {
        if (props.item.data.node.currentRelativeDiscountPercentage < 10) {
            percentageClasses += " ";
        }

        if (
            props.item.data.node.currentRelativeDiscountPercentage >= 10 &&
            props.item.data.node.currentRelativeDiscountPercentage < 30
        ) {
            percentageClasses += " gem-listing-deal-indicator_mid_deal";
        }

        if (props.item.data.node.currentRelativeDiscountPercentage >= 30) {
            percentageClasses += " gem-listing-deal-indicator_good_deal";
        }
    }
    return (
        <div className="gem-listing-pricing-info">
            <RelativePrice gemListing={props.item.data.node} />
            <div className="gem-listing-pricing-info__pricing">
                <div className="gem-listing-pricing-info__pricing__price">
                    <PriceEdit
                        gemListing={props.item.data.node}
                        iconography={props.iconography}
                    />
                </div>
                <div className={percentageClasses}>
                    {renderAsPercentage(
                        props.item.data.node &&
                            props.item.data.node
                                .currentRelativeDiscountPercentage
                    )}
                </div>
            </div>
        </div>
    );
}

function genActionsAsAdmin(gemListing, mutations) {
    switch (gemListing.state) {
        case "ON_MARKET":
            return [
                {
                    name: "SET_AS_SOLD",
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: { state: "SOLD", id: gemListing.id },
                        }),
                    needsConfirmation: true,
                },
                {
                    name: "FORCE_RELATIVE_PRICE_UPDATE",
                    onClick: (e) =>
                        mutations.forceGemListingToUpdateRelativePrice({
                            variables: { gemListingId: gemListing.id },
                        }),
                },
            ];
        case "VERIFICATION_REQUESTED":
            return [
                {
                    name: "VERIFY",
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: { state: "VERIFIED", id: gemListing.id },
                        }),
                },
                {
                    name: "DENY",
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: { state: "DENIED", id: gemListing.id },
                        }),
                },
                {
                    name: "FORCE_RELATIVE_PRICE_UPDATE",
                    onClick: (e) =>
                        mutations.forceGemListingToUpdateRelativePrice({
                            variables: { gemListingId: gemListing.id },
                        }),
                },
            ];
        default:
            return;
    }
}

function genActionsAsVendor(gemListing, mutations) {
    switch (gemListing.state) {
        case "ON_MARKET":
            return [
                {
                    name: "TAKE_OFF_MARKET",
                    needsConfirmation: true,
                    needsConfirmationBody: getConstToLanguage(
                        "_TAKE_OFF_MARKET_CONFIRMATION"
                    ),
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: {
                                state: "OFF_MARKET",
                                id: gemListing.id,
                            },
                        }),
                },
                {
                    name: "ARCHIVE",
                    needsConfirmation: true,
                    needsConfirmationBody: getConstToLanguage(
                        "_ARCHIVE_CONFIRMATION"
                    ),
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: { state: "ARCHIVED", id: gemListing.id },
                        }),
                },
            ];
        case "NEW":
            return [
                {
                    name: "REQUEST_VERIFICATION",
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: {
                                state: "VERIFICATION_REQUESTED",
                                id: gemListing.id,
                            },
                        }),
                },
                {
                    name: "ARCHIVE",
                    needsConfirmationBody: getConstToLanguage(
                        "_ARCHIVE_CONFIRMATION"
                    ),
                    needsConfirmation: true,
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: { state: "ARCHIVED", id: gemListing.id },
                        }),
                },
            ];
        case "VERIFICATION_REQUESTED":
            return [
                {
                    name: "ARCHIVE",
                    needsConfirmationBody: getConstToLanguage(
                        "_ARCHIVE_CONFIRMATION"
                    ),
                    needsConfirmation: true,
                    onClick: (e) => {
                        mutations.changeGemListingState({
                            variables: { state: "ARCHIVED", id: gemListing.id },
                        });
                    },
                },
            ];
        case "OFF_MARKET":
            return [
                {
                    name: "PUT_ON_MARKET",
                    needsConfirmation: true,
                    needsConfirmationBody: getConstToLanguage(
                        "_PUT_ON_MARKET_CONFIRMATION"
                    ),
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: {
                                state: "ON_MARKET",
                                id: gemListing.id,
                            },
                        }),
                },
            ];
        case "VERIFIED":
            return [
                {
                    name: "PUT_ON_MARKET",
                    needsConfirmation: true,
                    needsConfirmationBody: getConstToLanguage(
                        "_PUT_ON_MARKET_CONFIRMATION"
                    ),
                    onClick: (e) =>
                        mutations.changeGemListingState({
                            variables: {
                                state: "ON_MARKET",
                                id: gemListing.id,
                            },
                        }),
                },
            ];
        default:
            return;
    }
}

const GemListingListItem = memo((props) => {
    const updateCache = (cache, { data }) => {
        // Fetch the todos from the cache

        const existingGemListingsInState = cache.readQuery({
            query: props.gqlQuery,
            variables: props,
        });

        let newListOfGemListings = existingGemListingsInState.searchGemListingsInState.edges.filter(
            (gemListing) =>
                gemListing.node.id !== data.changeGemListingState.gemListing.id
        );

        cache.writeQuery({
            query: props.gqlQuery,
            variables: props,
            data: {
                ...existingGemListingsInState,
                searchGemListingsInState: {
                    ...existingGemListingsInState.searchGemListingsInState,
                    edges: newListOfGemListings,
                    totalCount:
                        existingGemListingsInState.searchGemListingsInState
                            .totalCount - 1,
                },
            },
        });
    };

    const generateBadges = () => {
        let badges = [];
        let dealBanner = getDealBanner(
            props?.item?.data?.node?.currentRelativeDiscountPercentage
        );
        if (dealBanner) {
            badges.push(dealBanner);
        }

        console.log(
            `Gem Listing is reserved: ${
                props?.item?.data?.node?.state === "ON_MARKET" &&
                props.isReserved
            }`
        );

        if (
            props?.item?.data?.node?.state === "ON_MARKET" &&
            props.isReserved
        ) {
            badges.push("CURRENTLY_RESERVED");
        }

        return badges;
    };

    const [
        changeGemListingState,
        { data, loading, error },
    ] = useMutation(CHANGE_GLS_GQL_MUTATION, { update: updateCache });

    const [forceGemListingToUpdateRelativePrice] = useMutation(
        FORCE_GEM_LISTING_TO_UPDATE_RELATIVE_PRICE
    );

    // const [caratRange, setCaratRange] = useState([0.0, 50.0]);

    let actions;
    if (props.userType === "vendor") {
        actions = genActionsAsVendor(props.item.data.node, {
            changeGemListingState,
        });
    } else if (props.userType === "admin") {
        actions = genActionsAsAdmin(props.item.data.node, {
            changeGemListingState,
            forceGemListingToUpdateRelativePrice,
        });
    }

    return (
        <SearchResultListItem
            id={props.item.data.node.id}
            iconography={props.iconography}
            modelType={renderModelType(props)}
            menu={props.menu}
            indentifier={renderIdentifier(props)}
            meta={renderMeta(props)}
            secondaryInfo={renderSecondaryInfo(props)}
            owner={renderOwner(props)}
            primaryInfo={renderPrimaryInfo(props)}
            createdAt={props.item.data && props.item.data.node.createdAt}
            actions={actions}
            bg={"white"}
            badges={generateBadges()}
            badgeColorMapping={{
                GOOD_DEAL: "green",
            }}
        />
    );
});

export default GemListingListItem;
