import { Icon } from "@chakra-ui/react";
import React from "react";

export const MapLocationIcon = ({}) => (
    <Icon width="98" height="98" viewBox="0 0 98 98">
        <path
            d="M49.0578 64.6562C47.8805 64.6562 46.7473 63.8837 45.6857 62.3627L36.2668 48.0098C34.5688 45.5938 33.6296 42.5551 33.6296 39.4722C33.6296 35.4025 35.2289 31.5744 38.1349 28.699C41.0375 25.8237 44.8962 24.238 49 24.238C53.1037 24.238 56.9591 25.8237 59.8651 28.699C62.771 31.5778 64.3703 35.4025 64.3703 39.4756C64.3703 42.5483 63.4346 45.5802 61.7332 48.0132L52.3245 62.3491C51.3615 63.8565 50.2794 64.6324 49.1123 64.6562C49.0953 64.6562 49.0782 64.6562 49.0578 64.6562ZM49 25.6127C45.2603 25.6127 41.7453 27.0555 39.1013 29.6756C36.4573 32.2923 35.0044 35.7734 35.0044 39.4756C35.0044 42.2795 35.8551 45.0358 37.4033 47.2408L46.8222 61.5971C47.3564 62.3627 48.1833 63.2849 49.0578 63.2849C49.0646 63.2849 49.0748 63.2849 49.0817 63.2849C49.9289 63.2678 50.6844 62.3627 51.171 61.6039L60.6035 47.2339C62.1483 45.0289 62.9956 42.2727 62.9956 39.4756C62.9956 35.7734 61.5392 32.2923 58.8987 29.6756C56.2513 27.0555 52.7362 25.6127 49 25.6127Z"
            fill="#9A7B2D"
        />
        <path
            d="M49 48.1459C44.1783 48.1459 40.2549 44.2565 40.2549 39.4756C40.2549 34.6947 44.1783 30.8019 49 30.8019C53.8218 30.8019 57.7452 34.6913 57.7452 39.4756C57.7452 44.2599 53.8218 48.1459 49 48.1459ZM49 32.1767C44.9337 32.1767 41.6262 35.4501 41.6262 39.4756C41.6262 43.5011 44.9337 46.7746 49 46.7746C53.0663 46.7746 56.3738 43.5011 56.3738 39.4756C56.3738 35.4501 53.0663 32.1767 49 32.1767Z"
            fill="#9A7B2D"
        />
        <path
            d="M68.7974 54.407H56.7175L55.8158 55.7783H67.9773L76.9164 72.3873H30.0227L21.0836 55.7783H42.1876L41.2859 54.407H18.7867L29.2026 73.7586H79.2133L68.7974 54.407Z"
            fill="#9A7B2D"
        />
    </Icon>
);
