import gql from "graphql-tag";

const GQL_Q_GET_VENDOR_ORGANIZATION = gql`
    query GetVendorOrganization($vendorOrganizationId: ID!) {
        getVendorOrganization(vendorOrganizationId: $vendorOrganizationId) {
            legalName
            id
            thirdPartyReferenceEntities {
                billDotCom {
                    vendorId
                }
            }
        }
    }
`;

export { GQL_Q_GET_VENDOR_ORGANIZATION };
