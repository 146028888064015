import {
  CHANGE_GEM_LISTING_CSV_UPLOAD_OPTION,
  ASYNC_START,
  ASYNC_END,
  TRADER_UPLOAD_GEM_LISTING_CSV
} from "../constants/actionTypes";

import { buildInitialGemListingRow } from "./initializers/gemListing";


export default (
  state = {
    options: {
      useRapnetPricing: true,
      autoSubmitForVerfication: true,
      uploadInProgress: false,
      newlyCreatedGemListingItems: []
    }
  },
  action
) => {
  switch (action.type) {
    case CHANGE_GEM_LISTING_CSV_UPLOAD_OPTION:
      return {
        ...state,
        options: { ...state.options, [action.key]: action.value }
      };
    case TRADER_UPLOAD_GEM_LISTING_CSV:
      return {
        ...state,
        newlyCreatedGemListingItems: action.payload.gemListings.map(gemListing => {
          return buildInitialGemListingRow(gemListing)})
      }
    case ASYNC_START:
      if (action.subtype === TRADER_UPLOAD_GEM_LISTING_CSV) {
        return { ...state, uploadInProgress: true };
      }
      break;
    case ASYNC_END:
      if (action.subtype === TRADER_UPLOAD_GEM_LISTING_CSV) {
        return { ...state, uploadInProgress: false };
      }
      break;
    default:
      return state;
  }
};
