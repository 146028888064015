import React from 'react';
import { LinkIcon } from "../../components/IconsV2"
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping"
import {
    Popover,
    PopoverTrigger,
    Box,
    Link,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverBody,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button
} from "."
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const Links = ({ links, variant = "modal" }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    if (variant === 'popover') {
        return <Popover trigger="hover" placement="right">
            <PopoverTrigger>
                <Box><LinkIcon /></Box>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>{getConstToLanguage('LINKS')}</PopoverHeader>
                <PopoverBody>
                    <Flex direction="column">
                        {Object.entries(links).map(([linkItem, linkSetting], idx) =>
                            <Link key={idx} href={linkSetting.link} isExternal>
                                {linkItem}<ExternalLinkIcon mx="2px" />
                            </Link>)}
                    </Flex>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    } else if (variant === 'modal') {

        return (<>
            <Box onClick={onOpen}><LinkIcon /></Box>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{getConstToLanguage('LINKS')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column">
                            {Object.entries(links).map(([linkItem, linkSetting]) => <Link href={linkSetting.link} isExternal> {linkItem}<ExternalLinkIcon mx="2px" /></Link>)}
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>)
    }


}
