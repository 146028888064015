import { useMutation } from "@apollo/react-hooks";
import React from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Loader,
    LoadingButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useDisclosure,
    VStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    PopoverCloseButton,
    PopoverHeader,
} from "../../../Primatives";
import { getConstToLanguage } from "../../ConstToLanguageMapping";
import { useCurrentUser } from "../../hooks";

import { ActionsThatNeedConfirmation } from "../../ActionsThatNeedConfirmation";

const SFTPGeneratedCredentials = ({
    isGenerating,
    generatedSshPrivateKey,
    bgColor,
}) => {
    return isGenerating ? (
        <Loader />
    ) : (
        <Box>
            <Heading as="h6" size="sm">
                {getConstToLanguage("GENERATED_SFTP_PRIVATE_KEY")}
            </Heading>
            <Textarea
                bg={bgColor}
                value={generatedSshPrivateKey?.privateKey}
                readOnly={true}
            />
        </Box>
    );
};

const SFTPInputCredentials = ({
    bgColor,
    handleSSHPrivateKeyInputChange,
    sshPrivateKeyValue,
}) => {
    return (
        <Box>
            <Textarea
                value={sshPrivateKeyValue}
                bg={bgColor}
                placeholder={getConstToLanguage("SSH_PRIVATE_KEY")}
                onChange={handleSSHPrivateKeyInputChange}
            />
        </Box>
    );
};

const DownloadAs = ({ downloadPrivateKeyAsMutation, sshPrivateKey }) => {
    console.log("downloadPrivateKeyAsMutation", downloadPrivateKeyAsMutation);
    const [downloadPrivateKeyAs] = useMutation(
        downloadPrivateKeyAsMutation,
        {}
    );

    return (
        <Popover trigger="hover">
            <PopoverTrigger>
                <Button>{getConstToLanguage("DOWNLOAD_AS")}</Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                    <Box>
                        <Button
                            onClick={async () => {
                                let data = await downloadPrivateKeyAs({
                                    variables: { sshPrivateKey, type: "PEM" },
                                });

                                if (data?.data?.convertPrivateKeyToPPKFormat) {
                                    window.open(
                                        data?.data?.convertPrivateKeyToPPKFormat
                                    );
                                }
                            }}
                        >
                            {getConstToLanguage("PEM")}
                        </Button>
                        <Button
                            onClick={async () => {
                                let data = await downloadPrivateKeyAs({
                                    variables: { sshPrivateKey, type: "PPK" },
                                });

                                if (data?.data?.convertPrivateKeyToPPKFormat) {
                                    window.open(
                                        data?.data?.convertPrivateKeyToPPKFormat
                                    );
                                }
                            }}
                        >
                            {getConstToLanguage("PPK")}
                        </Button>
                    </Box>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

const SFTPCredentialsView = ({
    header,
    isOpen,
    mode,
    onClose,
    generatedSshPrivateKey,
    isGenerating,
    testConnectionWithSFTPServerMutation,
    downloadPrivateKeyAsMutation,
}) => {
    let [sshPrivateKey, setSSHPrivateKey] = React.useState("");

    console.log("SLDJF:LSKDJF", downloadPrivateKeyAsMutation);

    let handleSSHPrivateKeyInputChange = (e) => {
        let inputValue = e.target.value;
        setSSHPrivateKey(inputValue);
    };

    const [
        testConnectionWithSFTPServer,
        {
            data: testConnectionWithSFTPServerData,
            loading: testConnectionWithSFTPServerLoading,
            error: testConnectionWithSFTPServerError,
        },
    ] = useMutation(testConnectionWithSFTPServerMutation, {
        fetchPolicy: "no-cache",
    });

    let bgColor = null;
    console.log(
        "testConnectionWithSFTPServerData",
        testConnectionWithSFTPServerData
    );

    switch (testConnectionWithSFTPServerData?.testSSHCredentials?.type) {
        case "PASS":
            bgColor = "green";
            break;
        case "ERROR":
            bgColor = "red";
            break;
        default:
            bgColor = null;
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{header}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {mode === "generated" && (
                        <SFTPGeneratedCredentials
                            isGenerating={isGenerating}
                            generatedSshPrivateKey={generatedSshPrivateKey}
                            bgColor={bgColor}
                        />
                    )}
                    {mode === "input" && (
                        <SFTPInputCredentials
                            sshPrivateKeyValue={sshPrivateKey}
                            bgColor={bgColor}
                            handleSSHPrivateKeyInputChange={
                                handleSSHPrivateKeyInputChange
                            }
                        />
                    )}
                </ModalBody>

                <ModalFooter>
                    <DownloadAs
                        sshPrivateKey={
                            generatedSshPrivateKey
                                ? generatedSshPrivateKey.privateKey.replace(
                                      /\\n/g,
                                      "\n"
                                  )
                                : sshPrivateKey
                        }
                        downloadPrivateKeyAsMutation={
                            downloadPrivateKeyAsMutation
                        }
                    />

                    <LoadingButton
                        isLoading={testConnectionWithSFTPServerLoading}
                        variant="cloud-management-os.primary"
                        onClick={() =>
                            testConnectionWithSFTPServer({
                                variables: {
                                    sshPrivateKey:
                                        mode === "generated"
                                            ? generatedSshPrivateKey?.privateKey
                                            : sshPrivateKey,
                                },
                            })
                        }
                        c
                    >
                        {getConstToLanguage("TEST_CONNECTION")}
                    </LoadingButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export const UserAttributeAndValue = ({ attributeConst, value }) => {
    return (
        <Flex w="100%" paddingLeft={4} alignItems="center">
            <Heading as="h4" size="sm" marginRight={4}>
                {getConstToLanguage(attributeConst)}:
            </Heading>
            <Box>{value}</Box>
        </Flex>
    );
};

export const ManageSFTPCredentials = ({
    generateSFTPCredentialsMutation,
    testConnectionWithSFTPServerMutation,
    downloadPrivateKeyAsMutation,
}) => {
    const [
        generateSFTPCredentials,
        {
            data: generateSFTPCredentialsData,
            loading: generateSFTPCredentialsLoading,
            error: generateSFTPCredentialsError,
        },
    ] = useMutation(generateSFTPCredentialsMutation);
    const {
        isOpen: isOpenSFTPTester,
        onOpen: onOpenSFTPTester,
        onClose: onCloseSFTPTester,
    } = useDisclosure();
    const {
        isOpen: isOpenGenerateSFTPCredentials,
        onOpen: onOpenGenerateSFTPCredentials,
        onClose: onCloseGenerateSFTPCredentials,
    } = useDisclosure();
    let { getCurrentVendorOrganizationId } = useCurrentUser();

    return (
        <VStack spacing={3}>
            <Text>
                {getConstToLanguage("MANAGE_SFTP_CREDENTIALS_DESCRIPTION")}
            </Text>
            <UserAttributeAndValue
                attributeConst={"GEM_LISTING_INVENTORY_CSV_SFTP_HOST"}
                value={
                    process.env.REACT_APP__GEM_LISTING_INVENTORY_CSV_SFTP_HOST
                }
            />
            <UserAttributeAndValue
                attributeConst="USER_NAME"
                value={getCurrentVendorOrganizationId()}
            />
            <HStack spacing={4} justifyContent="end" w="100%">
                <ActionsThatNeedConfirmation
                    confirmationBody={getConstToLanguage(
                        "_GENERATE_KEY_PAIR_CONFIRMATION"
                    )}
                    body={
                        <Button variant="cloud-management-os.primary" size="sm">
                            {getConstToLanguage("GENERATE_KEY_PAIR")}
                        </Button>
                    }
                    funcToRun={async () => {
                        await onOpenGenerateSFTPCredentials();
                        generateSFTPCredentials();
                    }}
                />
                {isOpenGenerateSFTPCredentials && (
                    <SFTPCredentialsView
                        title={getConstToLanguage("GENERATED_SFTP_CREDENTIALS")}
                        generatedSshPrivateKey={
                            generateSFTPCredentialsData?.generateNewVendorOrganizationAPISFTPCredentials
                        }
                        onClose={onCloseGenerateSFTPCredentials}
                        isGenerating={generateSFTPCredentialsLoading}
                        isOpen={isOpenGenerateSFTPCredentials}
                        mode="generated"
                        testConnectionWithSFTPServerMutation={
                            testConnectionWithSFTPServerMutation
                        }
                        downloadPrivateKeyAsMutation={
                            downloadPrivateKeyAsMutation
                        }
                    />
                )}
                <Button
                    variant="cloud-management-os.primary"
                    size="sm"
                    onClick={onOpenSFTPTester}
                >
                    {getConstToLanguage("TEST_CONNECTION")}
                </Button>
                {isOpenSFTPTester && (
                    <SFTPCredentialsView
                        title={getConstToLanguage("TEST_SFTP_CONNECTION")}
                        isOpen={isOpenSFTPTester}
                        mode="input"
                        testConnectionWithSFTPServerMutation={
                            testConnectionWithSFTPServerMutation
                        }
                        onClose={onCloseSFTPTester}
                        downloadPrivateKeyAsMutation={
                            downloadPrivateKeyAsMutation
                        }
                    />
                )}
            </HStack>
        </VStack>
    );
};
