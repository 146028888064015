import React from 'react';
import { Box, Text, HStack, Center, VStack, SimpleGrid, Spacer } from "@chakra-ui/react"
import { Logo } from "../Branding/Icons/Logo"
import { ContactIcon } from "../Primatives/ContactIcon";


/**
 * Primary UI component for user interaction
 */
export const Footer = ({ personFullName, title, personImage, description, ...props }) => {
    let titleStyles

    titleStyles = {
        fontFamily: 'heading',
        fontWeight: 'bold',
        color: 'greyish',
        fontSize: 'md'
    }
    return (

        <SimpleGrid bg="off-black" w="100%" columns={[1, 1, 3, 3]} p={[8, 16, 24, 24]}>
            <Center>
                <VStack color="gray.200">
                    <Text as={'h2'}
                        {...titleStyles}>
                        FAQ</Text>
                    <Box>How it Works</Box>
                    <Box>Payment</Box>
                    <Box>Other</Box>
                </VStack>
            </Center>
            <Box><VStack>
                <Logo colorMode="dark" />
                <Box>
                    <Text color="gray.200" align="center" fontSize={'md'}>
                        We have a long history in the diamond industry. Our knowledge and experience spans across both the diamond trading and jewelry making and design industries. We are your trusted and transparent diamond shop
                </Text>
                </Box>
            </VStack>
            </Box>
            <Center>
                <VStack color="gray.200">
                    <Text as={'h2'}
                        {...titleStyles}>
                        Contact Us </Text>
                    <a href='mailto:info@carbonext.io' > <Box>info@carbonext.io</Box> </a>
                    <Box>+1 646.265.9224</Box>
                    <Box>22 West 48th New York, NY 10036</Box>
                    <Spacer />
                    <HStack>
                        <ContactIcon media={'email'} url={'mailto:info@carbonext.io'} />
                        <ContactIcon media={'instagram'} url={'https://www.instagram.com/carbonext.io'} />
                        <ContactIcon media={'facebook'} url={'https://www.facebook.com/Carbonext-Diamonds-103332271602554'} />
                    </HStack>
                </VStack>
            </Center>
        </SimpleGrid>
    );
}

Footer.propTypes = {

};

Footer.defaultProps = {

};
