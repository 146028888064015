import gql from "graphql-tag";

const LOGIN_AS_VENDOR = gql`
  mutation loginAsVendor(
    $email: String!
    $password: String!
  ) {
    loginAsVendor(
      email: $email
      password: $password
    ) {
      userAuth {
        type
        token
        userType
      }
      vendorOrganizationLegalName
    }
  }
`;

export { LOGIN_AS_VENDOR };
