import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../../lib/GraphCallBuilder";

function MatchComparableGemListingsFromSpecsGraphQLQuery() {
    return new GraphqlQueryBuilder({
        api: "matchComparableGemListingsFromSpecs",
        type: "query",
        args: [new GQLInputArg("specs", "[GemSpecInput!]", true)],
    });
}

export { MatchComparableGemListingsFromSpecsGraphQLQuery };
