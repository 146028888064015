import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../../lib/GraphCallBuilder";

function SearchGemListingsOnMarketGraphQLQuery() {
    return new GraphqlQueryBuilder({
        api: "searchGemListingsOnMarket",
        type: "query",
        args: [new GQLInputArg("searchParam", "GemListingSearchParam", false)],
    });
}

export { SearchGemListingsOnMarketGraphQLQuery };
