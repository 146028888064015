import { Icon } from "@chakra-ui/react";
import React from "react";

export const EmeraldShapeIcon = ({ color = "#000000", height = "auto" }) => (
    <Icon viewBox="0 0 405.191 730.113" fill={color} height={height} width={'100%'}>
        <g
            id="Diamonds_Emerald_Outlined"
            data-name="Diamonds_Emerald Outlined"
            transform="translate(-95.042 -56.019)"
        >
            <g id="Diamonds">
                <g id="Grupo_40" data-name="Grupo 40">
                    <g id="XMLID_1364_">
                        <path
                            id="XMLID_1497_"
                            d="M407.14,96.38l52.649,52.649v.627l38.39-27.123C473,97.352,458.862,83.216,433.665,58.019h-1.4L404.926,96.38Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1496_"
                            d="M404.926,96.38l27.336-38.359H162.741L190.1,96.38Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1397_"
                            d="M459.789,692.176l-.269.269,38.5,27.073.217-.216V122.588c-.019-.018-.035-.035-.053-.053l-38.39,27.123V692.176Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1396_"
                            d="M407.14,744.825h-2.87l27.886,39.043h1.51l64.35-64.351-38.5-27.073Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1395_"
                            d="M135.486,149.03l52.65-52.649H190.1L162.741,58.022H161.61L97.043,122.589v.1l38.444,27.145v-.8Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1394_"
                            d="M404.27,744.825H190.986l-27.871,39.043H432.156Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1393_"
                            d="M188.136,744.825l-52.468-52.467L97.23,719.489c25.1,25.094,39.228,39.228,64.379,64.379h1.505l27.871-39.043h-2.849Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1392_"
                            d="M135.486,692.176V149.833L97.042,122.688V719.3l.188.188,38.438-27.131C135.606,692.3,135.549,692.238,135.486,692.176Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1391_"
                            d="M378.345,133.681l26.581-37.3H190.1l26.6,37.3Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1390_"
                            d="M421.145,665.458l38.375,26.987.269-.269V149.657l-38.644,27.3Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1389_"
                            d="M379.025,133.681l42.12,42.12v1.158l38.644-27.3v-.627L407.14,96.382h-2.214l-26.581,37.3h.68Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1388_"
                            d="M174.131,175.8l42.12-42.12h.45l-26.6-37.3h-1.962l-52.65,52.649v.8l38.644,27.287V175.8Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1387_"
                            d="M216.25,708.208l-42.12-42.12v-.879l-38.462,27.149,52.468,52.467h2.85l26.139-36.617h-.875Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1386_"
                            d="M459.52,692.445l-38.375-26.987v.631c-16.449,16.448-25.671,25.671-42.12,42.12h-.908l26.153,36.617h2.87Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1385_"
                            d="M217.125,708.208l-26.139,36.617H404.27l-26.153-36.617Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1384_"
                            d="M174.131,177.12l-38.644-27.287V692.176l.182.182,38.462-27.149V177.12Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1383_"
                            d="M213.436,637.14V204.874L174.13,177.12V665.209l39.5-27.878Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1382_"
                            d="M213.436,204.749c12.3-12.3,19.218-19.217,31.49-31.49L216.7,133.681h-.45L174.13,175.8v1.319l39.306,27.754Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1381_"
                            d="M350.249,173.16l31.578,31.577,39.318-27.778V175.8l-42.12-42.12h-.68l-28.133,39.478h.037Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1380_"
                            d="M245.026,173.16H350.212l28.133-39.478H216.7l28.226,39.578Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1370_"
                            d="M381.827,204.737l.013.013V637.14l-.4.4,39.7,27.921v-488.5Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1369_"
                            d="M350.249,668.73h-.33l28.2,39.479h.908l42.12-42.12v-.631l-39.7-27.921C369.359,649.621,362.45,656.529,350.249,668.73Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1368_"
                            d="M245.026,668.73c-12.272-12.271-19.185-19.184-31.4-31.4l-39.5,27.878v.879c16.449,16.448,25.671,25.671,42.12,42.12h.875l28.182-39.479h-.281Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1367_"
                            d="M245.307,668.73l-28.182,39.478H378.117l-28.2-39.478Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1366_"
                            d="M350.249,173.16H245.026l-.1.1h0l-31.49,31.49v.125h0V637.14l.191.191h0l31.4,31.4H350.25l31.193-31.193h0l.4-.4V204.749a.158.158,0,0,1-.013-.013h0Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <path
                        id="Trazado_1661"
                        data-name="Trazado 1661"
                        d="M498.18,122.8C473,97.617,458.863,83.481,433.666,58.284H161.61L97.043,122.851V719.565l.188.188,64.379,64.379H433.666l64.35-64.351.217-.216V122.851Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
