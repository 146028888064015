import React from "react";
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
    ConditionalLink,
    VStack,
    Heading,
    Grid,
} from "../../Primatives";

import { useHistory } from "react-router-dom";
import { SubNavAndBody } from "./SubNavAndBody";

import { useParamsMatching } from "../hooks/useParamsMatching";
import { Link as ReactLink, Route } from "react-router-dom";

export const SideNavItemSubItem = ({
    subItem,
    routePrefix,
    parentItemConfig,
}) => {
    const isCurrentlySelected = useParamsMatching({
        param1: subItem?.link,
        subtool: parentItemConfig?.link,
    });

    return (
        <ConditionalLink
            as={ReactLink}
            to={routePrefix + subItem.link}
            condition={!!subItem.link}
        >
            <Box
                align="left"
                color={isCurrentlySelected === true ? "brand.500" : null}
            >
                {subItem.label}
            </Box>
        </ConditionalLink>
    );
};

export const SideNavItemSubItemList = ({
    subItems,
    routePrefix,
    parentItemConfig,
}) => {
    console.log("SDLK:JF:DLKJFDS:");
    return (
        <VStack align="left" marginLeft={4}>
            {subItems.map((subItem) => (
                <SideNavItemSubItem
                    subItem={subItem}
                    routePrefix={routePrefix}
                    parentItemConfig={parentItemConfig}
                />
            ))}
        </VStack>
    );
};

export const SideNavItem = ({ routePrefix, navItemConfig }) => {
    const isCurrentlySelected = useParamsMatching({
        subtool: navItemConfig?.link,
        param1: null,
    });

    return (
        <Box>
            <ConditionalLink
                as={ReactLink}
                to={navItemConfig.view && routePrefix + navItemConfig.link}
                condition={!!navItemConfig.link}
            >
                <Box color={isCurrentlySelected ? "brand.500" : null}>
                    {navItemConfig.navItemLabel}
                </Box>
            </ConditionalLink>
            {navItemConfig.subItems && (
                <SideNavItemSubItemList
                    subItems={navItemConfig.subItems}
                    routePrefix={routePrefix + navItemConfig.link}
                    parentItemConfig={navItemConfig}
                />
            )}
        </Box>
    );
};

export const SideNavBody = ({ navConfig, routePrefix, ref }) => {
    return (
        <VStack align="left" marginLeft={{ base: 0, md: 3 }}>
            {navConfig.map((navItemConfig) => (
                <SideNavItem
                    ref={ref}
                    navItemConfig={navItemConfig}
                    routePrefix={routePrefix}
                />
            ))}
        </VStack>
    );
};
export const SubMenuSideNavHeader = ({ title }) => {
    return (
        <Heading as="h5" size="md">
            {title}
        </Heading>
    );
};

export const SubMenuSideNav = ({ title, navConfig, routePrefix }) => {
    return (
        <Grid
            templateRows="1.5rem 1fr"
            gridGap={3}
            p={6}
            paddingLeft={{ base: 16, md: 6 }}
        >
            <SubMenuSideNavHeader title={title} navConfig={navConfig} />
            <SideNavBody navConfig={navConfig} routePrefix={routePrefix} />
        </Grid>
    );
};

export const SubNavAndBodyWithModesBody = ({ navConfig, routePrefix }) => {
    return (
        <Box>
            {navConfig
                .map((navConfigItem) => {
                    let allRoutes = [];

                    if (navConfigItem.view) {
                        allRoutes.push(
                            <Route
                                exact
                                path={routePrefix + navConfigItem.link}
                                component={() => navConfigItem.view}
                            ></Route>
                        );
                    }
                    if (navConfigItem.subItems) {
                        navConfigItem.subItems.forEach((subItem) => {
                            allRoutes.push(
                                <Route
                                    exact
                                    path={
                                        routePrefix +
                                        navConfigItem.link +
                                        subItem.link
                                    }
                                    component={() => subItem.view}
                                ></Route>
                            );
                        });
                    }
                    return allRoutes;
                })
                .flat()}
        </Box>
    );
};

export const SubNavAndBodyWithModes = ({ mode, nav, body }) => {
    const isShowSubMenu = !useParamsMatching({
        subtool: undefined,
    });

    const history = useHistory();

    switch (mode) {
        case "full":
            return <SubNavAndBody nav={nav} body={body} />;
        case "drawer":
            return (
                <Box h="100%" w="100%">
                    {nav}
                    <Drawer isOpen={isShowSubMenu} placement="right">
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton
                                onClick={() => history.goBack()}
                            />
                            <DrawerBody>{body}</DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </Box>
            );
    }
    return "";
};
