import React from "react";
import { Nav } from "../../stories/Market/Nav";
import { AboutUs as MarketingAboutUs } from "../../stories/Marketing/AboutUs";
import { Flex, useBreakpointValue, Box } from "@chakra-ui/react";
import { iconography } from "../IconsV2";
import { ApolloProvider } from "@apollo/client";
import { marketUserGraphClient } from "../../graph";
import { ContactUs } from "./ContactUs";
export const ContactUsWrapper = ({ ...props }) => {
    let slideInMenuStyling = {
        color: "white",
        bg: "off-black",
    };
    const menuVariant = useBreakpointValue({ base: "slide-in", lg: "full" });

    return (
        <ApolloProvider client={marketUserGraphClient}>
            <Flex direction="column">
                <Nav
                    colorMode="dark"
                    slideInMenuStyling={slideInMenuStyling}
                    menuVariant={menuVariant}
                    {...props}
                    iconography={iconography}
                    logOut={props.logOut}
                />
                <Box marginTop={"111.04px"}>
                    <ContactUs />
                </Box>
            </Flex>
        </ApolloProvider>
    );
};
