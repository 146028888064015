import React, { useEffect } from "react";
import { Nav } from "../../stories/Market/Nav"
import { WhyChooseUs as MarketingWhyChooseUs } from "../../stories/Marketing/WhyChooseUs"
import { useBreakpointValue, Flex } from "@chakra-ui/react"
import { iconography } from "../IconsV2"
import { ApolloProvider } from "@apollo/client";
import { marketUserGraphClient } from "../../graph";

export const WhyChooseUs = ({ ...props }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let slideInMenuStyling = {
        color: "white",
        bg: "off-black"
    }
    const menuVariant = useBreakpointValue({ base: "slide-in", lg: "full" })

    return (
        <ApolloProvider client={marketUserGraphClient}>
            <Flex direction="column" >
                <Nav colorMode="dark" slideInMenuStyling={slideInMenuStyling} menuVariant={menuVariant} {...props} iconography={iconography} logOut={props.logOut} />
                <MarketingWhyChooseUs marginTop="6.94rem" {...props} />
            </Flex>
        </ApolloProvider>
    )
}
