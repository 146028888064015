import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  VENDOR_LOGIN,
  ADMIN_LOGIN,
  MARKET_LOGIN,
  MARKET_SIGNUP,
  ADMIN_CHANGE_TOOL,
  ADMIN_HOME_PAGE_LOADED,
  VENDOR_HOME_PAGE_LOADED,
  MARKET_LOGOUT,
  VENDOR_LOGOUT,
  ADMIN_LOGOUT,
  GO_TO_SIGNUP,
  UNAUTHORIZED_ACCESS,
  ADMIN_GEM_LISTING_GO_TO_SUBTOOL,
  TRADER_GEM_LISTING_GO_TO_SUBTOOL,
  GO_TO,
} from '../constants/actionTypes';

import {isVendorLoggedIn} from '../components/helpers/currentUser'

const defaultState = {
  appName: 'Carbonext',
  token: null,
  tokenType:null,
  viewChangeCounter: 0
};



export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        tokenType: action.tokenType || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case GO_TO_SIGNUP:
      if (action.returnToUrl) {
        return { ...state, redirectTo: '/market/sign-up', returnToUrl: action.returnToUrl };
      } else {
        return { ...state, redirectTo: '/market/sign-up' };
      }
    case GO_TO:
      return { ...state, redirectTo: action.route };
    case ADMIN_HOME_PAGE_LOADED:
      if (state.tokenType !== 'admin') {
        return { ...state, redirectTo: '/admin/login' };
      } 
      return {...state}
    case VENDOR_HOME_PAGE_LOADED:
      if (!isVendorLoggedIn()) {
        return { ...state, redirectTo: '/vendor/login'};
      }
      return {...state}
    case LOGOUT:
      return { ...state, redirectTo: '/', token: null, tokenType: null, currentUser: null };
    //case MARKET_LOGOUT:
    //  return { ...state, token: null, tokenType: null, currentUser: null };
    // case SETTINGS_SAVED:
    //   return {
    //     ...state,
    //     redirectTo: action.error ? null : '/',
    //     currentUser: action.error ? null : action.payload.user
    //   };

    case MARKET_LOGIN:

      let redirectTo = '/market/gems';

      if (state.returnToUrl) {
        redirectTo = state.returnToUrl
      }
      
      return {
        ...state,
        redirectTo: action.error ? null : redirectTo,
        returnToUrl: null,
        token: action.error ? null : action.payload.user.token,
        tokenType: action.error ? null : "market",
        currentVendor: action.error ? null : action.payload.user
      };
    case ADMIN_CHANGE_TOOL:

      return {
        ...state,
        redirectTo: action.error ? null : `/admin/${action.api}`,
      };
    case VENDOR_LOGIN:
      return {
        ...state,
        redirectTo: action.error ? null : '/vendor/trader/home',
        token: action.error ? null : action.payload.vendor.token,
        currentVendor: action.error ? null : action.payload.vendor,
        tokenType: action.error ? null : "vendor",
      };
    case MARKET_SIGNUP:
      return {
        ...state,
        redirectTo: action.error ? null : '/market/login',
      };
    case UNAUTHORIZED_ACCESS:

      let apiSplit = action.path.split('/')
      let tool = apiSplit[1]
      let view = apiSplit[2]

      if (view !== 'login') {
        return {
        ...state,
          redirectTo: `/${tool}/login`,
        };

      }
      return state;
    case MARKET_LOGOUT:
      return {
        ...state,
        redirectTo: action.error ? null : '/market/login',
        token: null,
        currentAdmin: null,
        tokenType: null
      };

    case VENDOR_LOGOUT:
      return {
        ...state,
        redirectTo: action.error ? null : '/vendor/login',
        token: null,
        currentAdmin: null,
        tokenType: null
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        redirectTo: action.error ? null : '/admin/login',
        token: null,
        currentAdmin: null,
        tokenType: null
      };
    case ADMIN_GEM_LISTING_GO_TO_SUBTOOL:
      return {
        ...state,
        redirectTo: action.error ? null : `/admin/gem-listing-management/${action.subtool}`,
      };

    case TRADER_GEM_LISTING_GO_TO_SUBTOOL:
      return {
        ...state,
        redirectTo: action.error ? null : `/vendor/trader/gems/${action.subtool}`,
      };


    case ADMIN_LOGIN:

      return {
        ...state,
        redirectTo: action.error ? null : '/admin/gem-listing-management',
        token: action.error ? null : action.payload.admin.token,
        currentAdmin: action.error ? null : action.payload.admin,
        tokenType: action.error ? null : "admin",
      };
    case LOGIN:
    // case DELETE_ARTICLE:
    //   return { ...state, redirectTo: '/' };
    // case ARTICLE_PAGE_UNLOADED:
    // case EDITOR_PAGE_UNLOADED:
    // case HOME_PAGE_UNLOADED:
    // case PROFILE_PAGE_UNLOADED:
    // case PROFILE_FAVORITES_PAGE_UNLOADED:
    // case SETTINGS_PAGE_UNLOADED:
    // case LOGIN_PAGE_UNLOADED:
    // case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    default:
      return state;
  }
};