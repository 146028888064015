import marketSignUp from './reducers/marketSignUp';
import common from './reducers/common';
//import userList from './reducers/userList';
import marketLogin from './reducers/marketLogin'
import vendorLogin from './reducers/vendorLogin'
import vendorSignUp from './reducers/vendorSignUp'
import vendorHome from './reducers/vendorHome';
import gemCSVUploader from './reducers/gemCSVUploader';
import adminHome from './reducers/adminHome'
import adminLogin from './reducers/adminLogin'
import marketHome from './reducers/marketHome'
import adminGemListingDetails from './reducers/adminGemListingDetails'
import marketJewelryMakerHome from './reducers/marketJewelryMakerHome'
import jewelryMakerHome from './reducers/jewelryMakerHome'
import salesFlow from './reducers/salesFlow'
import marketResetPassword from './reducers/marketResetPassword'
import marketSetNewPassword from './reducers/marketSetNewPassword'
import gemListingsManager from './reducers/vendorHome/gemListingsManager'
import traderGemListingCreator from './reducers/traderGemListingCreator'
import gemListingDetails from './reducers/gemListingDetails'
import gemListingReservation from './reducers/gemListingReservation'
import relativePriceSearchTool from './reducers/relativePriceSearchTool'
import gemRequest from './reducers/gemRequest'
import adminRelativeGemListingSearchJobs from './reducers/adminRelativeGemListingSearchJobs'
import gemRequestsManager from './reducers/vendorHome/gemRequestsManager'
import adminGemRequestsManager from './reducers/adminHome/adminGemRequestsManager'
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  marketSignUp,
  common,
  marketLogin,
  vendorLogin,
  vendorSignUp,
  gemCSVUploader,
  adminHome,
  marketHome,
  adminLogin,
  marketResetPassword,
  jewelryMakerHome,
  marketJewelryMakerHome,
  gemListingsManager,
  salesFlow,
  adminGemListingDetails,
  gemListingReservation,
  marketSetNewPassword,
  traderGemListingCreator,
  gemListingDetails,
  gemRequest,
  gemRequestsManager,
  adminGemRequestsManager,
  adminRelativeGemListingSearchJobs,
  relativePriceSearchTool,
  
  // editor,
  vendorHome,
  // profile,
  // settings,
  router: routerReducer
});