import {
  ASYNC_START,
  MARKET_USER_SIGN_UP_PAGE__UPDATE_FIELD,
  UPDATE_FIELD_SIGN_UP_TYPES,
  VENDOR_SIGNUP_LOADED,
  TRADER_SIGNUP_LOADED,
  USER_INFO_SUBMITTED,
  TRADER_SIGNUP,
  JEWELRY_MAKER_SIGNUP,
  VENDOR_CREATED

} from '../constants/actionTypes';

export default (state = {signUpFlow:[]}, action) => {


  let signUpFlow = state.signUpFlow.slice()

  switch (action.type) {
    case VENDOR_SIGNUP_LOADED:
      signUpFlow.push('finishedSignUp')
      signUpFlow.unshift('userSignUp')
      return {
        ...state,
        signUpFlow: signUpFlow,
        vendorSignUpProcessStarted: true
      };
    case TRADER_SIGNUP_LOADED:
      return {
        ...state
      };
    case VENDOR_CREATED:
      console.log("IF IM HERE")
      return {
        ...state, createdVendor: action.payload.vendor
      };

    case USER_INFO_SUBMITTED:

      if (!action.payload.errors)
      {

        signUpFlow.shift()

        return {
          ...state,
          inProgress: false,
          signUpFlow: signUpFlow,
          errors: action.error ? action.payload.errors : null
        }
      } 
      else {
        return {
          ...state,
          inProgress: false,
          vendorUserAuthErrors: action.error ? action.payload.errors : null
        }
        
      }
    case JEWELRY_MAKER_SIGNUP:
    case TRADER_SIGNUP:

      signUpFlow.shift()

      return {
        ...state,
        inProgress: false,
        signUpFlow: signUpFlow,
        errors: action.error ? action.payload.errors : null
      }

    case UPDATE_FIELD_SIGN_UP_TYPES:
      if (action.value === true) {
        signUpFlow.push(action.key)
      } else {
        signUpFlow = signUpFlow.filter(signUpType => signUpType !== action.key);
      }
      return { ...state, signUpFlow: signUpFlow };
    case ASYNC_START:
      if (action.subtype === USER_INFO_SUBMITTED || action.subtype === TRADER_SIGNUP) {
        return { ...state, inProgress: true };
      }
      break;
    case MARKET_USER_SIGN_UP_PAGE__UPDATE_FIELD:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};