import React from "react";
import { Link as ReachLink } from "@reach/router";
import PropTypes from "prop-types";
import {
    Circle,
    Box,
    Popover,
    PopoverContent,
    Flex,
    PopoverTrigger,
    VStack,
    PopoverArrow,
    PopoverBody,
    Center,
    HStack,
    Heading,
    Button,
    Divider,
    StackDivider,
    Link,
    textDecoration,
    useBreakpointValue,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
import { getSum } from "../../components/UtilityComponents/etc/calculations";
import { SalesOrderItem } from "./SalesOrderItem";
import { useTranslation } from "react-i18next";
import { PopoverOrModal } from ".";
import DesignSystem from "../../DesignSystem";

const AccountActions = ({ onLogout }) => {
    let { t } = useTranslation();
    return (
        <VStack spacing={2}>
            <Link _hover={{ textDecoration: "none" }}>{t("MY_ACCOUNT")}</Link>
            <Link onClick={onLogout} _hover={{ textDecoration: "none" }}>
                {t("SIGN_OUT")}
            </Link>
        </VStack>
    );
};
const CurrentShoppingBagDetailsMolecule = ({ items, onRemove }) => {
    let itemsCount = items.length;
    let { t } = useTranslation();

    return (
        <VStack>
            <Heading size="s"> {t("SHOPPING_BAG")} </Heading>
            <Box>{itemsCount + (itemsCount === 1 ? " Item" : " Items")}</Box>
            <VStack spacing={2}>
                {items.map((item) => {
                    return (
                        <SalesOrderItem
                            item={item}
                            h={16}
                            onRemove={() => onRemove(item.id)}
                            key={item.id}
                        />
                    );
                })}
                <Divider />
            </VStack>
            <Flex
                justifyContent={"space-between"}
                w={"100%"}
                color={"brand.500"}
                fontWeight={"bold"}
                py={2}
            >
                <Box>{t("SUBTOTAL")}</Box>
                <Box>{getSum(items, "price")}</Box>
            </Flex>

            <Link
                as={ReactRouterLink}
                to="/market/cart"
                _hover={{ textDecoration: "none" }}
            >
                <Button w={204} variant={"market.primary"}>
                    {t("CHECKOUT")}
                </Button>
            </Link>
        </VStack>
    );
};

export const CurrentMarketUserNav = ({
    icon,
    itemCountColor,
    currentMarketUser,
    logout,
    color = "white",
    removeItemFunc,
    shoppingCartColor = "white",
    ...props
}) => {
    let currentSalesOrder = currentMarketUser?.currentSalesOrder;
    let items = currentSalesOrder && currentSalesOrder?.items;
    const variant = useBreakpointValue({ base: "modal", lg: "popover" });

    return (
        <PopoverOrModal
            variant={variant}
            trigger="click"
            placement="bottom-end"
            isLazy
            lazyBehavior={"keepMounted"}
            popoverWidth="lg"
            triggerBody={
                <Center>
                    <HStack>
                        <DesignSystem.Molecules.CurrentShoppingBagMenuMolecule
                            items={items}
                            color={shoppingCartColor}
                            itemCountBgColor="gray.300"
                            itemCountTextColor="black"
                            icon={icon}
                        />
                        <Box color={color}>
                            {currentMarketUser
                                ? `${
                                      currentMarketUser.marketUser.person
                                          .givenName
                                  } ${
                                      currentMarketUser.marketUser.person
                                          .familyName || ""
                                  }`
                                : null}
                        </Box>
                    </HStack>
                </Center>
            }
            body={
                <HStack spacing={3} divider={<StackDivider />} color="black">
                    <AccountActions onLogout={logout} />
                    {items && (
                        <CurrentShoppingBagDetailsMolecule
                            items={items}
                            onRemove={removeItemFunc}
                        />
                    )}
                </HStack>
            }
        />
    );
};

CurrentMarketUserNav.propTypes = {
    unreadNotificationsCount: PropTypes.number,
};

CurrentMarketUserNav.defaultProps = {
    unreadNotificationsCount: null,
};
