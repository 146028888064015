import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { DIVIDER } from "../../Icons";

import {
    PriceSlider,
    CaratSlider,
    ClarityRange,
    CutRange,
    ColorRange,
    ShapeSelector,
} from "../../../stories/Primatives/GemSearchFilterComponents";
import MarketUserGemRequestManagement from "./GemRequestManagement";
import { Button, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const CREATE_GEM_REQUEST_GQL_MUTATION = gql`
    mutation CreateGemRequest($input: GemRequestInput!) {
        createGemRequest(input: $input) {
            shapes
        }
    }
`;

const SEARCH_MY_GEM_REQUESTS_IN_STATE = gql`
    query searchMyGemRequestsInState {
        searchMyGemRequestsInState(state: UNFULFILLED) {
            edges {
                node {
                    cutRange
                    priceRange
                    colorRange
                    shapes
                    clarityRange
                }
            }
        }
    }
`;

const GemRequestManualCreation = () => {
    const { t } = useTranslation();
    const [caratRange, setCaratRange] = useState([0.0, 10.0]);
    const [priceRange, setPriceRange] = useState([0, 100000]);
    const [cutRange, setCutRange] = useState(["FAIR", "EXCELLENT"]);
    const [clarityRange, setClarityRange] = useState(["I2", "FL"]);
    const [colorRange, setColorRange] = useState(["K"]);
    const [shapes, setShapes] = useState(["ROUND"]);

    const [createGemRequest, { data, loading, error }] = useMutation(
        CREATE_GEM_REQUEST_GQL_MUTATION
    );

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();

                createGemRequest({
                    variables: {
                        input: {
                            caratRange,
                            priceRange,
                            cutRange,
                            clarityRange,
                            colorRange,
                            shapes,
                        },
                    },
                });
            }}
        >
            <VStack spacing={8}>
                <PriceSlider value={priceRange} onChange={setPriceRange} />
                <CaratSlider value={caratRange} onChange={setCaratRange} />

                <CutRange cutRange={cutRange} onChange={setCutRange} />
                <ClarityRange
                    clarityRange={clarityRange}
                    onChange={setClarityRange}
                />
                <ColorRange colorRange={colorRange} onChange={setColorRange} />
                <Button
                    variant={"market.primary"}
                    type="submit"
                    disabled={loading}
                >
                    {t("SUBMIT")}
                </Button>
            </VStack>
        </form>
    );
};

const renderGemRequestionCreationChoice = (gemRequestInputTypeChoice) => {
    if (gemRequestInputTypeChoice === "url") {
        return <div>UFLLLLFLF</div>;
    } else if (gemRequestInputTypeChoice === "specs") {
        return <GemRequestManualCreation />;
    }
};

const renderGemRequestCreation = (
    setGemRequestInputTypeChoice,
    gemRequestInputTypeChoice
) => {
    console.log("PWOEIURPEOWIURPOEIWURPOWEIRUEWPOI");
    return (
        <div>
            <h1>Request A Gem</h1>
            <button onClick={() => setGemRequestInputTypeChoice("url")}>
                Input Competitor Urlss
            </button>
            <button onClick={() => setGemRequestInputTypeChoice("specs")}>
                Input Gem Spec
            </button>

            <DIVIDER />

            {renderGemRequestionCreationChoice(gemRequestInputTypeChoice)}
        </div>
    );
};

const GemRequest = (props) => {
    let state = "UNFUFILLED";

    const [gemRequestInputTypeChoice, setGemRequestInputTypeChoice] = useState(
        null
    );

    const { loading, error, data } = useQuery(SEARCH_MY_GEM_REQUESTS_IN_STATE, {
        variables: { state },
    });

    let items;

    if (data) {
        items = data.searchMyGemRequestsInState.edges.map((datum) => ({
            data: datum,
        }));

        if (items && items.length > 0) {
            return <MarketUserGemRequestManagement items={items} />;
        } else {
            return renderGemRequestCreation(
                setGemRequestInputTypeChoice,
                gemRequestInputTypeChoice
            );
        }
    } else if (loading) {
        return <div className="loader"></div>;
    }
    // return (<div></div>)
};

export default { GemRequestManualCreation };
export { GemRequestManualCreation };
