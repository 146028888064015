import { Box, Icon } from "../../../Primatives";
import * as React from "react";
import { List } from "./List";
import { ListItem } from "./ListItem";

export const VerticalStepList = ({ steps }) => {
  return (
    <Box maxW="2xl" p={{ base: "4", md: "8" }}>
      <List spacing="12">
        {steps.map((step, index) => (
          <ListItem
            index={index + 1}
            title={step.title}
            subTitle={step.subTitle}
            icon={step.icon}
            body={step.body}
          ></ListItem>
        ))}
      </List>
    </Box>
  );
};

export default VerticalStepList;
