import {
  UPDATE_MARKET_NEW_PASSWORD_FIELD_AUTH,
  MARKET_SET_NEW_PASSWORD,
  MARKET_SET_NEW_PASSWORD_UNLOADED
} from "../constants/actionTypes";

function initialState() {
  return {
    password: null,
    passwordConfirmation: null,
    resp: null
  };
}

export default (state = initialState(), action) => {
  switch (action.type) {
    case UPDATE_MARKET_NEW_PASSWORD_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    case MARKET_SET_NEW_PASSWORD:
      return { ...state, resp: action.payload };
    case MARKET_SET_NEW_PASSWORD_UNLOADED:
      return initialState();
    default:
      return state;
  }
};
