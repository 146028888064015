import * as React from "react";
import {
    VStack,
    Box,
    Alert,
    AlertIcon,
    AlertDescription,
} from "../../Primatives";

export const GraphQLRespWithMessageHandler = ({ result, initialResult }) => {
    console.log("sfas;kldfj;askf", initialResult);
    if ((result === null || result === undefined) && initialResult) {
        result = {};
        result.initialResult = initialResult;
    }
    if (result && result.data) {
        return result && result?.data[Object.keys(result?.data)[0]].message ? (
            <Box>
                <Alert status="success">
                    <AlertIcon />
                    <AlertDescription>
                        {result?.data[Object.keys(result?.data)[0]].message}
                    </AlertDescription>
                </Alert>
            </Box>
        ) : null;
    } else if (result) {
        if (result && result[Object.keys(result)[0]].message) {
            return (
                <Box>
                    <Alert status="success">
                        <AlertIcon />
                        <AlertDescription>
                            {result[Object.keys(result)[0]].message}
                        </AlertDescription>
                    </Alert>
                </Box>
            );
        } else if (result && result[Object.keys(result)[0]].title) {
            return (
                <Box>
                    <Alert status="success">
                        <AlertIcon />
                        <AlertDescription>
                            {result[Object.keys(result)[0]].title}
                        </AlertDescription>
                    </Alert>
                </Box>
            );
        }
    }
    return null;
};
