import React from "react";

import { GalleryItem } from "./GalleryItem";

export default {
    title: "Market/GalleryItem",
    component: GalleryItem,
    argTypes: {},
};

const Template = (args) => <GalleryItem {...args}></GalleryItem>;

export const Item = Template.bind({});
Item.args = {
    src:
        "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Gold-Ring-With-Small-Diamonds.I09.2k2x.png",
    subheading: "Pacheco's Jewelry",
    title: "Gold Diamond Ring",
    stars: 4,
};
