import gql from "graphql-tag";
import React from "react";
import { VictoryChart, VictoryLine, VictoryTheme } from "victory";
import agent from "../../../../../agent";
import {
    ADMIN_GEMS_NEXT_PAGE,
    ADMIN_GEMS_PREVIOUS_PAGE,
    ADMIN_GEM_DELETED,
    ADMIN_GEM_MANAGEMENT_PAGE_LOADED,
    ADMIN_GEM_MANAGEMENT_SUBTOOL_UNLOADED,
    ADMIN_GEM_SEARCH_UPDATE_FIELD,
    ADMIN_MARK_GEM_AS_SOLD,
    ADMIN_SEARCH_GEM_LISTINGS_BY_CATEGORY,
    GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR,
    GEM_LISTING_MANAGER_SEARCH_SUBMIT,
    GEM_VERIFIED,
    GO_TO,
} from "../../../../../constants/actionTypes";
import { GLS_CATEGORY_TO_GLS_ARRAY_MAPPING } from "../../../../../constants/businessConstants";
import GemListingsManager from "../../../../UtilityComponents/GemListingsManager";
import { GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS } from "../../../graph/queries/getGlobalStatsFromSearchOfGemListings";
import { GQL_Q_SEARCH_GEM_LISTINGS_IN_STATE } from "../../../graph/queries";

const mapStateToProps = (state) => ({
    ...state.adminHome,
});

const mapDispatchToProps = (dispatch) => ({
    verifyGemListing: (gemListing) =>
        dispatch({
            type: GEM_VERIFIED,
            payload: agent.Admin.verifyGemListing(gemListing),
        }),
    onLoad: (pager, payload, subtool) => {
        dispatch({
            type: ADMIN_GEM_MANAGEMENT_PAGE_LOADED,
            pager,
            payload,
            subtool,
        });
    },
    onUnLoad: () => {
        dispatch({ type: ADMIN_GEM_MANAGEMENT_SUBTOOL_UNLOADED });
    },
    nextPage: (gemListingsSearchParam) => {
        dispatch({
            type: ADMIN_GEMS_NEXT_PAGE,
            gemListingsSearchParam,
            payload: agent.Admin.searchGems(gemListingsSearchParam),
        });
    },
    deleteGemListing: (gemListing) => {
        dispatch({
            type: ADMIN_GEM_DELETED,
            payload: agent.Admin.deleteGemListing(gemListing),
        });
    },
    previousPage: (gemListingsSearchParam) => {
        dispatch({
            type: ADMIN_GEMS_PREVIOUS_PAGE,
            gemListingsSearchParam,
            payload: agent.Admin.searchGems(gemListingsSearchParam),
        });
    },
    onChangeSearchValue: (value) =>
        dispatch({ type: ADMIN_GEM_SEARCH_UPDATE_FIELD, value }),
    onSearchSubmit: (searchParam) =>
        dispatch({
            type: GEM_LISTING_MANAGER_SEARCH_SUBMIT,
            payload: agent.Admin.searchGems(searchParam),
        }),
    markGemAsSold: (gemListing) =>
        dispatch({
            type: ADMIN_MARK_GEM_AS_SOLD,
            payload: agent.Admin.markGemListingAsSold(gemListing),
        }),
    //  TODO - ITS WEIRD TO CHANGE SEARCH PARAM AFTER THE FACE
    changeGLSSelector: (gls, searchParam) => {
        let newGLSearchParam = { ...searchParam, states: [gls] };
        dispatch({
            type: GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR,
            gls,
            newGLSearchParam: newGLSearchParam,
            payload: agent.Admin.searchGems(newGLSearchParam),
        });
    },
    goToGemListingDetails: (gemListing) => {
        dispatch({
            type: GO_TO,
            route: `/admin/gem-listings/${gemListing._id}`,
        });
    },
    searchGemListingsByCategory: (urlGLSCategory) => {
        dispatch({
            type: ADMIN_SEARCH_GEM_LISTINGS_BY_CATEGORY,
            payload: agent.Admin.searchGemListingsByCategory(urlGLSCategory),
        });
    },
});

class SubTool extends React.Component {
    constructor(props) {
        super(props);

        this.searchValue = "";
        this.searchValueUpdateFunc = (ev) =>
            this.props.onChangeSearchValue(ev.target.value);

        this.singleGemListingToolbar = this.singleGemListingToolbar.bind(this);

        this.submitSearchFunc = (searchParam) => (ev) => {
            ev.preventDefault();
            this.props.onSearchSubmit(searchParam);
        };

        this.goToGemListingDetails = (gemListing) => (ev) => {
            ev.preventDefault();
            this.props.goToGemListingDetails(gemListing);
        };

        this.nextPageFunc = () => (ev) => {
            ev.preventDefault();
            let newSearchParam = this.props.gemListingsSearchParam;
            newSearchParam.offset += newSearchParam.limit;
            this.props.nextPage(newSearchParam);
        };
        this.previousPageFunc = () => (ev) => {
            ev.preventDefault();
            let newSearchParam = this.props.gemListingsSearchParam;
            newSearchParam.offset -= newSearchParam.limit;
            if (newSearchParam.offset < 0) {
                newSearchParam.offset = 0;
            }
            this.props.previousPage(newSearchParam);
        };
        this.toggleVerification = (gemListing) => (ev) => {
            ev.preventDefault();
            if (gemListing.verified) {
                this.props.unverifyGemListing(gemListing);
            } else {
                this.props.verifyGemListing(gemListing);
            }
        };

        this.deleteGemListing = (gemListing) => (ev) => {
            ev.preventDefault();
            this.props.deleteGemListing(gemListing);
        };

        this.searchGemListingsByCategory = (urlCategory) => (ev) => {
            ev.preventDefault();
            this.props.searchGemListingsByCategory(urlCategory);
        };

        this.markGemAsSold = (gemListing) => (ev) => {
            ev.preventDefault();
            this.props.markGemAsSold(gemListing);
        };

        this.changeGLSSelector = (gls, searchParam) => (ev) => {
            ev.preventDefault();
            this.props.changeGLSSelector(gls, searchParam);
        };

        this.showPastSalesChart = this.showPastSalesChart.bind(this);
    }

    showPastSalesChart() {
        return (
            <div className="past-sales-chart">
                <VictoryChart theme={VictoryTheme.material}>
                    <VictoryLine
                        style={{
                            data: { stroke: "#c43a31" },
                            parent: { border: "1px solid #ccc" },
                        }}
                        data={this.props.gemListingSearchResultsByCategory.stats.totals.totalPricePerMonthAndYear.map(
                            (totalPriceForMonthAndYear) => ({
                                x: new Date(
                                    totalPriceForMonthAndYear[0].year,
                                    totalPriceForMonthAndYear[0].month - 1,
                                    1
                                ),
                                y: totalPriceForMonthAndYear[1],
                            })
                        )}
                    />
                </VictoryChart>
            </div>
        );
    }

    singleGemListingToolbar(gemListingRow) {
        switch (gemListingRow.gemListing.state) {
            // MARKET STATES
            case "ON_MARKET":
                // code block
                return (
                    <div>
                        <button> TAKE OFF MARKET * </button>
                        <button> HELP UPDATE PRICE * </button>
                        <button
                            onClick={this.markGemAsSold(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            SET AS SOLD{" "}
                        </button>
                        <button
                            onClick={this.deleteGemListing(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            PERMANENT DELETE{" "}
                        </button>
                        <button
                            onClick={this.goToGemListingDetails(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            DETAILS{" "}
                        </button>
                    </div>
                );
            case "OFF_MARKET":
                // code block
                return (
                    <div>
                        <button> TAKE OFF MARKET* </button>
                        <button> HELP UPDATE PRICE* </button>
                        <button
                            onClick={this.deleteGemListing(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            PERMANENT DELETE{" "}
                        </button>
                        <button
                            onClick={this.goToGemListingDetails(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            DETAILS{" "}
                        </button>
                    </div>
                );

            // PENDING STATES
            case "NEW":
                return (
                    <div>
                        <button> ARCHIVE </button>
                        <button
                            onClick={this.deleteGemListing(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            PERMANENT DELETE{" "}
                        </button>
                        <button
                            onClick={this.goToGemListingDetails(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            DETAILS{" "}
                        </button>
                    </div>
                );
            case "VERIFICATION_REQUESTED":
                return (
                    <div>
                        <button
                            onClick={this.toggleVerification(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            VERIFY{" "}
                        </button>
                        <button> ASK FOR UPDATE* </button>
                        <button
                            onClick={this.deleteGemListing(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            PERMANENT DELETE{" "}
                        </button>
                        <button
                            onClick={this.goToGemListingDetails(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            DETAILS{" "}
                        </button>
                    </div>
                );
            case "VERIFIED":
                return (
                    <div>
                        <button
                            onClick={this.deleteGemListing(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            PERMANENT DELETE{" "}
                        </button>
                        <button
                            onClick={this.goToGemListingDetails(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            DETAILS{" "}
                        </button>
                    </div>
                );
            case "REJECTED":
                return (
                    <div>
                        <button
                            onClick={this.deleteGemListing(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            PERMANENT DELETE{" "}
                        </button>
                        <button
                            onClick={this.goToGemListingDetails(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            DETAILS{" "}
                        </button>
                    </div>
                );
            case "UNVERIFIED":
                return (
                    <div>
                        <button
                            onClick={this.deleteGemListing(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            PERMANENT DELETE{" "}
                        </button>
                        <button
                            onClick={this.goToGemListingDetails(
                                gemListingRow.gemListing
                            )}
                        >
                            {" "}
                            DETAILS{" "}
                        </button>
                    </div>
                );

            default:
            // code block
        }
    }

    filterSearchParamForSubTool(subtool, searchParam) {
        if (subtool === "market") {
            searchParam.states = searchParam.states.filter((state) =>
                ["ON_MARKET", "OFF_MARKET"].includes(state)
            );
        } else if (subtool === "pending") {
            searchParam.states = searchParam.states.filter((state) =>
                [
                    "NEW",
                    "VERIFICATION_REQUESTED",
                    "UNVERIFIED",
                    "VERIFIED",
                    "REJECTED",
                ].includes(state)
            );
        }
        return searchParam;
    }

    render() {
        return (
            <GemListingsManager
                gqlQuery={GQL_Q_SEARCH_GEM_LISTINGS_IN_STATE}
                userType="admin"
                stateSelectorMenu={
                    GLS_CATEGORY_TO_GLS_ARRAY_MAPPING[this.props.subtool]
                }
                gqlQueries={{
                    globalStatForSearchQuery: GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS,
                }}
                submitSearchFunc={this.submitSearchFunc}
                searchValueUpdateFunc={this.searchValueUpdateFunc}
                nextPageFunc={this.nextPageFunc}
                previousPageFunc={this.previousPageFunc}
                gemListingsCount={this.props.gemListingsCount}
                gemListingRows={this.props.gemListingRows}
                searchParam={this.props.searchParam}
                searchFlags={this.props.gemListingsSearchFlags}
                subtool={this.props.subtool}
                param1={this.props.param1}
                param2={this.props.param2}
                singleGemListingToolbar={this.singleGemListingToolbar}
                currentSelectedGLS={this.props.currentlySelectedGLS}
                options={{ asAdmin: true }}
                changeGLSSelector={this.changeGLSSelector}
                globalGLSStats={this.props.globalGLSStats}
                searchStats={this.props.searchStats}
                reservedGemListings={this.props.reservedGemListings}
                isDataLoading={this.props.isDataLoading}
                match={this.props.match}
                bodyOnly={this.props.bodyOnly}
                scope={this.props.scope}
            />
            // </div>
        );
    }
}

export default SubTool;
