import {
    Box,
    Button,
    Container,
    Divider,
    Heading,
    Input,
    Stack,
    Text,
    Flex,
    useBreakpointValue,
    HStack,
    VStack,
    Spinner,
    Link,
    FormControl,
    AlertDescription,
    FormErrorMessage,
    Image,
    Center,
} from "@chakra-ui/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { GoogleIcon } from "./ProviderIcons";
import { LogoIconOnly } from "../../IconsV2";
import { useForm } from "react-hook-form";
import useSubmitMutation from "../../../stories/CloudManagementOS/hooks/useSubmitMutation";
import { yupResolver } from "@hookform/resolvers/yup";

import { GraphQLRespErrorHandler } from "../../../stories/CloudManagementOS/Apollo/GraphQLRespErrorHandler";
import { GraphQLRespWithMessageHandler } from "../../../stories/CloudManagementOS/Apollo/GraphQLRespWithMessageHandler";
import TwoPanelShell from "../../../stories/CloudManagementOS/shells/TwoPanelShell";
import * as Yup from "yup";
import UserSignUpForm from "./UserSignUpForm";

const LoginSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string().required(),
});

const UpdatePasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is a required field.")
        .min(8, "Password must be at least 8 characters.")
        .matches(/[A-Z]/, "Must have a capital letter.")
        .matches(
            /[!+=@#$%^&*,.;]/,
            "Must have a special charater [!+=@#$%^&*,.;]"
        )
        .matches(/[0-9]/, "Must have a number"),
    passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
});

const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().required().email(),
});

const ResetPasswordForm = ({
    resetPasswordFunc,
    setResultError,
    setResult,
}) => {
    let {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: yupResolver(ResetPasswordSchema) });

    let { onSubmit, loading, error, data } = useSubmitMutation(
        resetPasswordFunc
    );

    React.useEffect(() => {
        setResultError(error);
    }, [error]);

    React.useEffect(() => {
        setResult(data);

        console.log("SDLKFJ:SDLKJF:DS", data);
    }, [data]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors && Object.keys(errors).length > 0}>
                <VStack spacing="4">
                    <Input
                        id="reset-password-email-input"
                        {...register("email")}
                        placeholder="Enter your email of your account"
                    />
                    <FormErrorMessage>
                        {errors.email && errors.email.message}
                    </FormErrorMessage>
                    <Divider />
                    <Button
                        id="reset-password-submit-button"
                        type="submit"
                        w="100%"
                        variant="cloud-management-os.primary"
                    >
                        {loading ? <Spinner /> : "Reset Password"}
                    </Button>
                </VStack>
            </FormControl>
        </form>
    );
};

const UpdatePasswordForm = ({
    updatePasswordFunc,
    setResultError,
    setResult,
    postAuthenticatedCallBack,
}) => {
    let {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: yupResolver(UpdatePasswordSchema) });

    let { onSubmit, loading, error, data } = useSubmitMutation(
        updatePasswordFunc
    );

    React.useEffect(() => {
        setResultError(error);
    }, [error]);

    React.useEffect(() => {
        setResult(data);
        if (data) {
            postAuthenticatedCallBack(data);
        }
    }, [data]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="4">
                <FormControl isInvalid={errors?.password}>
                    <Input
                        {...register("password")}
                        placeholder="Password"
                        type={"password"}
                    />
                    <FormErrorMessage>
                        {errors.password && errors.password.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors?.passwordConfirmation}>
                    <Input
                        {...register("passwordConfirmation")}
                        placeholder="Password Confirmation"
                        type={"password"}
                    />
                    <FormErrorMessage>
                        {errors.passwordConfirmation &&
                            errors.passwordConfirmation.message}
                    </FormErrorMessage>
                </FormControl>

                <Divider />

                <Button
                    type="submit"
                    // onClick={loginFunc}
                    w="100%"
                    variant="cloud-management-os.primary"
                >
                    {loading ? <Spinner /> : "Login"}
                </Button>
            </Stack>
        </form>
    );
};

const LoginForm = ({
    loginFunc,
    postAuthenticatedCallBack,
    setResultError,
    forgotPasswordLink,
}) => {
    let {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: yupResolver(LoginSchema) });

    let { onSubmit, loading, error } = useSubmitMutation(
        loginFunc,
        postAuthenticatedCallBack
    );

    console.log("LSDJFL:JSD:LFKJSD:LKFJ:SDLKJFSD", forgotPasswordLink);

    React.useEffect(() => {
        setResultError(error);
    }, [error]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={6}>
                <VStack spacing={4}>
                    <FormControl isInvalid={errors.email}>
                        <Input
                            id="login-email-input"
                            {...register("email")}
                            placeholder="Enter your email"
                        />
                        <FormErrorMessage>
                            {errors.email && errors.email.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.password}>
                        <Input
                            id="login-password-input"
                            {...register("password")}
                            placeholder="Password"
                            type={"password"}
                        />
                        <FormErrorMessage>
                            {errors.password && errors.password.message}
                        </FormErrorMessage>
                    </FormControl>
                </VStack>
                {forgotPasswordLink && (
                    <Box w="100%" textAlign={"right"}>
                        <Link href={forgotPasswordLink} color={"brand.500"}>
                            <Box>Forgot Password?</Box>
                        </Link>
                    </Box>
                )}

                <Divider />
                <Button
                    id="login-submit-button"
                    type="submit"
                    // onClick={loginFunc}
                    w="100%"
                    variant="cloud-management-os.primary"
                >
                    {loading ? <Spinner /> : "Login"}
                </Button>
            </Stack>
        </form>
    );
};

const Choice = ({ vendorOrganizationUserAuth, postAuthenticatedCallBack }) => {
    const history = useHistory();

    let selectChoice = async (vendorOrganizationUserAuth) => {
        await localStorage.setItem(
            "vendorToken",
            vendorOrganizationUserAuth.userAuth.token
        );

        history.push("/vendor/home");
    };
    return (
        <Button
            variant="cloud-management-os.primary"
            w="100%"
            // key={uuidv4()}
            onClick={() => selectChoice(vendorOrganizationUserAuth)}
        >
            {vendorOrganizationUserAuth.vendorOrganizationLegalName}
        </Button>
    );
};

const Login = ({
    loginFunc,
    postAuthenticatedCallBack,
    setResultError,
    logo,
    links,
    forgotPasswordLink,
}) => {
    return (
        <Box>
            <LoginForm
                forgotPasswordLink={forgotPasswordLink}
                setResultError={setResultError}
                loginFunc={loginFunc}
                postAuthenticatedCallBack={postAuthenticatedCallBack}
            />
        </Box>
    );
};

export const AuthLoginBody = ({
    heading,
    initialResult,
    loginFunc,
    logo,
    links,
    mode = "login",
    postAuthenticatedCallBack,
    resetPasswordFunc,
    showHeader = true,
    updatePasswordFunc,
    variant,
    createAccountLink,
    headingSubtext,
    headingProps,
    footer,
    forgotPasswordLink,
}) => {
    let title;
    let authBody;

    let [resultError, setResultError] = React.useState();
    let [result, setResult] = React.useState();
    let [currentView, setCurrentView] = React.useState();
    let [accounts, setAccounts] = React.useState();

    React.useEffect(() => {
        setCurrentView(mode);
    }, []);

    let post = (data) => {
        if (Array.isArray(data)) {
            if (data.length > 1) {
                setCurrentView("account-choice");
                setAccounts(data);
                localStorage.setItem(
                    "vendorTokensForOtherVendorOrganizations",
                    JSON.stringify(data)
                );
            } else {
                postAuthenticatedCallBack(data[0]);
            }
        } else {
            postAuthenticatedCallBack(data.token);
        }
    };

    let pre = async (data) => {
        await setResultError();
        return await loginFunc(data);
    };

    if (currentView == "reset-password") {
        title = heading || "Reset password";
        authBody = (
            <ResetPasswordForm
                setResultError={setResultError}
                setResult={setResult}
                resetPasswordFunc={resetPasswordFunc}
            />
        );
    } else if (currentView == "update-password") {
        title = "Update Password";
        authBody = (
            <UpdatePasswordForm
                updatePasswordFunc={updatePasswordFunc}
                setResultError={setResultError}
                setResult={setResult}
                postAuthenticatedCallBack={postAuthenticatedCallBack}
            />
        );
    } else if (currentView == "account-choice" && accounts) {
        title = "Account Selection";
        authBody = (
            <VStack spacing={4} w="100%">
                <Box>
                    Your user is associated with {accounts.length} accounts.
                    Please select which organization to log in to.
                </Box>

                <VStack spacing={2} w="100%">
                    {accounts.map((vendorOrganizationUserAuth) => (
                        <Choice
                            vendorOrganizationUserAuth={
                                vendorOrganizationUserAuth
                            }
                        />
                    ))}
                </VStack>
            </VStack>
        );
    } else if (currentView == "sign-up") {
        title = heading || "Sign Up";
        authBody = <UserSignUpForm />;
        footer = footer || (
            <HStack justifyContent="center" w="100%">
                <Box>Already have an account?</Box>
                <Link color="brand.500" href={"/market/login"}>
                    Login Here
                </Link>
            </HStack>
        );
    } else {
        title = heading;
        authBody = (
            <Login
                forgotPasswordLink={forgotPasswordLink}
                links={links}
                setResultError={setResultError}
                loginFunc={pre}
                postAuthenticatedCallBack={post}
            />
        );
        footer = footer || (
            <HStack justifyContent="center" w="100%">
                <Box>Need to create an account? </Box>

                <Link color="brand.500" href={createAccountLink}>
                    Sign Up
                </Link>
            </HStack>
        );
    }

    return (
        <Stack spacing="4" textColor={"black"}>
            <Stack spacing="6" align="center">
                {showHeader && (
                    <VStack
                        w="100%"
                        alignItems={variant === "generic" ? "center" : "left"}
                        spacing={2}
                    >
                        <HStack>
                            {logo || variant === "generic" ? (
                                <LogoIconOnly w="50px" />
                            ) : null}

                            <Heading size="xl" {...headingProps}>
                                {title}
                            </Heading>
                        </HStack>
                        <Heading as="h4" size="xxs">
                            {headingSubtext}
                        </Heading>
                    </VStack>
                )}
                {links && currentView != "account-choice" && (
                    <Stack spacing="3" textAlign="center">
                        {links}
                    </Stack>
                )}
            </Stack>
            <Stack spacing="3">
                <GraphQLRespWithMessageHandler
                    result={result}
                    initialResult={initialResult}
                />
                <GraphQLRespErrorHandler error={resultError} />
                {authBody}
                {footer && <Divider />}
                {footer && <Box alignItems={"center"}>{footer}</Box>}
            </Stack>

            {/* <Stack spacing="0.5" align="center">
        <Text fontSize="sm" color="muted">
            Having trouble logging in?
        </Text>
        <Button variant="link" size="sm">
            Contact us
        </Button>
    </Stack> */}
            {/* <Text
                fontSize="xs"
                color="subtle"
                textAlign="center"
                textColor={"black"}
            >
                By continuing, you acknowledge that you have read, understood,
                and agree to our terms and condition
            </Text> */}
        </Stack>
    );
};

export const AuthLogin = ({
    footer,
    h = "100vh",
    heading,
    headingProps,
    headingSubtext,
    logo,
    loginFunc,
    forgotPasswordLink,
    links,
    createAccountLink,
    mode = "login",
    postAuthenticatedCallBack,
    py = { base: "12", md: "24" },
    resetPasswordFunc,
    updatePasswordFunc,
    variant = "generic",
    initialResult,
}) => {
    let bg = useBreakpointValue({
        base: "transparent",
        sm: "white",
    });
    if (variant === "generic") {
        return (
            <Box
                bgGradient={{ sm: "linear(to-r, brand.500, white)" }}
                py={py}
                h={h}
            >
                <Container
                    maxW="md"
                    py={{ base: "0", sm: "8" }}
                    px={{ base: "4", sm: "10" }}
                    bg={bg}
                    boxShadow={{ base: "none", sm: "xl" }}
                    borderRadius={{ base: "none", sm: "xl" }}
                >
                    <AuthLoginBody
                        variant={variant}
                        logo={logo}
                        heading={heading}
                        loginFunc={loginFunc}
                        links={links}
                        createAccountLink={createAccountLink}
                        mode={mode}
                        forgotPasswordLink={forgotPasswordLink}
                        footer={footer}
                        initialResult={initialResult}
                        headingProps={headingProps}
                        headingSubtext={headingSubtext}
                        updatePasswordFunc={updatePasswordFunc}
                        postAuthenticatedCallBack={postAuthenticatedCallBack}
                        resetPasswordFunc={resetPasswordFunc}
                    />
                </Container>
            </Box>
        );
    } else {
        return (
            <TwoPanelShell
                variant="left-main"
                h={h}
                w="100vw"
                overflow="hidden"
                subViewWidth="50%"
                subPanelHiddenOnSmallScreens={true}
                breakpointValue="lg"
                panel1={
                    <Box w="100%" padding={16}>
                        <AuthLoginBody
                            variant={variant}
                            logo={logo}
                            forgotPasswordLink={forgotPasswordLink}
                            heading={heading}
                            loginFunc={loginFunc}
                            links={links}
                            createAccountLink={createAccountLink}
                            mode={mode}
                            footer={footer}
                            initialResult={initialResult}
                            headingSubtext={headingSubtext}
                            headingProps={
                                headingProps || {
                                    fontFamily: "Montserrat, sans-serif",
                                    as: "h3",
                                }
                            }
                            updatePasswordFunc={updatePasswordFunc}
                            postAuthenticatedCallBack={
                                postAuthenticatedCallBack
                            }
                            resetPasswordFunc={resetPasswordFunc}
                        />
                    </Box>
                }
                panel2={
                    <Box overflow={"hidden"} h="100%">
                        <Image
                            objectFit={"cover"}
                            w="100%"
                            h="100%"
                            src="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/diamondphoto.png"
                        ></Image>
                    </Box>
                }
            />
        );
    }
};
