import React from "react";
import { Link as ReactLink, Route, useParams } from "react-router-dom";
import {
    Box,
    ConditionalLink,
    Grid,
    Heading,
    useBreakpointValue,
    VStack,
} from "../../Primatives";
import { getConstToLanguage } from "../ConstToLanguageMapping";
import {
    SubNavAndBodyWithModes,
    SubMenuSideNav,
    SubNavAndBodyWithModesBody,
} from "../shells/SubNavAndBodyWithModes";

const Settings = ({ navConfig, routePrefix }) => {
    const btnRef = React.useRef();
    const subNavAndBodyWithMode = useBreakpointValue({
        base: "drawer",
        sm: "full",
    });

    return (
        <SubNavAndBodyWithModes
            mode={subNavAndBodyWithMode}
            nav={
                <SubMenuSideNav
                    title={getConstToLanguage("SETTINGS")}
                    ref={btnRef}
                    navConfig={navConfig}
                    boxShadow="md"
                    routePrefix={routePrefix}
                />
            }
            body={
                <SubNavAndBodyWithModesBody
                    navConfig={navConfig}
                    routePrefix={routePrefix}
                />
            }
        ></SubNavAndBodyWithModes>
    );
};

export default Settings;
