import React, { forwardRef } from "react";

import {
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "../../stories/Primatives";

export const InputWithFloatingLabel = forwardRef(
    ({ label, placeholder, onBlur, onChange, error, name }, ref) => {
        return (
            <FormControl variant="floating" isInvalid={error}>
                <Input
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    ref={ref}
                />
                <FormLabel>{label}</FormLabel>
                <FormErrorMessage>{error?.message}</FormErrorMessage>
            </FormControl>
        );
    }
);
