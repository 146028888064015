import gql from "graphql-tag";

const GQL_M_COMPLETE_CURRENT_SALES_ORDER = gql`
  mutation CompleteCurrentSalesOrder($id: ID!) {
    completeCurrentSalesOrder(id: $id) {
      state
    }
  }
`;

export { GQL_M_COMPLETE_CURRENT_SALES_ORDER };
