function updateGLS(state, action) {
  let updatedStats = { ...state.globalGLSStats };

  let origGemListingState = state.gemListingRows.filter(
    gemListingRow => gemListingRow.gemListing._id === action.payload.gemListing._id
  )[0].gemListing.state;

  updatedStats.stats.counts.byGLS[action.payload.gemListing.state] = updatedStats.stats
    .counts.byGLS[action.payload.gemListing.state]
    ? (updatedStats.stats.counts.byGLS[action.payload.gemListing.state] += 1)
    : 1;

  updatedStats.stats.counts.byGLS[origGemListingState] =
    updatedStats.stats.counts.byGLS[origGemListingState] - 1;

  return {
    ...state,
    gemListingRows: state.gemListingRows.filter(
      gemListingRow => gemListingRow.gemListing._id !== action.payload.gemListing._id
    ),
    globalGLSStats: updatedStats,
    gemListingsCount: state.gemListingsCount - 1
  };
}

export { updateGLS };
