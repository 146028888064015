import {
  MARKET_JEWELRY_MAKER_PAGE_LOADED,
  MARKET_JEWELRY_MAKER_ABOUT_CHANGE_SECTION,
  MARKET_JEWELRY_MAKER_GEMS_CHANGE_SECTION
} from '../constants/actionTypes';

export default (state = {currentHistoryView: 'history', currentGemsView: 'market'}, action) => {
  switch (action.type) {
    case MARKET_JEWELRY_MAKER_PAGE_LOADED:

      return {
        ...state,
        jewelryMakerWebsite: action.payload[0].jewelryMakerWebsite
      };

    case MARKET_JEWELRY_MAKER_ABOUT_CHANGE_SECTION:

    return {
        ...state,
        currentHistoryView: action.section
      };

    case MARKET_JEWELRY_MAKER_GEMS_CHANGE_SECTION:

    return {
        ...state,
        currentGemsView: action.section
      };

    default:
      return state;
  }
};
