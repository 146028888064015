import React from "react";
import { Icon } from "@chakra-ui/react"


export const SquareEmeraldShapeIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 41.3 41.2" height={'auto'} width={'100%'} fill={color}>
<g id="Diamonds" transform="translate(0 0)">
	<g id="Grupo_40">
		<g id="Grupo_272">
			<g id="Grupo_247" transform="translate(31.35)">
				<path id="Trazado_1850" d="M5.8,9.7c-0.1,0-0.2-0.1-0.2-0.2L0.3,4.3H0.2C0.1,4.3,0,4.2,0,4.1C0,4.1,0,4,0,4l2.8-3.9
					C2.9,0,2.9,0,3,0h0.1c0.1,0,0.1,0,0.1,0.1l6.6,6.6c0.1,0.1,0.1,0.2,0,0.3c0,0,0,0,0,0L5.9,9.7C5.9,9.7,5.8,9.7,5.8,9.7z M0.6,4
					L0.6,4l5.3,5.3l3.5-2.5L3.1,0.4L0.6,4z"/>
			</g>
			<g id="Grupo_248" transform="translate(6.69 0)">
				<path id="Trazado_1851" d="M24.9,4.3H3c-0.1,0-0.1,0-0.2-0.1L0,0.3C0,0.2,0,0.1,0.1,0c0,0,0.1,0,0.1,0h27.4
					c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.1L25,4.2C25,4.3,24.9,4.3,24.9,4.3z M3.1,3.9h21.7l2.5-3.5H0.6L3.1,3.9z"/>
			</g>
			<g id="Grupo_249" transform="translate(36.909 6.569)">
				<path id="Trazado_1852" d="M4.1,28.1c0,0-0.1,0-0.1,0l-3.9-2.8C0,25.2,0,25.1,0,25V3c0-0.1,0-0.1,0.1-0.2L4,0
					c0.1-0.1,0.2,0,0.3,0c0,0,0.1,0.1,0.1,0.1v27.7c0,0.1,0,0.1-0.1,0.1l0,0C4.2,28.1,4.2,28.1,4.1,28.1z M0.4,25l3.5,2.5V0.6
					L0.4,3.1V25z"/>
			</g>
			<g id="Grupo_250" transform="translate(31.283 64.599)">
				<path id="Trazado_1853" d="M3.2-23.4H3c-0.1,0-0.1,0-0.2-0.1l-2.8-4c-0.1-0.1-0.1-0.2,0-0.3c0,0,0.1,0,0.1,0h0.2L5.7-33
					c0.1-0.1,0.2-0.1,0.3,0l3.9,2.8C10-30.3,10-30.1,9.9-30c0,0,0,0,0,0l-6.6,6.6C3.3-23.4,3.3-23.4,3.2-23.4z M0.6-27.4l2.5,3.6
					l6.3-6.3l-3.6-2.5L0.6-27.4C0.6-27.4,0.6-27.4,0.6-27.4L0.6-27.4z"/>
			</g>
			<g id="Grupo_251" transform="translate(0 0)">
				<path id="Trazado_1854" d="M4.1,9.8c0,0-0.1,0-0.1,0L0.1,7C0,6.9,0,6.9,0,6.8v0c0-0.1,0-0.1,0.1-0.1l6.6-6.6C6.7,0,6.7,0,6.8,0
					h0.1C7,0,7,0,7.1,0.1L9.8,4c0.1,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0H9.6L4.3,9.6C4.3,9.7,4.2,9.8,4.1,9.8L4.1,9.8L4.1,9.8z
					 M0.5,6.8L4,9.3L9.3,4L6.8,0.5L0.5,6.8z"/>
			</g>
			<g id="Grupo_252" transform="translate(6.728 69.933)">
				<path id="Trazado_1855" d="M27.6-28.7H0.2c-0.1,0-0.2-0.1-0.2-0.2C0-29,0-29,0-29l2.8-4c0-0.1,0.1-0.1,0.2-0.1h21.7
					c0.1,0,0.1,0,0.2,0.1l2.8,4c0.1,0.1,0.1,0.2,0,0.3C27.7-28.7,27.6-28.7,27.6-28.7L27.6-28.7z M0.6-29.1h26.6l-2.5-3.6H3.1
					L0.6-29.1z"/>
			</g>
			<g id="Grupo_253" transform="translate(0.019 64.59)">
				<path id="Trazado_1856" d="M6.9-23.4H6.8c-0.1,0-0.1,0-0.1-0.1l-2.2-2.2L0.1-30c-0.1-0.1-0.1-0.2,0-0.3c0,0,0,0,0,0L4-33.1
					c0.1-0.1,0.2,0,0.3,0l5.3,5.3h0.2c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.1l-2.8,4C7-23.4,7-23.4,6.9-23.4z M0.5-30.1L4.7-26
					l2.2,2.2l2.5-3.6c0,0,0,0-0.1,0l-5.2-5.2L0.5-30.1z"/>
			</g>
			<g id="Grupo_254" transform="translate(0 6.585)">
				<path id="Trazado_1857" d="M0.2,28.1c-0.1,0-0.1,0-0.1-0.1l0,0C0,28,0,27.9,0,27.8V0.2C0,0.1,0.1,0,0.2,0c0,0,0.1,0,0.1,0
					l3.9,2.8c0.1,0,0.1,0.1,0.1,0.2V25c0,0.1,0,0.2-0.1,0.2L0.3,28C0.3,28.1,0.3,28.1,0.2,28.1z M0.4,0.6v26.9L3.9,25V3.1L0.4,0.6z"
					/>
			</g>
			<g id="Grupo_255" transform="translate(9.475 3.906)">
				<path id="Trazado_1858" d="M19.4,4.2H2.9c-0.1,0-0.1,0-0.2-0.1L0,0.3C0,0.2,0,0.1,0.1,0c0,0,0.1,0,0.1,0h21.9
					c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.1l-2.7,3.8C19.5,4.2,19.4,4.2,19.4,4.2z M3,3.8h16.2l2.4-3.4H0.6L3,3.8z"/>
			</g>
			<g id="Grupo_256" transform="translate(33.001 9.331)">
				<path id="Trazado_1859" d="M4.1,22.6c0,0-0.1,0-0.1,0l-3.9-2.7C0,19.7,0,19.7,0,19.6V3c0-0.1,0-0.1,0.1-0.2L4,0
					c0.1-0.1,0.2,0,0.3,0c0,0,0,0.1,0,0.1v22.1c0,0.1,0,0.1-0.1,0.1l0,0C4.2,22.5,4.2,22.6,4.1,22.6z M0.4,19.5L3.9,22V0.6L0.4,3.1
					L0.4,19.5z"/>
			</g>
			<g id="Grupo_257" transform="translate(28.643 3.906)">
				<path id="Trazado_1860" d="M4.6,8.6c-0.1,0-0.2-0.1-0.2-0.2v0v0L0.2,4.2C0.1,4.2,0,4.1,0,4c0,0,0-0.1,0-0.1l2.7-3.8
					C2.8,0,2.8,0,2.9,0h0.2c0.1,0,0.1,0,0.1,0.1l5.4,5.4c0,0,0.1,0.1,0.1,0.1v0.1c0,0.1,0,0.1-0.1,0.2L4.7,8.6
					C4.6,8.6,4.6,8.6,4.6,8.6z M0.5,3.9l4.2,4.2l3.6-2.5L3,0.4h0L0.5,3.9z"/>
			</g>
			<g id="Grupo_258" transform="translate(3.915 3.906)">
				<path id="Trazado_1861" d="M4.1,8.6c0,0-0.1,0-0.1,0L0.1,5.8C0,5.8,0,5.7,0,5.6V5.6c0-0.1,0-0.1,0.1-0.1l5.4-5.4
					C5.5,0,5.5,0,5.6,0h0.2c0.1,0,0.1,0,0.2,0.1l2.7,3.8c0.1,0.1,0,0.2,0,0.3c0,0-0.1,0-0.1,0L4.3,8.4v0.1C4.3,8.5,4.3,8.6,4.1,8.6
					L4.1,8.6L4.1,8.6z M0.5,5.6L4,8.1L8.2,4L5.7,0.4h0L0.5,5.6z"/>
			</g>
			<g id="Grupo_259" transform="translate(3.933 61.826)">
				<path id="Trazado_1862" d="M5.8-24.6H5.5c-0.1,0-0.1,0-0.1-0.1L0.1-30c-0.1-0.1-0.1-0.2,0-0.3c0,0,0,0,0,0L4-33.1
					c0.1-0.1,0.2,0,0.3,0c0,0,0,0.1,0,0.1v0l4.2,4.2l0,0c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.1L6-24.7C6-24.6,5.9-24.6,5.8-24.6z
					 M5.6-25L5.6-25l2.6-3.5L4-32.6l-3.5,2.5L5.6-25z"/>
			</g>
			<g id="Grupo_260" transform="translate(28.62 61.851)">
				<path id="Trazado_1863" d="M3.2-24.6H2.9c-0.1,0-0.1,0-0.2-0.1L0-28.4c-0.1-0.1-0.1-0.2,0-0.3c0,0,0.1,0,0.1,0h0l4.2-4.2
					c0-0.1,0.1-0.2,0.2-0.2c0,0,0.1,0,0.1,0l3.9,2.8c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0l-5.3,5.3C3.3-24.6,3.2-24.6,3.2-24.6z
					 M3-25L3-25l5.2-5.1l-3.5-2.5l-4.1,4.1L3-25z"/>
			</g>
			<g id="Grupo_261" transform="translate(9.566 66.204)">
				<path id="Trazado_1864" d="M21.9-29H0.2C0.1-29,0-29.1,0-29.2c0,0,0-0.1,0-0.1L2.7-33c0-0.1,0.1-0.1,0.2-0.1h16.4
					c0.1,0,0.1,0,0.2,0.1l2.7,3.7c0.1,0.1,0.1,0.2,0,0.3C22-29,22-29,21.9-29z M0.6-29.4h20.9l-2.4-3.3H3L0.6-29.4z"/>
			</g>
			<g id="Grupo_262" transform="translate(3.915 9.349)">
				<path id="Trazado_1865" d="M0.2,22.5c-0.1,0-0.1,0-0.1-0.1l0,0c0,0-0.1-0.1-0.1-0.1V0.2C0,0.1,0.1,0,0.2,0c0,0,0.1,0,0.1,0
					l3.9,2.8c0.1,0,0.1,0.1,0.1,0.2v16.6c0,0.1,0,0.1-0.1,0.2l-3.9,2.8C0.3,22.5,0.3,22.5,0.2,22.5z M0.4,0.6V22l3.5-2.5V3.1
					L0.4,0.6z"/>
			</g>
			<g id="Grupo_263" transform="translate(7.849 12.127)">
				<path id="Trazado_1866" d="M0.2,17C0.1,17,0,16.9,0,16.8c0,0,0,0,0,0V0.2C0,0.1,0.1,0,0.2,0c0,0,0.1,0,0.1,0l4,2.8
					C4.4,2.9,4.4,3,4.4,3v10.8c0,0.1,0,0.2-0.1,0.3l-4,2.8C0.3,17,0.2,17,0.2,17z M0.4,0.6v15.8L4,13.9V3.1L0.4,0.6z"/>
			</g>
			<g id="Grupo_264" transform="translate(7.849 7.704)">
				<path id="Trazado_1867" d="M4.2,7.7c0,0-0.1,0-0.1,0l-4-2.8C0,4.8,0,4.7,0,4.6V4.5c0-0.1,0-0.1,0.1-0.1l4.3-4.3
					C4.4,0,4.4,0,4.5,0h0c0.1,0,0.1,0,0.2,0.1l2.9,4c0.1,0.1,0,0.2,0,0.3L4.4,7.5C4.4,7.6,4.3,7.7,4.2,7.7L4.2,7.7z M0.4,4.5
					l3.7,2.6l3-3L4.5,0.5L0.4,4.5z"/>
			</g>
			<g id="Grupo_265" transform="translate(25.778 7.704)">
				<path id="Trazado_1868" d="M3.4,7.6c-0.1,0-0.1,0-0.1-0.1L0.1,4.4C0,4.3,0,4.2,0,4.1c0,0,0,0,0,0l2.9-4C2.9,0,3,0,3.1,0h0.1
					c0.1,0,0.1,0,0.1,0.1l4.3,4.3c0,0,0.1,0.1,0.1,0.1v0.1c0,0.1,0,0.1-0.1,0.2l-4,2.8C3.5,7.6,3.5,7.6,3.4,7.6z M0.5,4.2l3,3
					l3.7-2.6L3.1,0.5L0.5,4.2z"/>
			</g>
			<g id="Grupo_266" transform="translate(12.184 7.704)">
				<path id="Trazado_1869" d="M3.1,4.4L3.1,4.4c-0.1,0-0.1,0-0.2-0.1L0,0.3C0,0.2,0,0.1,0.1,0c0,0,0.1,0,0.1,0h16.5
					c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.1l-2.9,4c0,0.1-0.1,0.1-0.2,0.1L3.1,4.4C3.1,4.4,3.1,4.4,3.1,4.4z M3.2,4h10.5l2.6-3.6
					H0.6L3.2,4z"/>
			</g>
			<g id="Grupo_267" transform="translate(28.958 12.111)">
				<path id="Trazado_1870" d="M4.2,17c0,0-0.1,0-0.1,0l-4-2.8C0,14.1,0,14,0,13.9c0,0,0,0,0,0V3c0,0,0,0,0,0c0-0.1,0-0.1,0.1-0.1
					l4-2.8c0.1-0.1,0.2,0,0.3,0c0,0,0,0.1,0,0.1v16.6C4.4,16.9,4.4,17,4.2,17L4.2,17L4.2,17z M0.4,13.9L4,16.4V0.6L0.4,3.1V13.9z"/>
			</g>
			<g id="Grupo_268" transform="translate(25.749 59.008)">
				<path id="Trazado_1871" d="M3.2-25.5L3.2-25.5c-0.2,0-0.2,0-0.3-0.1l-2.9-4c-0.1-0.1,0-0.2,0-0.3c0,0,0,0,0.1,0L3.3-33
					c0.1-0.1,0.2-0.1,0.3,0l4,2.8c0.1,0,0.1,0.1,0.1,0.2v0.1c0,0.1,0,0.1-0.1,0.1l-4.3,4.3C3.3-25.5,3.2-25.5,3.2-25.5z M0.5-29.7
					L3.1-26l4-4l-3.7-2.6L0.5-29.7z"/>
			</g>
			<g id="Grupo_269" transform="translate(7.849 58.987)">
				<path id="Trazado_1872" d="M4.6-25.5L4.6-25.5c-0.1,0-0.2,0-0.2-0.1l-4.3-4.3C0-29.9,0-29.9,0-30v-0.1c0-0.1,0-0.1,0.1-0.2
					l4-2.8c0.1-0.1,0.2,0,0.3,0l3.1,3.1c0.1,0,0.2,0.1,0.1,0.3c0,0,0,0,0,0.1l-2.9,4C4.7-25.5,4.6-25.5,4.6-25.5z M0.5-30l4.1,4.1
					l2.7-3.7l-3-3L0.5-30z"/>
			</g>
			<g id="Grupo_270" transform="translate(12.227 62.184)">
				<path id="Trazado_1873" d="M16.6-28.7H0.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.1l2.9-4c0-0.1,0.1-0.1,0.2-0.1h10.7
					c0.1,0,0.1,0,0.2,0.1l2.9,4c0.1,0.1,0.1,0.2,0,0.3C16.7-28.7,16.6-28.7,16.6-28.7L16.6-28.7z M0.6-29.1h15.6l-2.6-3.6H3.2
					L0.6-29.1z"/>
			</g>
			<g id="Grupo_271" transform="translate(11.852 11.724)">
				<path id="Trazado_1874" d="M14.1,17.8H3.4c-0.1,0-0.1,0-0.1-0.1l-3.2-3.2c0,0-0.1-0.1-0.1-0.1V3.4c0-0.1,0-0.1,0.1-0.1l3.2-3.2
					C3.3,0,3.4,0,3.4,0h10.7c0.1,0,0.1,0,0.1,0.1l3.2,3.2l0,0c0,0,0.1,0.1,0.1,0.1v10.9c0,0.1,0,0.1-0.1,0.1l0,0l-3.2,3.2
					C14.2,17.7,14.2,17.8,14.1,17.8z M3.5,17.4H14l3.1-3.1V3.5L14,0.4H3.5L0.4,3.5v10.8L3.5,17.4z"/>
			</g>
		</g>
		<g id="Trazado_1661" transform="translate(0 0.027)">
			<path id="Trazado_1875" d="M34.5,41.2H6.8c-0.1,0-0.1,0-0.1-0.1l-6.6-6.6c0,0-0.1-0.1-0.1-0.1V6.8c0-0.1,0-0.1,0.1-0.1l6.6-6.6
				C6.7,0,6.7,0,6.8,0h27.7c0.1,0,0.1,0,0.1,0.1l6.6,6.6c0,0,0.1,0.1,0.1,0.1v27.7c0,0.1,0,0.1-0.1,0.1l0,0l-6.6,6.6
				C34.6,41.2,34.5,41.2,34.5,41.2z M6.9,40.8h27.5l6.5-6.5V6.9l-6.5-6.5H6.9L0.4,6.9v27.5L6.9,40.8z"/>
		</g>
	</g>
</g>
</Icon>
);
