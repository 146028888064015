import React from 'react';
import { Center, Spinner } from "@chakra-ui/react"


/**
 * Primary UI component for user interaction
 */
export const Loader = props => {

  return (
    <Center w="100%" h="100%">
      <Spinner color="brand.300" />
    </Center>
  );
}

Loader.propTypes = {

};

Loader.defaultProps = {

};
