import { Box, Center, Text } from "@chakra-ui/react";
import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Container } from "../Primatives";
import {CurrentMarketUserContext} from "../../components/UtilityComponents/MarketOS/context";

export const GetStarted = (props) => {
    const bgColor = props.bgColor ? props.bgColor : 'white';
    const { currentMarketUser } = useContext(CurrentMarketUserContext);

    return (
        <Box
            bgColor={bgColor}
            textAlign={"center"}
            py={{ base: 10, lg: 20 }}
            px={{ base: 10, lg: 20 }}
        >
            <Center>
                <Divider variant={"gold"} w={44} mb={12} />
            </Center>

            <Text
                as={"h1"}
                fontSize={{ base: "3xl", md: "4xl", xl: "4xl" }}
                lineHeight={"shorter"}
                fontFamily="QuicheText, sans-serif"
                textTransform={"uppercase"}
            >
                Get started
            </Text>

            <Container maxW="2xl">
                <Text
                    mt={6}
                    mb={10}
                    fontSize={24}
                    fontFamily={"Quicksand, sans-serif"}
                >
                    Register at Carbonext to find the perfect diamond at a great
                    price. Whichever diamond you choose, know that it will be
                    backed by the guarantee that it was the best diamond for the
                    best price.
                </Text>
            </Container>
            <Link
                to={
                    currentMarketUser
                        ? "market/diamonds"
                        : "market/sign-up"
                }
            >
                <Button size="md" variant="market.secondary">
                    {" "}
                    {currentMarketUser
                        ? "Search our diamonds"
                        : "Register now"}
                </Button>
            </Link>
        </Box>
    );
};

GetStarted.propTypes = {};

GetStarted.defaultProps = {};
