import gql from "graphql-tag";

const GQL_Q_SEARCH_BANK_ACCOUNTS = gql`
    query SearchBankAccounts($searchParam: BankAccountSearchParam!) {
        searchBankAccounts(searchParam: $searchParam) {
            id
            accountNumber
            routingNumber
            tags
            uses {
                onlineTransfer
            }
            thirdPartyReferenceEntities {
                billDotCom {
                    vendorBankAccountId
                }
            }
        }
    }
`;

export { GQL_Q_SEARCH_BANK_ACCOUNTS };
