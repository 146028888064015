import React, { FC, useContext } from "react";
import { Box, Grid, GridItem, Progress } from "../../Primatives";
import { RunningMutationsContext } from "../Context";

export interface IProps {
    header: any;
    body: any;
    headerHeight?: any;
}

export const HeaderBodyShell: FC<IProps> = ({
    header,
    body,
    headerHeight, // TODO - how to give a default??
}: IProps) => {
    let runningMutations = useContext(RunningMutationsContext);

    return (
        <Grid
            w="100%"
            h="100%"
            maxH="100vh"
            templateRows={`${headerHeight} 2px 1fr`}
        >
            <GridItem>{header}</GridItem>

            <Box w="100%" h="100%">
                {runningMutations && runningMutations.length > 0 ? (
                    <Progress
                        size="xs"
                        isIndeterminate
                        colorScheme="yellow"
                        height={"100%"}
                    />
                ) : (
                    <Box
                        bgGradient="linear(180deg, gray.400, white)"
                        w="100%"
                        h="100%"
                    ></Box>
                )}
            </Box>

            <GridItem overflowY="scroll">{body}</GridItem>
        </Grid>
    );
};

HeaderBodyShell.defaultProps = { headerHeight: "4rem" };
