import { useQuery } from "@apollo/react-hooks";
import { Box, SimpleGrid, useColorModeValue as mode } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { LIST_OF_GEM_LISTING_STATE_CATEGORIES_IN_ORDER } from "../../../constants/businessConstants";
import { Header } from "../../../stories/CloudManagementOS";
import { BreadCrumbToolbarInnerBody } from "../../../stories/CloudManagementOS/BreadCrumbToolbarInnerBody";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { convertConstToUrlConst } from "../../../stories/CloudManagementOS/ConstToUrl";
import { CloudManagementOSVariant } from "../../../stories/CloudManagementOS/Context";
import { useUserEvents } from "../../../stories/CloudManagementOS/hooks/useUserEvents";
import { HeaderBodyShell } from "../../../stories/CloudManagementOS/shells";
import { StatCard } from "../../../stories/CloudManagementOS/StatCard";

const Body = ({ actionMenuButtons, transformedDataFromGQLQuery }) => {
    return (
        <BreadCrumbToolbarInnerBody
            tools={null}
            meta={null}
            actionMenuButtons={actionMenuButtons}
            breadcrumbs={[{ breadcrumb: "HOME", link: "" }]}
            body={
                <Box as="section" bg={mode("gray.50", "gray.800")} p="10">
                    <Box maxW="7xl" mx="auto" px={{ base: "6", md: "8" }}>
                        <SimpleGrid
                            columns={{ base: 1, md: 2, lg: 4 }}
                            spacing="6"
                        >
                            {transformedDataFromGQLQuery?.map(
                                (categoryStat, idx) => (
                                    <StatCard key={idx} data={categoryStat} />
                                )
                            )}
                        </SimpleGrid>
                    </Box>
                </Box>
            }
        />
    );
};

export const GemListingDashboard = ({
    gqlQueries,
    actionMenuButtons,
    urlPrefix,
    toolUrlRoute,
    bodyOnly,
    scope,
}) => {
    let searchParam = {};

    if (scope) {
        searchParam.filter = { ...scope };
    }

    const { loading, error, data, refetch } = useQuery(
        gqlQueries.globalStatForSearchQuery,
        {
            variables: { searchParam },
        }
    );

    let transformedDataFromGQLQuery = data
        ? LIST_OF_GEM_LISTING_STATE_CATEGORIES_IN_ORDER.map((category) => ({
              label: category,
              value:
                  data.getGlobalStatsFromSearchOfGemListings.totalPrice
                      .byCategory[category],
              count:
                  data.getGlobalStatsFromSearchOfGemListings.counts.byCategory[
                      category
                  ],
              currency: "$",
              link: `/${urlPrefix}/${toolUrlRoute}/gem-listings/${convertConstToUrlConst(
                  category
              )}`,
          }))
        : null;

    let variant = useContext(CloudManagementOSVariant);

    let { eventToHandle } = useUserEvents();
    // console.log("EVEENTTTTSSSS", events)

    useEffect(() => {
        if (eventToHandle && eventToHandle.type === "mutation.state_change") {
            if (eventToHandle.data.state === "COMPLETED") {
                refetch();
            }
        }
    }, [eventToHandle]);

    if (bodyOnly) {
        return (
            <Body
                actionMenuButtons={actionMenuButtons}
                transformedDataFromGQLQuery={transformedDataFromGQLQuery}
            />
        );
    }

    return (
        <HeaderBodyShell
            headerHeight="4rem"
            header={
                <Header
                    variant={variant}
                    title={getConstToLanguage("GEM_LISTINGS")}
                    bg={variant === "full" ? "white" : "off-black"}
                    linkTo={`/${urlPrefix}/${toolUrlRoute}/home`}
                    color={variant === "full" ? "off-black" : "white"}
                />
            }
            body={
                <Body
                    actionMenuButtons={actionMenuButtons}
                    transformedDataFromGQLQuery={transformedDataFromGQLQuery}
                />
            }
        />
    );
};

export default GemListingDashboard;
