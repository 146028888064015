import PubSub from 'pubsub-js';
import { useEffect, useState } from 'react';

export function useUserEvents() {


    const [eventToHandle, setEventToHandle] = useState();


    useEffect(() => {
        const token = PubSub.subscribe("user:events", (topic, incomingEvent) => (setEventToHandle(incomingEvent)));
        return () => PubSub.unsubscribe(token);
    }, [eventToHandle])


    return { eventToHandle }
}