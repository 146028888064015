import React from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    VStack,
    Spinner,
    Alert,
    AlertTitle,
    AlertIcon,
    Center,
    HStack,
    Heading,
} from "../../Primatives";

import { WarningTwoIcon } from "@chakra-ui/icons";

const Errors = ({ error }) => {
    return (
        <VStack align="left">
            <Box>
                {error.message}
                {/* {errors?.map((error) => (
                    <Alert status="error">
                        <AlertIcon />
                        <AlertTitle>{error.message}</AlertTitle>
                    </Alert>
                ))} */}
            </Box>
        </VStack>
    );
};

const Query = ({ loading, error, children }) => {
    const { t } = useTranslation();
    if (loading) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }
    if (error) {
        return (
            <Center w="100%" h="100%">
                <VStack>
                    <HStack>
                        <WarningTwoIcon boxSize={6} color={"red.200"} />
                        <Heading fontFamily="Montserrat">{t("ERROR")}</Heading>
                    </HStack>

                    <Box>{t("THERE_WAS_ERROR")}</Box>
                </VStack>
            </Center>
        );
    }
    return (
        <Box>
            {error && <Errors error={error} />}
            {children}
        </Box>
    );
};
export default Query;
