import React from "react";
import { getShapeCategoryIcon, CloseIcon } from "../../../../IconsV2";
import { numberToCurrency } from "../../../etc/floatToCurrency";
import { getConstToLanguage } from "../../../../../stories/CloudManagementOS/ConstToLanguageMapping";
import {
    Box,
    Flex,
    HStack,
    SpinnerButton,
    VStack,
} from "../../../../../stories/Primatives";
import { useTranslation } from "react-i18next";

const FormatedSpecs = ({ specs }) => {
    let { t } = useTranslation();
    const carat = specs.carat ? specs.carat + " " + t("gems:CARAT") + " " : "";
    const color = specs.color ? t(`gems:${specs.color}`) + " " : "";
    const clarity = specs.clarity ? t(`gems:${specs.clarity}`) + " " : "";
    const cut = specs.cut
        ? t(`gems:${specs.cut}`) + " " + t("gems:CUT") + " "
        : "";
    const shape = specs.shape
        ? t(`gems:${specs.shape}`) + " " + t("gems:SHAPE") + " "
        : "";

    return (
        <Box>{carat + color + clarity + cut + shape + t("gems:DIAMOND")}</Box>
    );
};

const SalesOrderItem = (props) => {
    let { t } = useTranslation();
    return (
        <HStack w="100%" spacing={4} h="6.25rem">
            <Box>
                <Box w="6.25rem">
                    {getShapeCategoryIcon(props.item.certifiedGem.specs.shape)(
                        {}
                    )}
                </Box>
            </Box>
            <VStack align={"left"} w="100%">
                <Flex>
                    <Box color={"brand.500"}>GIA:</Box>{" "}
                    {props?.item?.certifiedGem?.certification?.certificationId}
                </Flex>
                <Flex justifyContent={"space-between"} w="100%">
                    <FormatedSpecs specs={props?.item?.certifiedGem.specs} />
                    <Box>{numberToCurrency(props.item.price)}</Box>
                </Flex>
            </VStack>

            {props.onRemove && (
                <Box>
                    <SpinnerButton
                        value={props.item.id}
                        onClick={props.onRemove}
                    >
                        <CloseIcon />
                    </SpinnerButton>
                </Box>
            )}
        </HStack>
    );
};

export default SalesOrderItem;
