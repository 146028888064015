import React, { useContext } from "react";
import { CloudManagementOSVariant } from "../../../stories/CloudManagementOS/Context";
import { Links } from "../../../stories/Primatives";

const CertificateInfo = ({ gemListing }) => {

  let cloudManagementOSVariant = useContext(CloudManagementOSVariant)

  let links = {
    GIA_CERTIFICATE: {
      link: `https://www.gia.edu/report-check?reportno=${gemListing.certifiedGem.certification.certificationId}`,
    }
  }

  if (gemListing.state === 'ON_MARKET') {
    links.LISTING_ON_MARKET = {
      link: `${process.env.REACT_APP_CARBONEXT_HOME}/market/diamonds/${gemListing.id}`
    }
  }
  return (
    <div className="gem-listing-identifier">
      <div>
        <div className="gem-listing-identifier__certification-type">
          {gemListing.certifiedGem.certification.certificationType}:{" "}
        </div>
        <div className="gem-listing-identifier__certification-id">
          {gemListing.certifiedGem.certification.certificationId}
        </div>
      </div>
      <Links
        variant={cloudManagementOSVariant === "collapse" ? "modal" : "popover"}
        links={links}
      />
    </div>


  );
}



export default CertificateInfo;
