import React, { useState } from "react";
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  useDisclosure,
} from "../Primatives";

const ActionsMenu = ({ buttons }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let [renderedAction, setRenderedAction] = useState();

  const openAndSetRenderedAction = async (action) => {
    await setRenderedAction(action);
    onOpen();
  };

  return (
    <>
      <SimpleGrid columns={2}>
        {buttons &&
          Object.entries(buttons).map(([action, button]) => (
            <div
              key={action}
              className="action-menu-button grow2"
              onClick={() =>
                button.action
                  ? button.action()
                  : openAndSetRenderedAction(button)
              }
            >
              <div>{button.icon}</div>
              <div>{button.description}</div>
            </div>
          ))}
      </SimpleGrid>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{renderedAction?.description}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderedAction?.body}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

class MainActionMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAction: null,
      showAction: false,
    };

    // this.renderActionsMenu = this.renderActionsMenu.bind(this);
  }

  renderActionBody(actionBody) {
    return (
      <div className="action-menu">
        <div className="action-menu-action-body ">
          <div className="action-menu-action-body__title">
            <h2>{this.state && this.state.description}</h2>
          </div>
          <div onClick={() => this.setState({ selectedAction: null })}>x</div>
          {actionBody}
        </div>
      </div>
    );
  }

  // renderActionsMenu() {

  //   const { isOpen, onOpen, onClose } = useDisclosure()

  //   return (
  //     <SimpleGrid columns={2}>
  //       {this.props.buttons &&
  //         Object.entries(this.props.buttons).map(([action, button]) => (
  //           <div
  //             className="action-menu-button grow2"
  //           >
  //             <div>{button.icon}</div>
  //             <div>{button.description}</div>
  //           </div>
  //         ))}
  //     </SimpleGrid >
  //   )

  // }

  // renderActionsMenu() {
  //   return (
  //     <ModalConsumer>
  //       {({ showModal }) => (
  //         <div className="action-menu">
  //           <div className="action-menu-button-body">
  //             <div className="action-menu-button-container">
  //               {this.props.buttons &&
  //                 Object.entries(this.props.buttons).map(([action, button]) => (
  //                   <div
  //                     className="action-menu-button grow2"
  //                     onClick={() =>
  //                       showModal(Modal1, {
  //                         body: button.body,
  //                         description: button.description,
  //                       })
  //                     }
  //                   >
  //                     <div>{button.icon}</div>
  //                     <div>{button.description}</div>
  //                   </div>
  //                 ))}
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </ModalConsumer>
  //   );
  // }

  render() {
    let hasButtons =
      this.props.buttons && Object.keys(this.props.buttons).length > 0;
    return (
      <Popover trigger="hover" placement="bottom-end">
        <PopoverTrigger>
          <Button
            onClick={() =>
              this.setState({ showAction: !this.state.showAction })
            }
            variant="cloud-management-os.primary"
            size="sm"
            disabled={!hasButtons}
          >
            {getConstToLanguage("ACTIONS")}
          </Button>
        </PopoverTrigger>
        {hasButtons && (
          <PopoverContent boxShadow="lg">
            <PopoverArrow />
            <PopoverBody>
              <ActionsMenu buttons={this.props.buttons} />
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    );
  }
}
export default MainActionMenu;
