import {
    VENDOR_HOME_PAGE_UNLOADED, 
    ASYNC_END, 
    VENDOR_HOME_CLOSE_REQUEST_ERROR,
    VENDOR_HOME_CLOSE_ALL_REQUEST_ERROR,
    CLOSE_APP_EVENT,
    VENDOR_SETTINGS_PAGE_LOADED,
    GET_DIAMOND_RELATIVE_PRICE_DATA,
    EDIT_VENDOR_INFO_ATTR,
    TOGGLE_VENDOR_INFO_EDIT_MODE,
    SAVE_VENDOR_INFO } from '../constants/actionTypes';

var _ = require('lodash');
const uuidv4 = require('uuid/v4');

export default (state = initialState(), action) => {
  switch (action.type) {
    case VENDOR_HOME_PAGE_UNLOADED:
      return {};
    case VENDOR_HOME_CLOSE_ALL_REQUEST_ERROR:
    case VENDOR_HOME_CLOSE_REQUEST_ERROR:
    // TODO - make this isolated close
      return {
               ...state, 
              requestErrors: []

      };

    case VENDOR_SETTINGS_PAGE_LOADED:

      return {
        ...state,
        meInfo: action.payload[0].me
      }
    case GET_DIAMOND_RELATIVE_PRICE_DATA:

      return {
        ...state,
        gemRelativeData: action.payload
      };
    case CLOSE_APP_EVENT:
      let newEvents2 = state.vendorEvents.filter(function(item){return item.eventId!==action.event.eventId})

      return {
            ...state,
            vendorEvents: newEvents2
          }
    case TOGGLE_VENDOR_INFO_EDIT_MODE:
      let updateVendorInfo;
      if (state.vendorInfoEditMode) {
        updateVendorInfo = {}
      } else {
        updateVendorInfo = state.meInfo
      }
      return {
            ...state,
            vendorInfoEditMode: !state.vendorInfoEditMode,
            updateVendorInfo: updateVendorInfo
          }
    case EDIT_VENDOR_INFO_ATTR:

      let updateVendorInfo2 = {...state.updateVendorInfo}
      _.set(updateVendorInfo2, action.attr, action.value);
      return {
        ...state,
        updateVendorInfo: updateVendorInfo2
      }
    case SAVE_VENDOR_INFO:
      return {
        ...state,
        meInfo: action.payload.me,
        vendorInfoEditMode: false
      }

    case ASYNC_END:
      let apiSplit = action.path.split('/')
      let tool = apiSplit[1]
      let view = apiSplit[2]

      if (tool==='vendor' && view !== 'login') {

        if (action.promise && action.promise.result !== undefined) {
          let newEvents = state.vendorEvents.slice();
          newEvents.push({...action.promise, eventId:uuidv4()})
          return {
            ...state,
            vendorEvents: newEvents
          }

        }
      }
      return state;
      
    default:
      return state;
  }
};

function initialState () {
  return {vendorEvents: [], requestErrors: [], 
    updateVendorInfo: {},
    vendorInfoEditMode: false,
    gemListingsSearchFlags: {
      statesFiltered: false,
      shapesFiltered: false
    }
  }
}