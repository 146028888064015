import { useParams } from "react-router-dom";

export function useParamsMatching(compare) {
    const params = useParams();

    let isCurrentlySelected = true;

    for (const [key, value] of Object.entries(compare)) {
        console.log("a;lksjdf;akls", key, value, params);

        if (
            !(key in params) ||
            (key in params &&
                params[key].toLowerCase() !==
                    (value?.startsWith("/")
                        ? value?.toLowerCase().substr(1)
                        : value?.toLowerCase()))
        ) {
            isCurrentlySelected = false;
        }

        if (value === undefined) {
            console.log("VLUAUUEESSF");
            isCurrentlySelected = !(key in params);
        }
    }

    return isCurrentlySelected;
}
