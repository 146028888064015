function GQLInputArg(name, type, required = false) {
    this.name = name;
    this.type = type;
    this.required = required;

    const buildVariableDefinitions = () => {
        let result = `$${this.name}: ${this.type}`;
        if (required === true) {
            result += "!";
        }
        return result;
    };

    const buildArgs = () => {
        let result = `${this.name}: $${this.name}`;

        return result;
    };

    return Object.assign(this, {
        buildVariableDefinitions,
        buildArgs,
    });
}

function GQLFragment(attr, requestedAttrs) {
    this.attr = attr;
    this.requestedAttrs = requestedAttrs;

    const toGQLString = () => {
        console.log(":DSF:LKJDS:LIFJSD", this);

        return `${this.attr} {${this.requestedAttrs
            .map((attr) => attrToString(attr))
            .join(" ")}}`;
    };
    return Object.assign(this, {
        toGQLString,
    });
}

const attrToString = (attr) => {
    console.log(attr.constructor.name);
    if (typeof attr === "string") {
        return attr;
    } else if (attr instanceof GQLFragment) {
        console.log("WEPOIURPOIEWURPOIEWPIOW");
        return attr.toGQLString();
    }
};

const requestedAttrsToGQLString = (requestedAttrs) => {
    return requestedAttrs.map((attr) => attrToString(attr)).join("\n");
};

function GraphqlQueryBuilder({ api, args, type }) {
    const operationName = api.charAt(0).toUpperCase() + api.slice(1);
    this.api = api;
    this.args = args;

    const build = ({ requestedAttrs }) => {
        let requestedAttrsStr = requestedAttrs
            ? `{
        ${requestedAttrsToGQLString(requestedAttrs)} 
    }`
            : "";
        return `${type} ${operationName} (${args
            .map((arg) => arg.buildVariableDefinitions())
            .join(" ")}) {
         ${this.api} (${this.args.map((arg) => arg.buildArgs()).join(" ")})
        ${requestedAttrsStr}
}`;
    };
    return Object.assign(this, {
        build,
    });
}

export { GraphqlQueryBuilder, GQLInputArg, GQLFragment };
