import gql from "graphql-tag";

const GQL_Q_CURRENT_ADMIN = gql`
query CurrentAdmin {
  currentAdmin {
    createdAt
    localAuth {
      email
    }
    lastLogin {
      IP
      at
    }
    person {
      familyName
      givenName
    }
  }
}
`;
export { GQL_Q_CURRENT_ADMIN };
