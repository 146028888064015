export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const SIGNUP = 'SIGNUP';
export const LOGOUT = 'LOGOUT';
export const GEM_PAGE_LOADED = 'GEM_CREATOR_PAGE_LOADED'
export const GEM_PAGE_UNLOADED = 'GEM_CREATOR_PAGE_UNLOADED'
export const GEM_CREATOR_PAGE_LOADED = 'GEM_CREATOR_PAGE_LOADED'
export const GEM_CREATOR_PAGE_UNLOADED = 'GEM_CREATOR_PAGE_UNLOADED'
export const GEM_SUBMITTED = 'GEM_SUBMITTED'
export const SET_PAGE = 'SET_PAGE'

export const GO_TO_SIGNUP = 'GO_TO_SIGNUP'

export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH'
export const SIGNUP_PAGE_UNLOADED ='SIGNUP_PAGE_UNLOADED'


export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED'
export const USER_VERIFIED = 'USER_VERIFIED'
export const USER_UNVERIFIED ='USER_UNVERIFIED'
export const TRADER_SIGNUP = 'TRADER_SIGNUP'
export const MARKET_SIGNUP = 'MARKET_SIGNUP'
export const UPDATE_FIELD_SIGN_UP_TYPES = 'UPDATE_FIELD_SIGN_UP_TYPES'



export const MARKET_LOGOUT = 'MARKET_LOGOUT'
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'
export const VENDOR_LOGOUT = 'VENDOR_LOGOUT'


export const INDEX_PAGE_UNLOADED = 'INDEX_PAGE_UNLOADED'

export const USER_INFO_SUBMITTED = 'USER_INFO_SUBMITTED'

export const VENDOR_LOGIN_PAGE_UNLOADED = 'VENDOR_LOGIN_PAGE_UNLOADED'
export const VENDOR_LOGIN = 'VENDOR_LOGIN';
export const VENDOR_SIGNUP_PAGE_UNLOADED = 'VENDOR_SIGNUP_PAGE_UNLOADED'
export const VENDOR_SIGNUP = 'VENDOR_SIGNUP';
export const VENDOR_HOME_PAGE_LOADED = 'VENDOR_HOME_PAGE_LOADED'
export const VENDOR_HOME_PAGE_UNLOADED = 'VENDOR_HOME_PAGE_UNLOADED'
export const VENDOR_SIGNUP_LOADED = 'VENDOR_SIGNUP_LOADED'
export const VENDOR_LOGIN_UPDATE_FIELD_AUTH = 'VENDOR_LOGIN_UPDATE_FIELD_AUTH'
export const VENDOR_SIGNUP_UPDATE_FIELD_AUTH = 'VENDOR_SIGNUP_UPDATE_FIELD_AUTH'
export const VENDOR_TRADER_PAGE_LOADED = 'VENDOR_TRADER_PAGE_LOADED'
export const VENDOR_TRADER_GEMS_NEXT_PAGE = 'VENDOR_TRADER_GEMS_NEXT_PAGE'
export const VENDOR_TRADER_GEMS_PREVIOUS_PAGE = 'VENDOR_TRADER_GEMS_PREVIOUS_PAGE'
export const TRADER_GEM_SEARCH_UPDATE_FIELD = 'TRADER_GEM_SEARCH_UPDATE_FIELD'
export const TRADER_GEM_SEARCH_SUBMIT = 'TRADER_GEM_SEARCH_SUBMIT'
export const VENDOR_JEWELRY_MAKER_PAGE_LOADED = 'VENDOR_JEWELRY_MAKER_PAGE_LOADED'
export const VENDOR_JEWELRY_MAKER_ABOUT_PAGE_LOADED = 'VENDOR_JEWELRY_MAKER_ABOUT_PAGE_LOADED'
export const VENDOR_JEWELRY_MAKER_ABOUT_TOGGLE_EDIT_MODE = 'VENDOR_JEWELRY_MAKER_ABOUT_TOGGLE_EDIT_MODE'
export const VENDOR_JEWELRY_MAKER_ABOUT_UPDATE_FIELD = 'VENDOR_JEWELRY_MAKER_ABOUT_UPDATE_FIELD'
export const VENDOR_JEWELRY_MAKER_ABOUT_SAVE = 'VENDOR_JEWELRY_MAKER_ABOUT_SAVE'
export const VENDOR_HOME_CLOSE_REQUEST_ERROR = 'VENDOR_HOME_CLOSE_REQUEST_ERROR'
export const VENDOR_HOME_CLOSE_ALL_REQUEST_ERROR = 'VENDOR_HOME_CLOSE_ALL_REQUEST_ERROR'
export const SAVE_VENDOR_INFO = 'SAVE_VENDOR_INFO'

export const VENDOR_CREATED = 'VENDOR_GEM_CREATED'
export const MEDIUM_UPLOADED = 'MEDIUM_UPLOADED'

export const TRADER_UPLOAD_GEM_LISTING_CSV = 'TRADER_UPLOAD_GEM_LISTING_CSV'
export const TRADER_GEM_PUT_ON_MARKET = 'TRADER_GEM_PUT_ON_MARKET'
export const TRADER_GEM_TAKE_OFF_MARKET = 'TRADER_GEM_TAKE_OFF_MARKET'
export const TRADER_GEM_REQUEST_VERIFICATION = 'TRADER_GEM_REQUEST_VERIFICATION'
export const TRADER_GEM_CHANGE_PRICE = 'TRADER_GEM_CHANGE_PRICE'
export const TRADER_GEM_LISTING_PRICE_CHANGE = 'TRADER_GEM_LISTING_PRICE_CHANGE'
export const TRADER_TOGGLE_GEM_PRICE_EDIT_MODE = 'TRADER_TOGGLE_GEM_PRICE_EDIT_MODE'
export const TRADER_SIGNUP_LOADED = 'TRADER_SIGNUP_LOADED'
export const TRADER_GEMS_NEXT_PAGE = 'TRADER_GEMS_NEXT_PAGE'
export const TRADER_GEMS_PREVIOUS_PAGE = 'TRADER_GEMS_PREVIOUS_PAGE'
export const TRADER_GEM_LISTING_GO_TO_SUBTOOL = 'TRADER_GEM_LISTING_GO_TO_SUBTOOL'
export const TRADER_GEM_MANAGEMENT_PAGE_LOADED = 'TRADER_GEM_MANAGEMENT_PAGE_LOADED'
export const TRADER_GEM_MANAGEMENT_SUBTOOL_UNLOADED = 'TRADER_GEM_MANAGEMENT_SUBTOOL_UNLOADED'
export const TRADER_EDIT_GEM_LISTING_PRICE = 'TRADER_EDIT_GEM_LISTING_PRICE'
export const TRADER_EDIT_GEM_LISTING_ACQ_PRICE = 'TRADER_EDIT_GEM_LISTING_ACQ_PRICE'
export const TRADER_TOGGLE_GEM_ACQ_PRICE_EDIT_MODE = 'TRADER_TOGGLE_GEM_ACQ_PRICE_EDIT_MODE'
export const TRADER_GEM_CHANGE_ACQ_PRICE = 'TRADER_GEM_CHANGE_ACQ_PRICE'
export const VENDOR_SETTINGS_PAGE_LOADED = 'VENDOR_SETTINGS_PAGE_LOADED'
export const TOGGLE_VENDOR_INFO_EDIT_MODE = 'TOGGLE_VENDOR_INFO_EDIT_MODE'
export const EDIT_VENDOR_INFO_ATTR = 'EDIT_VENDOR_INFO_ATTR'
export const JEWELRY_MAKER_SIGNUP_LOADED = 'JEWELRY_MAKER_SIGNUP_LOADED'
export const JEWELRY_MAKER_SIGNUP = 'JEWELRY_MAKER_SIGNUP'
export const TRADER_CREATE_GEM_LISTING = 'TRADER_CREATE_GEM_LISTING'
export const TRADER_UPDATE_SPEC_FOR_NEW_GEM_LISTING = 'TRADER_UPDATE_SPEC_FOR_NEW_GEM_LISTING'
export const TRADER_CREATE_GEM_LISTING_UNLOADED = 'TRADER_CREATE_GEM_LISTING_UNLOADED'
export const GET_DIAMOND_RELATIVE_PRICE_DATA = 'GET_DIAMOND_RELATIVE_PRICE_DATA'
export const TRADER_SUBMIT_GEM_SPECS_FOR_RELATIVE_PRICE_SEARCH = 'TRADER_SUBMIT_GEM_SPECS_FOR_RELATIVE_PRICE_SEARCH'
export const TRADER_UPDATE_SPEC_FOR_RELATIVE_PRICE_SEARCH = 'TRADER_UPDATE_SPEC_FOR_RELATIVE_PRICE_SEARCH'
export const UPDATE_SEARCH_SORT_BY_ATTR = 'UPDATE_SEARCH_SORT_BY_ATTR'
export const TRADER_ARCHIVE_GEM_LISTING = 'TRADER_ARCHIVE_GEM_LISTING'
export const TRADER_CANCEL_ARCHIVE_GEM_LISTING = 'TRADER_CANCEL_ARCHIVE_GEM_LISTING'
export const TRADER_CONFIRM_ARCHIVE_GEM_LISTING = 'TRADER_CONFIRM_ARCHIVE_GEM_LISTING'

// TODO - may rename "sales pipeline"
export const SUPER_ADMIN_REPULL_RELATIVE_GEM_LISTING_FOR_GEM_LISTINGS_IN_SALES_PIPELINE = 'SUPER_ADMIN_REPULL_RELATIVE_GEM_LISTING_FOR_GEM_LISTINGS_IN_SALES_PIPELINE'


export const ADMIN_LOGIN_PAGE_UNLOADED = 'ADMIN_LOGIN_PAGE_UNLOADED'
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_HOME_PAGE_LOADED = 'ADMIN_HOME_PAGE_LOADED'
export const ADMIN_HOME_PAGE_UNLOADED = 'ADMIN_HOME_PAGE_UNLOADED'
export const ADMIN_USER_MANAGEMENT_PAGE_LOADED = 'ADMIN_USER_MANAGEMENT_PAGE_LOADED'
export const UPDATE_ADMIN_LOGIN_FIELD_AUTH = 'UPDATE_ADMIN_LOGIN_FIELD_AUTH'
export const ADMIN_CHANGE_TOOL ='ADMIN_CHANGE_TOOL'
export const ADMIN_ADMIN_MANAGEMENT_PAGE_LOADED = 'ADMIN_ADMIN_MANAGEMENT_PAGE_LOADED'
export const UPDATE_NEW_ADMIN_FIELD = 'UPDATE_NEW_ADMIN_FIELD'
export const ADMIN_GEM_DELETED = 'ADMIN_GEM_DELETED'
export const ADMIN_USER_DELETED = 'ADMIN_USER_DELETED'
export const ADMIN_CREATED = 'ADMIN_CREATED'
export const CREATE_ADMIN = 'CREATE_ADMIN'
export const TOGGLE_ADMIN_ACTIVE_ATTR = 'TOGGLE_ADMIN_ACTIVE_ATTR'
export const ADMIN_GEM_MANAGEMENT_PAGE_LOADED = 'ADMIN_GEM_MANAGEMENT_PAGE_LOADED'
export const ADMIN_TOGGLE_SHOW_JEWELRY_MAKER_INFO = 'ADMIN_TOGGLE_SHOW_JEWELRY_MAKER'
export const ADMIN_TOGGLE_SHOW_TRADER_INFO = 'ADMIN_TOGGLE_SHOW_TRADER_INFO'
export const ADMIN_TOGGLE_SHOW_TRADER_GEMS = 'ADMIN_TOGGLE_SHOW_TRADER_GEMS'
export const ADMIN_GEMS_NEXT_PAGE = 'ADMIN_GEMS_NEXT_PAGE'
export const ADMIN_GEMS_PREVIOUS_PAGE = 'ADMIN_GEMS_PREVIOUS_PAGE'
export const ADMIN_TRADER_GEMS_NEXT_PAGE = 'ADMIN_TRADER_GEMS_NEXT_PAGE'
export const ADMIN_TRADER_GEMS_PREVIOUS_PAGE = 'ADMIN_TRADER_GEMS_PREVIOUS_PAGE'
export const ADMIN_GEM_SEARCH_UPDATE_FIELD = 'ADMIN_GEM_SEARCH_UPDATE_FIELD'
export const ADMIN_GEM_SEARCH_SUBMIT = 'ADMIN_GEM_SEARCH_SUBMIT'
export const ADMIN_USERS_NEXT_PAGE = 'ADMIN_USERS_NEXT_PAGE'
export const ADMIN_USERS_PREVIOUS_PAGE = 'ADMIN_USERS_PREVIOUS_PAGE'
export const ADMIN_USER_SEARCH_UPDATE_FIELD = 'ADMIN_USER_SEARCH_UPDATE_FIELD'
export const ADMIN_USER_SEARCH_SUBMIT = 'ADMIN_USER_SEARCH_SUBMIT'
export const ADMIN_MARK_GEM_AS_SOLD = 'ADMIN_MARK_GEM_AS_SOLD'
export const ADMIN_TOGGLE_GEM_HISTORY_VIEW = 'ADMIN_TOGGLE_GEM_HISTORY_VIEW'
export const ADMIN_GEM_EVENT_HISTORY_LOADED = 'ADMIN_GEM_EVENT_HISTORY_LOADED'
export const ADMIN_TOGGLE_GEM_STATE = 'ADMIN_TOGGLE_GEM_STATE'
export const ADMIN_TOGGLE_GEM_SHAPE = 'ADMIN_TOGGLE_GEM_SHAPE'
export const ADMIN_GEM_LISTING_TOGGLE_SELECT_ALL_GEM_STATES = 'ADMIN_GEM_LISTING_TOGGLE_SELECT_ALL_GEM_STATES'
export const ADMIN_GEM_LISTING_TOGGLE_SELECT_ALL_GEM_SHAPES = 'ADMIN_GEM_LISTING_TOGGLE_SELECT_ALL_GEM_SHAPES'
export const ADMIN_RESET_ATTR_INITIAL_SEARCH_STATE = 'ADMIN_RESET_ATTR_INITIAL_SEARCH_STATE'
export const ADMIN_GEM_LISTING_SEARCH_CHANGE_ATTR = 'ADMIN_GEM_LISTING_SEARCH_CHANGE_ATTR'
export const ADMIN_GEM_LISTING_GO_TO_SUBTOOL = 'ADMIN_GEM_LISTING_GO_TO_SUBTOOL'
export const ADMIN_GEM_MANAGEMENT_SUBTOOL_UNLOADED = 'ADMIN_GEM_MANAGEMENT_SUBTOOL_UNLOADED'
export const ADMIN_GEM_LISTING_DETAILS_LOADED = 'ADMIN_GEM_LISTING_DETAILS_LOADED'
export const ADMIN_GEM_EVENT_HISTORY_UNLOADED = 'ADMIN_GEM_EVENT_HISTORY_UNLOADED'
export const ADMIN_GEM_RELATIVE_PRICING_HISTORY_LOADED = 'ADMIN_GEM_RELATIVE_PRICING_HISTORY_LOADED'
export const ADMIN_GEM_RELATIVE_PRICING_HISTORY_UNLOADED = 'ADMIN_GEM_RELATIVE_PRICING_HISTORY_UNLOADED'
export const ADMIN_GEM_LISTING_DETAILS_UNLOADED = 'ADMIN_GEM_LISTING_DETAILS_UNLOADED'
export const ADMIN_SEARCH_GEM_LISTINGS_BY_CATEGORY = 'ADMIN_SEARCH_GEM_LISTINGS_BY_CATEGORY'
export const ADMIN_RELATIVE_GEM_LISTING_SEARCH_JOBS_PAGE_LOADED = 'ADMIN_RELATIVE_GEM_LISTING_SEARCH_JOBS_PAGE_LOADED'
export const ADMIN_SEARCH_RELATIVE_GEM_LISTING_SEARCH_JOBS = 'ADMIN_SEARCH_RELATIVE_GEM_LISTING_SEARCH_JOBS'

export const UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS'

export const ADMIN_LOGIN_UPDATE_FIELD_AUTH = 'ADMIN_LOGIN_UPDATE_FIELD_AUTH'

export const GEM_VERIFIED = 'GEM_VERIFIED'
export const GEM_UNVERIFIED ='GEM_UNVERIFIED'



export const MARKET_LOGIN = 'MARKET_LOGIN'
export const MARKET_LOGIN_PAGE_UNLOADED = 'MARKET_LOGIN_PAGE_UNLOADED'
export const MARKET_HOME_PAGE_LOADED = 'MARKET_HOME_PAGE_LOADED'
export const MARKET_HOME_PAGE_UNLOADED = 'MARKET_HOME_PAGE_UNLOADED'
export const MARKET_USER_SIGN_UP_PAGE__UPDATE_FIELD = 'MARKET_USER_SIGN_UP_PAGE__UPDATE_FIELD'
export const UPDATE_MARKET_LOGIN_FIELD_AUTH = 'UPDATE_MARKET_LOGIN_FIELD_AUTH'
export const MARKET_UPDATE_PRICE_RANGE_IN_SEARCH_PARAM = 'MARKET_UPDATE_PRICE_RANGE_IN_SEARCH_PARAM'
export const MARKET_UPDATE_CARAT_RANGE_IN_SEARCH_PARAM = 'MARKET_UPDATE_CARAT_RANGE_IN_SEARCH_PARAM'
export const MARKET_UPDATE_CUT_RANGE_IN_SEARCH_PARAM = 'MARKET_UPDATE_CUT_RANGE_IN_SEARCH_PARAM'
export const MARKET_GEM_SEARCH = 'MARKET_GEM_SEARCH'
export const MARKET_JEWELRY_MAKER_PAGE_LOADED = 'MARKET_JEWELRY_MAKER_PAGE_LOADED'
export const MARKET_JEWELRY_MAKER_ABOUT_CHANGE_SECTION = 'MARKET_JEWELRY_MAKER_ABOUT_CHANGE_SECTION'
export const MARKET_JEWELRY_MAKER_GEMS_CHANGE_SECTION = 'MARKET_JEWELRY_MAKER_GEMS_CHANGE_SECTION'
export const MARKET_GEM_RESERVATION_START_PAGE_LOADED = 'MARKET_GEM_RESERVATION_START_PAGE_LOADED'
export const MARKET_USER_START_RESERVE_PROCESS = 'MARKET_USER_START_RESERVE_PROCESS'
export const MARKET_USER_RESERVE_GEM = 'MARKET_USER_RESERVE_GEM'
export const MARKET_USER_CLOSE_RESERVE_GEM_VIEW = 'MARKET_USER_CLOSE_RESERVE_GEM_VIEW'
export const MARKET_USER_CANCEL_GEM_RESERVATION = 'MARKET_USER_CANCEL_GEM_RESERVATION'
export const MARKET_TOGGLE_GEM_SHAPE = 'MARKET_TOGGLE_GEM_SHAPE'
export const MARKET_UPDATE_CLARITY_RANGE_IN_SEARCH_PARAM = 'MARKET_UPDATE_CLARITY_RANGE_IN_SEARCH_PARAM'
export const MARKET_UPDATE_COLOR_RANGE_IN_SEARCH_PARAM = 'MARKET_UPDATE_COLOR_RANGE_IN_SEARCH_PARAM'
export const MARKET_GEM_LISTINGS_PAGE_LOADED = 'MARKET_GEM_LISTINGS_PAGE_LOADED'
export const MARKET_RESEND_EMAIL_VERIFICATION_EMAIL = 'MARKET_RESEND_EMAIL_VERIFICATION_EMAIL'
export const MARKET_RESET_PASSWORD = 'MARKET_RESET_PASSWORD'
export const MARKET_SHOW_SPECIFIC_SHAPE_DESCRIPTION = 'MARKET_SHOW_SPECIFIC_SHAPE_DESCRIPTION'
export const UPDATE_MARKET_RESET_PASSWORD_FIELD_AUTH = 'UPDATE_MARKET_RESET_PASSWORD_FIELD_AUTH'
export const CLOSE_APP_EVENT = 'CLOSE_APP_EVENT'
export const UPDATE_MARKET_NEW_PASSWORD_FIELD_AUTH = 'UPDATE_MARKET_NEW_PASSWORD_FIELD_AUTH'
export const MARKET_SET_NEW_PASSWORD_UNLOADED = 'MARKET_SET_NEW_PASSWORD_UNLOADED'
export const MARKET_SET_NEW_PASSWORD = 'MARKET_SET_NEW_PASSWORD'
export const GO_TO = 'GO_TO'
export const MARKET_RESET_PASSWORD_UNLOADED = 'MARKET_RESET_PASSWORD_UNLOADED'
export const MARKET_GEM_LISTING_DETAILS = 'MARKET_GEM_LISTING_DETAILS'
export const MARKET_GEM_LISTINGS_PREVIOUS_PAGE = 'MARKET_GEM_LISTINGS_PREVIOUS_PAGE'
export const MARKET_GEM_LISTINGS_NEXT_PAGE = 'MARKET_GEM_LISTINGS_NEXT_PAGE'
export const CHANGE_GEM_LISTING_CSV_UPLOAD_OPTION = 'CHANGE_GEM_LISTING_CSV_UPLOAD_OPTION'

export const MARKET_GEM_REQUEST__ON_LOAD = 'MARKET_GEM_REQUEST__GEM_REQUEST__ON_LOAD'
export const MARKET_GEM_REQUEST__CREATE_GEM_REQUEST_FROM_RELATIVE_GEM_LISTING_URL = 'MARKET_GEM_REQUEST__CREATE_GEM_REQUEST_FROM_RELATIVE_GEM_LISTING_URL'
export const MARKET_GEM_REQUEST__EDIT_RELATIVE_GEM_LISTING_INFO = 'MARKET_GEM_REQUEST__EDIT_RELATIVE_GEM_LISTING_INFO'
export const MARKET_GEM_REQUEST__CHOOSE_GEM_LISTING_INPUT_TYPE = 'MARKET_GEM_REQUEST__CHOOSE_GEM_LISTING_INPUT_TYPE'
export const MARKET_GEM_REQUEST__ARCHIVE_GEM_REQUEST = 'MARKET_GEM_REQUEST__ARCHIVE_GEM_REQUEST'
export const MARKET_GEM_REQUEST__UPDATE_RELATIVE_GEM_LISTING_URL = 'MARKET_GEM_REQUEST__UPDATE_RELATIVE_GEM_LISTING_URL'

export const TRADER__GEM_REQUESTS_MANAGER__ON_LOAD = 'TRADER__GEM_REQUESTS_MANAGER__ON_LOAD'

export const ADMIN__GEM_REQUESTS_MANAGER__ON_LOAD = 'ADMIN__GEM_REQUESTS_MANAGER__ON_LOAD'
// need to get rid of htis.
export const LOGIN = 'LOGIN'



export const MARKET_USER_SIGNUP_PAGE__LOADED = 'MARKET_USER_SIGNUP_PAGE__LOADED'
export const MARKET_USER_SIGNUP_PAGE__UNLOADED = 'MARKET_USER_SIGNUP_PAGE__UNLOADED'

// GEM LISTING MANAGER
export const GEM_LISTING_MANAGER_SEARCH_SUBMIT = 'GEM_LISTING_MANAGER_SEARCH_SUBMIT'
export const GEM_LISTING_MANAGER_SEARCH_CHANGE_ATTR = 'GEM_LISTING_MANAGER_SEARCH_CHANGE_ATTR'
export const GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_STATES = 'GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_STATES'
export const GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_SHAPES = 'GEM_LISTING_MANAGER_TOGGLE_SELECT_ALL_GEM_SHAPES'
export const GEM_LISTING_MANAGER_TOGGLE_STATE = 'GEM_LISTING_MANAGER_TOGGLE_STATE'
export const GEM_LISTING_MANAGER_TOGGLE_SHAPE = 'GEM_LISTING_MANAGER_TOGGLE_SHAPE'
export const GEM_LISTING_MANAGER_ATTR_INITIAL_SEARCH_STATE = 'GEM_LISTING_MANAGER_ATTR_INITIAL_SEARCH_STATE'
export const GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR = 'GEM_LISTING_MANAGER_CHANGE_GLS_SELECTOR'
export const GEM_LISTING_MANAGER__CLEAR_SEARCH_PARAM = 'GEM_LISTING_MANAGER__CLEAR_SEARCH_PARAM'
export const GEM_LISTING_MANAGER__SEARCH_PARAM__FILTER__TOGGLE_SHAPE = 'GEM_LISTING_MANAGER__SEARCH_PARAM__FILTER__TOGGLE_SHAPE'

