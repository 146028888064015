import React from "react";
import {
    Box,
    Grid,
    Input,
    VStack,
    Button,
    FormControl,
    FormLabel,
    GridItem,
    AspectRatio,
    useBreakpointValue,
    AspectRatioBox,
} from "./";

export const GridShowcase = ({ image1, image2, image3, image4 }) => {
    return (
        // .container {
        //     background-color: red;
        //     width: 100%;
        //     padding-top: 100%; /* 1:1 Aspect Ratio */
        //     position: relative; /* If you want text inside of it */
        //   }

        //   /* If you want text inside of the container */
        //   .text {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     bottom: 0;
        //     right: 0;
        //   }
        <AspectRatioBox>
            <Grid
                gap={{ base: "18px", sm: "20px", md: "28px", lg: "32px" }}
                h={"100%"}
                templateAreas={`"a b b"
"c b b "
"d b b "
"d e f"`}
                // gridTemplateColumns={"132px 242px 48px"}
                // gridTemplateRows={"65px 143px 119px 220px"}
                gridTemplateColumns={"27.16% 49.79% 9.88%"}
                gridTemplateRows={"10.64% 23.40% 19.48% 36%"}
                overflow="hidden"
            >
                <GridItem
                    bgImage={image1}
                    area={"b"}
                    backgroundSize="cover"
                ></GridItem>
                <GridItem
                    bgImage={image2}
                    area={"c"}
                    backgroundSize="cover"
                ></GridItem>
                <GridItem
                    bgImage={image3}
                    area={"d"}
                    backgroundSize="cover"
                ></GridItem>
                <GridItem
                    bgImage={image4}
                    area={"e"}
                    backgroundSize="cover"
                ></GridItem>
            </Grid>
        </AspectRatioBox>
    );
};

// 611;
