import PropTypes from "prop-types";
import React from "react";
import {
    FixedText,
    Button,
    Divider,
    Box,
    Center,
    Circle,
    Container,
    Image,
    SimpleGrid,
    Text,
} from "../../stories/Primatives";

/**
 * Primary UI component for user interaction
 */
export const TextWithReadMore = ({ title, ...props }) => {
    let titleColor, titleSize, pSize, width, rowsHeight, boxSize, circleColor;

    titleColor = props.color ? props.color : "black";
    titleSize = 28;
    pSize = 22;
    width = "100%";
    rowsHeight = [10, 50, 100];
    circleColor = "white";

    const Paragraph = (props) => {
        let text = null;

        text = (
            <FixedText
                fontFamily={"Montserrat"}
                fontSize={pSize}
                lineHeight={1.45}
                color={"grey.600"}
                noOfLines={4}
            >
                {props.children}
            </FixedText>
        );

        return (
            <Box minH={props.children ? rowsHeight[2] : 0}>
                {props.children ? text : null}
            </Box>
        );
    };

    return (
        <Container maxW={"2xl"}>
            <SimpleGrid columns={1} spacing={5} w={width}>
                <Box minH={rowsHeight[0]}>
                    <Divider
                        variant={"black"}
                        w={{ base: "100%", lg: "70%" }}
                    />
                </Box>
                <Box minH={rowsHeight[1]}>
                    <Text
                        as={"h2"}
                        fontFamily={"QuicheText"}
                        fontSize={titleSize}
                        fontWeight={400}
                        lineHeight={1.36}
                        letterSpacing={-0.56}
                        color={titleColor}
                        textTransform={"uppercase"}
                    >
                        {title}
                    </Text>
                </Box>
                <Paragraph {...props} />
            </SimpleGrid>
        </Container>
    );
};

TextWithReadMore.propTypes = {
    /**
     * Title
     */
    title: PropTypes.string.isRequired,
};

TextWithReadMore.defaultProps = {
    title: "",
};
