import { Icon } from "@chakra-ui/react";
import React from "react";


export const RoseShapeIcon = ({ color = "#000000" }) => (
    <Icon viewBox="0 0 549.9 542.1" height={'auto'} width={'100%'} fill={color}>
        <style type="text/css">
            .st0 {`{fill:none;stroke:${color};strokeLinecap:round;strokeLinejoin:round;strokeMiterlimit:10;}`}
        </style>
        <g strokeWidth={4}>
            <polygon className="st0"
                     points="45,414.9 194,421.3 360.4,421.3 504.9,414.9 435.4,274.9 359.2,138.3 274.9,2.6 191.7,136.1
		113.6,276.2 	"/>
            <polygon className="st0"
                     points="142.8,40.1 274.9,2.6 410.7,40.1 504.9,138.8 541.4,276.9 504.9,414.9 413.4,502.7 274.9,542
		142.8,507.3 45,414.9 8.4,272.3 45,144.8 	"/>
            <line className="st0"
                  x1="142.8" y1="40.1" x2="413.4" y2="502.7" />
            <line className="st0"
                  x1="410.7" y1="40.1" x2="142.8" y2="507.3" />
            <polygon className="st0"
                     points="504.9,138.8 360.4,421.3 274.9,542 194,421.3 45,144.8 191.7,136.1 359.2,138.3 	" />
            <line className="st0"
                  x1="541.4" y1="276.9" x2="8.4" y2="272.3" />
        </g>
    </Icon>
);
