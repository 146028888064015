import {
    GraphqlQueryBuilder,
    GQLInputArg,
} from "../../../../lib/GraphCallBuilder";

function LoginAsMarketUserGraphQLMutation() {
    return new GraphqlQueryBuilder({
        api: "loginAsMarketUser",
        type: "mutation",
        args: [
            new GQLInputArg("email", "String", true),
            new GQLInputArg("password", "String", true),
        ],
    });
}

export { LoginAsMarketUserGraphQLMutation };
