import gql from "graphql-tag";

const VERIFY_AND_CREATE_ORGANIZATION_FROM_VENDOR_ORGANIZATION_INQUIRY= gql`
  mutation VerifyAndCreateOrganizationFromVendorOrganizationInquiry(
    $vendorOrganizationInquiryId: ID!
  ) {
    verifyAndCreateOrganizationFromVendorOrganizationInquiry(
      vendorOrganizationInquiryId: $vendorOrganizationInquiryId
    ) {
      id
      createdVendorOrganization
      state
    }
  }
`;

export { VERIFY_AND_CREATE_ORGANIZATION_FROM_VENDOR_ORGANIZATION_INQUIRY };
