import { Icon } from "@chakra-ui/react";
import React from "react";

export const TrilliantShapeIcon = ({ color = "#00000" }) => (
	<Icon viewBox="0 0 640.3 526.4" height={'auto'} width={'100%'} fill={color}>
		<style type="text/css">
			{".st0{fill:none;}"}
		</style>
		<g id="Diamonds_Trilliant2" transform="translate(0 3.075)">
			<g id="Trazado_1692" transform="translate(-595 -295)">
				<g>
					<path className="st0" d="M915.1,295L595,817.1h640.3L915.1,295z" />
				</g>
				<g>
					<path d="M915.1,304.6L603.9,812.1h622.4L915.1,304.6 M915.1,295l320.1,522.1H595L915.1,295z" />
				</g>
			</g>
			<g id="Trazado_1693">
				<path d="M154,522.7c-1.4,0-2.5-1.1-2.5-2.5v-86.3l0.3-0.5l70.8-140.9l64.7-182.7L317.7-0.7c0.4-1.3,1.7-2.1,3.1-1.7
			c1.3,0.4,2.1,1.7,1.7,3.1L292,111.4l-64.8,183.1l-0.1,0.1l-70.6,140.5v85.2C156.5,521.6,155.4,522.7,154,522.7z"/>
			</g>
			<g id="Trazado_1694">
				<path d="M108.6,423.6c-0.4,0-0.8-0.1-1.2-0.3c-1-0.6-1.5-1.8-1.2-2.9l38.4-134.9c0.1-0.2,0.2-0.4,0.3-0.6l25.5-41.5
			c0.1-0.1,0.2-0.2,0.2-0.3l113.8-130.3l-29.8-1.8l0.3-5l34.9,2.1c1,0.1,1.8,0.6,2.2,1.5c0.4,0.9,0.2,1.9-0.4,2.6l-117.1,134
			l-25.2,41.1l-35.4,124.6l107.9-116.1l3.7,3.4l-115,123.7C110,423.4,109.3,423.6,108.6,423.6z"/>
			</g>
			<g id="Trazado_1695">
				<path d="M224.9,296c-0.5,0-1-0.1-1.4-0.4c-1.1-0.8-1.4-2.3-0.6-3.5l94.3-135.8l-29.5-44.3c-0.8-1.1-0.5-2.7,0.7-3.5
			s2.7-0.5,3.5,0.7l31.4,47.1l-96.3,138.6C226.4,295.6,225.6,296,224.9,296z"/>
			</g>
			<g id="Línea_11">
				<rect x="-2.5" y="460.6" transform="matrix(0.7508 -0.6605 0.6605 0.7508 -290.6747 155.6808)" width="126.9" height="5" />
			</g>
			<g id="Trazado_1696">
				<path d="M5.7,522.7c-1.1,0-2.1-0.7-2.4-1.8c-0.4-1.3,0.4-2.7,1.7-3.1l148.4-43.3l0.2,0l145.1-22.4L152,436.9l-42.6-11.6
			l-32.8-25.1c-1.1-0.8-1.3-2.4-0.5-3.5c0.8-1.1,2.4-1.3,3.5-0.5l32,24.5l41.3,11.3l184.9,19.1l-183.3,28.3L6.4,522.6
			C6.2,522.7,5.9,522.7,5.7,522.7z"/>
			</g>
			<g id="Línea_12">
				<path d="M318.2,522.7c-0.2,0-0.4,0-0.6-0.1l-163.9-41.9c-1.3-0.3-2.1-1.7-1.8-3c0.3-1.3,1.7-2.1,3-1.8l163.9,41.9
			c1.3,0.3,2.1,1.7,1.8,3C320.4,522,319.3,522.7,318.2,522.7z"/>
			</g>
			<g id="Trazado_1697">
				<polygon points="488.4,520.2 483.4,520.2 483.4,435.1 412.7,294.4 412.7,294.3 347.9,111.3 347.9,111.2 317.4,0.7 322.2,-0.7 
			352.6,109.8 417.4,292.5 488.4,433.9 		"/>
			</g>
			<g id="Trazado_1698">
				<polygon points="529.8,422.8 416.2,301 419.8,297.6 526.4,411.8 490.6,287.2 465.4,246.2 348.4,112.2 349.8,108.1 382.6,102.3 
			383.5,107.3 355.1,112.2 469.3,243 469.6,243.4 495,284.9 495.3,285.5 534.1,420.4 		"/>
			</g>
			<g id="Trazado_1699">
				<path d="M415,296c-0.8,0-1.6-0.4-2.1-1.1l-96.2-138.6l31.4-47.1c0.8-1.1,2.3-1.5,3.5-0.7s1.5,2.3,0.7,3.5l-29.5,44.3L417.1,292
			c0.8,1.1,0.5,2.7-0.6,3.5C416,295.8,415.5,296,415,296z"/>
			</g>
			<g id="Línea_13">
				<rect x="576.8" y="397.1" transform="matrix(0.6606 -0.7507 0.7507 0.6606 -151.0249 592.0386)" width="5" height="131.9" />
			</g>
			<g id="Trazado_1700">
				<path d="M634.6,522.7c-0.2,0-0.5,0-0.7-0.1l-148.5-43.2l-183.3-28.3L487,432l41.3-11.3l32.4-24.5c1.1-0.8,2.7-0.6,3.5,0.5
			c0.8,1.1,0.6,2.7-0.5,3.5l-33.1,25.1L487.7,437l-146.5,15.2l145.2,22.4l0.2,0l148.6,43.3c1.3,0.4,2.1,1.8,1.7,3.1
			C636.6,522,635.6,522.7,634.6,522.7z"/>
			</g>
			<g id="Línea_14">
				<path d="M321.7,522.7c-1.1,0-2.1-0.8-2.4-1.9c-0.3-1.3,0.5-2.7,1.8-3l163.9-41.9c1.3-0.3,2.7,0.5,3,1.8c0.3,1.3-0.5,2.7-1.8,3
			l-163.9,41.9C322.1,522.7,321.9,522.7,321.7,522.7z"/>
			</g>
		</g>
	</Icon>
);
