import React from 'react';
import { Heading as ChakraHeading } from "@chakra-ui/react"


/**
 * Primary UI component for user interaction
 */
export const Heading = ({ children, ...props }) => {

    return (

        <ChakraHeading {...props} >
            {children}
        </ChakraHeading>

    );
}

Heading.propTypes = {

};

Heading.defaultProps = {

};
