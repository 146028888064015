import React from "react";
import { LinkIcon } from "../../components/IconsV2";
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
import {
    Popover,
    PopoverTrigger,
    Box,
    Link,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverBody,
    Flex,
    Modal,
    PopoverFooter,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
} from ".";

export const PopoverOrModal = ({
    body,
    header,
    trigger,
    triggerBody,
    isLazy,
    placement,
    lazyBehavior,
    popOverOnlyFooter,
    paddingBody,
    popoverWidth = "md",
    variant = "modal", // -- Jeff - should change this to mode
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    if (variant === "popover") {
        return (
            <Popover
                trigger={trigger}
                isLazy={isLazy}
                placement={placement}
                lazyBehavior={lazyBehavior}
            >
                <PopoverTrigger>{triggerBody}</PopoverTrigger>
                <PopoverContent w={popoverWidth}>
                    <PopoverArrow />
                    {header && <PopoverHeader>{header}</PopoverHeader>}
                    <PopoverBody padding={paddingBody}>{body}</PopoverBody>
                    {popOverOnlyFooter}
                </PopoverContent>
            </Popover>
        );
    } else if (variant === "modal") {
        return (
            <>
                <Box onClick={onOpen}>{triggerBody}</Box>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{header}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>{body}</ModalBody>

                        <ModalFooter>
                            <Button
                                variant={"market.primary"}
                                colorScheme="blue"
                                mr={3}
                                onClick={onClose}
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    }
};
