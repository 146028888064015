function isVendorLoggedIn() {
  return !!localStorage.getItem("vendorToken");
}

function isMarketUserLoggedIn() {
  return !!localStorage.getItem("marketUserToken");
}

function isAdminLoggedIn() {
  return !!localStorage.getItem("adminToken");
}

export { isVendorLoggedIn, isMarketUserLoggedIn, isAdminLoggedIn };
