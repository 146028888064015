import AuthListErrors from "../../AuthListErrors";
import React, { useState } from "react";

import qs from "qs";
import gql from "graphql-tag";

import { AuthLogin } from "../../UtilityComponents/AuthLogin";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";

const UPDATE_MARKET_USER_PASSWORD_GQL_MUTATION = gql`
    mutation ResetMarketUserPasswordFromResetEmail(
        $resetToken: String!
        $password: String!
    ) {
        updateMarketUserPasswordFromResetEmail(
            resetToken: $resetToken
            password: $password
        ) {
            status
            title
        }
    }
`;

const MarketUserUpdatePassword = (props) => {
    let history = useHistory();
    let queryParams = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
    });
    let resetToken = queryParams.token;

    const [
        updateMarketUserPasswordMutation,
        { data, loading, error },
    ] = useMutation(UPDATE_MARKET_USER_PASSWORD_GQL_MUTATION, {});

    const updateMarketUserPassword = async (variables) =>
        await updateMarketUserPasswordMutation({
            variables: { ...variables, resetToken },
        });
    // let data =
    return (
        <AuthLogin
            variant="two-panel-template"
            mode="update-password"
            heading={"Reset Password"}
            updatePasswordFunc={updateMarketUserPassword}
            postAuthenticatedCallBack={(data) => {
                if (data) {
                    let initialResult = {
                        initialResult:
                            data.updateMarketUserPasswordFromResetEmail,
                    };

                    const queryString = qs.stringify(initialResult);
                    history.push(`/market/login?${queryString}`);
                }
            }}
        />
    );
};

export default MarketUserUpdatePassword;
