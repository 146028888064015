import gql from "graphql-tag";

const LOGIN_AS_ADMIN = gql`
  mutation LoginAsAdmin($email: String!, $password: String!) {
    loginAsAdmin(email: $email, password: $password) {
      token
      userType
      type
    }
  }
`;

export { LOGIN_AS_ADMIN };
