import React, { useCallback } from "react";
import MarketSignUp from "./MarketSignUp";
import MarketLogin from "./MarketLogin";
import SalesOrder from "./SalesOrder";
// import Checkout from "./Checkout";
import GemListingSingleItem from "./GemListings/SingleItem";
import GemListings from "./GemListings";
import CheckoutReferral from "../UtilityComponents/MarketOS/SalesOrder/CheckoutReferral";
import MarketOS from "../UtilityComponents/MarketOS";
import LandingPage from "./LandingPage";
import { Route } from "react-router-dom";

import { useMutation } from "@apollo/react-hooks";
import { GQL_CREATE_OR_UPDATE_TO_CURRENT_SALES_ORDER } from "./graph/mutations/createOrUpdateCurrentSalesOrder";
import { useApolloClient } from "@apollo/client";
import { updateSalesOrder } from "./cache";
import { Footer } from "../../stories/Marketing/Footer";
import { useBreakpointValue } from "@chakra-ui/react";
import { iconography } from "../IconsV2";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Stack,
    useDisclosure,
    Text,
    ModalCloseButton,
    useColorModeValue,
    Container,
    Box,
} from "../../stories/Primatives";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import SalesOrderCompleted from "../UtilityComponents/MarketOS/SalesOrder/SalesOrderCompleted";
import { GemRequestManualCreation } from "./GemRequest/index.js";

const MarketMain = ({ ...props }) => {
    const client = useApolloClient();

    const [createOrUpdateCurrentSalesOrder] = useMutation(
        GQL_CREATE_OR_UPDATE_TO_CURRENT_SALES_ORDER,
        {
            update: updateSalesOrder,
        }
    );

    let removeItemFunc = (id) => {
        createOrUpdateCurrentSalesOrder({
            variables: { thirdPartyItemToRemove: id },
        });
    };

    let { t } = useTranslation();

    let siteMap = [
        <Route
            key={"cart"}
            exact
            path="/market/current-sales-order/completed"
            component={SalesOrderCompleted}
        />,
        <Route
            key={"login"}
            exact
            path="/market/login"
            component={MarketLogin}
        />,
        <Route
            key={"diamonds"}
            exact
            path="/market/diamonds"
            component={GemListings}
        />,
        <Route
            key={"signup"}
            exact
            path="/market/sign-up"
            component={MarketSignUp}
        />,
        <Route key={"cart"} exact path="/market/cart" component={SalesOrder} />,
        <Route
            key={"landingpage"}
            exact
            path="/market/landing-page"
            component={LandingPage}
        />,
        <Route
            key={"diamond"}
            exact
            path="/market/diamonds/:id"
            component={GemListingSingleItem}
        />,
        // <Route
        //     key={"checkout"}
        //     exact
        //     path="/market/checkout"
        //     component={Checkout}
        // />,
    ];
    let navigationMap = {
        FIND_A_DIAMOND: {
            link: "/market/diamonds",
        },
    };

    const cache = useApolloClient();

    let slideInMenuStyling = {
        color: "white",
        bg: "off-black",
    };
    const menuVariant = useBreakpointValue({ base: "slide-in", lg: "full" });
    const { isOpen, onOpen, onClose } = useDisclosure({
        defaultIsOpen: localStorage.getItem("hasSeenDisclaimer1") !== "true",
    });

    const onCloseWrapper = useCallback(() => {
        localStorage.setItem("hasSeenDisclaimer1", true);
        onClose();
    }, [onClose]);

    return (
        <>
            <MarketOS
                iconography={iconography}
                menuVariant={menuVariant}
                slideInMenuStyling={slideInMenuStyling}
                match={props.match}
                siteMap={siteMap}
                navigationMap={navigationMap}
                removeItemFunc={removeItemFunc}
                footer={Footer}
            />
            <Modal isOpen={isOpen} onClose={onCloseWrapper}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack
                            maxW="sm"
                            mx="auto"
                            py={{ base: "12", md: "16" }}
                            spacing={{ base: "6", md: "10" }}
                        >
                            <Stack spacing="3" textAlign="center">
                                <Text
                                    color={"brand.500"}
                                    fontWeight="extrabold"
                                    fontSize={{ base: "xl" }}
                                    textTransform="uppercase"
                                    transform="scale(1.2)"
                                >
                                    A message from Carbonext
                                </Text>
                                <Container>
                                    <Text fontSize="sm">
                                        <Box fontWeight="bold">
                                            {t("marketing:BETA_MSG")}
                                        </Box>{" "}
                                        Sincerely, the Carbonext team.
                                    </Text>
                                </Container>
                            </Stack>
                            <Button
                                variant="market.primary"
                                w={"100%"}
                                onClick={onCloseWrapper}
                            >
                                Close
                            </Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MarketMain;
