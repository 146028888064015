import {
  ADMIN_RELATIVE_GEM_LISTING_SEARCH_JOBS_PAGE_LOADED,
  ADMIN_SEARCH_RELATIVE_GEM_LISTING_SEARCH_JOBS
} from "../constants/actionTypes";

export default (
  state = {
    searchParam: { searchOptions: { offset: 0, limit: 20 } },
    relativeGemListingSearchJobsResp: null
  },
  action
) => {
  switch (action.type) {
    case ADMIN_RELATIVE_GEM_LISTING_SEARCH_JOBS_PAGE_LOADED:
      return {
        ...state,
        relativeGemListingSearchJobsResp: action.payload[0]
      };
    case ADMIN_SEARCH_RELATIVE_GEM_LISTING_SEARCH_JOBS:
      return {
        ...state,
        relativeGemListingSearchJobsResp: action.payload,
        searchParam: action.searchParam
      };

    default:
      return state;
  }
};
