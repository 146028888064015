import * as React from "react";

export default function useSubmitMutation(mutation, postCallBack) {
    let [error, setRespError] = React.useState();
    let [loading, setLoading] = React.useState(false);
    let [data, setData] = React.useState();

    const onSubmit = async (variables) => {
        try {
            await Promise.all([
                setLoading(true),
                setRespError(null),
                setData(null),
            ]);
            let respData = await mutation(variables);
            await Promise.all([setData(respData.data), setLoading(false)]);

            if (postCallBack) {
                postCallBack(respData.data[Object.keys(respData?.data)[0]]);
            }
        } catch (e) {
            console.log("EXCCPTTTIONSSSSSS11111", e);
            setLoading(false);
            setRespError(e);
        }
    };

    return { loading, error, onSubmit, data };
}
