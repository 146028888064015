import React from "react";
import { Range } from "rc-slider";
import { Input, Box, Flex, VStack } from "../Primatives";

import "../css/css.css";
import "../css/rc-slider.css";

const SliderWithManualInputs = ({
    min,
    max,
    step,
    marks,
    allowCross,
    value,
    onChange,
    matchRegex,
    parseAs,
}) => {
    const onChangeNewLowerValue = (event) => {
        if (
            !event.target.value ||
            !matchRegex ||
            event.target.value.match(matchRegex)
        ) {
            let result = [event.target.value, value[1]];
            onChange(result);
        }
    };

    const onChangeNewUpperValue = (event) => {
        if (
            !event.target.value ||
            !matchRegex ||
            event.target.value.match(matchRegex)
        ) {
            let result = [value[0], event.target.value];
            onChange(result);
        }
    };

    const onChangeSliderValue = (newRange) => {
        onChange(newRange);
    };

    return (
        <VStack align="left">
            <Flex justifyContent={"space-between"}>
                <Box>
                    <Input
                        w="5rem"
                        size={"m"}
                        bg="off-white"
                        paddingLeft="0.5rem"
                        value={value && value[0]}
                        onChange={onChangeNewLowerValue}
                    />
                </Box>
                <Box>
                    <Input
                        size={"m"}
                        paddingLeft="0.5rem"
                        bg="off-white"
                        w="5rem"
                        value={value && value[1]}
                        onChange={onChangeNewUpperValue}
                    />
                </Box>
            </Flex>
            <Box>
                <Range
                    min={min}
                    max={max}
                    value={
                        value && [
                            value[0] ? parseAs(value[0]) : 0,
                            value[1] ? parseAs(value[1]) : 0,
                        ]
                    }
                    allowCross={allowCross}
                    marks={marks}
                    step={step}
                    onChange={onChangeSliderValue}
                />
            </Box>
        </VStack>
    );
};
export default SliderWithManualInputs;
