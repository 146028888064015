import gql from 'graphql-tag';

const CREATE_MARKET_USER = gql`
  mutation CreateMarketUser(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $marketUser: MarketUserInput!
  ) {
    createMarketUser(
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
      marketUser: $marketUser
    ) {
      id
      person {
        givenName
        familyName
        additionalName
      }
    }
  }
`;


const LOGIN_AS_MARKET_USER = gql`
  mutation loginAsMarketUser(
    $email: String!
    $password: String!
  ) {
    loginAsMarketUser(
      email: $email
      password: $password
    ) {
      token
      userType
      type
    }
  }
`;

export {
  CREATE_MARKET_USER,
  LOGIN_AS_MARKET_USER
};

