import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import React, { Suspense } from "react";
import { store, history } from "./store";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";

import { marketUserGraphClient } from "./graph";
import App from "./components/App";

import { ApolloProvider } from "@apollo/react-hooks";
import "./i18n.js";

import { Spinner } from "./stories/Primatives";

ReactDOM.render(
    <Suspense fallback={<Spinner />}>
        <ApolloProvider client={marketUserGraphClient}>
            <Provider store={store}>
                <Router history={history}>
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route path="/" component={App} />
                        </Switch>
                    </ConnectedRouter>
                </Router>
            </Provider>
        </ApolloProvider>
    </Suspense>,

    document.getElementById("root")
);
