import React from "react";
import { connect } from "react-redux";
import { MARKET_RESEND_EMAIL_VERIFICATION_EMAIL } from "../constants/actionTypes";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  resendEmailVerificationEmail: idx =>
    dispatch({ type: MARKET_RESEND_EMAIL_VERIFICATION_EMAIL, idx })
});

class AuthListErrors extends React.Component {
  constructor(props) {
    super(props);

    this.resendEmailVerificationEmail = idx => ev => {
      ev.preventDefault();
      this.props.resendEmailVerificationEmail(idx);
    };

    this.createListOfErrors = this.createListOfErrors.bind(this);
  }

  errorsWithActions(err) {
    if (err.actions) {
      return err.actions(err);
    }
  }

  renderError(err) {
    return (
      <div>
        {err.msg} {this.errorsWithActions(err)}
      </div>
    );
  }

  createListOfErrors(errors) {
    let returnHtml = [];
    errors.forEach(err => {
      returnHtml.push(<li key={err.uuid}>{this.renderError(err)}</li>);
    });
    return returnHtml;
  }

  render() {
    return (
      <div className="auth-error-messages">
        <ul>
          {this.props.graphError &&
            this.props.graphError.graphQLErrors.map(({ message }, i) => (
              <li key={i}>{message}</li>
            ))}
        </ul>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthListErrors);
