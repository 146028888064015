import { Icon } from "@chakra-ui/react";
import React from "react";

export const PearShapeIcon = ({ color = "#000000", height = "auto" }) => (
    <Icon viewBox="0 0 439.897 601.896" height={height} width={'100%'} fill={color}>
        <g
            id="Diamonds_Pear_Outlined"
            data-name="Diamonds_Pear Outlined"
            transform="translate(-78.908 -105.071)"
        >
            <g id="Diamonds">
                <g id="Grupo_44" data-name="Grupo 44">
                    <g id="XMLID_1597_">
                        <path
                            id="XMLID_1646_"
                            d="M228.174,636.208l-2.167,1.073,0-.019,2.157-1.071A.118.118,0,0,0,228.174,636.208Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1645_"
                            d="M446.2,165.377l-.016.254c-.065-.06-.131-.119-.2-.179Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1644_"
                            d="M149.947,165.376h0l0,0Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1643_"
                            d="M225.592,266.394l72.335-32.78-72.335,32.78,10.173-70.646,62.451,37.868Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1642_"
                            d="M388.626,443.294l-.17.243h0l-56.913,81.706L298.2,533.507l-33.628-8.265-56.913-81.707h0l-.169-.243L186.018,343.257l39.562-76.863,72.336-32.78-72.336,32.78L298.2,233.615l72.335,32.78L410.1,343.258Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1641_"
                            d="M207.506,443.291l-50.3-43.518,28.824-56.517Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1640_"
                            d="M157.206,282.217l68.386-15.824L186.03,343.256Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1639_"
                            d="M513.742,309.6l.9.329q.3,5.607.3,11.286a322.731,322.731,0,0,1-11.493,83.578l-64.515-5.018Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1638_"
                            d="M503.452,404.792c-8.407,31.368-20.893,61.527-35.625,89.84l-28.891-94.858Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1637_"
                            d="M370.551,266.394l68.386,15.825-28.824,61.038Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1636_"
                            d="M410.113,343.257l28.824-61.038,74.8,27.38-74.8,90.175Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1635_"
                            d="M157.206,399.773,82.4,309.6l74.8-27.38,28.824,61.039Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1634_"
                            d="M202.42,525.241l5.255-81.707,56.913,81.707Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1633_"
                            d="M298.216,601.682l-.143-.071.143-.071Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1632_"
                            d="M360.378,195.748l10.173,70.646-72.335-32.78Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1631_"
                            d="M388.467,443.536l.169-.243,50.3-43.518,28.891,94.858q-2.286,4.392-4.642,8.724l-69.462,21.885Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1630_"
                            d="M514.558,308.615c.026.438.061.874.084,1.313l-.9-.329Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1629_"
                            d="M438.937,399.774l-50.3,43.518,21.476-100.035Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1628_"
                            d="M298.073,601.611l-.132.065v-.13Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1627_"
                            d="M297.941,601.676l-.014.007v-.144l.014.007Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1626_"
                            d="M297.941,601.546l-.014-.007-33.339-76.3,33.628,8.265Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1625_"
                            d="M298.216,533.506l33.338-8.264-33.338,76.3-.143.071-.132-.065Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1624_"
                            d="M393.723,525.242H331.554l56.913-81.706Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1623_"
                            d="M210.7,126.071a219.605,219.605,0,0,1,85.657-17.907l-60.594,87.582Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1622_"
                            d="M298.216,233.614l-62.451-37.868,60.594-87.582c.523,0,1.044-.02,1.568-.02.621,0,1.239.018,1.859.023l60.592,87.58Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1621_"
                            d="M149.951,165.377a217.671,217.671,0,0,1,60.75-39.305l25.063,69.675Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1620_"
                            d="M101.609,230.311a213.927,213.927,0,0,1,48.338-64.931l7.259,116.837Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1619_"
                            d="M225.592,266.393l-68.386,15.824L149.947,165.38l0,0,85.814,30.37Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1618_"
                            d="M81.2,310.035c.031-.583.077-1.163.113-1.745L82.4,309.6Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1617_"
                            d="M82.4,309.6l-1.084-1.306a208.753,208.753,0,0,1,20.292-77.98l55.6,51.907Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1616_"
                            d="M446.18,165.63A213.894,213.894,0,0,1,494.334,230.5l-55.4,51.72Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1615_"
                            d="M513.742,309.6l-74.8-27.38,55.4-51.72a208.77,208.77,0,0,1,20.224,78.116Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1614_"
                            d="M157.206,399.773l-64.857,5.045a323.908,323.908,0,0,1-11.441-83.6q0-5.625.3-11.179l1.2-.438Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1613_"
                            d="M385.4,126.179a217.66,217.66,0,0,1,60.582,39.273l-85.606,30.3Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1612_"
                            d="M370.551,266.394l-10.173-70.646,85.606-30.3.2.179-7.243,116.588Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1611_"
                            d="M299.785,108.168A219.566,219.566,0,0,1,385.4,126.179l-25.025,69.569Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1610_"
                            d="M463.185,503.357a618.275,618.275,0,0,1-33.021,53.771l-36.441-31.886Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1609_"
                            d="M297.927,704.727c-3.065-2.548-33.01-27.729-69.752-68.519l69.752-34.525Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1608_"
                            d="M367.852,636.151c-38.588,43.059-69.636,68.815-69.636,68.815h0V601.682Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1607_"
                            d="M297.941,601.676l-.007,1.841.007-1.841.132-.065.143.071V704.967l-.289-.24V601.683Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1606_"
                            d="M298.216,601.54l69.651,34.6-.015.017-69.636-34.47Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1605_"
                            d="M430.164,557.128a773.337,773.337,0,0,1-59.011,75.321l22.57-107.207Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1604_"
                            d="M298.216,601.54l33.338-76.3h62.169l-22.57,107.207c-1.1,1.245-2.2,2.468-3.287,3.685Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1603_"
                            d="M264.588,525.241l33.338,76.3-69.767,34.652c-1.034-1.147-2.068-2.3-3.112-3.469L202.42,525.241Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1602_"
                            d="M297.927,601.539v.145l-69.752,34.525-.015-.017Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1601_"
                            d="M207.506,443.291l.169.243-5.256,81.707-69.978-22.048q-2.172-4-4.283-8.049l29.047-95.371Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1600_"
                            d="M165.734,557.341a612.852,612.852,0,0,1-33.292-54.148l69.978,22.048Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1599_"
                            d="M225.047,632.722a766.115,766.115,0,0,1-59.313-75.381l36.686-32.1Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_1598_"
                            d="M128.159,495.144c-14.824-28.427-27.379-58.746-35.81-90.327l64.857-5.045Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <path
                        id="Trazado_1665"
                        data-name="Trazado 1665"
                        d="M516.5,308.855c-.023-.439-.058-.875-.085-1.313a208.774,208.774,0,0,0-20.224-78.116,213.907,213.907,0,0,0-48.154-64.869l-.2-.179a218.339,218.339,0,0,0-146.2-57.284c-.62,0-1.237-.023-1.859-.023-.524,0-1.044.016-1.568.02A218.371,218.371,0,0,0,151.811,164.3h0l-1.456,1.351c-20.021,18.335-35.048,38.994-46.882,63.584a208.785,208.785,0,0,0-20.292,77.98c-.036.582-.082,1.161-.113,1.745q-.292,5.555-.3,11.179a323.908,323.908,0,0,0,11.441,83.6c8.431,31.58,20.986,61.9,35.81,90.327q2.112,4.05,4.283,8.049a612.849,612.849,0,0,0,33.292,54.148,765.992,765.992,0,0,0,59.313,75.381c1.044,1.172,2.078,2.321,3.112,3.469l.015.017c36.742,40.79,66.687,65.971,69.752,68.519l.289.24s31.048-25.757,69.636-68.815l.015-.017c1.091-1.217,2.184-2.44,3.287-3.685a773.345,773.345,0,0,0,59.011-75.321,618.175,618.175,0,0,0,33.021-53.771q2.355-4.332,4.642-8.724c14.731-28.313,27.218-58.473,35.625-89.84A322.731,322.731,0,0,0,516.8,320.143Q516.8,314.461,516.5,308.855Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
