import React from "react";
import {
    Box,
    Grid,
    Input,
    VStack,
    Button,
    FormControl,
    FormLabel,
    GridItem,
    AspectRatio,
    useBreakpointValue,
} from "./";

export const AspectRatioBox = ({ children }) => {
    let ratioPercentage = (544 / 486) * 100;
    return (
        <Box w={`${ratioPercentage}`} paddingTop={"100%"} position={"relative"}>
            <Box position={"absolute"} top={0} left={0} bottom={0} right={0}>
                {children}
            </Box>
        </Box>
    );
};
