import React from 'react';
import {
  mainTemplate1
} from '../../../Templates'



class AdminHome extends React.Component {

  render() {

    let sections = {
      header: 'Home',
      body: ""
    };

    return mainTemplate1(sections);

  
  }

  
};

export default AdminHome