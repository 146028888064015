import agent from "./agent";
import {
  ASYNC_START,
  ASYNC_END,
  VENDOR_LOGIN,
  LOGOUT,
  VENDOR_SIGNUP,
  ADMIN_LOGIN,
  MARKET_LOGOUT,
  ADMIN_LOGOUT,
  VENDOR_LOGOUT,
  MARKET_LOGIN,
  MARKET_SIGNUP,
  UNAUTHORIZED_ACCESS
} from "./constants/actionTypes";

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({
      type: ASYNC_START,
      identifier: action.identifier,
      subtype: action.type
    });

    console.log(action);

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      res => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        console.log("RESULT", res);
        action.payload = res;
        store.dispatch({
          type: ASYNC_END,
          identifier: action.identifier,
          subtype: action.type,
          promise: action.payload,
          path: currentState.router.location.pathname
        });
        store.dispatch(action);

        if (action.thenPayload) {
          let thenAction = action.thenPayload(res);
          console.log(res);

          console.log("GONNA TRY THE THEN");

          store.dispatch({ type: ASYNC_START, subtype: thenAction.type });

          const currentView = store.getState().viewChangeCounter;
          const skipTracking = action.skipTracking;

          thenAction.payload.then(
            res => {
              const currentState = store.getState();
              if (
                !skipTracking &&
                currentState.viewChangeCounter !== currentView
              ) {
                return;
              }
              console.log("RESULT", res);
              thenAction.payload = res;
              store.dispatch({
                type: ASYNC_END,
                subtype: thenAction.type,
                promise: thenAction.payload,
                path: currentState.router.location.pathname
              });
              store.dispatch(thenAction);
            },
            error => {
              const currentState = store.getState();
              if (
                !skipTracking &&
                currentState.viewChangeCounter !== currentView
              ) {
                return;
              }
              console.log("ERROR", error);
              thenAction.error = true;
              console.log(thenAction);
              thenAction.thenPayload = error.response.body;
              if (!action.skipTracking) {
                store.dispatch({
                  type: ASYNC_END,
                  promise: thenAction.payload,
                  path: currentState.router.location.pathname
                });
              }
              store.dispatch(thenAction);
            }
          );
        }
      },
      error => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        console.log("ERROR", currentState);
        action.error = true;

        console.log(error.response.statusCode);
        action.payload = error.response.body;
        if (!action.skipTracking) {
          store.dispatch({
            type: ASYNC_END,
            promise: action.payload,
            path: currentState.router.location.pathname
          });
        }
        if (error.response.statusCode === 401) {
          store.dispatch({
            type: UNAUTHORIZED_ACCESS,
            promise: action.payload,
            path: currentState.router.location.pathname
          });
        }
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = store => next => action => {
  if (action.type === ADMIN_LOGIN) {
    if (!action.error) {
      window.localStorage.setItem("jwt", action.payload.admin.token);
      window.localStorage.setItem("jwtTokenType", "admin");
      window.localStorage.setItem("adminJwt", action.payload.admin.token);
      window.localStorage.setItem("adminJwtTokenType", "admin");
      agent.setToken(action.payload.admin.token, "admin");
    }
  } else if (action.type === MARKET_LOGIN || action.type === MARKET_SIGNUP) {
    if (!action.error) {
      window.localStorage.setItem("jwt", action.payload.user.token);
      window.localStorage.setItem("jwtTokenType", "market");
      agent.setToken(action.payload.user.token, "market");
    }
  } else if (action.type === VENDOR_SIGNUP || action.type === VENDOR_LOGIN) {
    if (!action.error) {
      window.localStorage.setItem("jwt", action.payload.vendor.token);
      window.localStorage.setItem("jwtTokenType", "vendor");
      agent.setToken(action.payload.vendor.token, "vendor");
    }
  } else if (
    action.type === LOGOUT ||
    action.type === MARKET_LOGOUT ||
    action.type === ADMIN_LOGOUT ||
    action.type === VENDOR_LOGOUT
  ) {
    window.localStorage.setItem("jwt", "");
    window.localStorage.setItem("jwtTokenType", "");
    agent.setToken(null);
  }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then === "function";
}

export { promiseMiddleware, localStorageMiddleware };
