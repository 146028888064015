import React from "react";
import RelativePriceSearch from "./RelativePriceSearch";


import { Header } from "../../../../../stories/CloudManagementOS/Header"
import { HeaderBodyShell } from "../../../../../stories/CloudManagementOS/shells"
import { getConstToLanguage } from "../../../../../stories/CloudManagementOS/ConstToLanguageMapping"

export const TraderTools = (props) => {


  return <HeaderBodyShell
    header={<Header linkTo={'#'} title={`${getConstToLanguage("RELATIVE_GEM_LISTING_SEARCH_TOOL")}`}
    />}
    body={<RelativePriceSearch />}
  />

}


