import React, { useContext } from "react";
import { Link as ReachLink } from "react-router-dom";
import {
    Box,
    Center,
    Divider,
    Flex,
    IconButton,
    Heading,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    useStyleConfig,
    VStack,
} from "../Primatives";
import { getConstToLanguage } from "./ConstToLanguageMapping";
import { CloudManagementOSVariant, BaseRoutePrefix } from "./Context";
import { useAuth } from "./hooks/useCurrentUser";
import { Notifications } from "./Notifications";
// TODO - jeff should be using our own icon but need to make our icons works better.
// import { SettingsIcon } from "../../components/IconsV2";
import { QuestionOutlineIcon, SettingsIcon } from "@chakra-ui/icons";
/**
 * Primary UI component for user interaction
 *
 *
 */

const GoToHelpButton = ({}) => {
    let baseRoutePrefix = useContext(BaseRoutePrefix);
    return (
        <IconButton
            _hover={{ bg: "white", color: "brand.500" }}
            bg={{ sm: "off-black", md: "white" }}
            _active={{ bg: "white" }}
        >
            <Link to={`${baseRoutePrefix}/help`} as={ReachLink}>
                <QuestionOutlineIcon color={"grey.400"} w={4} h={4} />
            </Link>
        </IconButton>
    );
};

const GoToSettingsButton = ({}) => {
    let baseRoutePrefix = useContext(BaseRoutePrefix);

    console.log("ROUTEEEE PREFIXXX", baseRoutePrefix);
    return (
        <IconButton
            _hover={{ bg: "white", color: "brand.500" }}
            bg={{ sm: "off-black", md: "white" }}
            _active={{ bg: "white" }}
        >
            <Link to={`${baseRoutePrefix}/settings`} as={ReachLink}>
                <SettingsIcon w={4} h={4} _hover={{ color: "brand.500" }} />
            </Link>
        </IconButton>
    );
};

export const Header = React.memo(
    ({ variant, title, bg, color, linkTo, ...props }) => {
        let cloudManagementOSVariant = useContext(CloudManagementOSVariant);

        let currentUser = useAuth();

        const styles = useStyleConfig("Header", {
            variant: cloudManagementOSVariant,
        });

        const CurrentUserInfo = () => {
            const OtherAccounts = () => {
                return (
                    <Popover trigger="hover" placement="left">
                        <PopoverTrigger>
                            <Box w="100%">
                                {
                                    currentUser?.currentUser?.vendorOrganization
                                        .legalName
                                }
                            </Box>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverHeader>
                                {getConstToLanguage("ALL_ACCOUNTS")}
                            </PopoverHeader>
                            <PopoverBody>
                                <VStack>
                                    {currentUser?.currentUser?.otherVendorOrganizationsAuth.map(
                                        (otherVendorOrganizationAuth, idx) => (
                                            <Box
                                                key={idx}
                                                w="100%"
                                                onClick={() =>
                                                    currentUser?.switchAccount(
                                                        idx
                                                    )
                                                }
                                            >
                                                {
                                                    otherVendorOrganizationAuth.vendorOrganizationLegalName
                                                }
                                            </Box>
                                        )
                                    )}
                                </VStack>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                );
            };

            return (
                <Popover
                    h="100%"
                    color="white"
                    placement="bottom-end"
                    trigger="hover"
                    variant="sidenav"
                    isLazy
                >
                    <PopoverTrigger>
                        <Center h="100%" padding="0 1rem">
                            {currentUser &&
                                currentUser?.currentUser?.getFullname()}
                        </Center>
                    </PopoverTrigger>

                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <VStack>
                                {/* {currentUser && currentUser} */}
                                {currentUser?.currentUser
                                    ?.otherVendorOrganizationsAuth && (
                                    <OtherAccounts />
                                )}
                                <Box onClick={currentUser.logout} w="100%">
                                    {getConstToLanguage("LOGOUT")}
                                </Box>
                            </VStack>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            );
        };

        return (
            <Flex
                w="100%"
                h="100%"
                alignItems="center"
                color={color}
                sx={styles}
            >
                {/* TODO - not sure if this is the right way to do it */}
                <Heading size={7} sx={styles.heading}>
                    <Link as={ReachLink} to={linkTo}>
                        {title}
                    </Link>
                </Heading>
                <Spacer />
                <Notifications size="s" />
                <GoToSettingsButton />
                <GoToHelpButton />
                <Divider orientation="vertical" marginLeft="1.5rem" />
                <CurrentUserInfo />
            </Flex>
        );
    }
);

Header.propTypes = {};

Header.defaultProps = {};
