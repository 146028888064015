import superagentPromise from "superagent-promise";
import _superagent from "superagent";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_CARBONEXT_API_BASE_URL;
const DIAMOND_DATA_API_ROOT_URL = "https://data.carbonext.io";

const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
let tokenType = null;
const tokenPlugin = req => {
  if (token) {
    req.set("Authorization", `Token ${token}`);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  longPost: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .timeout(120000)
      .then(responseBody),
  postWithoutToken: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).then(responseBody)
};

const diamondDataRequests = {
  del: url =>
    superagent
      .del(`${DIAMOND_DATA_API_ROOT_URL}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${DIAMOND_DATA_API_ROOT_URL}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${DIAMOND_DATA_API_ROOT_URL}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${DIAMOND_DATA_API_ROOT_URL}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  postWithoutToken: (url, body) =>
    superagent
      .post(`${DIAMOND_DATA_API_ROOT_URL}${url}`, body)
      .then(responseBody)
};

const Trader = {
  getGems: tax_id =>
    requests.post("/traders/gem-listings", { trader: { tax_id } }),
  uploadGemCSV: formData =>
    requests.longPost("/traders/gem-listings/csv/upload", formData),
  putGemOnMarket: gemListing =>
    requests.post(`/traders/gem-listings/${gemListing._id}/market/on`),
  takeGemOffMarket: gemListing =>
    requests.post(`/traders/gem-listings/${gemListing._id}/market/off`),
  requestVerification: gemListing =>
    requests.post(
      `/traders/gem-listings/${gemListing._id}/verification/request`
    ),
  changePrice: (gemListing, price) =>
    requests.put(`/traders/gem-listings/${gemListing._id}/price/change`, {
      price: price
    }),
  changeAcqPrice: (gemListing, acqPrice) =>
    requests.put(`/traders/gem-listings/${gemListing._id}/acq-price/change`, {
      acqPrice: acqPrice
    }),
  getReservedGems: () => requests.get(`/traders/gem-listings/reserved`),
  archiveGemListing: gemListing =>
    requests.put(`/traders/gem-listings/${gemListing._id}/archive`),
  createGemListing: gemListing =>
    requests.post("/v1/vendors/trader/gem-listings", { gemListing })
};

const Market = {
  login: (email, password) =>
    requests.post("/market/login", { user: { email, password } }),
  searchGems: searchParam =>
    requests.post(`/market/gem-listings/search`, searchParam),
  getJewelryMakerWebsite: jewelryMakerId =>
    requests.get(`/market/jewelry-maker/${jewelryMakerId}/website`),
  reserveGem: gemListing =>
    requests.post(`/market/gem-listings/${gemListing._id}/reserve`),
  cancelGemListingReservation: gemListingReservation =>
    requests.del(
      `/market/gem-listing-reservations/${gemListingReservation._id}/cancel`
    ),
  getGemListingDetails: id => requests.get(`/market/gem-listings/${id}`)
};

const Vendor = {
  signup: user => requests.post(`/users/${user.id}/vendor/apply`),
  login: (email, password) =>
    requests.postWithoutToken("/vendors/login", { user: { email, password } }),
  me: () => requests.get("/vendors/me"),
  searchGems: searchParam =>
    requests.post(`/vendors/trader/gem-listings/search`, { searchParam }),
  getJewelryMakerAbout: () => requests.get(`/jewelry-maker/website/about`),
  saveJewelryMakerAbout: about =>
    requests.put(`/jewelry-maker/website/about/update`, { about: about }),
  applyAsTrader: (vendor, taxId) =>
    requests.post(`/vendors/${vendor._id}/trader/apply`, { trader: { taxId } }),
  applyAsJewelryMaker: (vendor, taxId) =>
    requests.post(`/vendors/${vendor._id}/jewelry-maker/apply`, {
      jewelryMaker: { taxId }
    }),
  meInfo: () => requests.get(`/vendors/me/info`),
  updateMeInfo: updatedInfo =>
    requests.put(`/vendors/me/info`, { info: updatedInfo })
};

const Admin = {
  login: (email, password) =>
    requests.postWithoutToken("/v1/admin/login", {
      admin: { email, password }
    }),
  search: page => requests.post(`/admin/search`),
  create: email => requests.post(`/admin/create`, { email: email }),
  activateAmin: admin => requests.post(`/admin/${admin._id}/make-active`),
  deactivateAmin: admin => requests.post(`/admin/${admin._id}/make-inactive`),
  searchGems: searchParam =>
    requests.post(`/admin/gem-listings/search`, searchParam),
  searchGemListingEvents: (gemListing, searchParam) =>
    requests.post(
      `/admin/gem-listings/${gemListing._id}/events/search`,
      searchParam
    ),
  searchUsers: searchParam => requests.post(`/admin/users/search`, searchParam),
  deleteGemListing: gemListing =>
    requests.del(`/admin/gem-listings/${gemListing._id}/delete`),
  getReservedGemListings: () => requests.get(`/admin/gem-listings/reserved`),
  getGemListingDetails: id => requests.get(`/admin/gem-listings/${id}`),
  verifyGemListing: gemListing =>
    requests.put(`/admin/gem-listings/${gemListing._id}/verify`),
  unverifyGemListing: gemListing =>
    requests.put(`/admin/gem-listings/${gemListing._id}/unverify`),
  markGemListingAsSold: gemListing =>
    requests.post(`/admin/gem-listings/${gemListing._id}/sold`),
  searchGemListingsByCategory: (urlCategory, searchParam) =>
    requests.post(`/admin/gem-listings/categories/${urlCategory}`, searchParam),
  repullRelativeGemListingDataForListingsInSalesPipeline: () =>
    requests.post("/admin/gem-listings/relative-pricing/update")
};

const Users = {
  current: () => requests.get("/user"),
  login: (email, password) =>
    requests.post("/users/login", { user: { email, password } }),
  signup: (
    givenName,
    additionalName,
    familyName,
    email,
    password,
    passwordConfirmation
  ) =>
    requests.post("/users", {
      user: {
        givenName,
        additionalName,
        familyName,
        email,
        password,
        passwordConfirmation
      }
    }),
  save: user => requests.put("/users", { user }),
  search: page => requests.post(`/users/search`),
  delete: user => requests.del(`/users/${user._id}`),
  gemListingReservations: () =>
    requests.get(`/users/gem-listings/reservations`),
  resendVerificationEmail: email =>
    requests.post(`/users/resend-verification-email`, { email: email }),
  resetMarketPassword: email =>
    requests.post(`/users/reset-password`, { email: email }),
  setNewMarketPassword: (email, token, password, passwordConfirmation) =>
    requests.post(`/users/set-new-password`, {
      email,
      token,
      password,
      passwordConfirmation
    })
};

const DiamondData = {
  getRelativeDiamondPrices: diamondsInfo =>
    diamondDataRequests.post(
      "/v1/gems/diamonds/get_relative_pricings",
      diamondsInfo
    ),
  getRelativeDiamondPrice: gemSpec =>
    diamondDataRequests.post("/v1/gems/diamond", gemSpec),
  extractPriceAndStatsFromBlueNileUrl: url =>
    diamondDataRequests.post("/v1/pricing/bluenile/url-relative-price", { url })
};

const RelativePricing = {
  getRelativeDiamondPricingHistory: diamondSpecs =>
    requests.post("/v1/relative-pricing/diamonds/history", diamondSpecs),
  getRelativeGemListingSearchJobs: searchParam =>
    diamondDataRequests.post(
      "/v1/pricing/bluenile/url-relative-price",
      searchParam
    )
};

const Media = {
  upload: formData => requests.post("/media/upload", formData)
};

const GemRequests = {
  me: () => requests.post("/v1/gem-requests/me"),
  createFromRelativeGemListingUrl: url =>
    requests.post("/v1/gem-requests/create", { url }),
  archive: gemRequest =>
    requests.post(`/v1/gem-requests/${gemRequest._id}/archive`),
  search: searchParam => requests.post("/v1/gem-requests/search", searchParam),
  submitFufillment: fulfillment =>
    requests.post("/v1/gem-requests/submit-fufillment")
};

function setToken(_token, _tokenType) {
  token = _token;
  tokenType = _tokenType;
}

export default {
  Users,
  Market,
  Vendor,
  Trader,
  Admin,
  Media,
  RelativePricing,
  DiamondData,
  GemRequests,
  setToken: setToken
};
