import gql from "graphql-tag";

const GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_VENDOR_ORGANIZATION = gql`
  query GetGlobalStatsFromSearchOfVendorOrganizations {
    getGlobalStatsFromSearchOfVendorOrganizations {
      counts {
        byState
      }
    }
  }
`;

export { GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_VENDOR_ORGANIZATION };
