import * as React from "react";
import { Link as ReactLink } from "react-router-dom";
import {
  Box,
  ConditionalLink,
  Flex,
  Stack,
  Text,
  useColorModeValue as mode,
} from "../Primatives";

export interface StatCardProps {
  data: {
    label: string;
    currency: string;
    value: number;
    change: number;
    count: number;
    link?: string;
  };
}

function format(value: number, currency: string) {
  return new Intl.NumberFormat("en-US", { style: "decimal", currency }).format(
    value
  );
}

export function StatCard(props: StatCardProps) {
  const { label, currency, value, count, change, link = "" } = props.data;
  //const isNegative = change < 0;

  return (
    <ConditionalLink
      to={link}
      as={ReactLink}
      condition={!!link}
      linkType={"LinkBox"}
    >
      <Flex
        direction="column"
        align="center"
        p="6"
        bg={mode("white", "gray.700")}
        rounded="8px"
        shadow="base"
        color={mode("gray.500", "gray.400")}
        textAlign="center"
        justifyContent="center"
      >
        <Box>
          <Text
            fontSize="xs"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="wide"
          >
            {label}
          </Text>
          <Stack
            direction="row"
            align="flex-start"
            my="3"
            justifyContent="center"
          >
            <Text fontWeight="bold" fontSize="2xl">
              {currency}
            </Text>
            <Text
              as="span"
              color={mode("gray.800", "white")}
              fontSize="4xl"
              fontWeight="bold"
              lineHeight="1"
            >
              {format(value || 0, "USD")}
            </Text>
          </Stack>

          <Box>count: {count || 0}</Box>

          {/* <Indicator type={isNegative ? "down" : "up"} value={`${change}%`} /> */}
        </Box>
      </Flex>
    </ConditionalLink>
  );
}
