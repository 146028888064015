import { Box, Image, AspectRatio } from "@chakra-ui/react";
import React from "react";
import {
    getShapeCategoryIcon,
    Video360Icon,
} from "../../../components/IconsV2";
import { getConstToLanguage } from "../../CloudManagementOS/ConstToLanguageMapping";
import { ListingItem } from "./ListingItem";

export const GemListingItem = ({
    currentMarketUser,
    node,
    currentReservedData,
    ...props
}) => {
    const reservedIDs = currentReservedData;

    const genTag = (node, currentlyReserved) => {
        if (
            currentMarketUser?.currentMarketUser?.currentSalesOrder?.items.some(
                (reservedItem) => reservedItem.id === node.id
            )
        ) {
            return {
                name: "RESERVED_BY_YOU",
                level: "reseved-by-you-tag",
            };
        } else if (currentlyReserved && currentlyReserved.includes(node.id)) {
            return {
                name: "CURRENTLY_RESERVED",
                level: "currently-reserved-tag",
            };
        } else if (node.currentRelativeDiscountPercentage > 40) {
            return {
                name: "SPECIAL_OFFER",
                level: "special-offer-tag",
            };
        }
    };

    let itemShowCase = node.mainImage ? (
        <Box>
            <Image src={node.mainImage} height={"100%"} />
        </Box>
    ) : (
        <AspectRatio ratio={1} w={"100%"}>
            {getShapeCategoryIcon(node.certifiedGem.specs.shape)({
                height: "100%",
            })}
        </AspectRatio>
    );

    const discount =
        node.currentRelativeDiscountPercentage &&
        node.currentRelativeDiscountPercentage.toFixed(0);

    const relativePrice =
        node?.latestRelativePrice?.relativePrice &&
        node.latestRelativePrice.relativePrice.toFixed(2);

    const title = (
        <Box>
            {node.certifiedGem.specs.carat} {getConstToLanguage("CARAT")}{" "}
            {getConstToLanguage(node.certifiedGem.specs.color)}{" "}
            {getConstToLanguage(node.certifiedGem.specs.clarity)}{" "}
            {getConstToLanguage(node.certifiedGem.specs.cut)}{" "}
            {getConstToLanguage("CUT")}{" "}
            {getConstToLanguage(node.certifiedGem.specs.shape)}{" "}
            {getConstToLanguage("SHAPE")} {getConstToLanguage("Diamond")}
        </Box>
    );

    return (
        <ListingItem
            id={node.id}
            linkPrefix={"diamonds"}
            tag={genTag(node, reservedIDs)}
            itemShowCase={itemShowCase}
            discount={discount}
            price={node.price}
            relativePrice={relativePrice}
            title={title}
            // not sure if there is a better name for this
            emphasis={
                node?.videoLink ? <Video360Icon color="brand.500" /> : null
            }
        ></ListingItem>
    );
};

GemListingItem.propTypes = {};

GemListingItem.defaultProps = {};
