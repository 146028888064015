import * as React from "react";
import {
    VStack,
    Box,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "../../stories/Primatives";

export const GoodResponseBoxAtom = ({ title, description }) => {
    return (
        <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
        >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
                {title}
            </AlertTitle>
            <AlertDescription maxWidth="sm">{description}</AlertDescription>
        </Alert>
    );
};

export const InlineGoodResponseAtom = ({ title, description }) => {
    return (
        <VStack>
            <Alert status="success">
                <AlertIcon />
                <AlertTitle>{title}</AlertTitle>
                <AlertDescription>{description}</AlertDescription>
            </Alert>
        </VStack>
    );
};

export const GoodResponseAtom = ({ resultVariant, title, description }) => {
    // TODO - make this match statemeent
    if (resultVariant === "box") {
        return <GoodResponseBoxAtom title={title} description={description} />;
    }
    return <InlineGoodResponseAtom title={title} description={description} />;
};
