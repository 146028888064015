import { Icon } from "@chakra-ui/react";
import React from "react";

export const MarquiseShapeIcon = ({ color = "#000000", height = 'auto' }) => (
    <Icon viewBox="0 0 427.25 631.01" fill={color} height={height} width={'100%'}>
        <g
            id="Diamonds_Marquise_Outlined"
            data-name="Diamonds_Marquise Outlined"
            transform="translate(-75.573 -97.487)"
        >
            <g id="Diamonds">
                <g id="Grupo_42" data-name="Grupo 42">
                    <g id="XMLID_750_">
                        <path
                            id="XMLID_930_"
                            d="M77.707,420.278l.013-.017c0,.01,0,.021,0,.031Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_929_"
                            d="M288.4,216.309,211.3,280.8l17.21-53.3Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_928_"
                            d="M288.4,216.309h0L228.513,227.5l.759-2.353L288.4,100.024l59.132,125.127.875,2.714Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_927_"
                            d="M288.4,216.309Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_823_"
                            d="M365.506,280.8l71.558,59.853-27.582,76.33Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_822_"
                            d="M409.482,416.983l27.582-76.33L499.1,420.278,433.2,490.418Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_821_"
                            d="M360.665,552.082,288.4,613.517l-72.261-61.435-48.817-135.1L211.3,280.8l77.1-64.491,77.1,64.491,43.976,136.183Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_820_"
                            d="M499.1,420.278l1.03,1.322c-1.432,33.723-10.735,66.1-24.7,96.342l-42.237-27.525Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_819_"
                            d="M288.4,613.518l-53.543-9.671-.063-.139-18.655-51.626L288.4,613.517Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_818_"
                            d="M288.4,216.309l60.007,11.556L365.506,280.8Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_817_"
                            d="M288.4,613.518h0l53.678-10.011-.073.2L288.4,722.943l-53.543-119.1,53.543,9.671Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_816_"
                            d="M143.613,490.418l23.714-73.435,48.817,135.1Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_815_"
                            d="M139.745,340.653,211.3,280.8,167.327,416.983Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_814_"
                            d="M433.2,490.418l42.237,27.525c-11.229,24.314-25.461,47.239-41.057,68.351l-.175.033Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_813_"
                            d="M234.739,603.825l.06-.117.063.139Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_812_"
                            d="M288.4,613.518h0Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_811_"
                            d="M409.482,416.983,433.2,490.418l-72.53,61.664Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_810_"
                            d="M288.4,613.517l72.26-61.435-18.582,51.425L288.4,613.518Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_809_"
                            d="M433.2,490.418l1.005,95.909-92.117,17.18,18.582-51.425Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_808_"
                            d="M229.272,225.151l-30.315-50.194C242.8,131.2,282.962,103.538,288.344,99.9l.06.126Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_807_"
                            d="M347.537,225.151l30.729-50.88a558.979,558.979,0,0,1,60.88,71.065l-90.733-17.473Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_771_"
                            d="M288.345,99.9l.174-.117-.115.243Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_770_"
                            d="M288.4,100.024l.115-.243.438-.294s42.454,28.16,89.309,74.785l-30.729,50.88Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_769_"
                            d="M434.375,586.294l-.173.237,0-.2Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_768_"
                            d="M97.7,317.978c10.3-24.721,23.865-48.11,39.029-69.753l3.014,92.428Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_767_"
                            d="M228.513,227.5l-88.884,16.613a559.558,559.558,0,0,1,59.329-69.16l30.315,50.194Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_766_"
                            d="M499.1,420.278l1.115-1.187c-.026.837-.049,1.674-.085,2.509Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_765_"
                            d="M437.064,340.653l42.942-23.16c12.552,29.973,20.336,61.9,20.336,95.033,0,2.2-.057,4.381-.125,6.566l-1.115,1.187Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_764_"
                            d="M365.506,280.8l-17.093-52.935,90.733,17.473c.329.461.653.926.981,1.39l-3.063,93.926Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_762_"
                            d="M440.127,246.727c15.489,21.919,29.361,45.653,39.879,70.766l-42.942,23.16Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_761_"
                            d="M211.3,280.8l-71.557,59.853-3.014-92.428c.962-1.374,1.923-2.749,2.9-4.108L228.513,227.5Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_760_"
                            d="M289.438,725.242l-.481.323s-.459-.3-1.328-.9l.775-1.724Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_759_"
                            d="M207.128,658.111a580.332,580.332,0,0,1-62.7-70.6l90.307,16.31Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_758_"
                            d="M234.862,603.847l53.543,119.1-.775,1.724c-7.068-4.827-41.5-28.946-80.5-66.556l27.611-54.286Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_757_"
                            d="M342.01,603.708l28.036,55.122c-40.711,39.135-76.236,63.464-80.608,66.412l-1.034-2.3Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_756_"
                            d="M342.083,603.507l92.117-17.18,0,.2c-20.332,27.49-42.942,51.905-64.156,72.3l-28.036-55.122Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_755_"
                            d="M216.144,552.082,234.8,603.708l-.06.117-90.307-16.31c-.608-.818-1.205-1.652-1.81-2.475l.991-94.622Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_754_"
                            d="M167.327,416.983l-23.713,73.434L77.721,420.292c0-.01,0-.021,0-.031l62.025-79.609Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_753_"
                            d="M77.72,420.262c-.093-2.571-.147-5.149-.147-7.736,0-32.956,7.7-64.715,20.129-94.548l42.043,22.675Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_752_"
                            d="M102.229,517.387c-14-30.471-23.272-63.107-24.508-97.1l65.893,70.125Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                        <path
                            id="XMLID_751_"
                            d="M142.622,585.04c-15.353-20.927-29.346-43.612-40.393-67.653l41.384-26.97Z"
                            fill="none"
                            stroke={color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="4"
                        />
                    </g>
                    <path
                        id="Trazado_1663"
                        data-name="Trazado 1663"
                        d="M480.486,318.424c-10.518-25.114-24.39-48.847-39.879-70.766-.328-.463-.652-.928-.981-1.39a558.91,558.91,0,0,0-60.88-71.065c-46.854-46.625-89.308-74.785-89.308-74.785l-.438.294-.174.117c-5.383,3.641-45.543,31.3-89.387,75.059a559.641,559.641,0,0,0-59.329,69.16c-.974,1.359-1.935,2.734-2.9,4.108-15.164,21.643-28.726,45.032-39.029,69.753C85.751,348.742,78.055,380.5,78.055,413.457q0,3.879.147,7.736l-.013.017.014.014c1.237,33.988,10.506,66.624,24.508,97.095,11.047,24.041,25.04,46.726,40.393,67.653.6.824,1.2,1.657,1.81,2.475a580.417,580.417,0,0,0,62.7,70.6c39.005,37.61,73.433,61.729,80.5,66.556.869.593,1.328.9,1.328.9l.481-.323c4.373-2.948,39.9-27.277,80.608-66.412,21.214-20.394,43.824-44.81,64.156-72.3.058-.078.115-.158.173-.237,15.6-21.112,29.828-44.037,41.057-68.351,13.965-30.238,23.267-62.619,24.7-96.342.036-.835.059-1.672.085-2.509.067-2.184.125-4.37.125-6.565C500.822,380.322,493.039,348.4,480.486,318.424Z"
                        fill="none"
                        stroke={color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                </g>
            </g>
        </g>
    </Icon>
);
