import React from "react";
import gql from "graphql-tag";
import SalesOrderManager from "./SalesOrderManager";

const SEARCH_CURRENT_SALES_ORDERS = gql`
  query SearchCurrentSalesOrders {
    searchCurrentSalesOrders {
      id
      createdAt
      items {
        price
        certifiedGem {
          certification {
            certificationId
          }
          specs {
            carat
            clarity
            cut
            shape
            color
          }
        }
      }
      marketUser {
        localAuth {
          email
        }
        person {
          givenName
          familyName
        }
      }
    }
  }
`;

const GemRequestsManagement = props => {
  return (
    <SalesOrderManager
      searchQuery={SEARCH_CURRENT_SALES_ORDERS}
      match={props.match}
    />
  );
};

export default GemRequestsManagement;
