import React from 'react';
import { Button, Loader } from ".";


/**
 * Primary UI component for user interaction
 */
export const LoadingButton = ({ isLoading, children, ...props }) => {

	return (

		<Button {...props} disabled={isLoading}>
			{isLoading ? <Loader /> : children}
		</Button>

	);
}

