import React from "react";

import { Link } from "./"

export const ConditionalLink = ({ children, to, condition, as, linkType="" }) => {
    switch (linkType){
        case "LinkBox":
            return !!condition && to ? <Link _hover={{ textDecoration: 'none'}} to={to} as={as}>{children}</Link> : children;
        default:
            return !!condition && to ? <Link to={to} as={as}>{children}</Link> : children;
    }
}

