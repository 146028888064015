import { Query } from "@apollo/react-components";
import { useQuery } from "@apollo/react-hooks";
import { Flex } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LIST_OF_SHAPES_IN_ORDER } from "../../../constants/businessConstants";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { urlConstToConst } from "../../../stories/CloudManagementOS/ConstToUrl";
import { Header } from "../../../stories/CloudManagementOS/Header";
import {
    useUserEvents,
    useSecuredUrlFileDownloadFromMutation,
} from "../../../stories/CloudManagementOS/hooks";
import MainActionMenu from "../../../stories/CloudManagementOS/MainActionMenu";
import { ModelManager } from "../../../stories/CloudManagementOS/ModelManager";
import TreeHeader from "../../../stories/CloudManagementOS/TreeHeader";
import {
    Box,
    Button,
    Center,
    Heading,
    Input,
    VStack,
    Grid,
    Select,
} from "../../../stories/Primatives";
import gql from "graphql-tag";
import { CaratSlider } from "../../../stories/Primatives/GemSearchFilterComponents";
import { GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS } from "../../Admin/graph/queries/getGlobalStatsFromSearchOfGemListings";
import { ARROW_DOWN_ICON, CSV_ICON } from "../../Icons";
import { getShapeIcon, iconography } from "../../IconsV2";
import {
    PriceSlider,
    ShapeSelector,
} from "../../../stories/Primatives/GemSearchFilterComponents";
import UploadAGemListingInventoryCSV from "./Actions/UploadAGemListingInventoryCSV";
import GemListingDashboard from "./GemListingDashboard";
import GemListingListItem from "./GemListingListItem";
import GemListingStateCategoryDashboard from "./GemListingStateCategoryDashboard";
import apiGateway from "../../../api_gateway";

// TODO - maybe this needs to be an input
import { GQL_M_GENERATE_GEM_LISTING_CSV_FROM_SEARCH_PARAM } from "../../Admin/graph/mutations/generateAGemListingHistoryCSVfromSearchParam";
import { head } from "lodash";

const {
    IGraphQLRequest,
    GraphqlQueryGenerator,
} = require("graphql-request-generator");

const DEFAUL_FILTER_PARAM = {
    caratRange: [0.0, 10.0],
    priceRange: [0.0, 1000000.0],
    shapes: LIST_OF_SHAPES_IN_ORDER,
};

const SearchFilterItem = ({ heading, body }) => {
    return (
        <Grid templateRows="1.5rem auto" gap={1}>
            <Heading size="xs">{heading}</Heading>
            <Box>{body}</Box>
        </Grid>
    );
};

const VendorOrganizationFilter = ({ onChange }) => {
    const generator = new GraphqlQueryGenerator();
    let { loading, error, data } = useQuery(
        gql(
            generator.generateRequestString([
                apiGateway.carbonext.graph.queries.searchVendorOrganizationsGQLQueryBuilder(
                    ["id", "legalName"]
                ),
            ])
        )
    );

    console.log("ONCHANGGGGEE", onChange);

    return (
        <SearchFilterItem
            heading={getConstToLanguage("VENDOR_ORGANIZATION")}
            body={
                data && (
                    <Select
                        placeholder="Select option"
                        size="sm"
                        onChange={onChange}
                    >
                        {data?.searchVendorOrganizations.map(
                            (vendorOrganization) => (
                                <option value={vendorOrganization.id}>
                                    {vendorOrganization.legalName}
                                </option>
                            )
                        )}
                    </Select>
                )
            }
        />
    );
};

const SearchBody = ({
    scope,
    userType,
    newSearchParam,
    handleInputChange,
    handleRangeChange,
    onToggleNewSearchParamFilterShape,
    onSelectOnlyShape,
}) => {
    return (
        <VStack align="left" p={6} spacing={6}>
            <VStack align="left" spacing={6}>
                {userType === "admin" && (
                    <VendorOrganizationFilter
                        onChange={(event) =>
                            handleInputChange(event, "vendorOrganizationId")
                        }
                    />
                )}
                <SearchFilterItem
                    heading={"Certificate ID"}
                    body={
                        <Input
                            type="text"
                            size="sm"
                            bg="off-white"
                            value={
                                (newSearchParam &&
                                    newSearchParam.filter &&
                                    newSearchParam.filter.certificationId) ||
                                ""
                            }
                            onChange={(event) =>
                                handleInputChange(event, "certificationId")
                            }
                        />
                    }
                />

                <SearchFilterItem
                    heading={getConstToLanguage("CARAT")}
                    body={
                        <CaratSlider
                            value={
                                newSearchParam &&
                                newSearchParam.filter &&
                                newSearchParam.filter.caratRange
                            }
                            title={false}
                            onChange={(value) =>
                                handleRangeChange(value, "caratRange")
                            }
                        />
                    }
                />
                <SearchFilterItem
                    heading={getConstToLanguage("PRICE")}
                    body={
                        <PriceSlider
                            value={newSearchParam?.filter?.priceRange}
                            title={false}
                            onChange={(value) =>
                                handleRangeChange(value, "priceRange")
                            }
                        />
                    }
                />
                <SearchFilterItem
                    heading={getConstToLanguage("SHAPES")}
                    body={
                        <ShapeSelector
                            selectedShapes={newSearchParam?.filter?.shapes}
                            onClick={onToggleNewSearchParamFilterShape}
                            onClickSelectOnly={onSelectOnlyShape}
                        />
                    }
                />
            </VStack>
        </VStack>
    );
};

const SearchFooter = ({ onClearNewSearchParam, handleSubmitSearch }) => {
    return (
        <VStack p="0.5rem 0.5rem">
            <Center
                onClick={() => {
                    onClearNewSearchParam();
                }}
                textDecoration="underline"
            >
                {getConstToLanguage("RESET")}
            </Center>
            <Button
                w="100%"
                variant="cloud-management-os.primary"
                onClick={() => handleSubmitSearch()}
            >
                {getConstToLanguage("SEARCH")}
            </Button>
        </VStack>
    );
};

const SearchParamFilter = ({ onRemoveFilter, attr, refetch, value }) => {
    let valueSubstitute = value;
    if (attr === "shapes") {
        if (value && LIST_OF_SHAPES_IN_ORDER.length === value.length) {
            valueSubstitute = "ALL";
        }
    }

    return (
        <Flex
            key={value}
            bg={"#fefefe"}
            color={"#222222"}
            p={"0.2em 0.6em"}
            mr={1}
            fontSize={"0.75em"}
            justifyContent={"center"}
            alignItems={"center"}
            _after={{
                borderRadius: "50%",
                bg: "#fdf9f0",
                color: "#000",
                ml: ".5em",
                content: '"X"',
                textAlign: "center",
                w: "1em",
            }}
            onClick={() => onRemoveFilter(attr, refetch)}
        >
            <Box color={"#9a7b1d"} fontWeight={"bold"} mr={"0.2em"}>
                {getConstToLanguage(attr)}
            </Box>
            :
            <Box ml={"0.2em"}>
                {Array.isArray(valueSubstitute)
                    ? `${valueSubstitute[0]} - ${valueSubstitute[1]}`
                    : valueSubstitute}
            </Box>
        </Flex>
    );
};

const GenModelManager = ({
    actionMenuButtons,
    currentSelectedGLS,
    scope, //
    userType,
    ...props
}) => {
    console.log("SCCOPEEEEEEE", scope);
    let [newSearchParamFilterChange, setNewSearchParamFilterChange] = useState(
        {}
    );
    let [newSearchParam, setNewSearchParam] = useState({
        filter: DEFAUL_FILTER_PARAM,
    });
    let [searchParam, setSearchParam] = useState({ filter: scope });

    let gqlQuery = props.gqlQuery;

    let variables = {
        state: currentSelectedGLS,
        searchParam: searchParam,
    };

    let { loading, error, data, fetchMore, refetch } = useQuery(gqlQuery, {
        variables,
    });

    let items = useMemo(
        () =>
            data &&
            data.searchGemListingsInState.edges.map((datum) => ({
                data: datum,
            })),
        [data]
    );

    let { eventToHandle } = useUserEvents();

    useEffect(() => {
        if (eventToHandle && eventToHandle.type === "mutation.state_change") {
            if (eventToHandle.data.state === "COMPLETED") {
                refetch();
            }
        }
    }, [eventToHandle]);

    const searchSection = useCallback(() => {
        return {
            body: (
                <SearchBody
                    scope={scope}
                    userType={userType}
                    newSearchParam={newSearchParam}
                    handleInputChange={handleInputChange}
                    handleRangeChange={handleRangeChange}
                    onToggleNewSearchParamFilterShape={
                        onToggleNewSearchParamFilterShape
                    }
                    onSelectOnlyShape={onSelectOnlyShape}
                />
            ),
            footer: (
                <SearchFooter
                    onClearNewSearchParam={onClearNewSearchParam}
                    handleSubmitSearch={handleSubmitSearch}
                />
            ),
        };
    }, [newSearchParam]);

    const listItemFunc = useCallback(
        (gemListingItem) => {
            return (
                <GemListingListItem
                    gqlQuery={props.gqlQuery}
                    userType={userType}
                    isReserved={
                        props.reservedGemListings &&
                        props.reservedGemListings.includes(
                            gemListingItem.data.node.id
                        )
                    }
                    variables={{
                        state: currentSelectedGLS,
                        searchParam: searchParam,
                    }}
                    item={gemListingItem}
                    key={gemListingItem.data.node.id}
                    itemIdentifierIcon={
                        gemListingItem.data &&
                        gemListingItem.data.node.certifiedGem &&
                        getShapeIcon(
                            gemListingItem.data.node.certifiedGem.specs.shape
                        )
                    }
                    iconography={iconography}
                />
            );
        },
        [items, props.reservedGemListings]
    );

    function handleInputChange(event, filterField) {
        console.log("S:LDFJ:DSLKJF:DSKL");
        setNewSearchParamFilterChange({
            ...newSearchParamFilterChange,
            [filterField]: true,
        });

        setNewSearchParam({
            ...newSearchParam,
            filter: {
                ...newSearchParam.filter,
                [filterField]: event.target.value,
            },
        });
    }

    async function onClearNewSearchParam(event) {
        console.log("Clearing current new search param", newSearchParam);
        setNewSearchParam({
            filter: DEFAUL_FILTER_PARAM,
        });
        setNewSearchParamFilterChange({ all: true });
        setSearchParam({
            filter: DEFAUL_FILTER_PARAM,
        });
    }

    function handleSubmitSearch(event) {
        console.log(
            "Sumbitting gem listing new search to backend: ",
            newSearchParam
        );
        let updatedFilter = {};

        Object.keys(newSearchParamFilterChange).forEach((key) => {
            console.log(updatedFilter);

            if (key === "caratRange") {
                let convertedCaratRange = newSearchParam.filter[key].map(
                    parseFloat
                );

                if (
                    convertedCaratRange[0] !==
                        DEFAUL_FILTER_PARAM.caratRange[0] ||
                    convertedCaratRange[1] !== DEFAUL_FILTER_PARAM.caratRange[1]
                ) {
                    updatedFilter[key] = convertedCaratRange;
                }
            } else if (key === "priceRange") {
                let convertedPriceRange = newSearchParam.filter[key].map(
                    parseFloat
                );
                if (
                    convertedPriceRange[0] !==
                        DEFAUL_FILTER_PARAM.priceRange[0] ||
                    convertedPriceRange[1] !== DEFAUL_FILTER_PARAM.priceRange[1]
                ) {
                    updatedFilter[key] = convertedPriceRange;
                }
            } else {
                updatedFilter[key] = newSearchParam.filter[key];
            }
        });
        console.log(newSearchParam);

        setNewSearchParamFilterChange({});
        setSearchParam({
            ...newSearchParam,
            filter: updatedFilter,
        });
    }

    function handleRangeChange(value, filterField) {
        console.log(value);

        setNewSearchParamFilterChange({
            ...newSearchParamFilterChange,
            [filterField]: true,
        });
        setNewSearchParam({
            ...newSearchParam,
            filter: {
                ...newSearchParam.filter,
                [filterField]: value,
            },
        });
    }

    function onSelectOnlyShape(ev) {
        let shape = ev.target.value;

        setNewSearchParam({
            ...newSearchParam,
            filter: {
                ...newSearchParam.filter,
                shapes: [shape],
            },
        });

        setNewSearchParamFilterChange({
            ...newSearchParamFilterChange,
            shapes: true,
        });
    }

    function onToggleNewSearchParamFilterShape(ev) {
        let shape = ev.target.value;

        if (newSearchParam.filter.shapes.includes(shape)) {
            setNewSearchParam({
                ...newSearchParam,
                filter: {
                    ...newSearchParam.filter,
                    shapes: newSearchParam.filter.shapes.filter(
                        (s) => s !== shape
                    ),
                },
            });

            setNewSearchParamFilterChange({
                ...newSearchParamFilterChange,
                shapes: true,
            });
        } else {
            let newListOfShapes = newSearchParam.filter.shapes.slice();
            newListOfShapes.push(shape);
            setNewSearchParam({
                ...newSearchParam,
                filter: {
                    ...newSearchParam.filter,
                    shapes: newListOfShapes,
                },
            });

            setNewSearchParamFilterChange({
                ...newSearchParamFilterChange,
                shapes: true,
            });
        }
    }

    const header = useCallback(() => {
        let modelConst = "GEM_LISTINGS";

        let modelSelectorConfig = {
            tree: {
                GEM_LISTINGS: {
                    children: {
                        PENDING: {
                            children: {
                                NEW: {},
                                VERIFICATION_REQUESTED: {},
                                VERIFIED: {},
                                UNVERIFIED: {},
                                REJECTED: {},
                            },
                        },
                        MARKET: {
                            children: {
                                ON_MARKET: {},
                                OFF_MARKET: {},
                            },
                        },
                        PAST_SALES: {
                            children: {
                                SOLD: {},
                            },
                        },
                        ARCHIVES: {
                            children: {
                                ARCHIVED: {},
                            },
                        },
                    },
                },
            },
            currentSelection: [
                modelConst,
                urlConstToConst(props.match.params.param1),
            ],
        };

        if (props.match.params.param1) {
            modelSelectorConfig.currentSelection.push(
                urlConstToConst(props.match.params.param2)
            );
        }
        return (
            <Query
                query={
                    GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS
                }
                variables={searchParam}
            >
                {({ loading, error, data, fetchMore }) => {
                    if (data) {
                        Object.keys(
                            modelSelectorConfig.tree[modelConst].children
                        ).forEach((category) => {
                            if (
                                category in
                                data.getGlobalStatsFromSearchOfGemListings
                                    .counts.byCategory
                            ) {
                                modelSelectorConfig.tree[modelConst].children[
                                    category
                                ].count =
                                    data.getGlobalStatsFromSearchOfGemListings.counts.byCategory[
                                        category
                                    ];
                            }
                            Object.keys(
                                modelSelectorConfig.tree[modelConst].children[
                                    category
                                ].children
                            ).forEach((state) => {
                                if (
                                    state in
                                    data.getGlobalStatsFromSearchOfGemListings
                                        .counts.byState
                                ) {
                                    modelSelectorConfig.tree[
                                        modelConst
                                    ].children[category].children[state].count =
                                        data.getGlobalStatsFromSearchOfGemListings.counts.byState[
                                            state
                                        ];
                                }
                            });
                        });
                    }

                    return (
                        <Header
                            title={getConstToLanguage("GEM_LISTINGS")}
                            main={
                                <TreeHeader
                                    urlPrefix={
                                        userType === "vendor"
                                            ? "/vendor/gem-listings-manager"
                                            : "/admin/gem-listings-manager"
                                    }
                                    dropDownIcon={ARROW_DOWN_ICON}
                                    tree={modelSelectorConfig.tree}
                                    currentSelection={
                                        modelSelectorConfig.currentSelection
                                    }
                                />
                            }
                            actionMenu={
                                <MainActionMenu buttons={actionMenuButtons} />
                            }
                        />
                    );
                }}
            </Query>
        );
    }, [props.match]);

    function onRemoveFilter(filter, refetch) {
        let newSearchParam = { ...searchParam };
        delete newSearchParam.filter[filter];

        setSearchParam(newSearchParam);
        refetch();
    }

    return (
        <ModelManager
            modelName={getConstToLanguage("GEM_LISTINGS")}
            urlPrefix={userType}
            toolUrlRoute="gem-listings-manager"
            modelUrlName="gem-listings"
            query={gqlQuery}
            isDataLoading={loading}
            category={props.match.params.param1}
            state={props.match.params.param2}
            items={items}
            totalCount={data && data.searchGemListingsInState.totalCount}
            pageInfo={data && data.searchGemListingsInState.pageInfo}
            listItemFunc={listItemFunc}
            loadMoreIcon={ARROW_DOWN_ICON}
            dropdownIcon={ARROW_DOWN_ICON}
            header={header()}
            actionMenuButtons={actionMenuButtons}
            searchParam={searchParam}
            onLoadMore={useCallback(
                () =>
                    fetchMore({
                        variables: {
                            pagination: {
                                page:
                                    data &&
                                    data.searchGemListingsInState.pageInfo
                                        .page + 1,
                            },
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges =
                                fetchMoreResult.searchGemListingsInState.edges;
                            const pageInfo =
                                fetchMoreResult.searchGemListingsInState
                                    .pageInfo;

                            return newEdges.length
                                ? {
                                      // Put the new comments at the end of the list and update `pageInfo`
                                      // so we have the new `endCursor` and `hasNextPage` values
                                      searchGemListingsInState: {
                                          __typename:
                                              previousResult
                                                  .searchGemListingsInState
                                                  .__typename,
                                          edges: [
                                              ...previousResult
                                                  .searchGemListingsInState
                                                  .edges,
                                              ...newEdges,
                                          ],
                                          pageInfo,
                                      },
                                  }
                                : previousResult;
                        },
                    }),
                [data]
            )}
            searchParamFilterRenderFunc={useCallback(
                (attr, value) => (
                    <SearchParamFilter
                        attr={attr}
                        value={value}
                        refetch={refetch}
                        onRemoveFilter={onRemoveFilter}
                    />
                ),
                [props.searchParam]
            )}
            newSearchParam={props.newSearchParam}
            param1={props.param1}
            searchSection={searchSection()}
            template="template1"
            searchFilterLocation={"search-filter"}
            iconography={iconography}
            submitSearchFunc={props.submitSearchFunc}
            searchValueUpdateFunc={props.searchValueUpdateFunc}
        />
    );
};

const GemListingManager = ({ bodyOnly, scope, userType, ...props }) => {
    let currentSelectedGLS =
        props?.match?.params?.param2 &&
        urlConstToConst(props.match.params.param2.toUpperCase());

    let [downloadGemListingCSV] = useSecuredUrlFileDownloadFromMutation(
        GQL_M_GENERATE_GEM_LISTING_CSV_FROM_SEARCH_PARAM
    );

    console.log("SDOIFSODIUFPSDIF___", scope);

    let actionMenuButtons =
        userType === "vendor"
            ? {
                  UPLOAD_A_GEM_LISTINGS_CSV: {
                      icon: <CSV_ICON />,
                      body: <UploadAGemListingInventoryCSV />,
                      description: getConstToLanguage("UPLOAD_A_CSV"),
                  },
              }
            : {
                  DOWNLOAD_GEM_LISTING_CSV: {
                      icon: <CSV_ICON />,
                      description: getConstToLanguage("DOWNLOAD_AS_CSV"),
                      action: () => downloadGemListingCSV(),
                  },
              };

    if (props?.match?.params?.param1 && props?.match?.params?.param2) {
        return (
            <GenModelManager
                {...props}
                actionMenuButtons={actionMenuButtons}
                currentSelectedGLS={currentSelectedGLS}
                scope={scope}
                userType={userType}
            />
        );
    } else if (props?.match?.params?.param1) {
        return (
            <GemListingStateCategoryDashboard
                urlPrefix={userType}
                toolUrlRoute="gem-listings-manager"
                actionMenuButtons={actionMenuButtons}
                category={props.param1}
                match={props.match}
                gqlQueries={props.gqlQueries}
                scope={scope}
                userType={userType}
            />
        );
    } else {
        return (
            <GemListingDashboard
                urlPrefix={userType}
                toolUrlRoute="gem-listings-manager"
                actionMenuButtons={actionMenuButtons}
                match={props.match}
                bodyOnly={bodyOnly}
                gqlQueries={props.gqlQueries}
                scope={scope}
                userType={userType}
            />
        );
    }
};

export default GemListingManager;
