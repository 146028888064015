import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { default as React } from "react";

import { AuthLogin } from "../../UtilityComponents/AuthLogin";

const RESET_MARKET_USER_PASSWORD_GQL_MUTATION = gql`
    mutation ResetMarketUserPassword($email: Email!) {
        resetMarketUserPassword(email: $email) {
            message
        }
    }
`;

function MarketUserResetPassword() {
    const [resetPasswordFuncMutation, { data, loading, error }] = useMutation(
        RESET_MARKET_USER_PASSWORD_GQL_MUTATION,
        {}
    );

    const resetPasswordFunc = async (variables) =>
        await resetPasswordFuncMutation({ variables });

    return (
        <AuthLogin
            variant="two-panel-template"
            mode="reset-password"
            heading={"Reset Password"}
            resetPasswordFunc={resetPasswordFunc}
        />
    );
}

export default MarketUserResetPassword;
