import React from "react";
import { Mutation } from "@apollo/react-components";
import { Link } from "react-router-dom";
import { VERIFY_AND_CREATE_ORGANIZATION_FROM_VENDOR_ORGANIZATION_INQUIRY } from "../../../../graph/mutations/VendorOrganization/verifyAndCreateVendorOrganizationFromVendorInquiry";

class VendorOrganizationInquiryListItem extends React.Component {
  constructor() {
    super();

    this.renderToolBar = this.renderToolBar.bind(this);
  }

  renderToolBar() {
    console.log(this.props.item);
    if (
      this.props.item &&
      this.props.item.data &&
      this.props.item.data.node.createdVendorOrganization === null &&
      this.props.item.data.node.state === "NEW"
    ) {
      return (
        <Mutation
          mutation={
            VERIFY_AND_CREATE_ORGANIZATION_FROM_VENDOR_ORGANIZATION_INQUIRY
          }
        >
          {(
            verifyAndCreateVendorOrganizationFromVendorInquiry,
            { error, data, loading }
          ) => {
            return (
              <button
                onClick={e => {
                  e.preventDefault();
                  verifyAndCreateVendorOrganizationFromVendorInquiry({
                    variables: {
                      vendorOrganizationInquiryId: this.props.item.data.node.id
                    }
                  });
                }}
              >
                Verify Inquiry
              </button>
            );
          }}
        </Mutation>
      );
    }
  }

  render() {
    return (
      <div className="model-item grow">
        <div
          className="model-item-style-1"
          key={
            this.props.item && this.props.item.data && this.props.item.data.id
          }
        >
          <div className="model-item-style-1-model-type"></div>
          <div className="model-item-style-1-model-identifier">
            {this.props.item &&
              this.props.item.data &&
              this.props.item.data.node.organization &&
              this.props.item.data.node.organization.legalName}
          </div>
          <div className="model-item-style-1-model-body">
            <div>
              Contact Name :{" "}
              {this.props.item &&
                this.props.item.data &&
                this.props.item.data.node.person.givenName}{" "}
              {this.props.item &&
                this.props.item.data &&
                this.props.item.data.node.person.familyName}
            </div>
            <div>
              Contact Email :{" "}
              {this.props.item &&
                this.props.item.data &&
                this.props.item.data.node.email}
            </div>
            <div>
              Contact Phone :{" "}
              {this.props.item &&
                this.props.item.data &&
                this.props.item.data.node.phoneNumber}
            </div>
            <div>
              State :{" "}
              {this.props.item &&
                this.props.item.data &&
                this.props.item.data.node.state}
            </div>

            <div>
              Created At :{" "}
              {this.props.item &&
                this.props.item.data &&
                this.props.item.data.node.createdAt}
            </div>

            {this.props.item &&
              this.props.item.data &&
              this.props.item.data.node.createdVendorOrganization && (
                <Link
                  to={`/admin/vendor-organization/${this.props.item.data.createdVendorOrganization}`}
                >
                  vendor organization
                </Link>
              )}
          </div>
          <div className="model-item-style-1-model-tool-bar">
            {this.renderToolBar()}
          </div>
        </div>
      </div>
    );
  }
}

export default VendorOrganizationInquiryListItem;
