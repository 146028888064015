import { Box } from "@chakra-ui/react";
import React from "react";

const ListContainer = React.memo((props) => {
    return (
        <Box
            id="product-search-results-list"
            w="100vw"
            paddingLeft={{ base: 8, lg: 24 }}
            paddingRight={{ base: 8, lg: 24 }}
            paddingTop={{ base: 8 }}
            paddingBottom={{ base: 8 }}
            className="jdsu__product-search__search-results__list-container"
        >
            {props.listItems.map((item) =>
                props.itemFunc({ ...props.itemProps, item })
            )}
        </Box>
    );
});

export default ListContainer;
