import AuthListErrors from "../../AuthListErrors";
import React, { useState, useCallback } from "react";

import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";

import { LOGO_GRAPHIC_ONLY } from "../../Icons";

import { Mutation } from "@apollo/react-components";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { AuthLogin } from "../../UtilityComponents/AuthLogin";
import qs from "qs";
import gql from "graphql-tag";

const UPDATE_MARKET_USER_PASSWORD_GQL_MUTATION = gql`
    mutation ResetVendorPasswordFromResetEmail(
        $resetToken: String!
        $password: String!
    ) {
        updateVendorPasswordFromResetEmail(
            resetToken: $resetToken
            password: $password
        )
    }
`;

const VendorUpdatePassword = (props) => {
    let queryParams = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
    });
    let resetToken = queryParams.token;

    let history = useHistory();

    const [updatePasswordFuncMutation, { data, loading, error }] = useMutation(
        UPDATE_MARKET_USER_PASSWORD_GQL_MUTATION,
        {}
    );

    const updatePasswordFunc = useCallback(async (variables) => {
        let newVariables = { ...variables, resetToken };

        return await updatePasswordFuncMutation({ variables: newVariables });
    }, updatePasswordFuncMutation);

    if (data) {
        history.push("/vendor/login");
    }

    return (
        <AuthLogin
            mode="update-password"
            heading={getConstToLanguage("VENDOR_LOGIN")}
            updatePasswordFunc={updatePasswordFunc}
        />
    );
};

export default VendorUpdatePassword;
