import React from "react";
import { Text, VStack, SimpleGrid, Center, Box } from "@chakra-ui/react";
import { TextWithLink } from "../../components/Market/TextWithLink";
import { Divider } from "../Primatives";

export const HowItWorks = () => {
    return (
        <Box px={{ base: 10, lg: 20 }} py={{ base: 10, lg: 20 }}>
            <Center>
                <Divider variant={"gold"} w={44} />
            </Center>
            <Text
                as={"h1"}
                fontSize={{ base: "3xl", md: "4xl", xl: "4xl" }}
                lineHeight={"shorter"}
                fontFamily="QuicheText, sans-serif"
                textTransform={"uppercase"}
                textAlign={"center"}
                my={10}
            >
                How it works
            </Text>
            <SimpleGrid columns={[1, 1, 1, 3]} spacing={10}>
                <TextWithLink
                    title="Reserve and purchase your diamond"
                    firstLetter={"1"}
                    url={"/market/diamonds"}
                    urlText={"SEARCH DIAMONDS"}
                >
                    Search our diamonds to find your perfect diamond at
                    unbeatable value, sourced by our US based trusted traders.
                    By "reserving" your diamond, you are able to hold your
                    selected diamond for 24 hours before you make your final
                    decision to purchase.
                </TextWithLink>
                <TextWithLink
                    title="Pick up at partner jeweler or ship directly to you"
                    firstLetter={"2"}
                    url={"/"}
                    urlText={"COMING SOON"}
                >
                    Once you select, reserve and purchase your diamond, we can
                    either have the diamond shipped directly to you or to one of
                    our entrusted partner jewelers you have selected to design
                    your ring.
                </TextWithLink>
                <TextWithLink
                    title="Customize a setting at the jeweler"
                    firstLetter={"3"}
                    url={"/"}
                    urlText={"COMING SOON"}
                >
                    Once we have matched you with one of our entrusted jewelers,
                    you will work with them to design and build your perfect
                    ring. The customer gets the retail, luxury experience with
                    the benefits of online pricing.
                </TextWithLink>
            </SimpleGrid>
        </Box>
    );
};
