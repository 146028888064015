import { Mutation } from "@apollo/react-components";
import React from "react";
import { Link } from "react-router-dom";
import { CREATE_VENDOR_ORGANIZATION_INQUIRY } from "../../../graph/mutations/VendorOrganizationInquiry";
import { getConstToLanguage } from "../../../stories/CloudManagementOS/ConstToLanguageMapping";
import { Checkbox } from "../../../stories/Primatives";
import AuthListErrors from "../../AuthListErrors";
import { LOGO_GRAPHIC_ONLY } from "../../Icons";

import { Nav } from "../../../stories/Market/Nav";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { iconography } from "../../../components/IconsV2";
import { ApolloProvider } from "@apollo/client";
import { marketUserGraphClient } from "../../Market/graph";
import { VendorOrganizationInquiryBody } from "./VendorOrganizationInquiryBody";

// export const VendorOrganizationInquiryBody = ({ ...props }) => {};

export const VendorOrganizationInquiry = ({ ...props }) => {
    let slideInMenuStyling = {
        color: "white",
        bg: "off-black",
    };
    const menuVariant = useBreakpointValue({ base: "slide-in", lg: "full" });

    return (
        <ApolloProvider client={marketUserGraphClient}>
            <Flex direction="column">
                <Nav
                    colorMode="dark"
                    slideInMenuStyling={slideInMenuStyling}
                    menuVariant={menuVariant}
                    {...props}
                    iconography={iconography}
                    logOut={props.logOut}
                />
                <VendorOrganizationInquiryBody />
            </Flex>
        </ApolloProvider>
    );
};

// class VendorOrganizationInquiry extends React.Component {
//     constructor() {
//         super();
//         this.state = { vendorTypes: [] };
//         this.handleChange = this.handleChange.bind(this);
//     }

//     handleChange = (vendorRole) => (e) => {
//         console.log("vendorROLEEEE", vendorRole);
//         // TODO - V3 Fix - when we can support more types of vendors strip this out!

//         let category;
//         let newListOfVendorRoles = this.state.vendorTypes.slice();

//         if (vendorRole === "GEM_TRADER") {
//             category = "CERTIFIED_COLORLESS_DIAMONDS";
//         } else if (vendorRole === "JEWELRY_MAKER") {
//             category = "ENGAGEMENT_RINGS";
//         }

//         if (e.currentTarget.checked) {
//             newListOfVendorRoles = Array.from(
//                 new Set(
//                     this.state.vendorTypes.concat({
//                         vendorRole: vendorRole,
//                         category: category,
//                     })
//                 )
//             );
//         } else {
//             newListOfVendorRoles = newListOfVendorRoles.filter((vendorRole) =>
//                 this.isSameVendorRole(newListOfVendorRoles, {
//                     vendorRole: vendorRole,
//                     category: category,
//                 })
//             );
//         }

//         this.setState({ vendorTypes: newListOfVendorRoles });
//     };

//     isSameVendorRole(vendorRole1, vendorRole2) {
//         if (
//             vendorRole1.vendorRole === vendorRole2.vendorRole &&
//             vendorRole1.category === vendorRole2.category
//         ) {
//             return true;
//         }
//         return false;
//     }

//     hasVendorTypeChecked(currentlySelectedVendorRoles, vendorRoleToCheck) {
//         // let a = currentlySelectedVendorRoles.filter(vendorRole =>
//         //   this.isSameVendorRole(currentlySelectedVendorRoles, vendorRoleToCheck)
//         // );
//     }

//     render() {
//         let personGivenName;
//         let personFamilyName;
//         let personAdditionalName;
//         let email;
//         let organizationLegalName;
//         let phoneNumber;

//         return (
//             <div className="auth-page">
//                 <div className="auth-container">
//                     <div className="vendor-sign-up">
//                         <div className="auth-card">
//                             <div className="auth-title">
//                                 <div className="auth-logo">
//                                     <LOGO_GRAPHIC_ONLY />
//                                 </div>
//                                 <h1>{getConstToLanguage("VENDOR_INQUIRY")}</h1>
//                             </div>

//                             <Mutation
//                                 mutation={CREATE_VENDOR_ORGANIZATION_INQUIRY}
//                             >
//                                 {(
//                                     createVendorOrganizationInquiry,
//                                     { error, loading, data }
//                                 ) => {
//                                     if (data) {
//                                         return (
//                                             <div className="auth-good-resp">
//                                                 Thank you for the inquiry. We
//                                                 are in the process of building
//                                                 out Carbonext. We will be
//                                                 contacting you shortly once the
//                                                 website is ready.
//                                             </div>
//                                         );
//                                     } else {
//                                         return (
//                                             <div>
//                                                 <p>
//                                                     <Link to="/vendor/login">
//                                                         {getConstToLanguage(
//                                                             "HAVE_AN_ACCOUNT_Q"
//                                                         )}
//                                                     </Link>
//                                                 </p>
//                                                 <AuthListErrors
//                                                     graphError={error}
//                                                 />

//                                                 <form
//                                                     onSubmit={(e) => {
//                                                         console.log(this.state);
//                                                         e.preventDefault();
//                                                         createVendorOrganizationInquiry(
//                                                             {
//                                                                 variables: {
//                                                                     email:
//                                                                         email.value,
//                                                                     phoneNumber:
//                                                                         phoneNumber.value,
//                                                                     organization: {
//                                                                         legalName:
//                                                                             organizationLegalName.value,
//                                                                         organizationType:
//                                                                             "LLC",
//                                                                     },
//                                                                     person: {
//                                                                         givenName:
//                                                                             personGivenName.value,
//                                                                         familyName:
//                                                                             personFamilyName.value,
//                                                                         additionalName:
//                                                                             personAdditionalName.value,
//                                                                     },
//                                                                     vendorTypes: this
//                                                                         .state
//                                                                         .vendorTypes,
//                                                                 },
//                                                             }
//                                                         );
//                                                         personGivenName = "";
//                                                     }}
//                                                 >
//                                                     <fieldset>
//                                                         <div className="white xycenter">
//                                                             <label className="white xycenter">
//                                                                 <Checkbox
//                                                                     colorScheme={
//                                                                         "checkbox-gold"
//                                                                     }
//                                                                     name={
//                                                                         "GEM_TRADER"
//                                                                     }
//                                                                     value={this.hasVendorTypeChecked(
//                                                                         "GEM_TRADER"
//                                                                     )}
//                                                                     onChange={this.handleChange(
//                                                                         "GEM_TRADER"
//                                                                     )}
//                                                                 />
//                                                                 {getConstToLanguage(
//                                                                     "CERTIFIED_DIAMOND_GEM_TRADER"
//                                                                 )}{" "}
//                                                             </label>

//                                                             <label className="white xycenter">
//                                                                 <Checkbox
//                                                                     colorScheme={
//                                                                         "checkbox-gold"
//                                                                     }
//                                                                     name={
//                                                                         "JEWELRY_MAKER"
//                                                                     }
//                                                                     value={this.hasVendorTypeChecked(
//                                                                         "JEWELRY_MAKER"
//                                                                     )}
//                                                                     onChange={this.handleChange(
//                                                                         "JEWELRY_MAKER"
//                                                                     )}
//                                                                 />
//                                                                 {getConstToLanguage(
//                                                                     "JEWELRY_MAKER"
//                                                                 )}{" "}
//                                                             </label>
//                                                         </div>
//                                                     </fieldset>
//                                                     <fieldset>
//                                                         <input
//                                                             placeholder={getConstToLanguage(
//                                                                 "ORGANIZATION_LEGAL_NAME"
//                                                             )}
//                                                             ref={(node) => {
//                                                                 organizationLegalName = node;
//                                                             }}
//                                                         />
//                                                     </fieldset>
//                                                     <fieldset>
//                                                         <input
//                                                             placeholder={getConstToLanguage(
//                                                                 "GIVEN_NAME"
//                                                             )}
//                                                             ref={(node) => {
//                                                                 personGivenName = node;
//                                                             }}
//                                                         />
//                                                     </fieldset>
//                                                     <fieldset>
//                                                         <input
//                                                             placeholder={getConstToLanguage(
//                                                                 "ADDITIONAL_NAME"
//                                                             )}
//                                                             ref={(node) => {
//                                                                 personAdditionalName = node;
//                                                             }}
//                                                         />
//                                                     </fieldset>
//                                                     <fieldset>
//                                                         <input
//                                                             placeholder={getConstToLanguage(
//                                                                 "FAMILY_NAME"
//                                                             )}
//                                                             ref={(node) => {
//                                                                 personFamilyName = node;
//                                                             }}
//                                                         />
//                                                     </fieldset>
//                                                     <fieldset>
//                                                         <input
//                                                             placeholder={getConstToLanguage(
//                                                                 "EMAIL"
//                                                             )}
//                                                             ref={(node) => {
//                                                                 email = node;
//                                                             }}
//                                                         />
//                                                     </fieldset>

//                                                     <fieldset>
//                                                         <input
//                                                             placeholder={getConstToLanguage(
//                                                                 "PHONE_NUMBER"
//                                                             )}
//                                                             ref={(node) => {
//                                                                 phoneNumber = node;
//                                                             }}
//                                                         />
//                                                     </fieldset>

//                                                     <fieldset>
//                                                         <button
//                                                             className="auth-submit-btn"
//                                                             type="submit"
//                                                             disabled={loading}
//                                                         >
//                                                             {getConstToLanguage(
//                                                                 "INQUIRE"
//                                                             )}
//                                                         </button>
//                                                     </fieldset>
//                                                 </form>
//                                             </div>
//                                         );
//                                     }
//                                 }}
//                             </Mutation>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

export default VendorOrganizationInquiry;
