import React from "react";
import { SHAPE_TO_ICON_MAPPING, CLOSE_ICON } from "../../components/Icons";
import { numberToCurrency } from "../../components/UtilityComponents/etc/floatToCurrency";
import { getConstToLanguage } from "../CloudManagementOS/ConstToLanguageMapping";
import { Box, HStack, Center, Button, VStack, Heading } from "@chakra-ui/react";
import { PhoneIcon } from "../../components/IconsV2";
export const PhoneNumber = ({
    name,
    countryCode,
    areaCode,
    phoneNumber,
    fullNumber,
    iconColor = "black",
}) => {
    return (
        <Box>
            <a href={`tel:${fullNumber}`}>
                <PhoneIcon w={12} color={iconColor} />
                {fullNumber}
            </a>
        </Box>
    );
};
