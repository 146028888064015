import {
  VENDOR_LOGIN,
  VENDOR_SIGNUP,
  VENDOR_LOGIN_PAGE_UNLOADED,
  ASYNC_START,
  VENDOR_LOGIN_UPDATE_FIELD_AUTH
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case VENDOR_LOGIN:
      return {
        ...state,
        inProgress: false,
        vendorAuthErrorResp: action.error ? action.payload : null
      };
    case VENDOR_LOGIN_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      if (action.subtype === VENDOR_LOGIN || action.subtype === VENDOR_SIGNUP) {
        return { ...state, inProgress: true };
      }
      break;
    case VENDOR_LOGIN_UPDATE_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};