import { useMutation } from "@apollo/react-hooks";

export function useSecuredUrlFileDownloadFromMutation(gqlMutation, options) {
  const [mutation, { data, loading, error }] = useMutation(
    gqlMutation,
    options
  );

  if (data) {
    window.open(data[Object.keys(data)[0]], "_blank");
  }

  return [mutation, { data, loading, error }];
}
