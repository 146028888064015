import React from "react";
import { Link as ReachLink } from "@reach/router";
import PropTypes from "prop-types";
import {
    Circle,
    Box,
    Popover,
    PopoverContent,
    Flex,
    PopoverTrigger,
    VStack,
    PopoverArrow,
    PopoverBody,
    Center,
    HStack,
    Heading,
    Button,
    Divider,
    Text,
    StackDivider,
    Link,
    textDecoration,
} from "@chakra-ui/react";
import { Link as ReactLink, Route } from "react-router-dom";

export const CurrentShoppingBagMenuMolecule = ({
    color,
    itemCountBgColor = "gray.300",
    itemCountTextColor = "black",
    items,
    icon,
    linkTo,
    countPosition = "right",
}) => {
    let itemsCount = items && items.length;

    if (countPosition === "right-corner") {
        return (
            <Box width={4} position="relative">
                <Box>{<icon.component color={color} />}</Box>

                <Circle
                    size={4}
                    pos="absolute"
                    bg={itemCountBgColor}
                    top={-1}
                    right={-2}
                    fontSize="xs"
                    // zIndex="-1"
                    color={color}
                >
                    {itemsCount}
                </Circle>
            </Box>
        );
    } else if (countPosition === "right") {
        let currentShoppingView = (
            <HStack>
                <Box width={4}>{<icon.component color={color} />}</Box>
                <Circle size={5} bg={itemCountBgColor} fontSize="xs">
                    <Text color={itemCountTextColor}>{itemsCount || "0"}</Text>
                </Circle>
            </HStack>
        );

        // TODO - jeff can make this a helper function

        if (linkTo) {
            return (
                <Link
                    as={ReactLink}
                    to={linkTo}
                    _hover={{ textDecoration: "none" }}
                >
                    {currentShoppingView}
                </Link>
            );
        } else {
            return currentShoppingView;
        }
    }
};
