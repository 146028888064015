import React from 'react';
import PropTypes from 'prop-types';
import { LogoWithWordsIcon, LogoIconOnly } from "../../../components/IconsV2"

/**
 * Primary UI component for user interaction
 */
export const Logo = ({ logoColor1, colorMode, logoType }) => {

    if (logoType === 'logoOnly') {
        return (<LogoIconOnly colorMode={colorMode} logoColor1={logoColor1} />)
    } else {
        return (

            <LogoWithWordsIcon colorMode={colorMode} logoColor1={logoColor1} />

        );
    }
};


Logo.propTypes = {
    logoType: PropTypes.string,
    logoColor1: PropTypes.string,
    colorMode: PropTypes.oneOf(['light', 'dark'])
};

Logo.defaultProps = {
    colorMode: 'light'
};
