import React, { useState, useCallback } from "react";
import useToggle from "../hooks/useToggle";
import { UnlockIcon, LockIcon } from "@chakra-ui/icons";
import { Input, Flex, Box, IconButton } from "../../Primatives";

const HiddenAttribute = ({ register, unHiddenValue, mode, name, onUnhide }) => {
    let { isToggled: isLocked, toggle: toggleLock } = useToggle(true);

    let icon = isLocked ? <UnlockIcon /> : <LockIcon />;

    const handleToggle = async () => {
        if (isLocked && onUnhide) {
            await onUnhide();
        }
        toggleLock();
    };

    return mode === "edit" ? (
        <Input id={name} {...register(name)} />
    ) : (
        <Flex>
            <Box>
                {isLocked ? "***" : unHiddenValue}
                <IconButton
                    onClick={handleToggle}
                    aria-label="Unlock"
                    icon={icon}
                />
            </Box>
        </Flex>
    );
};

const NormalAttribute = ({ register, currentValue, mode, setValue, name }) => {
    return mode === "edit" ? (
        <Input id={name} {...register(name)} />
    ) : (
        <Box>{currentValue}</Box>
    );
};

const EditableKeyValue = ({
    name,
    keyName,
    mode,
    hidden,
    register,
    onUnhide,
    unHiddenValue,
}) => {
    return (
        <>
            <Box>{keyName}</Box>
            {hidden === true ? (
                <HiddenAttribute
                    mode={mode}
                    name={name}
                    register={register}
                    onUnhide={onUnhide}
                    unHiddenValue={unHiddenValue}
                />
            ) : (
                <NormalAttribute mode={mode} name={name} register={register} />
            )}
        </>
    );
};

export { EditableKeyValue, HiddenAttribute, NormalAttribute };
