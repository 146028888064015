import * as React from "react";
import {
    VStack,
    Box,
    Alert,
    AlertIcon,
    AlertDescription,
} from "../../Primatives";
export const GraphQLRespErrorHandler = ({ error }) => {
    return (
        <VStack id="graphql-resp-error">
            {Array.isArray(error?.graphQLErrors) &&
                error?.graphQLErrors.map((errorInRespError) => (
                    <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>
                            {errorInRespError.message}
                        </AlertDescription>
                    </Alert>
                ))}
        </VStack>
    );
};
