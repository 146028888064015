import gql from "graphql-tag";

const GQL_Q_SEARCH_VENDOR_ORGANIZATIONS = gql`
    query SearchVendorOrganizations {
        searchVendorOrganizations {
            id
            legalName
            vendorTypes {
                ... on JewelryMakerOrganizationVendorType {
                    vendorRole
                }
                ... on GemTradingOrganizationVendorType {
                    vendorRole
                }
            }
        }
    }
`;

export { GQL_Q_SEARCH_VENDOR_ORGANIZATIONS };
