import React from "react";
import {
    Box,
    Heading,
    Text,
    Center,
    VStack,
    SimpleGrid,
    Image,
    Grid,
    GridItem,
    Container,
} from "@chakra-ui/react";
import { DividerImage } from "../Primatives/DividerImage";
import { GetStarted } from "../Market/GetStarted";
import { Footer } from "./Footer";
import { Button, Divider } from "../Primatives";

/**
 * Primary UI component for user interaction
 */
export const WhyChooseUs = ({ ...props }) => {
    return (
        <Box w="100%" {...props}>
            <Box
                pt={44}
                backgroundSize={"cover"}
                backgroundImage="url('https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/mel-BN0HcaBdPug-unsplash.png')"
            >
                {/* <Box w="100%" h="100%" bg="black" pos="absolute" opacity="60%" zIndex="-1"></Box> */}
                <Box pos="relative" p={[8, 16, "9%"]}>
                    <VStack spacing={8}>
                        <Heading
                            fontSize={{ base: "2rem", md: "70" }}
                            fontWeight={400}
                            textTransform={"uppercase"}
                        >
                            Why choose us?
                        </Heading>
                        <Text
                            align="center"
                            fontFamily={"Montserrat"}
                            fontSize={24}
                        >
                            With several online diamond retailers available to
                            consumers, you may ask yourself what sets Carbonext
                            apart. We are able to offer concierge service with
                            the advantage of online pricing.
                        </Text>
                    </VStack>
                </Box>
            </Box>

            <SimpleGrid
                columns={[1, 1, 2]}
                minH={500}
                pt={[8, 16, 20]}
                pb={[8, 16, 8]}
                px={[1, 2, 2, "9%"]}
            >
                <Grid
                    p={{ base: 12, lg: 4 }}
                    templateColumns="repeat(14, 1fr)"
                    templateRows="repeat(12, 0.5fr)"
                    maxH={600}
                    alignSelf={"center"}
                    bgSize="contain"
                    bgRepeat={"no-repeat"}
                    bgPosition="center"
                    bgImage={
                        'url("https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/Engagement-Ring-in-Box.H03.2k.png")'
                    }
                >
                    <GridItem colStart={2} colSpan={3} rowStart={3} rowEnd={6}>
                        <Image
                            src="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/silver-wedding-rings-h-03-2-k.png"
                            alt="Wedding rings"
                        />
                    </GridItem>
                    <GridItem colSpan={3} rowStart={7} rowEnd={13}>
                        <Image
                            src="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/engagement-ring-h-03-2-k.png"
                            alt="Engagement ring"
                        />
                    </GridItem>
                    <GridItem
                        colStart={11}
                        colEnd={15}
                        rowStart={5}
                        rowEnd={13}
                    >
                        <Image
                            src="https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/round-diamond-h-03-2-k.png"
                            alt="Round diamond"
                        />
                    </GridItem>
                </Grid>

                <Box
                    // px={{bas[8, 8, 10, 10, 12]}}
                    fontFamily="Montserrat, sans-serif"
                    p={{ base: 12, lg: 4 }}
                >
                    <Divider variant={"gold"} w={44} />
                    <VStack alignItems={"baseline"} gap={5} pt={10}>
                        <Heading
                            fontSize={{ base: "3xl", md: "4xl", xl: "4xl" }}
                            lineHeight={"shorter"}
                            fontFamily="QuicheText, sans-serif"
                            textTransform={"uppercase"}
                        >
                            Transparency
                        </Heading>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            {" "}
                            The most important feature we are able to offer our
                            customers is transparency. What you see is what you
                            get. All of the available diamonds on our platform
                            are as good in person as they are on paper and are
                            sold by only US based trusted traders.
                        </Text>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            This will guarantee the stone you select is on US
                            soil and will not be delayed due to any
                            international freight issues or international custom
                            delays. Our goal, from beginning to end, is to make
                            the entire engagement ring process as seamless as
                            possible.
                        </Text>
                    </VStack>
                </Box>
            </SimpleGrid>

            <SimpleGrid
                bgColor={"grey.500"}
                columns={[1, 1, 2]}
                px={[1, 2, 2, "9%"]}
                py={[8, 16, 20]}
            >
                <Box
                    pr={"10%"}
                    fontFamily="Montserrat, sans-serif"
                    p={{ base: 12, lg: 4 }}
                >
                    <Divider variant={"gold"} w={44} />
                    <VStack alignItems={"baseline"} gap={5} pt={10}>
                        <Heading
                            fontSize={{ base: "3xl", md: "4xl", xl: "4xl" }}
                            lineHeight={"shorter"}
                            fontFamily="QuicheText, sans-serif"
                            textTransform={"uppercase"}
                        >
                            Our Process
                        </Heading>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            {" "}
                            Carbonext brings your vision to life. We felt it was
                            important to give back the control over the design
                            process to the customer. Typically a customer would
                            come to an online diamond retail website, select
                            their diamond and then choose the setting that was
                            offered to them from that website. We didn’t think
                            it made sense for the customer to be told they have
                            to use a specific, predetermined selection of
                            settings.
                        </Text>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            We put the power of design back into your hands by
                            pairing you with one of our hundreds of custom
                            jewelers around the country. You also have the
                            option to select the jeweler yourself, once you
                            purchase your diamond. Upon receipt of your diamond
                            you will work with the jeweler you’ve selected to
                            custom build your perfect setting with their state
                            of the art Computer Aided Design (CAD) software and
                            know your ring is truly one of a kind.
                        </Text>
                    </VStack>
                </Box>

                <Box pt={10} p={{ base: 12, lg: 4 }}>
                    <Image
                        src={
                            "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/diamond-picked-up.png"
                        }
                        float={"right"}
                    />
                </Box>
            </SimpleGrid>

            <SimpleGrid
                columns={[1, 1, 2]}
                px={[1, 2, 2, "9%"]}
                py={[8, 16, 20]}
            >
                <Box pt={10} p={{ base: 12, lg: 4 }}>
                    <Image
                        src={
                            "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/diamonds.png"
                        }
                    />
                </Box>

                <Box
                    fontFamily="Montserrat, sans-serif"
                    p={{ base: 12, lg: 4 }}
                >
                    <Divider variant={"gold"} w={44} />
                    <VStack alignItems={"baseline"} gap={5} pt={10}>
                        <Heading
                            fontSize={{ base: "3xl", md: "4xl", xl: "4xl" }}
                            lineHeight={"shorter"}
                            fontFamily="QuicheText, sans-serif"
                            textTransform={"uppercase"}
                        >
                            Our Diamonds
                        </Heading>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            {" "}
                            All of our listed diamonds come with an
                            authenticated GIA report. We only offer GIA
                            diamonds, so you know you are getting the very best
                            quality diamond. Our diamonds are as good in person
                            as they are on paper. What does this mean? On a GIA
                            or any other report, it will not notate a cloudy or
                            hazy stone, which can make the diamond look dull,
                            yet still read beautifully on paper. On Carbonext,
                            we do not allow any of these types of stones onto
                            our platform to be sold to the end user.
                        </Text>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            We do offer “nice” I1 stones for the budget minded
                            customers. A “nice” I1 stone will read as an I1 on
                            the GIA report but is an “eye pleasant” stone
                            meaning you won’t see any imperfections to the
                            naked, untrained eye and you will never see an
                            unlooped center black in our I1 stones.
                        </Text>
                        <Box py={2}>
                            <Button size="md" variant="market.outlined-black">
                                {" "}
                                Learn more{" "}
                            </Button>
                        </Box>
                    </VStack>
                </Box>
            </SimpleGrid>
            <SimpleGrid
                bgColor={"grey.500"}
                columns={[1, 1, 2]}
                px={[1, 2, 2, "9%"]}
                py={[8, 16, 20]}
            >
                <Box
                    pr={"10%"}
                    fontFamily="Montserrat, sans-serif"
                    p={{ base: 12, lg: 4 }}
                >
                    <Divider variant={"gold"} w={44} />
                    <VStack alignItems={"baseline"} gap={5} pt={10}>
                        <Heading
                            fontSize={{ base: "3xl", md: "4xl", xl: "4xl" }}
                            lineHeight={"shorter"}
                            fontFamily="QuicheText, sans-serif"
                            textTransform={"uppercase"}
                        >
                            Our Vendors
                        </Heading>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            What makes Carbonext a truly unique online diamond
                            retailer is, unlike most technology, which
                            eliminates the need for a person to do that job,
                            Carbonext is putting customers back into the bespoke
                            jewelry stores. We are giving this role back to the
                            jewelers to design and make the most beautiful rings
                            for our customers
                        </Text>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            All of the available diamonds on our platform are
                            sourced by one of our hundreds of US based trusted
                            diamond traders that have been heavily vetted for
                            our customers to shop with confidence. Each and
                            every diamond that is listed on our platform has our
                            guarantee that it meets our highest of standards.
                        </Text>
                        <Text as={"p"} color={"grey.600"} fontSize={22}>
                            When it comes to designing the most perfect diamond
                            engagement ring, Carbonext has strategically created
                            this new premiere service retailer. We have created
                            a disruptive hybrid model blurring the line between
                            online retail pricing and premiere concierge
                            service.
                        </Text>
                    </VStack>
                </Box>

                <Box p={{ base: 12, lg: 4 }}>
                    <Image
                        src={
                            "https://carbonext-assests.nyc3.cdn.digitaloceanspaces.com/parallax-register-mirror.png"
                        }
                        float={"right"}
                    />
                </Box>
            </SimpleGrid>
            <GetStarted
                bgColor={"white"}
                currentMarketUser={props.currentMarketUser}
            />

            <Footer />
        </Box>
    );
};

WhyChooseUs.propTypes = {};

WhyChooseUs.defaultProps = {};
