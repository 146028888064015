import { useApolloClient, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { GLS_CATEGORY_TO_GLS_ARRAY_MAPPING } from "../../../../../../constants/businessConstants";
import { useVOrgEvents } from "../../../../../../stories/CloudManagementOS/hooks";
import { GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY } from "../../../../../Market/graph/queries/getReservedGemListingIDs";
import GemListingsManager from "../../../../../UtilityComponents/GemListingsManager";
import { GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS } from "../../../../graph/queries/getGlobalStatsFromSearchOfGemListings";

const GQL_Q_SEARCH_GEM_LISTINGS_WITH_STATE = gql`
    query SearchGemListingsInState(
        $state: GemListingState!
        $searchParam: GemListingSearchParam
        $pagination: PaginationInput
    ) {
        searchGemListingsInState(
            state: $state
            searchParam: $searchParam
            pagination: $pagination
        ) {
            pageInfo {
                hasNextPage
                endCursor
                page
            }
            totalCount
            edges {
                node {
                    id
                    createdAt
                    state
                    price
                    askingPrice
                    timeSold
                    videoLink
                    currentRelativeDiscountPercentage
                    vendorOrganization {
                        legalName
                    }
                    latestRelativePrice {
                        relativePrice
                    }
                    createdBy {
                        ... on Vendor {
                            localAuth {
                                email
                            }
                            person {
                                givenName
                                familyName
                            }
                        }
                        ... on SystemProcess {
                            serviceName
                        }
                    }
                    certifiedGem {
                        certification {
                            certificationId
                            certificationType
                        }

                        specs {
                            carat
                            color
                            cut
                            shape
                            clarity
                        }
                    }
                }
            }
        }
    }
`;

function SubTool(props) {
    const { loading, error, data } = useQuery(
        GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY
    );

    let { eventToHandle } = useVOrgEvents();
    const cache = useApolloClient();

    // This useEffect is for handling real time updates for currently reserved banner
    useEffect(() => {
        if (eventToHandle) {
            const data = cache.readQuery({
                query: GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY,
            });
            let newListOfReservedGemListings = [
                ...data.getReservedGemListingIDs,
            ];

            switch (eventToHandle.type) {
                case "gemlisting.reserved":
                    newListOfReservedGemListings.push(eventToHandle.data.id);
                    break;
                case "gemlisting.unreserved":
                    newListOfReservedGemListings = newListOfReservedGemListings.filter(
                        (reservedGemListingId) =>
                            eventToHandle.data.id !== reservedGemListingId
                    );
                    break;
                default:
                    break;
            }
            cache.writeQuery({
                query: GET_RESERVED_GEM_LISTINGS_IDS_GRAPH_QL_QUERY,
                data: {
                    getReservedGemListingIDs: newListOfReservedGemListings,
                },
            });
        }
    }, [eventToHandle, cache]);

    return (
        <GemListingsManager
            runningMutations={props.runningMutations}
            param1={props.param1}
            userType="vendor"
            reservedGemListings={data?.getReservedGemListingIDs}
            gqlQuery={GQL_Q_SEARCH_GEM_LISTINGS_WITH_STATE}
            gqlQueries={{
                globalStatForSearchQuery: GQL_Q_SEARCH_GET_GLOBAL_STATS_FROM_SEARCH_OF_GEM_LISTINGS,
            }}
            newSearchParam={props.newGemListingSearchParam}
            searchParam={props.gemListingsSearchParam}
            subtoolSelectorMenu={Object.keys(GLS_CATEGORY_TO_GLS_ARRAY_MAPPING)}
            stateSelectorMenu={GLS_CATEGORY_TO_GLS_ARRAY_MAPPING[props.subtool]}
            gemListingsCount={props.gemListingsCount}
            gemListingRows={props.gemListingRows}
            searchFlags={props.gemListingsSearchFlags}
            subtool={props.subtool}
            currentSelectedGLS={props.currentlySelectedGLS}
            options={{}}
            searchStats={props.searchStats}
            isDataLoading={props.isDataLoading}
            priceChangeInProgress={props.priceChangeInProgress}
            acqPriceChangeInProgress={props.acqPriceChangeInProgress}
            cancelArchiveFunc={props.cancelArchive}
            confirmArchiveFunc={props.confirmArchive}
            match={props.match}
        />
    );
}

export default SubTool;
