function convertConstToUrlConst(constant) {
    return constant ? constant.replace(/_/g, '-').toLowerCase() : null
}

function urlConstToConst(constant) {
    return constant ? constant.replace(/-/g, '_').toUpperCase() : null
}

module.exports = {
    convertConstToUrlConst,
    urlConstToConst
}