import gql from "graphql-tag";

const GQL_M_STAGE_NEW_SESSION_FOR_BILL_DOT_COM = gql`
  mutation StageNewSessionForBillDotCom {
    stageNewSessionForBillDotCom {
      type
      message
      status
      title
      description
    }
  }
`;

export { GQL_M_STAGE_NEW_SESSION_FOR_BILL_DOT_COM };
