export const GLS_CATEGORY_TO_GLS_ARRAY_MAPPING = {
    market: ["ON_MARKET", "OFF_MARKET"],
    pending: [
        "NEW",
        "VERIFICATION_REQUESTED",
        "REJECTED",
        "VERIFIED",
        "UNVERIFIED",
    ],
    "past-sales": ["SOLD"],
    archives: ["ARCHIVED"],
};

// TO DO - THIS SHOULD BE GENERATED  !!!!! IMPORTANT
export const GLS_TO_GLS_CATEGORY_MAPPING = {
    OFF_MARKET: "market",
    ON_MARKET: "market",
    ARCHIVED: "archives",
    SOLD: "past-sales",
};

export const DEFAULT_PRICE_RANGE = [0, 100000];
export const DEFAULT_CARAT_RANGE = [0, 10];

export const ROLE_TO_INITIAL_SUBTOOL_GLS_MAPPING = {
    vendor: {
        pending: "new",
        market: "on-market",
        archives: "archived",
        "past-sales": "sold",
    },
    admin: {
        pending: "verification-requested",
        market: "on-market",
        archives: "archived",
        "past-sales": "sold",
    },
};

// THIS COULD PROBABLY be code
export const URL_TO_GLS_MAPPING = {
    "verification-requested": "VERIFICATION_REQUESTED",
    new: "NEW",
    "on-market": "ON_MARKET",
    "off-market": "OFF_MARKET",
    rejected: "REJECTED",
    UNVERIFIED: "UNVERIFIED",
    verified: "VERIFIED",
};

export const LIST_OF_GEM_LISTING_STATE_CATEGORIES_IN_ORDER = [
    "PENDING",
    "MARKET",
    "PAST_SALES",
    "ARCHIVES",
];
export const LIST_OF_SHAPES_IN_ORDER = [
    "ROUND",
    "PRINCESS",
    "PEAR",
    "OVAL",
    "HEART",
    "EMERALD",
    "MARQUISE",
    "ASSCHER",
    "CUSHION",
    "RADIANT",
    "OLD_MINER",
    "TRILLIANT",
    "EUROPEAN_CUT",
    "ROSE",
];
export const LIST_OF_CUTS_IN_ORDER = ["FAIR", "GOOD", "VERY_GOOD", "EXCELLENT"];
export const LIST_OF_CLARITIES_IN_ORDER = [
    "I2",
    "I1",
    "SI2",
    "SI1",
    "VS2",
    "VS1",
    "VVS2",
    "VVS1",
    "IF",
    "FL",
];
export const LIST_OF_COLORS_IN_ORDER = [
    "M",
    "L",
    "K",
    "J",
    "I",
    "H",
    "G",
    "F",
    "E",
    "D",
];
