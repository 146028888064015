import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";
import possibleTypes from "./possibleTypes.json";
import { onError } from "@apollo/client/link/error";
import { history } from "../store";
import jwt_decode from "jwt-decode";

import { RetryLink } from "@apollo/client/link/retry";
import React, { useState } from "react";
import ApolloClientErrorHandler from "../components/ApolloClientErrorHandler";
import { CURRENT_MARKET_USER_GRAPH_QL_QUERY } from "../components/Market/graph/queries/currentMarketUser";
import gql from "graphql-tag";

const CARBONEXT_API_ROOT = process.env.REACT_APP_CARBONEXT_API_BASE_URL;

const REFRESH_TIME = 300000;
const userTypes = {
    marketUser: { tokenType: "marketUserToken", redirectPath: null },
    vendor: { tokenType: "vendorToken", redirectPath: "/vendor/login" },
    admin: { tokenType: "adminToken", redirectPath: "/admin/login" },
};

let refreshing = false;

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: possibleTypes,
});

const cache = new InMemoryCache({
    fragmentMatcher,
});
const httpLink = new createUploadLink({
    uri: CARBONEXT_API_ROOT + "/graphql",
});

const REFRESH = gql`
    mutation RenewAuthToken {
        renewAuthToken
    }
`;

/* gets a new token */
async function updateToken(tokenType) {
    refreshing = true;

    const response = await marketUserGraphClient.mutate({
        mutation: REFRESH,
    });

    const newToken = response.data.renewAuthToken;
    localStorage.setItem(tokenType, newToken);

    refreshing = false;
}

/* if the token expired, redirect to user url
 if the token is about to expire, update token */
const checkTokenExpiration = (token, userType) => {
    const { exp } = jwt_decode(token);
    const expirationTime = exp * 1000 - 60000;
    //const expirationTime = 300000 + (exp * 1000) - 1727930000 //for testing purposes
    //console.log("expiration time left: ", expirationTime - Date.now());//for testing purposes

    if (Date.now() >= expirationTime) {
        localStorage.clear();

        if (userType.redirectPath) {
            history.push(userType.redirectPath);
        }
        window.location.reload();
    } else if (Date.now() >= expirationTime - REFRESH_TIME && !refreshing) {
        //refresh token
        updateToken(userType.tokenType);
    }
};

const marketUserLink = setContext((_, { headers }) => {
    let token = localStorage.getItem("marketUserToken");

    if (token) {
        checkTokenExpiration(token, userTypes.marketUser);
    }

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const vendorAuthLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("vendorToken");

    if (token) {
        checkTokenExpiration(token, userTypes.vendor);
    }
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const adminAuthLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("adminToken");

    if (token) {
        checkTokenExpiration(token, userTypes.admin);
    }

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const marketErrorLink = onError(ApolloClientErrorHandler());

const vendorErrorLink = onError(
    ApolloClientErrorHandler(userTypes.vendor.redirectPath)
);

const adminErrorLink = onError(
    ApolloClientErrorHandler(userTypes.admin.redirectPath)
);

const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error,
    },
});

const marketUserGraphClient = new ApolloClient({
    cache,
    link: ApolloLink.from([
        retryLink,
        marketUserLink,
        marketErrorLink,
        httpLink,
    ]),
});

const vendorGraphClient = new ApolloClient({
    cache,
    link: ApolloLink.from([
        retryLink,
        vendorAuthLink,
        vendorErrorLink,
        httpLink,
    ]),
});

const httpLinkOther = new createUploadLink({
    uri: "https://graph.carbonext.io/graphql",
});

const graphGraphClient = new ApolloClient({
    cache,
    link: ApolloLink.from([retryLink, httpLinkOther]),
});

const adminGraphClient = new ApolloClient({
    cache,
    link: ApolloLink.from([retryLink, adminAuthLink, adminErrorLink, httpLink]),
});

export {
    marketUserGraphClient,
    vendorGraphClient,
    adminGraphClient,
    graphGraphClient,
};
