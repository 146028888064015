import { Flex, Heading, VStack } from "@chakra-ui/react";
import React from "react";
import VerticalStepList from "../shells/VerticalStepList";

export const StepInstructions = ({ steps, title }) => {
  return (
    <Flex direction={"column"}>
      <Heading as="h4" size={"md"}>
        {title}
      </Heading>
      <VerticalStepList steps={steps} />;
    </Flex>
  );
};

export default StepInstructions;
